var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 ddiv", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "card h-100" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "div",
                {
                  staticClass: "w-100 mr-2",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search",
                      },
                    ],
                    ref: "v_focus",
                    staticClass: "form-control w-100",
                    domProps: { value: _vm.search },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.search = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: "fa-solid fa-eraser",
                      size: "lg",
                      title: "очистить",
                    },
                    on: {
                      click: function ($event) {
                        _vm.search = ""
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c(
                  "span",
                  {
                    staticClass: "badge ml-2",
                    class: { active: _vm.state.isTypeList },
                    staticStyle: { cursor: "pointer", opacity: "0.4" },
                    on: {
                      click: function ($event) {
                        _vm.state.isTypeList = !_vm.state.isTypeList
                      },
                    },
                  },
                  [_vm._v("тип листа")]
                ),
                _vm._v(" "),
                _vm._l(_vm.state.search2, function (val) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "badge ml-2",
                        staticStyle: { cursor: "pointer" },
                        style: { "background-color": val.color },
                        on: {
                          click: function ($event) {
                            return _vm.clickRemoveTag(val)
                          },
                        },
                      },
                      [_vm._v(_vm._s(val.name))]
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            this.local.table.length === 0
              ? _c(
                  "div",
                  { staticClass: "text-center p-5" },
                  [
                    true
                      ? _c("font-awesome-icon", {
                          staticClass: "mt-1 text-secondary",
                          attrs: {
                            icon: "fa-solid fa-circle-notch",
                            size: "3x",
                            title: "загрузка данных...",
                            spin: "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.filteredTable, function (val, index) {
              return _c(
                "div",
                { key: "a_" + index, staticClass: "card my-1 myRow" },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-start align-items-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "nav-item dropdown p-0" },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "nav-link dropdown-toggle pl-0",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                icon: "fa-regular fa-square-caret-down",
                                "data-toggle": "dropdown",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "dropdown-menu" }, [
                              !_vm.isForText
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickInsertBlock(
                                            val,
                                            "insertPage"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("вставить новую страницу в проект")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isInsertText(val)
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickInsertBlock(
                                            val,
                                            "insertText"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("добавить текст в конце")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "dropdown-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickInsertBlock(
                                        val,
                                        "replaceTextOnPage"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("заменить текст на странице")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "font-weight-bold",
                          attrs: {
                            href:
                              val.link + "/" + val.project_id + "/" + val.id,
                          },
                          domProps: {
                            innerHTML: _vm._s(_vm.markerText(val.name)),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "badge ml-2 bg-info",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.clickAddTag(val, "project")
                              },
                            },
                          },
                          [_vm._v(_vm._s(val.project_name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "badge ml-2 bg-info",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.clickAddTag(val, "org")
                              },
                            },
                          },
                          [_vm._v(_vm._s(val.org.name))]
                        ),
                        _vm._v(" "),
                        _vm._l(val.tags, function (tt) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "badge ml-2",
                                staticStyle: { cursor: "pointer" },
                                style: { "background-color": tt.color },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickAddTag(tt, "tag")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(tt.name))]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "btn",
                        attrs: {
                          "data-toggle": "collapse",
                          "data-target": "#collapse2_" + val.id,
                        },
                      },
                      [_vm._v("показать")]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "collapse",
                      class: { show: !_vm.isFold(val) },
                      attrs: { id: "collapse2_" + val.id },
                      domProps: { innerHTML: _vm._s(_vm.markerText(val.html)) },
                    }),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }