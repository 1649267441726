<template>
    <div class="row">
        <div class="col-12">
            <span v-if="!inputVal.toRazdel" class="small" @click="clickTag" style="cursor: pointer">раздел ></span>
            <label v-else class="w-100 mt-12">
                <span class="small" style="cursor: pointer" @click="inputVal.toRazdel=''">название раздела</span>
                <input class="form-control" v-model.number="inputVal.toRazdel"/>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    name: 'c-input-razdel-name-so',
    props: {
        value: {type: Object, default: {}, required: true,},
    },
    methods: {
        clickTag() {
            this.inputVal.toRazdel = 'Прочее оборудование';
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', this.value);
            }
        }
    }
}
</script>
