<template>
    <div>
        <div class="container-fluid m1">

            <title-for-page :c-page="state.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                                <div v-if="ePotr.length>0" class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt2=!state.swOnOpt2" :class="{active:state.swOnOpt2}" title="настройки страницы">настройки страницы</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="state.cPage" :state="state" class="mb-3"/>

                    <div v-if="state.swOnOpt2" class="card mb-3">
                        <div class="card-body">
                            <div v-if="ePotr.length>0">
                                <div class="row">
                                    <div class="col-5">
                                        <p>Расчётная точка</p>
                                        <div class="form-check d-inline-block">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="cOpt.data.calcPoint.type" value='eBbs'> секция шин
                                            </label>
                                        </div>
                                        <div class="form-check d-inline-block ml-3">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" v-model="cOpt.data.calcPoint.type" value='ePotr'> потребитель
                                            </label>
                                        </div>
                                        <span class="ml-3 text-danger small" style="cursor: pointer" @click="cOpt.data.calcPoint.id=0">сброс</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="cOpt.data.calcPoint.type==='ePotr'" class="col-5">
                                        <multiselect
                                            v-model="cOpt.data.calcPoint.id"
                                            :options="local.ePotr.map(item=>item.id)"
                                            :show-labels="false"
                                            :searchable="true"
                                            :close-on-select="true"
                                            :allow-empty="false"
                                            :internal-search="false"
                                            @search-change="customSearch"
                                        >
                                            <template slot="singleLabel" slot-scope="props">
                                                <span class="option__desc"><span class="option__title">{{ getMSStr(props.option) }}</span></span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <div class="option__desc">
                                                    <span class="option__title">{{ getMSStr(props.option) }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div v-if="cOpt.data.calcPoint.type==='eBbs'" class="col-5">
                                        <multiselect
                                            v-model="cOpt.data.calcPoint.id"
                                            :options="local.eBbs.map(item=>item.id)"
                                            :show-labels="false"
                                            :searchable="true"
                                            :close-on-select="true"
                                            :allow-empty="false"
                                            :internal-search="false"
                                            @search-change="customSearchBbs"
                                        >
                                            <template slot="singleLabel" slot-scope="props">
                                                <span class="option__desc"><span class="option__title">{{ getMSStrBbs(props.option) }}</span></span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <div class="option__desc">
                                                    <span class="option__title">{{ getMSStrBbs(props.option) }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-3 d-flex align-items-end pb-2">
                                        <button class="btn btn-primary" @click="clickSavePoint">сохранить</button>
                                    </div>
                                </div>
                            </div>

                            <p>Установка исходных данных</p>
                            <div v-if="eBbs.length>0" class="row">
                                <div class="col-3">
                                    <label class="mb-0">
                                        <span class="small">секция шин</span>
                                    </label>
                                    <multiselect
                                        v-model="currentBbs"
                                        :options="eBbs"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :custom-label="opt => opt.panel.name+' ('+opt.name+')'"
                                        :allow-empty="false"
                                        placeholder="название помещения"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-2">
                                    <label for="">
                                        <span class="small">падение напряжения, %</span>
                                        <input class="form-control" v-model.number="currentBbs.es.dU"/>
                                    </label>
                                </div>
                                <div class="col-3 d-flex align-items-end pb-2">
                                    <button class="btn btn-primary" @click="clickLoadBbs">загрузить</button>
                                    <button class="btn btn-primary ml-3" @click="clickSaveBbs">сохранить</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="form-check d-inline-block">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" v-model="cOpt.data.setting.typeReport" value='long'> подробный отчёт
                        </label>
                    </div>
                    <div class="form-check d-inline-block ml-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" v-model="cOpt.data.setting.typeReport" value='short'> краткий отчёт
                        </label>
                    </div>

                    <div class="form-check d-inline-block ml-5">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz3" value='short'> 3хф КЗ
                        </label>
                    </div>
                    <div class="form-check d-inline-block ml-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz3r" value='short'> 3хфД КЗ
                        </label>
                    </div>
                    <div class="form-check d-inline-block ml-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz3sr" value='short'> 3хф.ср КЗ
                        </label>
                    </div>
                    <div class="form-check d-inline-block ml-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz2" value='short'> 2хф КЗ
                        </label>
                    </div>
                    <div class="form-check d-inline-block ml-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz2r" value='short'> 2хфД КЗ
                        </label>
                    </div>
                    <div class="form-check d-inline-block ml-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz1" value='short'> 1ф КЗ
                        </label>
                    </div>
                    <div class="form-check d-inline-block ml-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz1r" value='short'> 1фД КЗ
                        </label>
                    </div>

                    <button type="button" class="btn btn-sm btn-danger ml-2" style="float: right" @click="clickReset">
                        <i v-if="status.reset" class="fa fa-spinner fa-spin fa-fw"></i> reset
                    </button>
                    <table class="table border mb-5">
                        <thead>
                        <tr>
                            <th class="w-50">элемент расчётной схемы</th>
                            <th title="ток 3х фазного, наиболее вероятного, короткого замыкания">3хф.ср, кА</th>
                            <th title="ток металлического 3х фазного короткого замыкания в конце участка">3хф, кА</th>
                            <th title="ток 3х фазного короткого замыкания в конце участка с учётом сопротитвления дуги">3хфД, кА</th>
                            <th title="ток металлического 2х фазного короткого замыкания в конце участка">2хф, кА</th>
                            <th title="ток 2х фазного короткого замыкания в конце участка с учётом сопротитвления дуги">2хфД, кА</th>
                            <th title="ток металлического 1 фазного короткого замыкания в конце участка">1ф, кА</th>
                            <th title="ток 1 фазного короткого замыкания в конце участка с учётом сопротитвления дуги">1фД, кА</th>
                            <th style="width: 3rem"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(value,key) in cOpt.data.rSchema">
                            <tr v-if="value.type==='system'" :key="key">
                                <td class="text-left">
                                    <p v-if="value.ikz===value.ikzmin" class="mb-0 text-primary" @click="clickLock(value)">Система {{ value.ikz }}кА</p>
                                    <p v-else class="mb-0 text-primary" @click="clickLock(value)">Система {{ value.ikzmin }}-{{ value.ikz }}кА</p>
                                    <div v-if="value.dop">
                                        <div class="row">
                                            <div class="col-6">
                                                <label>
                                                    <span class="small">Ток короткого замыкания на зажимах ВН трансформатора, кА</span>
                                                    <vue-autonumeric class="form-control" v-model.number="value.ikz" :options="optionsForFloat(1000,1)" @input="changeIkzSystem('ikz')"/>
                                                </label>
                                            </div>
                                            <div class="col-6">
                                                <label>
                                                    <span class="small">Ток короткого замыкания на зажимах ВН трансформатора, <b>минимальный</b>, кА</span>
                                                    <vue-autonumeric class="form-control" v-model.number="value.ikzmin" :options="optionsForFloat(1000,1)" @input="changeIkzSystem('ikzmin')"/>
                                                </label>
                                            </div>
                                        </div>
                                        <span class="small text-secondary m-0 p-0">r={{ value.r }} x={{ value.x }}</span>
                                    </div>
                                </td>
                                <td>{{ value.ikz3sr }}</td>
                                <td>{{ value.ikz3 }}</td>
                                <td>{{ value.ikz3r }}</td>
                                <td>{{ value.ikz2 }}</td>
                                <td>{{ value.ikz2r }}</td>
                                <td>{{ value.ikz1 }}</td>
                                <td>{{ value.ikz1r }}</td>
                                <td>
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click="clickDelRow(value)" style="cursor: pointer" size="lg"/>
                                </td>
                            </tr>
                            <tr v-if="value.type==='transformer'" :key="key">
                                <td class="text-left">
                                    <p class="mb-0 text-primary" @click="clickLock(value)">Трансформатор
                                        <span>{{ value.obj.name }} {{ value.obj.uv }}/{{ value.obj.un }}кВ {{ value.obj.sn }}кВА {{ value.obj.shem }}</span>
                                        <span v-if="value.nameTp">в {{ value.nameTp }}</span>
                                        <span class="text-danger">~{{ value.obj.in }}А</span>
                                    </p>
                                    <div v-if="value.dop">
                                        <multiselect v-model="value.obj" :options="listTransformer" :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
                                                     :custom-label="opt => opt.name + ' ' + opt.uv + '/' + opt.un + 'кВ ' + opt.sn + 'кВА '+opt.shem"/>
                                        <label class="w-100 mt-2">
                                            <span class="small">Название ТП</span>
                                            <input class="form-control" v-model="value.nameTp" placeholder="введите название ТП если необходимо"/>
                                        </label>
                                        <span class="small text-secondary m-0 p-0">u<sub>кз</sub>={{ value.obj.uk }} P<sub>кз</sub>={{ value.obj.pkz }}  r={{ value.r }} x={{
                                                value.x
                                            }} z/3={{ value.z3 }}</span>
                                    </div>
                                </td>
                                <td>{{ value.ikz3sr }}</td>
                                <td>{{ value.ikz3 }}</td>
                                <td>{{ value.ikz3r }}</td>
                                <td>{{ value.ikz2 }}</td>
                                <td>{{ value.ikz2r }}</td>
                                <td>{{ value.ikz1 }}</td>
                                <td>{{ value.ikz1r }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="value.type==='shina'" :key="key">
                                <td class="text-left">
                                    <p class="mb-0 text-primary d-inline-block" @click="clickLock(value)">Шины {{ value.obj.w }}х{{ value.obj.h }}мм, l={{ value.l }}м
                                        <span class="text-danger">~<span v-if="value.isCu">{{ value.obj.icu }}</span><span v-if="!value.isCu">{{ value.obj.ial }}</span>А</span>
                                    </p>
                                    <span class="ml-3 small" @click="value.lock=!value.lock"><i v-if="value.lock" class="fa fa-lock"></i><i v-if="!value.lock" class="fa fa-lock-open"></i></span>
                                    <div class="row" v-if="value.dop">
                                        <div class="col-12">
                                            <label class="text-secondary" style="cursor: pointer">алюминий <input class="apple-switch" type="checkbox" v-model="value.isCu"> медь</label>
                                        </div>

                                        <div class="col-6" v-if="!value.isCu" style="margin-top: 1.2rem">
                                            <multiselect v-model="value.obj" :options="listShina" :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
                                                         :custom-label="opt => opt.w + 'х' + opt.h+' ~'+ opt.ial+'А'"/>
                                        </div>

                                        <div class="col-6" v-if="value.isCu" style="margin-top: 1.2rem">
                                            <multiselect v-model="value.obj" :options="listShina" :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
                                                         :custom-label="opt => opt.w + 'х' + opt.h+' ~'+ opt.icu+'А'"/>
                                        </div>

                                        <div class="col-3">
                                            <label class="small" title="расстояние между шинами">
                                                расстояние, мм
                                                <vue-autonumeric class="form-control" v-model.number="value.a" :options="optionsForFloat(1000,0)"/>
                                            </label>
                                        </div>
                                        <div class="col-3">
                                            <label class="small" title="длина шин">
                                                длина, м
                                                <vue-autonumeric class="form-control" v-model.number="value.l" :options="optionsForFloat(1000,0)"/>
                                            </label>
                                        </div>
                                        <div class="col-12"><span class="small text-secondary m-0 p-0 ">r={{ value.r }} x={{ value.x }}</span></div>
                                    </div>
                                </td>
                                <td>{{ value.ikz3sr }}</td>
                                <td>{{ value.ikz3 }}</td>
                                <td>{{ value.ikz3r }}</td>
                                <td>{{ value.ikz2 }}</td>
                                <td>{{ value.ikz2r }}</td>
                                <td>{{ value.ikz1 }}</td>
                                <td>{{ value.ikz1r }}</td>
                                <td>
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click="clickDelRow(value)" style="cursor: pointer" size="lg"/>
                                </td>
                            </tr>
                            <tr v-if="value.type==='cable'" :key="key">
                                <td class="text-left">
                                    <p class="mb-0 text-primary d-inline-block" @click="clickLock(value)">
                                        Кабель <span v-if="value.amount>1">{{ value.amount }}х</span>{{ value.marka }} {{ value.obj.name }}мм<sup>2</sup>, l={{ value.l }}м
                                        <span v-if="value.ru"> от щита {{ value.ru }}</span>
                                        <span class="text-danger">~<span>{{ getIForCable(value) }}</span>А</span>
                                    </p>
                                    <span class="ml-3 small" @click="value.lock=!value.lock"><i v-if="value.lock" class="fa fa-lock"></i><i v-if="!value.lock" class="fa fa-lock-open"></i></span>
                                    <div class="row" v-if="value.dop">
                                        <div class="col-12">
                                            <label class="text-secondary" style="cursor: pointer">алюминий <input class="apple-switch" type="checkbox" v-model="value.isCu"> медь</label>
                                        </div>
                                        <div class="col-2">
                                            <label class="small w-100">
                                                кол-во
                                                <vue-autonumeric class="form-control" v-model.number="value.amount" :options="optionsForFloat(10,0)"/>
                                            </label>
                                        </div>
                                        <div class="col-3">
                                            <label class="small w-100">
                                                марка кабеля
                                                <input class="form-control" v-model="value.marka"/>
                                            </label>
                                        </div>
                                        <div class="col-5">
                                            <label class="mb-0">
                                                <span class="small">сечение</span>
                                            </label>
                                            <div v-if="!value.isCu">
                                                <multiselect v-model="value.obj" :options="listCable" :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
                                                             :custom-label="opt => opt.name+' ~'+opt.ial+'А'"/>
                                            </div>
                                            <div v-if="value.isCu">
                                                <multiselect v-model="value.obj" :options="listCable" :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
                                                             :custom-label="opt => opt.name+' ~'+opt.icu+'А'"/>
                                            </div>
                                        </div>
                                        <div class="col-2 pt-1">
                                            <label class="small" title="длина кабеля">
                                                длина, м
                                                <vue-autonumeric class="form-control" v-model.number="value.l" :options="optionsForFloat(10000,0)"/>
                                            </label>
                                        </div>
                                        <div class="col-12"><span class="small text-secondary m-0 p-0 ">r={{ value.r }} x={{ value.x }} zп={{ value.zp }}</span></div>
                                    </div>
                                </td>
                                <td>{{ value.ikz3sr }}</td>
                                <td>{{ value.ikz3 }}</td>
                                <td>{{ value.ikz3r }}</td>
                                <td>{{ value.ikz2 }}</td>
                                <td>{{ value.ikz2r }}</td>
                                <td>{{ value.ikz1 }}</td>
                                <td>{{ value.ikz1r }}</td>
                                <td>
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click="clickDelRow(value)" style="cursor: pointer" size="lg"/>
                                </td>
                            </tr>
                            <tr v-if="value.type==='tt'" :key="key">
                                <td class="text-left">
                                    <p class="mb-0 text-primary d-inline-block" @click="clickLock(value)">Трансформаторы тока {{ value.marka }} {{ value.obj.name }} кл.точн.{{ value.obj.tochn }}</p>
                                    <span class="ml-3 small" @click="value.lock=!value.lock"><i v-if="value.lock" class="fa fa-lock"></i><i v-if="!value.lock" class="fa fa-lock-open"></i></span>
                                    <div class="row" v-if="value.dop">
                                        <div class="col-6 pt-1">
                                            <label class="small w-100" title="марка трансформатора тока">
                                                марка ТТ
                                                <input class="form-control" v-model="value.marka"/>
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <label class="mb-0">
                                                <span class="small">трансформатор тока</span>
                                            </label>
                                            <multiselect v-model="value.obj" :options="listTT" :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
                                                         :custom-label="opt => opt.name+' кл.точн.'+opt.tochn"/>
                                        </div>
                                        <div class="col-12"><span class="small text-secondary m-0 p-0 ">r={{ value.r }} x={{ value.x }}</span></div>
                                    </div>
                                </td>
                                <td>{{ value.ikz3sr }}</td>
                                <td>{{ value.ikz3 }}</td>
                                <td>{{ value.ikz3r }}</td>
                                <td>{{ value.ikz2 }}</td>
                                <td>{{ value.ikz2r }}</td>
                                <td>{{ value.ikz1 }}</td>
                                <td>{{ value.ikz1r }}</td>
                                <td>
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click="clickDelRow(value)" style="cursor: pointer" size="lg"/>
                                </td>
                            </tr>
                            <tr v-if="value.type==='ab'" :key="key">
                                <td class="text-left">
                                    <p class="mb-0 text-primary d-inline-block" @click="clickLock(value)">Аппарат {{ value.marka }} {{ value.obj.name }} {{ value.obj.in }}А</p>
                                    <span class="ml-3 small" @click="value.lock=!value.lock"><i v-if="value.lock" class="fa fa-lock"></i><i v-if="!value.lock" class="fa fa-lock-open"></i></span>
                                    <div class="row" v-if="value.dop">
                                        <div class="col-6 pt-1">
                                            <label class="small w-100">
                                                марка
                                                <input class="form-control" v-model="value.marka"/>
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <label class="mb-0">
                                                <span class="small">номинал коммутационного аппарата</span>
                                            </label>
                                            <multiselect v-model="value.obj" :options="listAB" :searchable="true" :close-on-select="true" :show-labels="false" :allow-empty="false"
                                                         :custom-label="opt => opt.name+' '+opt.in+'А'"/>
                                        </div>
                                        <div class="col-12"><span class="small text-secondary m-0 p-0 ">r={{ value.r }} x={{ value.x }}</span></div>
                                    </div>
                                </td>
                                <td>{{ value.ikz3sr }}</td>
                                <td>{{ value.ikz3 }}</td>
                                <td>{{ value.ikz3r }}</td>
                                <td>{{ value.ikz2 }}</td>
                                <td>{{ value.ikz2r }}</td>
                                <td>{{ value.ikz1 }}</td>
                                <td>{{ value.ikz1r }}</td>
                                <td>
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click="clickDelRow(value)" style="cursor: pointer" size="lg"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <template v-for="value in getButtons">
                        <button class="btn btn-primary" v-if="value==='system'" @click="addE('system')">система</button>
                        <button class="btn btn-primary" v-if="value==='tr'" @click="addE('transformer')">трансформатор</button>
                        <button class="btn btn-primary" v-if="value==='shina'" @click="addE('shina')">шины</button>
                        <button class="btn btn-primary" v-if="value==='cable'" @click="addE('cable')">кабель</button>
                        <button class="btn btn-primary" v-if="value==='tt'" @click="addE('tt')">ТТ</button>
                        <button class="btn btn-primary" v-if="value==='ab'" @click="addE('ab')">коммутационные аппараты</button>
                    </template>


                    <!--                    <button v-if="nbi.projectInfo.id>0 && nbi.isAuth" class="btn btn-secondary text-white" @click="clickSaveAsTP">сохранить как ТП</button>-->
                    <br>
                    <br>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Multiselect from "vue-multiselect";
// import {default as tools} from "./sub/tools";
import VueAutonumeric from 'vue-autonumeric';
import {page} from "./sub/tools";
import CardOptions from "./sub/CardOptions";
import TitleForPage from "./sub/TitleForPage";


export default {
    name: "page-calc-kz",
    components: {Multiselect, VueAutonumeric, CardOptions, TitleForPage},
    props: ['listTransformer', 'listCable', 'listTT', 'listAB', 'listShina', 'cPage', 'defaultValues', 'eBbs', 'ePotr'],
    data: function () {
        return {
            pageT: new page(this),
            nbi: {
                projectInfo: {
                    id: 0
                }
            },
            tools: '',
            countries: [],
            currentBbs: {id: '', name: '', panel: {name: ''}, es: {dU: 0}},
            isLoadingSearch: false,
            pausedL: false,
            result: {},
            buttons: [],
            status: {
                reset: false,
                save: false,
                loading: false,
            },
            local: {
                ePotr: this.ePotr,
                eBbs: this.eBbs,
            },
            tempTr: {},
            lockCalc: false,
            state: {
                version: 12,
                cPage: this.cPage,
                swOnOpt: false,
                swOnOpt2: false,
            },
            isSave: false,
            nameCField: 'table_kz_0',
            isMounted: false,
            resultPhp: {},
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
            if (e.key === 'ctrlS') this.clickSave()
            if (e.key === 'exportWord') this.clickExportWord();
        })
        Bus.$on('navbar_info', obj => this.nbi = obj);
    },
    mounted() {
        this.init();
        Bus.$emit('navbar', {command: 'showButtons', args: {word: true, reload: true, save: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.isMounted = true;
    },
    methods: {
        init() {
            this.pageT.loadLS();
            if (this.pageT.isU(this.state.cPage.id)) this.state.cPage = this.cPage;
        },
        getMSStr(id) {
            let obj = this.local.ePotr.find(item => item.id === id);
            if (obj === undefined) return '';

            let name2 = obj.name2 ?? "_";
            let panel = obj.panel !== undefined ? obj.panel.name : "_";
            let section = obj.section !== undefined ? obj.section.name : "_";
            let room = obj.room !== undefined ? obj.room.name : "_";

            return name2 + ' (' + room + ') ' + panel + ' ' + section;
        },
        getMSStrBbs(id) {
            let obj = this.local.eBbs.find(item => item.id === id);
            if (obj === undefined) return '';

            let name = obj.name ?? "_";
            let panel = obj.panel !== undefined ? obj.panel.name : "_";

            return panel + ' (' + name + ') ';
        },
        customSearch(query) {
            if (query === '') this.local.ePotr = this.ePotr;

            this.local.ePotr = this.ePotr.filter(item => {
                let res = true;
                query.split(' ').forEach(value => {
                    let s = this.getMSStr(item);
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        customSearchBbs(query) {
            if (query === '') this.local.eBbs = this.eBbs;

            this.local.eBbs = this.eBbs.filter(item => {
                let res = true;
                query.split(' ').forEach(value => {
                    let s = this.getMSStrBbs(item);
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        clickLoadBbs() {
            if (this.currentBbs.id === '') return;
            this.cOpt.data.rSchema = this.currentBbs.r_schema !== undefined ? this.currentBbs.r_schema : [];
        },
        clickSaveBbs() {
            if (this.currentBbs.id === '') return;
            this.saveBbs();
        },
        optionsForFloat(max, b) {
            return {
                showWarnings: true,
                allowDecimalPadding: false,
                caretPositionOnFocus: "end",
                decimalPlaces: b,
                digitGroupSeparator: "",
                maximumValue: max,
                outputFormat: "number"
            }
        },
        clickSave() {
            this.pageT.clickSave('/calc/kz/save', {cPage:this.state.cPage}, '_save_kz')
            Bus.$once('zResponse_save_kz', response => {
                this.cOpt.data = response.data;
                if (this.state.cPage.no_project) this.pageT.updLS();
                else this.pageT.clickSaveCPage();
            });
        },
        saveBbs() {
            this.pageT.updLS();
            this.pageT.clickSave('/calc/kz/save_panel', {data: this.cOpt.data, bbs: this.currentBbs}, '_save_panel')
            Bus.$once('zResponse_save_panel', response => {
                this.cOpt.data = response.data;
            });
        },
        clickSavePoint() {
            this.pageT.updLS();
            this.pageT.clickSave('/calc/kz/save_point', {data: this.cOpt.data, bbs: this.currentBbs}, '_save_point')
            Bus.$once('zResponse_save_point', response => {
                this.cOpt.data = response.data;
            });
        },
        changeIkzSystem(tType) {
            let system = this.cOpt.data.rSchema.find(item => item.type === 'system');
            if (system === undefined) return false;
            if (tType === 'ikz' && system.ikz < system.ikzmin) system.ikzmin = system.ikz;
            else if (tType === 'ikzmin' && system.ikz < system.ikzmin) system.ikz = system.ikzmin;
        },
        clickExportWord() {
            if (!this.state.cPage.no_project)
                this.pageT.clickDownload();
            else {
                this.pageT.updLS();
                location.href = this.nbi.url.current + '/export';
            }
        },
        clickDelRow(obj) {
            let index = this.cOpt.data.rSchema.findIndex(item => JSON.stringify(item) === JSON.stringify(obj));
            if (index !== undefined && index !== null) this.cOpt.data.rSchema.splice(index, 1);
        },
        clickLock(obj) {
            obj.dop = !obj.dop;
            obj.lock = true;
        },
        addE(type) {
            let res = this.defaultValues.find(item => item.type === type);
            this.cOpt.data.rSchema.push(res);
        },
        clickReset() {
            if (window.confirm('Вы уверены что хотите сбросить настройки?')) {
                this.pageT.clickSave('/calc/kz/reset', {}, '_reset')
                Bus.$once('zResponse_reset', response => {
                    this.cOpt.data = response.data;
                });
            }
        },
        getIForCable(item) {
            let i = item.obj.ial;
            if (item.isCu) i = item.obj.icu;
            return this.r0(i * item.amount);
        },
        r0(val) {
            if (val === 0 || val === undefined || val === null) return '';
            return Math.round(parseFloat(val));
        },
    }
    ,
    computed: {
        getButtons: function () {
            let res = ['shina', 'cable', 'tt', 'ab'];
            if (this.cOpt.data.rSchema.length === 0) res = ['system', 'tr'];
            else if (this.cOpt.data.rSchema.length === 1 && this.cOpt.data.rSchema[0].type === 'system') res = ['tr'];
            else if (this.cOpt.data.rSchema.length > 1 && this.cOpt.data.rSchema[this.cOpt.data.rSchema.length - 1].type === 'transformer') res = ['shina', 'cable'];
            return res;
        },
        cOpt: function () {
            return this.state.cPage.data.fields.find(item => item.idd === this.nameCField).opt;
        },
    },
    watch: {
        'state.swOnOpt'(newVal, oldVal) {
            this.pageT.updLS();
        }
    }
    ,
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    }
}
</script>
