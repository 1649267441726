<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center m-0">
                <div class="col-12 col-xl-10">
                    <div class="mb-3">
                        <h1 class="mb-0">
                            <span @click="state.catEdit=false">Категории оборудования</span>
                            <font-awesome-icon v-if="!state.catEdit && !state.propEdit" icon="fa-solid fa-pen" class="ml-2" size="sm" @click="state.catEdit=true" title="редактировать категорию"/>
                            <font-awesome-icon v-if="state.catEdit" icon="fa-solid fa-plus" class="ml-2" @click="clickAddCat" title="добавить категорию"/>
                            <font-awesome-icon v-if="state.catEdit" icon="fa-solid fa-floppy-disk" class="ml-2" @click="clickSaveCat" title="сохранить изменения"/>
                        </h1>
                    </div>

                    <div class="row vh-100">
                        <div class="col-4 vh-100" style="overflow-y: scroll;">
                            <nested-category v-model="local.table" v-if="isMounted" :p-state="state"/>
                        </div>
                        <div class="col-8">
                            <label class="w-100">название категории<input class="form-control" v-model.trim="state.activeRow.name" :disabled="!state.catEdit"/></label>
                            <label class="w-100">описание категории<input class="form-control" v-model.trim="state.activeRow.desc" :disabled="!state.catEdit"/></label>

                            <p class="font-weight-bold">ПОЛЯ
                                <font-awesome-icon v-if="!state.propEdit && !state.catEdit" icon="fa-solid fa-pen" class="ml-2" size="sm" @click="state.propEdit=true" title="редактировать поле"/>
                                <font-awesome-icon v-if="state.propEdit" icon="fa-solid fa-plus" class="ml-2" @click="clickAddProp" title="добавить поле"/>
                                <font-awesome-icon v-if="state.propEdit" icon="fa-solid fa-floppy-disk" class="ml-2" @click="clickSaveFields" title="сохранить изменеия"/>
                                <font-awesome-icon v-if="state.propEdit" icon="fa-solid fa-file-import" class="ml-2 text-secondary"
                                                   @click="state.importCategoryFields.isEdit=!state.importCategoryFields.isEdit" :class="{'text-dark':state.importCategoryFields.isEdit}"
                                                   title="импорт полей из другой выбранной категории"/>
                            </p>

                            <nested-category-fields v-model="getProp" v-if="isMounted" :p-state="state" :prop-visible="local.potrVisible"/>

                            <div v-if="isMounted && state.importCategoryFields.isEdit">
                                <p class="font-weight-bold">импорт <span>{{ state.importCategoryFields.row.name }}</span></p>
                                <nested-category-fields v-model="getImportProp" :p-state="state" :prop-visible="local.potrVisible" :is-import="true"/>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import nestedCategory from "./sub/nestedCategory";
import {page, toolM} from "./sub/tools";
import NestedCategoryFields from "./sub/nestedCategoryFields";

export default {
    name: "page-category",
    components: {NestedCategoryFields, Multiselect, nestedCategory},
    props: ['table'],
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            nbi: {},
            local: {
                table: this.table,
                propVisible: [],
            },
            status: {
                loading: false,
            },
            state: {
                version: 6,
                activeRow: {},
                propEdit: false,
                catEdit: false,
                search: {
                    val: ''
                },
                importCategoryFields: {
                    row: {},
                    isEdit: false,
                },
            },
            defaultItem: {
                id: 'name',
                name: 'name',
                desc: 'name',
                req: false,
                type: 'string',
                unit: null,
                val: '',
                size: 2,
            },
            isMounted: false,
        }
    },
    created: function () {
        Bus.$on('navbar_info', obj => this.nbi = obj);
    },
    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {reload: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.state.activeRow = this.local.table[0];
        this.state.importCategoryFields.row = this.local.table[0];
        this.pageT.loadLS();
        this.isMounted = true;
    },
    methods: {
        clickAddProp() {
            let r = {};
            if (this.state.activeRow.prop.fields.length === 0) {
                r = this.defaultItem;
            } else {
                r = JSON.parse(JSON.stringify(this.state.activeRow.prop.fields[this.state.activeRow.prop.fields.length - 1]));
                r.id = r.id + '_';
                if (r.size === undefined) this.$set(r.size, 2, false);
            }
            this.state.activeRow.prop.fields.push(r);
        },
        clickAddCat() {
            let o = this.getParentInThreeById(this.local.table);
            let r = JSON.parse(JSON.stringify(this.state.activeRow));
            r.child = [];
            r.id = r.id * 10000;
            if (o) o.child.unshift(r);
            else this.local.table.unshift(r);
        },
        getParentInThreeById(arr) {
            let o;
            arr.forEach(item => {
                if (item.id === this.state.activeRow.id_parent) {
                    o = item;
                }
                if (!o) o = this.getParentInThreeById(item.child);
            })

            return o;
        },
        clickSaveFields() {
            console.log(this.state.activeRow);
            this.pageT.clickSave('/category/save_fields', {active: this.state.activeRow, table: this.local.table}, '_save_fields');
            Bus.$once('zResponse_save_fields', response => {
                // this.local.eRoom = response.eRoom;
            });
        },
        clickSaveCat() {
            this.pageT.clickSave('/category/save_cat', {table: this.local.table}, '_save_cat');
            Bus.$once('zResponse_save_cat', response => {
                this.state.catEdit = false;
            });
        },
    },
    computed: {
        getProp: {
            get() {
                return this.state.activeRow.prop !== undefined ? this.state.activeRow.prop.fields : [];
            },
            set(val) {
                this.state.activeRow.prop.fields = val;
            }
        },
        getImportProp: {
            get() {
                return this.state.importCategoryFields.row.prop !== undefined ? this.state.importCategoryFields.row.prop.fields : [];
            }
        }
    },
    watch: {
        'state.activeRow'(newVal, oldVal) {
            this.pageT.updLS();
        },
    },
    filters: {},
    directives: {}
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/variables';

svg {
    &:hover {
        cursor: pointer;
    }
}

.hover {
    &:hover {
        background-color: lighten($primary, 20%);
    }
}

//todo css удалить scrollbar
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

* {
    -ms-overflow-style: none !important;
}
</style>
