<template>
</template>

<script>
export default {
    methods: {
        keyUp: function (evt) {
            // console.log(evt.code);
            if (evt.keyCode === 45) {
                // Bus.$emit('keyUp', 'insert');
                Bus.$emit('keyUp', {key: 'insert', evt: evt});
                evt.preventDefault();
                return false;
            }
            // Bus.$emit('keyUp', evt.keyCode);
            Bus.$emit('keyUp', {key: evt.keyCode, evt: evt});
        },
        keyDown: function (evt) {
            if (evt.ctrlKey && evt.keyCode === 83) {
                // Bus.$emit('keyUp', 'ctrlS');
                Bus.$emit('keyUp', {key: 'ctrlS', evt: evt});
                evt.preventDefault();
                return false;
            }
            if (evt.ctrlKey && evt.keyCode === 13) {
                // Bus.$emit('keyUp', 'ctrlEnter');
                Bus.$emit('keyUp', {key: 'ctrlEnter', evt: evt});
                evt.preventDefault();
                return false;
            }
            if (evt.ctrlKey && evt.keyCode === 46) {
                // Bus.$emit('keyUp', 'ctrlDel');
                Bus.$emit('keyUp', {key: 'ctrlDel', evt: evt});
                evt.preventDefault();
                return false;
            }
            if (evt.shiftKey && evt.keyCode === 38) {
                // Bus.$emit('keyUp', 'shiftUp');
                Bus.$emit('keyUp', {key: 'shiftUp', evt: evt});
                // evt.preventDefault();
                return false;
            }
            if (evt.shiftKey && evt.keyCode === 40) {
                // Bus.$emit('keyUp', 'shiftDown');
                Bus.$emit('keyUp', {key: 'shiftDown', evt: evt});
                // evt.preventDefault();
                return false;
            }
            if (evt.ctrlKey && evt.keyCode === 221) {
                // Bus.$emit('keyUp', 'shiftDown');
                Bus.$emit('keyUp', {key: 'ctrl]', evt: evt});
                // evt.preventDefault();
                return false;
            }
            if (evt.keyCode === 113) {
                // Bus.$emit('keyUp', 'f2');
                Bus.$emit('keyUp', {key: 'f2', evt: evt});
                evt.preventDefault();
                return false;
            }
            if (evt.keyCode === 38) {
                Bus.$emit('keyUp', {key: 'up', evt: evt});
                // evt.preventDefault();
                return false;
            }
            if (evt.keyCode === 40) {
                Bus.$emit('keyUp', {key: 'down', evt: evt});
                // evt.preventDefault();
                return false;
            }
            if (evt.keyCode === 27) {
                // Bus.$emit('keyUp', 'esc');
                Bus.$emit('keyUp', {key: 'esc', evt: evt});
                evt.preventDefault();
                return false;
            }
            if (evt.keyCode === 13) {
                // Bus.$emit('keyUp', 'enter');
                Bus.$emit('keyUp', {key: 'enter', evt: evt});
                // evt.preventDefault();
                return false;
            }
            // Bus.$emit('keyDown', evt.keyCode);
            Bus.$emit('keyDown', {key: evt.keyCode, evt: evt});


        }
    },
    created: function () {
        document.addEventListener('keydown', this.keyDown);
        document.addEventListener('keyup', this.keyUp);
    },
    destroyed: function () {
        document.removeEventListener('keydown', this.keyDown);
        document.removeEventListener('keyup', this.keyUp);
    },
}
</script>

