/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('vue-flash-message/dist/vue-flash-message.min.css');

window.Vue = require('vue').default;
window.Bus = new Vue();

let jQuery = require('jquery');
require('jquery-ui-dist/jquery-ui');


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('zapros', require('./components/sub/zapros.vue').default);

//npm install --save vue-flash-message
import VueFlashMessage from 'vue-flash-message/dist/vue-flash-message.min.js';

Vue.use(VueFlashMessage, {
    createShortcuts: false,
});

//npm install -S vue-inputmask
// const VueInputMask = require('vue-inputmask').default
// Vue.use(VueInputMask)

//npm install vue-autonumeric --save

//npm install vue-cookies --save
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

//npm i vue-momentjs
import moment from "moment";
import 'moment-timezone';
import VueMomentJS from "vue-momentjs";
Vue.use(VueMomentJS, moment);
moment.tz.setDefault('Europe/Moscow');

//npm install animate.css --save
//npm i vuedraggable
//npm i tiptap-extensions
//npm i vuejs-datepicker
//npm i moment
//npm install vue-color
//npm i vue-context
//npm i uniqid


import {library} from '@fortawesome/fontawesome-svg-core'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {faMinus} from '@fortawesome/free-solid-svg-icons'
import {faEraser} from '@fortawesome/free-solid-svg-icons'
import {faList} from '@fortawesome/free-solid-svg-icons'
import {faEyeDropper} from '@fortawesome/free-solid-svg-icons'
import {faFileArrowDown} from '@fortawesome/free-solid-svg-icons'
import {faGear} from '@fortawesome/free-solid-svg-icons'
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import {faFileImport} from '@fortawesome/free-solid-svg-icons'
import {faFileExport} from '@fortawesome/free-solid-svg-icons'
import {faAnglesRight} from '@fortawesome/free-solid-svg-icons'
import {faFileZipper} from '@fortawesome/free-solid-svg-icons'
import {faFileWord} from '@fortawesome/free-solid-svg-icons'
import {faFloppyDisk} from '@fortawesome/free-solid-svg-icons'
import {faArrowsRotate} from '@fortawesome/free-solid-svg-icons'
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons'
import {faRotate} from '@fortawesome/free-solid-svg-icons'
import {faCalculator} from '@fortawesome/free-solid-svg-icons'
import {faPen} from '@fortawesome/free-solid-svg-icons'
import {faFile as faFileSolid} from '@fortawesome/free-solid-svg-icons'
import {faFile} from '@fortawesome/free-regular-svg-icons'
import {faStar} from '@fortawesome/free-regular-svg-icons'
import {faStar as faStarSolid} from '@fortawesome/free-solid-svg-icons'
import {faLink} from '@fortawesome/free-solid-svg-icons'
import {faBold} from '@fortawesome/free-solid-svg-icons'
import {faItalic} from '@fortawesome/free-solid-svg-icons'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {faExclamation} from '@fortawesome/free-solid-svg-icons'
import {faCloudArrowDown} from '@fortawesome/free-solid-svg-icons'
import {faCloudArrowUp} from '@fortawesome/free-solid-svg-icons'
import {faCircleNodes} from '@fortawesome/free-solid-svg-icons'
import {faEyeSlash} from '@fortawesome/free-regular-svg-icons'
import {faEye} from '@fortawesome/free-regular-svg-icons'
import {faAnglesDown} from '@fortawesome/free-solid-svg-icons'
import {faArrowRightArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {faCircle} from '@fortawesome/free-solid-svg-icons'
import {faCircle as faCircleEmpty} from '@fortawesome/free-regular-svg-icons'
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {faCircleDot} from '@fortawesome/free-solid-svg-icons'
import {faSquareCaretDown} from '@fortawesome/free-regular-svg-icons'
import {faCircleUser} from '@fortawesome/free-regular-svg-icons'
import {fa1} from '@fortawesome/free-solid-svg-icons'
import {fa3} from '@fortawesome/free-solid-svg-icons'
import {faLock} from '@fortawesome/free-solid-svg-icons'
import {faLockOpen} from '@fortawesome/free-solid-svg-icons'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {faLocationCrosshairs} from '@fortawesome/free-solid-svg-icons'
import {faBookmark} from '@fortawesome/free-solid-svg-icons'
import {faAngleDown} from '@fortawesome/free-solid-svg-icons'
import {faAngleUp} from '@fortawesome/free-solid-svg-icons'
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons'
import {faCommentsDollar} from '@fortawesome/free-solid-svg-icons'
import {faCoins} from '@fortawesome/free-solid-svg-icons'
import {faHandHoldingDollar} from '@fortawesome/free-solid-svg-icons'
import {faSort} from '@fortawesome/free-solid-svg-icons'
import {faSortUp} from '@fortawesome/free-solid-svg-icons'
import {faSortDown} from '@fortawesome/free-solid-svg-icons'
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'
import {faFileCsv} from '@fortawesome/free-solid-svg-icons'
import {faAward} from '@fortawesome/free-solid-svg-icons'
import {faCookieBite} from '@fortawesome/free-solid-svg-icons'
import {faDatabase} from '@fortawesome/free-solid-svg-icons'



library.add(faBookmark, faFileExport, faAngleUp, faAngleDown, faLocationCrosshairs, faAngleRight, faLock, faLockOpen, fa1, fa3, faArrowLeft, faCloudArrowUp, faSquareCaretDown, faCircleCheck, faCircle,
faCircleEmpty, faCircleExclamation,
faArrowRightArrowLeft,
 faAnglesDown, faEyeSlash, faCloudArrowDown, faExclamation, faBars, faItalic,
    faBold, faCircleDot,
    faXmark, faPlus, faAward,faCircleUser,
    faMinus, faEraser,
    faList,faCheck, faStar, faDatabase,
    faEyeDropper,
    faFileArrowDown,
    faGear, faMagnifyingGlass,faStarSolid,
    faFileImport,
    faAnglesRight,
    faEye,
    faCircleNodes,
    faFileZipper, faFileWord, faFloppyDisk, faArrowsRotate,
    faCircleNotch, faRotate, faCalculator, faPen, faFileSolid, faFile, faLink, faCommentsDollar, faCoins, faHandHoldingDollar,
    faSort,faSortUp,faSortDown,faArrowUpRightFromSquare,faFileCsv,faCookieBite)

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.config.productionTip = false


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
