<template>
    <div>
        <div class="container-fluid m1">

            <title-for-page :c-page="local.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav v-if="!!local.cPage.id" class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt2=!state.swOnOpt2" :class="{active:state.swOnOpt2}" title="настройки страницы">настройки таблицы</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <card-options v-if="!!local.cPage.id" :c-page="local.cPage" :state="state" class="mb-3"/>


                    <div v-if="state.swOnOpt2" class="card mb-3">
                        <div class="card-header p-1 pl-2" @click="state.swOnOpt2 = !state.swOnOpt2" style="cursor: pointer">настройки таблицы</div>
                        <div class="card-body">
                            <div class="row">

                            </div>
                        </div>
                    </div>

                    <h1 v-if="!local.cPage.id" class="text-center mb-5">Расчёт сопротивления заземляющего устройства</h1>

                    <div class="row">
                        <div class="col-12 bg-primary-l font-weight-bold p-2">Климатический регион</div>
                    </div>
                    <div class="row">
                        <div class="col-5">

                            <br>
                        </div>
                        <div class="col-7">


                            <label class="small w-100 text-left mb-0">
                                регион
                                <span class="ml-3 text-danger small" style="cursor: pointer" @click="cOpt.data.cityObj={}">сброс</span>
                            </label>
                            <multiselect
                                ref="ms4"
                                v-model="cOpt.data.cityObj"
                                :options="local.listCity"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                :internal-search="false"
                                @search-change="customSearch4"
                                @open="onOpen4('ms4')"
                                :blockKeys="['Delete']"
                                placeholder="регион"
                                class="mb-3"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc"><span class="option__title">{{ props.option.city }} ({{ props.option.region }})</span></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.city }} ({{ props.option.region }})</span>
                                    </div>
                                </template>
                            </multiselect>

                            <div class="form-check d-inline-block ml-3 my-2">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" :checked="cOpt.data.region.id===1" @click="clickSetRegion(1)"> I
                                </label>
                            </div>
                            <div class="form-check d-inline-block ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" :checked="cOpt.data.region.id===2" @click="clickSetRegion(2)"> II
                                </label>
                            </div>
                            <div class="form-check d-inline-block ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" :checked="cOpt.data.region.id===3" @click="clickSetRegion(3)"> III
                                </label>
                            </div>
                            <div class="form-check d-inline-block ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" :checked="cOpt.data.region.id===4" @click="clickSetRegion(4)"> IV
                                </label>
                            </div>

                            <p class="my-0 mt-1">Средняя многолетняя низшая температура (январь) <span class="font-weight-bold">{{ cOpt.data.region.t1 }}</span> &deg;C</p>
                            <p class="my-0">Средняя многолетняя высшая температура (июль) <span class="font-weight-bold">{{ cOpt.data.region.t2 }}</span> &deg;C</p>
                            <p class="my-0">Продолжительность замерзания вод <span class="font-weight-bold">{{ cOpt.data.region.d }}</span> дней</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 bg-primary-l font-weight-bold p-2">Грунт</div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <span class="small">сопротивление слоя грунта</span>
                            <multiselect v-model="currentGrunt" :options="listGrunt" :searchable="false" :close-on-select="true" :show-labels="false" :allow-empty="false"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc">
                                        <span class="option__title">{{ props.option.name }} ({{ props.option.min }}...{{ props.option.max }} Ом*м)</span>
                                    </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                        <br>
                                        <span class="option__small small">{{ props.option.min }}...{{ props.option.max }} Ом*м</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-1 d-flex align-items-end">
                            <button type="button" class="btn btn-secondary" style="float: right" @click="clickAddGrunt">добавить</button>
                        </div>
                    </div>

                    <div class="row mb-5">
                        <template v-for="(el,index) in cOpt.data.grunt">
                            <div class="col-5 text-left">
                                <span class="small">слой грунта <a class="form-control btn btn-sm d-inline text-danger" @click="clickRemoveGrunt(index)">удалить</a></span>
                                <input class="form-control" v-model="el.name">
                            </div>
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-4" title="Глубина залегания слоя от, мм">
                                        <span class="small">глубина от, м</span>
                                        <input class="form-control" v-model.number="el.h1">
                                    </div>
                                    <div class="col-4" title="Глубина залегания слоя до, мм">
                                        <span class="small">глубина до, м</span>
                                        <input class="form-control" v-model.number="el.h2">
                                    </div>
                                    <div class="col-4">
                                        <span class="small">сопротивление грунта, Ом*м</span>
                                        <input class="form-control" v-model.number="el.r">
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="row">
                        <div class="col-12 bg-primary-l font-weight-bold p-2">Элементы заземления</div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <button class="btn form-control btn-secondary" @click="clickAddElement(1)" :disabled="isDisabled(1)">вертикальный электрод</button>
                        </div>
                        <div class="col-3">
                            <button class="btn form-control btn-secondary" @click="clickAddElement(2)" :disabled="isDisabled(2)">горизонтальный электрод</button>
                        </div>
                    </div>

                    <div class="row" v-for="(el,index) in cOpt.data.elements">
                        <div class="col-5">
                            {{ listElements.find(item => item.id === el.id).name }} <a class="form-control btn btn-sm d-inline text-danger" @click="clickRemoveElement(index)">удалить</a>
                            <br>
                            <h5><span class="font-weight-bold" title="сопротивление одиночного электрода">{{ el.r1 }}</span> /
                                <span class="font-weight-bold" title="сопротивление с учётом экранирования">{{ el.r2 }}</span> Ом</h5>

                        </div>
                        <div class="col-7">
                            <div class="row">
                                <div v-if="el.id!==2" class="col-2">
                                    <span class="small">количество</span>
                                    <input class="form-control" v-model.number="el.amount">
                                </div>
                                <div class="col-3">
                                    <span class="small">длина электрода, м</span>
                                    <input class="form-control" v-model.number="el.l">
                                </div>
                                <div class="col-3">
                                    <span v-if="el.isPipe" class="small">диаметр электрода, м</span>
                                    <span v-if="!el.isPipe && el.id===1" class="small">ширина уголка, м</span>
                                    <span v-if="!el.isPipe && el.id===2" class="small">ширина полосы, м</span>
                                    <input class="form-control" v-model.number="el.d">
                                </div>
                                <div class="col-4">
                                    <span v-if="el.id===1" class="small">глубина верхнего конца, м</span>
                                    <span v-else class="small">глубина укладки, м</span>
                                    <input class="form-control" v-model.number="el.h">
                                </div>

                                <div class="col-4 d-flex align-items-end pb-2" title="форма электрода">
                                    <div class="form-check d-inline-block">
                                        <label class="form-check-label" style="cursor: pointer">
                                            <input class="form-check-input" type="radio" v-model="el.isPipe" v-bind:value='false'>
                                            <span v-if="el.id===1">уголок</span>
                                            <span v-else>полоса</span>
                                        </label>
                                    </div>
                                    <div class="form-check d-inline-block ml-3">
                                        <label class="form-check-label" style="cursor: pointer">
                                            <input class="form-check-input" type="radio" v-model="el.isPipe" v-bind:value='true'> труба/арматура
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 d-flex align-items-end pb-2" title="электроды расположены в ряд или расположены по замкнутому контуру?">
                                    <div class="form-check d-inline-block">
                                        <label class="form-check-label" style="cursor: pointer">
                                            <input class="form-check-input" type="radio" v-model="el.isClosed" v-bind:value='false'> в ряд
                                        </label>
                                    </div>
                                    <div class="form-check d-inline-block ml-3">
                                        <label class="form-check-label" style="cursor: pointer">
                                            <input class="form-check-input" type="radio" v-model="el.isClosed" v-bind:value='true'> по контуру
                                        </label>
                                    </div>
                                </div>
                                <div v-if="el.id===1" class="col-4" title="среднее расстояние между электродами">
                                    <span class="small">интервал, м</span>
                                    <input class="form-control" v-model.number="el.interval">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12"><h5>Итого {{ cOpt.data.r }} Ом</h5></div>
                    </div>


                    <br>
                    <br>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import VueAutonumeric from 'vue-autonumeric';
import CardOptions from "./sub/CardOptions";
import TitleForPage from "./sub/TitleForPage";
import {page} from "./sub/tools";

export default {
    name: "page-calc-zaz",
    components: {Multiselect, VueAutonumeric, CardOptions, TitleForPage},
    props: ['listGrunt', 'listRegion', 'cPage', 'listCity'],
    data: function () {
        return {
            pageT: new page(this),
            nbi: {},
            status: {
                reset: false,
                save: false,
                loading: false,
            },
            currentGrunt: {},
            listElements: [
                {id: 1, name: 'Вертикальный электрод'},
                {id: 2, name: 'Горизонтальный электрод'},
            ],
            local: {
                cPage: this.cPage,
                listCity: this.listCity,
            },
            state: {
                version: 10,
                opt: {
                    data: {
                        t_reg: 0,
                        grunt: [],
                        region: [],
                        cityObj: {},
                        elements: [],
                        r: 0,
                        fix: {
                            region: true,
                        },
                        setting: {
                            typeReport: 'long',
                            ikz3: true,
                        },
                    }
                },
                swOnOpt: false,
                swOnOpt2: false,
            },
            isSave: false,
            nameCField: 'table_zaz_0',
            isMounted: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
            if (e.key === 'calc') this.clickCalc()
            if (e.key === 'ctrlS') this.clickSave()
            if (e.key === 'download') this.clickExportWord();
        })
        Bus.$on('navbar_info', obj => this.nbi = obj);
    },
    mounted() {
        this.init();
        if (!!this.local.cPage.id) {
            Bus.$emit('navbar', {command: 'showButtons', args: {calc: true, reload: true, save: true, download: true}});
        } else {
            Bus.$emit('navbar', {command: 'showButtons', args: {calc: true, reload: true, download: true}});
        }

        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.currentGrunt = this.listGrunt.find(item => item.name === 'Садовая земля');
        this.isMounted = true;
    },
    methods: {
        clickSetRegion(id) {
            let obj = this.listRegion.find(item => item.id === id);
            if (!obj) return;
            this.cOpt.data.region = obj;
        },
        onOpen4(r) {
            setTimeout(() => {
                if (!this.cOpt.data.cityObj) return;
                let obj = this.local.listCity.find(item => item.id === this.cOpt.data.cityObj.id);
                if (!obj) return;
                this.$refs[r].$refs.search.value = obj.city + ' (' + obj.region + ')';
            }, 10);
        },
        //todo js multiselect custom search
        customSearch4(query) {
            if (query === '') return;
            let i = 0;
            this.local.listCity = this.listCity.filter(item => {
                if (i > 9) return false;
                let res = true;
                if (query !== '') {
                    query.split(' ').forEach(value => {
                        let s = item.city + item.region.replace('(', '').replace(')', '');
                        res = res && s.toLowerCase().indexOf(value.replace('(', '').replace(')', '').toLowerCase()) !== -1;
                    });
                }
                if (res) i++;
                return res;
            })
        },
        isDisabled(id) {
            return this.cOpt.data.elements.findIndex(item => item.id === id) >= 0;
        },
        clickAddElement(id) {
            this.cOpt.data.elements.push({
                id: id,
                amount: 1,
                l: 1,
                d: 0.05,
                b: 0.05,
                h: 0.8,
                interval: 1,
                r1: 1,
                r2: 1,
                isClosed: false,
                isPipe: false,
            })
        },
        clickRemoveElement(index) {
            this.cOpt.data.elements.splice(index, 1);
        },
        clickRemoveGrunt(index) {
            this.cOpt.data.grunt.splice(index, 1);
        },
        clickAddGrunt() {
            this.cOpt.data.grunt.push({
                name: this.currentGrunt.name,
                r: this.currentGrunt.val,
                h1: 0,
                h2: 0.8,
            });

        },
        init() {
            if (!!this.cPage.id) {
                if (this.cOpt.data === undefined || this.cOpt.data === null) this.cOpt.data = this.state.opt.data;
            } else {
                this.pageT.loadLS();
            }
            if (this.cOpt.data === undefined || this.cOpt.data === null) return;
        },
        clickSave() {
            this.isSave = true;
            this.clickCalc();
        },
        clickCalc() {
            this.pageT.clickSave('/calc/zaz/save', {data: this.cOpt.data}, '_save_zaz')
            Bus.$once('zResponse_save_zaz', response => {
                this.cOpt.data = response.data;
                if (!this.cPage.id) this.pageT.updLS();
                if (this.isSave) this.pageT.clickSaveCPage();
                this.isSave = false;
            });
        },
        clickExportWord() {
            if (!!this.local.cPage.id)
                this.pageT.clickDownload();
            else {
                this.pageT.updLS();
                location.href = this.nbi.url.current + '/export';
            }
        },
    },
    computed: {
        cOpt: function () {
            if (!!this.local.cPage.id) return this.local.cPage.data.fields.find(item => item.idd === this.nameCField).opt;
            else return this.state.opt;
        },
    },
    watch: {
        'state.swOnOpt'(newVal, oldVal) {
            this.pageT.updLS();
        }
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    }
}
</script>

<style scoped>

</style>
