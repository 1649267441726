var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown-menu",
      class: { context: !_vm.isChild },
      style: _vm.style,
    },
    _vm._l(_vm.items, function (val, index) {
      return _c(
        "div",
        { key: val + index },
        [
          val.name === "---"
            ? _c("div", { staticClass: "dropdown-divider" })
            : !_vm.isHide(val)
            ? _c(
                "button",
                {
                  staticClass: "dropdown-item",
                  class: { submenu: val.child },
                  attrs: { type: "button", disabled: _vm.isDisable(val) },
                  on: {
                    click: function ($event) {
                      return _vm.clickItem(val.link)
                    },
                  },
                },
                [_vm._v(_vm._s(val.name))]
              )
            : _vm._e(),
          _vm._v(" "),
          val.child
            ? _c("ContextMenuMy", {
                attrs: {
                  items: val.child,
                  "is-child": true,
                  "obj-p": _vm.sObj,
                  "id-menu": _vm.idMenu,
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }