var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "item-container",
      attrs: {
        tag: "div",
        list: _vm.list,
        value: _vm.value,
        handle: ".handle",
        animation: "150",
        group: "nested",
        "fallback-on-body": true,
        "swap-threshold": 0.5,
        "ghost-class": "ghost",
        filter: ".no-drag",
      },
      on: { input: _vm.emitter, change: _vm.onChange },
    },
    _vm._l(_vm.realValue, function (el) {
      return _c(
        "div",
        { key: el.id, staticClass: "item-group" },
        [
          _c(
            "div",
            {
              staticClass: "item row mb-0",
              class: { ru: el.isRu, "not-visible": !el.isVisible },
              staticStyle: { cursor: "pointer" },
              on: {
                contextmenu: function ($event) {
                  $event.preventDefault()
                  return _vm.onShowContext($event, el)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "handle",
                    attrs: { icon: "fa-solid fa-bars" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v(" " + _vm._s(el.name)),
                  ]),
                  _vm._v(" "),
                  !el.isVisible
                    ? _c("font-awesome-icon", {
                        attrs: { icon: "fa-solid fa-eye-slash" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 text-center" }, [
                _vm._v(_vm._s(el.kolvo)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 text-center" }, [
                _vm._v(_vm._s(el.pu)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 text-center" }, [
                _vm._v(_vm._s(el.cosic)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 text-center" }, [
                _vm._v(_vm._s(el.ks)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 text-center" }, [
                _vm._v(_vm._s(el.pr)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 text-center" }, [
                _vm._v(_vm._s(el.qr)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 text-center" }, [
                _vm._v(_vm._s(el.sr)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 text-center" }, [
                el.ir > 0 ? _c("span", [_vm._v(_vm._s(el.ir))]) : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("nested-trn", {
            staticClass: "item-sub ml-3",
            attrs: {
              list: el.child,
              level: _vm.level + 1,
              id: el.id,
              nbi: _vm.nbi,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }