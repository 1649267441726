<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">
                    <div class="mb-3">
                        <h1 class="mb-0">Проекты</h1>
                    </div>

                    <input class="form-control w-100 mb-3" v-model="search">


                    <table class="table table-sm">
                        <thead>
                        <tr>
                            <td>name1</td>
                            <td>name3</td>
                            <td class="w-5">нрр</td>
                            <td class="w-5">пожар</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="val in filteredTable" :key="val.id">
                            <td class="text-left text-nowrap">
                                {{val.name1}}
                            </td>
                            <td>
                                <multiselect
                                    v-model="val.name3"
                                    :options="listName3.map(item => item.name3)"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :custom-label="opt => labelSearch(listName3,opt)"
                                    :allow-empty="false"
                                >
                                </multiselect>
                            </td>
                            <td><input class="apple-switch" type="checkbox" v-model="val.nrr"></td>
                            <td><input class="apple-switch" type="checkbox" v-model="val.arr"></td>
                        </tr>
                        </tbody>
                    </table>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import nestedList from "./sub/nestedList";
    import moment from "moment";

    export default {
        name: "page-name-1",
        components: {Multiselect, nestedList},
        props: ['table', 'listName3'],
        data: function () {
            return {
                search: '',
                listStatus: [
                    {id: 0, name: 'закрытый'},
                    {id: 1, name: 'открытый'},
                ],
                old: {
                    username: 'qwe',
                    pass: 'qwe',
                },
                pass: '',
                nbi: {
                    projectInfo: {},
                    authUser: {
                        prop: {
                            currentProjectId: 0,
                            loadProjectId: 0,
                        }
                    }
                },
                errors: {
                    pass: [''],
                },
                status: {
                    reset: false,
                    save: false,
                    loading: false,
                },
                local: {
                    table: this.table,
                }
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'ctrlS') this.clickSave();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload: true, save: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
        },
        methods: {
            labelSearch(objj, opt) {
                let obj = objj.find(item => item.name3 === opt);
                if (obj === undefined) return '';
                return obj.name3 + ' (' + obj.grupp + ')';
            },
            setLoading(state) {
                this.status.loading = state;
                Bus.$emit('navbar', {command: 'setLoading', args: state});
                Bus.$emit('navbar', {command: 'showButtons', args: {save: true}});
            },
            clickSave() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: this.nbi.url.current + '/save',
                    obj: {table: this.local.table},
                    flash: true,
                    suffix: '_save',
                    // messOk: 'сохранено',
                    // messError: 'ошибка привязки аккаунта',
                });
                Bus.$once('zResponse_save', response => {
                    this.setLoading(false);
                });
                Bus.$once('zError_save', () => {
                    this.setLoading(false);
                });
            },
        },
        computed: {
            filteredTable: function () {
                return this.local.table.filter(item => {
                    let res = true;
                    this.search.split(' ').forEach(value => {
                        let s = item.name1 + item.name3;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
        watch: {},
        filters: {},
        directives: {}
    }
</script>

<style lang="scss" scoped>
    /*.fas.fa-check {*/
    /*    opacity: 0.3;*/

    /*    &:hover {*/
    /*        cursor: pointer;*/
    /*    }*/

    /*    &.active {*/
    /*        opacity: 1;*/
    /*        color: green;*/
    /*    }*/
    /*}*/

    /*tr.active {*/
    /*    background-color: rgba(0, 250, 154, 0.2);*/
    /*}*/
</style>
