<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12"><input class="form-control w-100 mb-3" v-model="state.search.val"></div>
                    </div>
                    <button class="btn btn-primary" @click="clickReset">reset</button>

                    <div class="row zag">
                        <div class="col-5">
                            <div class="row">
                                <div class="col-2">позиция</div>
                                <div class="col-10">название</div>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="row">
                                <div class="col-3">марка</div>
                                <div class="col-3">артикул</div>
                                <div class="col-2">поставщик</div>
                                <div class="col-1">ед-изм</div>
                                <div class="col-1">кол-во</div>
                                <div class="col-2">примечение</div>
                            </div>
                        </div>
                    </div>

                    <nested-specification class="item-sub" v-model="local.table" :nbi="nbi" :local="local"/>

                </div>
            </div>
        </div>
        <modal-so modal-id="modalSo" :p-local="local" :p-state="state" @updInit="init" @selectObj="onSelectObjFromBase"/>
        <context-menu-my v-if="isMounted" ref="menu" :items="items"/>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import nestedList from "./sub/nestedList";
    import moment from "moment";
    import {eTable, page, toolM} from "./sub/tools";
    import ModalBase from "./sub/ModalBase";
    import nestedSo from "./sub/nestedSo";
    import nestedSpecification from "./sub/nestedSpecification";
    import ContextMenuMy from "./sub/ContextMenuMy";
    import ModalSo from "./sub/ModalSo";

    export default {
        name: "page-cable-journal",
        components: {Multiselect, nestedList, ModalBase, nestedSpecification, ContextMenuMy, ModalSo},
        props: ['table', 'base'],
        data: function () {
            return {
                pageT: new page(this),
                toolM: new toolM(this),
                eTable: new eTable(this),
                nbi: {},
                local: {
                    selectArr: [],
                    saveArr: [],
                    table: this.table,
                    base: this.base,
                },
                status: {
                    loading: false,
                },
                state: {
                    version: 3,
                    activeRowIndex: 0,
                    activeRow: {},
                    activeCategory: {},
                    search: {
                        val: ''
                    },
                },
                items: [
                    {name: 'переключить видимость', link: 'hide'},
                    {name: '---'},
                    {name: 'переключить видимость щита', link: 'hideRu'},
                ],
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'insert') this.showModalInsert();
                if (e.key === 'ctrlS') this.clickSave();
                if (e.key === 113) this.showModal();
            })
            Bus.$on('keyDown', e => {
                if (e.key === 40) this.keyDown()//Down
                if (e.key === 38) this.keyUp()//Up
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
            Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
            Bus.$on('dotSelect', () => this.eTable.onDotSelect());
            Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
            Bus.$on('dotInverse', () => this.eTable.onDotInverse());
            Bus.$on('onShowContextMenu', obj => this.$refs[obj.refs].open(obj.e, obj.el));
            Bus.$on('contextMenu_menu', (o) => {
                if (o.link === 'hide') this.onHide(o.obj);
                if (o.link === 'hideRu') this.onHideAll(o.obj);
            });
            Bus.$on('clickRow', (obj) => this.eTable.clickRow(obj));

        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload: true, save: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            this.state.activeRow = this.local.table[0];
            this.local.selectArr = [];
            if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
            this.isMounted = true;
            // setTimeout(() => this.showModal(), 200);
        },
        methods: {
            keyUp() {
                // let index = this.local.table.findIndex(item => item.id === this.lastSelectObj.id);
                // if (index <= 0) return;
                //
                // //todo js delete Array element
                // let key = this.local.selectArr.findIndex(item => item.id === this.lastSelectObj.id);
                // if (key < 0) return;
                // this.local.selectArr.splice(key, 1);
                //
                // this.local.selectArr.push(this.local.table[index - 1]);
            },
            keyDown() {
                // let index = this.local.table.findIndex(item => item.id === this.lastSelectObj.id);
                // if (index + 1 < this.local.table.length) return;
                //
                // //todo js delete Array element
                // let key = this.local.selectArr.findIndex(item => item.id === this.lastSelectObj.id);
                // if (key < 0) return;
                // this.local.selectArr.splice(key, 1);
                //
                // this.local.selectArr.push(this.local.table[index + 1]);
            },
            showModalInsert(obj) {
                Bus.$emit('showI_modalSo')
            },
            onSelectObjFromBase(obj) {
                this.local.selectArr.forEach(item => {
                    item.base_id = obj.id;
                    item.code = obj.code;
                    item.edizm = obj.edizm;
                    item.firma = obj.firma;
                    item.marka = obj.marka;
                    item.name = obj.namer1;
                    if (obj.dop.x) item.dop.x = obj.dop.x;
                    if (obj.dop.y) item.dop.y = obj.dop.y;
                    if (obj.dop.z) item.dop.z = obj.dop.z;
                    this.local.saveArr.push(item);
                })
            },
            onHide(obj) {
                obj.is_visible = !obj.is_visible;
            },
            onHideAll(obj) {
                let parent = obj;
                if (!obj.isRu) parent = this.local.table.find(item => item.id === obj.parent_id);

                parent.is_visible = !parent.is_visible;
                parent.child.forEach(item => item.is_visible = parent.is_visible);
            },
            clickDot(type) {
                if (type === 'select') Bus.$emit('dotSelect')
                else if (type === 'un_select') Bus.$emit('dotUnSelect')
                else if (type === 'inverse') Bus.$emit('dotInverse')
            },
            init() {
                let res = [];
                this.local.table.forEach(item => {
                    let l = this.local.selectArr.find(item2 => item2.id === item.id);
                    if (l) res.push(l); else res.push(item);
                })
                this.local.table = res;
            },
            //todo js multiselect custom search
            customSearch(query) {
                if (query === '') {
                    this.local.category = this.category;
                }
                let i = 0;
                this.local.category = this.category.filter(item => {
                    let res = true;
                    query.split(' ').forEach(value => {
                        let s = item.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
            showModal() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('show_modalSo')
            },
            clickSave() {
                Bus.$emit('localSave');
                this.pageT.clickSave('/marka_element/save', {table: this.local.table, save_arr: this.local.saveArr}, '_save');
                Bus.$once('zResponse_save', response => {
                    this.local.saveArr.length = 0;
                    this.local.table.length = 0;
                    response.table.forEach(item => this.local.table.push(item));
                    // Bus.$emit('updTable');
                    // Bus.$emit('updSelectArr');
                });
            },
            clickReset() {
                location.href = '/marka_element/reset';
            },
        },
        computed: {
            lastSelectObj: function () {
                if (this.local.selectArr.length === 0) return null;
                return this.local.selectArr[this.local.selectArr.length - 1];
            },
            fTable: function () {
                if (this.state.search.val === '') return this.local.table;
                return this.local.table.filter(item => {
                    let res = true;
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
        watch: {
            'fTable': {
                handler: function (newVal, oldVal) {
                    this.eTable.clearSelectArr();
                },
                deep: false
            },
        },
        filters: {},
        directives: {}
    }
</script>

<style lang="scss" scoped>
    @import 'resources/sass/variables';

    .noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .row {
        padding: 0;
        margin: 0.1rem 0;

        .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
            text-align: center;
            padding: 0;
        }

        .zag {
            font-weight: bold;
            text-align: center;
            vertical-align: middle;
            border: 1px solid $primary;
            background-color: lighten($primary, 20%);
        }
    }

    .row-w {
        border-bottom: 1px solid #959595;
        cursor: pointer;
    }

    span.small {
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }

        font-style: italic;
    }

    table.table.in_td {
        /*td{*/
        /*    text-align: center;*/
        /*    vertical-align: middle;*/
        /*}*/
        th {
            border: none;
            background: none;
            padding: 0;
        }

        tr {
            border: none;
            background: none;
            height: 0px;

            &.hover:hover {
                background-color: lighten($primary, 20%);
            }
        }

        tbody tr:first-child {
            td {
                border: none;
            }
        }

        .bg-primary-l {
            background-color: lighten($primary, 40);
        }

        .bg-success-l {
            background-color: lighten($success, 40);
        }
    }

</style>
