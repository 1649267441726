var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { id: _vm.modalId, role: "dialog" },
      on: { click: _vm.closeModal },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              { staticClass: "modal-header" },
              [
                _vm.state.isEdit
                  ? _c("span", [_vm._v("редактировать")])
                  : _c("span", [_vm._v("вставить документ")]),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  staticClass: "text-danger mt-1",
                  staticStyle: { cursor: "pointer" },
                  attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                  on: { click: _vm.closeModal },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("multiselect", {
                  attrs: {
                    placeholder: "выберите норматив",
                    options: _vm.local.fTable,
                    "option-height": 104,
                    "show-labels": false,
                    searchable: true,
                    "close-on-select": true,
                    "track-by": "id",
                    "internal-search": false,
                    "tag-placeholder": "создать новый",
                    taggable: true,
                  },
                  on: {
                    "search-change": _vm.customSearch,
                    tag: _vm.addNewNorm,
                    input: _vm.onChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function (props) {
                        return [
                          _c("span", { staticClass: "option__desc" }, [
                            _c(
                              "span",
                              { staticClass: "option__title" },
                              [
                                props.option.type === "norm"
                                  ? _c("font-awesome-icon", {
                                      staticClass: "handle",
                                      attrs: { icon: "fa-solid fa-file" },
                                    })
                                  : _c(
                                      "font-awesome-layers",
                                      { staticClass: "handle" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "fa-regular fa-file" },
                                        }),
                                        _vm._v(" "),
                                        _c("font-awesome-icon", {
                                          staticStyle: { color: "#ee9b00" },
                                          attrs: {
                                            icon: "fa-solid fa-link",
                                            transform:
                                              "shrink-6 down-1 right-1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(props.option.name) +
                                    "\n                                        "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          _c("div", { staticClass: "option__desc" }, [
                            props.option.name
                              ? _c(
                                  "span",
                                  { staticClass: "option__title" },
                                  [
                                    props.option.type === "norm"
                                      ? _c("font-awesome-icon", {
                                          staticClass: "handle",
                                          attrs: { icon: "fa-solid fa-file" },
                                        })
                                      : _c(
                                          "font-awesome-layers",
                                          { staticClass: "handle" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: "fa-regular fa-file",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              staticStyle: { color: "#ee9b00" },
                                              attrs: {
                                                icon: "fa-solid fa-link",
                                                transform:
                                                  "shrink-6 down-1 right-1",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(props.option.name) +
                                        "\n                                        "
                                    ),
                                  ],
                                  1
                                )
                              : _c("span", { staticClass: "option__title" }, [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(props.search) +
                                      "\n                                        "
                                  ),
                                ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "option__small small" }, [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(props.option.desc) +
                                  "\n                                        "
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.state.obj,
                    callback: function ($$v) {
                      _vm.$set(_vm.state, "obj", $$v)
                    },
                    expression: "state.obj",
                  },
                }),
                _vm._v(" "),
                !!_vm.state.obj && _vm.state.isEdit
                  ? _c("div", [
                      _c("label", { staticClass: "w-100" }, [
                        _c("span", { staticClass: "small" }, [
                          _vm._v("документ"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.state.obj.name,
                              expression: "state.obj.name",
                            },
                          ],
                          ref: "v_focus",
                          staticClass: "form-control",
                          attrs: { placeholder: "название документа" },
                          domProps: { value: _vm.state.obj.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.state.obj,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("label", { staticClass: "w-100" }, [
                        _c("span", { staticClass: "small" }, [
                          _vm._v("название документа"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.state.obj.desc,
                              expression: "state.obj.desc",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { placeholder: "полное название документа" },
                          domProps: { value: _vm.state.obj.desc },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.state.obj,
                                "desc",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "modal-footer d-flex flex-row justify-content-between",
              },
              [
                _c("div", [
                  !!_vm.state.obj && _vm.state.obj.type === "local"
                    ? _c("label", { staticClass: "apple-switch_2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.state.isEdit,
                              expression: "state.isEdit",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.state.isEdit)
                              ? _vm._i(_vm.state.isEdit, null) > -1
                              : _vm.state.isEdit,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.state.isEdit,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.state,
                                      "isEdit",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.state,
                                      "isEdit",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.state, "isEdit", $$c)
                              }
                            },
                          },
                        }),
                        _c("i"),
                        _vm._v("редактировать"),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm.state.isEdit
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button" },
                          on: { click: _vm.clickDelete },
                        },
                        [_vm._v("удалить")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.state.isEdit
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.clickSave(null)
                            },
                          },
                        },
                        [_vm._v("сохранить")]
                      )
                    : !!_vm.state.obj
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: { click: _vm.clickInsert },
                        },
                        [_vm._v("вставить")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("закрыть")]
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }