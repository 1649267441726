<template>
    <div>
        <div class="card-body">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" class="w-1">щит</th>
                    <th scope="col" class="w-1">группа</th>
                    <th scope="col" class="w-1">пом.</th>
                    <th scope="col" class="w-5">name1</th>
                    <th scope="col" class="w-4">name2</th>
                    <th scope="col" class="w-1">количество</th>
                    <th scope="col" class="w-1">Ру</th>
                    <th scope="col" class="w-1">cos</th>
                    <th scope="col" class="w-1">длина</th>
                    <th scope="col" class="w-0"></th>
                </tr>
                </thead>
                <template v-for="val in fTable">
                    <tr :key="val.id" @click.ctrl.prevent="eTable.clickCtrlRow(val)"
                        :class="{'bg-primary-l':eTable.isSelect(val),'bg-danger-l':val.room.id===''}"
                        @click.exact="eTable.clickRow(val)"
                    >
                        <td class="">{{val.panel.name}}</td>
                        <td class="">{{val['section'].name}}</td>
                        <td class="truncate text-left">{{val.room.name}}</td>
                        <td class="text-left">{{val.name1}} / {{val.name3}}</td>
                        <td class="text-left">{{val.name2}}</td>
                        <td><span v-if="val.es.kolvo>1">х{{val.es.kolvo}}</span></td>
                        <td>{{val.es.pu}}</td>
                        <td>{{val.es.cos}}</td>
                        <td>{{val.cable_length.all}}</td>
                        <td @click="eTable.copyClipBoard(val)" title="копирование координат в буфер обмена">
                            <font-awesome-icon icon="fa-solid fa-location-crosshairs"/>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
        <modal-potr modal-id="modalPotr2" :p-local="local" @updInit="init"/>
    </div>
</template>
<script>

    import ModalPotr from "./sub/ModalPotr";
    import {eTable} from "./sub/tools";


    export default {
        name: 'e-potr2',
        components: {ModalPotr},
        props: {
            pLocal: {},
            pState: {}
        },
        data: function () {
            return {
                eTable: new eTable(this),
                local: {
                    table: [],
                    selectArr: [],
                    fListImportPotr: this.pLocal.listImportPotr,
                    ePotr: this.pLocal.ePotr,
                    listName3: this.pLocal.listName3,
                    listAvt: this.pLocal.listAvt,
                    listKab: this.pLocal.listKab,
                },
                state: {
                    search: this.pState.search,
                },
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                // if (e.key === 'insert') this.showModalInsert();
                if (e.key === 113) this.showModal();
            });
            Bus.$on('updTable', () => this.init());
            Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
            Bus.$on('dotSelect', () => this.eTable.onDotSelect());
            Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
            Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        },
        mounted() {
            this.init();
            this.local.selectArr = [];
            if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
            setTimeout(() => this.$refs.search.focus(), 200);
        },
        methods: {
            showModal() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('show_modalPotr2');
            },
            showModalInsert() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('showI_modalPotr2');
            },
            init() {
                let res = [];
                res = this.pLocal.ePotr;
                res.sort((a, b) => {
                    if (a.room.name === b.room.name) {
                        if (a.name1 === b.name1) return 0;
                        return a.name1 > b.name1 ? 1 : -1;
                    }
                    return a.room.name > b.room.name ? 1 : -1;
                });

                this.local.table = res;
            },
        },
        computed: {
            fTable: function () {
                if (this.state.search.val === '') return this.local.table;
                return this.local.table.filter(item => {
                    let res = true;
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name2 + item.name1 + item.panel.name + item.section.name + item.room.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
        watch: {
            'fTable': {
                handler: function (newVal, oldVal) {
                    this.eTable.clearSelectArr();
                },
                deep: false
            },
        }
    }
</script>

<style scoped lang="scss">
    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
