<template>
    <div>
        <div class="container-fluid m1">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">
                    <h1 class="mb-0">Глобальные переменные</h1>
                </div>
            </div>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">



                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swImportW=!state.swImportW" :class="{active:state.swImportW}">переменые</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                </div>
            </div>

            <div class="row justify-content-center">

                <variables-div :local="local" :class="{'col-12':!state.swImportW,'col-xl-8':!state.swImportW,'col-6':state.swImportW}" :is-global="true"/>

                <import-fields v-show="state.swImportW" class="col-6" modal-id="modalImportFields" :is-show="state.swImportW"/>

            </div>


        </div>
        <modal-es-ru modal-id="modalEsRu"/>
    </div>
</template>

<script>
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import {page} from "./sub/tools";
    import importFields from "./sub/importFields";
    import uniqid from 'uniqid';
    import ModalEsRu from "./sub/ModalEsRu";
    import VariablesDiv from "./sub/variablesDiv";

    export default {
        name: "page-global-variables",
        components: {VariablesDiv, importFields, ModalEsRu},
        props: ['table'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                local: {
                    fields: this.table,
                },
                status: {
                    loading: false,
                },
                state: {
                    version: 2,
                    swImportW: false,
                },
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.clickSave();
                if (e.key === 'download') this.pageT.clickDownload();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
            Bus.$on('removeRow', obj => this.onRemoveRow(obj));
            Bus.$on('addRow', () => this.onAddRow());
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {save: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            this.isMounted = true;
        },
        methods: {
            clickSave() {
                this.pageT.clickSave('/global_variables/save', {table: this.local.fields}, '_save');
                Bus.$once('zResponse_save', response => {
                    this.local.fields = response.table;
                });
            },
            onAddRow() {
                this.pageT.clickSave('/global_variables/add_row', {}, '_add_row');
                Bus.$once('zResponse_add_row', response => {
                    this.local.fields = response.table;
                });
            },
            onRemoveRow(obj) {
                this.pageT.clickSave('/global_variables/remove_row', {obj: obj}, '_remove_row');
                Bus.$once('zResponse_remove_row', response => {
                    this.local.fields = response.table;
                });
            },
        },
        computed: {},
        watch: {
            'state.swImportW'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        }

    }
</script>


<style scoped lang="scss">
    @import 'resources/sass/variables';

</style>
