var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "card-body" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.state.search.val,
              expression: "state.search.val",
              modifiers: { trim: true },
            },
          ],
          ref: "search",
          staticClass: "form-control mb-2",
          domProps: { value: _vm.state.search.val },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.state.search, "val", $event.target.value.trim())
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "table table-sm" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.fTable, function (val) {
              return [
                _c("tr", { key: val.id, staticClass: "mark" }, [
                  _c("td", [
                    _vm._v(
                      _vm._s(val.marker_tag) + "/" + _vm._s(val.explication_tag)
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.clickCopyNumberForChild(val)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "fa-solid fa-angle-right" },
                        on: {
                          click: function ($event) {
                            return _vm.clickCopyNumberForChild(val)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(val.name))])]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(val.otm))])]),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(val.kabl))])]),
                  _vm._v(" "),
                  _c("td"),
                ]),
                _vm._v(" "),
                _c(
                  "draggable",
                  {
                    attrs: {
                      list: val.child,
                      tag: "tbody",
                      "ghost-class": "ghost",
                      disabled: _vm.pState.isEdit,
                      "scroll-sensitivity": 100,
                      "fallback-tolerance": 1,
                      "force-fallback": true,
                    },
                  },
                  _vm._l(_vm.fTableChild(val.child), function (el) {
                    return _c(
                      "tr",
                      {
                        key: el.id,
                        class: {
                          "bg-primary-l": _vm.eTable.isSelect(el),
                          "bg-danger-l": el.area.id === "",
                        },
                        on: {
                          click: [
                            function ($event) {
                              if (!$event.ctrlKey) return null
                              $event.preventDefault()
                              return _vm.eTable.clickCtrlRow(el)
                            },
                            function ($event) {
                              if (
                                $event.ctrlKey ||
                                $event.shiftKey ||
                                $event.altKey ||
                                $event.metaKey
                              )
                                return null
                              return _vm.eTable.clickRow(el)
                            },
                          ],
                        },
                      },
                      [
                        _c("td", { attrs: { scope: "row" } }, [
                          !_vm.pState.isEdit
                            ? _c("span", [_vm._v(_vm._s(el.number))])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: el.number,
                                    expression: "el.number",
                                  },
                                ],
                                staticClass: "form-control1",
                                domProps: { value: el.number },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(el, "number", $event.target.value)
                                  },
                                },
                              }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            on: {
                              click: function ($event) {
                                el.name = el.number
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "fa-solid fa-angle-right" },
                              on: {
                                click: function ($event) {
                                  el.name = el.number
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          !_vm.pState.isEdit
                            ? _c("span", [
                                _vm._v(_vm._s(el.name)),
                                el.area.id === ""
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bold text-danger",
                                      },
                                      [_vm._v(" Область не найдена")]
                                    )
                                  : _vm._e(),
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: el.name,
                                    expression: "el.name",
                                  },
                                ],
                                staticClass: "form-control1",
                                domProps: { value: el.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(el, "name", $event.target.value)
                                  },
                                },
                              }),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticStyle: { opacity: "0.3" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickAll(el)
                                },
                              },
                            },
                            [_vm._v("all")]
                          ),
                          _vm._v(" " + _vm._s(el.zone.name)),
                        ]),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            attrs: {
                              title: "копирование координат в буфер обмена",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.eTable.copyClipBoard(el)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: "fa-solid fa-location-crosshairs",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("modal-room", {
        attrs: { "modal-id": "modalRoom0", "p-local": _vm.local },
        on: { updInit: _vm.init },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("номер"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("копия"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-5", attrs: { scope: "col" } }, [
          _vm._v("название"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-5", attrs: { scope: "col" } }, [
          _vm._v("зона"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("отметка"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("кабель"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w0", attrs: { scope: "col" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }