var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 ddiv", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "card h-100" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "d-flex justify-content-between mb-3" }, [
            _c(
              "div",
              {
                staticClass: "w-100 mr-2",
                staticStyle: { position: "relative" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  ref: "v_focus",
                  staticClass: "form-control w-100",
                  domProps: { value: _vm.search },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  attrs: {
                    icon: "fa-solid fa-eraser",
                    size: "lg",
                    title: "очистить",
                  },
                  on: {
                    click: function ($event) {
                      _vm.search = ""
                    },
                  },
                }),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  attrs: {
                    icon: "fa-solid fa-arrow-right-arrow-left",
                    size: "lg",
                  },
                  on: { click: _vm.clickSyncSearch },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("font-awesome-icon", {
                  staticClass: "mt-2",
                  staticStyle: { cursor: "pointer" },
                  attrs: { icon: "fa-solid fa-magnifying-glass", size: "lg" },
                  on: { click: _vm.clickSearch },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          this.local.table.length === 0
            ? _c(
                "div",
                { staticClass: "text-center p-5" },
                [
                  true
                    ? _c("font-awesome-icon", {
                        staticClass: "mt-1 text-secondary",
                        attrs: {
                          icon: "fa-solid fa-circle-notch",
                          size: "3x",
                          title: "загрузка данных...",
                          spin: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.filteredTable, function (val, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "card my-1 myRow",
                  class: {
                    selected:
                      _vm.currentObj !== null && _vm.currentObj.id === val.id,
                  },
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-start align-items-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "nav-item dropdown m-0" },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "nav-link dropdown-toggle pl-0",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                icon: "fa-regular fa-square-caret-down",
                                "data-toggle": "dropdown",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "dropdown-menu" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "dropdown-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickMenu(val, "delPage")
                                    },
                                  },
                                },
                                [_vm._v("удалить страницу")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              href:
                                val.link_content.link +
                                "/" +
                                _vm.nbi.authUser.prop.currentProjectId +
                                "/" +
                                val.id,
                            },
                          },
                          [_vm._v(_vm._s(val.stamp.list_name))]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ml-auto" },
                          [
                            _vm.currentObj !== null &&
                            _vm.currentObj.id === val.id
                              ? _c("font-awesome-icon", {
                                  attrs: { icon: "fa-solid fa-circle-check" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickCurrentId(null)
                                    },
                                  },
                                })
                              : _c("font-awesome-icon", {
                                  attrs: { icon: "fa-solid fa-circle" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickCurrentId(val)
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "btn",
                        attrs: {
                          "data-toggle": "collapse",
                          "data-target": "#collapse_" + val.id,
                        },
                      },
                      [_vm._v("показать")]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "collapse",
                      class: { show: !_vm.isFold(val) },
                      attrs: { id: "collapse_" + val.id },
                      domProps: { innerHTML: _vm._s(val.html) },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }