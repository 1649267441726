<template>
    <div>
        <div v-if="ttype==='1'">
            <span v-for="(el,index) in local.listCat"><span v-if="index>0">></span> {{ el.name }}</span>
        </div>
        <div v-if="ttype==='2' && isPsGlobal">
            <span style="cursor: pointer" class="badge bg-info" @click="clickCategory('')">{{ category }}</span>
        </div>
        <div v-if="(ttype==='2'||ttype==='3') && !isPsGlobal">
            <span v-if="category!==''" style="cursor: pointer" @click="clickCategory('')">все</span>
            <span v-for="(el,index) in local.listCat" style="cursor: pointer" @click="clickCategory(el.id)"> <span v-if="index>0">></span> {{ el.name }}</span>
        </div>
        <div v-if="ttype==='4' && !isPsGlobal">
            <span style="cursor: pointer" @click="clickCategory('')">все
                <button type="button" class="btn btn-sm" @click.stop.prevent="onShowContext($event,{id:0})">...</button>
            </span>

            <span v-for="(el,index) in local.listCat" style="cursor: pointer">
                <span @click="clickCategory(el.id)">{{ el.name }}</span>
                <button v-if="isChild(el.id)" type="button" class="btn btn-sm" @click.stop.prevent="onShowContext($event,el)">....</button>
            </span>
        </div>
        <context-menu-my v-if="ttype==='4'" ref="menu" :items="items" id-menu="ccs"/>
    </div>
</template>
<script>
import ContextMenuMy from "./ContextMenuMy.vue";

export default {
    name: 'c-category-string',
    components: {ContextMenuMy},
    props: {
        listCategory: {type: Array, default: null,},
        cObj: {type: Object, default: null,},
        ttype: {type: String, default: '',},
        category: {type: String, default: '',},
        isPsGlobal: {type: Boolean, default: false}
    },
    data() {
        return {
            local: {
                listCat: [],
                listChild: [],
            },
            items: [
                {name: '---'},
            ],
        }
    },
    created: function () {
        Bus.$on('onShowContextMenu_ccs', obj => {
            if (this.$refs[obj.refs] === undefined) return;
            this.$refs[obj.refs].open(obj.e, obj.el)
        });
        Bus.$on('contextMenu_menu', (o) => {
            this.clickCategory(o.link);
        });
    },
    mounted() {
        this.upd();
    },
    methods: {
        onShowContext(e, el) {
            this.items = [];
            this.setChildCategory(el.id);
            this.items.sort((a, b) => {
                if (a.npp > b.npp) return 1;
                if (a.npp === b.npp) return 0;
                if (a.npp < b.npp) return -1;
            });
            Bus.$emit('onShowContextMenu_ccs', {e: e, el: el, refs: 'menu'});
        },
        isChild(id) {
            let index = this.listCategory.findIndex(item => item.id_parent === id);
            return index > -1;
        },
        setChildCategory(id) {
            this.listCategory.forEach(item => {
                if (item.id_parent === id) this.items.push({name: item.name, link: item.id, npp: item.npp})
            })
        },
        clickCategory(id) {
            this.$emit('setCategory', id);
            if (this.ttype === '4') {
                this.cObj.prop.info.category = id;
                this.local.listCat = [];
                this.upd();
            }
        },
        setCategory() {
            // if (this.cObj.category !== undefined) {
            //
            // }
        },
        upd() {
            this.setCategory();
            if (this.ttype === '1') {
                if (!this.isPS()) {
                    if (this.cObj !== null) this.recursList(parseInt(this.cObj.category));
                } else {
                    this.local.listCat.push({name: this.cObj.category, id: null})
                }
            } else if (this.ttype === '2') {
                if (!this.isPsGlobal) {
                    this.recursList(parseInt(this.category));
                }
            } else if (this.ttype === '3') {
                if (!this.isPS()) {
                    if (this.cObj !== null) this.recursList(parseInt(this.cObj.prop.info.category));
                } else {
                    this.local.listCat.push({name: this.cObj.category, id: null})
                }
            } else if (this.ttype === '4') {
                if (this.cObj !== null) this.recursList(parseInt(this.cObj.prop.info.category));
            }
        },
        isPS() {
            if (this.cObj === null) return false;
            return this.getTwoChars(this.cObj.uid) === 'ps';
        },
        getTwoChars(uid) {
            if (!uid) return '';
            return String(uid).slice(0, 2);
        },
        recursList(id) {
            if (this.listCategory === null) return;
            let o = this.listCategory.find(item => item.id === id);
            if (o) {
                this.local.listCat.unshift({name: o.name, id: o.id})
                if (o.id_parent > 0) this.recursList(o.id_parent);
            }
        },
    },
    computed: {},
    watch: {
        'category': {
            handler: function (newVal, oldVal) {
                if (this.ttype === '2') {
                    this.local.listCat = [];
                    this.upd();
                }
            },
            deep: false
        },
        'cObj': {
            handler: function (newVal, oldVal) {
                this.local.listCat = [];
                this.upd();
            },
            deep: false
        },
    }
}
</script>
