var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-fluid m1" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("ul", { staticClass: "nav nav-tabs mt-4" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 2 },
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(2)
                      },
                    },
                  },
                  [_vm._v("Щиты")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 3 },
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(3)
                      },
                    },
                  },
                  [_vm._v("Автоматы")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 4 },
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(4)
                      },
                    },
                  },
                  [_vm._v("Потребители")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 5 },
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(5)
                      },
                    },
                  },
                  [_vm._v("Выключатели")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 6 },
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(6)
                      },
                    },
                  },
                  [_vm._v("Дополнительно")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 7 },
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(7)
                      },
                    },
                  },
                  [_vm._v("Кабели")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tab-content" }, [
              _c(
                "div",
                {
                  staticClass: "tab-pane container-fluid",
                  class: { active: _vm.state.activeTab === 2 },
                },
                [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.isMounted
                          ? _c("marka_e-panel", {
                              attrs: {
                                "p-local": _vm.local,
                                "p-state": _vm.state,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane container-fluid",
                  class: { active: _vm.state.activeTab === 3 },
                },
                [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.isMounted
                          ? _c("marka_e-avt", {
                              attrs: {
                                "p-local": _vm.local,
                                "p-state": _vm.state,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane container-fluid",
                  class: { active: _vm.state.activeTab === 4 },
                },
                [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.isMounted
                          ? _c("marka_e-potr", {
                              attrs: {
                                "p-local": _vm.local,
                                "p-state": _vm.state,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane container-fluid",
                  class: { active: _vm.state.activeTab === 5 },
                },
                [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.isMounted
                          ? _c("marka_e-vykl", {
                              attrs: {
                                "p-local": _vm.local,
                                "p-state": _vm.state,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane container-fluid",
                  class: { active: _vm.state.activeTab === 6 },
                },
                [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.isMounted
                          ? _c("marka_e-dop", {
                              attrs: {
                                "p-local": _vm.local,
                                "p-state": _vm.state,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane container-fluid",
                  class: { active: _vm.state.activeTab === 7 },
                },
                [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.isMounted
                          ? _c("marka_e-section", {
                              attrs: {
                                "p-local": _vm.local,
                                "p-state": _vm.state,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-so", {
        attrs: {
          "modal-id": "modalSo1",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "marka_new",
        },
      }),
      _vm._v(" "),
      _c("modal-so-info", {
        attrs: {
          "modal-id": "modalSoInfo",
          "p-local": _vm.local,
          "prod-obj": {},
          marka: "marka_new",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }