<template>
    <div>
        <div class="card-body">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" class="w-2">тип</th>
                    <th scope="col" class="w-2">видимость</th>
                    <th scope="col" class="w-5">name1</th>
                    <th scope="col" class="w-5">name2</th>
                    <th scope="col" class="w-5">name3</th>
                    <th scope="col" class="w-1">количество</th>
                    <th scope="col" class="w-1">Ру</th>
                    <th scope="col" class="w-1">cos</th>
                    <th scope="col" class="w-1">отметка</th>
                    <th scope="col" class="w-1">запас</th>
                </tr>
                </thead>
                <template v-for="val in fTable">
                    <tr :key="val.id" @click.ctrl.prevent="eTable.clickCtrlRow(val)" :class="{'bg-primary-l':eTable.isSelect(val)}" @click.exact="eTable.clickRow(val)">
                        <td>{{val.type}}</td>
                        <td>{{val.vid}}</td>
                        <td class="text-left">{{val.name1}}</td>
                        <td class="">
                            <span class="d-flex justify-content-between">
                                <span>{{val.name2}}</span>
                                <span class="text-danger">{{getFireStatus(val)}}</span>
                            </span>
                        </td>
                        <td class="text-left">{{val.name3}}</td>
                        <td>{{val.child.length}}</td>
                        <td>{{val.es.pu}}</td>
                        <td>{{val.es.cos}}</td>
                        <td>{{val.dop.otm}}</td>
                        <td>{{val.dop.dd}}</td>
                    </tr>
                </template>
            </table>
        </div>
        <modal-potr modal-id="modalPotr0" :p-local="local" @updInit="init"/>
    </div>
</template>
<script>

    import uniqid from 'uniqid';
    import ModalPotr from "./sub/ModalPotr";
    import {eTable, page} from "./sub/tools";

    export default {
        name: 'e-potr0',
        components: {ModalPotr},
        props: {
            pLocal: {},
            pState: {}
        },
        data: function () {
            return {
                pageT: new page(this),
                eTable: new eTable(this),
                local: {
                    table: [],
                    selectArr: [],
                    fListImportPotr: this.pLocal.listImportPotr,
                    ePotr: this.pLocal.ePotr,
                    listName3: this.pLocal.listName3,
                    listAvt: this.pLocal.listAvt,
                    listKab: this.pLocal.listKab,
                },
                state: {
                    search: this.pState.search,
                },
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                // if (e.key === 'insert') this.showModalInsert();
                if (e.key === 113) this.showModal();
            });
            Bus.$on('updTable', () => this.init());
            Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
            Bus.$on('dotSelect', () => this.eTable.onDotSelect());
            Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
            Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        },
        mounted() {
            this.init();
            this.local.selectArr = [];
            if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
            setTimeout(() => this.$refs.search.focus(), 200);
        },
        methods: {
            getFireStatus(obj) {
                if (obj.panel.nrr && !obj.panel.arr) return 'OFF';
                else if (!obj.panel.nrr && obj.panel.arr) return 'ON';
                else if (!obj.panel.nrr && !obj.panel.arr) return 'резерв';
                else return '';
            },
            showModal() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('show_modalPotr0');
            },
            showModalInsert() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('showI_modalPotr0');
            },
            init() {
                let res = [];
                this.pLocal.ePotr.forEach(item => {
                    let p = res.find(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid &&
                        item2.es.pu === item.es.pu && item2.es.cos === item.es.cos && item2.dop.otm === item.dop.otm && item2.dop.dd === item.dop.dd
                        && item2.panel.nrr === item.panel.nrr && item2.panel.arr === item.panel.arr);
                    if (!p) {
                        let o = {
                            id: uniqid(),
                            type: item.type,
                            vid: item.vid,
                            name1: item.name1,
                            name2: item.name2,
                            name3: item.name3,
                            es: {
                                pu: item.es.pu,
                                cos: item.es.cos,
                            },
                            dop: {
                                otm: item.dop.otm,
                                dd: item.dop.dd,
                            },
                            panel: {
                                nrr: item.panel.nrr,
                                arr: item.panel.arr,
                            },
                            child: this.pLocal.ePotr.filter(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid
                                === item.vid && item2.es.pu === item.es.pu && item2.es.cos === item.es.cos && item2.dop.otm === item.dop.otm && item2.dop.dd === item.dop.dd
                                && item2.panel.nrr === item.panel.nrr && item2.panel.arr === item.panel.arr),
                        };
                        res.push(o);
                    }
                })

                res.sort((a, b) => {
                    if (a.type === b.type) {
                        if (a.vid === b.vid) return 0;
                        return a.vid > b.vid ? 1 : -1;
                    }
                    return a.type > b.type ? 1 : -1;
                });

                this.local.table = res;
            },
        },
        computed: {
            fTable: function () {
                if (this.state.search.val === '') return this.local.table;
                return this.local.table.filter(item => {
                    let res = true;
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name2 + item.name1 + item.name3 + item.vid;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
        watch: {
            'fTable': {
                handler: function (newVal, oldVal) {
                    this.eTable.clearSelectArr();
                },
                deep: false
            },
        }
    }
</script>
