var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "container-fluid bg-white",
          staticStyle: { "margin-top": "3rem", "padding-top": "3rem" },
        },
        [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-12 col-xl-10" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.state.search.val,
                        expression: "state.search.val",
                      },
                    ],
                    staticClass: "form-control w-100 mb-3",
                    domProps: { value: _vm.state.search.val },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.state.search, "val", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _vm.isMounted
                  ? _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.local.category,
                            "show-labels": false,
                            searchable: true,
                            "close-on-select": true,
                            "allow-empty": false,
                            "internal-search": false,
                          },
                          on: {
                            "search-change": _vm.customSearch,
                            input: _vm.onChange,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "singleLabel",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "option__desc" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "option__title" },
                                          [_vm._v(_vm._s(props.option.name))]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "option",
                                fn: function (props) {
                                  return [
                                    _c("div", { staticClass: "option__desc" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "option__title",
                                          style: {
                                            "padding-left":
                                              props.option.level + "rem",
                                          },
                                        },
                                        [_vm._v(_vm._s(props.option.name))]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1236036848
                          ),
                          model: {
                            value: _vm.state.activeCategory,
                            callback: function ($$v) {
                              _vm.$set(_vm.state, "activeCategory", $$v)
                            },
                            expression: "state.activeCategory",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isMounted
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      _vm._l(_vm.state.filterFields, function (el) {
                        return _c(
                          "span",
                          {
                            key: el.id,
                            staticClass: "mr-2",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.clickSwFilterField(el)
                              },
                            },
                          },
                          [_vm._v(_vm._s(el.name) + ":" + _vm._s(el.val))]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isMounted
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm._l(_vm.fix_field, function (el) {
                          return _c(
                            "span",
                            {
                              key: el.id,
                              staticClass: "mr-2",
                              class: {
                                "font-weight-bold": _vm.isActiveField(el),
                              },
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickSwField(el)
                                },
                              },
                            },
                            [_vm._v(_vm._s(el.name))]
                          )
                        }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.state.activeCategory.prop.fields,
                          function (el) {
                            return _c(
                              "span",
                              {
                                key: el.id,
                                staticClass: "mr-2",
                                class: {
                                  "font-weight-bold": _vm.isActiveField(el),
                                },
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickSwField(el)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(el.name))]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-2 mt-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-circle", size: "lg" },
                      on: {
                        click: function ($event) {
                          return _vm.clickDot("select")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "mr-2 mt-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-regular fa-circle", size: "lg" },
                      on: {
                        click: function ($event) {
                          return _vm.clickDot("un_select")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "mr-2 mt-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-circle-dot", size: "lg" },
                      on: {
                        click: function ($event) {
                          return _vm.clickDot("inverse")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm",
                        on: {
                          click: function ($event) {
                            _vm.state.sortColumn = []
                          },
                        },
                      },
                      [_vm._v("сбросить сортировку")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm",
                        on: { click: _vm.showModalInsert },
                      },
                      [_vm._v("вставить")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid m1" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 col-xl-10" }, [
            _c(
              "div",
              {
                attrs: { id: "open" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.menu.close()
                  },
                },
              },
              [
                _c("table", { staticClass: "w-100" }, [
                  _c(
                    "thead",
                    [
                      _c(
                        "draggable",
                        {
                          attrs: {
                            tag: "tr",
                            list: _vm.activeFields.fields,
                            "scroll-sensitivity": 100,
                            "fallback-tolerance": 1,
                            "force-fallback": true,
                            "ghost-class": "ghost",
                          },
                        },
                        _vm._l(_vm.activeFields.fields, function (el) {
                          return _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              attrs: { title: el.desc },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(el.name) +
                                  "\n                                "
                              ),
                              _vm.getSortStatus(el) === ""
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "sort"] },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickSort(el, "")
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getSortStatus(el) === "up"
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "sort-up"] },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickSort(el, "up")
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getSortStatus(el) === "down"
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "sort-down"] },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickSort(el, "down")
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                _vm._s(el.unit) +
                                  "\n                            "
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.fTable, function (val) {
                      return _c(
                        "tr",
                        {
                          key: val.id,
                          class: {
                            "bg-success-l": _vm.eTable.isEditForSave(val),
                            "bg-primary-l": _vm.eTable.isSelect(val),
                            "bg-info": !!val.base_uid,
                          },
                          on: {
                            click: [
                              function ($event) {
                                if (
                                  $event.ctrlKey ||
                                  $event.shiftKey ||
                                  $event.altKey ||
                                  $event.metaKey
                                )
                                  return null
                                return _vm.eTable.clickRow(val)
                              },
                              function ($event) {
                                if (!$event.ctrlKey) return null
                                $event.preventDefault()
                                return _vm.eTable.clickCtrlRow(val)
                              },
                            ],
                          },
                        },
                        [
                          _vm._l(_vm.activeFields.fields, function (el) {
                            return [
                              el.id === "url"
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "text-left small",
                                      on: {
                                        contextmenu: function ($event) {
                                          $event.preventDefault()
                                          return _vm.$refs.menu.open($event, {
                                            tr: val,
                                            td: el,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _vm.getValById(el.id, val)
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.getValById(
                                                  el.id,
                                                  val
                                                ),
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "mt-1 mr-3",
                                                attrs: {
                                                  icon: "fa-solid fa-arrow-up-right-from-square",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "td",
                                    {
                                      staticClass: "text-left small",
                                      class: {
                                        "font-weight-bold": val.base_id > 0,
                                      },
                                      on: {
                                        contextmenu: function ($event) {
                                          $event.preventDefault()
                                          return _vm.$refs.menu.open($event, {
                                            tr: val,
                                            td: el,
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.getValById(el.id, val)))]
                                  ),
                            ]
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-baseps", {
        attrs: {
          "modal-id": "modalBaseps",
          "p-local": _vm.local,
          "p-state": _vm.state,
        },
        on: { updInit: _vm.init },
      }),
      _vm._v(" "),
      _vm.isMounted
        ? _c("modal-file-upload", {
            attrs: { "modal-id": "modalFileUpload", partners: _vm.partners },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ContextMenuMy", { ref: "menu", attrs: { items: _vm.items } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }