var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "item-container",
      attrs: {
        tag: "div",
        list: _vm.list,
        value: _vm.value,
        handle: ".handle",
        animation: "150",
        group: "nested",
        "fallback-on-body": true,
        "swap-threshold": 0.5,
        "ghost-class": "ghost",
        filter: ".no-drag",
        "scroll-sensitivity": 100,
        "fallback-tolerance": 1,
        "force-fallback": true,
      },
      on: { input: _vm.emitter, change: _vm.onChange },
    },
    _vm._l(_vm.realValue, function (el) {
      return _c(
        "div",
        { key: el.id, staticClass: "item-group" },
        [
          el.t === "element"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(el.num)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-11 text-left" }, [
                        _vm._v(_vm._s(el.element.name)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(_vm._s(el.element.marka)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(_vm._s(el.element.article)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(el.element.brand)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _vm._v(_vm._s(el.element.unit)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _c("span", [_vm._v(_vm._s(el.element.val))]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _vm._v(_vm._s(el.element.wt)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _vm._v(_vm._s(el.element.desc)),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "razdel"
            ? _c(
                "div",
                {
                  staticClass: "item row razdel",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(el.num)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-11 font-weight-bold text-left" },
                        [_vm._v(_vm._s(el.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-6 text-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _c("span", [_vm._v(_vm._s(el.calc))]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("nested-specification-view", {
            staticClass: "item-sub ml-3",
            attrs: {
              list: el.child,
              level: _vm.level + 1,
              id: el.id,
              local: _vm.local,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }