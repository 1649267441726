<template>
    <div :id="modalId" class="h-100 ddiv" role="dialog">
        <div class="card h-100">
            <div class="card-body">

                <div class="d-flex justify-content-between">
                    <div class="w-100 mr-2" style="position: relative">
                        <input class="form-control w-100" v-model="search" ref="v_focus">
                        <font-awesome-icon icon="fa-solid fa-eraser" size="lg" @click="search=''" title="очистить"/>
                    </div>
                </div>
                <div class="mb-3">
                    <span @click="state.isTypeList=!state.isTypeList" :class="{active:state.isTypeList}" style="cursor: pointer; opacity: 0.4" class="badge ml-2">тип листа</span>
                    <template v-for="val in state.search2">
                        <span @click="clickRemoveTag(val)" style="cursor: pointer" class="badge ml-2" :style="{'background-color': val.color}">{{val.name}}</span>
                    </template>
                </div>

                <div v-if="this.local.table.length === 0" class="text-center p-5">
                    <font-awesome-icon v-if="true" icon="fa-solid fa-circle-notch" size="3x" class="mt-1 text-secondary" title="загрузка данных..." spin/>
                </div>

                <div v-for="(val,index) in filteredTable" :key="'a_'+index" class="card my-1 myRow">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="nav-item dropdown p-0">
                                <font-awesome-icon icon="fa-regular fa-square-caret-down" class="nav-link dropdown-toggle pl-0" data-toggle="dropdown" style="cursor: pointer;"/>
                                <div class="dropdown-menu">
                                    <button v-if="!isForText" class="dropdown-item" @click="clickInsertBlock(val,'insertPage')">вставить новую страницу в проект</button>
                                    <button v-if="isInsertText(val)" class="dropdown-item" @click="clickInsertBlock(val,'insertText')">добавить текст в конце</button>
                                    <button class="dropdown-item" @click="clickInsertBlock(val,'replaceTextOnPage')">заменить текст на странице</button>
                                </div>
                            </div>
                            <a class="font-weight-bold" :href="val.link+'/'+val.project_id+'/'+val.id" v-html="markerText(val.name)"></a>
                            <span @click="clickAddTag(val,'project')" style="cursor: pointer" class="badge ml-2 bg-info">{{val.project_name}}</span>
                            <span @click="clickAddTag(val,'org')" style="cursor: pointer" class="badge ml-2 bg-info">{{val.org.name}}</span>
                            <template v-for="tt in val.tags">
                                <span @click="clickAddTag(tt,'tag')" style="cursor: pointer" class="badge ml-2" :style="{'background-color': tt.color}">{{tt.name}}</span>
                            </template>

                        </div>


                        <span class="btn" data-toggle="collapse" :data-target="'#collapse2_'+val.id">показать</span>
                        <div class="collapse" :id="'collapse2_'+val.id" v-html="markerText(val.html)" :class="{show:!isFold(val)}"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import {page} from "./tools";


    export default {
        name: 'import-div',
        components: {Multiselect},
        props: {
            modalId: {type: String, default: 'importDiv',},
            isShow: {type: Boolean, default: false,},
            isForText: {type: Boolean, default: false,},
        },
        data: function () {
            return {
                pageT: new page(this),
                rand: 1,
                local: {
                    table: [],
                },
                state: {
                    version: 1,
                    search2: [],
                    isTypeList: true,
                },
                status: {
                    loading: false,
                },
                search: '',
                listFoldPage: ['/f/table_trn', '/f/table_od', '/f/table_vrch', '/f/table_pps', '/f/table_vspd', '/f/table_so'],
                currentObj: null,
            }
        },
        created: function () {
            // Bus.$on('show_' + this.modalId, () => this.showModal());
            Bus.$on('syncSearchA', (obj) => this.search = obj.search);
            Bus.$on('updCurrentId', obj => this.currentObj = obj);
        },
        mounted() {
            this.pageT.loadCookie();
            if (this.isShow) this.showModal();
        },
        methods: {
            markerText(str) {
                if (str === null) return '';
                if (this.search === '') return str;
                this.search.split(' ').forEach(value => {

                    if (value !== '' && value.length > 2) str = str.replaceAll(new RegExp(value, 'gmi'), (match) => {
                        return '<span class="font-weight-bold bg-secondary">' + match + '</span>';
                    });
                });
                return str;
            },
            isInsertText(obj) {
                let res = !obj.isEmptyHtml && obj.link === '/tp' && !!this.currentObj;
                if (this.currentObj) res = !!this.currentObj.html && this.currentObj.link_content.link === '/tp' && res;
                return res;
            },
            clickRemoveTag(obj) {
                //todo js delete Array element
                let key = this.state.search2.findIndex(item => item.id === obj.id && item.ttype === obj.ttype);
                if (key < 0) return;
                this.state.search2.splice(key, 1);
            },
            clickAddTag(obj, ttype) {
                let o = {};
                if (ttype === 'project') {
                    o = {id: obj.project_id, name: obj.project_name, ttype: ttype, color: '#e9d8a6'};
                }
                if (ttype === 'org') {
                    o = obj.org;
                    o.ttype = ttype;
                    o.color = '#e9d8a6';
                }
                if (ttype === 'tag') {
                    o = obj;
                    o.ttype = ttype;
                }
                this.state.search2.push(o);

            },
            clickInsertBlock(obj, ttype) {
                if (!this.currentObj) {
                    this.flash('выберите блок в левой части', 'info');
                    return;
                }
                if (ttype === 'insertPage') Bus.$emit('insertPage', obj);
                else if (ttype === 'insertText') Bus.$emit('insertText', obj);
                else if (ttype === 'replaceTextOnPage') Bus.$emit('replaceTextOnPage', obj);
            },
            isFold(obj) {
                // let o = this.listFoldPage.inArray(item => item === obj.link);
                // return !!o;
                //todo js inArray
                return !!~this.listFoldPage.indexOf(obj.link);
            },
            showModal() {
                // $('#' + this.modalId).modal('show');
                setTimeout(() => this.$refs.v_focus.focus(), 500);
                if (this.local.table.length === 0) this.load();
            },
            load() {
                this.pageT.clickSave('/import/load_list_fields', {}, '_load', false)
                Bus.$on('zResponse_load', response => {
                    this.local.table = response.table;
                    import("../../../sass/_rtl.css")
                });
            },
        },
        computed: {
            filteredTable: function () {
                return this.filteredTable2.filter(item => {
                    // if (item.link === '/tp' && item.obj.type_list === 'text') return false;
                    let res = true;
                    this.search.split(' ').forEach(value => {
                        let s = item.name + item.project_name + item.html;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                });
            },
            filteredTable2: function () {
                // if (this.state.search2.length === 0 && !this.currentObj) return this.local.table;
                return this.local.table.filter(item => {
                    let res = true;
                    this.state.search2.forEach(value => {
                        if (value.ttype === 'project') res = res && item.project_id === value.id;
                        if (value.ttype === 'org') res = res && item.org.id === value.id;
                        if (value.ttype === 'tag') res = res && !!item.tags.find(ii2 => ii2.id === value.id);
                    });
                    if (!!this.currentObj && this.state.isTypeList) res = res && item.type_list === this.currentObj.type_list;
                    return res;
                });
            }
        },
        watch: {
            isShow(newVal, oldVal) {
                this.showModal();
            },
            'state': {
                handler: function (newVal, oldVal) {
                    this.pageT.updCookie();
                },
                deep: true
            },
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }

    .myRow {
        &:hover {
            background-color: lighten($primary, 50%);
        }
    }

    span.active {
        border: 1px solid black;
        opacity: 1 !important;
    }

    span.mark_ {
        background-color: lighten($primary, 90%) !important;
    }
</style>
