<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount" data-keyboard="false">
        <div class="modal-dialog modal-xl" @click.stop="">
            <div class="modal-content">
                <div class="modal-header" style="cursor: move">
                    <span v-if="state.isEdit">редактировать</span>
                    <span v-else>вставить</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div v-if="isShow" class="modal-body">

                    <div class="d-flex flex-column" style="max-height: calc(100vh - 236px);">

                        <div style="overflow-x: hidden;">

                            <div class="row">
                                <div class="col-12">
                                    <span class="mr-3" :class="{'font-weight-bold':state.obj.baseType==='bs'}" @click="state.obj.baseType='bs'" style="cursor: pointer"><font-awesome-icon style="color: grey" icon="fa-regular fa-star"/> база</span>
                                    <span class="mr-3" :class="{'font-weight-bold':state.obj.baseType==='pr'}" @click="state.obj.baseType='pr'" style="cursor: pointer"><font-awesome-icon style="color: royalblue" icon="fa-regular fa-circle-user"/> личная</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">
                                            <multiselect
                                                v-model="state.obj.fCat"
                                                :options="local.listCategory.map(item=>item.id)"
                                                :show-labels="false"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :allow-empty="false"
                                                :internal-search="false"
                                                @search-change="customSearch"
                                                @input="onChange"
                                                class="mb-2"
                                            >
                                                <template slot="singleLabel" slot-scope="props">
                                                    <span v-if="local.listCategory.find(item => item.id === props.option)" class="option__desc"><span
                                                        class="option__title">{{ local.listCategory.find(item => item.id === props.option).name }}</span></span>
                                                    <span v-else class="option__desc"><span class="option__title">___</span></span>
                                                </template>
                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc">
                                                        <span class="option__title" :style="{'padding-left':local.listCategory.find(item => item.id === props.option).level+'rem'}">{{
                                                                local.listCategory.find(item => item.id === props.option).name
                                                            }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div class="col-6">
                                            <multiselect
                                                v-model="state.fCatField"
                                                :options="listCatFields"
                                                :show-labels="false"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :allow-empty="false"
                                                :internal-search="false"
                                                @search-change="customSearch"
                                                @input="onChange"
                                                class="mb-2"
                                            >
                                                <template slot="singleLabel" slot-scope="props">
                                                    <span class="option__desc"><span class="option__title">{{ props.option.name }}<span v-if="props.option.unit">, {{
                                                            props.option.unit
                                                        }}</span> </span></span>
                                                </template>
                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ props.option.name }}<span v-if="props.option.unit">, {{ props.option.unit }}</span></span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div class="col-1">
                                            <button v-if="!!this.state.fCatField.id" class="form-control btn btn-primary" @click="addVars">add</button>
                                        </div>
                                    </div>

                                    <b-input-val-so v-model="state.obj"/>

                                    <div class="row" v-for="el in state.obj.v" :key="el.id">
                                        <div class="col-2">
                                            <input class="form-control w-100" v-model.trim="el.id_f" title="id"/>
                                        </div>
                                        <div class="col-3 pt-2" :title="getFFieldDesc(el)">
                                            {{ getFFieldName(el) }}<span class="small"> ({{ getFFieldType(el) }})</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="ml-3 font-weight-bold position-absolute h4 text-right" style="cursor: pointer; top: 0.4rem; left: -1.5rem;"
                                                  @click="clickSign(el)">{{ el.ff.sign }}</span>
                                            <input class="form-control w-100" v-model.trim="el.v_in" title="значение"/>
                                            <span class="ml-3 font-weight-bold position-absolute text-left" style="cursor: pointer; top: 0.4rem; right: -0.3rem;">
                                                <font-awesome-icon v-if="!el.ff.order" :icon="['fas', 'sort']" @click="clickOrder(el)"/>
                                                <font-awesome-icon v-if="el.ff.order==='asc'" :icon="['fas', 'sort-up']" @click="clickOrder(el)"/>
                                                <font-awesome-icon v-if="el.ff.order==='desc'" :icon="['fas', 'sort-down']" @click="clickOrder(el)"/>
                                            </span>
                                        </div>
                                        <div class="col-3">
                                            <input class="form-control w-100" v-model.trim="el.formula" :title="el.resp.fText"/>
                                            <span v-if="el.resp.eMess" class="small text-danger">{{ el.resp.eMess }}</span>
                                        </div>
                                        <div class="col-2 d-flex">
                                            <div class="w-75 position-relative">
                                                <input class="form-control" v-model.trim="el.v_out" disabled title="результат"/>
                                                <span class="position-absolute" style="top: 0.45rem; right: 0.8rem; cursor: pointer;" title="округление" @click="clickRound(el)">{{ el.round }}</span>
                                            </div>
                                            <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-2 ml-3 w-auto" style="cursor: pointer" size="lg" @click="removeVars(el)"/>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-1">
                                            <button class="form-control btn btn-primary" @click="clickSearch">поиск</button>
                                        </div>
                                    </div>

                                    <div class="row" v-for="el in local.table" :key="el.uid">
                                        <div class="col-1">
                                            <img v-if="el.img && el.img[0]" :src="el.img[0]" alt="" style="width: 5rem;">
                                        </div>
                                        <div class="col-7">{{ el.name }}</div>
                                        <div class="col-2">{{ el.marka }}<br>{{ el.article }}</div>
                                        <div class="col-1">{{ el.brand }}</div>
                                    </div>

                                    <label class="apple-switch_2 mr-3" style=""><input type="checkbox" v-model="state.obj.isCalculate"><i></i>учитывать в расчётах блока</label>
                                    <c-input-razdel-name-so v-model="state.obj"/>
                                    <c-input-desc-so v-model="state.obj.element"/>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer d-flex flex-row">
                    <button type="button" class="btn btn-primary" @click="clickLocalSave">сохранить</button>
                    <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {eTable, page} from "./tools";
import EInput from "./EInput";
import BInputField from "./BInputField";
import uniqid from "uniqid";
import tab from "bootstrap/js/src/tab";
import modalSoInfo from "./ModalSoInfo.vue";
import cCategoryString from "./cCategoryString.vue"
import BInputValSo from "./BInputValSo.vue";
import CInputRazdelNameSo from "./cInputRazdelNameSo.vue";
import CInputDescSo from "./cInputDescSo.vue";


export default {
    name: 'modal-so-f-element-base',
    components: {CInputDescSo, CInputRazdelNameSo, BInputValSo, EInput, Multiselect, BInputField, modalSoInfo, cCategoryString},
    props: {
        modalId: {type: String, default: 'modal',},
        AW: {type: Array, default: () => [],},
        listCategory: {type: Array, default: () => [],},
        marka: {type: String, default: 'marka_new',},
    },
    data: function () {
        return {
            //todo js modal add page module
            pageT: new page(this),
            local: {
                selectArr: [],
                table: [],
                listCategory: this.listCategory,
            },
            state: {
                obj: null,
                fCatField: {},
                isEdit: true,
                search: {
                    val: ''
                },
                activeRowIndex: 0,
                listBase: [
                    {id: 'bs', name: 'база', isSelect: true},
                    {id: 'ps', name: 'производители', isSelect: false},
                    {id: 'pr', name: 'личная', isSelect: false},
                ],
                currentCategory: '',
                isPS: false,
            },
            status: {
                loading: false,
            },
            isMount: false,
            isShow: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (this.isAW) {
                if (e.key === 'f2') this.showModalSoInfo();
                if (e.key === 'ctrlEnter') this.clickLocalSave();
                if (e.key === 'esc') this.closeModal();
                if (e.key === 'down') {
                    if (this.state.activeRowIndex + 1 < this.fTable.length) this.clickRow(this.state.activeRowIndex + 1);
                    e.evt.preventDefault();
                }
                if (e.key === 'up') {
                    if (this.state.activeRowIndex > 0) this.clickRow(this.state.activeRowIndex - 1);
                    e.evt.preventDefault();
                }
            }
        });
        Bus.$on('show_' + this.modalId, (e) => {
            this.state.isEdit = true;
            this.local.selectArr = e.selectArr;
            this.local.saveArr = e.saveArr;
            this.showModal()
            this.isShow = true;
        });
        Bus.$on('showI_' + this.modalId, (e) => {
            this.state.isEdit = false;
            this.local.selectArr = e.selectArr;
            this.local.saveArr = e.saveArr;
            this.showModal();
            this.isShow = true;
        });
        Bus.$on('closeModal', () => {
            this.closeModal();
        });
    },
    mounted() {
        this.isMount = true;
    },
    methods: {
        getTwoChars(uid) {
            if (!uid) return '';
            return String(uid).slice(0, 2);
        },
        preCalc() {
            this.state.obj.v.forEach(item => {
                if (!item.formula) {
                    if (!!item.v_in) item.v_out = item.v_in;
                }
            })
        },
        clickSearch() {
            this.preCalc();
            this.pageT.clickSave('/specification/search_for_block', {obj: this.state.obj}, '_search_for_block');
            Bus.$once('zResponse_search_for_block', response => {
                this.local.table = response.table;
            });
        },
        clickSign(obj) {
            let s = obj.ff.sign;
            if (s === '=') obj.ff.sign = '!=';
            else if (s === '!=') obj.ff.sign = '>';
            else if (s === '>') obj.ff.sign = '<';
            else if (s === '<') obj.ff.sign = '=';
        },
        clickOrder(obj) {
            let s = obj.ff.order;
            if (!s) obj.ff.order = 'asc';
            else if (s === 'asc') obj.ff.order = 'desc';
            else if (s === 'desc') obj.ff.order = '';
        },
        clickRound(obj) {
            if (obj.round === 3) {
                obj.round = 0;
            } else {
                obj.round += 1;
            }
        },
        getFFieldType(el) {
            let o = this.getFField(el);
            if (!o) return '';
            return o.type;
        },
        getFFieldDesc(el) {
            let o = this.getFField(el);
            if (!o) return '';
            return o.desc;
        },
        getFFieldName(el) {
            let o = this.getFField(el);
            if (!o) return '';
            let u = ''
            if (o.unit !== null) u = ', ' + o.unit;
            return o.name + u;
        },
        getFField(el) {
            let f = this.listCatFields.find(item => item.id === el.ff.id);
            if (!f) return null;
            return f;
        },
        removeVars(el) {
            let index = this.state.obj.v.findIndex(item => item.id === el.id);
            if (index >= 0) this.state.obj.v.splice(index, 1);
        },
        addVars() {
            if (this.state.fCatField.id === undefined) return;
            let uid = uniqid();
            let o = {
                id: 'v' + uid,
                id_f: uid,
                name: '',
                v_in: 0,
                formula: '',
                v_out: 0,
                isCalc: false,
                isShow: true,
                round: 0,
                resp: {
                    eMess: '',
                    fText: '',
                },
                ff: {
                    id: this.state.fCatField.id,
                    sign: '=',
                    order: '',
                }
            };
            this.state.obj.v.push(o);
        },
        onChange(e) {
            // this.init_();
            // this.calc();
        },
        customSearch(query) {
            if (query === '') this.local.listCategory = this.listCategory;
            let i = 0;
            this.local.listCategory = this.listCategory.filter(item => {
                let res = true;
                query.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        showModal() {
            if (!this.lastSelectObj) return;
            this.activeRowIndex = 0;
            this.state.obj = JSON.parse(JSON.stringify(this.lastSelectObj));

            $('#' + this.modalId).modal('show');

            setTimeout(function () {
                $(".modal").draggable({handle: ".modal-header"});
            });

            (function (id) {
                setTimeout(function () {
                    Bus.$emit('setAW', id);
                });
            })(this.modalId)

            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    if (this.$refs.v_focus) this.$refs.v_focus.focus();
                }, 200);
            }

            // this.clickSearch();
        },
        clickLocalSave() {
            this.preCalc();
            this.local.selectArr.forEach(item => {
                item.fCat = this.state.obj.fCat;
                item.v = this.state.obj.v;
                item.val = this.state.obj.val;
                item.toRazdel = this.state.obj.toRazdel;
                item.isCalculate = this.state.obj.isCalculate;
                item.isVisible = this.state.obj.isVisible;
                item.baseType = this.state.obj.baseType;
                item.element.desc = this.state.obj.element.desc;
                item.element.unit = this.state.obj.element.unit;
                item.val.id_f = this.state.obj.val.id_f;
                this.local.saveArr.push(item);
            })
            this.closeModal();
        },
        closeModal() {
            $('#' + this.modalId).modal('hide');
            Bus.$emit('removeAW', this.modalId);
            this.isShow = false;
        },
    },
    computed: {
        cCat: function () {
            let o = this.listCategory.find(item => item.id === this.state.obj.fCat);
            return o;
        },
        listCatFields: function () {
            if (this.cCat === undefined) return [];
            let res = [
                {desc: 'name', id: 'name', name: 'имя', req: true, size: 4, type: 'string', unit: null, val: ''},
                {desc: 'marka', id: 'marka', name: 'марка', req: true, size: 4, type: 'string', unit: null, val: ''},
                {desc: 'article', id: 'article', name: 'артикул', req: true, size: 4, type: 'string', unit: null, val: ''},
                {desc: 'brand', id: 'brand', name: 'производитель', req: true, size: 4, type: 'string', unit: null, val: ''},
            ];
            this.cCat.prop.fields.forEach(item => {
                res.push(item);
            })
            return res;
        },
        listCatChild: function () {
            if (this.state.currentCategory === '') return [];
            if (this.state.isPS) return [this.state.currentCategory];
            let arr = [];
            this.recursGetChild(parseInt(this.state.currentCategory), arr);
            return arr;
        },
        isAW: function () {
            return this.AW !== undefined && this.AW[0] === this.modalId;
        },
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
        activeRow: function () {
            let o = this.fTable[this.state.activeRowIndex];
            if (!o) o = {};
            return o;
        },
        fTable: function () {
            if (this.state.search.val === undefined || this.state.search.val === '') return [];
            let i = 0;
            let table = [];
            this.state.listBase.forEach(item => {
                if (item.isSelect && this.local[item.id + 'Base'] !== undefined) table = table.concat(this.local[item.id + 'Base']);
            })
            if (table.length === 0) return [];

            if (this.listCatChild.length > 0) {
                table = table.filter(item => {
                    return this.listCatChild.findIndex(item2 => String(item2) === String(item.category)) > -1;
                })
            }

            return table.filter(item => {
                if (i > 9) return false;
                let res = true;
                if (this.state.search.val !== '') {
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name + item.marka + item.brand + item.article;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                }
                if (res) i++;
                return res;
            })
        },
    },
    watch: {},
}
</script>
