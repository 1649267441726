<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal" role="dialog" @click="closeModal" v-if="isMount">
        <div class="modal-dialog modal-xl" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span>{{ state.obj.name }}</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">

                            <div class="d-flex flex-column" style="max-height: calc(100vh - 236px);">
                                <div v-if="!!state.obj.img && state.obj.img.length>0" class="d-flex mb-2" style="min-height: 200px; height: 1px;">
                                    <div class="d-flex flex-item h-100" style="overflow-y: hidden">
                                        <img v-for="el in state.obj.img" :src="el" alt="" class="h-100">
                                    </div>
                                </div>

                                <multiselect
                                    v-model="state.activeCategory"
                                    :options="local.category"
                                    :show-labels="false"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :allow-empty="false"
                                    :internal-search="false"
                                    @search-change="customSearch"
                                    @input="onChange"
                                    class="mb-2"
                                >
                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc">
                                            <span class="option__title" :style="{'padding-left':props.option.level+'rem'}">{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect>

                                <div style="overflow-x: hidden;" class="px-1">
                                    <div class="row">
                                        <template v-for="el in state.aCompare.filter(item=>item.id!=='fix_name'&&item.id!=='fix_category')">
                                            <div class="col-12 draggable" :key="el.id" :draggable="true" @dragstart="dragStart(el, $event)" @dragend="draggedItem=null">
                                                <span :title="getField('desc', el)" :class="{'bg-danger-l':el.orig===null||el.val===null}">{{ getField('name', el) }}</span>
                                                <span v-if="getField('unit', el)">, {{ getField('unit', el) }}</span>
                                                <span style="opacity: 0.5"> ({{ el.id }})</span>
                                                <span>{{ getField('type', el) }}</span>
                                                <span v-if="el.val" class="font-weight-bold">{{ el.val }}</span>

                                            </div>
                                            <div class="col-12 d-flex">
                                                <input class="form-control d-inline-block py-0 h-auto" v-model="el.expl" title="разделитель разбиения строки" style="max-width: 4rem">
                                                <input class="form-control d-inline-block py-0 h-auto" v-model="el.factor" :disabled="getField('type',el)==='string'" title="множитель"
                                                       style="max-width: 4rem">
                                                <input class="form-control d-inline-block py-0 h-auto" v-model="el.fix" title="фиксированное значение">
                                            </div>
                                        </template>
                                        <button type="button" class="btn btn-sm" @click="calc">посчитать</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-6">
                            <div class="d-flex flex-column" style="max-height: calc(100vh - 236px);">
                                <div style="overflow-x: hidden;" class="px-1">
                                    <div>
                                        <template v-for="(val,key) in state.obj.prop">
                                            <p class="m-0 droppable" @dragover.prevent @drop="drop" :data-drop-key="key">
                                                {{ key }}: <span class="font-weight-bold" :data-drop-key="key">{{ val }}</span>
                                                <span v-if="getTag(key)" class="badge">{{ getTag(key) }}</span>
                                            </p>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex flex-row">
                    <div>
                        <!--                        <button v-if="!!lastSelectObj" type="button" class="btn btn-primary" @click="clickInsert" :disabled="!isReqFull">вставить новую запись</button>-->
                        <button type="button" class="btn btn-default" @click="clickLocalSave">сохранить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {page} from "./tools";
import EInput from "./EInput";
import BInputField from "./BInputField";
import uniqid from 'uniqid';


export default {
    name: 'modal-baseps',
    components: {EInput, Multiselect, BInputField},
    props: {
        modalId: {type: String, default: 'modal',},
        pLocal: {type: Object, default: {},},
        pState: {type: Object, default: {},},
        isGroupedItem: {type: Boolean, default: false,},
    },
    data: function () {
        return {
            //todo js modal add page module
            pageT: new page(this),
            rand: 1,
            local: {
                table: this.pLocal.table,
                category: this.pLocal.category,
            },
            state: {
                obj: null,
                isEdit: true,
                activeCategory: {},
                aCompare: [],
                sop: [],
            },
            status: {
                loading: false,
            },
            templateACompare: {
                id: null,
                orig: null,
                expl: null,
                factor: 1,
                fix: null,
                val: null,
            },
            templateObj: {
                id: '',
                name: '',
                marka: '',
                status: 0,
            },
            isMount: false,
            draggedItem: null
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlEnter') this.clickLocalSave();
        });
        Bus.$on('show_' + this.modalId, () => {
            this.state.isEdit = true;
            this.showModal()
        });
        Bus.$on('showI_' + this.modalId, () => {
            this.state.isEdit = false;
            this.showModal();
        });
    },
    mounted() {
        this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
        this.state.activeCategory = this.local.category[0];
        // this.init();
        this.isMount = true;
    },
    methods: {
        init_() {
            this.setActiveCategory();
            if (this.state.activeCategory.prop.aCompare === undefined) {
                this.$set(this.state.activeCategory.prop, 'aCompare', []);
                this.state.activeCategory.prop.aCompare.push({
                    brand: this.state.obj.brand,
                    category: this.state.obj.category,
                    data: [],
                })
            }

            if (!this.aC) {
                let aC = {
                    brand: this.state.obj.brand,
                    category: this.state.obj.category,
                    data: [],
                }
                this.state.activeCategory.prop.aCompare.push(aC)
            }


            this.state.aCompare.length = 0;
            this.pLocal.listFix.forEach(item => {
                let d = this.aC.data.find(item2 => item2.id === item.id);
                let o = {};
                if (d) o = JSON.parse(JSON.stringify(d))
                else o = JSON.parse(JSON.stringify(this.templateACompare))
                o.id = item.id
                this.state.aCompare.push(o)
            })
            this.state.activeCategory.prop.fields.forEach(item => {
                let d = this.aC.data.find(item2 => item2.id === item.id);
                let o = {};
                if (d) o = JSON.parse(JSON.stringify(d))
                else o = JSON.parse(JSON.stringify(this.templateACompare))
                o.id = item.id
                this.state.aCompare.push(o)
            })

        },
        setActiveCategory() {


        },
        calc() {
            this.state.aCompare.forEach(item => {
                if (item.id === 'fix_category') {
                    item.val = this.state.activeCategory.id
                    return;
                }
                if (item.fix !== null) {
                    item.val = item.fix;
                    return;
                }
                if (item.orig !== null) {
                    let val = this.state.obj.prop[item.orig];
                    if (val !== undefined) {
                        if (item.expl !== '' && item.expl !== null) {
                            let r = item.expl;
                            if (r === '__') r = ' ';
                            val = val.split(r)[0];
                        }

                        let field = this.state.activeCategory.prop.fields.find(item2 => item2.id === item.id);
                        if (!field) field = this.pLocal.listFix.find(item2 => item2.id === item.id);
                        if (field.type === 'string') item.val = val;
                        else if (field.type === 'text') item.val = val;
                        else if (field.type === 'int') item.val = parseInt(val.replace(',', '.').replace(/\s+/g, '')) * (item.factor ?? 1);
                        else if (field.type === 'float') item.val = parseFloat(val.replace(',', '.').replace(/\s+/g, '')) * (item.factor ?? 1);
                        else if (field.type === 'bool') item.val = val !== '';
                    }
                }
            })
            this.state.aCompare.forEach(item => {
                if (item.fix !== null) {
                    let isMask = item.fix.slice(0, 2) === '$$';
                    if (isMask) {
                        let mask = item.fix;
                        this.state.aCompare.forEach(value => {
                            if (item.id !== value.id) {
                                mask = mask.replaceAll(new RegExp('{' + value.id + '}', 'gmi'), (match) => {
                                    return value.val;
                                });
                            }
                        });
                        item.val = mask.slice(2, mask.length);
                    }
                }
            })
        },
        onChange(e) {
            this.init_();
            this.calc();
        },
        getField(key, obj) {
            let o = this.state.activeCategory.prop.fields.find(item => item.id === obj.id);
            if (o) return o[key];
            o = this.pLocal.listFix.find(item => item.id === obj.id);
            if (o) return o[key];

            return '';
        },
        getTag(key) {
            let o = this.state.aCompare.find(item => item.orig === key);
            if (!o) return '';
            let oo = this.state.activeCategory.prop.fields.find(item => item.id === o.id);
            if (!oo) return o.id;
            return oo.name;
        },
        dragStart(item, event) {
            this.draggedItem = item;
            event.dataTransfer.setData('text/plain', JSON.stringify(item));
        },
        drop(event) {
            let attr = event.target.dataset.dropKey;
            if (!attr) return;
            event.preventDefault();
            const data = JSON.parse(event.dataTransfer.getData('text/plain'));
            let obj = this.state.aCompare.find(item => item.id === data.id);
            if (obj) obj.orig = attr;
            this.draggedItem = null;
            this.calc();
        },
        customSearch(query) {
            if (query === '') {
                this.local.category = this.pLocal.category;
            }
            let i = 0;
            this.local.category = this.pLocal.category.filter(item => {
                let res = true;
                query.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        clickInsert() {
            this.closeModal();
        },
        showModal() {
            if (!this.lastSelectObj) return;

            this.state.obj.name = this.lastSelectObj.name;
            this.state.obj.article = this.lastSelectObj.article;
            this.state.obj.brand = this.lastSelectObj.brand;
            this.state.obj.category = this.lastSelectObj.category;
            this.state.obj.series = this.lastSelectObj.series;
            this.state.obj.series_p = this.lastSelectObj.series_p;
            this.state.obj.prop = this.lastSelectObj.prop;
            this.state.obj.img = this.lastSelectObj.img;

            this.init_();

            $('#' + this.modalId).modal('show');
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    if (this.$refs.v_focus) this.$refs.v_focus.focus();
                    this.calc();
                }, 200);
            }
            $(".modal").draggable({
                handle: ".modal-header"
            });
        },
        clickLocalSave() {
            this.calc();
            this.aC.data = JSON.parse(JSON.stringify(this.state.aCompare));
            this.lastSelectObj.aCompare = JSON.parse(JSON.stringify(this.state.aCompare));

            let scI = this.pLocal.saveArrCat.findIndex(item => item.id === this.state.activeCategory.id);
            if (scI >= 0) this.pLocal.saveArrCat.splice(scI, 1);
            this.pLocal.saveArrCat.push(this.state.activeCategory);

            this.closeModal();
        },
        closeModal() {
            this.$emit('updInit');
            $('#' + this.modalId).modal('hide');
        },
    },
    computed: {
        isReqFull: function () {
            let res = true;
            res = res && this.state.obj.name !== '';
            // this.local.activeCategoryL.prop.fields.forEach(item => {
            //     if (item.req) {
            //         res = res && this.state.obj.fields[item.id] !== '' && this.state.obj.fields[item.id] !== 0;
            //     }
            // })
            return res;
        },
        lastSelectObj: function () {
            if (this.pLocal.selectArr.length === 0) return null;
            return this.pLocal.selectArr[this.pLocal.selectArr.length - 1];
        },
        aC: function () {
            let obj = this.state.activeCategory.prop.aCompare.find(item => item.brand === this.state.obj.brand);
            if (obj) return obj;
            return null;
        }
    },
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.modal-footer {
    svg.fa-lock, svg.fa-lock-open {
        opacity: 1;
        cursor: pointer;
    }
}

svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}

ul.multiselect__content {
    width: 100% !important;
}

input.lock {
    background-color: lighten($primary, 50%);
}

.badge {
    display: inline-block;
    background-color: $primary;
    cursor: pointer;
}

.draggable {
    cursor: grab;
}

</style>
