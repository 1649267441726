<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-3">
                    <div v-for="(el,index) in fSuffix" class="row mt-3" :key="el.type_list" v-if="index<2">
                        <div class="col-7 mt-2 text-nowrap text-right" style="overflow: hidden">{{getName(el.type_list)}}</div>
                        <div class="col-5"><input class="form-control" v-model.trim="el.suffix"/></div>
                    </div>
                </div>
                <div class="col-3">
                    <div v-for="(el,index) in fSuffix" class="row mt-3" :key="el.type_list" v-if="index>=2&&index<4">
                        <div class="col-7 mt-2 text-nowrap text-right" style="overflow: hidden">{{getName(el.type_list)}}</div>
                        <div class="col-5"><input class="form-control" v-model.trim="el.suffix"/></div>
                    </div>
                </div>
                <div class="col-3">
                    <div v-for="(el,index) in fSuffix" class="row mt-3" :key="el.type_list" v-if="index>=4&&index<6">
                        <div class="col-7 mt-2 text-nowrap text-right" style="overflow: hidden">{{getName(el.type_list)}}</div>
                        <div class="col-5"><input class="form-control" v-model.trim="el.suffix"/></div>
                    </div>
                </div>
                <div class="col-3">
                    <div v-for="(el,index) in fSuffix" class="row mt-3" :key="el.type_list" v-if="index>=6">
                        <div class="col-7 mt-2 text-nowrap text-right" style="overflow: hidden">{{getName(el.type_list)}}</div>
                        <div class="col-5"><input class="form-control" v-model.trim="el.suffix"/></div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary" @click="clickSave">сохранить</button>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'edit-suffix',
        props: {
            listPagesTemplate: {
                type: Array,
                default: [],
            },
            listPages: {
                type: Array,
                default: [],
            },
            setting: {
                type: Object,
                default: {suffix: []},
            }
        },
        data() {
            return {
                local: {
                    listPages: [],
                }
            }
        },
        created: function () {
            //todo js tic
            // Bus.$on('updListPages', () => Vue.nextTick(() => this.upd()));
        },
        mounted() {
            this.upd();
        },
        methods: {
            getName(name) {
                return this.listPagesTemplate.find(item => item.name === name).list_name;
            },
            clickSave() {
                this.$emit('save');
            },
            upd() {
                this.local.listPages = this.listPages.map(item => item.type_list);
            },
        },
        computed: {
            fSuffix() {
                //todo js inArray
                return this.setting.suffix.filter(item => !!~this.local.listPages.indexOf(item.type_list));
            }
        },
        watch: {
            listPages() {
                this.upd();
            }
        },
    }
</script>
