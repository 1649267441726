var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "w-100", attrs: { title: _vm.title } },
    [
      _c("span", { staticClass: "small" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "w-100" },
        [
          _vm.isNumber
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.inputVal,
                    expression: "inputVal",
                    modifiers: { number: true },
                  },
                ],
                staticClass: "form-control",
                class: { lock: _vm.lock[_vm.k] },
                attrs: { placeholder: _vm.label },
                domProps: { value: _vm.inputVal },
                on: {
                  keyup: function ($event) {
                    _vm.lock[_vm.k] = false
                  },
                  click: function ($event) {
                    _vm.lock[_vm.k] = false
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inputVal = _vm._n($event.target.value)
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.inputVal,
                    expression: "inputVal",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                class: { lock: _vm.lock[_vm.k] },
                attrs: { placeholder: _vm.label },
                domProps: { value: _vm.inputVal },
                on: {
                  keyup: function ($event) {
                    _vm.lock[_vm.k] = false
                  },
                  click: function ($event) {
                    _vm.lock[_vm.k] = false
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inputVal = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
          _vm._v(" "),
          _vm.lock[_vm.k]
            ? _c("font-awesome-icon", { attrs: { icon: "fa-solid fa-lock" } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.listTag, function (e) {
        return _c(
          "span",
          {
            staticClass: "small tag",
            on: {
              click: function ($event) {
                return _vm.clickTag(e)
              },
            },
          },
          [_vm._v(_vm._s(e) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }