<template>
    <div class="h-100" v-if="isMounted">
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <div class="row">
                        <div class="col-12">
                            <h1 class="mb-0">{{nbi.projectInfo.name}}
                                <font-awesome-icon icon="fa-solid fa-gear" title="настройки" style="cursor: pointer" @click="goGetSetting"/>
                            </h1>
                        </div>
                    </div>

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}">настройки</button>
                                </div>
                                <div class="nav-item dropdown">
                                    <button class="dropdown-toggle nav-link btn" data-toggle="dropdown" style="cursor: pointer">вставить лист</button>
                                    <div class="dropdown-menu">
                                        <span v-for="val in fListListTypes.filter(item => item.parent === '_')" :key="val.name">
                                            <button class="dropdown-item" @click="clickAddPage(val.name)">{{val.link_content.name}}</button>
                                            <span v-if="val.name==='text'">
                                                <span v-for="val2 in fListListTypes.filter(item => item.parent !== '_')" :key="val2.name">
                                                    <button class="dropdown-item ml-3" @click="clickAddPage(val2.name)">{{val2.link_content.name}}</button>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="nav-item">
                                    <button v-if="!state.isThree" class="btn nav-link" @click="state.isThree=true">режим структуры</button>
                                    <button v-else class="btn nav-link active" @click="state.isThree=false">режим списка</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swImportW=!state.swImportW" :class="{active:state.swImportW}">импорт</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <div class="row">
                        <div class="col-12">
                            <div v-if="state.swOnOpt" class="card">
                                <div class="card-header p-1 pl-2" @click="state.swOnOpt=false" style="cursor: pointer">настройки</div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">

                                            <div v-for="el in this.fListTypes" class="row mt-3" :key="el.id">
                                                <div class="col-4">{{getNameSuffixForTable(el)}}</div>
                                                <div class="col-2">
                                                    <input class="form-control" v-model.trim="el.suffix"/>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-6">
                                            <!--                                            <label><input class="apple-switch" type="checkbox" v-model="local.setting.auto_structure"> авто структура</label><br>-->
                                            <label><input class="apple-switch" type="checkbox" v-model="local.setting.is_num_title"> нумерация титула</label><br>
                                            <label>
                                                шрифт
                                                <input class="form-control" v-model.trim="local.setting.font_name"/>
                                                <span style="cursor: pointer" @click="local.setting.font_name='isocpeur'">isocpeur</span>
                                                <span style="cursor: pointer" @click="local.setting.font_name='Arial Narrow'">Arial Narrow</span>
                                                <span style="cursor: pointer" @click="local.setting.font_name='Arial'">Arial</span>
                                            </label><br>
                                            <label>размер шрифта<input class="form-control" v-model.number="local.setting.font_size"/></label>
                                            <label><input class="apple-switch" type="checkbox" v-model="local.setting.font_italic"> italic</label><br>
                                            <label class="w-100 mt-2">
                                                <span class="small">имя файла при экспорте</span>
                                                <input class="form-control" v-model="local.setting.file_name" placeholder="имя файла при экспорте"/>
                                            </label>
                                            <label>запас кабеля<input class="form-control" v-model.number="local.setting.zapas"/></label>
                                            <label>отметка розеток<input class="form-control" v-model.number="local.setting.potr_otm"/></label>
                                            <label>отметка выключателей<input class="form-control" v-model.number="local.setting.vykl_otm"/></label>
                                            <label><input class="apple-switch" type="checkbox" v-model="local.setting.is_auto_label"> автоматические label</label>
                                            <br>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row justify-content-center">

                <div :class="{'col-12':!state.swImportW,'col-xl-8':!state.swImportW,'col-6':state.swImportW}">
                    <nested-list v-show="!state.isThree" class="item-sub" v-model="local.table" :setting="local.setting" :list-types="fListListTypes" :list-pages-template="listListTypes" :nbi="nbi"/>
                    <list-all-pages v-show="state.isThree" modal-id="modalListAllPages" :pages="local.table" :nbi="nbi"/>
                </div>

                <import-div v-show="state.swImportW && state.isThree" class="col-6" modal-id="modalImportDiv" :is-show="state.swImportW && state.isThree"/>
                <import-div-project v-show="state.swImportW && !state.isThree" class="col-6" modal-id="modalImportDivProject" :is-show="state.swImportW && !state.isThree"/>

            </div>
        </div>
    </div>
</template>

<script>
    import nestedList from "./sub/nestedList";
    import listAllPages from "./sub/listAllPages";
    import {page} from "./sub/tools";
    import importDiv from "./sub/importDiv";
    import importDivProject from "./sub/importDivProject";

    export default {
        name: "page-list",
        components: {nestedList, importDiv, importDivProject, listAllPages},
        props: ['table', 'setting', 'listPagesTemplate', 'psInfo'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                listListTypes: [
                    {id: 'cover', name: 'О', desc: 'обложка'},
                    {id: 'title_page', name: 'Т', desc: 'титульный лист'},
                    {id: 'approval_sheet', name: 'ЛС', desc: 'лист согласований'},
                    {id: 'st', name: 'СТ', desc: 'содержание тома'},
                    {id: 'spd', name: 'СПД', desc: 'состав проектной документации'},
                    {id: 'text', name: 'ТЧ', desc: 'текстовая часть'},
                    {id: 'dwg', name: 'Ч', desc: 'чертеж'},
                    {id: 'annex', name: 'П', desc: 'приложение'},
                    {id: 'razdel', name: 'р', desc: 'раздел'},
                    {id: 'vspd', name: 'ВСПД', desc: 'ведомость ссылочных и прилагаемых документов'},
                    {id: 'od', name: 'ОД', desc: 'общие данные'},
                    {id: 'vrch', name: 'ВРЧ', desc: 'ведомость рабочих чертежей'},
                    {id: 'pps', name: 'ППС', desc: 'перечень принятых сокращений'},
                    {id: 'so', name: 'СО', desc: 'спецификация оборудования, изделий и материалов'},
                    {id: 'pz', name: 'ПЗ', desc: 'пояснительная записка'},
                ],
                state: {
                    version: 3,
                    swOnOpt: false,
                    swImportW: false,
                    isThree: true,
                },
                status: {
                    reset: false,
                    save: false,
                    loading: false,
                },
                local: {
                    table: this.table,
                    setting: this.setting,
                },
                currentObj: null,
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'ctrlS') this.clickSave();
                if (e.key === 'reloadCalc') this.reloadCalc();
                if (e.key === 'exportAutocad') this.onExportAutocad();
                if (e.key === 'exportAutocadWs') this.pageT.clickAutocadWS('/export_autocadWs');
                if (e.key === 'download') this.pageT.clickDownloadAll();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
            Bus.$on('deletePage', obj => this.clickDelPage(obj));
            Bus.$on('updTable', obj => this.local.table = obj.table);
            Bus.$on('updSetting', obj => this.local.setting = obj.setting);
            Bus.$on('insertPage', obj => this.onInsertPage(obj));
            Bus.$on('insertText', obj => this.onInsertText(obj));
            Bus.$on('replaceTextOnPage', obj => this.onReplaceTextOnPage(obj));
            Bus.$on('updCurrentId', obj => this.currentObj = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, export: true, download: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            this.isMounted = true;
        },
        methods: {
            onReplaceTextOnPage(obj) {
                this.pageT.clickSave('/import/replace_text_on_page', {obj: obj, currentObj: this.currentObj}, '_replace_text_on_page', false)
                Bus.$on('zResponse_replace_text_on_page', response => {
                    this.local.table = response.table;
                    Bus.$emit('updTable', response);
                });
            },
            onInsertText(obj) {
                this.pageT.clickSave('/import/insert_text', {obj: obj, currentObj: this.currentObj}, '_insert_text', false)
                Bus.$on('zResponse_insert_text', response => {
                    this.local.table = response.table;
                    Bus.$emit('updTable', response);
                });
            },
            onInsertPage(obj) {
                this.pageT.clickSave('/import/insert_page', {obj: obj, currentObj: this.currentObj}, '_insert_page', false)
                Bus.$on('zResponse_insert_page', response => {
                    this.local.table = response.table;
                    Bus.$emit('updTable', response);
                });
            },
            onExportAutocad() {
                document.location.href = '/pages/' + this.nbi.projectInfo.id + '/export_autocad';
            },
            reloadCalc() {
                this.pageT.clickSave('/pages/reCalc', {}, '_re_calc')
                Bus.$on('zResponse_re_calc', response => {
                    this.local.table = response.table;
                    Bus.$emit('updListMenu', response.menu);
                    Bus.$emit('updTableLine', response.table);
                });
            },
            goGetSetting() {
                document.location.href = '/project/' + this.nbi.projectInfo.id + '/show';
            },
            getNameSuffixForTable(obj) {
                let o = this.listPagesTemplate.find(item => item.name === obj.type_list);
                if (o) return o.list_name;
                return '';
            },
            clickSave() {
                this.pageT.clickSave('/pages/save', {table: this.local.table, setting: this.local.setting}, '_save')
                Bus.$on('zResponse_save', response => {
                    this.local.table = response.table;
                    Bus.$emit('updListMenu', response.menu);
                    Bus.$emit('updTableLine', response.table);
                });
            },
            clickAddPage(pageName) {
                this.pageT.clickSave('/page/add', {setting: this.local.setting, pageName: pageName}, '_add_page')
                Bus.$on('zResponse_add_page', response => {
                    this.local.table = response.table;
                    Bus.$emit('updListMenu', response.menu);
                    Bus.$emit('updTableLine', response.table);
                });
            },
            clickDelPage(obj) {
                this.pageT.clickSave('/page/' + obj.id + '/del', {}, '_del_page')
                Bus.$on('zResponse_del_page', response => {
                    this.local.table = response.table;
                    Bus.$emit('updListMenu', response.menu);
                    Bus.$emit('updTableLine', response.table);
                });
            },
        },
        computed: {
            fListTypes: function () {
                if (this.psInfo.stage === 'П')
                    return this.local.setting.suffix.filter(item => item.type_list !== 'vrch' && item.type_list !== 'od')
                else
                    return this.local.setting.suffix.filter(item => item.type_list !== 'st' && item.type_list !== 'spd')
            },
            fListListTypes: function () {
                if (this.psInfo.stage === 'П')
                    return this.listPagesTemplate.filter(item => item.name !== 'vrch' && item.name !== 'od' && !item.isOnlyContent)
                else
                    return this.listPagesTemplate.filter(item => item.name !== 'st' && item.name !== 'spd' && !item.isOnlyContent)
            }
        },
        watch: {
            'state.swOnOpt'(newVal, oldVal) {
                this.pageT.updCookie();
            },
            'state.swImportW'(newVal, oldVal) {
                this.pageT.updCookie();
            },
            'state.isThree'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

</style>
