var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-fluid m1" },
      [
        _c("title-for-page", {
          attrs: { "c-page": _vm.local.cPage, nbi: _vm.nbi },
        }),
        _vm._v(" "),
        _vm.isMounted
          ? _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-12 col-xl-8" },
                [
                  _c(
                    "nav",
                    {
                      staticClass: "navbar navbar-expand pl-0 pt-0",
                      staticStyle: { "margin-left": "-0.5rem" },
                    },
                    [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c("div", { staticClass: "navbar-nav" }, [
                          _c("div", { staticClass: "nav-item" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-link btn",
                                class: { active: _vm.state.swOnOpt },
                                attrs: { title: "настройки страницы" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.swOnOpt = !_vm.state.swOnOpt
                                  },
                                },
                              },
                              [_vm._v("настройки")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "nav-item" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-link btn",
                                class: { active: _vm.state.swOnOpt2 },
                                attrs: { title: "настройки страницы" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                  },
                                },
                              },
                              [_vm._v("настройки таблицы")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("card-options", {
                    staticClass: "mb-3",
                    attrs: { "c-page": _vm.local.cPage, state: _vm.state },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm.state.swOnOpt2
                        ? _c("div", { staticClass: "card" }, [
                            _c(
                              "div",
                              {
                                staticClass: "card-header p-1 pl-2",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                  },
                                },
                              },
                              [_vm._v("настройки таблицы")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", { staticClass: "w-100 mt-2" }, [
                                    _c("span", { staticClass: "small" }, [
                                      _vm._v(_vm._s(_vm.cField.opt.w1.desc)),
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.cField.opt.w1.val,
                                          expression: "cField.opt.w1.val",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder: _vm.cField.opt.w1.desc,
                                      },
                                      domProps: {
                                        value: _vm.cField.opt.w1.val,
                                      },
                                      on: {
                                        keyup: _vm.changeWidth,
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.cField.opt.w1,
                                            "val",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", { staticClass: "w-100 mt-2" }, [
                                    _c("span", { staticClass: "small" }, [
                                      _vm._v(_vm._s(_vm.cField.opt.w2.desc)),
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.cField.opt.w2.val,
                                          expression: "cField.opt.w2.val",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder: _vm.cField.opt.w2.desc,
                                        disabled: "",
                                      },
                                      domProps: {
                                        value: _vm.cField.opt.w2.val,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.cField.opt.w2,
                                            "val",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", { staticClass: "w-100 mt-2" }, [
                                    _c("span", { staticClass: "small" }, [
                                      _vm._v(_vm._s(_vm.cField.opt.w3.desc)),
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.cField.opt.w3.val,
                                          expression: "cField.opt.w3.val",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder: _vm.cField.opt.w3.desc,
                                      },
                                      domProps: {
                                        value: _vm.cField.opt.w3.val,
                                      },
                                      on: {
                                        keyup: _vm.changeWidth,
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.cField.opt.w3,
                                            "val",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    !!_vm.local.cPage
                      ? _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "card" }, [
                            _c(
                              "table",
                              {
                                staticClass: "w-100",
                                on: {
                                  click: function ($event) {
                                    _vm.isEdit = !_vm.isEdit
                                  },
                                },
                              },
                              _vm._l(_vm.cField.opt.table, function (val) {
                                return _c("tr", { key: val.id }, [
                                  _c("td", { staticClass: "text-left" }, [
                                    _vm._v(_vm._s(val.list_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    val.count_page === 0 && _vm.isEdit
                                      ? _c("span", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: val.list_num2,
                                                expression: "val.list_num2",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            domProps: { value: val.list_num2 },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  val,
                                                  "list_num2",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(val.list_num2)),
                                        ]),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }