<template>
    <div class="h-100">
        <div class="container-fluid m1">

            <title-for-page :c-page="local.cPage" :nbi="nbi" :is-setting-page="true"/>

            <div class="row justify-content-center mt-3">
                <div class="col-12 col-xl-8">

                    <input class="form-control w-100 mb-3" v-model="local.cPage.stamp.list_name" v-focus>

                    <div class="row">
                        <div class="col-7">
                            <multiselect
                                v-model="local.cPage.link_content"
                                :options="fListTypePage.map(item => item.link_content)"
                                :searchable="false"
                                :close-on-select="true"
                                :show-labels="false"
                                :custom-label="opt => opt.name"
                                :allow-empty="false"
                                @input="onChange"
                            >
                            </multiselect>
                        </div>
                        <button v-if="!isGo" class="btn btn-secondary" @click="clickSave">сохранить</button>
                        <button v-if="isGo" class="btn btn-primary" @click="clickGo">перейти <i class="fas fa-angle-right"></i></button>
                        <button class="btn btn-danger ml-2" @click="clickReset">сброс</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import nestedList from "./sub/nestedList";
    import moment from "moment";
    import TitleForPage from "./sub/TitleForPage";
    import {page} from "./sub/tools";

    export default {
        name: "page-page-setting",
        components: {Multiselect, nestedList, TitleForPage},
        props: ['cPage', 'listTypePage'],
        data: function () {
            return {
                pageT: new page(this),
                isGo: true,
                search: '',
                local: {
                    cPage: this.cPage,

                },
                nbi: {projectInfo: {id: {}}},
                errors: {},
                status: {
                    reset: false,
                    save: false,
                    loading: false,
                },
                isReset: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'ctrlS') this.clickSave();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {save: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
        },
        methods: {
            clickReset() {
                let o = this.listTypePage.find(item => item.name === this.local.cPage.type_list);
                if (!o) return;
                this.local.cPage.link_content = o.link_content;
                this.isReset = true;
                this.clickSave();
            },
            clickGo() {
                document.location.href = this.local.cPage.link_content.link + '/' + this.nbi.projectInfo.id + '/' + this.local.cPage.id;
            },
            onChange() {
                let o = this.fListTypePage.find(item => item.link_content === this.local.cPage.link_content.link);
                if (o) this.local.cPage.link_content.name = o.list_name;
                this.isGo = false;
            },
            clickSave() {
                this.pageT.clickSave('/page/change_template', {cPage: this.local.cPage}, '_ch_tpl')
                Bus.$once('zResponse_ch_tpl', response => {
                    this.isGo = true;
                    this.local.cPage = response.cPage;
                    Bus.$emit('updListMenu', response.menu);
                    if (this.isReset) this.clickGo();
                });
            },
        },
        computed: {
            fListTypePage: function () {
                if (this.local.cPage.type_list === 'razdel')
                    return this.listTypePage.filter(item => item.isOnlyForRazdel);
                else
                    return this.listTypePage.filter(item => (item.isOnlyContent || item.name === this.local.cPage.type_list) && item.name !== 'trn');
            },
        },
        directives: {
            focus: {
                inserted: function (el) {
                    el.focus()
                }
            }
        }
    }
</script>
