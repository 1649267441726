var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "item-container",
      attrs: {
        tag: "div",
        list: _vm.list,
        value: _vm.value,
        animation: "150",
        "fallback-on-body": true,
        "swap-threshold": 0.5,
        "scroll-sensitivity": 100,
        "fallback-tolerance": 1,
        "force-fallback": true,
        "ghost-class": "ghost",
        group: "as",
        disabled: !_vm.pState.catEdit,
      },
      on: { input: _vm.emitter },
    },
    _vm._l(_vm.realValue, function (el) {
      return _c(
        "div",
        { staticClass: "item-group" },
        [
          _c(
            "div",
            {
              key: el.id,
              staticClass: "row m-0 item",
              class: { active: el.id === _vm.pState.activeRow.id },
              on: {
                click: function ($event) {
                  return _vm.clickRow(el)
                },
              },
            },
            [
              _c("div", { staticClass: "col-12" }, [
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(el.name) + " " + _vm._s(el.id)),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("nested-category", {
            staticClass: "item-sub",
            attrs: { list: el.child, id: el.id, "p-state": _vm.state },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }