var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "item-container",
      attrs: {
        tag: "div",
        list: _vm.list,
        value: _vm.value,
        handle: ".handle",
        animation: "150",
        group: "nested",
        "fallback-on-body": true,
        "swap-threshold": 0.5,
        "ghost-class": "ghost",
        filter: ".no-drag",
        "scroll-sensitivity": 100,
        "fallback-tolerance": 1,
        "force-fallback": true,
      },
      on: { input: _vm.emitter, change: _vm.onChange },
    },
    _vm._l(_vm.realValue, function (el) {
      return _c(
        "div",
        { key: el.id, staticClass: "item-group" },
        [
          el.t === "element"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                    "not-visible": !el.isVisible,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-11 text-left pl-0",
                          class: { "bg-danger-l": el.isEmpty },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(el.element.name) +
                              "\n                        "
                          ),
                          el.toRazdel
                            ? _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold ml-2",
                                  attrs: { title: el.toRazdel },
                                },
                                [_vm._v("R")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(_vm._s(el.element.marka)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(_vm._s(el.element.article)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(el.element.brand)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _vm._v(_vm._s(el.element.unit)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-1",
                          class: {
                            "bg-success-l":
                              el.id_parent_gen && el.id_parent_gen !== "0",
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(el.val.v_out))]),
                          el.val.resp.eMess
                            ? _c(
                                "span",
                                {
                                  staticClass: "text-danger",
                                  attrs: { title: el.val.resp.eMess },
                                },
                                [_vm._v(" error")]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-2",
                          attrs: { title: "вес и примечание" },
                        },
                        [
                          _vm._v(_vm._s(el.element.wt)),
                          _c("br"),
                          _vm._v(_vm._s(el.element.desc)),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "element_base"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                    "not-visible": !el.isVisible,
                  },
                  staticStyle: { "background-color": "#f6f3cb" },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-11 text-left pl-0",
                          class: { "bg-danger-l": el.isEmpty },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(el.element.name) +
                              "\n                        "
                          ),
                          el.toRazdel
                            ? _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold ml-2",
                                  attrs: { title: el.toRazdel },
                                },
                                [_vm._v("R")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(_vm._s(el.element.marka)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(_vm._s(el.element.article)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(el.element.brand)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }, [
                        _vm._v(_vm._s(el.element.unit)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1 bg-success-l" }, [
                        _c("span", [_vm._v(_vm._s(el.val.v_out))]),
                        el.val.resp.eMess
                          ? _c(
                              "span",
                              {
                                staticClass: "text-danger",
                                attrs: { title: el.val.resp.eMess },
                              },
                              [_vm._v(" error")]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-2",
                          attrs: { title: "вес и примечание" },
                        },
                        [
                          _vm._v(_vm._s(el.element.wt)),
                          _c("br"),
                          _vm._v(_vm._s(el.element.desc)),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "block_panel"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                    razdel: el.isRazdel,
                    "not-visible": !el.isVisible,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-11 text-left font-weight-bold" },
                        [_vm._v(_vm._s(el.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12 text-left" }, [
                        el.dParam.sech
                          ? _c("span", [
                              _vm._v("S "),
                              _c("b", [_vm._v(_vm._s(el.dParam.sech.sech))]),
                              _vm._v(" h50 "),
                              _c("b", [_vm._v(_vm._s(el.dParam.sech.h50))]),
                              _vm._v(" h100 "),
                              _c("b", [_vm._v(_vm._s(el.dParam.sech.h100))]),
                              _vm._v(" модулей: "),
                              _c("b", [_vm._v(_vm._s(el.dParam.dim.module))]),
                              _vm._v("шт."),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "block_cable" || el.t === "block_cable_fr"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                    razdel: el.isRazdel,
                    "not-visible": !el.isVisible,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-11 font-weight-bold text-left" },
                        [_vm._v(_vm._s(el.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12 text-left" }, [
                        el.dParam.sech
                          ? _c(
                              "span",
                              _vm._l(el.dParam.sech, function (el2) {
                                return _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(el2.name) + " "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(el2.xy) + "/" + _vm._s(el2.all)
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "block_light"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                    razdel: el.isRazdel,
                    "not-visible": !el.isVisible,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-11 font-weight-bold text-left" },
                        [_vm._v(_vm._s(el.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12 text-left" }, [
                        el.dParam.count
                          ? _c("span", [
                              _vm._v("Распаечные коробки: "),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(el.dParam.count)),
                              ]),
                              _vm._v("шт."),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "block_potr"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                    razdel: el.isRazdel,
                    "not-visible": !el.isVisible,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-11 font-weight-bold text-left" },
                        [_vm._v(_vm._s(el.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12 text-left" }, [
                        el.dParam.count
                          ? _c("span", [
                              _vm._v("Распаечные коробки: "),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(el.dParam.count)),
                              ]),
                              _vm._v("шт."),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "block_other"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                    razdel: el.isRazdel,
                    "not-visible": !el.isVisible,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-11 font-weight-bold text-left" },
                        [_vm._v(_vm._s(el.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12 text-left" }, [
                        el.dParam.count
                          ? _c("span", [
                              _vm._v("Распаечные коробки: "),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(el.dParam.count)),
                              ]),
                              _vm._v("шт."),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "block"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                    razdel: el.isRazdel,
                    "not-visible": !el.isVisible,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-11 font-weight-bold text-left" },
                        [_vm._v(_vm._s(el.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 text-left" },
                        _vm._l(el.v, function (el2) {
                          return _c(
                            "span",
                            { staticClass: "mr-3", attrs: { title: el2.name } },
                            [
                              _vm._v(_vm._s(el2.id_f) + " "),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(el2.v_out)),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          el.t === "razdel"
            ? _c(
                "div",
                {
                  staticClass: "item row",
                  class: {
                    "bg-primary-l": _vm.isSelect(el),
                    "bg-success-l": _vm.isActiveSave(el),
                    "bg-danger-l": el.isDelete,
                  },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.onShowContext($event, el)
                    },
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRow(el)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "col-5 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-1 handle" }, [
                        _vm._v(_vm._s(_vm.getT(el))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-11 font-weight-bold text-left" },
                        [_vm._v(_vm._s(el.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 p-0" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12 text-left" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("nested-specification", {
            staticClass: "item-sub ml-3",
            attrs: {
              list: el.child,
              level: _vm.level + 1,
              id: el.id,
              local: _vm.local,
              suffix: _vm.suffix,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }