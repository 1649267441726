var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c(
              "draggable",
              {
                attrs: {
                  list: _vm.local.projectInfo.fio,
                  "ghost-class": "ghost",
                },
              },
              _vm._l(_vm.local.projectInfo.fio, function (el, index) {
                return _c("div", { key: index, staticClass: "row p-0 m-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-4 m-0 p-0" },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.local.listFioA.map(
                              (item) => item.name
                            ),
                            searchable: true,
                            "close-on-select": true,
                            "show-labels": false,
                            "allow-empty": false,
                            "custom-label": (opt) =>
                              _vm.toolM.labelSearch_3(_vm.local.listFioA, opt),
                            placeholder: "должность",
                            "tag-placeholder": "добавить",
                            taggable: true,
                            id: "_" + index,
                          },
                          on: { tag: _vm.addNewFioA },
                          model: {
                            value: el.a,
                            callback: function ($$v) {
                              _vm.$set(el, "a", $$v)
                            },
                            expression: "el.a",
                          },
                        },
                        [
                          _c("template", { slot: "beforeList" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "form-control btn btn-sm btn-outline-primary p-0",
                                on: {
                                  click: function ($event) {
                                    el.a = null
                                  },
                                },
                              },
                              [_vm._v("очистить")]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-5 m-0 p-0" },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.local.listFio,
                            searchable: true,
                            "close-on-select": true,
                            "show-labels": false,
                            "allow-empty": false,
                            placeholder: "фио",
                            "tag-placeholder": "добавить",
                            taggable: true,
                            id: "_" + index,
                            label: "name",
                            "track-by": "id",
                          },
                          on: { tag: _vm.addNewFio },
                          model: {
                            value: el.b,
                            callback: function ($$v) {
                              _vm.$set(el, "b", $$v)
                            },
                            expression: "el.b",
                          },
                        },
                        [
                          _c("template", { slot: "beforeList" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "form-control btn btn-sm btn-outline-primary p-0",
                                on: {
                                  click: function ($event) {
                                    el.b = null
                                  },
                                },
                              },
                              [_vm._v("очистить")]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-1 m-0 p-0" }, [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: { src: _vm.getImgSrc(el), alt: "" },
                      on: { error: _vm.replaceImgByClear },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2 m-0 p-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: el.d,
                          expression: "el.d",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { placeholder: "" },
                      domProps: { value: el.d },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(el, "d", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-4 text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "form-control btn btn-secondary",
                    on: { click: _vm.showModalFioA },
                  },
                  [_vm._v("участники")]
                ),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "col-1 p-0 m-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-2 pl-0" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newDate,
                      expression: "newDate",
                    },
                  ],
                  staticClass: "form-control w-100",
                  domProps: { value: _vm.newDate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newDate = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-9" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local.projectInfo.number,
                    expression: "local.projectInfo.number",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "шифр проекта" },
                domProps: { value: _vm.local.projectInfo.number },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local.projectInfo,
                      "number",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("multiselect", {
                  attrs: {
                    options: _vm.listStage.map((item) => item.name),
                    "custom-label": (opt) =>
                      _vm.toolM.labelSearch_3(_vm.listStage, opt),
                    searchable: false,
                    "close-on-select": true,
                    "show-labels": false,
                    "allow-empty": false,
                  },
                  model: {
                    value: _vm.local.projectInfo.stage,
                    callback: function ($$v) {
                      _vm.$set(_vm.local.projectInfo, "stage", $$v)
                    },
                    expression: "local.projectInfo.stage",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local.projectInfo.address,
                    expression: "local.projectInfo.address",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "объект проектирования" },
                domProps: { value: _vm.local.projectInfo.address },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local.projectInfo,
                      "address",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.local.projectInfo.name_project,
                    expression: "local.projectInfo.name_project",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "название проекта " },
                domProps: { value: _vm.local.projectInfo.name_project },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.local.projectInfo,
                      "name_project",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-list-fio-a", {
        attrs: { "list-fio": _vm.nbi.authUser.prop.setting.listFioA },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5 text-center" }, [
      _c(
        "button",
        {
          staticClass: "form-control btn btn-secondary",
          attrs: { "data-toggle": "modal", "data-target": "#myModalFio" },
        },
        [_vm._v("подписи")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }