var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.state.search.val,
                expression: "state.search.val",
                modifiers: { trim: true },
              },
            ],
            ref: "search",
            staticClass: "form-control mb-2",
            domProps: { value: _vm.state.search.val },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.state.search, "val", $event.target.value.trim())
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-primary", on: { click: _vm.clickReset } },
            [_vm._v("reset")]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("nested-specification", {
            staticClass: "item-sub",
            attrs: { local: _vm.local },
            model: {
              value: _vm.local.table,
              callback: function ($$v) {
                _vm.$set(_vm.local, "table", $$v)
              },
              expression: "local.table",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-so", {
        attrs: {
          "modal-id": "modalSo",
          "p-local": _vm.local,
          "p-state": _vm.state,
        },
        on: { updInit: _vm.init, selectObj: _vm.onSelectObjFromBase },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row zag" }, [
      _c("div", { staticClass: "col-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2" }, [_vm._v("позиция")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10" }, [_vm._v("название")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-7" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }, [_vm._v("марка")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [_vm._v("артикул")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("поставщик")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [_vm._v("ед-изм")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [_vm._v("кол-во")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("примечение")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }