<template>
    <div :id="modalId" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">импортировать файл с базой оборудования</div>

                <div class="modal-body">
                    <div class="col-12">
                            <span class="mr-2" style="cursor: pointer"
                                  v-for="el in partners"
                                  :key="el.id"
                                  :class="{'font-weight-bold':el.id===state.selectPartner.id}"
                                  @click="clickSwPartner(el)"
                            >{{ el.name }}</span>
                    </div>

                    <vue-dropzone
                        id="dropzone"
                        ref="dropzone"
                        :options="dropzoneOptionsAttaches"
                        :useCustomSlot=true
                        @vdropzone-success="dropzoneSuccessAttach"
                        v-on:vdropzone-sending="addParamAttach"
                    >
                        <div class="dropzone-custom-content">
                            <h3 class="dropzone-custom-title">Перетащите сюда файл</h3>
                            <div class="subtitle">...или кликните для выбора файла на компьютере</div>
                        </div>
                    </vue-dropzone>
                </div>
                <div class="modal-footer">
                    <button v-if="state.id" type="button" class="btn btn-default text-danger" @click="clickDelete">удалить</button>
                    <button v-if="state.id" type="button" class="btn btn-primary" @click="clickSave">сохранить</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">закрыть</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import moment from "moment";
import {ru} from "vuejs-datepicker/dist/locale";
import CCollapseBlock from "./CCollapseBlock";
import vue2Dropzone from "vue2-dropzone";

export default {
    name: 'modal-file-upload',
    components: {Datepicker, Multiselect, vueDropzone: vue2Dropzone},
    props: {
        modalId: {type: String, default: 'myModal',},
        acceptedFiles: {type: String, default: '',},
        maxFileSize: {type: Number, default: null,},
        partners: {type: Array, default: [],},
    },
    data() {
        return {
            dropzoneOptionsAttaches: {
                url: 'baseps/upload',
                thumbnailWidth: 150,
                maxFileSize: this.maxFileSize,
                headers: {'X-CSRF-TOKEN': document.head.querySelector("[name=csrf-token]").content,},
                acceptedFiles: this.acceptedFiles,
                maxFiles: 1,
                init: function () {
                    this.on("maxfilesexceeded", function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    });
                }
            },
            ru: ru,
            attachTemplate: {
                id: null,
                name: '',
            },
            state: {
                id: null,
                name: '',
                selectPartner: {
                    id: '',
                },
            },
        }
    },
    created: function () {
        Bus.$on('showModalFileUpload', obj => this.showModal(obj));
        this.state.selectPartner=this.partners[0];
    },
    methods: {
        clickSwPartner(obj) {
            this.state.selectPartner = obj;
        },
        dropzoneSuccessAttach(file, response) {
            $('#' + this.modalId).modal('hide');
            if (response.error === undefined || response.error.length === 0) {
                this.flash('файл успешно загружен', 'success', {timeout: 2000});
                this.clickSave();
            } else {
                let str = '';
                response.error.forEach(item => str += item.message + '<br>');
                this.flash(str, 'error')
            }
        },
        addParamAttach(file, xhr, formData) {
            formData.append('partner', JSON.stringify(this.state.selectPartner));
        },
        showModal(obj) {
            // if (obj) {
            //     this.state = Object.assign({}, obj);
            // } else {
            //     this.state = this.attachTemplate;
            // }

            $('#' + this.modalId).modal('show');
            // setTimeout(() => this.$refs.v_focus.focus(), 500);
        },
        clickSave() {
            Bus.$emit('modalAttachEditSave', this.state);
            $('#' + this.modalId).modal('hide');
        },
        clickDelete() {
            Bus.$emit('modalAttachEditDelete', this.state);
            $('#' + this.modalId).modal('hide');
        },
    },
}
</script>
