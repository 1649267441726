var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSetList
    ? _c("div", { staticClass: "row justify-content-center mb-0" }, [
        _c("div", { staticClass: "col-12 col-xl-8" }, [
          _c("h1", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm.cPage.stamp.list_name)),
          ]),
          _vm._v(" "),
          _c("h6", { staticClass: "mt-0", staticStyle: { opacity: "0.5" } }, [
            _vm.cPage.stamp.list_num_global
              ? _c("span", [
                  _vm._v("л." + _vm._s(_vm.cPage.stamp.list_num_global)),
                ])
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.getTypeList) + " /\n            "),
            _vm.lockLink || _vm.isSettingPage
              ? _c("span", [_vm._v(_vm._s(_vm.cPage.link_content.name))])
              : _c(
                  "span",
                  {
                    staticStyle: {
                      cursor: "pointer",
                      "text-decoration": "underline",
                    },
                    attrs: { title: "изменить контент страницы" },
                    on: { click: _vm.clickSetting },
                  },
                  [_vm._v(_vm._s(_vm.cPage.link_content.name))]
                ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }