var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-fluid m1" },
      [
        _c("title-for-page", {
          attrs: { "c-page": _vm.state.cPage, nbi: _vm.nbi },
        }),
        _vm._v(" "),
        _vm.isMounted
          ? _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-12 col-xl-8" },
                [
                  !!_vm.state.cPage.id
                    ? _c(
                        "nav",
                        {
                          staticClass: "navbar navbar-expand pl-0 pt-0",
                          staticStyle: { "margin-left": "-0.5rem" },
                        },
                        [
                          _c("div", { staticClass: "container-fluid" }, [
                            _c("div", { staticClass: "navbar-nav" }, [
                              _c("div", { staticClass: "nav-item" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "nav-link btn",
                                    class: { active: _vm.state.swOnOpt },
                                    attrs: { title: "настройки страницы" },
                                    on: {
                                      click: function ($event) {
                                        _vm.state.swOnOpt = !_vm.state.swOnOpt
                                      },
                                    },
                                  },
                                  [_vm._v("настройки")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "nav-item" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "nav-link btn",
                                    class: { active: _vm.state.swOnOpt2 },
                                    attrs: { title: "настройки страницы" },
                                    on: {
                                      click: function ($event) {
                                        _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                      },
                                    },
                                  },
                                  [_vm._v("настройки таблицы")]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("card-options", {
                    staticClass: "mb-3",
                    attrs: { "c-page": _vm.state.cPage, state: _vm.state },
                  }),
                  _vm._v(" "),
                  _vm.state.swOnOpt2
                    ? _c("div", { staticClass: "card mb-3" }, [
                        _c("div", { staticClass: "card-body" }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.state.cPage.id
                    ? _c("h1", { staticClass: "text-center mb-5" }, [
                        _vm._v("Проверка кабеля на термическую стойкость"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.typeReport,
                            expression: "cOpt.data.setting.typeReport",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "radio", value: "long" },
                        domProps: {
                          checked: _vm._q(
                            _vm.cOpt.data.setting.typeReport,
                            "long"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.cOpt.data.setting,
                              "typeReport",
                              "long"
                            )
                          },
                        },
                      }),
                      _vm._v(" подробный отчёт\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-3" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.typeReport,
                            expression: "cOpt.data.setting.typeReport",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "radio", value: "short" },
                        domProps: {
                          checked: _vm._q(
                            _vm.cOpt.data.setting.typeReport,
                            "short"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.cOpt.data.setting,
                              "typeReport",
                              "short"
                            )
                          },
                        },
                      }),
                      _vm._v(" краткий отчёт\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger ml-2",
                      staticStyle: { float: "right" },
                      attrs: { type: "button" },
                      on: { click: _vm.clickReset },
                    },
                    [
                      _vm.status.reset
                        ? _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw",
                          })
                        : _vm._e(),
                      _vm._v(" reset\n                "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-5 border-top pt-3" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "label",
                          { staticClass: "small w-100 text-left mb-0" },
                          [
                            _vm._v(
                              "\n                            регион\n                            "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ml-3 text-danger small",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.cOpt.data.cityObj = { id: null }
                                  },
                                },
                              },
                              [_vm._v("сброс")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("multiselect", {
                          ref: "ms4",
                          staticClass: "mb-2",
                          attrs: {
                            options: _vm.local.listCity,
                            "show-labels": false,
                            searchable: true,
                            "close-on-select": true,
                            "allow-empty": false,
                            "internal-search": false,
                            blockKeys: ["Delete"],
                            placeholder: "регион",
                          },
                          on: {
                            "search-change": _vm.customSearch4,
                            open: function ($event) {
                              return _vm.onOpen4("ms4")
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "singleLabel",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "option__desc" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "option__title" },
                                          [
                                            _vm._v(
                                              _vm._s(props.option.city) +
                                                " (" +
                                                _vm._s(props.option.region) +
                                                ")"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "option",
                                fn: function (props) {
                                  return [
                                    _c("div", { staticClass: "option__desc" }, [
                                      _c(
                                        "span",
                                        { staticClass: "option__title" },
                                        [
                                          _vm._v(
                                            _vm._s(props.option.city) +
                                              " (" +
                                              _vm._s(props.option.region) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2711289441
                          ),
                          model: {
                            value: _vm.cOpt.data.cityObj,
                            callback: function ($$v) {
                              _vm.$set(_vm.cOpt.data, "cityObj", $$v)
                            },
                            expression: "cOpt.data.cityObj",
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "small w-100 text-left" }, [
                          _vm._v(
                            "\n                            температура окружающей среды в регионе, °C\n                            "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "ml-2",
                              staticStyle: {
                                opacity: "0.8",
                                cursor: "pointer",
                                color: "darkgoldenrod",
                              },
                            },
                            [
                              _vm.cOpt.data.t_os_fact.lock
                                ? _c("font-awesome-icon", {
                                    attrs: {
                                      size: "sm",
                                      icon: "fa-solid fa-lock",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.cOpt.data.t_os_fact.lock = false
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.cOpt.data.t_os_fact.lock
                                ? _c("font-awesome-icon", {
                                    attrs: {
                                      size: "sm",
                                      icon: "fa-solid fa-lock-open",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.cOpt.data.t_os_fact.lock = true
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.cOpt.data.t_os_fact.val,
                                expression: "cOpt.data.t_os_fact.val",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: _vm.cOpt.data.t_os_fact.lock },
                            domProps: { value: _vm.cOpt.data.t_os_fact.val },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.cOpt.data.t_os_fact,
                                  "val",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row border-top pt-3" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.cOpt.data.t_os,
                            expression: "cOpt.data.t_os",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        domProps: { value: _vm.cOpt.data.t_os },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.cOpt.data,
                              "t_os",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-left mt-1" }, [
                        _c(
                          "div",
                          { staticClass: "form-check d-inline-block" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cOpt.data.cable.isInAir,
                                    expression: "cOpt.data.cable.isInAir",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "radio" },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.cOpt.data.cable.isInAir,
                                    false
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.cOpt.data.t_os = 15
                                  },
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.cOpt.data.cable,
                                      "isInAir",
                                      false
                                    )
                                  },
                                },
                              }),
                              _vm._v(
                                " земля\n                                "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-check d-inline-block ml-3" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cOpt.data.cable.isInAir,
                                    expression: "cOpt.data.cable.isInAir",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "radio" },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.cOpt.data.cable.isInAir,
                                    true
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.cOpt.data.t_os = 25
                                  },
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.cOpt.data.cable,
                                      "isInAir",
                                      true
                                    )
                                  },
                                },
                              }),
                              _vm._v(
                                " воздух\n                                "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row border-top pt-3" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _vm._v(
                        "\n                        расчётная точка\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _vm.ePotr.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "form-check d-inline-block" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "form-check-label" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.cOpt.data.calcPoint.type,
                                          expression:
                                            "cOpt.data.calcPoint.type",
                                        },
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: { type: "radio", value: "eBbs" },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.cOpt.data.calcPoint.type,
                                          "eBbs"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.cOpt.data.calcPoint,
                                            "type",
                                            "eBbs"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(
                                      " секция шин\n                                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-check d-inline-block ml-3" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "form-check-label" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.cOpt.data.calcPoint.type,
                                          expression:
                                            "cOpt.data.calcPoint.type",
                                        },
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: { type: "radio", value: "ePotr" },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.cOpt.data.calcPoint.type,
                                          "ePotr"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.cOpt.data.calcPoint,
                                            "type",
                                            "ePotr"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(
                                      " потребитель\n                                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "ml-3 text-danger small",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.cOpt.data.calcPoint.id = 0
                                  },
                                },
                              },
                              [_vm._v("сброс")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-10" }, [
                                _vm.cOpt.data.calcPoint.type === "ePotr"
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("multiselect", {
                                          attrs: {
                                            options: _vm.local.ePotr.map(
                                              (item) => item.id
                                            ),
                                            "show-labels": false,
                                            searchable: true,
                                            "close-on-select": true,
                                            "allow-empty": false,
                                            "internal-search": false,
                                          },
                                          on: {
                                            input: _vm.clickSave,
                                            "search-change": _vm.customSearch,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "singleLabel",
                                                fn: function (props) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "option__desc",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "option__title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getMSStr(
                                                                  props.option
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "option",
                                                fn: function (props) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "option__desc",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "option__title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getMSStr(
                                                                  props.option
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3522616897
                                          ),
                                          model: {
                                            value: _vm.cOpt.data.calcPoint.id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.cOpt.data.calcPoint,
                                                "id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "cOpt.data.calcPoint.id",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cOpt.data.calcPoint.type === "eBbs"
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("multiselect", {
                                          attrs: {
                                            options: _vm.local.eBbs.map(
                                              (item) => item.id
                                            ),
                                            "show-labels": false,
                                            searchable: true,
                                            "close-on-select": true,
                                            "allow-empty": false,
                                            "internal-search": false,
                                          },
                                          on: {
                                            input: _vm.clickSave,
                                            "search-change":
                                              _vm.customSearchBbs,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "singleLabel",
                                                fn: function (props) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "option__desc",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "option__title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getMSStrBbs(
                                                                  props.option
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "option",
                                                fn: function (props) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "option__desc",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "option__title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getMSStrBbs(
                                                                  props.option
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1113787233
                                          ),
                                          model: {
                                            value: _vm.cOpt.data.calcPoint.id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.cOpt.data.calcPoint,
                                                "id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "cOpt.data.calcPoint.id",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row border-top pt-3" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _vm._v(
                        "\n                        устройство защиты\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        !_vm.cOpt.data.calcPoint.id
                          ? _c("multiselect", {
                              ref: "ms1",
                              staticClass: "mb-3 mt-4",
                              attrs: {
                                placeholder: "аппарат защиты",
                                options: _vm.local.listBaseAvt,
                                "option-height": 124,
                                "show-labels": false,
                                searchable: true,
                                "close-on-select": true,
                                "allow-empty": false,
                                "internal-search": false,
                                blockKeys: ["Delete"],
                              },
                              on: {
                                "search-change": _vm.customSearch3,
                                open: function ($event) {
                                  return _vm.onOpen("ms1")
                                },
                                input: _vm.upd,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "singleLabel",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "option__desc" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "option__title" },
                                              [
                                                _vm._v(
                                                  "\n                                                            " +
                                                    _vm._s(
                                                      _vm.getMSStrAvt(
                                                        props.option.uid,
                                                        "marka"
                                                      )
                                                    ) +
                                                    "\n                                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "option",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "option__desc" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "option__title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getMSStrAvt(
                                                      props.option.uid,
                                                      "marka"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "option__small small",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getMSStrAvt(
                                                      props.option.uid,
                                                      "brand"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                832508266
                              ),
                              model: {
                                value: _vm.cOpt.data.avt.obj,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cOpt.data.avt, "obj", $$v)
                                },
                                expression: "cOpt.data.avt.obj",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cOpt.data.avt.obj.uid
                          ? _c("div", [
                              _vm.cOpt.data.calcPoint.id && _vm.cOpt.data.isEnd
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "small mb-0",
                                      staticStyle: {
                                        "background-color": "orange",
                                      },
                                    },
                                    [_vm._v("защита в конце линии")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.cOpt.data.calcPoint.id
                                ? _c("p", { staticClass: "small" }, [
                                    _vm._v("информация из однолинейной схемы"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(_vm._s(_vm.cOpt.data.avt.obj.marka)),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  "Iном = " +
                                    _vm._s(_vm.cOpt.data.avt.obj.fields.iots) +
                                    "А"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  "Icn = " +
                                    _vm._s(_vm.cOpt.data.avt.obj.fields.icn) +
                                    "кА"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  "характеристика: " +
                                    _vm._s(_vm.cOpt.data.avt.obj.fields.har)
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "w-100 small",
                          attrs: { title: "Номинальный ток аппарата защиты" },
                        },
                        [
                          _vm._v(
                            "\n                            Iном\n                            "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "ml-2",
                              staticStyle: {
                                opacity: "0.8",
                                cursor: "pointer",
                                color: "darkgoldenrod",
                              },
                            },
                            [
                              _vm.cOpt.data.avt.iots.lock
                                ? _c("font-awesome-icon", {
                                    attrs: {
                                      size: "sm",
                                      icon: "fa-solid fa-lock",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.cOpt.data.avt.iots.lock = false
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.cOpt.data.avt.iots.lock
                                ? _c("font-awesome-icon", {
                                    attrs: {
                                      size: "sm",
                                      icon: "fa-solid fa-lock-open",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.cOpt.data.avt.iots.lock = true
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.cOpt.data.avt.iots.val,
                                expression: "cOpt.data.avt.iots.val",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: _vm.cOpt.data.avt.iots.lock },
                            domProps: { value: _vm.cOpt.data.avt.iots.val },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.cOpt.data.avt.iots,
                                  "val",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "mb-0 w-100",
                          attrs: {
                            title:
                              "Характеристика срабатывания аппарата защиты",
                          },
                        },
                        [
                          _c("span", { staticClass: "small" }, [
                            _vm._v("характеристика срабатывания"),
                          ]),
                          _vm._v(" "),
                          _c("multiselect", {
                            attrs: {
                              options: _vm.listHar.map((item) => item.id),
                              "close-on-select": true,
                              "show-labels": false,
                              "allow-empty": false,
                              searchable: false,
                              disabled: _vm.cOpt.data.avt.iots.lock,
                              placeholder: "характеристика срабатывания",
                              "custom-label": (opt) =>
                                _vm.listHar.find((item) => item.id === opt)
                                  .name,
                            },
                            model: {
                              value: _vm.cOpt.data.avt.har.val,
                              callback: function ($$v) {
                                _vm.$set(_vm.cOpt.data.avt.har, "val", $$v)
                              },
                              expression: "cOpt.data.avt.har.val",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row border-top pt-3" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        !_vm.cOpt.data.calcPoint.id
                          ? _c("multiselect", {
                              ref: "ms2",
                              staticClass: "mb-3 mt-4",
                              attrs: {
                                placeholder: "питающий кабель",
                                options: _vm.local.listBaseCable,
                                "option-height": 124,
                                "show-labels": false,
                                searchable: true,
                                "close-on-select": true,
                                "allow-empty": false,
                                "internal-search": false,
                                blockKeys: ["Delete"],
                              },
                              on: {
                                input: _vm.upd,
                                "search-change": _vm.customSearch2,
                                open: function ($event) {
                                  return _vm.onOpen2("ms2")
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "singleLabel",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "option__desc" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "option__title" },
                                              [
                                                _vm._v(
                                                  "\n                                                            " +
                                                    _vm._s(
                                                      _vm.getMSStrCable(
                                                        props.option.uid,
                                                        "marka"
                                                      )
                                                    ) +
                                                    "\n                                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "option",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "option__desc" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "option__title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getMSStrCable(
                                                      props.option.uid,
                                                      "marka"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "option__small small",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getMSStrCable(
                                                      props.option.uid,
                                                      "brand"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1525064576
                              ),
                              model: {
                                value: _vm.cOpt.data.cable.obj,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cOpt.data.cable, "obj", $$v)
                                },
                                expression: "cOpt.data.cable.obj",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cOpt.data.cable.obj.uid
                          ? _c("div", [
                              _vm.cOpt.data.calcPoint.id
                                ? _c("p", { staticClass: "small" }, [
                                    _vm._v("информация из однолинейной схемы"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", { staticClass: "m-0" }, [
                                _vm.cOpt.data.cable.number.val > 1
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.cOpt.data.cable.number.val) +
                                          "х"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(_vm._s(_vm.cOpt.data.cable.obj.marka)),
                              ]),
                              _vm._v(" "),
                              _vm.cOpt.data.cable.number.val > 1
                                ? _c("p", { staticClass: "m-0" }, [
                                    _vm._v(
                                      "количество ниток кабеля: х" +
                                        _vm._s(_vm.cOpt.data.cable.number.val)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "m-0",
                                  class: {
                                    "bg-danger-l":
                                      _vm.getNormIByType("iacv") === 0,
                                  },
                                },
                                [
                                  _vm._v(
                                    "в воздухе Iдоп = " +
                                      _vm._s(_vm.getNormIByType("iacv")) +
                                      "А"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "m-0",
                                  class: {
                                    "bg-danger-l":
                                      _vm.getNormIByType("iacz") === 0,
                                  },
                                },
                                [
                                  _vm._v(
                                    "в земле Iдоп = " +
                                      _vm._s(_vm.getNormIByType("iacz")) +
                                      "А"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "m-0",
                                  class: {
                                    "bg-danger-l":
                                      !_vm.cOpt.data.cable.obj.fields.sech,
                                  },
                                },
                                [
                                  _vm._v(
                                    "сечение: " +
                                      _vm._s(
                                        _vm.cOpt.data.cable.obj.fields.sech
                                      ) +
                                      "мм"
                                  ),
                                  _c("sup", [_vm._v("2")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "m-0",
                                  class: {
                                    "bg-danger-l":
                                      !_vm.cOpt.data.cable.obj.fields.u,
                                  },
                                },
                                [
                                  _vm._v(
                                    "U = " +
                                      _vm._s(_vm.cOpt.data.cable.obj.fields.u) +
                                      "кВ"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "m-0",
                                  class: {
                                    "bg-danger-l":
                                      !_vm.cOpt.data.cable.obj.fields.isCu,
                                  },
                                },
                                [
                                  _vm._v(
                                    "материал жилы:\n                                "
                                  ),
                                  !!_vm.cOpt.data.cable.obj.fields.isCu
                                    ? _c("span", [_vm._v("медь")])
                                    : _c("span", [_vm._v("алюминий")]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.cOpt.data.cable.number.val > 1
                                ? _c("p", { staticClass: "m-0 text-danger" }, [
                                    _vm._v(
                                      "расчёт производится при повреждении на одной нитке кабеля"
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _c("label", { staticClass: "w-100 small" }, [
                        _vm._v(
                          "\n                            количество ниток кабеля\n                            "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2",
                            staticStyle: {
                              opacity: "0.8",
                              cursor: "pointer",
                              color: "darkgoldenrod",
                            },
                          },
                          [
                            _vm.cOpt.data.cable.number.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.number.lock = false
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.cOpt.data.cable.number.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock-open",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.number.lock = true
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.cOpt.data.cable.number.val,
                              expression: "cOpt.data.cable.number.val",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { disabled: _vm.cOpt.data.cable.number.lock },
                          domProps: { value: _vm.cOpt.data.cable.number.val },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.cOpt.data.cable.number,
                                "val",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "w-100 small" }, [
                        _vm._v(
                          "\n                            рабочий ток кабеля, А\n                            "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2",
                            staticStyle: {
                              opacity: "0.8",
                              cursor: "pointer",
                              color: "darkgoldenrod",
                            },
                          },
                          [
                            _vm.cOpt.data.in.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.in.lock = false
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.cOpt.data.in.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock-open",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.in.lock = true
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.cOpt.data.in.val,
                              expression: "cOpt.data.in.val",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { disabled: _vm.cOpt.data.in.lock },
                          domProps: { value: _vm.cOpt.data.in.val },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.cOpt.data.in,
                                "val",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "small w-100 text-left" }, [
                        _vm._v(
                          "\n                            сечение жил кабеля, мм"
                        ),
                        _c("sup", [_vm._v("2")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2",
                            staticStyle: {
                              opacity: "0.8",
                              cursor: "pointer",
                              color: "darkgoldenrod",
                            },
                          },
                          [
                            _vm.cOpt.data.cable.sechenie.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.sechenie.lock = false
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.cOpt.data.cable.sechenie.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock-open",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.sechenie.lock = true
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.cOpt.data.cable.sechenie.val,
                              expression: "cOpt.data.cable.sechenie.val",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: _vm.cOpt.data.cable.sechenie.lock,
                          },
                          domProps: { value: _vm.cOpt.data.cable.sechenie.val },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.cOpt.data.cable.sechenie,
                                "val",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "small w-100 text-left" }, [
                        _vm._v(
                          "\n                            длительно допустимый ток кабеля, А\n                            "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2",
                            staticStyle: {
                              opacity: "0.8",
                              cursor: "pointer",
                              color: "darkgoldenrod",
                            },
                          },
                          [
                            _vm.cOpt.data.cable.i_dop.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.i_dop.lock = false
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.cOpt.data.cable.i_dop.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock-open",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.i_dop.lock = true
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.cOpt.data.cable.i_dop.val,
                              expression: "cOpt.data.cable.i_dop.val",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { disabled: _vm.cOpt.data.cable.i_dop.lock },
                          domProps: { value: _vm.cOpt.data.cable.i_dop.val },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.cOpt.data.cable.i_dop,
                                "val",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          { staticClass: "form-check d-inline-block" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cOpt.data.cable.u.val,
                                    expression: "cOpt.data.cable.u.val",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  disabled: _vm.cOpt.data.cable.u.lock,
                                },
                                domProps: {
                                  value: 1,
                                  checked: _vm._q(_vm.cOpt.data.cable.u.val, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.cOpt.data.cable.u,
                                      "val",
                                      1
                                    )
                                  },
                                },
                              }),
                              _vm._v(
                                " до 1кВ\n                                "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-check d-inline-block ml-3" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cOpt.data.cable.u.val,
                                    expression: "cOpt.data.cable.u.val",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  disabled: _vm.cOpt.data.cable.u.lock,
                                },
                                domProps: {
                                  value: 6,
                                  checked: _vm._q(_vm.cOpt.data.cable.u.val, 6),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.cOpt.data.cable.u,
                                      "val",
                                      6
                                    )
                                  },
                                },
                              }),
                              _vm._v(" 6кВ\n                                "),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-check d-inline-block ml-3" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cOpt.data.cable.u.val,
                                    expression: "cOpt.data.cable.u.val",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  disabled: _vm.cOpt.data.cable.u.lock,
                                },
                                domProps: {
                                  value: 10,
                                  checked: _vm._q(
                                    _vm.cOpt.data.cable.u.val,
                                    10
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.cOpt.data.cable.u,
                                      "val",
                                      10
                                    )
                                  },
                                },
                              }),
                              _vm._v(" 10кВ\n                                "),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2",
                            staticStyle: {
                              opacity: "0.8",
                              cursor: "pointer",
                              color: "darkgoldenrod",
                            },
                          },
                          [
                            _vm.cOpt.data.cable.u.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.u.lock = false
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.cOpt.data.cable.u.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock-open",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.u.lock = true
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-left mt-2" }, [
                        _c("label", { staticClass: "text-primary" }, [
                          _vm._v("алюминий "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.cOpt.data.cable.isCu.val,
                                expression: "cOpt.data.cable.isCu.val",
                              },
                            ],
                            staticClass: "apple-switch",
                            attrs: {
                              type: "checkbox",
                              disabled: _vm.cOpt.data.cable.isCu.lock,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.cOpt.data.cable.isCu.val
                              )
                                ? _vm._i(_vm.cOpt.data.cable.isCu.val, null) >
                                  -1
                                : _vm.cOpt.data.cable.isCu.val,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.cOpt.data.cable.isCu.val,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.cOpt.data.cable.isCu,
                                        "val",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.cOpt.data.cable.isCu,
                                        "val",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.cOpt.data.cable.isCu, "val", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" медь"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2",
                            staticStyle: {
                              opacity: "0.8",
                              cursor: "pointer",
                              color: "darkgoldenrod",
                            },
                          },
                          [
                            _vm.cOpt.data.cable.isCu.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.isCu.lock = false
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.cOpt.data.cable.isCu.lock
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    size: "sm",
                                    icon: "fa-solid fa-lock-open",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.cOpt.data.cable.isCu.lock = true
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          {
                            staticClass: "form-check d-inline-block",
                            attrs: {
                              title: "Кабель с ПВХ или резиновой изоляцией",
                            },
                          },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cOpt.data.cable.obolochka.val,
                                    expression: "cOpt.data.cable.obolochka.val",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "radio", value: "plastic" },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.cOpt.data.cable.obolochka.val,
                                    "plastic"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.cOpt.data.cable.obolochka,
                                      "val",
                                      "plastic"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" ПВХ\n                                "),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "form-check d-inline-block ml-3",
                            attrs: {
                              title:
                                "Кабель с изоляцией из вулканизированного полиэтилена",
                            },
                          },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cOpt.data.cable.obolochka.val,
                                    expression: "cOpt.data.cable.obolochka.val",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "radio", value: "v_plastic" },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.cOpt.data.cable.obolochka.val,
                                    "v_plastic"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.cOpt.data.cable.obolochka,
                                      "val",
                                      "v_plastic"
                                    )
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                                    ПЭТ\n                                "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "form-check d-inline-block",
                            attrs: {
                              title:
                                "Бронированный кабель с пропитанной бумажной изоляцией",
                            },
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "form-check-label ml-3" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cOpt.data.cable.obolochka.val,
                                      expression:
                                        "cOpt.data.cable.obolochka.val",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: {
                                    type: "radio",
                                    value: "bron_bum",
                                    disabled: "",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.cOpt.data.cable.obolochka.val,
                                      "bron_bum"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.cOpt.data.cable.obolochka,
                                        "val",
                                        "bron_bum"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(
                                  "\n                                    ББ\n                                "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "form-check d-inline-block ml-3",
                            attrs: {
                              title:
                                "Не бронированный кабель с пропитанной бумажной изоляцией",
                            },
                          },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cOpt.data.cable.obolochka.val,
                                    expression: "cOpt.data.cable.obolochka.val",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  value: "bum",
                                  disabled: "",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.cOpt.data.cable.obolochka.val,
                                    "bum"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.cOpt.data.cable.obolochka,
                                      "val",
                                      "bum"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" Б\n                                "),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.cOpt.data.calcPoint.id
                    ? _c("div", { staticClass: "row border-top pt-3" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _vm._v(
                            "\n                        виды токов КЗ\n                        "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-check d-inline-block" },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cOpt.data.setting.ikz3,
                                      expression: "cOpt.data.setting.ikz3",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.cOpt.data.setting.ikz3
                                    )
                                      ? _vm._i(
                                          _vm.cOpt.data.setting.ikz3,
                                          null
                                        ) > -1
                                      : _vm.cOpt.data.setting.ikz3,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.cOpt.data.setting.ikz3,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz3",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz3",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.cOpt.data.setting,
                                          "ikz3",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" 3хф КЗ\n                            "),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-check d-inline-block ml-3" },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cOpt.data.setting.ikz3r,
                                      expression: "cOpt.data.setting.ikz3r",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.cOpt.data.setting.ikz3r
                                    )
                                      ? _vm._i(
                                          _vm.cOpt.data.setting.ikz3r,
                                          null
                                        ) > -1
                                      : _vm.cOpt.data.setting.ikz3r,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.cOpt.data.setting.ikz3r,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz3r",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz3r",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.cOpt.data.setting,
                                          "ikz3r",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(
                                  " 3хфД КЗ\n                            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-check d-inline-block ml-3" },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cOpt.data.setting.ikz3sr,
                                      expression: "cOpt.data.setting.ikz3sr",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.cOpt.data.setting.ikz3sr
                                    )
                                      ? _vm._i(
                                          _vm.cOpt.data.setting.ikz3sr,
                                          null
                                        ) > -1
                                      : _vm.cOpt.data.setting.ikz3sr,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.cOpt.data.setting.ikz3sr,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz3sr",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz3sr",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.cOpt.data.setting,
                                          "ikz3sr",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(
                                  " 3хф.ср КЗ\n                            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-check d-inline-block" },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cOpt.data.setting.ikz2,
                                      expression: "cOpt.data.setting.ikz2",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.cOpt.data.setting.ikz2
                                    )
                                      ? _vm._i(
                                          _vm.cOpt.data.setting.ikz2,
                                          null
                                        ) > -1
                                      : _vm.cOpt.data.setting.ikz2,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.cOpt.data.setting.ikz2,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz2",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz2",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.cOpt.data.setting,
                                          "ikz2",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" 2хф КЗ\n                            "),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-check d-inline-block ml-3" },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cOpt.data.setting.ikz2r,
                                      expression: "cOpt.data.setting.ikz2r",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.cOpt.data.setting.ikz2r
                                    )
                                      ? _vm._i(
                                          _vm.cOpt.data.setting.ikz2r,
                                          null
                                        ) > -1
                                      : _vm.cOpt.data.setting.ikz2r,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.cOpt.data.setting.ikz2r,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz2r",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz2r",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.cOpt.data.setting,
                                          "ikz2r",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(
                                  " 2хфД КЗ\n                            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-check d-inline-block" },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cOpt.data.setting.ikz1,
                                      expression: "cOpt.data.setting.ikz1",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.cOpt.data.setting.ikz1
                                    )
                                      ? _vm._i(
                                          _vm.cOpt.data.setting.ikz1,
                                          null
                                        ) > -1
                                      : _vm.cOpt.data.setting.ikz1,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.cOpt.data.setting.ikz1,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz1",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz1",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.cOpt.data.setting,
                                          "ikz1",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" 1ф КЗ\n                            "),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-check d-inline-block ml-3" },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.cOpt.data.setting.ikz1r,
                                      expression: "cOpt.data.setting.ikz1r",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.cOpt.data.setting.ikz1r
                                    )
                                      ? _vm._i(
                                          _vm.cOpt.data.setting.ikz1r,
                                          null
                                        ) > -1
                                      : _vm.cOpt.data.setting.ikz1r,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.cOpt.data.setting.ikz1r,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz1r",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.cOpt.data.setting,
                                              "ikz1r",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.cOpt.data.setting,
                                          "ikz1r",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" 1фД КЗ\n                            "),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _vm._m(3),
                            _vm._v(" "),
                            _vm._l(
                              [
                                "kz3",
                                "kz3r",
                                "kz3sr",
                                "kz2",
                                "kz2r",
                                "kz1",
                                "kz1r",
                              ],
                              function (kk) {
                                return [
                                  _vm.cOpt.data.setting["i" + kk]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 font-weight-bold text-center",
                                        },
                                        [_vm._v(_vm._s(kk))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.cOpt.data.IkzArr, function (el) {
                                    return _vm.cOpt.data.setting["i" + kk]
                                      ? _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-2 text-center",
                                            },
                                            [_vm._v(_vm._s(el.l))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-2 text-center",
                                            },
                                            [_vm._v(_vm._s(el["i" + kk]))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-2 text-center",
                                            },
                                            [_vm._v(_vm._s(el["t" + kk]))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-2 text-center",
                                            },
                                            [_vm._v(_vm._s(el["k" + kk]))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-2 text-center",
                                            },
                                            [
                                              el["r" + kk] <=
                                              _vm.cOpt.data.tdop.t2
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "limegreen",
                                                      },
                                                      attrs: {
                                                        title:
                                                          "(" +
                                                          el["r" + kk] +
                                                          "<=" +
                                                          _vm.cOpt.data.tdop
                                                            .t2 +
                                                          ") возгорание отсутствует, кабели пригодны для дальнейшей эксплуатации",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(el["r" + kk])
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              el["r" + kk] >
                                                _vm.cOpt.data.tdop.t2 &&
                                              el["r" + kk] <=
                                                _vm.cOpt.data.tdop.t3
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "orange",
                                                      },
                                                      attrs: {
                                                        title:
                                                          "(" +
                                                          _vm.cOpt.data.tdop
                                                            .t2 +
                                                          "<" +
                                                          el["r" + kk] +
                                                          "<=" +
                                                          _vm.cOpt.data.tdop
                                                            .t3 +
                                                          ") возгорание отсутствует, допускается эксплуатация кабеля в течении одного года",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(el["r" + kk])
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              el["r" + kk] >
                                                _vm.cOpt.data.tdop.t3 &&
                                              el["r" + kk] <=
                                                _vm.cOpt.data.tdop.t1
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "palevioletred",
                                                      },
                                                      attrs: {
                                                        title:
                                                          "(" +
                                                          _vm.cOpt.data.tdop
                                                            .t3 +
                                                          "<" +
                                                          el["r" + kk] +
                                                          "<=" +
                                                          _vm.cOpt.data.tdop
                                                            .t1 +
                                                          ") возгорание отсутствует, кабель не пригоден для дальнейшей эксплуатации",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(el["r" + kk])
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              el["r" + kk] >
                                              _vm.cOpt.data.tdop.t1
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "palevioletred",
                                                      },
                                                      attrs: {
                                                        title:
                                                          "(" +
                                                          el["r" + kk] +
                                                          ">" +
                                                          _vm.cOpt.data.tdop
                                                            .t1 +
                                                          ") кабель не прошёл проверку на возгорание",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(el["r" + kk])
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ])
                                      : _vm._e()
                                  }),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.cOpt.data.calcPoint.id
                    ? _c("div", { staticClass: "row border-top pt-3" }, [
                        _vm._m(4),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.cOpt.data.ikz,
                                expression: "cOpt.data.ikz",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control",
                            domProps: { value: _vm.cOpt.data.ikz },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.cOpt.data,
                                  "ikz",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.cOpt.data.calcPoint.id
                    ? _c("div", { staticClass: "row border-top pt-3" }, [
                        _vm._m(5),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6" }, [
                          _c(
                            "label",
                            { staticClass: "small w-100 text-left" },
                            [
                              _vm._v(
                                "\n                            время действия резервной защиты,\n                            "
                              ),
                              _vm.cOpt.data.time_ots.lock
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.getHarName()) + ", "),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n                            сек\n                            "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2",
                                  staticStyle: {
                                    opacity: "0.8",
                                    cursor: "pointer",
                                    color: "darkgoldenrod",
                                  },
                                },
                                [
                                  _vm.cOpt.data.time_ots.lock
                                    ? _c("font-awesome-icon", {
                                        attrs: {
                                          size: "sm",
                                          icon: "fa-solid fa-lock",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.cOpt.data.time_ots.lock = false
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.cOpt.data.time_ots.lock
                                    ? _c("font-awesome-icon", {
                                        attrs: {
                                          size: "sm",
                                          icon: "fa-solid fa-lock-open",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.cOpt.data.time_ots.lock = true
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.cOpt.data.time_ots.val,
                                    expression: "cOpt.data.time_ots.val",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  disabled: _vm.cOpt.data.time_ots.lock,
                                },
                                domProps: { value: _vm.cOpt.data.time_ots.val },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.cOpt.data.time_ots,
                                      "val",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.cOpt.data.t_before_kz <= 3600
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: {
                                    "background-color": "palevioletred",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Аппарат защиты сработает от рабочего тока кабеля за время " +
                                      _vm._s(_vm.cOpt.data.t_before_kz) +
                                      "сек.\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.cOpt.data.calcPoint.id
                    ? _c("div", { staticClass: "row border-top pt-3" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _vm._v(
                            "\n                        температура жилы до короткого замыкания, в нормальном режиме работы\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 font-weight-bold" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.cOpt.data.t_ish) +
                              " °C\n                    "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.cOpt.data.calcPoint.id
                    ? _c("div", { staticClass: "row border-top pt-3" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _vm._v(
                            "\n                        расчётный коэффициент K\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 font-weight-bold" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.cOpt.data.k) +
                              "\n                    "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.cOpt.data.calcPoint.id
                    ? _c("div", { staticClass: "row border-top pt-3" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _vm._v(
                            "\n                        температура жилы в конце короткого замыкания\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 font-weight-bold" }, [
                          _vm.cOpt.data.t_res <= _vm.cOpt.data.tdop.t2
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: {
                                    "background-color": "limegreen",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.cOpt.data.t_res) +
                                      " °C <= " +
                                      _vm._s(_vm.cOpt.data.tdop.t2) +
                                      " °C\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cOpt.data.t_res > _vm.cOpt.data.tdop.t2 &&
                          _vm.cOpt.data.t_res <= _vm.cOpt.data.tdop.t3
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: { "background-color": "orange" },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.cOpt.data.tdop.t2) +
                                      " °C < " +
                                      _vm._s(_vm.cOpt.data.t_res) +
                                      " °C <= " +
                                      _vm._s(_vm.cOpt.data.tdop.t3) +
                                      " °C\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cOpt.data.t_res > _vm.cOpt.data.tdop.t3 &&
                          _vm.cOpt.data.t_res <= _vm.cOpt.data.tdop.t1
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: {
                                    "background-color": "palevioletred",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.cOpt.data.tdop.t3) +
                                      " °C < " +
                                      _vm._s(_vm.cOpt.data.t_res) +
                                      " °C <= " +
                                      _vm._s(_vm.cOpt.data.tdop.t1) +
                                      " °C\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cOpt.data.t_res > _vm.cOpt.data.tdop.t1
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: {
                                    "background-color": "palevioletred",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.cOpt.data.t_res) +
                                      " °C > " +
                                      _vm._s(_vm.cOpt.data.tdop.t1) +
                                      " °C\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.timerLoading
                            ? _c("i", {
                                staticClass:
                                  "fas fa-spin fa-circle-notch fa-fw",
                                staticStyle: {
                                  color: "#008000",
                                  opacity: "0.5",
                                },
                              })
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.cOpt.data.calcPoint.id
                    ? _c("div", [
                        _vm.cOpt.data.t_res <= _vm.cOpt.data.tdop.t2
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "background-color": "limegreen",
                                },
                              },
                              [
                                _c("b", [_vm._v("Вывод:")]),
                                _vm._v(
                                  " возгорание отсутствует, кабели пригодны для дальнейшей эксплуатации"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cOpt.data.t_res > _vm.cOpt.data.tdop.t2 &&
                        _vm.cOpt.data.t_res <= _vm.cOpt.data.tdop.t3
                          ? _c(
                              "p",
                              { staticStyle: { "background-color": "orange" } },
                              [
                                _c("b", [_vm._v("Вывод:")]),
                                _vm._v(
                                  " возгорание отсутствует, допускается эксплуатация кабеля в течении одного года"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cOpt.data.t_res > _vm.cOpt.data.tdop.t3 &&
                        _vm.cOpt.data.t_res <= _vm.cOpt.data.tdop.t1
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "background-color": "palevioletred",
                                },
                              },
                              [
                                _c("b", [_vm._v("Вывод:")]),
                                _vm._v(
                                  " возгорание отсутствует, кабель не пригоден для дальнейшей эксплуатации"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cOpt.data.t_res > _vm.cOpt.data.tdop.t1
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "background-color": "palevioletred",
                                },
                              },
                              [
                                _c("b", [_vm._v("Вывод:")]),
                                _vm._v(
                                  " кабель не прошёл проверку на возгорание"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                ],
                1
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _vm._v(
        "\n                        температура окружающей среды в регионе, °C\n                        "
      ),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(
          "определяется по СП 131.13330.2020 табл.5, принимается для наиболее тяжёлого режима а именно, берётся максимальное среднемесячное\n                            значение"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _vm._v(
        "\n                        температура окружающей среды, °C\n                        "
      ),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(
          "температура окружающей среды, при прокладке в земле - 15°C, при прокладке на воздухе - 25°C"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _vm._v(
        "\n                        характеристики кабеля\n                        "
      ),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(
          "длительно допустимый ток кабеля берётся из технических характеристик кабеля или из ПУЭ"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-2 text-center" }, [_vm._v("l, м")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2 text-center" }, [_vm._v("Iкз, кА")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2 text-center" }, [_vm._v("tотс, сек")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2 text-center" }, [_vm._v("K")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2 text-center" }, [_vm._v("T, °C")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _vm._v(
        "\n                        ток КЗ в проверяемой точке линии, кА\n                        "
      ),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v("ток КЗ в проверяемой точке линии"),
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(
          "основная проверка выполняется для металлического 3х фазного КЗ на расстойнии 1/3 от длины кабеля"
        ),
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(
          "также рекомендуется проверять кабель в конце линии, для разных токов КЗ"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _vm._v(
        "\n                        время действия резервной защиты\n                        "
      ),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(
          "время действия резервной защиты в соответствии с выбранным аппаратом защиты, или введённое вручную"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }