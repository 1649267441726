var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "node-view-wrapper",
    { staticClass: "draggable-item" },
    [
      _c(
        "div",
        {
          staticClass: "drag-handle text-left",
          attrs: {
            draggable: "true",
            "data-drag-handle": "",
            title: "перетащить",
          },
        },
        [
          _c("font-awesome-icon", {
            attrs: { icon: "fa-solid fa-bars", transform: "grow-5" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "b-del text-right",
          attrs: { title: "удалить" },
          on: { click: _vm.clickDel },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "text-danger",
            attrs: { icon: "fa-solid fa-xmark", transform: "grow-5" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("node-view-content", {
        staticClass: "content",
        attrs: { contenteditable: "false" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }