<template>
    <div>
        <div class="container-fluid m1">

            <title-for-page :c-page="local.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt2=!state.swOnOpt2" :class="{active:state.swOnOpt2}" title="настройки страницы">настройки таблицы</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="local.cPage" :state="state" class="mb-3"/>

                    <div class="row">
                        <div class="col-12">
                            <div v-if="state.swOnOpt2" class="card">
                                <div class="card-header p-1 pl-2" @click="state.swOnOpt2 = !state.swOnOpt2" style="cursor: pointer">настройки таблицы</div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="w-100 mt-2">
                                                <span class="small">{{cField.opt.w1.desc}}</span>
                                                <input class="form-control" v-model.number="cField.opt.w1.val" :placeholder="cField.opt.w1.desc" @keyup="changeWidth"/>
                                            </label>
                                        </div>
                                        <div class="col-2">
                                            <label class="w-100 mt-2">
                                                <span class="small">{{cField.opt.w2.desc}}</span>
                                                <input class="form-control" v-model.number="cField.opt.w2.val" :placeholder="cField.opt.w2.desc" disabled/>
                                            </label>
                                        </div>
                                        <div class="col-2">
                                            <label class="w-100 mt-2">
                                                <span class="small">{{cField.opt.w3.desc}}</span>
                                                <input class="form-control" v-model.number="cField.opt.w3.val" :placeholder="cField.opt.w3.desc" @keyup="changeWidth"/>
                                            </label>
                                        </div>
                                        <div class="col-2">
                                            <label class="mb-0 mt-2">
                                                <span class="small">{{cField.opt.type_num.desc}}</span>
                                            </label>
                                            <multiselect
                                                v-model="cField.opt.type_num.val"
                                                :options="cField.opt.list_type_num.map(item => item.val)"
                                                :custom-label="opt_ => cField.opt.list_type_num.find(i=>i.val===opt_).desc"
                                                :searchable="false"
                                                :close-on-select="true"
                                                :show-labels="false"
                                                :allow-empty="false"
                                            >
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div v-if="!!local.cPage" class="col-12">
                            <div class="card">
                                <div class="card-body" v-html="local.cPage.html"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TitleForPage from "./sub/TitleForPage";
    import {page} from "./sub/tools";
    import CardOptions from "./sub/CardOptions";
    import Multiselect from 'vue-multiselect';
    import "vue-multiselect/dist/vue-multiselect.min.css";

    export default {
        name: "page-text-st",
        components: {CardOptions, TitleForPage, Multiselect},
        props: ['cPage'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                state: {
                    version: 4,
                    swOnOpt: false,
                    swOnOpt2: false,
                },
                status: {
                    save: false,
                    loading: false,
                },
                local: {
                    cPage: this.cPage,
                },
                nameCField: 'table_st_0',
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.pageT.clickSaveCPage();
                if (e.key === 'download') this.pageT.clickDownload();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, download: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            if (this.cField === undefined) this.flash('ошибка содержимого страницы', 'error');
            else this.isMounted = true;
        },
        methods: {
            changeWidth(index) {
                let delta = 195 - this.cField.opt.w1.val - this.cField.opt.w3.val;
                if (delta <= 0) delta = 0;
                this.cField.opt.w2.val = delta;
            },
        },
        computed: {
            cField: function () {
                return this.local.cPage.data.fields.find(item => item.idd === this.nameCField);
            },
        },
        watch: {
            'state.swOnOpt'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

</style>
