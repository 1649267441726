var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          !_vm.state.id
            ? _c("div", { staticClass: "modal-header" }, [
                _vm._v("прикрепить файл к проекту"),
              ])
            : _c("div", { staticClass: "modal-header" }, [
                _vm._v("редактирование файла"),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.state.name,
                    expression: "state.name",
                  },
                ],
                ref: "v_focus",
                staticClass: "form-control mb-3",
                attrs: { placeholder: "название", disabled: !_vm.state.id },
                domProps: { value: _vm.state.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.state, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "vue-dropzone",
                {
                  ref: "dropzone",
                  attrs: {
                    id: "dropzone",
                    options: _vm.dropzoneOptionsAttaches,
                    useCustomSlot: true,
                  },
                  on: {
                    "vdropzone-success": _vm.dropzoneSuccessAttach,
                    "vdropzone-sending": _vm.addParamAttach,
                  },
                },
                [
                  _c("div", { staticClass: "dropzone-custom-content" }, [
                    _c("h3", { staticClass: "dropzone-custom-title" }, [
                      _vm._v("Перетащите сюда файл"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "subtitle" }, [
                      _vm._v("...или кликните для выбора файла на компьютере"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _vm.state.id
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-default text-danger",
                    attrs: { type: "button" },
                    on: { click: _vm.clickDelete },
                  },
                  [_vm._v("удалить")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.state.id
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.clickSave },
                  },
                  [_vm._v("сохранить")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button", "data-dismiss": "modal" },
              },
              [_vm._v("закрыть")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }