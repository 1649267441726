<template>
    <div class="noselect">
        <div class="card-body">
            <div class="row zag">
                <div class="col-4">
                    <div class="row">
                        <div class="col-2">группа</div>
                        <div class="col-10 text-left">название</div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-2">кол-во</div>
                        <div class="col-2">Ру, кВт</div>
                        <div class="col-2">Рр, кВт</div>
                        <div class="col-2">cos</div>
                        <div class="col-2">Iр, А</div>
                        <div class="col-2">фазы</div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-4">_</div>
                        <div class="col-5">кабель</div>
                        <div class="col-1">длина</div>
                        <div class="col-1">dU, %</div>
                        <div class="col-1">Ik1, A</div>
                    </div>
                </div>

            </div>

            <template v-for="val in pLocal.eFull">
                <div class="row row-w font-weight-bold" :key="val.id" @click.ctrl.prevent="eTable.clickCtrlRow(val)"
                     :class="{'bg-primary-l':eTable.isSelect(val)}"
                     @click.exact="eTable.clickRow(val)"
                     style="background-color: lightgrey"
                >
                    <div class="col-4">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-10 text-left" :title="getTitleForPanelName(val)">
                                <span>{{ val.name2 }}</span><span class="small ml-2">{{ val.room.name }}</span></div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-2">{{ val.es.pu }}</div>
                            <div class="col-2" :title="val.es.ks">{{ val.es.pr }}</div>
                            <div class="col-2">{{ val.es.cos }}</div>
                            <div class="col-2">{{ val.es.ir }}</div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-4"></div>
                            <div class="col-5"></div>
                            <div class="col-1"></div>
                            <div class="col-1"></div>
                            <div class="col-1"></div>
                        </div>
                    </div>
                </div>
                <template v-for="val2 in val.child">
                    <div class="row row-w font-italic" :key="val2.id" @click.ctrl.prevent="eTable.clickCtrlRow(val2)"
                         :class="{'bg-primary-l':eTable.isSelect(val2)}"
                         @click.exact="eTable.clickRow(val2)"
                         style="background-color: lightgrey"
                    >
                        <div class="col-4">
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-10 pl-2 text-left">{{ val2.name }}</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-2">{{ val2.es.pu }}</div>
                                <div class="col-2" :title="val2.es.ks">{{ val2.es.pr }}</div>
                                <div class="col-2">{{ val2.es.cos }}</div>
                                <div class="col-2">{{ val2.es.ir }}</div>
                                <div class="col-2" :title="val2.L.A+'/'+val2.L.B+'/'+val2.L.C">{{ val2.es.faz }}</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-4" :class="{'bg-danger':!!getWarningAvtGBS(val2)}"
                                     :title="getWarningAvtGBS(val2)">
                                    <div :class="{'bg-danger':!!getWarningAvt0(gbs(val2))}"
                                         :title="getWarningAvt0(gbs(val2))">
                                        <div class="text-nowrap text-right">
                                            <span>{{ gbs(val2).avt[0].marka }}</span>
                                            <font-awesome-icon icon="fa-solid fa-angle-down"
                                                               @click.stop="clickAvt(gbs(val2),'down')"/>
                                            <font-awesome-icon icon="fa-solid fa-angle-up"
                                                               @click.stop="clickAvt(gbs(val2),'up')"/>
                                        </div>
                                    </div>
                                    <div :class="{'bg-danger':!!getWarningAvt0(val2)}" :title="getWarningAvt0(val2)">
                                        <div class="text-nowrap text-right">
                                            <span>{{ val2.avt[0].marka }}</span>
                                            <font-awesome-icon icon="fa-solid fa-angle-down"
                                                               @click.stop="clickAvt(val2,'down')"/>
                                            <font-awesome-icon icon="fa-solid fa-angle-up"
                                                               @click.stop="clickAvt(val2,'up')"/>
                                        </div>
                                    </div>
                                    <div v-if="!!val2.avt[1].id" :class="{'bg-danger':!!getWarningAvt1(val2)}"
                                         :title="getWarningAvt1(val2)">
                                        <div class="text-nowrap text-right">
                                            <span>{{ val2.avt[1].marka }}</span>
                                            <font-awesome-icon icon="fa-solid fa-angle-down"
                                                               @click.stop="clickAvt(val2,'down',1)"/>
                                            <font-awesome-icon icon="fa-solid fa-angle-up"
                                                               @click.stop="clickAvt(val2,'up',1)"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="text-nowrap text-right" :class="{'bg-danger':gbs(val2).cable.id && (getICab(gbs(val2))<gbs(val2).avt[0].iots ||
                                    gbs(val2).cable.core<gbs(val2).avt[0].poles)}">
                                        <!--                                        <strike v-if="!gbs(val2).lock.cable_so" class="text-danger font-weight-bold">со</strike>-->
                                        <span v-if="gbs(val2).cable.sec>0" :title="getICab(gbs(val2))"><span v-if="gbs(val2).cable.number>1">{{ gbs(val2).cable.number }}х</span>{{ gbs(val2).cable.marka }}</span>
                                        <font-awesome-icon icon="fa-solid fa-angle-down"
                                                           @click.stop="clickCable(gbs(val2),'down')"/>
                                        <font-awesome-icon icon="fa-solid fa-angle-up"
                                                           @click.stop="clickCable(gbs(val2),'up')"/>
                                    </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-1" :class="{'text-danger':val2.r_schema!==undefined}">{{ val2.es.dU }}
                                </div>
                                <div class="col-1" :class="{'text-danger':val2.r_schema!==undefined}">
                                    <div>{{ val2.es.ik3 }}</div>
                                    <div>{{ Math.round(val2.es.ik1 * 1000,0) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <draggable :list="val2.child" group="section" :scroll-sensitivity="100" :fallback-tolerance="1"
                               :force-fallback="true" handle=".handle">
                        <template v-for="val3 in val2.child">
                            <div class="row row-w" :key="val3.id"
                                 @click.ctrl.prevent="eTable.clickCtrlRow(val3)"
                                 :class="{'bg-primary-l':eTable.isSelect(val3)}"
                                 @click.exact="eTable.clickRow(val3)"
                                 @contextmenu.prevent="$refs.menu.open($event, val3)"

                            >
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-2 handle"
                                             :class="{'text-danger':!val3.handle,'font-weight-bold':!val3.handle}">
                                            {{ val3.name }}
                                        </div>
                                        <div class="col-10 text-left">
                                            <div class="d-flex w-100 justify-content-between">
                                                <div>
                                                    <span>{{ getComm(val3) }}</span>
                                                    <span class="small">{{ getCommRoom(val3) }}</span>
                                                </div>
                                                <font-awesome-icon v-if="!getVisiblePotr(val3)"
                                                                   icon="fa-solid fa-angle-down"
                                                                   @click="clickSwDown(val3)"/>
                                                <font-awesome-icon v-else icon="fa-solid fa-angle-up"
                                                                   @click="clickSwUp(val3)"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-2">{{ val3.child.length }}</div>
                                        <div class="col-2">{{ val3.es.pu }}</div>
                                        <div class="col-2" :title="val3.es.ks">{{ val3.es.pr }}</div>
                                        <div class="col-2">{{ val3.es.cos }}</div>
                                        <div class="col-2">{{ val3.es.ir }}</div>
                                        <div class="col-2" :class="{'bg-danger':!!getWarningFaz(val3)}"
                                             :title="getWarningFaz(val3)">
                                            <span v-if="val3.panel.l==='A,B,C'" class="col-2">{{ val3.panel.l }}</span>
                                            <span v-if="val3.panel.l==='A'"
                                                  class="col-2 bg-primary">{{ val3.panel.l }}</span>
                                            <span v-if="val3.panel.l==='B'"
                                                  class="col-2 bg-secondary">{{ val3.panel.l }}</span>
                                            <span v-if="val3.panel.l==='C'"
                                                  class="col-2 bg-success">{{ val3.panel.l }}</span>
                                            {{ val3.es.faz }}
                                            <font-awesome-icon v-if="val3.lock.faz" size="xs" icon="fa-solid fa-lock"
                                                               @click="val3.lock.faz=false"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="text-nowrap text-right"
                                                 :class="{'bg-danger':!!getWarningAvt0(val3)}"
                                                 :title="getWarningAvt0(val3)">
                                                <font-awesome-icon v-if="val3.lock.avt0" size="xs"
                                                                   icon="fa-solid fa-lock"
                                                                   @click="val3.lock.avt0=false"/>
                                                <span>{{ val3.avt[0].marka }}</span>
                                                <font-awesome-icon icon="fa-solid fa-angle-down"
                                                                   @click.stop="clickAvt(val3,'down')"/>
                                                <font-awesome-icon icon="fa-solid fa-angle-up"
                                                                   @click.stop="clickAvt(val3,'up')"/>
                                            </div>
                                            <div v-if="!!val3.avt[1].id" class="text-nowrap text-right"
                                                 :class="{'bg-danger':!!getWarningAvt1(val3)}"
                                                 :title="getWarningAvt1(val3)">
                                                <font-awesome-icon v-if="val3.lock.avt1" size="xs"
                                                                   icon="fa-solid fa-lock"
                                                                   @click="val3.lock.avt1=false"/>
                                                <span>{{ val3.avt[1].marka }}</span>
                                                <font-awesome-icon icon="fa-solid fa-angle-down"
                                                                   @click.stop="clickAvt(val3,'down',1)"/>
                                                <font-awesome-icon icon="fa-solid fa-angle-up"
                                                                   @click.stop="clickAvt(val3,'up',1)"/>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="text-nowrap text-right"
                                                 :class="{'bg-danger':!!getWarningCable(val3)}"
                                                 :title="getWarningCable(val3)">
                                                <strike v-if="val3.lock.cable_so" class="text-danger font-weight-bold">со</strike>
                                                <font-awesome-icon v-if="val3.lock.cable" size="xs"
                                                                   icon="fa-solid fa-lock"
                                                                   @click="val3.lock.cable=false"/>
                                                <span v-if="val3.cable.id>0"
                                                      :title="getICab(val3)"><span v-if="val3.cable.number>1">{{ val3.cable.number }}х</span>{{ val3.cable.marka }}</span>
                                                <font-awesome-icon icon="fa-solid fa-angle-down"
                                                                   @click.stop="clickCable(val3,'down')"/>
                                                <font-awesome-icon icon="fa-solid fa-angle-up"
                                                                   @click.stop="clickCable(val3,'up')"/>
                                            </div>
                                        </div>
                                        <div class="col-1" :title="val3.cable_length.all">{{ val3.cable_length.max }}
                                        </div>
                                        <div class="col-1"
                                             :class="{'bg-danger':val3.es.dU>5,'bg-secondary':val3.es.dU>=4}">
                                            {{ val3.es.dU }}
                                        </div>
                                        <div class="col-1"
                                             :class="{'bg-danger':val3.avt[0].iots*10>=val3.es.ik1*1000,'bg-bg-secondary':val3.avt[0].iots*5>=val3.es.ik1*1000}"
                                             :title="val3.es.ik3">
                                            {{ Math.round(val3.es.ik1 * 1000,0) }}
                                        </div>
                                    </div>
                                </div>

                                <draggable class="w-100" v-if="isMove" :list="val3.child" group="potr">
                                </draggable>

                                <draggable class="w-100" v-if="getVisiblePotr(val3)" :list="val3.child" group="potr"
                                           @start="dStart" @end="dStop" :scroll-sensitivity="100"
                                           :fallback-tolerance="1"
                                           :force-fallback="true" handle=".handle">
                                    <div v-for="val4 in val3.child" :key="val4.index">
                                        <div class="row row-w" :key="val4.id">
                                            <div class="col-4">
                                                <div class="row">
                                                    <div class="col-2"></div>
                                                    <div class="col-10 pl-2 text-left handle">
                                                        <span>{{ val4.name2 }}</span><span class="small">{{ val4.marker.explication_tag }}.{{ val4.room.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="row">
                                                    <div class="col-2">{{ val4.es.kolvo }}</div>
                                                    <div class="col-2">{{ val4.es.pu }}</div>
                                                    <div class="col-2" :title="val4.es.ks">{{ val4.es.pr }}</div>
                                                    <div class="col-2">{{ val4.es.cos }}</div>
                                                    <div class="col-2">{{ val4.es.ir }}</div>
                                                    <div class="col-2">{{ val4.es.faz }}</div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="row">
                                                    <div class="col-4"></div>
                                                    <div class="col-5"></div>
                                                    <div class="col-1">{{ val4.cable_length.all }}</div>
                                                    <div class="col-1"></div>
                                                    <div class="col-1"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </draggable>
                            </div>
                        </template>
                    </draggable>
                </template>
            </template>
            <br>
            <br>
            <draggable class="w-100" :list="emptyPanel" group="potr" @start="dStart" @end="dStop"
                       :scroll-sensitivity="100" :fallback-tolerance="1" :force-fallback="true">
                <div v-for="val in emptyPanel" :key="val.index">
                    <div class="row row-w" :key="val.id">
                        <div class="col-4">
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-10 pl-2 text-left"><span>{{ val.name2 }}</span><span class="small">{{ val.marker.explication_tag }}.{{ val.room.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-2">{{ val.es.kolvo }}</div>
                                <div class="col-2">{{ val.es.pu }}</div>
                                <div class="col-2">{{ val.es.pr }}</div>
                                <div class="col-2">{{ val.es.cos }}</div>
                                <div class="col-2">{{ val.es.ir }}</div>
                                <div class="col-2">{{ val.es.faz }}</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-4"></div>
                                <div class="col-5"></div>
                                <div class="col-1"></div>
                                <div class="col-1"></div>
                                <div class="col-1"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </draggable>
            <br>
            <br>
            <draggable class="w-100" :list="emptyPotr" group="potr" @start="dStart" @end="dStop"
                       :scroll-sensitivity="100" :fallback-tolerance="1" :force-fallback="true">
                <div v-for="val in emptyPotr" :key="val.index">
                    <div class="row row-w" :key="val.id">
                        <div class="col-4">
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-10 pl-2 text-left"><span>{{ val.name2 }}</span><span class="small">{{ val.marker.explication_tag }}.{{ val.room.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-2">{{ val.es.kolvo }}</div>
                                <div class="col-2">{{ val.es.pu }}</div>
                                <div class="col-2">{{ val.es.pr }}</div>
                                <div class="col-2">{{ val.es.cos }}</div>
                                <div class="col-2">{{ val.es.ir }}</div>
                                <div class="col-2">{{ val.es.faz }}</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-4"></div>
                                <div class="col-5"></div>
                                <div class="col-1"></div>
                                <div class="col-1"></div>
                                <div class="col-1"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </draggable>
        </div>
        <modal-panel modal-id="modalPanel0" :p-local="local" @updInit="init"/>
        <modal-bbs modal-id="modalBbs0" :p-local="local" @updInit="init"/>
        <modal-section modal-id="modalSection0" :p-local="local" @updInit="init"/>

        <ContextMenuMy ref="menu" :items="items"/>
    </div>
</template>
<script>

import ModalPotr from "./sub/ModalPotr";
import ModalPanel from "./sub/ModalPanel";
import ModalBbs from "./sub/ModalBbs";
import ModalSection from "./sub/ModalSection";
import {eTable} from "./sub/tools";
import ContextMenuMy from "./sub/ContextMenuMy";
import uniqid from 'uniqid';


export default {
    name: 'e-panel0',
    components: {ModalSection, ModalPanel, ModalBbs, ContextMenuMy},
    props: {
        pLocal: {},
        pState: {}
    },
    data: function () {
        return {
            eTable: new eTable(this),
            local: {
                table: [],
                selectArr: [],
                listMethod: this.pLocal.listMethod,
                listPanelName2: this.pLocal.listPanelName2,
                listCableSeries: this.pLocal.listCableSeries,
                listBaseCable: this.pLocal.listBaseCable,
                listBaseAvt: this.pLocal.listBaseAvt,
                potrVisible: [],
                listApp: this.pLocal.listApp,
                listSec: [0, 1.5, 2.5, 4, 6, 10, 16, 25, 35, 50, 70, 95, 120, 150, 185, 240],
                listAvt: [4, 6, 10, 16, 20, 25, 32, 40, 50, 63, 80, 100, 125, 160, 200, 250, 315, 400, 500, 630],
                listCore: [3, 4, 5],
            },
            state: {
                search: this.pState.search,
            },
            isMove: false,
            items: [
                {name: 'выбрать всё', link: 'select_panel_all'},
                {name: 'auto', link: 'auto_calc',},
                {name: 'как здесь', link: 'like_this'},
                {
                    name: 'перенос', child: [
                        {name: 'только потребителей', link: 'move_potr'},
                        {name: 'потр+каб+авт', link: 'move_potr_all'},
                    ]
                },
                {
                    name: 'блокировка', child: [
                        {name: 'автомат 0', link: 'lock_avt0'},
                        {name: 'автомат 1', link: 'lock_avt1'},
                        {name: 'кабель', link: 'lock_cable'},
                        {
                            name: 'фазу', link: 'lock_faz', child: [
                                {name: 'A', link: 'lock_faz_a'},
                                {name: 'B', link: 'lock_faz_b'},
                                {name: 'C', link: 'lock_faz_c'},
                            ]

                        },
                    ]
                },
                {name: 'распределить группу по комнатам', link: 'r_by_room'},
                {name: 'распределить группу по этажам', link: 'r_by_marker'},
                {
                    name: 'потребители', child: [
                        {
                            name: 'автомат', link: 'set_avt_min', child: [
                                {name: '10', link: 'set_avt_min_10'},
                                {name: '16', link: 'set_avt_min_16'},
                                {name: '20', link: 'set_avt_min_20'},
                            ]
                        },
                        {
                            name: 'кабель', link: 'set_cable_min', child: [

                                {name: '1,5', link: 'set_cable_min_15'},
                                {name: '2,5', link: 'set_cable_min_25'},
                                {name: '4', link: 'set_cable_min_4'},
                            ]
                        },
                    ]
                },
                // {name: 'вставить', link: 'insert'},
                {name: '---'},
                {name: 'удалить', link: 'delete'},
                // {name: 'unSelect', link: 'unselect'},
                {name: 'отмена', link: 'cancel'},
            ],
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'insert') this.showModalInsert();
            if (e.key === 113) this.showModal();
        });
        Bus.$on('updTable', () => this.init());
        Bus.$on('localSave', () => this.localSave());
        Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
        Bus.$on('dotSelect', () => this.eTable.onDotSelect());
        Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
        Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        Bus.$on('contextMenu_menu', (obj) => this.onContext(obj));
    },
    mounted() {
        this.init();
        this.local.selectArr = [];
        this.autoCalcAll();
        if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
        // setTimeout(() => this.showModal(), 200);
    },
    methods: {
        likeThis(obj) {
            this.local.selectArr.forEach(item => {
                item.avt = JSON.parse(JSON.stringify(obj.avt));
                item.cable = JSON.parse(JSON.stringify(obj.cable));
            })
        },
        selectPanelAll(obj) {
            let parent = this.getParentForSection(obj);
            if (!parent) return;
            this.local.selectArr.length = 0;
            parent.child.forEach(item => this.local.selectArr.push(item));
        },
        getParentForSection(obj) {
            let res;
            this.pLocal.eFull.forEach(item => {
                if (!res) res = item.child.find(item2 => item2.id === obj.bbs.id);
            })

            return res;
        },
        getExtremumForGroup(obj) {
            let avt1 = 0;
            let avt2 = 0;
            let kab1 = 0;
            let kab2 = 0;
            let fr = false;
            let first = true;
            obj.child.forEach(item => {
                if (first) {
                    avt1 = item.panel.avt1;
                    avt2 = item.panel.avt2;
                    kab1 = item.panel.kab1;
                    kab2 = item.panel.kab2;
                    fr = item.panel.fr;
                    first = false;
                } else {
                    avt1 = Math.max(avt1, item.panel.avt1);
                    avt2 = Math.min(avt2, item.panel.avt2);
                    kab1 = Math.max(kab1, item.panel.kab1);
                    kab2 = Math.min(kab2, item.panel.kab2);
                    fr = fr || item.panel.fr;
                }
            })
            if (!avt1) avt1 = 16;
            if (!avt2) avt2 = 63;
            if (!kab1) kab1 = 2.5;
            if (!kab2) kab2 = 120;
            return {
                avt1: avt1,
                avt2: avt2,
                kab1: kab1,
                kab2: kab2,
                fr: fr,
            }
        },
        autoCalcAll() {
            this.pLocal.eFull.forEach(item => {
                item.child.forEach(item2 => {
                    item2.child.forEach(item3 => this.autoCalcOnce(item3))
                })
            })
        },
        autoCalc(obj) {
            this.local.selectArr.forEach(item => this.autoCalcOnce(item));
        },
        autoCalcOnce(obj) {
            let extremum = this.getExtremumForGroup(obj);
            obj.es.faz = this.getAutoFaz(obj);
            if (!obj.lock.avt0) this.getAutoAvt(obj, 0, extremum);
            if (!obj.lock.avt1) this.getAutoAvt(obj, 1, extremum);

            if (obj.child.length === 0) obj.cable.id = null;
            else if (!obj.lock.cable) this.getAutoCable(obj, extremum);
        },
        getAutoFaz(obj) {
            let faz = 0;
            obj.child.forEach(item => {
                faz = Math.max(faz, item.es.faz);
            })

            if (faz === 0) faz = obj.es.faz;

            return faz;
        },
        getAutoAvt(obj, k = 0, extremum) {
            if (!obj.avt[k].id) return false;

            let orig = this.local.listBaseAvt.find(item => item.id === obj.avt[k].id);
            if (!orig) return false;

            let nnew = this.local.listBaseAvt.find(item =>
                item.dop.seriya === orig.dop.seriya && item.firma === orig.firma &&
                item.dop.pol === obj.es.faz && item.dop.Iots * 1 > obj.es.ir * 1.1 &&
                item.dop.Iots * 1 >= extremum.avt1 &&
                // && item.dop.Iots * 1 <= extremum.avt2 &&
                item.dop.har === orig.dop.har
            );
            if (!nnew) {
                nnew = this.local.listBaseAvt.findLast(item =>
                    item.dop.seriya === orig.dop.seriya && item.firma === orig.firma &&
                    item.dop.pol === obj.es.faz &&
                    // item.dop.Iots * 1 >= extremum.avt1 && item.dop.Iots * 1 <= extremum.avt2 &&
                    item.dop.har === orig.dop.har
                );
            }
            if (!nnew) return false;

            this.setAvtInfo(obj.avt[k], nnew);

            return true;
        },
        getAutoCable(obj, extremum) {
            let orig = null;
            for (let i = 0; i < 3; i++) {
                orig = this.local.listBaseCable.find(item => item.id === obj.cable.id);
                if (!orig && this.brandCable[i] !== undefined) orig = this.brandCable[i];
                if (!orig) continue;
                let r = this.getAutoCableOnce(obj, extremum, orig);
                if (r) break;
            }
        },
        getAutoCableOnce(obj, extremum, orig) {
            let faz = obj.es.faz;
            let colvo = orig.dop.colvo;
            if (faz === 1 && orig.dop.colvo > 3) colvo = 3;
            if (faz === 3 && orig.dop.colvo < 4) colvo = 5;

            //запас на автомат 1.5 это по графику хар.С
            let nnew = this.local.listBaseCable.find(item =>
                    item.dop.marka === orig.dop.marka && item.firma === orig.firma &&
                    item.dop.colvo === colvo && item.dop.iacv1 * 1 > obj.avt[0].iots * 1.5 &&
                    item.dop.sech * 1 >= extremum.kab1
                // && item.dop.sech * 1 <= extremum.kab2
            );
            if (!nnew) return false;

            this.setCableInfo(obj.cable, nnew);

            return true;
        },
        getDefaultCableId() {
            if (this.brandCable[0] !== undefined) return this.brandCable[0];
            return null;
        },
        getWarningFaz(obj) {
            let log = '';
            let max = 1;
            let kk = {};
            obj.child.forEach(item => {
                if (item.es.faz > max) max = 3;
                kk[item.es.faz] += 1;
            })
            if (obj.child.length === 0) return '';
            if (obj.es.faz > max) log += "В группе нет 3хф потребителей \n";
            if (obj.es.faz < max) log += "В группе есть 3хф потребитель \n";
            if (Object.keys(kk).length === 2) log += "В группе есть 3хф и 1ф потребители \n";

            return log;
        },
        getWarningAvt0(obj) {
            let log = '';
            if (!obj.avt[0].id) return;
            if (obj.avt[0].iots <= obj.es.ir) log += "Ток отсечки автомата меньше тока группы \n";
            if (obj.es.faz === 1 && obj.avt[0].poles > 2) log += "Много полюсов в автомате \n";
            if (obj.es.faz === 3 && obj.avt[0].poles < 3) log += "Мало полюсов в автомате \n";

            return log;
        },
        getWarningAvt1(obj) {
            let log = '';
            if (!obj.avt[1].id) return;
            if (obj.avt[1].iots <= obj.es.ir) log += "Ток отсечки автомата меньше тока группы \n";
            if (obj.es.faz === 1 && obj.avt[1].poles > 2) log += "Много полюсов в автомате \n";
            if (obj.es.faz === 3 && obj.avt[1].poles < 3) log += "Мало полюсов в автомате \n";

            return log;
        },
        getWarningAvtGBS(obj) {
            let log = '';
            if (!this.gbs(obj).avt[0].id) return;
            if (obj.avt[0].type === 4 || obj.avt[0].type === 6) { //рубильник и УЗО
                if (this.gbs(obj).avt[0].iots > obj.avt[0].iots) log += "Нарушена селективность \n";
            } else {
                if (this.gbs(obj).avt[0].iots <= obj.avt[0].iots) log += "Нарушена селективность \n";
            }


            return log;
        },
        getWarningCable(obj) {
            let log = '';
            if (!obj.cable.id) return;
            if (this.getICab(obj) < obj.avt[0].iots) log += "Ток кабеля меньше тока автомата \n";
            if (obj.cable.core === 3 && obj.avt[0].poles > 2) log += "Мало жил в кабеле \n";
            if (obj.cable.core > 3 && obj.cable.poles < 3) log += "Много жил в кабеле \n";

            return log;
        },
        getTitleForPanelName(obj) {
            let res = '';
            let sec = 0;
            let mass = 0;
            let w = 0;
            obj.child.forEach(item => {
                let a = this.local.listBaseAvt.find(item3 => item3.id === item.avt[0].id);
                if (a) {
                    w += a.dop.x;
                }
                item.child.forEach(item2 => {
                    let c = this.local.listBaseCable.find(item3 => item3.id === item2.cable.id);
                    if (c) {
                        sec += 3.1415 * c.dop.d * c.dop.d / 4;
                        mass += c.dop.massa;
                    }

                    a = this.local.listBaseAvt.find(item3 => item3.id === item2.avt[0].id);
                    if (a) {
                        w += a.dop.x;
                    }
                })
            })


            res += "Ширина модулей: " + w + "мм \n";
            res += "Всего модулей с запасом: " + Math.round(w * 1.2 / 18) + "модуля \n";
            res += "------- \n";
            res += "Вес кабелей: " + Math.round(mass / 1000) + "кг/м \n";
            res += "Сечения кабелей: " + Math.round(sec) + "мм2 \n";
            res += "Ширина лотка h50: >" + Math.round((sec / 35 * 100) / 50) + "мм \n";
            res += "Ширина лотка h80: >" + Math.round((sec / 35 * 100) / 80) + "мм \n";
            res += "Ширина лотка h100: >" + Math.round((sec / 35 * 100) / 100) + "мм \n";
            return res;
        },
        gbs(obj) {
            let res = {
                id: '',
                cable: {marka: ''},
                avt: [{id: '', marka: '', iots: 0, poles: 1, type: 3}],
                es: {ir: 0, faz: 1}
            };
            this.pLocal.eFull.forEach(item => {
                if (res.id === '')
                    item.child.forEach(item2 => {
                        if (res.id === '') {
                            let res_ = item2.child.find(item3 => item3.id === obj.section.id);
                            if (res_) res = res_;
                        }
                    })
            })

            return res;
        },
        getICab(obj) {
            let orig = this.local.listBaseCable.find(item => item.id === obj.cable.id);
            if (!orig) return 0;
            return parseFloat(orig.dop.iacv1) * parseFloat(obj.cable.number);
        },
        onContext(obj) {
            if (obj.link === 'insert') this.clickAddSection(obj.obj);
            // if (obj.link === 'edit') Bus.$emit('show_modalVspd', obj.obj);
            if (obj.link === 'delete') this.clickRemoveSection(obj.obj);
            if (obj.link === 'move_potr') this.clickMovePotr(obj.obj);
            if (obj.link === 'move_potr_all') this.clickMovePotrAll(obj.obj);
            if (obj.link === 'unselect') this.eTable.onDotUnSelect();
            if (obj.link === 'lock_faz_a') this.clickSetFaz(obj.obj, 'A');
            if (obj.link === 'lock_faz_b') this.clickSetFaz(obj.obj, 'B');
            if (obj.link === 'lock_faz_c') this.clickSetFaz(obj.obj, 'C');
            if (obj.link === 'lock_faz') this.clickSetLock(obj.obj, 'faz');
            if (obj.link === 'lock_avt0') this.clickSetLock(obj.obj, 'avt0');
            if (obj.link === 'lock_avt1') this.clickSetLock(obj.obj, 'avt1');
            if (obj.link === 'lock_cable') this.clickSetLock(obj.obj, 'cable');

            if (obj.link === 'set_avt_min_10') this.clickSetAvtMin(obj.obj, 10);
            if (obj.link === 'set_avt_min_16') this.clickSetAvtMin(obj.obj, 16);
            if (obj.link === 'set_avt_min_20') this.clickSetAvtMin(obj.obj, 20);

            if (obj.link === 'set_cable_min_15') this.clickSetCableMin(obj.obj, 1.5);
            if (obj.link === 'set_cable_min_25') this.clickSetCableMin(obj.obj, 2.5);
            if (obj.link === 'set_cable_min_4') this.clickSetCableMin(obj.obj, 4);

            if (obj.link === 'r_by_room') this.raspredByRoom(obj.obj);
            if (obj.link === 'r_by_marker') this.raspredByMarker(obj.obj);
            if (obj.link === 'auto_calc') this.autoCalc(obj.obj);
            if (obj.link === 'select_panel_all') this.selectPanelAll(obj.obj);
            if (obj.link === 'like_this') this.likeThis(obj.obj);
        },
        clickSetAvtMin(obj, k) {
            this.local.selectArr.forEach(item => {
                item.child.forEach(item2 => item2.panel.avt1 = k);
            });
        },
        clickSetCableMin(obj, k) {
            this.local.selectArr.forEach(item => {
                item.child.forEach(item2 => item2.panel.kab1 = k);
            });
        },
        raspredByRoom(obj) {
            //todo js поиск уникальных значений
            let keys = obj.child.map(item => item.room.id).filter((value, index, self) => self.indexOf(value) === index);
            if (keys.length < 2) return;
            this.local.selectArr.filter(item => item.id !== obj.id).forEach((item, index) => {
                if (!!keys[index]) {
                    let fChild = obj.child.filter(item2 => item2.room.id === keys[index]);
                    fChild.forEach(item3 => {
                        item.child.push(item3);

                        //todo js delete Array element
                        let key = obj.child.findIndex(item4 => item4.id === item3.id);
                        if (key >= 0) obj.child.splice(key, 1);
                    })
                }
            })
        },
        raspredByMarker(obj) {
            //todo js поиск уникальных значений
            let keys = obj.child.map(item => item.marker.id).filter((value, index, self) => self.indexOf(value) === index);
            if (keys.length < 2) return;
            this.local.selectArr.filter(item => item.id !== obj.id).forEach((item, index) => {
                if (!!keys[index]) {
                    let fChild = obj.child.filter(item2 => item2.marker.id === keys[index]);
                    fChild.forEach(item3 => {
                        item.child.push(item3);

                        //todo js delete Array element
                        let key = obj.child.findIndex(item4 => item4.id === item3.id);
                        if (key >= 0) obj.child.splice(key, 1);
                    })
                }
            })
        },
        clickSetFaz(obj, faz) {
            this.local.selectArr.forEach(item => {
                item.panel.l = faz;
                item.lock.faz = true;
            })
        },
        clickSetLock(obj, k) {
            this.local.selectArr.forEach(item => {
                item.lock[k] = !item.lock[k];
            })
        },
        clickMovePotr(obj) {
            this.local.selectArr.filter(item => item.id !== obj.id).forEach(item => {
                item.child.forEach(item2 => {
                    obj.child.push(item2);
                })
                item.child.length = 0;
            })
        },
        clickMovePotrAll(obj) {
            this.local.selectArr.filter(item => item.id !== obj.id).forEach(item => {
                item.child.forEach(item2 => {
                    obj.child.push(item2);
                })
                item.child.length = 0;
            })
            obj.avt = JSON.parse(JSON.stringify(this.lastSelectObj.avt));
            obj.cable = JSON.parse(JSON.stringify(this.lastSelectObj.cable));
            obj.es.faz = this.lastSelectObj.es.faz;
            obj.lock = JSON.parse(JSON.stringify(this.lastSelectObj.lock));
        },
        clickRemoveSection(obj) {
            this.local.selectArr.forEach(item=>{
                let bbs = this.getBbsById(item.bbs.id);
                if (!bbs) return;
                //todo js delete Array element
                let key = bbs.child.findIndex(item2 => item2.id === item.id);
                if (key < 0) return;
                bbs.child.splice(key, 1);
            })
        },
        clickAddSection(obj) {
            let bbs = this.getBbsById(obj.bbs.id);
            if (!bbs) return;
            let objNew = JSON.parse(JSON.stringify(obj));
            objNew.id = uniqid();
            objNew.child = [];
            objNew.handle = '';
            bbs.child.push(objNew);
            return objNew;
        },
        getBbsById(id) {
            let res = null;
            let find = false;
            this.pLocal.eFull.forEach(item => {
                if (!find) res = item.child.find(item2 => item2.id === id);
                find = !!res;
            })
            return res;
        },
        getChild(child) {
            if (child[0] === undefined) return [{id: 'as'}];
            else return child[0];
        },
        dStart() {
            this.isMove = true;
        },
        dStop() {
            this.isMove = false;
        },
        move() {
            return this.isMove;
        },
        clickCable(obj, tt) {
            let index = this.local.listSec.findIndex(item => item === obj.cable.sec);
            if (tt === 'down') index--; else index++;
            if (index === 0) {
                if (this.local.selectArr.length > 1) {
                    this.local.selectArr.forEach(item => {
                        item.cable.id = null;
                        item.cable.sec = 0;
                        item.lock.cable = true;
                    })
                } else {
                    obj.cable.id = null;
                    obj.cable.sec = 0;
                    obj.lock.cable = true;
                }
            }
            if (this.local.listSec[index] !== undefined) {
                let orig = this.local.listBaseCable.find(item => item.id === obj.cable.id);
                if (!orig) return;
                let nnew = this.local.listBaseCable.find(item => item.dop.marka === orig.dop.marka && item.firma === orig.firma && item.dop.colvo === orig.dop.colvo && item.dop.sech * 1 === this.local.listSec[index]);
                if (!nnew) return;

                if (this.local.selectArr.length > 1) {
                    this.local.selectArr.forEach(item => {
                        this.setCableInfo(item.cable, nnew);
                        item.lock.cable = true;
                    })
                } else {
                    this.setCableInfo(obj.cable, nnew);
                    obj.lock.cable = true;
                }
            }
        },
        setCableInfo(cable, base) {
            cable.id = base.id;
            cable.core = base.dop.colvo;
            cable.marka = base.marka;
            cable.material = base.dop.cual;
            cable.sec = base.dop.sech * 1;
            cable.series = base.dop.marka;
        },
        clickAvt(obj, tt, k = 0) {
            let index = this.local.listAvt.findIndex(item => item === obj.avt[k].iots);
            if (tt === 'down') index--; else index++;
            if (this.local.listAvt[index] !== undefined) {
                let orig = this.local.listBaseAvt.find(item => item.id === obj.avt[k].id);
                if (!orig) return;
                let nnew = this.local.listBaseAvt.find(item =>
                    item.dop.seriya === orig.dop.seriya && item.firma === orig.firma && item.dop.pol === orig.dop.pol && item.dop.har === orig.dop.har && item.dop.Iots * 1 ===
                    this.local.listAvt[index]
                );
                if (!nnew) return;

                if (this.local.selectArr.length > 1) {
                    this.local.selectArr.forEach(item => {
                        this.setAvtInfo(item.avt[k], nnew);
                        if (k === 0) item.lock.avt0 = true;
                        else item.lock.avt1 = true;
                    })
                } else {
                    this.setAvtInfo(obj.avt[k], nnew);
                    if (k === 0) obj.lock.avt0 = true;
                    else obj.lock.avt1 = true;
                }
            }
        },
        setAvtInfo(avt, base) {
            avt.id = base.id;
            avt.har = base.dop.har;
            avt.har_diff = null;
            avt.ics = base.dop.Icn;
            avt.inom = base.dop.Inom;
            avt.iots = base.dop.Iots;
            avt.iut = base.dop.Iut;
            avt.marka = base.marka;
            avt.poles = base.dop.pol;
            avt.series = base.dop.seriya;
            avt.type = base.dop.tip * 1;
        },
        clickCore(obj) {
            let index = this.local.listCore.findIndex(item => item === obj.cable.core);
            index++;
            if (this.local.listCore[index] === undefined) index = 0;
            if (this.local.selectArr.length > 1) {
                this.local.selectArr.forEach(item => {
                    item.cable.core = this.local.listCore[index];
                    item.cable.id = 0;
                })
            } else {
                obj.cable.core = this.local.listCore[index];
                obj.cable.id = 0;
            }

        },
        clickSwDown(obj) {
            this.local.potrVisible.push(obj);
        },
        clickSwUp(obj) {
            let key = this.local.potrVisible.findIndex(item => item.id === obj.id);
            if (key >= 0) {
                this.local.potrVisible.splice(key, 1);
            }
        },
        getVisiblePotr(obj) {
            if (obj === undefined) return false;
            let key = this.local.potrVisible.findIndex(item => item.id === obj.id);
            return key >= 0;
        },
        getComm(obj) {
            let res = ''
            //todo js поиск уникальных значений
            let oo = obj.child.map(item => item.name2).filter((value, index, self) => self.indexOf(value) === index);
            if (oo) oo.forEach(item => {
                if (res === '') res = item;
                else res += ', ' + item;
            })
            return res
        },
        getCommRoom(obj) {
            let res = ''
            //todo js поиск уникальных значений
            let oo = obj.child.map(item => item.marker.explication_tag + '.' + item.room.name).filter((value, index, self) => self.indexOf(value) === index);
            if (oo) oo.forEach(item => {
                if (res === '') res = item;
                else res += ', ' + item;
            })
            return res
        },
        localSave() {
            let eFull = [];
            this.local.table.forEach(item => {
                eFull.push(item);
            })
            this.pLocal.eFull = eFull;
        },
        showModal() {
            if (this.local.selectArr.length === 0) return;
            if (this.local.selectArr.length > 1) {
                let k = JSON.parse(JSON.stringify(this.local.selectArr.filter(item => item.nn !== this.eTable.lastSelectObj().nn)));
                console.log(k);
                // this.local.selectArr = [];
                k.forEach(item => this.eTable.clickCtrlRow(item));
            }
            if (this.eTable.lastSelectObj().nn === 'электрощит') Bus.$emit('show_modalPanel0')
            if (this.eTable.lastSelectObj().nn === 'bbs') Bus.$emit('show_modalBbs0')
            if (this.eTable.lastSelectObj().nn === 'секция') Bus.$emit('show_modalSection0')
        },
        showModalInsert() {
            if (this.local.selectArr.length === 0) return;
            Bus.$emit('showI_modalPanel0');
        },
        allocationPotr() {
            this.emptyPotr.forEach(item => {
                let panel = this.pLocal.eFull.find(item2 => {
                    return item2.name2.toLowerCase() === item.panel.name.toLowerCase();
                })
                if (panel && panel.child[0].child.length > 0) {
                    let section = panel.child[0].child.find(item2 => item2.name.toLowerCase() === item.section.name.toLowerCase());
                    if (section) {
                        section.child.push(item);
                    } else {
                        let newO = this.clickAddSection(panel.child[0].child[0]);
                        if (newO) {
                            newO.name = item.section.name;
                            newO.child.push(item);
                        }
                    }
                }
            })

        },
        init() {
            let res = [];
            res = this.pLocal.eFull;

            this.local.table = res;

            this.allocationPotr();
        },
    },
    computed: {
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
        fTable: function () {
            if (this.state.search.val === '') return this.local.table;
            return this.local.table.filter(item => {
                let res = true;
                this.state.search.val.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        brandCable: function () {
            let res = {};
            this.pLocal.eFull.forEach(item => {
                item.child.forEach(item2 => {
                    item2.child.forEach(item3 => {
                        if (item3.cable.id !== null && item3.cable.id !== 0) {
                            if (!res[item3.cable.id]) res[item3.cable.id] = 0;
                            res[item3.cable.id] += 1;
                        }
                    })
                })
            })

            //todo js сортировка объекта с преобразованием его в массив
            let res2 = [];
            for (let resKey in res) res2.push([resKey, res[resKey]]);
            res2.sort((a, b) => b[1] - a[1]);

            let res3 = [];

            for (let i = 0; i < 3; i++) {
                if (res2[i] !== undefined) {
                    let orig = this.local.listBaseCable.find(item => item.id === res2[i][0] * 1);
                    if (orig !== undefined) res3.push(orig);
                }
            }

            return res3;
        },
        BrandAvt: function () {
            let res = {};
            this.pLocal.eFull.forEach(item => {
                item.child.forEach(item2 => {
                    item2.child.forEach(item3 => {
                        if (item3.avt[0].id !== null && item3.avt[0].id !== 0) {
                            if (!res[item3.avt[0].id]) res[item3.avt[0].id] = 0;
                            res[item3.avt[0].id] += 1;
                        }
                    })
                })
            })

            //todo js сортировка объекта с преобразованием его в массив
            let res2 = [];
            for (let resKey in res) res2.push([resKey, res[resKey]]);
            res2.sort((a, b) => b[1] - a[1]);

            return res2;
        },
        emptyPotr: function () {
            return this.pLocal.ePotr.filter(item => {
                return !this.allPotr.find(item2 => item2.id === item.id);
            })
        },
        emptyPanel: function () {
            return this.pLocal.ePanel.filter(item => {
                return !this.allPotr.find(item2 => item2.id === item.id);
            })
        },
        allPotr: function () {
            let potr = [];
            this.pLocal.eFull.forEach(item => {
                item.child.forEach(item2 => {
                    item2.child.forEach(item3 => {
                        item3.child.forEach(item4 => {
                            potr.push(item4);
                        })
                    })
                })
            })
            return potr;
        },
    },
    watch: {
        'fTable': {
            handler: function (newVal, oldVal) {
                this.eTable.clearSelectArr();
            },
            deep: false
        },
    }
}
</script>

<style scoped lang="scss">
@import 'resources/sass/variables';

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.row {
    padding: 0;
    margin: 0.1rem 0;

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        text-align: center;
        padding: 0;
    }

    .zag {
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        border: 1px solid $primary;
        background-color: lighten($primary, 20%);
    }
}

.row-w {
    border-bottom: 1px solid #959595;
    cursor: pointer;
}

span.small {
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    font-style: italic;
}

table.table.in_td {
    /*td{*/
    /*    text-align: center;*/
    /*    vertical-align: middle;*/
    /*}*/
    th {
        border: none;
        background: none;
        padding: 0;
    }

    tr {
        border: none;
        background: none;
        height: 0px;

        &.hover:hover {
            background-color: lighten($primary, 20%);
        }
    }

    tbody tr:first-child {
        td {
            border: none;
        }
    }

    .bg-primary-l {
        background-color: lighten($primary, 40);
    }
}

</style>
