var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMount
    ? _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: { id: _vm.modalId, role: "dialog", "data-keyboard": "false" },
          on: { click: _vm.closeModal },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-xl",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-header",
                    staticStyle: { cursor: "move" },
                  },
                  [
                    _vm.state.isEdit
                      ? _c("span", [_vm._v("редактировать")])
                      : _c("span", [_vm._v("вставить")]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "text-danger mt-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                      on: { click: _vm.closeModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isShow
                  ? _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          staticStyle: { "max-height": "calc(100vh - 236px)" },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "overflow-x": "hidden" } },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-3",
                                      class: {
                                        "font-weight-bold":
                                          _vm.state.obj.baseType === "bs",
                                      },
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          _vm.state.obj.baseType = "bs"
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticStyle: { color: "grey" },
                                        attrs: { icon: "fa-regular fa-star" },
                                      }),
                                      _vm._v(" база"),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-3",
                                      class: {
                                        "font-weight-bold":
                                          _vm.state.obj.baseType === "pr",
                                      },
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          _vm.state.obj.baseType = "pr"
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticStyle: { color: "royalblue" },
                                        attrs: {
                                          icon: "fa-regular fa-circle-user",
                                        },
                                      }),
                                      _vm._v(" личная"),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-6" },
                                        [
                                          _c("multiselect", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              options:
                                                _vm.local.listCategory.map(
                                                  (item) => item.id
                                                ),
                                              "show-labels": false,
                                              searchable: true,
                                              "close-on-select": true,
                                              "allow-empty": false,
                                              "internal-search": false,
                                            },
                                            on: {
                                              "search-change": _vm.customSearch,
                                              input: _vm.onChange,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "singleLabel",
                                                  fn: function (props) {
                                                    return [
                                                      _vm.local.listCategory.find(
                                                        (item) =>
                                                          item.id ===
                                                          props.option
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__desc",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "option__title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.local.listCategory.find(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.id ===
                                                                          props.option
                                                                      ).name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__desc",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "option__title",
                                                                },
                                                                [_vm._v("___")]
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "option",
                                                  fn: function (props) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "option__desc",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__title",
                                                              style: {
                                                                "padding-left":
                                                                  _vm.local.listCategory.find(
                                                                    (item) =>
                                                                      item.id ===
                                                                      props.option
                                                                  ).level +
                                                                  "rem",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.local.listCategory.find(
                                                                    (item) =>
                                                                      item.id ===
                                                                      props.option
                                                                  ).name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1546234801
                                            ),
                                            model: {
                                              value: _vm.state.obj.fCat,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.state.obj,
                                                  "fCat",
                                                  $$v
                                                )
                                              },
                                              expression: "state.obj.fCat",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-6" },
                                        [
                                          _c("multiselect", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              options: _vm.listCatFields,
                                              "show-labels": false,
                                              searchable: true,
                                              "close-on-select": true,
                                              "allow-empty": false,
                                              "internal-search": false,
                                            },
                                            on: {
                                              "search-change": _vm.customSearch,
                                              input: _vm.onChange,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "singleLabel",
                                                  fn: function (props) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "option__desc",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.option
                                                                    .name
                                                                )
                                                              ),
                                                              props.option.unit
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      ", " +
                                                                        _vm._s(
                                                                          props
                                                                            .option
                                                                            .unit
                                                                        )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "option",
                                                  fn: function (props) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "option__desc",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.option
                                                                    .name
                                                                )
                                                              ),
                                                              props.option.unit
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      ", " +
                                                                        _vm._s(
                                                                          props
                                                                            .option
                                                                            .unit
                                                                        )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2751041249
                                            ),
                                            model: {
                                              value: _vm.state.fCatField,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.state,
                                                  "fCatField",
                                                  $$v
                                                )
                                              },
                                              expression: "state.fCatField",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-1" }, [
                                        !!this.state.fCatField.id
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "form-control btn btn-primary",
                                                on: { click: _vm.addVars },
                                              },
                                              [_vm._v("add")]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("b-input-val-so", {
                                      model: {
                                        value: _vm.state.obj,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.state, "obj", $$v)
                                        },
                                        expression: "state.obj",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.state.obj.v, function (el) {
                                      return _c(
                                        "div",
                                        { key: el.id, staticClass: "row" },
                                        [
                                          _c("div", { staticClass: "col-2" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: el.id_f,
                                                  expression: "el.id_f",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "form-control w-100",
                                              attrs: { title: "id" },
                                              domProps: { value: el.id_f },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    el,
                                                    "id_f",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-3 pt-2",
                                              attrs: {
                                                title: _vm.getFFieldDesc(el),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.getFFieldName(el))
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "small" },
                                                [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        _vm.getFFieldType(el)
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-2" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-3 font-weight-bold position-absolute h4 text-right",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                  top: "0.4rem",
                                                  left: "-1.5rem",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickSign(el)
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(el.ff.sign))]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: el.v_in,
                                                  expression: "el.v_in",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "form-control w-100",
                                              attrs: { title: "значение" },
                                              domProps: { value: el.v_in },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    el,
                                                    "v_in",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-3 font-weight-bold position-absolute text-left",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                  top: "0.4rem",
                                                  right: "-0.3rem",
                                                },
                                              },
                                              [
                                                !el.ff.order
                                                  ? _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["fas", "sort"],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickOrder(
                                                            el
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                el.ff.order === "asc"
                                                  ? _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "fas",
                                                          "sort-up",
                                                        ],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickOrder(
                                                            el
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                el.ff.order === "desc"
                                                  ? _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "fas",
                                                          "sort-down",
                                                        ],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickOrder(
                                                            el
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-3" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: el.formula,
                                                  expression: "el.formula",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "form-control w-100",
                                              attrs: { title: el.resp.fText },
                                              domProps: { value: el.formula },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    el,
                                                    "formula",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            el.resp.eMess
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "small text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(el.resp.eMess)
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-2 d-flex" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-75 position-relative",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model.trim",
                                                        value: el.v_out,
                                                        expression: "el.v_out",
                                                        modifiers: {
                                                          trim: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      disabled: "",
                                                      title: "результат",
                                                    },
                                                    domProps: {
                                                      value: el.v_out,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          el,
                                                          "v_out",
                                                          $event.target.value.trim()
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "position-absolute",
                                                      staticStyle: {
                                                        top: "0.45rem",
                                                        right: "0.8rem",
                                                        cursor: "pointer",
                                                      },
                                                      attrs: {
                                                        title: "округление",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickRound(
                                                            el
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(el.round))]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "text-danger mt-2 ml-3 w-auto",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                                attrs: {
                                                  icon: "fa-solid fa-xmark",
                                                  size: "lg",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeVars(el)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-1" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "form-control btn btn-primary",
                                            on: { click: _vm.clickSearch },
                                          },
                                          [_vm._v("поиск")]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.local.table, function (el) {
                                      return _c(
                                        "div",
                                        { key: el.uid, staticClass: "row" },
                                        [
                                          _c("div", { staticClass: "col-1" }, [
                                            el.img && el.img[0]
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "5rem",
                                                  },
                                                  attrs: {
                                                    src: el.img[0],
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-7" }, [
                                            _vm._v(_vm._s(el.name)),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-2" }, [
                                            _vm._v(_vm._s(el.marka)),
                                            _c("br"),
                                            _vm._v(_vm._s(el.article)),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-1" }, [
                                            _vm._v(_vm._s(el.brand)),
                                          ]),
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      { staticClass: "apple-switch_2 mr-3" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.state.obj.isCalculate,
                                              expression:
                                                "state.obj.isCalculate",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.state.obj.isCalculate
                                            )
                                              ? _vm._i(
                                                  _vm.state.obj.isCalculate,
                                                  null
                                                ) > -1
                                              : _vm.state.obj.isCalculate,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.state.obj.isCalculate,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.state.obj,
                                                      "isCalculate",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.state.obj,
                                                      "isCalculate",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.state.obj,
                                                  "isCalculate",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("i"),
                                        _vm._v("учитывать в расчётах блока"),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("c-input-razdel-name-so", {
                                      model: {
                                        value: _vm.state.obj,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.state, "obj", $$v)
                                        },
                                        expression: "state.obj",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("c-input-desc-so", {
                                      model: {
                                        value: _vm.state.obj.element,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.state.obj,
                                            "element",
                                            $$v
                                          )
                                        },
                                        expression: "state.obj.element",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer d-flex flex-row" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.clickLocalSave },
                    },
                    [_vm._v("сохранить")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("закрыть")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }