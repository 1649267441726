<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount">
        <div class="modal-dialog modal-lg" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span v-if="state.isEdit">редактировать</span>
                    <span v-else>вставить</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <button class="btn btn-primary" @click="state.isPrice=true" :class="{active:state.isPrice}">договор</button>
                            <button v-if="!state.isFirst" class="btn btn-primary" @click="state.isPrice=false" :class="{active:!state.isPrice}">поступление</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <datepicker v-model="state.obj.d_event" :language="ru" :format="toolM.customFormatter" inputClass="form-control"/>
                        </div>
                        <div class="col-3">
                            <input class="form-control" v-model.number="state.obj.amount" ref="v_focus" placeholder="сумма">
                        </div>
                        <div v-if="!state.isPrice" class="col-6">
                            <div class="form-check d-inline-block mt-2">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="state.obj.wallet" :checked="state.obj.wallet==='avangard'" value="avangard">
                                    <img :src="'/img/avangard_logo.png'" style="width: 1.5rem" class="mb-1"/>
                                </label>
                            </div>
                            <div class="form-check d-inline-block mt-2 ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="state.obj.wallet" :checked="state.obj.wallet==='sber'" value="sber">
                                    <img :src="'/img/sber_logo.png'" style="width: 1.5rem" class="mb-1"/>
                                </label>
                            </div>
                            <div class="form-check d-inline-block mt-2 ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="state.obj.wallet" :checked="state.obj.wallet==='cache'" value="cache">
                                    <font-awesome-icon :icon="['fas', 'hand-holding-dollar']" class="text-secondary" size="lg"/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <input class="form-control" v-model.trim="state.obj.comm" placeholder="комментарий">
                        </div>
                    </div>

                </div>
                <div class="modal-footer d-flex flex-row justify-content-end">
                    <div>
                        <button v-if="state.isEdit" type="button" class="btn btn-default" @click="clickDelete">удалить</button>
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickSave">сохранить</button>
                        <button v-else type="button" class="btn btn-primary" @click="clickInsert">вставить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {modalDraggable, page, toolM} from "./tools";
import EInput from "./EInput";
import {ru} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import moment from "moment/moment";


export default {
    name: 'modal-finance',
    components: {EInput, Multiselect, Datepicker},
    props: {
        modalId: {type: String, default: 'modal',},
        pLocal: {type: Object, default: {},},
        pState: {type: Object, default: {},},
    },
    data: function () {
        return {
            //todo js modal add page module
            pageT: new page(this),
            toolM: new toolM(this),
            rand: 1,
            local: {
                finance: this.pLocal.finance,
                ps_info: this.pLocal.ps_info,
            },
            state: {
                isEdit: true,
                obj: null,
                isPrice: false,
                isFirst: false,
            },
            status: {
                loading: false,
            },
            isImport: false,
            templateObj: {
                amount: '',
                comm: '',
                d_event: '',
                id: '',
                t_event: '',
                wallet: '',
            },
            lock: {},
            ru: ru,
            isMount: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlEnter') this.clickSave();
        });
        Bus.$on('show_' + this.modalId, () => {
            this.state.isEdit = true;
            this.showModal()
        });
        Bus.$on('showI_' + this.modalId, () => {
            this.state.isEdit = false;
            this.showModal();
        });
    },
    mounted() {
        new modalDraggable(this);
        this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
        this.isMount = true;
    },
    methods: {
        showModal() {
            if (this.pLocal.finance.length === 0) {
                this.state.isFirst = true;
                this.state.isPrice = true;
            } else {
                this.state.isFirst = false;
                this.state.isPrice = false;
            }

            if (this.state.isEdit) {
                this.state.obj.amount = this.pState.obj.amount;
                this.state.obj.comm = this.pState.obj.comm;
                this.state.obj.d_event = this.pState.obj.d_event;
                this.state.obj.id = this.pState.obj.id;
                this.state.obj.t_event = this.pState.obj.t_event;
                this.state.obj.wallet = this.pState.obj.wallet;
            } else {
                this.state.obj.amount = this.getRest()===0?'':this.getRest();
                this.state.obj.comm = '';
                this.state.obj.d_event = this.local.ps_info.start_date;
                this.state.obj.t_event = this.state.isPrice ? 'price' : 'receipt';
                this.state.obj.wallet = '';
            }
            $('#' + this.modalId).modal('show');

            setTimeout(() => {
                if (this.$refs.v_focus) this.$refs.v_focus.focus()
            }, 200);
        },
        getRest() {
            let price = 0;
            let receipt = 0;
            this.pLocal.finance.forEach(item => {
                if (item.t_event === 'price') price += parseFloat(item.amount);
                if (item.t_event === 'receipt') receipt += parseFloat(item.amount);
            })

            return price - receipt;
        },
        preSave() {
            this.state.obj.t_event = this.state.isPrice ? 'price' : 'receipt';
        },
        clickSave() {
            this.preSave();
            this.pageT.clickSave('/project/finance/save', {obj: this.state.obj}, '_finance_save');
            Bus.$once('zResponse_finance_save', response => {
                Bus.$emit('updTableFinance', response.table);
                Bus.$emit('updTable');
                this.closeModal();
            });
        },
        clickDelete() {
            this.pageT.clickSave('/project/finance/delete', {id: this.state.obj.id}, '_finance_delete');
            Bus.$once('zResponse_finance_delete', response => {
                Bus.$emit('updTableFinance', response.table);
                Bus.$emit('updTable');
                this.closeModal();
            });
        },
        clickInsert() {
            this.preSave();
            this.pageT.clickSave('/project/finance/insert', {obj: this.state.obj}, '_finance_insert');
            Bus.$once('zResponse_finance_insert', response => {
                Bus.$emit('updTableFinance', response.table);
                Bus.$emit('updTable');
                this.closeModal();
            });
        },
        setObj(obj, iObj, isExport = false) {
            // if (!this.lock.name1) obj.name1 = iObj.name1;
            // if (!this.lock.name2) obj.name2 = iObj.name2;
            // if (!this.lock.name3) obj.name3 = iObj.name3;
            // if (!this.lock.pu) obj.es.pu = iObj.es.pu;
            // if (!this.lock.cos) obj.es.cos = iObj.es.cos;
            // if (!this.lock.i_st) obj.es.i_st = iObj.es.i_st;
            // if (!this.lock.faz) obj.es.faz = iObj.es.faz;
            // if (!this.lock.dd) obj.dop.dd = iObj.dop.dd;
            // if (!this.lock.dlina) obj.dop.dlina = iObj.dop.dlina;
            // if (!this.lock.otm && !isExport) obj.dop.otm = iObj.dop.otm;
            // if (!this.lock.arr) obj.panel.arr = iObj.panel.arr;
            // if (!this.lock.nrr) obj.panel.nrr = iObj.panel.nrr;
            // if (!this.lock.fr) obj.panel.fr = iObj.panel.fr;
            // if (!this.lock.avt1) obj.panel.avt1 = iObj.panel.avt1;
            // if (!this.lock.avt2) obj.panel.avt2 = iObj.panel.avt2;
            // if (!this.lock.kab1) obj.panel.kab1 = iObj.panel.kab1;
            // if (!this.lock.kab2) obj.panel.kab2 = iObj.panel.kab2;
            // if (!this.lock.grouped_up) obj.panel.grouped_up = iObj.panel.grouped_up;
            // if (!this.lock.room_is_hide) obj.room.isHide = iObj.room.isHide;
        },
        addNewItem1(newTag) {
            // this.state.obj.name1 = newTag;
            // this.local.listName1.push(newTag);
        },
        addNewItem2(newTag) {
            // this.state.obj.name2 = newTag;
            // this.local.listName2.push(newTag);
        },
        onOpen(r, f) {
            // setTimeout(() => {
            //     this.$refs[r].$refs.search.value = this.state.obj[f];
            // }, 10);
        },
        onChange() {
            // if (!this.state.obj) this.state.isEdit = false;
        },
        clickImport(unLock) {
            // this.setObj(this.state.obj, this.state.obj.import, true);
            // console.log(this.state.obj.import);
            // this.setLock(!unLock);
            // this.isImport = false;
        },
        closeModal() {
            this.$emit('updInit');
            $('#' + this.modalId).modal('hide');
        },
        //todo js multiselect custom search
    },
    computed: {},
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.form-check-label{
    img{
        cursor: pointer;
    }
    input{
        cursor: pointer;
    }
}
svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}


input.lock {
    background-color: lighten($primary, 50%);
}

</style>
