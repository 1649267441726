var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-fluid m1" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-xl-11" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm._l(_vm.listRazdel, function (val) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm",
                        class: { active: val === _vm.state.currentRazdel },
                        on: {
                          click: function ($event) {
                            return _vm.clickRazdel(val)
                          },
                        },
                      },
                      [_vm._v(_vm._s(val))]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-xl-11" }, [
          _c("ul", { staticClass: "nav nav-tabs" }, [
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  class: { active: _vm.state.activeTab === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(0)
                    },
                  },
                },
                [_vm._v("Потребители")]
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  class: { active: _vm.state.activeTab === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(1)
                    },
                  },
                },
                [_vm._v("По названию")]
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  class: { active: _vm.state.activeTab === 2 },
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(2)
                    },
                  },
                },
                [_vm._v("Все")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item ml-auto" },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-2 mt-2",
                  staticStyle: { cursor: "pointer" },
                  attrs: { icon: "fa-solid fa-circle", size: "lg" },
                  on: {
                    click: function ($event) {
                      return _vm.clickDot("select")
                    },
                  },
                }),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  staticClass: "mr-2 mt-2",
                  staticStyle: { cursor: "pointer" },
                  attrs: { icon: "fa-regular fa-circle", size: "lg" },
                  on: {
                    click: function ($event) {
                      return _vm.clickDot("un_select")
                    },
                  },
                }),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  staticClass: "mr-2 mt-2",
                  staticStyle: { cursor: "pointer" },
                  attrs: { icon: "fa-solid fa-circle-dot", size: "lg" },
                  on: {
                    click: function ($event) {
                      return _vm.clickDot("inverse")
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.state.activeTab === 0
            ? _c(
                "div",
                { staticClass: "card", staticStyle: { "border-top": "none" } },
                [
                  _vm.isMounted
                    ? _c("e-potr0", {
                        attrs: { "p-local": _vm.local, "p-state": _vm.state },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.state.activeTab === 1
            ? _c(
                "div",
                { staticClass: "card", staticStyle: { "border-top": "none" } },
                [
                  _vm.isMounted
                    ? _c("e-potr1", {
                        attrs: { "p-local": _vm.local, "p-state": _vm.state },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.state.activeTab === 2
            ? _c(
                "div",
                { staticClass: "card", staticStyle: { "border-top": "none" } },
                [
                  _vm.isMounted
                    ? _c("e-potr2", {
                        attrs: { "p-local": _vm.local, "p-state": _vm.state },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-12 col-xl-8" }, [
        _c("h1", { staticClass: "mb-0" }, [_vm._v("Потребители")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }