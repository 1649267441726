var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "w-100 text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-sm",
          on: { click: _vm.clickAddRow },
        },
        [_vm._v("добавить")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        _vm._l(_vm.fFields, function (val, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "card my-1 myRow",
              class: {
                selected:
                  _vm.currentObj !== null && _vm.currentObj.id === val.id,
              },
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-start" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-danger mr-2 mt-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-xmark", title: "удалить" },
                      on: {
                        click: function ($event) {
                          return _vm.clickRemoveRow(val)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: val.name,
                          expression: "val.name",
                        },
                      ],
                      staticClass: "form-control w-25",
                      domProps: { value: val.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(val, "name", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-75 ml-2" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: val.pattern,
                            expression: "val.pattern",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "1" },
                        domProps: { value: val.pattern },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(val, "pattern", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-100 mt-2" }, [
                        _vm._v(_vm._s(_vm.getValue(val))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-2" },
                      [
                        _vm.currentObj !== null && _vm.currentObj.id === val.id
                          ? _c("font-awesome-icon", {
                              attrs: { icon: "fa-solid fa-circle-check" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickCurrentId(null)
                                },
                              },
                            })
                          : _c("font-awesome-icon", {
                              attrs: { icon: "fa-solid fa-circle" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickCurrentId(val)
                                },
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  _vm._l(val.fields, function (val2) {
                    return _c(
                      "div",
                      {
                        key: val2.id,
                        staticClass:
                          "d-flex justify-content-start align-items-center",
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-danger mr-2",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            icon: "fa-solid fa-xmark",
                            title: "удалить",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.clickRemoveFieldFromBlock(val, val2)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: val2.id,
                              expression: "val2.id",
                            },
                          ],
                          staticClass: "form-control w-25",
                          domProps: { value: val2.id },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(val2, "id", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-75 ml-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "small",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickNameField(val2)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(val2.name) +
                                  "\n                                    "
                              ),
                              _vm._l(val2.opt, function (val3) {
                                return _c("span", [
                                  _vm._v(" " + _vm._s(val3.val)),
                                ])
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(val2.value))]),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }