var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container-fluid m1" },
        [
          _c("title-for-page", {
            attrs: { "c-page": _vm.local.cPage, nbi: _vm.nbi },
          }),
          _vm._v(" "),
          _vm.isMounted
            ? _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-xl-8" },
                  [
                    _c(
                      "nav",
                      {
                        staticClass: "navbar navbar-expand pl-0 pt-0",
                        staticStyle: { "margin-left": "-0.5rem" },
                      },
                      [
                        _c("div", { staticClass: "container-fluid" }, [
                          _c("div", { staticClass: "navbar-nav" }, [
                            _c("div", { staticClass: "nav-item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "nav-link btn",
                                  class: { active: _vm.state.swOnOpt },
                                  on: {
                                    click: function ($event) {
                                      _vm.state.swOnOpt = !_vm.state.swOnOpt
                                    },
                                  },
                                },
                                [_vm._v("настройки")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "nav-item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "nav-link btn",
                                  class: { active: _vm.state.swImportW },
                                  on: {
                                    click: function ($event) {
                                      _vm.state.swImportW = !_vm.state.swImportW
                                    },
                                  },
                                },
                                [_vm._v("переменые")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nav",
                      {
                        staticClass: "navbar navbar-expand pl-0 pt-0",
                        staticStyle: { "margin-left": "-0.5rem" },
                      },
                      [
                        _c("div", { staticClass: "container-fluid" }, [
                          _c("div", { staticClass: "navbar-nav" }, [
                            _c("div", { staticClass: "nav-item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "nav-link btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickShowModal(null)
                                    },
                                  },
                                },
                                [_vm._v("добавить файл")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "nav-item" }, [
                              !!_vm.cField.opt.attach.name
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn nav-link",
                                      attrs: {
                                        href: _vm.nbi.url.current + "/dl",
                                      },
                                    },
                                    [_vm._v("скачать оригинал")]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            !!_vm.cField.opt.attach.name
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "navbar-text font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      "шаблон: " +
                                        _vm._s(_vm.cField.opt.attach.name)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("card-options", {
                      attrs: { "c-page": _vm.local.cPage, state: _vm.state },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isMounted
            ? _c(
                "div",
                { staticClass: "row justify-content-center" },
                [
                  _c("variables-div", {
                    class: {
                      "col-12": !_vm.state.swImportW,
                      "col-xl-8": !_vm.state.swImportW,
                      "col-6": _vm.state.swImportW,
                    },
                    attrs: { local: _vm.local.cPage.data },
                  }),
                  _vm._v(" "),
                  _c("import-fields", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.state.swImportW,
                        expression: "state.swImportW",
                      },
                    ],
                    staticClass: "col-6",
                    attrs: {
                      "modal-id": "modalImportFields",
                      "is-show": _vm.state.swImportW,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-template-docx", {
        attrs: {
          "modal-id": "modalAttach",
          url: "/template_docx/upload",
          acceptedFiles: ".docx",
          "c-page": _vm.local.cPage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }