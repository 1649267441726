var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "noselect" },
    [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.pLocal.eFull, function (val) {
            return [
              _c(
                "div",
                {
                  key: val.id,
                  staticClass: "row row-w font-weight-bold",
                  class: { "bg-primary-l": _vm.eTable.isSelect(val) },
                  staticStyle: { "background-color": "lightgrey" },
                  on: {
                    click: [
                      function ($event) {
                        if (!$event.ctrlKey) return null
                        $event.preventDefault()
                        return _vm.eTable.clickCtrlRow(val)
                      },
                      function ($event) {
                        if (
                          $event.ctrlKey ||
                          $event.shiftKey ||
                          $event.altKey ||
                          $event.metaKey
                        )
                          return null
                        return _vm.eTable.clickRow(val)
                      },
                    ],
                  },
                },
                [
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-10 text-left" }, [
                        _c("span", [_vm._v(_vm._s(val.name2))]),
                        _c("span", { staticClass: "small ml-2" }, [
                          _vm._v(_vm._s(val.room.name)),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.pu)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-2", attrs: { title: val.es.ks } },
                        [_vm._v(_vm._s(val.es.pr))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.cos)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.ir)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(1, true),
                ]
              ),
              _vm._v(" "),
              _vm._l(val.child, function (val2) {
                return [
                  _c(
                    "div",
                    {
                      key: val2.id,
                      staticClass: "row row-w font-italic",
                      class: { "bg-primary-l": _vm.eTable.isSelect(val2) },
                      staticStyle: { "background-color": "lightgrey" },
                      on: {
                        click: [
                          function ($event) {
                            if (!$event.ctrlKey) return null
                            $event.preventDefault()
                            return _vm.eTable.clickCtrlRow(val2)
                          },
                          function ($event) {
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            )
                              return null
                            return _vm.eTable.clickRow(val2)
                          },
                        ],
                      },
                    },
                    [
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-2" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-10 pl-2 text-left" }, [
                            _vm._v(_vm._s(val2.name)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-2" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-2" }, [
                            _vm._v(_vm._s(val2.es.pu)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-2",
                              attrs: { title: val2.es.ks },
                            },
                            [_vm._v(_vm._s(val2.es.pr))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-2" }, [
                            _vm._v(_vm._s(val2.es.cos)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-2" }, [
                            _vm._v(_vm._s(val2.es.ir)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-2",
                              attrs: {
                                title:
                                  val2.L.A + "/" + val2.L.B + "/" + val2.L.C,
                              },
                            },
                            [_vm._v(_vm._s(val2.es.faz))]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-4",
                              class: {
                                "bg-danger": !!_vm.getWarningAvtGBS(val2),
                              },
                              attrs: { title: _vm.getWarningAvtGBS(val2) },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "bg-danger": !!_vm.getWarningAvt0(
                                      _vm.gbs(val2)
                                    ),
                                  },
                                  attrs: {
                                    title: _vm.getWarningAvt0(_vm.gbs(val2)),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-nowrap text-right" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.gbs(val2).avt[0].marka)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "fa-solid fa-angle-down",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            _vm.clickAvt(_vm.gbs(val2), "down")
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "fa-solid fa-angle-up" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            _vm.clickAvt(_vm.gbs(val2), "up")
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: {
                                    "bg-danger": !!_vm.getWarningAvt0(val2),
                                  },
                                  attrs: { title: _vm.getWarningAvt0(val2) },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-nowrap text-right" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(val2.avt[0].marka)),
                                      ]),
                                      _vm._v(" "),
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "fa-solid fa-angle-down",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.clickAvt(val2, "down")
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "fa-solid fa-angle-up" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.clickAvt(val2, "up")
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !!val2.avt[1].id
                                ? _c(
                                    "div",
                                    {
                                      class: {
                                        "bg-danger": !!_vm.getWarningAvt1(val2),
                                      },
                                      attrs: {
                                        title: _vm.getWarningAvt1(val2),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-nowrap text-right",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(val2.avt[1].marka)),
                                          ]),
                                          _vm._v(" "),
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "fa-solid fa-angle-down",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickAvt(
                                                  val2,
                                                  "down",
                                                  1
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "fa-solid fa-angle-up",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clickAvt(
                                                  val2,
                                                  "up",
                                                  1
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-5" }, [
                            _c(
                              "div",
                              {
                                staticClass: "text-nowrap text-right",
                                class: {
                                  "bg-danger": !!_vm.getWarningCable(
                                    _vm.gbs(val2)
                                  ),
                                },
                                attrs: {
                                  title: _vm.getWarningCable(_vm.gbs(val2)),
                                },
                              },
                              [
                                _vm.gbs(val2).lock.cable
                                  ? _c("font-awesome-icon", {
                                      attrs: {
                                        size: "xs",
                                        icon: "fa-solid fa-lock",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.gbs(val2).lock.cable = false
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !!_vm.gbs(val2).marka_new.id
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          title: _vm.getICab(_vm.gbs(val2)),
                                        },
                                      },
                                      [
                                        _vm.gbs(val2).cable.number > 1
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.gbs(val2).cable.number
                                                ) + "х"
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                                " +
                                            _vm._s(
                                              _vm.gbs(val2).marka_new.marka
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  attrs: { icon: "fa-solid fa-angle-down" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.clickCable(_vm.gbs(val2), "down")
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  attrs: { icon: "fa-solid fa-angle-up" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.clickCable(_vm.gbs(val2), "up")
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-1" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-1",
                              class: {
                                "text-danger": val2.r_schema !== undefined,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(val2.es.dU) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-1",
                              class: {
                                "text-danger": val2.r_schema !== undefined,
                              },
                            },
                            [
                              _c("div", [_vm._v(_vm._s(val2.es.ik3))]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(Math.round(val2.es.ik1 * 1000, 0))
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    {
                      attrs: {
                        list: val2.child,
                        group: "section",
                        "scroll-sensitivity": 100,
                        "fallback-tolerance": 1,
                        "force-fallback": true,
                        handle: ".handle",
                      },
                    },
                    [
                      _vm._l(val2.child, function (val3) {
                        return [
                          _c(
                            "div",
                            {
                              key: val3.id,
                              staticClass: "row row-w",
                              class: {
                                "bg-primary-l": _vm.eTable.isSelect(val3),
                              },
                              on: {
                                click: [
                                  function ($event) {
                                    if (!$event.ctrlKey) return null
                                    $event.preventDefault()
                                    return _vm.eTable.clickCtrlRow(val3)
                                  },
                                  function ($event) {
                                    if (
                                      $event.ctrlKey ||
                                      $event.shiftKey ||
                                      $event.altKey ||
                                      $event.metaKey
                                    )
                                      return null
                                    return _vm.eTable.clickRow(val3)
                                  },
                                ],
                                contextmenu: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$refs.menu.open($event, val3)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "col-4" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-2 handle",
                                      class: {
                                        "text-danger": !val3.handle,
                                        "font-weight-bold": !val3.handle,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(val3.name) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-10 text-left" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex w-100 justify-content-between",
                                        },
                                        [
                                          _c("div", [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.getComm(val3))),
                                            ]),
                                            _vm._v(" "),
                                            _vm.pState.isPgr
                                              ? _c(
                                                  "span",
                                                  { staticClass: "small" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getCommPgr(val3)
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "small" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getCommRoom(val3)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ]),
                                          _vm._v(" "),
                                          !_vm.getVisiblePotr(val3)
                                            ? _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: "fa-solid fa-angle-down",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickSwDown(val3)
                                                  },
                                                },
                                              })
                                            : _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: "fa-solid fa-angle-up",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickSwUp(val3)
                                                  },
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-4" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-2" }, [
                                    _vm._v(_vm._s(val3.child.length)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-2" }, [
                                    _vm._v(_vm._s(val3.es.pu)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-2",
                                      attrs: { title: val3.es.ks },
                                    },
                                    [_vm._v(_vm._s(val3.es.pr))]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-2" }, [
                                    _vm._v(_vm._s(val3.es.cos)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-2" }, [
                                    _vm._v(_vm._s(val3.es.ir)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-2",
                                      class: {
                                        "bg-danger": !!_vm.getWarningFaz(val3),
                                      },
                                      attrs: { title: _vm.getWarningFaz(val3) },
                                    },
                                    [
                                      val3.panel.l === "A,B,C"
                                        ? _c("span", { staticClass: "col-2" }, [
                                            _vm._v(_vm._s(val3.panel.l)),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      val3.panel.l === "A"
                                        ? _c(
                                            "span",
                                            { staticClass: "col-2 bg-primary" },
                                            [_vm._v(_vm._s(val3.panel.l))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      val3.panel.l === "B"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "col-2 bg-secondary",
                                            },
                                            [_vm._v(_vm._s(val3.panel.l))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      val3.panel.l === "C"
                                        ? _c(
                                            "span",
                                            { staticClass: "col-2 bg-success" },
                                            [_vm._v(_vm._s(val3.panel.l))]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(val3.es.faz) +
                                          "\n                                        "
                                      ),
                                      val3.lock.faz
                                        ? _c("font-awesome-icon", {
                                            attrs: {
                                              size: "xs",
                                              icon: "fa-solid fa-lock",
                                            },
                                            on: {
                                              click: function ($event) {
                                                val3.lock.faz = false
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-4" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-4" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap text-right",
                                        class: {
                                          "bg-danger":
                                            !!_vm.getWarningAvt0(val3),
                                        },
                                        attrs: {
                                          title: _vm.getWarningAvt0(val3),
                                        },
                                      },
                                      [
                                        val3.lock.avt0
                                          ? _c("font-awesome-icon", {
                                              attrs: {
                                                size: "xs",
                                                icon: "fa-solid fa-lock",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  val3.lock.avt0 = false
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(val3.avt[0].marka)),
                                        ]),
                                        _vm._v(" "),
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: "fa-solid fa-angle-down",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickAvt(val3, "down")
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: "fa-solid fa-angle-up",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickAvt(val3, "up")
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !!val3.avt[1].id
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-nowrap text-right",
                                            class: {
                                              "bg-danger":
                                                !!_vm.getWarningAvt1(val3),
                                            },
                                            attrs: {
                                              title: _vm.getWarningAvt1(val3),
                                            },
                                          },
                                          [
                                            val3.lock.avt1
                                              ? _c("font-awesome-icon", {
                                                  attrs: {
                                                    size: "xs",
                                                    icon: "fa-solid fa-lock",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      val3.lock.avt1 = false
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(val3.avt[1].marka)),
                                            ]),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: "fa-solid fa-angle-down",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.clickAvt(
                                                    val3,
                                                    "down",
                                                    1
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: "fa-solid fa-angle-up",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.clickAvt(
                                                    val3,
                                                    "up",
                                                    1
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-5" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap text-right",
                                        class: {
                                          "bg-danger":
                                            !!_vm.getWarningCable(val3),
                                        },
                                        attrs: {
                                          title: _vm.getWarningCable(val3),
                                        },
                                      },
                                      [
                                        val3.lock.cable_so
                                          ? _c(
                                              "strike",
                                              {
                                                staticClass:
                                                  "text-danger font-weight-bold",
                                              },
                                              [_vm._v("со")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        val3.lock.cable
                                          ? _c("font-awesome-icon", {
                                              attrs: {
                                                size: "xs",
                                                icon: "fa-solid fa-lock",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  val3.lock.cable = false
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !!val3.marka_new.id
                                          ? _c(
                                              "span",
                                              {
                                                attrs: {
                                                  title: _vm.getICab(val3),
                                                },
                                              },
                                              [
                                                val3.cable.number > 1
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          val3.cable.number
                                                        ) + "х"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(val3.marka_new.marka)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: "fa-solid fa-angle-down",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickCable(
                                                val3,
                                                "down"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: "fa-solid fa-angle-up",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickCable(val3, "up")
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-1",
                                      attrs: { title: val3.cable_length.all },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(val3.cable_length.max) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-1",
                                      class: {
                                        "bg-danger": val3.es.dU > 5,
                                        "bg-secondary": val3.es.dU >= 4,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(val3.es.dU) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-1",
                                      class: {
                                        "bg-danger":
                                          val3.avt[0].iots * 10 >=
                                          val3.es.ik1 * 1000,
                                        "bg-bg-secondary":
                                          val3.avt[0].iots * 5 >=
                                          val3.es.ik1 * 1000,
                                      },
                                      attrs: { title: val3.es.ik3 },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            Math.round(val3.es.ik1 * 1000, 0)
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.isMove
                                ? _c("draggable", {
                                    staticClass: "w-100",
                                    attrs: { list: val3.child, group: "potr" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getVisiblePotr(val3)
                                ? _c(
                                    "draggable",
                                    {
                                      staticClass: "w-100",
                                      attrs: {
                                        list: val3.child,
                                        group: "potr",
                                        "scroll-sensitivity": 100,
                                        "fallback-tolerance": 1,
                                        "force-fallback": true,
                                        handle: ".handle",
                                      },
                                      on: { start: _vm.dStart, end: _vm.dStop },
                                    },
                                    _vm._l(val3.child, function (val4) {
                                      return _c("div", { key: val4.index }, [
                                        _c(
                                          "div",
                                          {
                                            key: val4.id,
                                            staticClass: "row row-w",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c("div", {
                                                      staticClass: "col-2",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-10 pl-2 text-left handle",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(val4.name2)
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _vm.pState.isPgr
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "small",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    val4.marker
                                                                      .explication_tag
                                                                  ) +
                                                                    "." +
                                                                    _vm._s(
                                                                      val4.vykl
                                                                        ?.pgr ??
                                                                        ""
                                                                    )
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "small",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    val4.marker
                                                                      .explication_tag
                                                                  ) +
                                                                    "." +
                                                                    _vm._s(
                                                                      val4.room
                                                                        .name
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(val4.es.kolvo)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(val4.es.pu)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-2",
                                                        attrs: {
                                                          title: val4.es.ks,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(val4.es.pr)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(val4.es.cos)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(val4.es.ir)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(val4.es.faz)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c("div", {
                                                      staticClass: "col-4",
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass: "col-5",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            val4.cable_length
                                                              .all
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass: "col-1",
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass: "col-1",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              }),
            ]
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "w-100",
              attrs: {
                list: _vm.emptyPanel,
                group: "potr",
                "scroll-sensitivity": 100,
                "fallback-tolerance": 1,
                "force-fallback": true,
              },
              on: { start: _vm.dStart, end: _vm.dStop },
            },
            _vm._l(_vm.emptyPanel, function (val) {
              return _c("div", { key: val.index }, [
                _c("div", { key: val.id, staticClass: "row row-w" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-10 pl-2 text-left" }, [
                        _c("span", [_vm._v(_vm._s(val.name2))]),
                        _c("span", { staticClass: "small" }, [
                          _vm._v(
                            _vm._s(val.marker.explication_tag) +
                              "." +
                              _vm._s(val.room.name)
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.kolvo)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.pu)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.pr)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.cos)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.ir)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.faz)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-5" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "w-100",
              attrs: {
                list: _vm.emptyPotr,
                group: "potr",
                "scroll-sensitivity": 100,
                "fallback-tolerance": 1,
                "force-fallback": true,
              },
              on: { start: _vm.dStart, end: _vm.dStop },
            },
            _vm._l(_vm.emptyPotr, function (val) {
              return _c("div", { key: val.index }, [
                _c("div", { key: val.id, staticClass: "row row-w" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-10 pl-2 text-left" }, [
                        _c("span", [_vm._v(_vm._s(val.name2))]),
                        _c("span", { staticClass: "small" }, [
                          _vm._v(
                            _vm._s(val.marker.explication_tag) +
                              "." +
                              _vm._s(val.room.name)
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.kolvo)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.pu)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.pr)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.cos)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.ir)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(_vm._s(val.es.faz)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-5" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-1" }),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("modal-panel", {
        attrs: { "modal-id": "modalPanel0", "p-local": _vm.local },
        on: { updInit: _vm.init },
      }),
      _vm._v(" "),
      _c("modal-bbs-n", {
        attrs: { "modal-id": "modalBbs0", "p-local": _vm.local },
        on: { updInit: _vm.init },
      }),
      _vm._v(" "),
      _c("modal-section-n", {
        attrs: { "modal-id": "modalSectionN", "p-local": _vm.local },
        on: { updInit: _vm.init },
      }),
      _vm._v(" "),
      _c("ContextMenuMy", { ref: "menu", attrs: { items: _vm.items } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row zag" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2" }, [_vm._v("группа")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10 text-left" }, [_vm._v("название")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2" }, [_vm._v("кол-во")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("Ру, кВт")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("Рр, кВт")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("cos")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("Iр, А")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("фазы")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [_vm._v("_")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-5" }, [_vm._v("кабель")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [_vm._v("длина")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [_vm._v("dU, %")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [_vm._v("Ik1, A")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-5" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-1" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }