<template>
    <div>
        <div class="multiselect__select"></div>
        <div class="multiselect__tags">
            <div class="multiselect__tags-wrap" style="display: none;"></div> <!---->
            <div class="multiselect__spinner" style="display: none;"></div>
            <input name="" type="text" autocomplete="nope" placeholder="name2" tabindex="2" class="multiselect__input" style="width: 0px; position: absolute; padding: 0px;">
            <span class="multiselect__single">Котёл</span> <!----></div>
        <div tabindex="-1" class="multiselect__content-wrapper" style="max-height: 300px; /*! display: none; */">
            <ul class="multiselect__content" style="display: inline-block;"> <!---->
                <li class="multiselect__element">
                    <span data-select="Press enter to select" data-selected="Selected" data-deselect="Press enter to remove" class="multiselect__option multiselect__option--highlight"><span>Вытяжка</span></span>
                    <!----></li>
                <li class="multiselect__element">
                    <span data-select="Press enter to select" data-selected="Selected" data-deselect="Press enter to remove" class="multiselect__option"><span>Тёплый пол</span></span> <!----></li>
                <li class="multiselect__element">
                    <span data-select="Press enter to select" data-selected="Selected" data-deselect="Press enter to remove" class="multiselect__option"><span>Розетки</span></span> <!----></li>
                <li style="display: none;"><span class="multiselect__option">No elements found. Consider changing the search query.</span></li>
                <li style="display: none;"><span class="multiselect__option">List is empty.</span></li>
            </ul>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'm-multiselect'
    }
</script>

<style scoped lang="scss">


    .input {
        width: 100%;
    }
</style>
