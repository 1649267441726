<template>
    <div>
        <div class="container-fluid m1">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">
                    <h1 class="mb-0">Щиты</h1>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-12 col-xl-11">
                    <div class="row">
                        <div class="col-12">
                            <template v-for="val in listRazdel">
                                <button class="btn btn-sm" @click="clickRazdel(val)" :class="{active:val===state.currentRazdel}">{{ val }}</button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-12 col-xl-11">
                    <label><input class="apple-switch" type="checkbox" v-model="state.isPgr"> pgr</label><br>
                </div>
            </div>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">


                    <!--                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">-->
                    <!--                        <div class="container-fluid">-->
                    <!--                            <div class="navbar-nav">-->
                    <!--                                <div class="nav-item">-->
                    <!--&lt;!&ndash;                                    <button class="nav-link btn" @click="clickReCalc">пересчитать</button>&ndash;&gt;-->
                    <!--                                </div>-->
                    <!--                                <div class="nav-item">-->
                    <!--                                    <button class="nav-link btn" @click="state.isEdit=!state.isEdit" :class="{active:state.isEdit}">редактирование</button>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </nav>-->

                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-12 col-xl-11">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(0)" :class="{active:state.activeTab===0}">Щиты</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(3)" :class="{active:state.activeTab===3}">ТРН</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(2)" :class="{active:state.activeTab===2}">Все</a>
                        </li>

                        <li class="nav-item ml-auto">
                            <font-awesome-icon icon="fa-solid fa-circle" class="mr-2 mt-2" size="lg" @click="clickDot('select')" style="cursor: pointer;"/>
                            <font-awesome-icon icon="fa-regular fa-circle" class="mr-2 mt-2" size="lg" @click="clickDot('un_select')" style="cursor: pointer;"/>
                            <font-awesome-icon icon="fa-solid fa-circle-dot" class="mr-2 mt-2" size="lg" @click="clickDot('inverse')" style="cursor: pointer;"/>
                        </li>
                    </ul>

                    <div v-if="state.activeTab===0" class="card" style="border-top: none;">
                        <e-panel1 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>
                    <div v-if="state.activeTab===3" class="card" style="border-top: none;">
                        <e-panel3 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>
                    <div v-if="state.activeTab===2" class="card" style="border-top: none;">
                        <e-panel2 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>


                </div>

            </div>


        </div>

    </div>
</template>

<script>
import {page, toolM} from "./sub/tools";
import EPanel2 from "./EPanel2";
import EPanel3 from "./EPanel3";
import EPanel0 from "./EPanel0";
import EPanel1 from "./EPanel1.vue";

export default {
    name: "e-panel",
    components: {EPanel1, EPanel0, EPanel2, EPanel3},
    props: ['ePotr', 'ePanel', 'listMethod', 'listPanelName2', 'listApp', 'listCableSeries', 'listBaseCable', 'listBaseAvt', 'normI', 'listRazdel'],
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            nbi: {},
            local: {
                ePotr: [],
                ePanel: [],
                eFull: [],
                listMethod: this.listMethod,
                listPanelName2: this.listPanelName2,
                listCableSeries: this.listCableSeries,
                listBaseCable: this.listBaseCable,
                listBaseAvt: this.listBaseAvt,
                listApp: this.listApp,
                normI: this.normI,
                currentRoom: {},
            },
            status: {
                loading: false,
            },
            state: {
                version: 6,
                swImportW: false,
                isEdit: false,
                activeTab: 0,
                search: {
                    val: ''
                },
                isPgr: false,
                currentRazdel: '',
            },
            isMounted: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
            if (e.key === 'ctrlS') this.clickSave();
            if (e.key === 'exportAutocad') this.onExportAutocad();
            if (e.key === 'exportAutocadWs') this.pageT.clickAutocadWS('/export_autocadWs');
        });
        Bus.$on('navbar_info', obj => this.nbi = obj);
        Bus.$on('removeRow', obj => this.onRemoveRow(obj));
        Bus.$on('addRow', () => this.onAddRow());
        Bus.$on('removeSection', (arr) => this.onRemoveSection(arr));
    },
    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {save: true, export: true, reload: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.pageT.loadCookie();
        this.clickRazdel(this.state.currentRazdel);
        this.isMounted = true;
    },
    methods: {
        onRemoveSection(arr) {
            this.pageT.clickSave('/panel/remove_section', {eSection: arr}, '_remove_section');
            Bus.$once('zResponse_remove_section', response => {
                this.clickSave();
            });
        },
        clickRazdel(razdel) {
            this.state.currentRazdel = razdel;
            this.pageT.clickSave('/panel/get_data', {razdel: this.state.currentRazdel}, '_get_data', false);
            Bus.$once('zResponse_get_data', response => {
                this.local.eFull.length = 0;
                response.eFull.forEach(item => this.local.eFull.push(item));
                this.local.ePotr.length = 0;
                response.ePotr.forEach(item => this.local.ePotr.push(item));
                this.local.ePanel.length = 0;
                response.ePanel.forEach(item => this.local.ePanel.push(item));
                Bus.$emit('updTable');
                Bus.$emit('updSelectArr');
            });
        },
        clickDot(type) {
            if (type === 'select') Bus.$emit('dotSelect')
            else if (type === 'un_select') Bus.$emit('dotUnSelect')
            else if (type === 'inverse') Bus.$emit('dotInverse')
        },
        clickTab(i) {
            Bus.$emit('localSave');
            this.state.activeTab = i;
        },
        clickSave() {
            Bus.$emit('localSave');
            this.pageT.clickSave('/panel/save', {eFull: this.local.eFull, razdel: this.state.currentRazdel}, '_save');
            Bus.$once('zResponse_save', response => {
                this.local.eFull.length = 0;
                response.eFull.forEach(item => this.local.eFull.push(item));
                this.local.ePotr.length = 0;
                response.ePotr.forEach(item => this.local.ePotr.push(item));
                this.local.ePanel.length = 0;
                response.ePanel.forEach(item => this.local.ePanel.push(item));
                Bus.$emit('updTable');
                Bus.$emit('updSelectArr');
            });
        },
        onExportAutocad() {
            document.location.href = '/pages/' + this.nbi.projectInfo.id + '/export_autocad';
        },
    },
    computed: {
    },
    watch: {
        'state': {
            handler: function (newVal, oldVal) {
                this.pageT.updCookie();
                // Bus.$off('keyUp');
            },
            deep: true
        },
    }

}
</script>


<style scoped lang="scss">
@import 'resources/sass/variables';

.nav-link {
    cursor: pointer;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
