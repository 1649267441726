var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand-sm navbar-light navbar-laravel fixed-top",
    },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("ul", { staticClass: "navbar-nav" }, [
          _vm.isAuth
            ? _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: { "data-toggle": "dropdown", href: "#" },
                  },
                  [_vm._v("содержание")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu",
                    staticStyle: { "overflow-y": "auto", height: "40rem" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item font-weight-bold",
                        attrs: { href: "/pages/" + _vm.projectInfo.id },
                      },
                      [_vm._v("Содержание")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.local.pageMenu, function (val) {
                      return [
                        _c(
                          "a",
                          {
                            key: val.id,
                            staticClass: "dropdown-item",
                            class: { disabled: val.link === "/tp" },
                            attrs: {
                              href:
                                val.link +
                                "/" +
                                _vm.authUser.prop.currentProjectId +
                                "/" +
                                val.id,
                            },
                          },
                          [_vm._v(_vm._s(val.name))]
                        ),
                        _vm._v(" "),
                        _vm._l(val.child, function (val2) {
                          return [
                            _c(
                              "a",
                              {
                                key: val2.id,
                                staticClass: "dropdown-item",
                                staticStyle: { "padding-left": "2.3rem" },
                                attrs: {
                                  href:
                                    val2.link +
                                    "/" +
                                    _vm.authUser.prop.currentProjectId +
                                    "/" +
                                    val2.id,
                                },
                              },
                              [_vm._v(_vm._s(val2.name))]
                            ),
                          ]
                        }),
                      ]
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item font-weight-bold",
                        attrs: {
                          href:
                            "/pages/" +
                            _vm.authUser.prop.currentProjectId +
                            "/get_docx",
                        },
                      },
                      [_vm._v("скачать текст")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item font-weight-bold",
                        attrs: {
                          href:
                            "/pages/" +
                            _vm.authUser.prop.currentProjectId +
                            "/get_zip",
                        },
                      },
                      [_vm._v("скачать ZIP")]
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuth
            ? _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: { "data-toggle": "dropdown", href: "#" },
                  },
                  [_vm._v("база")]
                ),
                _vm._v(" "),
                _vm._m(1),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuth
            ? _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: { "data-toggle": "dropdown", href: "#" },
                  },
                  [_vm._v("расчёт")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-menu" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: {
                        href:
                          "/global_variables/" +
                          _vm.authUser.prop.currentProjectId,
                      },
                    },
                    [_vm._v("глобальные переменные")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "/calc/kz" },
                    },
                    [_vm._v("ток КЗ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "/calc/zaz" },
                    },
                    [_vm._v("расчёт сопротивления заземления")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "/calc/term_cab" },
                    },
                    [_vm._v("проверка кабеля")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuth
            ? _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: { "data-toggle": "dropdown", href: "#" },
                  },
                  [_vm._v("электрика")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-menu" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: {
                        href: "/room/" + _vm.authUser.prop.currentProjectId,
                      },
                    },
                    [_vm._v("помещения и зоны")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: {
                        href: "/potr/" + _vm.authUser.prop.currentProjectId,
                      },
                    },
                    [_vm._v("потребители")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: {
                        href: "/panel/" + _vm.authUser.prop.currentProjectId,
                      },
                    },
                    [_vm._v("щиты")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "/panel_re_calc_all" },
                    },
                    [_vm._v("пересчитать")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuth
            ? _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: { "data-toggle": "dropdown", href: "#" },
                  },
                  [_vm._v("проект")]
                ),
                _vm._v(" "),
                _vm._m(2),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("li", { staticClass: "ml-5" }),
          _vm._v(" "),
          _vm.onShow.reload
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _vm.isLoading
                    ? _c("font-awesome-icon", {
                        staticClass: "mt-1",
                        attrs: {
                          icon: "fa-solid fa-circle-notch",
                          size: "2xl",
                          title: "обновить страницу",
                          spin: "",
                        },
                        on: { click: _vm.clickReload },
                      })
                    : _c("font-awesome-icon", {
                        staticClass: "mt-1",
                        attrs: {
                          icon: "fa-solid fa-rotate",
                          size: "2xl",
                          title: "обновить страницу",
                        },
                        on: { click: _vm.clickReload },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.reload_calc
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _vm.isLoading
                    ? _c("font-awesome-icon", {
                        staticClass: "mt-1",
                        attrs: {
                          icon: "fa-solid fa-circle-notch",
                          size: "2xl",
                          title: "обновить страницу",
                          spin: "",
                        },
                        on: { click: _vm.clickReload },
                      })
                    : _c("font-awesome-icon", {
                        staticClass: "mt-1",
                        attrs: {
                          icon: "fa-solid fa-rotate",
                          size: "2xl",
                          title: "обновить страницу",
                        },
                        on: { click: _vm.clickReloadCalc },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.save
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    class: "animate__animated animate__tada animate__repeat-2",
                    attrs: {
                      icon: "fa-solid fa-floppy-disk",
                      size: "2xl",
                      title: "сохранить изменения",
                    },
                    on: { click: _vm.clickSave },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.calc
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    class: "animate__animated animate__tada animate__repeat-2",
                    attrs: {
                      icon: "fa-solid fa-calculator",
                      size: "2xl",
                      title: "сохранить изменения",
                    },
                    on: { click: _vm.clickCalc },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.insert
            ? _c("li", { staticClass: "ml-2" }, [
                _c("i", {
                  staticClass: "fas fa-plus fa-2x fa-fw",
                  staticStyle: { opacity: "1" },
                  attrs: { title: "вставить элемент" },
                  on: { click: _vm.clickAdd },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.excel
            ? _c("li", { staticClass: "ml-2" }, [
                _c("i", {
                  staticClass: "fas fa-file-excel fa-2x fa-fw",
                  staticStyle: { opacity: "1" },
                  attrs: { title: "экспорт в excel'" },
                  on: { click: _vm.clickExcel },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.word
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    attrs: {
                      icon: "fa-solid fa-file-word",
                      size: "2xl",
                      title: "экспорт в word",
                    },
                    on: { click: _vm.clickWord },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.import
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    attrs: {
                      icon: "fa-solid fa-angles-down",
                      size: "2xl",
                      title: "импорт из другого проекта",
                    },
                    on: { click: _vm.clickImport },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.download
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    attrs: {
                      icon: "fa-solid fa-file-arrow-down",
                      size: "2xl",
                      title: "закачать документ",
                    },
                    on: { click: _vm.clickDownload },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.downloadZip
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    attrs: {
                      icon: "fa-solid fa-file-zipper",
                      size: "2xl",
                      title: "скачать комплект документов",
                    },
                    on: { click: _vm.clickDownloadZip },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.export
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    staticStyle: { color: "green" },
                    attrs: {
                      icon: "fa-solid fa-cloud-arrow-up",
                      size: "2xl",
                      title: "экспорт в Autocad WS",
                    },
                    on: { click: _vm.clickExportWs },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.fileImport
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    attrs: {
                      icon: "fa-solid fa-file-import",
                      size: "2xl",
                      title: "импорт информации из файла",
                    },
                    on: { click: _vm.clickFileImport },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.clearCookie
            ? _c(
                "li",
                { staticClass: "ml-2" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mt-1",
                    staticStyle: { color: "goldenrod" },
                    attrs: {
                      icon: "fa-solid fa-cookie-bite",
                      size: "2xl",
                      title: "удаление cookie для этой страницы",
                    },
                    on: { click: _vm.clickClearCookie },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.onShow.setting
            ? _c("li", { staticClass: "ml-2" }, [
                _c("i", {
                  staticClass: "fas fa-cogs fa-2x fa-fw",
                  attrs: { title: "настройки" },
                  on: { click: _vm.clickSetting },
                }),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.statusRow.desc && _vm.isGetStatus
          ? _c("div", { staticClass: "ml-5 w-25" }, [
              _c("div", { staticClass: "progress" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "progress-bar progress-bar-striped progress-bar-animated",
                    style: { width: _vm.statusRow.percent + "%" },
                  },
                  [_vm._v(_vm._s(_vm.statusRow.desc))]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("ul", { staticClass: "navbar-nav ml-auto right navbar-right" }, [
          _vm.isAuth
            ? _c(
                "li",
                [
                  _vm.authUser.prop.currentProjectId !==
                  _vm.authUser.prop.loadProjectId
                    ? _c("font-awesome-icon", {
                        staticClass:
                          "mt-2 text-danger animate__animated animate__tada animate__repeat-2",
                        staticStyle: { opacity: "1" },
                        attrs: { icon: "fa-solid fa-exclamation", size: "xl" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuth
            ? _c("li", { staticClass: "mr-3 nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: {
                      id: "navbarDropdown2",
                      href: "#",
                      "data-toggle": "dropdown",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.local.projectInfo.name)
                    ),
                    _c("span", { staticClass: "caret" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dropdown-menu dropdown-menu-right" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          href:
                            "/project/" +
                            _vm.authUser.prop.currentProjectId +
                            "/show",
                        },
                      },
                      [_vm._v("информация")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          href:
                            "/project/" +
                            _vm.authUser.prop.loadProjectId +
                            "/set_current",
                        },
                      },
                      [_vm._v(_vm._s(_vm.local.projectInfo.loadName))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "/project/project_matching" },
                      },
                      [_vm._v("сопоставить с другим")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAuth
            ? _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  { staticClass: "nav-link", attrs: { href: "/login" } },
                  [_vm._v("вход")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuth
            ? _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: {
                      id: "navbarDropdown",
                      href: "#",
                      "data-toggle": "dropdown",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " + _vm._s(_vm.authUser.name)
                    ),
                    _c("span", { staticClass: "caret" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu dropdown-menu-right",
                    attrs: { "aria-labelledby": "navbarDropdown" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "/profile" },
                      },
                      [_vm._v("профиль")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "/logout" },
                      },
                      [_vm._v("выход")]
                    ),
                    _vm._v(" "),
                    _vm.isAdmin
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "/admin/sync_all_projects" },
                            },
                            [_vm._v("синхронизировать")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("zapros"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "navbar-brand font-weight-bold", attrs: { href: "/" } },
      [
        _c("span", { staticStyle: { color: "green" } }, [_vm._v("S")]),
        _vm._v("peka"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-menu" }, [
      _c("a", { staticClass: "dropdown-item", attrs: { href: "/baseps" } }, [
        _vm._v("база партнёров"),
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "dropdown-item", attrs: { href: "/base" } }, [
        _vm._v("база оборудования"),
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "dropdown-item", attrs: { href: "/category" } }, [
        _vm._v("категории"),
      ]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "dropdown-item", attrs: { href: "/marka_element" } },
        [_vm._v("марки оборудования")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "dropdown-item", attrs: { href: "/specification" } },
        [_vm._v("спецификация")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "dropdown-item", attrs: { href: "/cj" } }, [
        _vm._v("КЖ"),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: { href: "/specification/clear_cache" },
        },
        [_vm._v("очистить кэш")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-menu" }, [
      _c("a", { staticClass: "dropdown-item", attrs: { href: "/project" } }, [
        _vm._v("проекты"),
      ]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "dropdown-item", attrs: { href: "/project/start_ws" } },
        [_vm._v("startWS")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }