<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="mb-0">{{ local.project.name }}
                                <font-awesome-icon icon="fa-solid fa-list" title="содержание" style="cursor: pointer"
                                                   @click="clickGoPages"/>
                            </h1>
                        </div>
                    </div>

                    <ul class="nav nav-tabs mt-4">
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(0)" :class="{active:state.activeTab===0}">общая информация</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(1)" :class="{active:state.activeTab===1}">участники</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(2)" :class="{active:state.activeTab===2}">bakup</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(3)" :class="{active:state.activeTab===3}">финансы</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(4)" :class="{active:state.activeTab===4}">файлы</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane container" :class="{active:state.activeTab===0}">
                            <div class="row mt-3">
                                <div class="col-3">
                                    <input class="form-control" v-model="local.ps_info.number"
                                           placeholder="шифр проекта">
                                </div>
                                <div class="col-6">
                                    <input class="form-control" v-model="local.project.name" placeholder="название">
                                </div>
                                <div class="col-1">
                                    <multiselect
                                        v-model="local.ps_info.stage"
                                        :options="listStage"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :allow-empty="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-2">
                                    <multiselect
                                        v-model="local.project.status"
                                        :options="listStatus.map(item => item.id)"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :custom-label="opt => toolM.labelSearch_1(listStatus,opt)"
                                        :allow-empty="false"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="mb-0">
                                        <span class="small">объект проектирования</span>
                                    </label>
                                    <input class="form-control" v-model.trim="local.ps_info.address"
                                           placeholder="объект проектирования"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1">
                                    <input class="form-control" v-model.trim="local.ps_info.section.number"
                                           placeholder="номер"/>
                                </div>
                                <div class="col-5">
                                    <input class="form-control" v-model.trim="local.ps_info.section.name"
                                           placeholder="раздел"/>
                                </div>
                                <div class="col-1">
                                    <input class="form-control" v-model.trim="local.ps_info.sub_section.number"
                                           placeholder="номер"/>
                                </div>
                                <div class="col-5">
                                    <input class="form-control" v-model.trim="local.ps_info.sub_section.name"
                                           placeholder="подраздел"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1">
                                    <input class="form-control" v-model.trim="local.ps_info.part.number"
                                           placeholder="номер"/>
                                </div>
                                <div class="col-5">
                                    <input class="form-control" v-model.trim="local.ps_info.part.name"
                                           placeholder="часть"/>
                                </div>
                                <div class="col-1">
                                    <input class="form-control" v-model.trim="local.ps_info.book.number"
                                           placeholder="номер"/>
                                </div>
                                <div class="col-5">
                                    <input class="form-control" v-model.trim="local.ps_info.book.name"
                                           placeholder="книга"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label class="mb-0">
                                        <span class="small">название проекта</span>
                                    </label>
                                    <input class="form-control" v-model.trim="nameProject" disabled/>
                                </div>
                                <div class="col-3">
                                    <label class="mb-0">
                                        <span class="small">начало</span>
                                    </label>
                                    <datepicker v-model="local.ps_info.start_date" :language="ru"
                                                :format="toolM.customFormatter" inputClass="form-control"></datepicker>
                                </div>
                                <div class="col-3">
                                    <label class="mb-0">
                                        <span class="small">окончание</span>
                                    </label>
                                    <datepicker v-model="local.ps_info.stop_date" :language="ru"
                                                :format="toolM.customFormatter" inputClass="form-control"></datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex justify-content-between">
                                    <!--//todo-code-vue multiselect obj-->
                                    <multiselect
                                        v-model="local.ps_info.organization"
                                        :options="local.listOrganization.map(item => {return {id:item.id, name: item.name}})"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :custom-label="opt => toolM.labelSearch_2(local.listOrganization,opt)"
                                        :allow-empty="true"
                                        placeholder="организация"
                                        tag-placeholder="создать новую"
                                        @tag="addNewOrg"
                                        :taggable="true"
                                    >
                                        <template v-slot="beforeList">
                                            <button class="form-control btn btn-sm btn-outline-primary p-0"
                                                    @click="clickResetOrg">очистить
                                            </button>
                                        </template>
                                    </multiselect>
                                    <font-awesome-icon icon="fa-solid fa-list" size="lg" class="mt-2 ml-2 mr-2"
                                                       data-toggle="modal" data-target="#myModal"/>
                                </div>
                                <div class="col-6 d-flex justify-content-between">
                                    <multiselect
                                        v-model="local.project.tags"
                                        placeholder="теги"
                                        label="name"
                                        track-by="name"
                                        :options="local.listTag"
                                        :multiple="true"
                                        :taggable="true"
                                        tag-placeholder="создать новый"
                                        @tag="addTag"
                                    ></multiselect>
                                    <font-awesome-icon icon="fa-solid fa-list" size="lg" class="mt-2 ml-2 mr-2" @click="showModalListTag"/>
                                </div>
                                <!--                                <div class="col-2">-->
                                <!--                                    <button class="btn form-control btn-primary" data-toggle="modal" data-target="#myModal">организации</button>-->
                                <!--                                </div>-->
                            </div>
                            <div class="row">
                                <c-collapse-block name="комментарий" v-model="local.ps_info.comm" :is-edit="true"
                                                  class="w-100" :expand="local.ps_info.comm!==''"/>
                            </div>
                            <div class="row">
                                <button type="button" class="btn btn-primary" @click="clickClone">клонировать проект</button>
                            </div>
                        </div>
                        <div class="tab-pane container" :class="{active:state.activeTab===1}">
                            <fio-stamp :project-info="local.ps_info" :list-fio="local.listFio" :nbi="nbi"/>
                        </div>
                        <div class="tab-pane container" :class="{active:state.activeTab===2}">
                            <div class="row mt-3">
                                <div v-for="el in listBackup" class="col-12">
                                    <button v-if="el.name!=='upd'" class="form-control btn btn-primary" @click="clickBackup(el)">{{ el.name }} {{ el.size }}кБ</button>
                                    <button v-if="el.name==='upd'" class="form-control btn btn-primary" @click="clickBackup(el)">последняя загрузка {{ el.size }}кБ</button>
                                </div>
                            </div>
                        </div>

                        <!--                        <div v-if="state.activeTab===0" class="card" style="border-top: none;">-->
                        <!--                            <e-potr0 v-if="isMounted" :p-local="local" :p-state="state"/>-->
                        <!--                        </div>-->

                        <!--                        <div class="tab-pane container" :class="{active:state.activeTab===3}">-->
                        <div v-if="state.activeTab===3" class="card" style="border-top: none;">
                            <finance v-if="isMounted" :p-local="local" :p-state="state"/>
                        </div>

                        <div class="tab-pane container" :class="{active:state.activeTab===4}">
                            <div class="row mt-3">
                                <div class="col-6 d-flex justify-content-between">
                                    <input class="form-control mb-2" v-model.trim="addUser" ref="search">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-org-logo :list-organization="local.listOrganization" modal-id="myModal"/>
        <modal-fio-sign :list-fio="local.listFio" modal-id="myModalFio"/>
        <!--        <modal-attach-edit modal-id="modalAttach"/>-->
        <modal-list-tag :list-tag="local.listTag"/>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import nestedList from "./sub/nestedList";
import CCollapseBlock from "./sub/CCollapseBlock";
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import {ru} from 'vuejs-datepicker/dist/locale'
import ModalFioSign from "./sub/ModalFioSign";
import ModalOrgLogo from "./sub/ModalOrgLogo";
import FioStamp from "./sub/FioStamp";
import ModalAttachEdit from "./sub/ModalAttachEdit";
import {page, toolM} from "./sub/tools";
import ModalListFioA from "./sub/ModalListFioA";
import ModalListTag from "./sub/ModalListTag";
import ModalFinance from "./sub/ModalFinance.vue";
import Finance from "./sub/Finance.vue";

export default {
    name: "page-project-show",
    components: {
        Finance,
        ModalFinance,
        ModalOrgLogo,
        Multiselect,
        nestedList,
        CCollapseBlock,
        Datepicker,
        ModalFioSign,
        ModalAttachEdit,
        ModalListFioA,
        ModalListTag
    },
    props: ['project', 'listOrganization', 'listFio', 'psInfo', 'attach', 'listTag', 'finance', 'listBackup'],
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            ru: ru,
            search: '',
            listStage: ['П', 'Р', 'ИД'],
            listStatus: [
                {id: 0, name: 'закрытый'},
                {id: 1, name: 'открытый'},
            ],
            old: {
                username: 'qwe',
                pass: 'qwe',
            },
            pass: '',
            nbi: {
                projectInfo: {},
                authUser: {
                    prop: {
                        setting: {
                            listTag: [],
                            listFioA: [],
                        }
                    }
                }
            },
            errors: {
                pass: [''],
            },
            status: {
                reset: false,
                save: false,
                loading: false,
                id_edit_finance: null,
            },
            local: {
                project: this.project,
                listOrganization: this.listOrganization,
                listFio: this.listFio,
                listTag: this.listTag,
                ps_info: this.psInfo,
                attach: this.attach,
                finance: this.finance,
            },
            state: {
                version: 5,
                activeTab: 0,
            },
            lockCalc: false,
            isMounted: false,
            addUser:'',

        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlS') this.clickSave();
            if (e.key === 'exportAutocadWs') this.pageT.clickAutocadWS('/export_autocadWs');
        });
        Bus.$on('navbar_info', obj => this.nbi = obj);

        Bus.$on('updListFio', obj => this.local.listFio = obj);
        Bus.$on('updListOrganization', (obj) => this.local.listOrganization = obj);
        Bus.$on('updListFioA', obj => this.nbi.authUser.prop.setting.listFioA = obj);
        Bus.$on('updListTag', obj => this.local.listTag = obj);
        Bus.$on('updTagsProject', obj => this.local.project.tags = obj);
        Bus.$on('updTableFinance', obj => this.local.finance = obj);
        Bus.$on('changeTiptapContent', obj => {
            this.local.ps_info.comm = obj;
        });
    },
    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {save: true, export: true, reload: true,}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.pageT.loadCookie();
        this.isMounted = true;
    },
    methods: {
        clickClone() {
            Bus.$emit('zPost', {
                url: '/project/clone',
                obj: {id: this.nbi.projectInfo.id},
                flash: true,
                suffix: '_clone',
            });
            Bus.$once('zResponse_clone', response => {
                document.location.href = '/project/' + response.id + '/show';
            });
        },
        clickTab(i) {
            this.state.activeTab = i;
        },
        clickResetOrg() {
            this.local.ps_info.organization.id = null;
            this.local.ps_info.organization.name = null;
        },
        clickGoPages() {
            document.location.href = '/pages/' + this.nbi.projectInfo.id;
        },
        showModalListTag() {
            Bus.$emit('show_modalListTag');
        },
        updAttach(obj) {
            if (!obj) return false;
            if (!this.local.attach) return false;
            this.local.attach = obj;
        },
        addNewOrg(name, index) {
            Bus.$emit('zPost', {
                url: '/project/org/add',
                obj: {name: name},
                flash: true,
                suffix: '_add_org',
            });
            Bus.$once('zResponse_add_org', response => {
                this.local.listOrganization = response.list;
                this.local.ps_info.organization.id = response.id;
                Bus.$emit('updListOrganization', this.local.listOrganization);
            });
        },
        clickSave() {
            this.toolM.setLoading(true);
            Bus.$emit('zPost', {
                url: this.nbi.url.current + '/save',
                obj: {project: this.local.project, psInfo: this.local.ps_info},
                flash: true,
                suffix: '_show_save',
                // messOk: 'сохранено',
                // messError: 'ошибка привязки аккаунта',
            });
            Bus.$once('zResponse_show_save', () => {
                this.toolM.setLoading(false);
            });
            Bus.$once('zError_show_save', () => {
                this.toolM.setLoading(false);
            });
        },
        addTag(newTag) {
            Bus.$emit('zPost', {
                url: '/project/tag/add',
                obj: {name: newTag, color: '#ffae07'},
                flash: true,
                suffix: '_add_tag_modal',
            });
            Bus.$once('zResponse_add_tag_modal', response => {
                this.local.listTag.push(response.tag);
                this.local.project.tags.push(response.tag);
            });
        },
        clickBackup(el) {
            this.pageT.clickSave('/project/backup_restore', {name: el.name}, '_backup_restore')
        },
    },
    computed: {
        nameProject: function () {
            let t = '';
            let info = this.local.ps_info;
            if (info.book.name) t = 'book';
            else if (info.part.name) t = 'part';
            else if (info.sub_section.name) t = 'sub_section';
            else if (info.section.name) t = 'section';

            if (t === '') {
                info.name_project = '';
            } else {
                let num = info[t].number;
                let str = '';
                if (num) {
                    if (t === 'book') str = 'Книга ';
                    else if (t === 'part') str = 'Часть ';
                    else if (t === 'section') str = 'Раздел ';
                    else if (t === 'sub_section') str = 'Подраздел ';
                    str += num + '. ';
                }
                info.name_project = '';
                if (info[t] !== undefined) info.name_project = str + info[t].name;
            }
            info.name_project_type = t;

            return info.name_project;
        },
    },
    watch: {
        'local.project': {
            handler: function (newVal, oldVal) {
                Bus.$emit('navbar', {command: 'showButtons', args: {save: true}});
            },
            deep: true
        },
        'local.ps_info': {
            handler: function (newVal, oldVal) {
                Bus.$emit('navbar', {command: 'showButtons', args: {save: true}});
            },
            deep: true
        },
        'state': {
            handler: function (newVal, oldVal) {
                this.pageT.updCookie();
            },
            deep: true
        },
    },
    filters: {},
    directives: {}
}
</script>

<style lang="scss" scoped>

svg.fa-list {
    cursor: pointer;
}

.nav-link {
    cursor: pointer;
}

.fas.fa-check {
    opacity: 0.3;


    &:hover {
        cursor: pointer;
    }

    &.active {
        opacity: 1;
        color: green;
    }
}
</style>
