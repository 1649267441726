<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">
                    <div class="mb-3">
                        <h1 class="mb-0">Профиль</h1>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label class="mb-0">
                                <span class="small">name</span>
                            </label>
                            <input class="form-control" v-model="profile.name" disabled/>

                            <label class="mb-0 mt-3">
                                <span class="small">email</span>
                            </label>
                            <input class="form-control" v-model="profile.email" disabled/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <label class="mb-0">
                                <span class="small">новый пароль</span>
                            </label>
                            <input class="form-control" v-model.trim="pass" type="password" placeholder="6-20 символов"/>
                            <label class="mb-0">
                                <span v-if="errors.pass[0]" class="small text-danger">{{errors.pass[0]}}</span>
                            </label>
                            <div class="text-right">
                                <button class="btn btn-primary" @click="clickSavePass" :disabled="!checkPassLen()">сохранить пароль</button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <button class="btn btn-secondary w-100" @click="clickResetPass">сброс пароля</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <label class="mb-0">
                                <span class="small">токен для приложения</span>
                            </label>
                            <textarea class="form-control mb-3" v-model="profile.app_token" disabled/>
                            <div class="text-right">
                                <button class="btn btn-primary" @click="clickGenToken">генерировать</button>
                                <button class="btn btn-primary" @click="clickSaveToken">сохранить</button>
                            </div>
                        </div>
                    </div>

                    <div id="myModal" class="modal fade" role="dialog">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    myspeka.ru
                                </div>
                                <div class="modal-body">
                                    <p>Подключение существующего аккаунта myspeka.ru</p>
                                    <label class="mb-0">
                                        <span class="small">username</span>
                                    </label>
                                    <input class="form-control" v-model.trim="old.username"/>
                                    <label class="mb-0">
                                        <span class="small">пароль</span>
                                    </label>
                                    <input class="form-control" v-model.trim="old.pass" type="password"/>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="clickOldConnect">привязать</button>
                                    <button type="button" class="btn btn-default" data-dismiss="modal">отмена</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import nestedList from "./sub/nestedList";
    import {page} from "./sub/tools";

    export default {
        name: "page-profile",
        components: {Multiselect, nestedList},
        props: ['prop', 'profile'],
        data: function () {
            return {
                pageT: new page(this),
                old: {
                    username: '',
                    pass: '',
                },
                pass: '',
                nbi: {
                    projectInfo: {},
                },
                errors: {
                    pass: [''],
                },
                status: {
                    reset: false,
                    save: false,
                    loading: false,
                },
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'get_navbar_info'});
        },
        methods: {
            clickGenToken() {
                this.profile.app_token = this.generateToken(50);
            },
            generateToken(length) {
                let result = '';
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                const charactersLength = characters.length;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            clickOldConnect() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/profile/old_account',
                    obj: {old: this.old},
                    flash: true,
                    suffix: '_old_account',
                    messOk: 'аккаунт myspeka.ru привязан',
                    messError: 'ошибка привязки аккаунта',
                });
                Bus.$once('zResponse_old_account', () => {
                    document.location.reload();
                });
                Bus.$once('zError_old_account', () => {
                    this.setLoading(false);
                });
            },
            clickResetPass() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/reset_password_send',
                    obj: {email: this.profile.email},
                    flash: true,
                    suffix: '_reset_pass',
                    messOk: 'новый пароль отправлен на почту',
                    messError: 'ошибка изменения пароля',
                });
                Bus.$once('zResponse_reset_pass', () => {
                    this.setLoading(false);
                });
                Bus.$once('zError_reset_pass', response => {
                    this.errors.pass = response.error.pass;
                    this.setLoading(false);
                });
            },
            clickSavePass() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/profile/change_pass',
                    obj: {pass: this.pass},
                    flash: true,
                    suffix: '_change_pass',
                    messOk: 'пароль изменён',
                    messError: 'ошибка изменения пароля',
                });
                Bus.$once('zResponse_change_pass', () => {
                    this.errors.pass = [];
                    this.setLoading(false);
                });
                Bus.$once('zError_change_pass', response => {
                    this.errors.pass = response.error.pass;
                    this.setLoading(false);
                });
            },
            clickSaveToken() {
                Bus.$emit('localSave');
                this.pageT.clickSave('/profile/change_token', {token: this.profile.app_token}, '_change_token');
                Bus.$once('zResponse_change_token', response => {
                    this.toolM.setLoading(false);
                });
            },
            checkPassLen() {
                return this.pass.length >= 6;
            },
            setLoading(state) {
                this.status.loading = state;
                Bus.$emit('navbar', {command: 'setLoading', args: state});
            },
        },
        computed: {},
        watch: {},
        filters: {},
        directives: {}
    }
</script>
