<template>
    <div :id="modalId" class="h-100 ddiv" role="dialog">
        <div class="card h-100">
            <div class="card-body">

                <div class="d-flex justify-content-between">
                    <div class="w-100 mr-2" style="position: relative">
                        <input class="form-control w-100" v-model="search" ref="v_focus">
                        <font-awesome-icon icon="fa-solid fa-eraser" size="lg" @click="search=''" title="очистить"/>
                    </div>
                </div>

                <div v-if="this.local.table.length === 0" class="text-center p-5">
                    <font-awesome-icon v-if="true" icon="fa-solid fa-circle-notch" size="3x" class="mt-1 text-secondary" title="загрузка данных..." spin/>
                </div>

                <div v-for="(val,index) in filteredTable" :key="'a_'+index" class="card my-1 myRow">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center">
                            <span v-if="!!currentObj">
                                <font-awesome-icon v-if="withHtml" icon="fa-solid fa-arrow-left" class="mr-2" style="cursor: pointer" @click="clickInsertBlockHTML(val)"/>
                                <font-awesome-icon v-else icon="fa-solid fa-arrow-left" class="mr-2" style="cursor: pointer" @click="clickInsertBlock(val)"/>
                            </span>
                            <div class="font-weight-bold">{{val.name}}</div>
                        </div>
                        <div class="small">{{val.id}}</div>
                        <div v-html="val.value"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import {page} from "./tools";


    export default {
        name: 'import_fields',
        components: {Multiselect},
        props: {
            modalId: {type: String, default: 'importDiv',},
            isShow: {type: Boolean, default: false,},
            withHtml: {type: Boolean, default: false},
            cPage: {type: Object, required: false,},
        },
        data: function () {
            return {
                pageT: new page(this),
                rand: 1,
                local: {
                    table: [],
                },
                state: {},
                status: {
                    loading: false,
                },
                search: '',
                search2: [],
                listFoldPage: ['/f/table_trn', '/f/table_od', '/f/table_vrch', '/f/table_pps', '/f/table_vspd', '/f/table_so'],
                currentObj: null,
            }
        },
        created: function () {
            // Bus.$on('show_' + this.modalId, () => this.showModal());
            Bus.$on('syncSearchA', (obj) => this.search = obj.search);
            Bus.$on('updCurrentId', obj => this.currentObj = obj);
        },
        mounted() {
            if (this.isShow) this.showModal();
        },
        methods: {
            clickInsertBlockHTML(obj) {
                console.log(obj);
                this.loadHtml(JSON.parse(JSON.stringify(obj)));
            },
            loadHtml(obj) {
                this.pageT.clickSave('/global_variables/load_html', {obj: obj}, '_load_html', false)
                Bus.$once('zResponse_load_html', response => {
                    if (!response.html) return;
                    Bus.$emit('insertTiptapBlock', response.html);
                    obj.id=response.id;
                    Bus.$emit('addFieldInPage', obj);
                });
            },
            clickInsertBlock(obj) {
                Bus.$emit('insertBlock', JSON.parse(JSON.stringify(obj)));
            },
            showModal() {
                // $('#' + this.modalId).modal('show');
                setTimeout(() => this.$refs.v_focus.focus(), 500);
                if (this.local.table.length === 0) this.load();
            },
            load() {
                let url = '/global_variables/load_list_fields';
                if (this.withHtml) url = '/global_variables/load_list_fields_for_page';
                this.pageT.clickSave(url, {cPage: this.cPage}, '_load', false)
                Bus.$once('zResponse_load', response => {
                    this.local.table = response.table;
                    import("../../../sass/_rtl.css")
                });
            },
        },
        computed: {
            filteredTable: function () {
                return this.local.table.filter(item => {
                    let res = true;
                    this.search.split(' ').forEach(value => {
                        let s = item.name + item.value;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                });
            },
        },
        watch: {
            isShow(newVal, oldVal) {
                this.showModal();
            }
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }

    .myRow {
        &:hover {
            background-color: lighten($primary, 50%);
        }
    }
</style>
