<template>
    <div v-if="state.swOnOpt" class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-6">
                    <label class="w-100 mt-2">
                        <span class="small">количество листов в файле</span>
                        <input class="form-control" v-model.number="cPage.options.count_page" placeholder="количество листов в файле" v-focus/>
                    </label>
                    <label class="w-100 mt-2">
                        <span class="small">название листа</span>
                        <small class="btn-link d-inline-block ml-2" @click="cPage.stamp.list_name=cPage.link_content.name" style="cursor: pointer">auto</small>
                        <input class="form-control" v-model="cPage.stamp.list_name" placeholder="название листа"/>
                    </label>
                    <label class="w-100 mt-2">
                        <span class="small">имя файла при экспорте</span>
                        <small class="btn-link d-inline-block ml-2" @click="cPage.options.file_name='_'+cPage.link_content.name" style="cursor: pointer">auto</small>
                        <input class="form-control" v-model="cPage.options.file_name" placeholder="имя файла при экспорте"/>
                    </label>
                    <label class="apple-switch_2 mt-3"><input type="checkbox" v-model="cPage.options.isOd"><i></i>учитывать в содержании</label>
                    <label class="w-100 mt-2">
                        <label class="apple-switch_2 mt-3"><input type="checkbox" v-model="cPage.stamp.suffix.auto"><i></i>суффикс автоматически</label>
                        <input class="form-control" v-model.trim="cPage.stamp.suffix.value" :disabled="cPage.stamp.suffix.auto"/>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'card-options',
    props: {
        cPage: {},
        state: {},
    },
    methods: {
        clickHeader() {
            this.state.swOnOpt = false;
        }
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    },
}
</script>
