<template>
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal">
        <div class="modal-dialog" @click.stop="">
            <div class="modal-content" style="width: 67rem">
                <div class="modal-header">
                    поля
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div class="w-100 mr-2" style="position: relative">
                            <input class="form-control w-100" v-model="search" ref="v_focus">
                            <font-awesome-icon icon="fa-solid fa-eraser" size="lg" @click="search=''" title="очистить"/>
                        </div>
                        <div>
                            <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="lg" class="mt-2" style="cursor: pointer" @click="clickSearch"/>
                        </div>
                    </div>

                    <div v-if="this.local.table.length === 0" class="text-center p-5">
                        <font-awesome-icon v-if="true" icon="fa-solid fa-circle-notch" size="3x" class="mt-1 text-secondary" title="загрузка данных..." spin/>
                    </div>
                    <div style="max-height: 40rem; overflow-y: auto; overflow-x: hidden;">
                        <table class="table table-sm">
                            <tr v-for="(val,index) in local.table" :key="index">
                                <td class="text-left">
                                    {{val.value}}<br>
                                    <span class="small">{{val.param.name}}</span>
                                </td>
                                <td style="width: 3rem;" class="text-center">
                                    <font-awesome-icon icon="fa-solid fa-angles-right" @click="clickInsertBlock(val)" title="вставить" size="lg"/>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import {modalDraggable, page} from "./tools";


    export default {
        name: 'modal-search-fields',
        components: {Multiselect},
        props: {
            modalId: {type: String, default: 'modalColorPicker',},
            cPage: {type: Object, default: {},},
        },
        data: function () {
            return {
                pageT: new page(this),
                rand: 1,
                local: {
                    obj: this.cPage,
                    table: [],
                },
                state: {},
                status: {
                    loading: false,
                },
                search: '',
            }
        },
        created: function () {
            Bus.$on('show_' + this.modalId, () => this.showModal());
        },
        mounted() {
            new modalDraggable(this);
        },
        methods: {
            clickInsertBlock(obj) {
                let tt = Date.now();
                //todo js DOMParser
                let parser = new DOMParser();
                let doc = parser.parseFromString(obj.html, 'text/html');
                let el = doc.documentElement.querySelectorAll('span')
                el[0].setAttribute('data-ms-id', tt);
                let res = doc.documentElement.querySelector('body').innerHTML;
                Bus.$emit('insertTiptapBlock', res);
                let o={id: tt, name: obj.id, param: obj.param};
                console.log(o);
                Bus.$emit('addFieldInPage', o);
            },
            clickSearch() {

            },
            showModal() {
                $('#' + this.modalId).modal('show');
                setTimeout(() => this.$refs.v_focus.focus(), 500);
                if (this.local.table.length === 0) this.load();
            },
            closeModal() {
                $('#' + this.modalId).modal('hide');
            },
            clickSave() {
            },
            load() {
                this.pageT.clickSave('/page/load_list_fields', {cPage: this.local.obj}, '_load')
                Bus.$on('zResponse_load', response => {
                    this.local.table = response.table;
                });
            },
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }
</style>
