var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-3" },
          _vm._l(_vm.fSuffix, function (el, index) {
            return index < 2
              ? _c("div", { key: el.type_list, staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-7 mt-2 text-nowrap text-right",
                      staticStyle: { overflow: "hidden" },
                    },
                    [_vm._v(_vm._s(_vm.getName(el.type_list)))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: el.suffix,
                          expression: "el.suffix",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: el.suffix },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(el, "suffix", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e()
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-3" },
          _vm._l(_vm.fSuffix, function (el, index) {
            return index >= 2 && index < 4
              ? _c("div", { key: el.type_list, staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-7 mt-2 text-nowrap text-right",
                      staticStyle: { overflow: "hidden" },
                    },
                    [_vm._v(_vm._s(_vm.getName(el.type_list)))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: el.suffix,
                          expression: "el.suffix",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: el.suffix },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(el, "suffix", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e()
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-3" },
          _vm._l(_vm.fSuffix, function (el, index) {
            return index >= 4 && index < 6
              ? _c("div", { key: el.type_list, staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-7 mt-2 text-nowrap text-right",
                      staticStyle: { overflow: "hidden" },
                    },
                    [_vm._v(_vm._s(_vm.getName(el.type_list)))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: el.suffix,
                          expression: "el.suffix",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: el.suffix },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(el, "suffix", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e()
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-3" },
          _vm._l(_vm.fSuffix, function (el, index) {
            return index >= 6
              ? _c("div", { key: el.type_list, staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-7 mt-2 text-nowrap text-right",
                      staticStyle: { overflow: "hidden" },
                    },
                    [_vm._v(_vm._s(_vm.getName(el.type_list)))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: el.suffix,
                          expression: "el.suffix",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: el.suffix },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(el, "suffix", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e()
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", on: { click: _vm.clickSave } },
        [_vm._v("сохранить")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }