<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount">
        <div class="modal-dialog modal-lg" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span v-if="state.isEdit">редактировать</span>
                    <span v-else>вставить потребитель</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="row mt-0">
                        <div class="col-6 pr-1" @click="lock.name=false">
                            <span class="small">название <font-awesome-icon v-if="lock.name" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.name"
                                ref="ms1"
                                v-model="state.obj.name"
                                placeholder="name"
                                :options="local.listName"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms1','name')"
                                :blockKeys="['Delete']"
                                @tag="addNewItem1"
                                :taggable="true"
                            >
                            </multiselect>
                        </div>
                        <div class="col-6 pr-1" @click="lock.zone=false">
                            <span class="small">зона <font-awesome-icon v-if="lock.zone" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.zone"
                                ref="ms2"
                                v-model="state.obj.zone"
                                placeholder="зона"
                                :options="local.listZones.map(item => {return {id:item.id,name:item.name}})"
                                :custom-label="opt => customLabel(opt)"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                :blockKeys="['Delete']"
                            >
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex flex-row justify-content-end">
                    <div>
                        <!--                        <button v-if="state.isEdit" type="button" class="btn btn-default" @click="clickDelete">удалить</button>-->
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickLocalSave">сохранить</button>
                        <button v-else-if="!!lastSelectObj" type="button" class="btn btn-primary" @click="clickInsert">вставить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    //todo js modal modalDraggable
    import {modalDraggable, page} from "./tools";
    import EInput from "./EInput";


    export default {
        name: 'modal-room',
        components: {EInput, Multiselect},
        props: {
            modalId: {type: String, default: 'modal',},
            pLocal: {type: Object, default: {},},
        },
        data: function () {
            return {
                //todo js modal add page module
                pageT: new page(this),
                rand: 1,
                local: {
                    eRoom: this.pLocal.eRoom,
                    listName: [],
                    listZones: this.pLocal.listZones,
                },
                state: {
                    obj: null,
                    isEdit: true,
                },
                status: {
                    loading: false,
                },
                isImport: false,
                templateObj: {
                    child: [],
                    name: '',
                    zone: {id: '', name: ''},
                    import: {},
                },
                lock: {
                    name: true,
                    zone: true,
                },
                isMount: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'ctrlEnter') this.clickLocalSave();
            });
            Bus.$on('show_' + this.modalId, () => {
                this.state.isEdit = true;
                this.showModal()
            });
            Bus.$on('showI_' + this.modalId, () => {
                this.state.isEdit = false;
                this.showModal();
            });
        },
        mounted() {
            new modalDraggable(this);
            this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
            this.isMount = true;
        },
        methods: {
            customLabel(opt) {
                let o = this.local.listZones.find(item => item.id === opt.id);
                if (o) return o.name; else return '';
            },
            clickInsert() {

            },
            setLock(val) {
                Object.keys(this.lock).forEach(item => this.lock[item] = val);
            },
            showModal() {
                this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
                if (this.lastSelectObj) {
                    this.setLock(false);
                    let child = [];
                    if (this.lastSelectObj.child) {
                        child = JSON.parse(JSON.stringify(this.lastSelectObj.child));
                        this.setObj(this.state.obj, child[0]);
                    } else {
                        this.setObj(this.state.obj, this.lastSelectObj);
                    }

                    this.state.obj.child = child;
                    // this.state.obj.import = this.local.fListImportPotr.find(item => item.name2.toLowerCase() === this.lastSelectObj.name2.toLowerCase());

                    //todo js поиск уникальных значений
                    this.local.listName = this.local.eRoom.map(item => item.name).filter((value, index, self) => self.indexOf(value) === index);

                    let obj = [];
                    if (this.lastSelectObj.child) {
                        obj = this.state.obj.child;
                    } else {
                        obj = this.local.eRoom;
                    }
                    // this.local.listName1 = obj.map(item => item.name1).filter((value, index, self) => self.indexOf(value) === index);
                }
                this.setLock(this.pLocal.selectArr.length > 1);
                $('#' + this.modalId).modal('show');
                for (let i = 0; i < 3; i++) {
                    setTimeout(() => {
                        if (this.$refs.v_focus) this.$refs.v_focus.focus()
                    }, 200);
                }
            },
            clickLocalSave() {
                this.pLocal.selectArr.forEach(item => {
                    if (item.child) {
                        item.child.forEach(item2 => {
                            this.setObj(item2, this.state.obj);
                        })
                    } else {
                        this.setObj(item, this.state.obj);
                    }
                })
                this.closeModal();
            },
            setObj(obj, iObj) {
                if (!this.lock.name) obj.name = iObj.name;
                if (!this.lock.zone) obj.zone.id = iObj.zone.id;
                if (!this.lock.zone) obj.zone.name = iObj.zone.name;
            },
            addNewItem1(newTag) {
                this.state.obj.name = newTag;
                this.local.listName.push(newTag);
            },
            onOpen(r, f) {
                setTimeout(() => {
                    this.$refs[r].$refs.search.value = this.state.obj[f];
                }, 10);
            },
            onChange() {
                if (!this.state.obj) this.state.isEdit = false;
            },
            clickImport(unLock) {
                this.setObj(this.state.obj, this.state.obj.import);
                this.setLock(!unLock);
                this.isImport = false;
            },
            closeModal() {
                this.$emit('updInit');
                $('#' + this.modalId).modal('hide');
            },
        },
        computed: {
            lastSelectObj: function () {
                if (this.pLocal.selectArr.length === 0) return null;
                return this.pLocal.selectArr[this.pLocal.selectArr.length - 1];
            }
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    span.small.tag {
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    svg.fa-lock {
        opacity: 0.2;

        &.right {
            position: absolute;
            right: 14px;
            top: 36px;
        }
    }


    input.lock {
        background-color: lighten($primary, 50%);
    }

</style>
