var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-fluid m1" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.state.search.val,
                        expression: "state.search.val",
                      },
                    ],
                    staticClass: "form-control w-100 mb-3",
                    domProps: { value: _vm.state.search.val },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.state.search, "val", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.clickReset },
                },
                [_vm._v("reset")]
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("nested-specification", {
                staticClass: "item-sub",
                attrs: { nbi: _vm.nbi, local: _vm.local },
                model: {
                  value: _vm.local.table,
                  callback: function ($$v) {
                    _vm.$set(_vm.local, "table", $$v)
                  },
                  expression: "local.table",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-so", {
        attrs: {
          "modal-id": "modalSo",
          "p-local": _vm.local,
          "p-state": _vm.state,
        },
        on: { updInit: _vm.init, selectObj: _vm.onSelectObjFromBase },
      }),
      _vm._v(" "),
      _vm.isMounted
        ? _c("context-menu-my", { ref: "menu", attrs: { items: _vm.items } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row zag" }, [
      _c("div", { staticClass: "col-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2" }, [_vm._v("позиция")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10" }, [_vm._v("название")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-7" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }, [_vm._v("марка")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [_vm._v("артикул")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("поставщик")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [_vm._v("ед-изм")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [_vm._v("кол-во")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [_vm._v("примечение")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }