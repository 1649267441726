<template>
    <div :id="modalId" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    редактирование тегов
                </div>
                <div class="modal-body" style="max-height: 30rem; overflow: auto">
                    <div class="d-flex justify-content-between mb-3">
                        <div class="w-100 mr-2" style="position: relative">
                            <input class="form-control w-100" v-model="search" ref="v_focus" @click="setClear">
                            <font-awesome-icon icon="fa-solid fa-eraser" size="lg" @click="search=''" title="очистить"/>
                        </div>
                        <div>
                            <font-awesome-icon icon="fa-solid fa-plus" size="lg" border class="mt-1" style="cursor: pointer" @click="clickAdd" title="добавить"/>
                        </div>
                    </div>
                    <table class="table table-sm">
                        <tr v-for="(val,index) in fTable" :key="val.id">
                            <td v-if="val.id!==currentId" class="text-left"
                                style="cursor: pointer; text-decoration: underline;"
                                @click="setActive(val)">{{val.name}}
                            </td>
                            <td v-else class="text-left"><input class="form-control" v-model="val.name"></td>
                            <td class="p-2" style="width: 5rem">
                                <div :style="{backgroundColor:val.color}" @click="showModalColorPicker(val)">
                                    <font-awesome-icon icon="fa-solid fa-eye-dropper" size="xs" :style="{color:invertHex(val.color)}"/>
                                </div>
                            </td>
                            <td class="text-right">
                                <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click="clickRemove(val)" title="удалить"/>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="modal-footer justify-content-between" @click="setClear">
                    <div></div>
                    <div>
                        <button type="button" class="btn btn-primary" @click="clickSave">сохранить</button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">отмена</button>
                    </div>
                </div>
            </div>

        </div>
        <modal-color-picker/>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import ModalColorPicker from "./ModalColorPicker";

    export default {
        name: 'modal-list-tag',
        components: {Multiselect, vueDropzone: vue2Dropzone, ModalColorPicker},
        props: {
            modalId: {type: String, default: 'modalListTag',},
            listTag: {type: Array, default: [],}
        },
        data: function () {
            return {
                rand: 1,
                local: {
                    listTag: [],
                },
                listRemove: [],
                listUpdate: [],
                state: {},
                currentId: null,
                search: '',
            }
        },
        created: function () {
            Bus.$on('show_' + this.modalId, () => this.showModal());
            Bus.$on('updColor', obj => {
                this.local.listTag.forEach(item => {
                    if (item.name === obj.name) item.color = obj.color;
                })
            });
        },
        mounted() {
            // Vue.nextTick(() => this.local.listTag = JSON.parse(JSON.stringify(this.listTag)))
        },
        methods: {
            setActive(obj) {
                this.currentId = obj.id;
                if (obj.new === undefined) this.listUpdate.push(obj);
            },
            setClear() {
                this.currentId = null;
            },
            showModalColorPicker(obj) {
                if (obj.new === undefined) this.listUpdate.push(obj);
                Bus.$emit('show_modalColorPicker', obj);
            },
            showModal() {
                this.local.listTag = JSON.parse(JSON.stringify(this.listTag));
                $('#' + this.modalId).modal('show');
                setTimeout(() => this.$refs.v_focus.focus(), 500);
            },
            clickAdd() {
                if (this.search === '') {
                    setTimeout(() => this.$refs.v_focus.focus(), 500)
                    return
                }
                this.local.listTag.push({id: Date.now(), name: this.search, color: '#ffae07', new: true});
            },
            clickRemove(obj) {
                if (obj.new === undefined) this.listRemove.push(obj);
                let index = this.local.listTag.findIndex(item => item.id === obj.id);
                if (index !== undefined && index !== null) this.local.listTag.splice(index, 1);
            },
            clickSave() {
                Bus.$emit('zPost', {
                    url: '/project/tag/save',
                    obj: {listNew: this.local.listTag.filter(item => item.new !== undefined), listRemove: this.listRemove, listUpdate: this.listUpdate},
                    flash: true,
                    suffix: '_save_tag_modal',
                });
                Bus.$once('zResponse_save_tag_modal', response => {
                    this.local.listTag = response.list;
                    Bus.$emit('updListTag', this.local.listTag);
                    Bus.$emit('updTagsProject', response.listP);
                    $('#' + this.modalId).modal('hide');
                });
            },
            invertHex(hex) {
                if (hex.indexOf('#') === 0) hex = hex.slice(1);
                return '#'+(Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase();
            },
        },
        computed: {
            fTable: function () {
                return this.local.listTag.filter(item => {
                    let res = true;
                    this.search.split(' ').forEach(value => {
                        let s = item.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }
</style>
