<template>
    <nav class="navbar navbar-expand-sm navbar-light navbar-laravel fixed-top">
        <div class="container-fluid">
            <a class="navbar-brand font-weight-bold" href="/"><span style="color: green">S</span>peka</a>

            <ul class="navbar-nav">
                <li v-if="isAuth" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">содержание</a>
                    <div class="dropdown-menu" style="overflow-y: auto; height: 40rem">
                        <a class="dropdown-item font-weight-bold" :href="'/pages/'+projectInfo.id">Содержание</a>
                        <template v-for="val in local.pageMenu">
                            <a :key="val.id" class="dropdown-item" :href="val.link+'/'+authUser.prop.currentProjectId+'/'+val.id" :class="{disabled:val.link==='/tp'}">{{ val.name }}</a>
                            <template v-for="val2 in val.child">
                                <a :key="val2.id" class="dropdown-item" :href="val2.link+'/'+authUser.prop.currentProjectId+'/'+val2.id" style="padding-left: 2.3rem">{{ val2.name }}</a>
                            </template>
                        </template>
                        <a class="dropdown-item font-weight-bold" :href="'/pages/'+authUser.prop.currentProjectId+'/get_docx'">скачать текст</a>
                        <a class="dropdown-item font-weight-bold" :href="'/pages/'+authUser.prop.currentProjectId+'/get_zip'">скачать ZIP</a>
                    </div>
                </li>

                <li v-if="isAuth" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">база</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="/baseps">база партнёров</a>
                        <a class="dropdown-item" href="/base">база оборудования</a>
                        <a class="dropdown-item" href="/category">категории</a>
                        <a class="dropdown-item" href="/marka_element">марки оборудования</a>
                        <a class="dropdown-item" href="/specification">спецификация</a>
                        <a class="dropdown-item" href="/cj">КЖ</a>
                        <a class="dropdown-item" href="/specification/clear_cache">очистить кэш</a>
                    </div>
                </li>

                <li v-if="isAuth" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">расчёт</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" :href="'/global_variables/'+authUser.prop.currentProjectId">глобальные переменные</a>
                        <a class="dropdown-item" href="/calc/kz">ток КЗ</a>
                        <a class="dropdown-item" href="/calc/zaz">расчёт сопротивления заземления</a>
                        <a class="dropdown-item" href="/calc/term_cab">проверка кабеля</a>
                    </div>
                </li>

                <li v-if="isAuth" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">электрика</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" :href="'/room/'+authUser.prop.currentProjectId">помещения и зоны</a>
                        <a class="dropdown-item" :href="'/potr/'+authUser.prop.currentProjectId">потребители</a>
                        <a class="dropdown-item" :href="'/panel/'+authUser.prop.currentProjectId">щиты</a>
                        <a class="dropdown-item" href="/panel_re_calc_all">пересчитать</a>
                    </div>
                </li>

                <li v-if="isAuth" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">проект</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="/project">проекты</a>
                        <a class="dropdown-item" href="/project/start_ws">startWS</a>
                    </div>
                </li>

                <li class="ml-5"></li>
                <li class="ml-2" v-if="onShow.reload">
                    <font-awesome-icon v-if="isLoading" icon="fa-solid fa-circle-notch" size="2xl" class="mt-1" @click="clickReload" title="обновить страницу" spin/>
                    <font-awesome-icon v-else icon="fa-solid fa-rotate" size="2xl" class="mt-1" @click="clickReload" title="обновить страницу"/>
                </li>

                <li class="ml-2" v-if="onShow.reload_calc">
                    <font-awesome-icon v-if="isLoading" icon="fa-solid fa-circle-notch" size="2xl" class="mt-1" @click="clickReload" title="обновить страницу" spin/>
                    <font-awesome-icon v-else icon="fa-solid fa-rotate" size="2xl" class="mt-1" @click="clickReloadCalc" title="обновить страницу"/>
                </li>

                <li class="ml-2" v-if="onShow.save">
                    <font-awesome-icon :class="'animate__animated animate__tada animate__repeat-2'"
                                       icon="fa-solid fa-floppy-disk" size="2xl" class="mt-1" @click="clickSave" title="сохранить изменения"/>
                </li>
                <li class="ml-2" v-if="onShow.calc">
                    <font-awesome-icon :class="'animate__animated animate__tada animate__repeat-2'"
                                       icon="fa-solid fa-calculator" size="2xl" class="mt-1" @click="clickCalc" title="сохранить изменения"/>
                </li>
                <li class="ml-2" v-if="onShow.insert"><i class="fas fa-plus fa-2x fa-fw" @click="clickAdd" style="opacity: 1" title="вставить элемент"></i></li>
                <li class="ml-2" v-if="onShow.excel"><i class="fas fa-file-excel fa-2x fa-fw" @click="clickExcel" style="opacity: 1" title="экспорт в excel'"></i></li>

                <li class="ml-2" v-if="onShow.word">
                    <font-awesome-icon icon="fa-solid fa-file-word" size="2xl" class="mt-1" @click="clickWord" title="экспорт в word"/>
                </li>
                <li class="ml-2" v-if="onShow.import">
                    <font-awesome-icon icon="fa-solid fa-angles-down" size="2xl" class="mt-1" @click="clickImport" title="импорт из другого проекта"/>
                </li>
                <li class="ml-2" v-if="onShow.download">
                    <font-awesome-icon icon="fa-solid fa-file-arrow-down" size="2xl" class="mt-1" @click="clickDownload" title="закачать документ"/>
                </li>
                <li class="ml-2" v-if="onShow.downloadZip">
                    <font-awesome-icon icon="fa-solid fa-file-zipper" size="2xl" class="mt-1" @click="clickDownloadZip" title="скачать комплект документов"/>
                </li>
                <!--                <li class="ml-2" v-if="onShow.export">-->
                <!--                    <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" size="2xl" class="mt-1" @click="clickExport" title="экспорт в Autocad"/>-->
                <!--                </li>-->
                <li class="ml-2" v-if="onShow.export">
                    <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" size="2xl" class="mt-1" style="color: green" @click="clickExportWs" title="экспорт в Autocad WS"/>
                </li>
                <li class="ml-2" v-if="onShow.fileImport">
                    <font-awesome-icon icon="fa-solid fa-file-import" size="2xl" class="mt-1" @click="clickFileImport" title="импорт информации из файла"/>
                </li>
                <li class="ml-2" v-if="onShow.clearCookie">
                    <font-awesome-icon icon="fa-solid fa-cookie-bite" size="2xl" class="mt-1" @click="clickClearCookie" title="удаление cookie для этой страницы" style="color: goldenrod"/>
                </li>
                <li class="ml-2" v-if="onShow.setting"><i class="fas fa-cogs fa-2x fa-fw" @click="clickSetting" title="настройки"></i></li>
            </ul>

            <div v-if="statusRow.desc && isGetStatus" class="ml-5 w-25">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" :style="{width: statusRow.percent+'%'}">{{ statusRow.desc }}</div>
                </div>
            </div>

            <ul class="navbar-nav ml-auto right navbar-right">
                <li v-if="isAuth">
                    <font-awesome-icon v-if="authUser.prop.currentProjectId!==authUser.prop.loadProjectId"
                                       icon="fa-solid fa-exclamation" size="xl" class="mt-2 text-danger animate__animated animate__tada animate__repeat-2" style="opacity: 1;"/>
                </li>
                <li v-if="isAuth" class="mr-3 nav-item dropdown">
                    <a id="navbarDropdown2" class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        {{ local.projectInfo.name }}<span class="caret"></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" :href="'/project/'+authUser.prop.currentProjectId+'/show'">информация</a>
                        <a class="dropdown-item" :href="'/project/'+authUser.prop.loadProjectId+'/set_current'">{{ local.projectInfo.loadName }}</a>
                        <a class="dropdown-item" href="/project/project_matching">сопоставить с другим</a>
                    </div>
                </li>


                <!--                <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" size="2xl" class="mt-1" @click="clickLoad"/>-->

                <!--                <ul class="navbar-nav ml-auto">-->
                <li v-if="!isAuth" class="nav-item">
                    <a class="nav-link" href="/login">вход</a>
                </li>
                <li v-if="isAuth" class="nav-item dropdown">
                    <a id="navbarDropdown" class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        {{ authUser.name }}<span class="caret"></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="/profile">профиль</a>
                        <a class="dropdown-item" href="/logout">выход</a>
                        <span v-if="isAdmin">
                                <a class="dropdown-item" href="/admin/sync_all_projects">синхронизировать</a>
                            </span>
                    </div>
                </li>
                <!--                </ul>-->

            </ul>

        </div>
        <zapros></zapros>
    </nav>
</template>

<script>
import globalKeyHook from "./globalKeyHook";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Zapros from "./sub/zapros";

export default {
    components: {Zapros, globalKeyHook, vueDropzone: vue2Dropzone},
    props: {
        data: {
            type: Object,
            required: true,
        },
        url: {
            type: Object,
            required: true,
        },
        isAuth: {
            type: Boolean,
            default: false,
            required: true,
        },
        isAdmin: {
            type: Boolean,
            default: false,
            required: true,
        },
        projectInfo: {
            type: Object,
            required: true,
        },
        authUser: {
            type: Object,
            required: true,
        },
        pageMenu: {
            type: Array,
            default: () => [],
            required: true,
        },
        getMErrors: {
            type: Array,
            default: () => [],
            required: true,
        },
        channel: {
            type: String,
            default: 'default',
            required: true,
        },
    },
    data: function () {
        return {
            user: {},
            isLoading: false,
            currentUrl: '',
            local: {
                projectInfo: {
                    id: '0',
                    name: 'проект не загружен',
                },
                pageMenu: this.pageMenu,
            },
            onShow: {
                reload: false,
                reload_calc: false,
                save: false,
                calc: false,
                insert: false,
                excel: false,
                export: false,
                download: false,
                downloadZip: false,
                fileImport: false,
                clearCookie: false,
            },
            statusRow: '',
            isGetStatus: true,
            statusWSocket: false,
        }
    },
    mounted() {
        this.local.projectInfo = Object.assign(this.local.projectInfo, this.projectInfo);
        Bus.$on('navbar', e => {
            if (e.command === 'setLoading') {
                this.isLoading = e.args;
            }
            if (e.command === 'onSave' && !this.isLoading) this.onShow.save = e.args;
            if (e.command === 'showButtons') this.onShow = Object.assign(this.onShow, e.args);
            if (e.command === 'get_navbar_info') {
                Bus.$emit('navbar_info', {
                    url: {
                        current: this.url.current,
                        prev: this.url.prev,
                        main: this.url.main,
                    },
                    projectInfo: this.local.projectInfo,
                    authUser: this.authUser,
                    isAdmin: this.isAdmin,
                    isAuth: this.isAuth,
                });
            }
        });
        Bus.$on('updListMenu', (obj) => this.local.pageMenu = obj);
        $('.dropdown-item[href="' + this.url.current + '"]').addClass('active').parent().parent().find('.dropdown-toggle').addClass('active');
        $('.single-menu[href="' + this.url.current + '"]').addClass('active');
        this.showErrors();
        this.pusherInit();
    },
    methods: {
        pusherInit() {
            window.Echo.channel(this.channel).listen('evMessage', (e) => {
                if (e.command === 'upd') window.location.reload();
                if (e.command === 'sop') location.href = location.origin + "/project/project_matching";
                console.log(e);
            });
        },
        showErrors() {
            if (this.getMErrors.length === 0) return;
            this.getMErrors.forEach(item => this.flash(item.message, 'error', {timeout: 2000}));
        },
        clickWS() {
            if (!this.statusWSocket) {
                this.statusWSocket = true;
                this.clickWSUp();
            } else {
                this.statusWSocket = false;
                this.clickWSUp();
            }
        },
        clickWSUp() {
            let suffix = '_wsUp';
            this.isLoading = true;
            Bus.$emit('zPost', {
                url: '/ws/up',
                obj: {},
                suffix: suffix,
            });
            Bus.$on('zResponse' + suffix, response => {
                this.isLoading = false;
                this.isGetStatus = false;
            });
            Bus.$on('zError' + suffix, response => {
                this.isLoading = false;
                this.isGetStatus = false;
            });
            Bus.$on('zCatch' + suffix, response => {
                this.isLoading = false;
                this.isGetStatus = false;
            });
        },
        clickLoad() {
            let suffix = '_load';
            this.isLoading = true;
            Bus.$emit('zPost', {
                url: '/project/load',
                obj: {},
                suffix: suffix,
            });
            Bus.$on('zResponse' + suffix, response => {
                this.isLoading = false;
                this.isGetStatus = false;
                if (response.error === undefined || response.error.length === 0) document.location.href = response.url;
                else {
                    let str = '';
                    response.error.forEach(item => str += item.message + '<br>');
                    this.flash(str, 'error')
                }
            });
            Bus.$on('zError' + suffix, response => {
                this.isLoading = false;
                this.isGetStatus = false;
                if (response.sop) document.location.href = this.url.main + '/project/project_matching';
            });
            Bus.$on('zCatch' + suffix, response => {
                this.isLoading = false;
                this.isGetStatus = false;
            });
            this.isGetStatus = true;
            setTimeout(() => this.getStatusPost(), 1000);
        },
        getStatusPost() {
            axios.post('/get_status').then(response => {
                if (response.data[0] !== undefined) {
                    this.statusRow = response.data[0];
                    this.statusRow.percent = 0;
                    if (this.statusRow['count'] > 0) this.statusRow.percent = this.statusRow.index / this.statusRow['count'] * 100;
                }
                setTimeout(() => {
                    if (this.isGetStatus) this.getStatusPost();
                }, 1000);
            });
        },
        clickSave() {
            Bus.$emit('keyUp', {key: 'ctrlS', evt: {}});
        },
        clickCalc() {
            Bus.$emit('keyUp', {key: 'calc', evt: {}});
        },
        clickExport() {
            Bus.$emit('keyUp', {key: 'exportAutocad', evt: {}});
        },
        clickExportWs() {
            Bus.$emit('keyUp', {key: 'exportAutocadWs', evt: {}});
        },
        clickExcel() {
            Bus.$emit('keyUp', {key: 'exportExcel', evt: {}});
        },
        clickWord() {
            Bus.$emit('keyUp', {key: 'exportWord', evt: {}});
        },
        clickImport() {
            Bus.$emit('keyUp', {key: 'import', evt: {}});
        },
        clickAdd() {
            Bus.$emit('keyUp', {key: 'insert', evt: {}});
        },
        clickSetting() {
            Bus.$emit('keyUp', {key: 'setting', evt: {}});
        },
        clickDownload() {
            Bus.$emit('keyUp', {key: 'download', evt: {}});
        },
        clickDownloadZip() {
            Bus.$emit('keyUp', {key: 'downloadZip', evt: {}});
        },
        clickReloadCalc() {
            Bus.$emit('keyUp', {key: 'reloadCalc', evt: {}});
        },
        clickFileImport() {
            Bus.$emit('keyUp', {key: 'fileImport', evt: {}});
        },
        clickClearCookie() {
            Bus.$emit('keyUp', {key: 'clearCookie', evt: {}});
        },
        clickReload() {
            document.location.reload();
        },
        an() {
            animateCSS('.fas.fa-file-excel', 'tada', function (e) {
                // e.css('display', 'none');
            });
        }
    },
}
</script>


<style scoped lang="scss">
@import 'resources/sass/variables';

.navbar-nav i {
    opacity: 0.5;
    margin-top: 0.3rem;
}

.navbar-nav i:hover {
    opacity: 1;
}

svg.svg-inline--fa {
    cursor: pointer;
    color: $primary;

    &.fa-circle-notch {
        color: $warning;
    }
}

i {
    cursor: pointer;

    &:hover {
        opacity: 1 !important;
    }

    &.fa-sync-alt {
        color: $primary;
    }

    &.fa-save {
        color: $primary;
    }

    &.fa-file-excel {
        color: $primary;
    }

    &.fa-file-word {
        color: $primary;
    }

    &.fa-cloud-upload-alt {
        color: $primary;
    }

    &.fa-cloud-download-alt {
        color: $primary;
    }

    &.fa-trash-alt {
        color: $primary;
    }

    &.fa-cogs {
        color: $primary;
        margin-left: 4px;
    }
}
</style>
