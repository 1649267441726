var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _vm.getOstatok() !== 0
                ? _c("h3", { staticClass: "mb-3" }, [
                    _vm._v("остаток: "),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(" " + _vm._s(_vm.getOstatok())),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.local.finance, function (val) {
                return _c(
                  "div",
                  {
                    staticClass: "row",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickEdit(val.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "col-3" }, [
                      _vm._v(_vm._s(_vm.toolM.customFormatter(val.d_event))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        val.t_event === "price"
                          ? _c("font-awesome-icon", {
                              staticClass: "text-primary",
                              attrs: { icon: ["fas", "comments-dollar"] },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        val.t_event === "receipt"
                          ? _c("font-awesome-icon", {
                              staticStyle: { color: "goldenrod" },
                              attrs: { icon: ["fas", "coins"] },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n                        " +
                            _vm._s(val.amount) +
                            "\n                    "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-1" },
                      [
                        val.wallet === "avangard"
                          ? _c("img", {
                              staticClass: "mb-1",
                              staticStyle: { width: "1rem" },
                              attrs: { src: "/img/avangard_logo.png" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        val.wallet === "sber"
                          ? _c("img", {
                              staticClass: "mb-1",
                              staticStyle: { width: "1rem" },
                              attrs: { src: "/img/sber_logo.png" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        val.wallet === "cache"
                          ? _c("font-awesome-icon", {
                              staticClass: "text-secondary",
                              attrs: { icon: ["fas", "hand-holding-dollar"] },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-5" }, [
                      _vm._v(_vm._s(val.comm)),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "form-control btn btn-primary w-100",
                      on: { click: _vm.showModalInsert },
                    },
                    [_vm._v("добавить")]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-finance", {
        attrs: {
          "modal-id": "modalFinance",
          "p-local": _vm.local,
          "p-state": _vm.state,
        },
        on: { updInit: _vm.init },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }