<template>
    <div class="h-100">
        <div class="container-fluid m1">



            <p>HOT</p>
            <div v-for="el in graph.h" class="row">
                <div class="col-1">{{ el.key }}</div>
                <div class="col-1">{{ el.val }}</div>
            </div>

            <textarea class="form-control mb-3" v-text="getArrText(graph.h)"/>

            <p>COLD</p>
            <div v-for="el in graph.c" class="row">
                <div class="col-1">{{ el.key }}</div>
                <div class="col-1">{{ el.val }}</div>
            </div>

            <textarea class="form-control mb-3" v-text="getArrText(graph.c)"/>


        </div>
    </div>
</template>

<script>
import TitleForPage from "./sub/TitleForPage";
import {page} from "./sub/tools";
import CardOptions from "./sub/CardOptions";
import nestedSo from "./sub/nestedSo";
import ContextMenuMy from "./sub/ContextMenuMy";

export default {
    name: "page-import-graph",
    components: {},
    props: ['graph'],
    data: function () {
        return {
            pageT: new page(this),
            nbi: {},
            state: {
                version: 5,
                swOnOpt: false,
                swOnOpt2: false,
            },
            status: {
                save: false,
                loading: false,
            },
            local: {
                cPage: this.cPage,
            },
            items: [
                {name: 'переключить видимость', link: 'hide'},
                {name: '---'},
                {name: 'переключить видимость щита', link: 'hideRu'},
            ],
            nameCField: 'table_cj_0',
            isMounted: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
            if (e.key === 'ctrlS') this.pageT.clickSaveCPage();
            if (e.key === 'download') this.pageT.clickDownload();
        });
        Bus.$on('navbar_info', obj => this.nbi = obj);

        Bus.$on('onShowContextMenu', obj => this.$refs[obj.refs].open(obj.e, obj.el));
        Bus.$on('contextMenu_menu', (o) => {
            if (o.link === 'hide') this.onHide(o.obj);
            if (o.link === 'hideRu') this.onHideAll(o.obj);
        });
    },
    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, download: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.pageT.loadCookie();
        if (this.cField === undefined) this.flash('ошибка содержимого страницы', 'error');
        else this.isMounted = true;
    },
    methods: {
        getArrText(arr) {
            let str = '';
            arr.forEach(item => {
                str += item.key + '=>' + item.val + ', ';
            })
            return str;
        },
        onHide(obj) {
            obj.isVisible = !obj.isVisible;
        },
        onHideAll(obj) {
            let parent = obj;
            if (!obj.isRu) parent = this.cField.opt.table.find(item => item.id === obj.parent_id);

            parent.isVisible = !parent.isVisible;
            parent.child.forEach(item => item.isVisible = parent.isVisible);
        },
        clickReset() {
            this.cField.opt.table = [];
            this.pageT.clickSaveCPage();
        },
    },
    computed: {
        cField: function () {
            return this.local.cPage.data.fields.find(item => item.idd === this.nameCField);
        },
    },
    watch: {
        'state.swOnOpt'(newVal, oldVal) {
            this.pageT.updCookie();
        },
        'state.swOnOpt2'(newVal, oldVal) {
            this.pageT.updCookie();
        },
    },
}
</script>

<style scoped lang="scss">
@import 'resources/sass/variables';

.row.header {
    font-weight: bold;
    text-align: center;
    border: 1px solid $primary;
    background-color: lighten($primary, 20%);
}
</style>
