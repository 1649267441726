<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount" data-keyboard="false">
        <div class="modal-dialog modal-xl" @click.stop="">
            <div class="modal-content">
                <div class="modal-header" style="cursor: move">
                    <c-title-so v-model="state.obj" :is-razdel="true"/>
                    <span>{{ state.obj?.name }}</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div v-if="isShow" class="modal-body">

                    <div class="d-flex flex-column" style="max-height: calc(100vh - 236px);">

                        <div style="overflow-x: hidden;">
                            <div class="row">
                                <div class="col-12">
                                    <input class="form-control w-100" v-model.trim="state.obj.name" title="название блока" placeholder="название блока"/>
                                </div>
                            </div>

                            <c-list-v-so v-model="state.obj.v"/>

                        </div>
                    </div>

                </div>
                <div class="modal-footer d-flex flex-row">
                    <button type="button" class="btn btn-primary" @click="clickLocalSave">сохранить</button>
                    <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {eTable, page} from "./tools";
import EInput from "./EInput";
import BInputField from "./BInputField";
import modalSoInfo from "./ModalSoInfo.vue";
import cCategoryString from "./cCategoryString.vue"
import BInputValSo from "./BInputValSo.vue";
import CListVSo from "./cListVSo.vue";
import CTitleSo from "./cTitleSo.vue";


export default {
    name: 'modal-so-f-block',
    components: {CTitleSo, CListVSo, BInputValSo, EInput, Multiselect, BInputField, modalSoInfo, cCategoryString},
    props: {
        modalId: {type: String, default: 'modal',},
        AW: {type: Array, default: () => [],},
        listCategory: {type: Array, default: () => [],},
        marka: {type: String, default: 'marka_new',},
    },
    data: function () {
        return {
            //todo js modal add page module
            eTable: new eTable(this),
            pageT: new page(this),
            rand: 1,
            local: {
                bsBase: [],
                psBase: [],
                prBase: [],
                selectArr: [],
            },
            state: {
                obj: null,
                fCatField: {},
                isEdit: true,
                search: {
                    val: ''
                },
                activeRowIndex: 0,
                listBase: [
                    {id: 'bs', name: 'база', isSelect: true},
                    {id: 'ps', name: 'производители', isSelect: false},
                    {id: 'pr', name: 'личная', isSelect: false},
                ],
                currentCategory: '',
                isPS: false,
            },
            status: {
                loading: false,
            },
            templateObj: {
                name: '',
                marka: '',
                fields: {},
            },
            lock: {
                name: true,
                marka: true,
            },
            lockF: {},
            isMount: false,
            isShow: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (this.isAW) {
                if (e.key === 'f2') this.showModalSoInfo();
                if (e.key === 'ctrlEnter') this.clickLocalSave();
                if (e.key === 'esc') this.closeModal();
                if (e.key === 'down') {
                    if (this.state.activeRowIndex + 1 < this.fTable.length) this.clickRow(this.state.activeRowIndex + 1);
                    e.evt.preventDefault();
                }
                if (e.key === 'up') {
                    if (this.state.activeRowIndex > 0) this.clickRow(this.state.activeRowIndex - 1);
                    e.evt.preventDefault();
                }
            }
        });
        Bus.$on('show_' + this.modalId, (e) => {
            this.state.isEdit = true;
            this.local.selectArr = e.selectArr;
            this.local.saveArr = e.saveArr;
            this.showModal()
            this.isShow = true;
        });
        Bus.$on('showI_' + this.modalId, (e) => {
            this.state.isEdit = false;
            this.local.selectArr = e.selectArr;
            this.local.saveArr = e.saveArr;
            this.showModal();
            this.isShow = true;
        });
        Bus.$on('closeModal', () => {
            this.closeModal();
        });
    },
    mounted() {
        // new modalDraggable(this);
        this.isMount = true;
        // this.state.search.val = 'авт';
    },
    methods: {
        preCalc() {
            this.state.obj.v.forEach(item=>{
                if(!item.formula){
                    if(!!item.v_in) item.v_out = item.v_in;
                }
            })
        },
        getFFieldType(el) {
            let o = this.getFField(el);
            if (!o) return '';
            return o.type;
        },
        getFFieldDesc(el) {
            let o = this.getFField(el);
            if (!o) return '';
            return o.desc;
        },
        getFFieldName(el) {
            let o = this.getFField(el);
            if (!o) return '';
            return o.name + ' ' + o.unit;
        },
        getFField(el) {
            let cCat = this.listCategory.find(item => item.id === this.state.obj.fCat);
            if (!cCat) return null;
            let f = cCat.prop.fields.find(item => item.id === el.idF);
            if (!f) return null;
            return f;
        },
        customSearch(query) {
            if (query === '') {
                this.local.category = this.pLocal.category;
            }
            this.local.category = this.pLocal.category.filter(item => {
                let res = true;
                query.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        clickEnter() {
            let o = this.fTable[this.state.activeRowIndex];
            if (o) this.$emit('selectObj', o);
            this.closeModal();
        },
        showModal() {
            if (!this.lastSelectObj) return;
            this.activeRowIndex = 0;
            this.state.obj = JSON.parse(JSON.stringify(this.lastSelectObj));

            $('#' + this.modalId).modal('show');

            setTimeout(function () {
                $(".modal").draggable({handle: ".modal-header"});
            });

            (function (id) {
                setTimeout(function () {
                    Bus.$emit('setAW', id);
                });
            })(this.modalId)

            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    if (this.$refs.v_focus) this.$refs.v_focus.focus();
                }, 200);
            }
        },
        clickLocalSave() {
            this.preCalc();
            this.local.selectArr.forEach(item => {
                item.name = this.state.obj.name;
                item.isVisible = this.state.obj.isVisible;
                item.isRazdel = this.state.obj.isRazdel;
                item.v = this.state.obj.v;
                this.local.saveArr.push(item);
            })
            this.closeModal();
        },
        closeModal() {
            $('#' + this.modalId).modal('hide');
            Bus.$emit('removeAW', this.modalId);
            this.isShow = false;
        },
    },
    computed: {
        cCat: function () {
            return this.listCategory.find(item => item.id === this.state.obj.fCat);
        },
        listCatChild: function () {
            if (this.state.currentCategory === '') return [];
            if (this.state.isPS) return [this.state.currentCategory];
            let arr = [];
            this.recursGetChild(parseInt(this.state.currentCategory), arr);
            return arr;
        },
        isAW: function () {
            return this.AW !== undefined && this.AW[0] === this.modalId;
        },
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
        activeRow: function () {
            let o = this.fTable[this.state.activeRowIndex];
            if (!o) o = {};
            return o;
        },

        fTable: function () {
            if (this.state.search.val === undefined || this.state.search.val === '') return [];
            let i = 0;
            let table = [];
            this.state.listBase.forEach(item => {
                if (item.isSelect && this.local[item.id + 'Base'] !== undefined) table = table.concat(this.local[item.id + 'Base']);
            })
            if (table.length === 0) return [];

            if (this.listCatChild.length > 0) {
                table = table.filter(item => {
                    return this.listCatChild.findIndex(item2 => String(item2) === String(item.category)) > -1;
                })
            }

            return table.filter(item => {
                if (i > 9) return false;
                let res = true;
                if (this.state.search.val !== '') {
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name + item.marka + item.brand + item.article;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                }
                if (res) i++;
                return res;
            })
        },
    },
    watch: {},
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}

ul.multiselect__content {
    width: 100% !important;
}

input.lock {
    background-color: lighten($primary, 50%);
}

.bg-primary-l {
    background-color: lighten($primary, 40);
}


</style>
