var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _c(
        "div",
        { staticClass: "container-fluid m1" },
        [
          _c("title-for-page", {
            attrs: { "c-page": _vm.local.cPage, nbi: _vm.nbi },
          }),
          _vm._v(" "),
          _vm.isMounted
            ? _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-xl-11" },
                  [
                    _c(
                      "nav",
                      {
                        staticClass: "navbar navbar-expand pl-0 pt-0",
                        staticStyle: { "margin-left": "-0.5rem" },
                      },
                      [
                        _c("div", { staticClass: "container-fluid" }, [
                          _c("div", { staticClass: "navbar-nav" }, [
                            _c("div", { staticClass: "nav-item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "nav-link btn",
                                  class: { active: _vm.state.swOnOpt },
                                  attrs: { title: "настройки страницы" },
                                  on: {
                                    click: function ($event) {
                                      _vm.state.swOnOpt = !_vm.state.swOnOpt
                                    },
                                  },
                                },
                                [_vm._v("настройки")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "nav-item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "nav-link btn",
                                  class: { active: _vm.state.swOnOpt2 },
                                  attrs: { title: "настройки страницы" },
                                  on: {
                                    click: function ($event) {
                                      _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                    },
                                  },
                                },
                                [_vm._v("настройки таблицы")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("card-options", {
                      staticClass: "mb-3",
                      attrs: { "c-page": _vm.local.cPage, state: _vm.state },
                    }),
                    _vm._v(" "),
                    _vm.state.swOnOpt2
                      ? _c("div", { staticClass: "card mb-3" }, [
                          _c(
                            "div",
                            {
                              staticClass: "card-header p-1 pl-2",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                },
                              },
                            },
                            [_vm._v("настройки таблицы")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-2" }, [
                                _c("label", { staticClass: "w-100 mt-2" }, [
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v(
                                      _vm._s(_vm.cField.opt.colEmptyRow.desc)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: _vm.cField.opt.colEmptyRow.val,
                                        expression:
                                          "cField.opt.colEmptyRow.val",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      placeholder:
                                        _vm.cField.opt.colEmptyRow.desc,
                                    },
                                    domProps: {
                                      value: _vm.cField.opt.colEmptyRow.val,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.cField.opt.colEmptyRow,
                                          "val",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-sm",
                          on: { click: _vm.clickReset },
                        },
                        [_vm._v("сброс")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("nested-so", {
                            staticClass: "item-sub",
                            attrs: { nbi: _vm.nbi },
                            model: {
                              value: _vm.cField.opt.table,
                              callback: function ($$v) {
                                _vm.$set(_vm.cField.opt, "table", $$v)
                              },
                              expression: "cField.opt.table",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMounted
        ? _c("context-menu-my", { ref: "menu", attrs: { items: _vm.items } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row header align-items-center" }, [
      _c("div", { staticClass: "col-1" }, [_vm._v("Позиция")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5" }, [_vm._v("Наименование")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("Тип")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("Код")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("Поставщик")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("Ед.изм.")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("Кол-во")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("Примечание")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }