<template>
    <draggable
        tag="div"
        class="item-container"
        :list="list"
        :value="value"
        @input="emitter"
        handle=".handle"
        animation="150"
        group="nested"
        :fallback-on-body="true"
        :swap-threshold="0.5"
        ghost-class="ghost"
        filter=".no-drag"
        @change="onChange"
        :scroll-sensitivity="100"
        :fallback-tolerance="1"
        :force-fallback="true"
    >
        <div v-for="el in realValue" class="item-group" :key="el.id">

            <div v-if="el.t==='element'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ el.num }}</div>
                        <div class="col-11 text-left">{{ el.element.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">

                        <div class="col-3">{{ el.element.marka }}</div>
                        <div class="col-3">{{ el.element.article }}</div>
                        <div class="col-2">{{ el.element.brand }}</div>
                        <div class="col-1">{{ el.element.unit }}</div>
                        <div class="col-1"><span>{{ el.element.val }}</span></div>
                        <div class="col-1">{{ el.element.wt }}</div>
                        <div class="col-1">{{ el.element.desc }}</div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='razdel'" class="item row razdel"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ el.num }}</div>
                        <div class="col-11 font-weight-bold text-left">{{ el.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">

                        <div class="col-6 text-left"></div>
                        <div class="col-2"></div>
                        <div class="col-1"></div>

                        <div class="col-1"><span>{{ el.calc }}</span></div>
                        <div class="col-1"></div>
                    </div>
                </div>
            </div>

            <nested-specification-view class="item-sub ml-3" :list="el.child" :level="level + 1" :id="el.id" :local="local"/>
        </div>

    </draggable>
</template>

<script>

import draggable from 'vuedraggable'
import {eTable} from "./tools";

export default {
    name: "nested-specification-view",
    components: {draggable},
    props: {
        value: {
            required: false,
            type: Array,
            default: null
        },
        list: {
            required: false,
            type: Array,
            default: null
        },
        level: {
            type: Number,
            default: 1,
        },
        id: {
            required: false,
            type: String,
            default: '0'
        },
        // nbi: {
        //     required: true,
        //     type: Object,
        //     default: {}
        // },
        local: {},
    },
    data: function () {
        return {
            isMove: true,
            oldValue: this.value,
            lValue: this.value,
        }
    },
    created: function () {

    },
    methods: {
        isActiveSave(obj) {
            return this.local.saveArr.find(item => item.id === obj.id);
        },
        isSelect(obj) {
            let key = this.local.selectArr.findIndex(item => item.id === obj.id);
            return key >= 0;
        },
        clickRow(obj) {
            Bus.$emit('clickRow', obj);
        },
        onShowContext(e, el) {
            Bus.$emit('onShowContextMenu', {e: e, el: el, refs: 'menu'});
        },
        clickRemove(obj) {
            Bus.$emit('deletePps', obj);
        },
        emitter(value) {
            this.$emit("input", value);
        },
        onChange() {
            this.realValue.forEach(item => {
                item.id_parent = this.id
            });
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        realValue() {
            return this.value ? this.value : this.list;
        },

    },
}
</script>

<style scoped lang="scss">
@import 'resources/sass/variables';

.handle {
    cursor: move;
    color: $primary;
    text-align: center;
}

.ghost {
    opacity: 0.3;
    background: #c8ebfb;
}

.item-container {
    margin: 0;
}

.item {
    border: solid #D9D9D9FF 1px;
    cursor: pointer;
    margin: 0;

    &.razdel {
        background: linear-gradient(to bottom, #d9d9d9 0%, #cbcbcb 100%);
        padding: 5px 0;
    }

    &.not-visible {
        opacity: 0.5;
    }

    &:hover {
        background-color: lighten($primary, 20%);
    }

    &.ru {
        font-weight: bold;
        text-align: center !important;
        background-color: lighten($primary, 50%);
    }
}

.is-auto {
    /*font-weight: bold;*/
    /*text-align: center!important;*/
    background-color: lighten($success, 20%);
}

</style>
