<template>
    <div style="height: 100%" @click="context.close()">
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12">

                    <button type="button" class="btn btn-sm btn-primary" @click="clickSwView" :class="{active:isViewTable}">просмотр</button>
                    <button type="button" class="btn btn-sm btn-danger" @click="clickResetTable">сброс</button>

                    <div class="card-body" @click="context.close()">
                        <div v-if="!isViewTable">
                            <nested-specification class="item-sub" v-model="local.table" :local="local"/>
                        </div>
                        <div v-else>
                            <button type="button" class="btn btn-sm btn-primary mb-3" @click="clickRefreshView">обновить</button>
                            <nested-specification-view class="item-sub" v-model="local.tableV" :local="local"/>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <modal-so-f-element-base modal-id="modalSoFElementBase" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-f-element modal-id="modalSoFElement" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-f-panel modal-id="modalSoFPanel" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-f-cable modal-id="modalSoFCable" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-f-cable-fr modal-id="modalSoFCableFR" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-f-light modal-id="modalSoFLight" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-f-potr modal-id="modalSoFPotr" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-f-other modal-id="modalSoFOther" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-f-import modal-id="modalSoFImport" :a-w="local.AW" :list-project="listProject" :marka="'element'"/>
        <modal-so-f-block modal-id="modalSoFBlock" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so modal-id="modalSo" :a-w="local.AW" :list-category="listCategory" :marka="'element'"/>
        <modal-so-info modal-id="modalSoInfo" :p-local="local" :prod-obj="{}" :marka="'element'"/>
        <context-menu-my ref="menu" :items="items" id-menu="sp"/>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import nestedList from "./sub/nestedList";
import {contextMenuTools, eTable, page, toolM} from "./sub/tools";
import ModalBase from "./sub/ModalBase";
import nestedSpecification from "./sub/nestedSpecification";
import ContextMenuMy from "./sub/ContextMenuMy";
import ModalSo from "./sub/ModalSo";
import Specification1 from "./specification1.vue";
import Specification0 from "./specification0.vue";
import EPotr0 from "./EPotr0.vue";
import modalSoInfo from "./sub/ModalSoInfo.vue";
import Marka_eAvt from "./marka_eAvt.vue";
import Marka_eDop from "./marka_eDop.vue";
import Marka_ePotr from "./marka_ePotr.vue";
import Marka_eSection from "./marka_eSection.vue";
import Marka_ePanel from "./marka_ePanel.vue";
import Marka_eVykl from "./marka_eVykl.vue";
import ModalSoFPanel from "./sub/ModalSoFPanel.vue";
import ModalSoFCable from "./sub/ModalSoFCable.vue";
import ModalSoFSimple from "./sub/ModalSoFSimple.vue";
import ModalSoFElement from "./sub/ModalSoFElement.vue";
import uniqid from "uniqid";
import ModalSoFElementBase from "./sub/ModalSoFElementBase.vue";
import ModalSoFBlock from "./sub/ModalSoFBlock.vue";
import NestedSpecificationView from "./sub/nestedSpecificationView.vue";
import ModalSoFLight from "./sub/ModalSoFLight.vue";
import ModalSoFPotr from "./sub/ModalSoFPotr.vue";
import ModalSoFOther from "./sub/ModalSoFOther.vue";
import ModalSoFImport from "./sub/ModalSoFImport.vue";
import ModalSoFCableFr from "./sub/ModalSoFCableFR.vue";

export default {
    name: "page-specification",
    components: {
        ModalSoFCableFr,
        ModalSoFImport,
        ModalSoFOther,
        ModalSoFPotr,
        ModalSoFLight,
        NestedSpecificationView,
        ModalSoFBlock,
        ModalSoFElementBase,
        Marka_eVykl,
        Marka_ePanel,
        Marka_eSection,
        Marka_ePotr,
        Marka_eDop,
        Marka_eAvt,
        modalSoInfo, EPotr0, Specification1, Multiselect, nestedList, ModalBase, nestedSpecification, ContextMenuMy, ModalSo, ModalSoFPanel, ModalSoFCable,
        ModalSoFSimple, ModalSoFElement, Specification0
    },
    props: ['table', 'soSetting', 'listCategory', 'listProject'],
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            eTable: new eTable(this),
            context: new contextMenuTools(this),
            nbi: {},
            local: {
                selectArr: [],
                saveArr: [],
                table: this.table,
                soSetting: this.soSetting,
                AW: ['root0'],
                listCategory: this.listCategory,
                listProject: this.listProject,
                insertObj: {},
                tableV: [],
            },
            status: {
                loading: false,
            },
            state: {
                version: 3,
                activeTab: 0,
                activeRowIndex: 0,
                activeRow: {},
                activeCategory: {},
                search: {
                    val: ''
                },
            },
            items: [
                {name: 'сделать копию', link: 'dublRow'},
                {
                    name: 'вставить', child: [
                        {name: 'элемент из базы', link: 'onInsertElementBase'},
                        {name: 'блок', link: 'onInsertBlock'},
                        // {name: 'раздел', link: 'onInsertRazdel'},
                        {name: 'элемент', link: 'onInsertElement'},
                    ]
                },
                {name: 'переключить видимость', link: 'onToggleVisible'},
                {name: '---'},
                {name: 'импорт из другого проекта', link: 'onImportSo'},
            ],
            isMounted: false,
            currentAW: 0,
            isViewTable: false,
        }
    },
    //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    created: function () {
        Bus.$on('keyUp', e => {
            if (this.isAW) {
                if (!this.isViewTable) {
                    if (e.key === 'f2') this.showModal();
                    if (e.key === 'down') {
                        this.eTable.keyDownArrowWithChild();
                        e.evt.preventDefault();
                    }
                    if (e.key === 'up') {
                        this.eTable.keyUpArrowWithChild();
                        e.evt.preventDefault();
                    }
                    if (e.key === 'esc') Bus.$emit('clickBody');
                    if (e.key === 'insert') this.showModalI();
                    if (e.key === 'ctrlDel') this.clickDeleteItem();
                    if (e.key === 'ctrl]') this.showModalF();
                }
                if (e.key === 'ctrlS') this.clickSave();
                if (e.key === 'exportAutocad') this.onExportAutocad();
                if (e.key === 'exportAutocadWs') this.pageT.clickAutocadWS('/export_autocadWs');
            }
        });
        Bus.$on('navbar_info', obj => {
            this.nbi = obj;
        });
        Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
        Bus.$on('dotSelect', () => this.eTable.onDotSelect());
        Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
        Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        Bus.$on('onShowContextMenu', (obj) => {
            if (this.$refs[obj.refs] === undefined) return;
            this.$refs[obj.refs].open(obj.e, obj.el)
        });
        Bus.$on('contextMenu_sp', (o) => {
            if (o.link === 'dublRow') this.dublRow(o.obj);
            if (o.link === 'onInsertElement') this.showModalI();
            if (o.link === 'onInsertElementBase') this.clickInsertElementBase();
            if (o.link === 'onInsertBlock') this.clickInsertBlock();
            if (o.link === 'onImportSo') this.onImportSo(o.obj);
            if (o.link === 'onImportSo') this.onImportSo(o.obj);
            if (o.link === 'onToggleVisible') this.onToggleVisible(o.obj);
        });
        Bus.$on('clickRow', (obj) => this.eTable.clickRow(obj));
        Bus.$on('setAW', (obj) => this.setAW(obj));
        Bus.$on('removeAW', (obj) => this.removeAW(obj));
        Bus.$on('onInsertEl', (obj) => this.clickInsertElement(obj));
        Bus.$on('importSo', (obj) => this.onImportSoBus(obj));
    },

    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {save: true, export: true, reload: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.pageT.loadLS();
        this.state.activeRow = this.local.table[0];
        this.local.selectArr = [];
        if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
        this.isMounted = true;
        $(document).on('show.bs.modal', '.modal', function () {
            const zIndex = 1040 + 10 * $('.modal:visible').length;
            $(this).css('z-index', zIndex);
            setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
        });
        setTimeout(() => this.clickTab(this.state.activeTab));
        // setTimeout(() => this.onImportSo());
    },
    //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    methods: {
        onToggleVisible(obj) {
            let isVisible = !obj.isVisible;
            this.onToggleVisibleR(obj, isVisible);
        },
        onToggleVisibleR(obj, isVisible) {
            obj.isVisible = isVisible;
            obj.child.forEach(item => {
                this.onToggleVisibleR(item, isVisible);
            })
        },
        onImportSoBus(obj) {
            let oo = JSON.parse(JSON.stringify(obj));
            this.updateUid(oo);
            let ch = this.getChildForId(this.lastSelectObj.id_parent, this.local.table);
            if (ch) ch.push(oo);
        },
        onImportSo(obj) {
            Bus.$emit('show_modalSoFImport', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
        },
        clickSwView() {
            this.isViewTable = !this.isViewTable;
            if (this.isViewTable) this.clickRefreshView();
        },
        clickRefreshView() {
            this.pageT.clickSave('/specification/get_view', {table: this.local.tableV, obj: this.lastSelectObj}, '_get_view');
            Bus.$once('zResponse_get_view', response => {
                this.local.tableV.length = 0;
                response.table.forEach(item => this.local.tableV.push(item));
            });
        },
        dublRow(o) {
            let oo = JSON.parse(JSON.stringify(o));
            this.updateUid(oo);
            let ch = this.getChildForId(oo.id_parent, this.local.table);
            if (ch) ch.push(oo);
        },
        getChildForId(id, arr) {
            let ch = null;
            arr.forEach(item => {
                if (!ch)
                    if (item.id === id)
                        ch = item.child;
                    else
                        ch = this.getChildForId(id, item.child);
            })
            return ch;
        },
        updateUid(o) {
            let uid = uniqid();
            o.id = uid;
            o.id_parent_gen = null;
            o.isDelete = false;
            o.child.forEach(item => {
                item.id_parent = uid;
                item.id_parent_gen = null;
                item.isDelete = false;
                this.updateUid(item);
            })
        },
        dStart() {
            this.isMove = true;
        },
        dStop() {
            this.isMove = false;
        },
        showModalF() {
            if (this.local.selectArr.length === 0) return;
            if (this.lastSelectObj.t === 'element')
                Bus.$emit('show_modalSoFElement', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            else if (this.lastSelectObj.t === 'block')
                Bus.$emit('show_modalSoFBlock', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            else if (this.lastSelectObj.t === 'block_cable')
                Bus.$emit('show_modalSoFCable', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            else if (this.lastSelectObj.t === 'block_cable_fr')
                Bus.$emit('show_modalSoFCableFR', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            else if (this.lastSelectObj.t === 'block_light')
                Bus.$emit('show_modalSoFLight', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            else if (this.lastSelectObj.t === 'block_potr')
                Bus.$emit('show_modalSoFPotr', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            else if (this.lastSelectObj.t === 'block_other')
                Bus.$emit('show_modalSoFOther', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            else if (this.lastSelectObj.t === 'block_panel')
                Bus.$emit('show_modalSoFPanel', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            else if (this.lastSelectObj.t === 'element_base')
                Bus.$emit('show_modalSoFElementBase', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
        },
        showModal() {
            if (this.local.selectArr.length === 0) return;
            if (this.lastSelectObj.t !== 'element') {
                this.showModalF();
                return;
            }
            Bus.$emit('show_modalSo', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
        },
        clickInsertElement(element) {
            this.pageT.clickSave('/specification/insert_item', {table: this.local.table, obj: this.local.insertObj, element: element}, '_insert_item');
            Bus.$once('zResponse_insert_item', response => {
                this.local.saveArr.length = 0;
                this.local.selectArr.length = 0;
                this.local.table.length = 0;
                response.table.forEach(item => this.local.table.push(item));
            });

        },
        clickInsertElementBase() {
            this.pageT.clickSave('/specification/insert_element_base', {table: this.local.table, obj: this.lastSelectObj}, '_insert_element_base');
            Bus.$once('zResponse_insert_element_base', response => {
                this.local.saveArr.length = 0;
                this.local.selectArr.length = 0;
                this.local.table.length = 0;
                response.table.forEach(item => this.local.table.push(item));
            });

        },
        clickInsertBlock() {
            this.pageT.clickSave('/specification/insert_block', {table: this.local.table, obj: this.lastSelectObj}, '_insert_block');
            Bus.$once('zResponse_insert_block', response => {
                this.local.saveArr.length = 0;
                this.local.selectArr.length = 0;
                this.local.table.length = 0;
                response.table.forEach(item => this.local.table.push(item));
            });

        },
        clickResetTable() {
            document.location.href = '/specification/reset';
        },
        clickDeleteItem() {
            this.local.selectArr.forEach(item => {
                item.isDelete = true;
            })
        },
        clickTab(ind) {
            this.state.activeTab = ind;
            Bus.$emit('setAW', 'root' + ind);
            Bus.$emit('clickTab', ind);
            this.pageT.updLS();
        },
        setAW(obj) {
            this.removeAW(obj);
            this.local.AW.unshift(obj);
        },
        removeAW(obj) {
            let index = this.local.AW.findIndex(item => item === obj);
            if (index > -1) this.local.AW.splice(index, 1);
        },
        showModalI() {
            if (this.local.selectArr.length === 0) return;
            this.local.insertObj = JSON.parse(JSON.stringify(this.lastSelectObj));
            this.local.selectArr.push(this.local.insertObj);

            Bus.$emit('showI_modalSo', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
        },
        onHide(obj) {
            obj.is_visible = !obj.is_visible;
        },
        onHideAll(obj) {
            let parent = obj;
            if (!obj.isRu) parent = this.local.table.find(item => item.id === obj.id_parent);

            parent.is_visible = !parent.is_visible;
            parent.child.forEach(item => {
                item.is_visible = parent.is_visible;
            })
        },
        clickDot(type) {
            if (type === 'select') Bus.$emit('dotSelect')
            else if (type === 'un_select') Bus.$emit('dotUnSelect')
            else if (type === 'inverse') Bus.$emit('dotInverse')
        },
        init() {

        },
        clickSave() {
            if (this.isViewTable) this.clickSaveV();
            else this.clickSaveM();
        },
        clickSaveM() {
            this.pageT.clickSave('/specification/save', {table: this.local.table, soSetting: this.local.soSetting}, '_save');
            Bus.$once('zResponse_save', response => {
                this.local.saveArr.length = 0;
                this.local.selectArr.length = 0;
                this.local.table = response.table;
            });
        },
        clickSaveV() {
            this.pageT.clickSave('/specification/save_view', {table: this.local.tableV}, '_save_view');
            Bus.$once('zResponse_save_view', response => {
                this.local.saveArr.length = 0;
                this.local.selectArr.length = 0;
                this.local.tableV = response.table;
            });
        },
        onExportAutocad() {
            document.location.href = '/pages/' + this.nbi.projectInfo.id + '/export_autocad';
        },
    },
    computed: {
        isAW: function () {
            return this.local.AW !== undefined && this.local.AW[0] === 'root' + this.currentAW;
        },
        fTable: function () {
            if (this.state.search.val === '') return this.local.table;
            return this.local.table.filter(item => {
                let res = true;
                this.state.search.val.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
    },
    watch: {
        'fTable': {
            handler: function () {
                this.eTable.clearSelectArr();
            },
            deep: false
        },
    },
    filters: {},
    directives: {}
}
</script>

<style lang="scss" scoped>
@import '../../sass/variables';

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.row {
    padding: 0;
    margin: 0.1rem 0;

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        text-align: center;
        padding: 0;
    }

    .zag {
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        border: 1px solid $primary;
        background-color: lighten($primary, 20%);
    }
}

.row-w {
    border-bottom: 1px solid #959595;
    cursor: pointer;
}

span.small {
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    font-style: italic;
}

table.table.in_td {
    /*td{*/
    /*    text-align: center;*/
    /*    vertical-align: middle;*/
    /*}*/
    th {
        border: none;
        background: none;
        padding: 0;
    }

    tr {
        border: none;
        background: none;
        height: 0;

        &.hover:hover {
            background-color: lighten($primary, 20%);
        }
    }

    tbody tr:first-child {
        td {
            border: none;
        }
    }

    .bg-primary-l {
        background-color: lighten($primary, 40);
    }

    .bg-success-l {
        background-color: lighten($success, 40);
    }
}

</style>
