<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount">
        <div class="modal-dialog modal-lg" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span v-if="state.isEdit">редактировать N</span>
                    <span v-else>вставить потребитель</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="row my-0">
                        <div class="col-6 pr-1" @click="lock.name=false">
                            <span class="small">название <font-awesome-icon v-if="lock.name" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.name"
                                ref="ms2"
                                v-model="state.obj.name"
                                placeholder="name"
                                :options="local.listName.map(item=>item.name)"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms2','name')"
                                :blockKeys="['Delete']"
                                @tag="addNewItem2"
                                :taggable="true"
                            >
                            </multiselect>
                        </div>
                        <div class="col-6 px-1" @click="lock.cable_id=false">
                            <span class="small">кабель <font-awesome-icon v-if="lock.cable_id" icon="fa-solid fa-lock"/></span>
                            <!--//todo-code-vue multiselect obj-->
                            <multiselect
                                ref="ms3"
                                :disabled="lock.cable_id"
                                v-model="state.obj.cable_base"
                                placeholder="марка кабеля"
                                :options="local.listBaseCable"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @input="onChange2"
                                @open="onOpen2('ms3','marka')"
                                :internal-search="false"
                                @search-change="customSearch2"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ props.option.marka }}
                                                            </span>
                                                        </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.marka }}</span>
                                        <br>
                                        <span class="option__small small">{{ props.option.brand }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6 pr-1" @click="lock.avt1=false">
                            <span class="small">автомат 1 <font-awesome-icon v-if="lock.avt1" icon="fa-solid fa-lock"/></span>
                            <!--//todo-code-vue multiselect obj-->
                            <multiselect
                                ref="ms4"
                                :disabled="lock.avt1"
                                v-model="state.obj.avt1_base"
                                placeholder="марка автомата"
                                :options="local.listBaseAvt"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @input="onChange3"
                                @open="onOpen3('ms4',0)"
                                :internal-search="false"
                                @search-change="customSearch3"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ props.option.marka }}
                                                            </span>
                                                        </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.marka }}</span>
                                        <br>
                                        <span class="option__small small">{{ props.option.brand }} {{ props.option.fields.icn ?? 0 }}кА</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-6 px-1" @click="lock.avt2=false">
                            <span class="small">автомат 2 <font-awesome-icon v-if="lock.avt2" icon="fa-solid fa-lock"/></span>
                            <!--//todo-code-vue multiselect obj-->
                            <multiselect
                                ref="ms5"
                                :disabled="lock.avt2"
                                v-model="state.obj.avt2_base"
                                placeholder="марка аппарата"
                                :options="local.listBaseAvt"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @input="onChange4"
                                @open="onOpen3('ms5',1)"
                                :internal-search="false"
                                @search-change="customSearch3"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ props.option.marka }}
                                                            </span>
                                                        </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.marka }}</span>
                                        <br>
                                        <span class="option__small small">{{ props.option.brand }} {{ props.option.fields.icn ?? 0 }}кА</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2 pl-4">
                            <span v-if="state.obj.es.faz===3" @click="clickFaz(1)" style="cursor: pointer;">
                                <font-awesome-icon icon="fa-solid fa-3" size="lg" style="margin-top: 2rem;"/><span class="ml-1">фазы</span>
                            </span>
                            <span v-else @click="clickFaz(3)" style="cursor: pointer;">
                                <font-awesome-icon icon="fa-solid fa-1" size="lg" style="margin-top: 2rem;"/><span class="ml-1">фаза</span>
                            </span>
                            <font-awesome-icon v-if="lock.faz" icon="fa-solid fa-lock"/>
                        </div>

                    </div>
                    <div class="row mb-0">
                        <div class="col-3 pr-1" @click="lock.cable_so=false" title="отображать кабели в спецификации">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox" v-model="state.obj.lock.cable_so"><i></i>скрывать в СО</label>
                            <font-awesome-icon v-if="lock.cable_so" icon="fa-solid fa-lock"/>
                        </div>

                        <div class="col-2 pl-1">
                            <label class="w-100">
                                <span class="small">количество </span>
                                <span class="w-100" title="количество спаренных кабелей">
                                    <input class="form-control" v-model.number="state.obj.cable.number" placeholder="1"/>
                                </span>
                            </label>
                        </div>

                        <div class="col-2 pl-1">
                            <label class="w-100">
                                <span class="small">коэф. длина </span>
                                <span class="w-100" title="количество спаренных кабелей">
                                    <input class="form-control" v-model.number="state.obj.cable.kl" placeholder="1"/>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex flex-row justify-content-end">
                    <div>
                        <!--                        <button v-if="state.isEdit" type="button" class="btn btn-default" @click="clickDelete">удалить</button>-->
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickLocalSave">сохранить</button>
                        <button v-else-if="!!lastSelectObj" type="button" class="btn btn-primary" @click="clickInsert">вставить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {modalDraggable, page} from "./tools";
import EInput from "./EInput";


export default {
    name: 'modal-section-n',
    components: {EInput, Multiselect},
    props: {
        modalId: {type: String, default: 'modal',},
        pLocal: {type: Object, default: {},},
        isGroupedItem: {type: Boolean, default: false,},
    },
    data: function () {
        return {
            //todo js modal add page module
            pageT: new page(this),
            rand: 1,
            local: {
                ePanel: this.pLocal.ePanel,
                listBaseCable: this.pLocal.listBaseCable,
                listBaseAvt: this.pLocal.listBaseAvt,
                listName: [],
                listDd: [],
                listOtm: [],
            },
            state: {
                obj: null,
                isEdit: true,
            },
            status: {
                loading: false,
            },
            templateObj: {
                name: '',
                es: {faz: 1,},
                panel: {},
                cable: {material: '', number: '', core: '', sec: '', series: '', marka: '', id: ''},
                lock: {cable_so: true},
                cable_base: {id: ''},
                avt: [
                    {id: '', marka: '', name: ''},
                    {id: '', marka: '', name: ''},
                ],
                avt1_base: {},
                avt2_base: {},
                marka_new: {
                    id: '',
                    name: '',
                    marka: '',
                }
            },
            lock: {
                faz: true,
                name: true,
                cable_series: true,
                cable_id: true,
                is_kz: true,
                cable_so: true,
                number: true,
                avt1: true,
                avt2: true,
            },
            isMount: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlEnter') this.clickLocalSave();
        });
        Bus.$on('show_' + this.modalId, () => {
            this.state.isEdit = true;
            this.showModal()
        });
        Bus.$on('showI_' + this.modalId, () => {
            this.state.isEdit = false;
            this.showModal();
        });
    },
    mounted() {
        new modalDraggable(this);
        this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
        this.isMount = true;
    },
    methods: {
        //todo js multiselect custom search
        customSearch3(query) {
            let i = 0;
            this.local.listBaseAvt = this.pLocal.listBaseAvt.filter(item => {
                if (i > 9) return false;
                let res = true;
                if (query !== '') {
                    query.split(' ').forEach(value => {
                        let s = item.marka + item.brand;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                }
                if (res) i++;
                return res;
            })
        },
        //todo js multiselect custom search
        customSearch2(query) {
            let i = 0;
            this.local.listBaseCable = this.pLocal.listBaseCable.filter(item => {
                if (i > 9) return false;
                let res = true;
                if (query !== '') {
                    query.split(' ').forEach(value => {
                        let s = item.marka + item.brand;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                }
                if (res) i++;
                return res;
            })
        },
        onOpen3(r, f) {
            setTimeout(() => {
                this.$refs[r].$refs.search.value = this.state.obj.avt[f].marka;
            }, 10);
        },
        onOpen2(r, f) {
            setTimeout(() => {
                this.$refs[r].$refs.search.value = this.state.obj.marka_new[f];
            }, 10);
        },
        onChange2(e) {
            this.state.obj.marka_new.id = e.uid;
            this.state.obj.marka_new.marka = e.marka;
            this.state.obj.marka_new.name = e.name;
        },
        onChange3(e) {
            this.state.obj.avt[0].id = e.uid;
            this.state.obj.avt[0].marka = e.marka;
            this.state.obj.avt[0].name = e.name;
        },
        onChange4(e) {
            this.state.obj.avt[1].id = e.uid;
            this.state.obj.avt[1].marka = e.marka;
            this.state.obj.avt[1].name = e.name;
        },
        clickInsert() {

        },
        setLock(val) {
            Object.keys(this.lock).forEach(item => this.lock[item] = val);
        },
        clickFaz(f) {
            this.state.obj.es.faz = f;
            this.lock.faz = false;
        },
        showModal() {
            this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
            if (this.lastSelectObj) {
                this.setLock(false);
                if (this.isGroupedItem) {
                    let child = JSON.parse(JSON.stringify(this.lastSelectObj.child));
                    this.setObj(this.state.obj, child[0]);
                    this.state.obj.child = child;
                } else {
                    this.setObj(this.state.obj, this.lastSelectObj);
                }

                this.state.obj.marka_new = this.lastSelectObj.marka_new;
                this.state.obj.name = this.lastSelectObj.name;
                this.state.obj.cable = this.lastSelectObj.cable;
                this.state.obj.lock = this.lastSelectObj.lock;
                this.state.obj.cable_base = this.local.listBaseCable.find(item => item.uid === this.lastSelectObj.marka_new.id);
                this.state.obj.avt = this.lastSelectObj.avt;
                this.state.obj.avt1_base = this.local.listBaseAvt.find(item => item.uid === this.lastSelectObj.avt[0].id);
                this.state.obj.avt2_base = this.local.listBaseAvt.find(item => item.uid === this.lastSelectObj.avt[1].id);


                //todo js поиск уникальных значений
                // this.local.listName = this.local.ePanel.map(item => item.dop.dlina).filter((value, index, self) => self.indexOf(value) === index);
            }
            this.setLock(this.pLocal.selectArr.length > 1);
            $('#' + this.modalId).modal('show');
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    if (this.$refs.v_focus) this.$refs.v_focus.focus()
                }, 200);
            }
        },
        clickLocalSave() {
            this.pLocal.selectArr.forEach(item => {
                if (this.isGroupedItem) {
                    item.child.forEach(item2 => {
                        this.setObj(item2, this.state.obj);
                    })
                } else {
                    this.setObj(item, this.state.obj);
                }
            })
            this.closeModal();
        },
        setObj(obj, iObj) {
            if (!this.lock.name) obj.name = iObj.name;
            if (!this.lock.faz) obj.es.faz = iObj.es.faz;
            if (!this.lock.cable_series) obj.cable.series = iObj.cable.series;
            if (!this.lock.cable_so) obj.lock.cable_so = iObj.lock.cable_so;
            if (!this.lock.number) obj.cable.number = iObj.cable.number;
            if (!this.lock.is_kz) obj.cable.is_kz = iObj.cable.is_kz;
            if (!this.lock.cable_id) {
                obj.marka_new.id = iObj.marka_new.id;
                obj.marka_new.name = iObj.marka_new.name;
                obj.marka_new.marka = iObj.marka_new.marka;
            }
            if (!this.lock.avt1) {
                obj.avt[0].id = iObj.avt[0].id;
                obj.avt[0].marka = iObj.avt[0].marka;
                obj.avt[0].name = iObj.avt[0].name;
            }
            if (!this.lock.avt2) {
                obj.avt[1].id = iObj.avt[1].id;
                obj.avt[1].marka = iObj.avt[1].marka;
                obj.avt[1].name = iObj.avt[1].name;
            }
        },
        addNewItem2(newTag) {
            this.state.obj.name = newTag;
            this.local.listName.push(newTag);
        },
        onOpen(r, f) {
            setTimeout(() => {
                this.$refs[r].$refs.search.value = this.state.obj[f];
            }, 10);
        },
        onChange() {
            if (!this.state.obj) this.state.isEdit = false;
        },
        closeModal() {
            this.$emit('updInit');
            $('#' + this.modalId).modal('hide');
        },
        //todo js multiselect custom search
        customSearch(query) {
            if (query === '') {
                this.local.fListImportPotr = this.pLocal.fListImportPotr;
            }
            let i = 0;
            this.local.fListImportPotr = this.pLocal.fListImportPotr.filter(item => {
                // if (i > 10) return false;
                let res = true;
                query.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                if (res) i++;
                return res;
            })
        },
    },
    computed: {
        lastSelectObj: function () {
            if (this.pLocal.selectArr.length === 0) return null;
            return this.pLocal.selectArr[this.pLocal.selectArr.length - 1];
        }
    },
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}

ul.multiselect__content {
    width: 100% !important;
}

input.lock {
    background-color: lighten($primary, 50%);
}

</style>
