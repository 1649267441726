<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount">
        <div class="modal-dialog modal-lg" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span v-if="state.isEdit">редактировать</span>
                    <span v-else>вставить потребитель</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="row my-0">
                        <div class="col-6 pr-1" @click="lock.name=false">
                            <span class="small">название <font-awesome-icon v-if="lock.name2" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.name2"
                                ref="ms2"
                                v-model="state.obj.name2"
                                placeholder="name2"
                                :options="local.listPanelName2.map(item=>item.name2)"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms2','name2')"
                                :blockKeys="['Delete']"
                                @tag="addNewItem2"
                                :taggable="true"
                            >
                            </multiselect>
                        </div>
                        <div class="col-6 pl-1" @click="lock.name1=false">
                            <span class="small">функциональная группа <font-awesome-icon v-if="lock.name1" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.name1"
                                ref="ms1"
                                v-model="state.obj.name1"
                                placeholder="name1"
                                :options="local.listName1"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms1','name1')"
                                :blockKeys="['Delete']"
                                @tag="addNewItem1"
                                :taggable="true"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-7 pr-1" @click="lock.method=false">
                            <span class="small">методика расчёта <font-awesome-icon v-if="lock.method" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                ref="ms3"
                                v-model="state.obj.dop.method"
                                placeholder="методика расчёта"
                                :options="pLocal.listMethod"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="false"
                                :close-on-select="true"
                                :allow-empty="false"
                                @input="onChange"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                        <br>
                                        <div class="option__small small">{{ props.option.desc }}</div>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-1 text-right" @click="lock.fix=false" title="фиксированные значения">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox" v-model="state.obj.fix.isFix"><i></i></label>
                            <font-awesome-icon v-if="lock.fix" icon="fa-solid fa-lock"/>
                        </div>
                        <div class="col-2 px-1">
                            <label class="w-100">
                                <span class="small">Рр, кВт </span>
                                <span class="w-100" title="Фиксированная расчётная мощность, кВт">
                                    <input class="form-control" v-model.number="state.obj.fix.pr" placeholder="Рр, кВт" :class="{lock:lock.fix}" @keyup="lock.fix=false"
                                           :disabled="!state.obj.fix.isFix"/>
                                </span>
                            </label>
                        </div>
                        <div class="col-2 pl-1">
                            <label class="w-100">
                                <span class="small">Кс </span>
                                <span class="w-100" title="Фиксированный коэффициент спроса">
                                    <input class="form-control" v-model.number="state.obj.fix.ks" placeholder="Кс" :class="{lock:lock.fix}" @keyup="lock.fix=false"
                                           :disabled="!state.obj.fix.isFix"/>
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="row mt-0">
                        <div class="col-2 pl-4">
                            <span v-if="state.obj.es.faz===3" @click="clickFaz(1)" style="cursor: pointer;">
                                <font-awesome-icon icon="fa-solid fa-3" size="lg" style="margin-top: 2rem;"/><span class="ml-1">фазы</span>
                            </span>
                            <span v-else @click="clickFaz(3)" style="cursor: pointer;">
                                <font-awesome-icon icon="fa-solid fa-1" size="lg" style="margin-top: 2rem;"/><span class="ml-1">фаза</span>
                            </span>
                            <font-awesome-icon v-if="lock.faz" icon="fa-solid fa-lock"/>
                        </div>
                        <div class="col-2 pr-1">
                            <label class="w-100">
                                <span class="small">отм., мм </span>
                                <span class="w-100" title="Отметка установки относительно уровня маркера, мм">
                                    <input class="form-control" v-model.number="state.obj.dop.otm" placeholder="отм., мм" ref="v_focus" :class="{lock:lock.otm}" @keyup="lock.otm=false"/>
                                    <font-awesome-icon v-if="lock.otm" icon="fa-solid fa-lock" class="right"/>
                                </span>
                                <span v-for="e in local.listOtm" class="small tag" @click="state.obj.dop.otm=e">{{ e }} </span>
                            </label>
                        </div>
                        <div class="col-2 px-1">
                            <e-input :lock="lock" :k="'dd'" :list-tag="local.listDd" v-model="state.obj.dop.dd" :label="'запас, мм'" :title="'Запас кабеля, мм'"/>
                        </div>
                        <div class="col-2 px-1">
                            <e-input :lock="lock" :k="'dlina'" :list-tag="local.listDlina" v-model="state.obj.dop.dlina" :label="'длина, мм'" :title="'Фиксированная длин кабеля, мм'"/>
                        </div>
                        <div class="col-4 pl-1" @click="lock.app=false">
                            <span class="small">назначение щита <font-awesome-icon v-if="lock.app" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                ref="ms3"
                                v-model="state.obj.dop.app"
                                placeholder="назначение щита"
                                :options="pLocal.listApp"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="false"
                                :close-on-select="true"
                                :allow-empty="false"
                                @input="onChange"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                        <br>
                                        <div class="option__small small">{{ props.option.desc }}</div>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="row mb-0">
                        <div class="col-3 pr-1" @click="lock.room_is_hide=false" title="скрывать названия помещений">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox" v-model="state.obj.room.isHide"><i></i>помещения</label>
                            <font-awesome-icon v-if="lock.room_is_hide" icon="fa-solid fa-lock"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex flex-row justify-content-end">
                    <div>
                        <!--                        <button v-if="state.isEdit" type="button" class="btn btn-default" @click="clickDelete">удалить</button>-->
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickLocalSave">сохранить</button>
                        <button v-else-if="!!lastSelectObj" type="button" class="btn btn-primary" @click="clickInsert">вставить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {modalDraggable, page} from "./tools";
import EInput from "./EInput";


export default {
    name: 'modal-panel',
    components: {EInput, Multiselect},
    props: {
        modalId: {type: String, default: 'modal',},
        pLocal: {type: Object, default: {},},
        isGroupedItem: {type: Boolean, default: false,},
    },
    data: function () {
        return {
            //todo js modal add page module
            pageT: new page(this),
            rand: 1,
            local: {
                listPanelName2: this.pLocal.listPanelName2,
                listName1: [],
                listDd: [],
                listOtm: [],
            },
            state: {
                obj: null,
                isEdit: true,
            },
            status: {
                loading: false,
            },
            templateObj: {
                name1: '',
                name2: '',
                fix: {isFix: false, pu: 0, ks: 0},
                dop: {dd: 0, dlina: 0, otm: 0, method: {}, app: {}},
                es: {faz: 1,},
                room: {isHide: false},
                panel: {},
            },
            lock: {
                otm: true,
                dd: true,
                dlina: true,
                faz: true,
                name1: true,
                name2: true,
                fix: true,
                method: true,
                app: true,
                room_is_hide: true,
            },
            isMount: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlEnter') this.clickLocalSave();
        });
        Bus.$on('show_' + this.modalId, () => {
            this.state.isEdit = true;
            this.showModal()
        });
        Bus.$on('showI_' + this.modalId, () => {
            this.state.isEdit = false;
            this.showModal();
        });
    },
    mounted() {
        new modalDraggable(this);
        this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
        this.isMount = true;
    },
    methods: {
        clickTag(e, val, k) {
            val = e;
            this.lock[k] = false;
        },
        clickInsert() {

        },
        setLock(val) {
            Object.keys(this.lock).forEach(item => this.lock[item] = val);
        },
        clickFaz(f) {
            this.state.obj.es.faz = f;
            this.lock.faz = false;
        },
        showModal() {
            this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
            if (this.lastSelectObj) {
                this.setLock(false);
                if (this.isGroupedItem) {
                    let child = JSON.parse(JSON.stringify(this.lastSelectObj.child));
                    this.setObj(this.state.obj, child[0]);
                    this.state.obj.child = child;
                } else {
                    this.setObj(this.state.obj, this.lastSelectObj);
                }

                this.state.obj.name1 = this.lastSelectObj.name1;
                this.state.obj.name2 = this.lastSelectObj.name2;
                this.state.obj.fix = JSON.parse(JSON.stringify(this.lastSelectObj.fix));
                this.state.obj.dop.method = JSON.parse(JSON.stringify(this.lastSelectObj.dop.method));
                this.state.obj.dop.app = JSON.parse(JSON.stringify(this.lastSelectObj.dop.app));

                //todo js поиск уникальных значений
                this.local.listName1 = this.pLocal.table.map(item => item.name1).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listDd = this.pLocal.table.map(item => item.dop.dd).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listDlina = this.pLocal.table.map(item => item.dop.dlina).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listOtm = this.pLocal.table.map(item => item.dop.otm).filter((value, index, self) => self.indexOf(value) === index);

                this.local.listName1 = this.correctFilter(this.local.listName1);
                this.local.listDd = this.correctFilter(this.local.listDd);
                this.local.listDlina = this.correctFilter(this.local.listDlina);
                this.local.listOtm = this.correctFilter(this.local.listOtm);

            }
            this.setLock(this.pLocal.selectArr.length > 1);
            $('#' + this.modalId).modal('show');
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    if (this.$refs.v_focus) this.$refs.v_focus.focus()
                }, 200);
            }
        },
        correctFilter(arr) {
            if (!!arr[0] || arr[0] === null) arr = [];
            return arr;
        },
        clickLocalSave() {
            this.pLocal.selectArr.forEach(item => {
                if (this.isGroupedItem) {
                    item.child.forEach(item2 => {
                        this.setObj(item2, this.state.obj);
                    })
                } else {
                    this.setObj(item, this.state.obj);
                }
            })
            this.closeModal();
        },
        setObj(obj, iObj) {
            if (!this.lock.name1) obj.name1 = iObj.name1;
            if (!this.lock.name2) obj.name2 = iObj.name2;
            if (!this.lock.method) obj.dop.method = JSON.parse(JSON.stringify(iObj.dop.method));
            if (!this.lock.app) obj.dop.app = JSON.parse(JSON.stringify(iObj.dop.app));
            if (!this.lock.fix) obj.fix = JSON.parse(JSON.stringify(iObj.fix));
            if (!this.lock.dd) obj.dop.dd = iObj.dop.dd;
            if (!this.lock.dlina) obj.dop.dlina = iObj.dop.dlina;
            if (!this.lock.otm) obj.dop.otm = iObj.dop.otm;
            if (!this.lock.faz) obj.es.faz = iObj.es.faz;
            if (!this.lock.room_is_hide) obj.room.isHide = iObj.room.isHide;
        },
        addNewItem1(newTag) {
            this.state.obj.name1 = newTag;
            this.local.listName1.push(newTag);
        },
        addNewItem2(newTag) {
            this.state.obj.name2 = newTag;
            this.local.listPanelName2.push(newTag);
        },
        onOpen(r, f) {
            setTimeout(() => {
                this.$refs[r].$refs.search.value = this.state.obj[f];
            }, 10);
        },
        onChange() {
            if (!this.state.obj) this.state.isEdit = false;
        },
        closeModal() {
            this.$emit('updInit');
            $('#' + this.modalId).modal('hide');
        },
        //todo js multiselect custom search
        customSearch(query) {
            if (query === '') {
                this.local.fListImportPotr = this.pLocal.fListImportPotr;
            }
            let i = 0;
            this.local.fListImportPotr = this.pLocal.fListImportPotr.filter(item => {
                // if (i > 10) return false;
                let res = true;
                query.split(' ').forEach(value => {
                    let s = item.name2;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                if (res) i++;
                return res;
            })
        },
    },
    computed: {
        lastSelectObj: function () {
            if (this.pLocal.selectArr.length === 0) return null;
            return this.pLocal.selectArr[this.pLocal.selectArr.length - 1];
        }
    },
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}

ul.multiselect__content {
    width: 100% !important;
}

input.lock {
    background-color: lighten($primary, 50%);
}

</style>
