<template>
    <span>
       <span @click="inputVal.isVisible=!inputVal.isVisible" style="cursor: pointer" class="mr-2" title="Видимость">
           <font-awesome-icon v-if="inputVal.isVisible" icon="fa-regular fa-eye"/>
           <font-awesome-icon style="opacity: 0.5" v-else icon="fa-regular fa-eye-slash"/>
       </span>
        <span v-if="isRazdel" @click="inputVal.isRazdel=!inputVal.isRazdel" style="cursor: pointer" class="mr-2" title="Блок является разделом">
           <span v-if="inputVal.isRazdel">Раздел > </span>
           <span v-else style="text-decoration: line-through; opacity: 0.5">Раздел > </span>
       </span>
    </span>
</template>
<script>
import uniqid from "uniqid";

export default {
    name: 'c-title-so',
    props: {
        value: {type: Object, default: {isRazdel: false, isVisible: false}},
        isRazdel: {type: Boolean, default: false},
    },
    methods: {},
    computed: {
        inputVal: {
            get() {
                return this.value ?? {isRazdel: false, isVisible: false};
            },
            set(val) {
                this.$emit('input', this.value);
            }
        }
    }
}
</script>
