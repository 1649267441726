<template>
    <div :id="modalId" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    редактирование участников
                </div>
                <div class="modal-body">
                    <multiselect
                        v-model="state.modal.fio"
                        :options="local.listFio"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        :allow-empty="true"
                        placeholder="фио"
                        tag-placeholder="создать новую"
                        @tag="addNew"
                        :taggable="true"
                        id="mindex"
                        label="name"
                        track-by="id"
                    >
                    </multiselect>
                    <div v-if="state.modal.fio.id">
                        <label class="mb-0 mt-3">
                            <span class="small">ФИО</span>
                        </label>
                        <input class="form-control" v-model="state.modal.fio.name" placeholder="фио">
                        <div class="row mt-3 justify-content-around">
                            <template v-for="val in [1,2,3,4,5]">
                                <div class="col-2 border m-0 p-0"
                                     :class="{active:state.modal.fio.prop.default===val}"
                                     @click="state.modal.fio.prop.default=val"
                                >
                                    <img :src="'/project/fio/sign/'+state.modal.fio.id+'/'+val+'?rand='+rand" @error="replaceImgByClear" class="w-100" :ref="'sign_'+val">
                                </div>
                            </template>
                        </div>
                        <div class="row">
                            <div class="col-12 small">
                                Для подписи использются изображения PNG с прозрачным фоном. Поле в штампе имеет размеры 5х15мм, т.е. соотношение сторон 1:3. Оптимальная ширина изображения для
                                подписи в штампе 60px, соотношение сторон не важно. Можно загружать больших размеров, просто все они будут уменьшаться до 60px при вставке в штамп.
                                Можно загрузить до 5ти разных подписей, и выбрать ту, которая будет использоваться по умолчанию.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <vue-dropzone
                                    ref="signDZ"
                                    id="signDZ1"
                                    :options="optionsDZ"
                                    :useCustomSlot=true
                                    @vdropzone-success="successDZ"
                                    @vdropzone-sending="addParamDZ"
                                >
                                    <div class="dropzone-custom-content">
                                        <h3 class="dropzone-custom-title">Перетащите сюда файл .PNG</h3>
                                        <div class="subtitle">подпись в формате .PNG с прозрачным фоном</div>
                                    </div>
                                </vue-dropzone>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <div>
                        <button v-if="state.modal.fio.id" type="button" class="btn btn-default text-danger" data-dismiss="modal" @click="clickDel">удалить</button>
                    </div>
                    <div>
                        <button v-if="state.modal.fio.id" type="button" class="btn btn-primary" data-dismiss="modal" @click="save">сохранить</button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">отмена</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';

    export default {
        name: 'modal-fio-sign',
        components: {Multiselect, vueDropzone: vue2Dropzone},
        props: {
            listFio: {type: Array, default: [],},
            modalId: {type: String, default: 'myModal',}
        },
        data: function () {
            return {
                rand: 1,
                local: {
                    listFio: this.listFio,
                },
                state: {
                    modal: {fio: {}}
                },
                optionsDZ: {
                    url: '/project/fio/upload_sign',
                    thumbnailWidth: 50,
                    maxFilesize: 1000,
                    headers: {'X-CSRF-TOKEN': document.head.querySelector("[name=csrf-token]").content,},
                    acceptedFiles: '.png',
                    maxFiles: 1,
                    init: function () {
                        this.on("maxfilesexceeded", function (file) {
                            this.removeAllFiles();
                            this.addFile(file);
                        });
                    }
                },
            }
        },
        created: function () {
            Bus.$on('updListFio', obj => this.local.listFio = obj);
        },
        methods: {
            successDZ(file, response) {
                if (!response.error) {
                    this.flash(response.name + ' успешно загружен', 'success', {timeout: 2000});
                    let def = this.state.modal.fio.prop.default;
                    let name = 'sign_' + def;
                    this.$refs[name].src = '/project/fio/sign/' + this.state.modal.fio.id + '/' + def + '?rand=' + this.rand;
                    this.rand = Date.now();
                } else {
                    this.flash(response.error, 'error');
                }
            },
            addParamDZ(file, xhr, formData) {
                formData.append('obj', JSON.stringify(this.state.modal));
            },
            replaceImgByClear(e) {
                e.target.src = '/img/clear.png';
            },
            addNew(name, index) {
                Bus.$emit('zPost', {
                    url: '/project/fio/add',
                    obj: {name: name},
                    flash: true,
                    suffix: '_add_new_fio_modal',
                });
                Bus.$once('zResponse_add_new_fio_modal', response => {
                    this.local.listFio = response.list;
                    Bus.$emit('updListFio', this.local.listFio);
                    this.state.modal.fio = this.local.listFio.find(item => item.id === response.id);
                });
            },
            save() {
                Bus.$emit('zPost', {
                    url: '/project/fio/save',
                    obj: {obj: this.state.modal},
                    flash: true,
                    suffix: '_save_fio_modal',
                });
                Bus.$once('zResponse_save_fio_modal', response => {
                    this.local.listFio = response.list;
                    Bus.$emit('updListFio', this.local.listFio);
                });
            },
            clickDel() {
                if (!window.confirm("Вы уверены что хотите удалить " + this.state.modal.fio.name + "?")) return false;
                Bus.$emit('zPost', {
                    url: '/project/fio/del/'+this.state.modal.fio.id,
                    obj: {},
                    flash: true,
                    suffix: '_del_fio_modal',
                });
                Bus.$once('zResponse_del_fio_modal', response => {
                    this.local.listFio = response.list;
                    Bus.$emit('updListFio', this.local.listFio);
                    this.state.modal.fio = {id: ''};
                });
            },
        }
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    .border {
        cursor: pointer;

        &.active {
            border: 2px solid black !important;
        }
    }
</style>
