<template>
    <div>
        <div class="container-fluid m1">

            <title-for-page :c-page="local.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="isEdit=!isEdit" :class="{active:isEdit}" title="редактирование текста">редактировать</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.isField=!state.isField" :class="{active:state.isField}">поля</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swImportW=!state.swImportW" :class="{active:state.swImportW}">импорт</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="local.cPage" :state="state" class="mb-3"/>

                </div>
            </div>
            <div class="row justify-content-center">

                <div :class="{'col-12':!state.swImportW,'col-xl-8':!state.swImportW,'col-6':state.swImportW}">
                    <div class="card">
                        <div class="card-body p-0" @click="isEdit=true">
                            <c-tiptap v-model="local.cPage.html" :is-edit="isEdit"/>
                        </div>
                    </div>
                </div>

                <import-fields v-show="state.swImportW && state.isField" class="col-6" modal-id="modalImportFields" :is-show="state.swImportW && state.isField" :c-page="local.cPage" :with-html="true"/>
                <import-div v-show="state.swImportW && !state.isField" class="col-6" modal-id="modalImportDiv" :is-show="state.swImportW && !state.isField" :is-for-text="true"/>

            </div>
            <!--            <div>{{this.local.cPage.html}}</div>-->
        </div>
    </div>
</template>

<script>
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import CTiptap from "./tipTap/CTiptap";
    import TitleForPage from "./sub/TitleForPage";
    import {page} from "./sub/tools";
    import CardOptions from "./sub/CardOptions";
    import importFields from "./sub/importFields";
    import importDiv from "./sub/importDiv";

    export default {
        name: "page-text-editor",
        components: {CardOptions, CTiptap, TitleForPage, importFields, importDiv},
        props: ['cPage'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                isEdit: false,
                state: {
                    version: 5,
                    swOnOpt: false,
                    swImportW: false,
                    isField: false,
                },
                status: {
                    save: false,
                    loading: false,
                },
                local: {
                    cPage: this.cPage,
                },
                currentTTBlock: {},
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.clickSave();
                if (e.key === 'download') this.pageT.clickDownload();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
            Bus.$on('changeTiptapContent', obj => {
                this.local.cPage.html = obj;
                $('#html_code').html(obj);
            });
            Bus.$on('delNode', (obj) => this.delNode(obj));
            Bus.$on('addFieldInPage', (obj) => this.local.cPage.data.fields.push(obj));
            Bus.$on('blockByPosition', obj => this.currentTTBlock = obj);
            Bus.$on('insertText', obj => this.onInsertText(obj));
            Bus.$on('replaceTextOnPage', obj => this.onReplaceTextOnPage(obj));
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, download: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            this.isMounted = true;
            Bus.$emit('updCurrentId', this.local.cPage);
        },
        methods: {
            onReplaceTextOnPage(obj) {
                this.pageT.clickSave('/import/replace_text_on_page_for_page', {obj: obj, currentObj: this.local.cPage}, '_replace_text_on_page_for_page', false)
                Bus.$on('zResponse_replace_text_on_page_for_page', response => {
                    this.local.cPage = response.page;
                    Bus.$emit('updTiptapContent', this.local.cPage.html);
                });
            },
            onInsertText(obj) {
                this.pageT.clickSave('/import/insert_text_for_page', {obj: obj, currentObj: this.local.cPage}, '_insert_text_for_page', false)
                Bus.$on('zResponse_insert_text_for_page', response => {
                    this.local.cPage = response.page;
                    Bus.$emit('updTiptapContent', this.local.cPage.html);
                });
            },
            clickSave() {
                this.pageT.clickSaveCPage();
                Bus.$once('zResponse_save_page', response => {
                    Bus.$emit('updTiptapContent', this.local.cPage.html);
                });
            },
            delNode(obj) {
                let id = obj.attrs.msId;
                //todo js DOMParser
                let parser = new DOMParser();
                let doc = parser.parseFromString(this.local.cPage.html, 'text/html');
                let el = doc.documentElement.querySelectorAll('span')
                el.forEach(item => {
                    if (item.getAttribute('data-ms-id') === id) item.remove();
                })
                this.local.cPage.html = doc.documentElement.querySelector('body').innerHTML;

                Bus.$emit('updTiptapContent', this.local.cPage.html);

                //todo js delete Array element
                let key = this.local.cPage.data.fields.findIndex(item => item.id === id);
                if (key < 0) return;
                this.local.cPage.data.fields.splice(key, 1);
            },
        },
        computed: {},
        watch: {
            'state.swOnOpt'(newVal, oldVal) {
                this.pageT.updCookie();
            },
            'state.swImportW'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

</style>
