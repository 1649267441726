var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _vm.editor && _vm.isEdit
        ? _c("div", { staticClass: "mb-3" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("heading", { level: 1 }) },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleHeading({ level: 1 }).run()
                  },
                },
              },
              [_vm._v("H1\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("heading", { level: 2 }) },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleHeading({ level: 2 }).run()
                  },
                },
              },
              [_vm._v("H2\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("heading", { level: 3 }) },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleHeading({ level: 3 }).run()
                  },
                },
              },
              [_vm._v("H3\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("paragraph") },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().setParagraph().run()
                  },
                },
              },
              [_vm._v("P\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("bold") },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleBold().run()
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "fa-solid fa-bold" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("italic") },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleItalic().run()
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "fa-solid fa-italic" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("strike") },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleStrike().run()
                  },
                },
              },
              [_vm._v("strike")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("highlight") },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleHighlight().run()
                  },
                },
              },
              [_vm._v("highlight")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive({ textAlign: "left" }) },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().setTextAlign("left").run()
                  },
                },
              },
              [_vm._v("left")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive({ textAlign: "center" }) },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().setTextAlign("center").run()
                  },
                },
              },
              [_vm._v("center")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive({ textAlign: "right" }) },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().setTextAlign("right").run()
                  },
                },
              },
              [_vm._v("right")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: {
                  active: _vm.editor.isActive({ textAlign: "justify" }),
                },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().setTextAlign("justify").run()
                  },
                },
              },
              [_vm._v("justify\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                class: { active: _vm.editor.isActive("bulletList") },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleBulletList().run()
                  },
                },
              },
              [_vm._v("bullet list")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                on: { click: _vm.clickHome },
              },
              [_vm._v("home")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary btn-sm",
                on: { click: _vm.clickEnd },
              },
              [_vm._v("end")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("editor-content", { attrs: { editor: _vm.editor } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }