<template>
    <div class="dropdown-menu" :style="style" :class="{context:!isChild}">
        <div v-for="(val,index) in items" :key="val+index">

            <div v-if="val.name==='---'" class="dropdown-divider"></div>
            <button v-else-if="!isHide(val)" class="dropdown-item" type="button" @click="clickItem(val.link)" :class="{submenu:val.child}" :disabled="isDisable(val)">{{val.name}}</button>

            <ContextMenuMy v-if="val.child" :items="val.child" :is-child="true" :obj-p="sObj" :id-menu="idMenu"/>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ContextMenuMy',
        props: {
            items: {required: true, type: Array, default: []},
            isChild: {required: false, type: Boolean, default: false},
            objP: {required: false, type: Object},
            idMenu: {required: false, type: String, default: 'menu'},
        },
        data: function () {
            return {
                left: 100,
                top: 100,
                show: false,
                obj: {},
            }
        },
        created: function () {
            Bus.$on('clickBody', () => this.close());
        },
        methods: {
            clickItem(link) {
                Bus.$emit('contextMenu_' + this.idMenu, {obj: this.sObj, link: link});
                this.close();
            },
            close() {
                this.show = false;
                this.left = 0;
                this.top = 0;
            },
            open(evt, obj) {
                this.obj = obj;
                this.left = evt.pageX || evt.clientX;
                this.top = (evt.pageY || evt.clientY) - window.pageYOffset;
                Vue.nextTick(() => this.$el.focus());
                this.show = true;
            },
            isDisable(item) {
                if (item.disable)
                    return item.disable(this.sObj);
                else
                    return false;
            },
            isHide(item) {
                if (item.hide)
                    return item.hide(this.sObj);
                else
                    return false;
            }
        },
        computed: {
            sObj() {
                if (this.objP) return this.objP;
                return this.obj;
            },
            style() {
                let res = {
                    top: this.top + 'px',
                    left: this.left + 'px',
                    display: this.show ? 'block' : 'none',
                };
                if (this.isChild) return {}; else return res;
            },
        },
    }
</script>
<style scoped lang="scss">

    .context {
        position: fixed;

        &:focus {
            outline: none;
        }
    }

    .dropdown-menu .dropdown-menu {
        top: auto;
        left: 100%;
        transform: translateY(-2rem);
    }

    .dropdown-item + .dropdown-menu {
        display: none;
    }

    .dropdown-item.submenu::after {
        content: '▸';
        margin-left: 0.5rem;
    }

    .dropdown-item:hover + .dropdown-menu,
    .dropdown-menu:hover {
        display: block;
    }

</style>
