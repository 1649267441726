<template>
    <div>
        <div class="container-fluid m1">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">
                    <h1 class="mb-0">Потребители</h1>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-12 col-xl-11">
                    <div class="row">
                        <div class="col-12">
                            <template v-for="val in listRazdel">
                                <button class="btn btn-sm" @click="clickRazdel(val)" :class="{active:val===state.currentRazdel}">{{ val }}</button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-12 col-xl-11">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(0)" :class="{active:state.activeTab===0}">Потребители</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(1)" :class="{active:state.activeTab===1}">По названию</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(2)" :class="{active:state.activeTab===2}">Все</a>
                        </li>

                        <li class="nav-item ml-auto">
                            <font-awesome-icon icon="fa-solid fa-circle" class="mr-2 mt-2" size="lg" @click="clickDot('select')" style="cursor: pointer;"/>
                            <font-awesome-icon icon="fa-regular fa-circle" class="mr-2 mt-2" size="lg" @click="clickDot('un_select')" style="cursor: pointer;"/>
                            <font-awesome-icon icon="fa-solid fa-circle-dot" class="mr-2 mt-2" size="lg" @click="clickDot('inverse')" style="cursor: pointer;"/>
                        </li>
                    </ul>

                    <div v-if="state.activeTab===0" class="card" style="border-top: none;">
                        <e-potr0 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>
                    <div v-if="state.activeTab===1" class="card" style="border-top: none;">
                        <e-potr1 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>
                    <div v-if="state.activeTab===2" class="card" style="border-top: none;">
                        <e-potr2 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>


                </div>

            </div>


        </div>

    </div>
</template>

<script>
import {page, toolM} from "./sub/tools";
import EPotr0 from "./EPotr0";
import EPotr1 from "./EPotr1";
import EPotr2 from "./EPotr2";

export default {
    name: "e-potr",
    components: {EPotr1, EPotr2, EPotr0,},
    props: ['eRoom', 'listImportPotr', 'listName3', 'listAvt', 'listKab', 'listRazdel'],
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            nbi: {},
            local: {
                eRoom: this.eRoom,
                ePotr: [],
                listImportPotr: this.listImportPotr,
                listName3: this.listName3,
                listAvt: this.listAvt,
                listKab: this.listKab,
                currentRoom: {},
            },
            status: {
                loading: false,
            },
            state: {
                version: 7,
                swImportW: false,
                isEdit: false,
                activeTab: 0,
                search: {
                    val: ''
                },
                currentRazdel: '',
            },
            isMounted: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
            if (e.key === 'ctrlS') this.clickSave();
            if (e.key === 'exportAutocad') this.onExportAutocad();
            if (e.key === 'exportAutocadWs') this.pageT.clickAutocadWS('/export_autocadWs');
        });
        Bus.$on('navbar_info', obj => this.nbi = obj);
        Bus.$on('removeRow', obj => this.onRemoveRow(obj));
        Bus.$on('addRow', () => this.onAddRow());
    },
    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {save: true, export: true, reload: true,}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.pageT.loadCookie();
        this.clickRazdel(this.state.currentRazdel);
        this.isMounted = true;
    },
    methods: {
        clickRazdel(razdel) {
            this.state.currentRazdel = razdel;
            this.pageT.clickSave('/potr/get_data', {razdel: this.state.currentRazdel}, '_get_data', false);
            Bus.$once('zResponse_get_data', response => {
                this.local.ePotr.length = 0;
                response.ePotr.forEach(item => this.local.ePotr.push(item));
                Bus.$emit('updTable');
                Bus.$emit('updSelectArr');
            });
        },
        clickDot(type) {
            if (type === 'select') Bus.$emit('dotSelect')
            else if (type === 'un_select') Bus.$emit('dotUnSelect')
            else if (type === 'inverse') Bus.$emit('dotInverse')
        },
        clickTab(i) {
            this.state.activeTab = i;
        },
        clickSave() {
            this.pageT.clickSave('/potr/save', {razdel: this.state.currentRazdel, ePotr: this.local.ePotr}, '_save');
            Bus.$once('zResponse_save', response => {
                this.local.ePotr.length = 0;
                response.ePotr.forEach(item => this.local.ePotr.push(item));
                Bus.$emit('updTable');
                Bus.$emit('updSelectArr');
            });
        },
        onExportAutocad() {
            document.location.href = '/pages/' + this.nbi.projectInfo.id + '/export_autocad';
        },
    },
    computed: {
        fExplList: function () {
            //todo js поиск уникальных значений
            return this.local.eRoom.map(item => item.marker.id).filter((value, index, self) => self.indexOf(value) === index);
        },
    },
    watch: {
        'state': {
            handler: function (newVal, oldVal) {
                this.pageT.updCookie();
            },
            deep: true
        },
    }

}
</script>


<style scoped lang="scss">
@import 'resources/sass/variables';

.nav-link {
    cursor: pointer;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
