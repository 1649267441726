<template>
    <draggable
        tag="div"
        class="item-container"
        :list="list"
        :value="value"
        @input="emitter"
        handle=".handle"
        animation="150"
        group="nested"
        :fallback-on-body="true"
        :swap-threshold="0.5"
        ghost-class="ghost"
        filter=".no-drag"
        @change="onChange"
        :scroll-sensitivity="100"
        :fallback-tolerance="1"
        :force-fallback="true"
    >
        <div v-for="el in realValue" class="item-group" :key="el.id">

            <div v-if="el.t==='element'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete,'not-visible':!el.isVisible}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 text-left pl-0" :class="{'bg-danger-l':el.isEmpty}">
                            {{ el.element.name }}
                            <span v-if="el.toRazdel" :title="el.toRazdel" class="font-weight-bold ml-2">R</span>
                        </div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">

                        <div class="col-3">{{ el.element.marka }}</div>
                        <div class="col-3">{{ el.element.article }}</div>
                        <div class="col-2">{{ el.element.brand }}</div>
                        <div class="col-1">{{ el.element.unit }}</div>
                        <div class="col-1" :class="{'bg-success-l':el.id_parent_gen && el.id_parent_gen!=='0'}"><span>{{ el.val.v_out }}</span><span v-if="el.val.resp.eMess" class="text-danger"
                                                                                                                                                     :title="el.val.resp.eMess"> error</span></div>
                        <div class="col-2" title="вес и примечание">{{ el.element.wt }}<br>{{ el.element.desc }}</div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='element_base'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete, 'not-visible':!el.isVisible}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)" style="background-color: #f6f3cb">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 text-left pl-0" :class="{'bg-danger-l':el.isEmpty}">
                            {{ el.element.name }}
                            <span v-if="el.toRazdel" :title="el.toRazdel" class="font-weight-bold ml-2">R</span>
                        </div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">

                        <div class="col-3">{{ el.element.marka }}</div>
                        <div class="col-3">{{ el.element.article }}</div>
                        <div class="col-2">{{ el.element.brand }}</div>
                        <div class="col-1">{{ el.element.unit }}</div>
                        <div class="col-1 bg-success-l"><span>{{ el.val.v_out }}</span><span v-if="el.val.resp.eMess" class="text-danger" :title="el.val.resp.eMess"> error</span></div>
                        <div class="col-2" title="вес и примечание">{{ el.element.wt }}<br>{{ el.element.desc }}</div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='block_panel'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete,'razdel':el.isRazdel,'not-visible':!el.isVisible}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 text-left font-weight-bold">{{ el.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">
                        <div class="col-12 text-left"><span v-if="el.dParam.sech">S <b>{{ el.dParam.sech.sech }}</b> h50 <b>{{ el.dParam.sech.h50 }}</b> h100 <b>{{
                                el.dParam.sech.h100
                            }}</b> модулей: <b>{{ el.dParam.dim.module }}</b>шт.</span></div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='block_cable' || el.t==='block_cable_fr'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete,'razdel':el.isRazdel,'not-visible':!el.isVisible}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 font-weight-bold text-left">{{ el.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">
                        <div class="col-12 text-left">
                            <span v-if="el.dParam.sech"><span class="ml-2" v-for="el2 in el.dParam.sech">{{ el2.name }} <span class="font-weight-bold">{{ el2.xy }}/{{ el2.all }}</span></span></span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='block_light'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete,'razdel':el.isRazdel,'not-visible':!el.isVisible}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 font-weight-bold text-left">{{ el.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">
                        <div class="col-12 text-left">
                            <span v-if="el.dParam.count">Распаечные коробки: <span class="font-weight-bold">{{ el.dParam.count }}</span>шт.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='block_potr'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete,'razdel':el.isRazdel,'not-visible':!el.isVisible}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 font-weight-bold text-left">{{ el.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">
                        <div class="col-12 text-left">
                            <span v-if="el.dParam.count">Распаечные коробки: <span class="font-weight-bold">{{ el.dParam.count }}</span>шт.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='block_other'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete,'razdel':el.isRazdel,'not-visible':!el.isVisible}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 font-weight-bold text-left">{{ el.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">
                        <div class="col-12 text-left">
                            <span v-if="el.dParam.count">Распаечные коробки: <span class="font-weight-bold">{{ el.dParam.count }}</span>шт.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='block'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete,'razdel':el.isRazdel,'not-visible':!el.isVisible}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 font-weight-bold text-left">{{ el.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">
                        <div class="col-12 text-left">
                            <span class="mr-3" v-for="el2 in el.v" :title="el2.name">{{ el2.id_f }} <span class="font-weight-bold">{{ el2.v_out }}</span></span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="el.t==='razdel'" class="item row"
                 :class="{'bg-primary-l':isSelect(el), 'bg-success-l':isActiveSave(el), 'bg-danger-l':el.isDelete}"
                 @contextmenu.prevent="onShowContext($event,el)"
                 @click.prevent="clickRow(el)">
                <div class="col-5 p-0">
                    <div class="row m-0">
                        <div class="col-1 handle">{{ getT(el) }}</div>
                        <div class="col-11 font-weight-bold text-left">{{ el.name }}</div>
                    </div>
                </div>
                <div class="col-7 p-0">
                    <div class="row m-0">
                        <div class="col-12 text-left">
                            <!--                            <span v-if="el.dParam.sech">-->
                            <!--                                <span class="ml-2" v-for="el2 in el.dParam.sech">{{ el2.name }} <span class="font-weight-bold">{{ el2.xy }}/{{ el2.all }}</span></span>-->
                            <!--                            </span>-->
                        </div>
                        <div class="col-2"></div>
                        <div class="col-1"></div>
                    </div>
                </div>
            </div>

            <nested-specification class="item-sub ml-3" :list="el.child" :level="level + 1" :id="el.id" :local="local" :suffix="suffix"/>
        </div>

    </draggable>
</template>

<script>

import draggable from 'vuedraggable'
import {eTable} from "./tools";

export default {
    name: "nested-specification",
    components: {draggable},
    props: {
        value: {
            required: false,
            type: Array,
            default: null
        },
        list: {
            required: false,
            type: Array,
            default: null
        },
        level: {
            type: Number,
            default: 1,
        },
        id: {
            required: false,
            type: String,
            default: '0'
        },
        suffix: {
            required: false,
            type: String,
            default: '0'
        },
        // nbi: {
        //     required: true,
        //     type: Object,
        //     default: {}
        // },
        local: {},
    },
    data: function () {
        return {
            isMove: true,
            oldValue: this.value,
            lValue: this.value,
        }
    },
    created: function () {

    },
    methods: {
        getT(el) {
            if (el.t === 'element') return 'el';
            else if (el.t === 'element_base') return 'eb';
            else if (el.t === 'block') return 'bl';
            else if (el.t === 'block_panel') return 'bp';
            else if (el.t === 'block_cable') return 'bc';
            else if (el.t === 'block_light') return 'bl';
        },
        isActiveSave(obj) {
            return this.local.saveArr.find(item => item.id === obj.id);
        },
        isSelect(obj) {
            let key = this.local.selectArr.findIndex(item => item.id === obj.id);
            return key >= 0;
        },
        clickRow(obj) {
            if(this.suffix==='0') Bus.$emit('clickRow', obj);
            else Bus.$emit('clickRow_'+this.suffix, obj);
        },
        onShowContext(e, el) {
            Bus.$emit('onShowContextMenu', {e: e, el: el, refs: 'menu'});
        },
        clickRemove(obj) {
            Bus.$emit('deletePps', obj);
        },
        emitter(value) {
            this.$emit("input", value);
        },
        onChange() {
            this.realValue.forEach(item => {
                item.id_parent = this.id
            });
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        realValue() {
            return this.value ? this.value : this.list;
        },

    },
}
</script>

<style scoped lang="scss">
@import 'resources/sass/variables';

.handle {
    cursor: move;
    color: $primary;
    text-align: center;
}

.ghost {
    opacity: 0.3;
    background: #c8ebfb;
}

.item-container {
    margin: 0;
}

.item {
    border: solid #D9D9D9FF 1px;
    cursor: pointer;
    margin: 0;

    &.razdel {
        background: linear-gradient(to bottom, #d9d9d9 0%, #cbcbcb 100%);
        padding: 5px 0;
    }

    &.not-visible {
        opacity: 0.5;
    }

    &:hover {
        background-color: lighten($primary, 20%);
    }

    &.ru {
        font-weight: bold;
        text-align: center !important;
        background-color: lighten($primary, 50%);
    }
}

.is-auto {
    /*font-weight: bold;*/
    /*text-align: center!important;*/
    background-color: lighten($success, 20%);
}

</style>
