<template>
    <div class="h-100">
        <div class="container-fluid m1">

            <title-for-page :c-page="local.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-11">

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt2=!state.swOnOpt2" :class="{active:state.swOnOpt2}" title="настройки страницы">настройки таблицы</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="local.cPage" :state="state" class="mb-3"/>

                    <div v-if="state.swOnOpt2" class="card mb-3">
                        <div class="card-header p-1 pl-2" @click="state.swOnOpt2 = !state.swOnOpt2" style="cursor: pointer">настройки таблицы</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-2">
                                    <label class="w-100 mt-2">
                                        <span class="small">{{cField.opt.colEmptyRow.desc}}</span>
                                        <input class="form-control" v-model.number="cField.opt.colEmptyRow.val" :placeholder="cField.opt.colEmptyRow.desc"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right">
                        <button class="btn btn-danger btn-sm" @click="clickReset">сброс</button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row header align-items-center">
                                <div class="col-1">Позиция</div>
                                <div class="col-5">Наименование</div>
                                <div class="col-1">Тип</div>
                                <div class="col-1">Код</div>
                                <div class="col-1">Поставщик</div>
                                <div class="col-1">Ед.изм.</div>
                                <div class="col-1">Кол-во</div>
                                <div class="col-1">Примечание</div>
                            </div>
                            <nested-so class="item-sub" v-model="cField.opt.table" :nbi="nbi"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <context-menu-my v-if="isMounted" ref="menu" :items="items"/>
    </div>
</template>

<script>
    import TitleForPage from "./sub/TitleForPage";
    import {page} from "./sub/tools";
    import CardOptions from "./sub/CardOptions";
    import nestedSo from "./sub/nestedSo";
    import ContextMenuMy from "./sub/ContextMenuMy";

    export default {
        name: "page-rashod",
        components: {CardOptions, TitleForPage, nestedSo, ContextMenuMy},
        props: ['cPage'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                state: {
                    version: 5,
                    swOnOpt: false,
                    swOnOpt2: false,
                },
                status: {
                    save: false,
                    loading: false,
                },
                local: {
                    cPage: this.cPage,
                },
                items: [
                    {name: 'переключить видимость', link: 'hide'},
                    {name: '---'},
                    {name: 'переключить видимость щита', link: 'hideRu'},
                ],
                nameCField: 'table_cj_0',
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.pageT.clickSaveCPage();
                if (e.key === 'download') this.pageT.clickDownload();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);

            Bus.$on('onShowContextMenu', obj => this.$refs[obj.refs].open(obj.e, obj.el));
            Bus.$on('contextMenu_menu', (o) => {
                if (o.link === 'hide') this.onHide(o.obj);
                if (o.link === 'hideRu') this.onHideAll(o.obj);
            });
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, download: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            if (this.cField === undefined) this.flash('ошибка содержимого страницы', 'error');
            else this.isMounted = true;
        },
        methods: {
            onHide(obj) {
                obj.isVisible = !obj.isVisible;
            },
            onHideAll(obj) {
                let parent = obj;
                if (!obj.isRu) parent = this.cField.opt.table.find(item => item.id === obj.parent_id);

                parent.isVisible = !parent.isVisible;
                parent.child.forEach(item => item.isVisible = parent.isVisible);
            },
            clickReset() {
                this.cField.opt.table = [];
                this.pageT.clickSaveCPage();
            },
        },
        computed: {
            cField: function () {
                return this.local.cPage.data.fields.find(item => item.idd === this.nameCField);
            },
        },
        watch: {
            'state.swOnOpt'(newVal, oldVal) {
                this.pageT.updCookie();
            },
            'state.swOnOpt2'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

    .row.header {
        font-weight: bold;
        text-align: center;
        border: 1px solid $primary;
        background-color: lighten($primary, 20%);
    }
</style>
