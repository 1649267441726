<template>
    <draggable
        tag="div"
        :list="list"
        :value="value"
        @input="emitter"
        animation="150"
        :fallback-on-body="true"
        :swap-threshold="0.5"
        :scroll-sensitivity="100"
        :fallback-tolerance="1"
        :force-fallback="true"
        ghost-class="ghost"
        group="as"
        class="item-container"
        :disabled="!pState.catEdit"
    >
        <div v-for="el in realValue" class="item-group">
            <div class="row m-0 item" :class="{active:el.id===pState.activeRow.id}" :key="el.id" @click="clickRow(el)">
                <div class="col-12">
                    <span class="ml-2">{{ el.name }} {{ el.id }}</span>
                </div>
            </div>
            <nested-category class="item-sub" :list="el.child" :id="el.id" :p-state="state"/>
        </div>
    </draggable>
</template>

<script>

    import draggable from 'vuedraggable'

    export default {
        name: "nested-category",
        components: {draggable},
        props: {
            value: {
                required: false,
                type: Array,
                default: null
            },
            list: {
                required: false,
                type: Array,
                default: null
            },
            id: {
                required: false,
                type: Number,
                default: 0
            },
            pState: {
                required: true,
                type: Object,
            },
        },
        data: function () {
            return {
                isMove: true,
                oldValue: this.value,
                lValue: this.value,
                state: this.pState,
            }
        },
        created: function () {
        },
        methods: {
            clickRow(obj) {
                if (this.pState.importCategoryFields.isEdit) {
                    this.pState.importCategoryFields.row = obj;
                } else {
                    this.pState.activeRow = obj;
                    this.pState.importCategoryFields.row = obj;
                    this.pState.propEdit = false;
                }
            },
            emitter(value) {
                this.$emit("input", value);
            },
        },
        computed: {
            realValue() {
                return this.value ? this.value : this.list;
            },
        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

    .ghost {
        opacity: 0.3;
        background: #c8ebfb;
    }

    .item-container {
        margin: 0;
        cursor: pointer;
    }

    .item-sub {
        margin: 0 0 0 1rem;
        cursor: pointer;
    }

    .item {
        &.active {
            background-color: lighten($primary, 40%);
        }

        &:hover {
            background-color: lighten($primary, 20%);
        }
    }


</style>
