<template>
    <div :id="modalId" class="h-100 ddiv" role="dialog">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-3">
                    <div class="w-100 mr-2" style="position: relative">
                        <input class="form-control w-100" v-model="search" ref="v_focus">
                        <font-awesome-icon icon="fa-solid fa-eraser" size="lg" @click="search=''" title="очистить"/>
                        <font-awesome-icon icon="fa-solid fa-arrow-right-arrow-left" size="lg" @click="clickSyncSearch"/>
                    </div>
                    <div>
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="lg" class="mt-2" style="cursor: pointer" @click="clickSearch"/>
                    </div>
                </div>

                <div v-if="this.local.table.length === 0" class="text-center p-5">
                    <font-awesome-icon v-if="true" icon="fa-solid fa-circle-notch" size="3x" class="mt-1 text-secondary" title="загрузка данных..." spin/>
                </div>

                <div>
                    <div v-for="(val,index) in filteredTable" :key="index" class="card my-1 myRow" :class="{selected:currentObj!==null && currentObj.id===val.id}">
                        <div class="card-body">

                            <div class="d-flex justify-content-start align-items-center">
                                <div class="nav-item dropdown m-0">
                                    <font-awesome-icon icon="fa-regular fa-square-caret-down" class="nav-link dropdown-toggle pl-0" data-toggle="dropdown" style="cursor: pointer;"/>
                                    <div class="dropdown-menu">
                                        <button class="dropdown-item" @click="clickMenu(val,'delPage')">удалить страницу</button>
                                    </div>
                                </div>
                                <a class="font-weight-bold" :href="val.link_content.link+'/'+nbi.authUser.prop.currentProjectId+'/'+val.id">{{val.stamp.list_name}}</a><br>
                                <div class="ml-auto">
                                    <font-awesome-icon v-if="currentObj!==null && currentObj.id===val.id" icon="fa-solid fa-circle-check" @click="clickCurrentId(null)"/>
                                    <font-awesome-icon v-else icon="fa-solid fa-circle" @click="clickCurrentId(val)"/>
                                </div>
                            </div>

                            <span class="btn" data-toggle="collapse" :data-target="'#collapse_'+val.id">показать</span>
                            <div class="collapse" :id="'collapse_'+val.id" v-html="val.html" :class="{show:!isFold(val)}"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import {page} from "./tools";


    export default {
        name: 'listAllPages',
        components: {Multiselect},
        props: {
            modalId: {type: String, default: 'importDiv',},
            pages: {type: Array, default: [],},
            nbi: {
                required: true, type: Object, default: {}
            },
        },
        data: function () {
            return {
                pageT: new page(this),
                rand: 1,
                local: {
                    // obj: this.cPage,
                    table: [],
                },
                state: {},
                status: {
                    loading: false,
                },
                search: '',
                listFoldPage: ['/f/table_trn', '/f/table_od', '/f/table_vrch', '/f/table_pps', '/f/table_vspd', '/f/table_so', '/f/table_st', '/f/table_pz'],
                currentObj: null,

            }
        },
        created: function () {
            // Bus.$on('show_' + this.modalId, () => this.showModal());
            Bus.$on('updTableLine', obj => this.local.table = this.convertThreeInLine(obj));
            Bus.$on('updTable', obj => this.local.table = this.convertThreeInLine(obj.table));

        },
        mounted() {
            this.local.table = this.convertThreeInLine(this.pages);
            import("../../../sass/_rtl.css");
        },
        methods: {
            clickCurrentId(val = null) {
                if (!val) this.currentObj = null; else this.currentObj = val;
                Bus.$emit('updCurrentId', val);
            },
            clickMenu(obj, ttype) {
                if (ttype === 'delPage') Bus.$emit('deletePage', obj);
            },
            clickSyncSearch() {
                Bus.$emit('syncSearchA', {search: this.search});
            },
            convertLineToThree(arr, parent_id = '0') {
                let res = [];
                arr.forEach(item => {
                    if (item.parent_id === parent_id) {
                        item.child = this.convertLineToThree(arr, item.id);
                        res.push(item);
                    }
                })

                return res;
            },
            convertThreeInLine(arr) {
                let res = [];
                arr.forEach(item => {
                    res.push(item);
                    item.child.forEach(item2 => {
                        res.push(item2);
                    })
                })
                return res;
            },
            isFold(obj) {
                // let o = this.listFoldPage.inArray(item => item === obj.obj.link_content);
                // return !!o;
                //todo js inArray
                return !!~this.listFoldPage.indexOf(obj.link_content.link);
            },
            // clickInsertBlock(obj) {
            //     let tt = Date.now();
            //     //todo js DOMParser
            //     let parser = new DOMParser();
            //     let doc = parser.parseFromString(obj.html, 'text/html');
            //     let el = doc.documentElement.querySelectorAll('span')
            //     el[0].setAttribute('data-ms-id', tt);
            //     let res = doc.documentElement.querySelector('body').innerHTML;
            //     Bus.$emit('insertTiptapBlock', res);
            //     let o={id: tt, name: obj.id, param: obj.param};
            //     console.log(o);
            //     Bus.$emit('addFieldInPage', o);
            // },
            clickSearch() {

            },
            // closeModal() {
            //     $('#' + this.modalId).modal('hide');
            // },
            // clickSave() {
            // },
        },
        computed: {
            filteredTable: function () {
                return this.local.table.filter(item => {
                    if (this.currentObj) return item.id === this.currentObj.id;
                    if (item.link_content.link === '/tp' && item.type_list === 'text') return false;
                    let res = true;
                    this.search.split(' ').forEach(value => {
                        let s = item.name + item.stamp.list_name + item.html;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                });
            },
        }
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-circle {
        cursor: pointer;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }

    svg.fa-circle-check {
        cursor: pointer;
        opacity: 1;
        color: $primary;

    }

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }

    svg.fa-arrow-right-arrow-left {
        cursor: pointer;
        position: absolute;
        right: 34px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }

    .myRow {
        &:hover {
            background-color: lighten($primary, 50%);
        }

        &.selected {
            background-color: lighten($primary, 40%);
            border: 1px solid $primary;
        }
    }

</style>
