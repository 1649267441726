<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <div class="mb-3">
                        <h1 class="mb-0">
                            Проекты
                            <span class="ml-5" title="задолженность">{{ fin_analysis.debt }}</span>
                            <span class="ml-5" title="за год">{{ fin_analysis.sum_Y }}</span> /
                            <span title="за квартал">{{ fin_analysis.sum_Q }}</span> /
                            <span title="за месяц">{{ fin_analysis.sum_M }}</span> /
                            <span title="за календарный месяц">{{ fin_analysis.sum_CM }}</span>
                        </h1>
                    </div>

                    <input class="form-control w-100 mb-3" v-model="search">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#open">открытые</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#close">закрытые</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane container active" id="open">
                            <table class="table table-sm">
                                <tr v-for="val in filteredOpenProject" :key="val.id" :class="{active:nbi.authUser.prop.currentProjectId===val.id}">
                                    <td class="w-2">
                                        <i class="fas fa-check" :class="{active:nbi.authUser.prop.currentProjectId===val.id}" @click="clickSetCurrentProject(val)"></i>
                                    </td>
                                    <td class="text-left text-nowrap">
                                        <a :href="'/project/'+val.id+'/show'" class="text-dark">{{ val.name }}</a>
                                    </td>
                                    <td>
                                        <template v-for="tt in val.tags">
                                            <span class="badge" :style="{backgroundColor: tt.color, color:invertHex(tt.color)}">{{ tt.name }}</span>
                                        </template>
                                    </td>
                                    <td>{{ val.stage }}</td>
                                    <td>{{ getRest(val.id) }}</td>
                                    <td>{{ customFormatter(val.start_date) }} - {{ customFormatter(val.stop_date) }}</td>
                                    <td class="text-nowrap">{{ val.organization.name }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="tab-pane container" id="close">
                            <table class="table table-sm">
                                <tr v-for="val in filteredCloseProject" :key="val.id" :class="{active:nbi.authUser.prop.currentProjectId===val.id}">
                                    <td class="w-2">
                                        <i class="fas fa-check" :class="{active:nbi.authUser.prop.currentProjectId===val.id}" @click="clickSetCurrentProject(val)"></i>
                                    </td>
                                    <td class="text-left text-nowrap"><a :href="'/project/'+val.id+'/show'" class="text-dark">{{ val.name }}</a></td>
                                    <td>
                                        <template v-for="tt in val.tag">
                                            <span class="badge" :style="{'background-color': tt.color}">{{ tt.name }}</span>
                                        </template>
                                    </td>
                                    <td>{{ val.stage }}</td>
                                    <td>{{ getRest(val.id) }}</td>
                                    <td>{{ customFormatter(val.start_date) }} - {{ customFormatter(val.stop_date) }}</td>
                                    <td class="text-nowrap">{{ val.organization.name }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import nestedList from "./sub/nestedList";
import moment from "moment";

export default {
    name: "page-project-list",
    components: {Multiselect, nestedList},
    props: ['table', 'finance', 'fin_analysis'],
    data: function () {
        return {
            search: '',
            listStatus: [
                {id: 0, name: 'закрытый'},
                {id: 1, name: 'открытый'},
            ],
            old: {
                username: 'qwe',
                pass: 'qwe',
            },
            pass: '',
            nbi: {
                projectInfo: {},
                authUser: {
                    prop: {
                        currentProjectId: 0,
                        loadProjectId: 0,
                    }
                }
            },
            errors: {
                pass: [''],
            },
            status: {
                reset: false,
                save: false,
                loading: false,
            },
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
        });
        Bus.$on('navbar_info', obj => this.nbi = obj);
    },
    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {reload: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
    },
    methods: {
        getRest(id) {
            let price = 0;
            let receipt = 0;
            this.finance.forEach(item => {
                if (item.project_id === id && item.t_event === 'price') price += parseFloat(item.amount);
                if (item.project_id === id && item.t_event === 'receipt') receipt += parseFloat(item.amount);
            })

            let diff = price - receipt;
            return diff === 0 ? '' : diff;
        },
        clickSetCurrentProject(obj) {
            this.setLoading(true);
            Bus.$emit('zPost', {
                url: '/set_current_project_id',
                obj: {id: obj.id},
                flash: true,
                suffix: '_set_current_project_id',
            });
            Bus.$once('zResponse_set_current_project_id', () => {
                document.location.reload();
            });
            Bus.$once('zError_set_current_project_id', () => {
                this.setLoading(false);
            });
        },
        customFormatter(date) {
            return moment(date).format('DD.MM.YYYY');
        },
        getStatus(obj) {
            return this.listStatus.find(item => item.id === obj.status).name;
        },
        invertHex(hex) {
            if (hex.indexOf('#') === 0) hex = hex.slice(1);
            return '#' + (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase();
        },
        setLoading(state) {
            this.status.loading = state;
            Bus.$emit('navbar', {command: 'setLoading', args: state});
        },
    },
    computed: {
        filteredOpenProject: function () {
            let i = 0;
            return this.table.filter(item => {
                // if (i > 10) return false;
                let res = true;
                this.search.split(' ').forEach(value => {
                    let s = item.name + item.organization.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                if (res) i++;
                return res && item.status === 1;
            })
        },
        filteredCloseProject: function () {
            let i = 0;
            return this.table.filter(item => {
                // if (i > 10) return false;
                let res = true;
                this.search.split(' ').forEach(value => {
                    let s = item.name + item.organization.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                if (res) i++;
                return res && item.status === 0;
            })
        }
    },
    watch: {},
    filters: {},
    directives: {}
}
</script>

<style lang="scss" scoped>
.fas.fa-check {
    opacity: 0.3;

    &:hover {
        cursor: pointer;
    }

    &.active {
        opacity: 1;
        color: green;
    }
}

tr.active {
    background-color: rgba(0, 250, 154, 0.2);
}
</style>
