<template>
    <div class="h-100">
        <div class="container-fluid m1" @click="clickBody">

            <title-for-page :c-page="local.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt2=!state.swOnOpt2" :class="{active:state.swOnOpt2}" title="настройки страницы">настройки таблицы</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="local.cPage" :state="state" class="mb-3"/>

                    <div v-if="state.swOnOpt2" class="card mb-3">
                        <div class="card-header p-1 pl-2" @click="state.swOnOpt2 = !state.swOnOpt2" style="cursor: pointer">настройки таблицы</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-2">
                                    <label class="w-100 mt-2">
                                        <span class="small">{{cField.opt.w1.desc}}</span>
                                        <input class="form-control" v-model.number="cField.opt.w1.val" :placeholder="cField.opt.w1.desc" @keyup="changeWidth"/>
                                    </label>
                                </div>
                                <div class="col-2">
                                    <label class="w-100 mt-2">
                                        <span class="small">{{cField.opt.w2.desc}}</span>
                                        <input class="form-control" v-model.number="cField.opt.w2.val" :placeholder="cField.opt.w2.desc" disabled/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right">
                        <button class="btn btn-primary btn-sm" @click="clickInsert">вставить</button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <nested-vspd class="item-sub" v-model="cField.opt.table" :nbi="nbi"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <modal-vspd v-if="isMounted" modal-id="modalVspd" :list-norms="sumTable" :table-c-page="cField.opt.table"/>
    </div>
</template>

<script>
    import TitleForPage from "./sub/TitleForPage";
    import {page} from "./sub/tools";
    import CardOptions from "./sub/CardOptions";
    import nestedVspd from "./sub/nestedVspd";
    import ModalVspd from "./sub/ModalVspd";


    export default {
        name: "page-text-vspd",
        components: {CardOptions, TitleForPage, nestedVspd, ModalVspd},
        props: ['cPage', 'norms', 'listNorms'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                state: {
                    version: 2,
                    swOnOpt: false,
                    swOnOpt2: false,
                },
                status: {
                    save: false,
                    loading: false,
                },
                local: {
                    cPage: this.cPage,
                    norms: this.norms,
                    listNorms: this.listNorms,
                },
                nameCField: 'table_vspd_0',
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.pageT.clickSaveCPage();
                if (e.key === 'download') this.pageT.clickDownload();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);

            Bus.$on('deleteVspd', (obj) => this.onDelete(obj));
            Bus.$on('updVspd', obj => this.onUpdNorms(obj));
            Bus.$on('addVspd', obj => this.onAdd(obj));
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, download: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            if (this.cField === undefined) this.flash('ошибка содержимого страницы', 'error');
            else this.isMounted = true;
        },
        methods: {
            clickInsert() {
                Bus.$emit('show_modalVspd', null);
            },
            onUpdNorms(obj) {
                this.local.listNorms = obj.table;
                let t = this.sumTable;
            },
            clickBody() {
                Bus.$emit('clickBody');
            },
            onDelete(obj) {
                let index = this.cField.opt.table.findIndex(item => item.id === obj.id);
                if (index !== undefined && index !== null) this.cField.opt.table.splice(index, 1);
            },
            onAdd(obj) {
                this.cField.opt.table.push(obj);
            },
            changeWidth(index) {
                let delta = 195 - this.cField.opt.w1.val;
                if (delta <= 0) delta = 0;
                this.cField.opt.w2.val = delta;

            },
        },
        computed: {
            sumTable: function () {
                let res = [];
                this.norms.forEach(item => {
                    item.id = item.id.toString();
                    item.type = 'norm';
                    res.push(item);
                });
                this.local.listNorms.forEach(item => {
                    item.type = 'local';
                    res.push(item);
                });
                res.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name === b.name) return 0;
                    if (a.name > b.name) return 1;
                });
                return res;
            },
            cField: function () {
                return this.local.cPage.data.fields.find(item => item.idd === this.nameCField);
            },
        },
        watch: {
            'state.swOnOpt'(newVal, oldVal) {
                this.pageT.updCookie();
            },
            'state.swOnOpt2'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

</style>
