<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-6 py-5" v-if="nbi.authUser">
                    <p class="mb-0 mt-5 font-weight-bold">сопоставить файл с проектом</p>
                    <multiselect
                        v-model="nbi.authUser.prop.loadProjectId"
                        :options="listProject.map(item => item.id)"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        :custom-label="opt => labelSearch(listProject,opt)"
                    />
                </div>
            </div>
            <div class="text-center">
                <button class="btn btn-primary" @click="clickSave"><i v-if="status.loading" class="fa fa-spinner fa-spin fa-fw"></i>сопоставить</button>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: {Multiselect},
        props: ['listProject'],
        data: function () {
            return {
                isLoading: false,
                nbi: {},
                newId: 0,
                currentId: 0,
                idd: '',
                status: {
                    loading: false,
                },
            }
        },
        created() {
            Bus.$on('navbar_info', obj => this.nbi = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
        },
        methods: {
            labelSearch(list, opt) {
                let obj = list.find(item => item.id === opt);
                if (obj === undefined) return '';
                return obj.name;
            },
            setLoading(state) {
                this.status.loading = state;
                Bus.$emit('navbar', {command: 'setLoading', args: state});
            },
            clickSave() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: this.nbi.url.current + '/save',
                    obj: {id: this.nbi.authUser.prop.loadProjectId},
                    suffix: '_save',
                });
                Bus.$on('zResponse_save', response => {
                    document.location.replace(this.nbi.url.prev);
                });
            },
        },
    }
</script>
