<template>
    <div v-if="isSetList" class="row justify-content-center mb-0">
        <div class="col-12 col-xl-8">
            <h1 class="mb-0">{{cPage.stamp.list_name}}</h1>
            <h6 class="mt-0" style="opacity: 0.5"><span v-if="cPage.stamp.list_num_global">л.{{cPage.stamp.list_num_global}}</span> {{getTypeList}} /
                <span v-if="lockLink || isSettingPage">{{cPage.link_content.name}}</span>
                <span v-else style="cursor: pointer; text-decoration: underline;" @click="clickSetting" title="изменить контент страницы">{{cPage.link_content.name}}</span>
            </h6>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'title-for-page',
        props: {
            cPage: {},
            isSettingPage: {type: Boolean, default: false, required: false,},
            lockLink: {type: Boolean, default: false, required: false,},
            nbi: {type: Object, default: {}, required: true,},
        },
        methods: {
            clickSetting() {
                if(!this.nbi.authUser) return;
                document.location.href = '/tp/setting/' + this.nbi.projectInfo.id + '/' + this.cPage.id;
            },
            clickGo() {
                if(!this.nbi.authUser) return;
                document.location.href = this.cPage.link_content.link + '/' + this.nbi.projectInfo.id + '/' + this.cPage.id;
            },
        },
        computed: {
            isSetList: function () {
                return !!this.cPage.id;
            },
            getTypeList: function () {
                let res = 'лист';
                if (this.cPage.type_list === 'cover') res = 'обложка';
                else if (this.cPage.type_list === 'title_page') res = 'титульный лист';
                else if (this.cPage.type_list === 'approval_sheet') res = 'лист согласований';
                else if (this.cPage.type_list === 'st') res = 'содержание тома';
                else if (this.cPage.type_list === 'spd') res = 'состав проектной документации';
                else if (this.cPage.type_list === 'text') res = 'текстовая часть';
                else if (this.cPage.type_list === 'dwg') res = 'чертёж';
                else if (this.cPage.type_list === 'annex') res = 'приложение';
                return res;
            },
            getLinkContent: function () {
                let res = '';
                let o = this.listLinkContent.find(item => item.link === this.cPage.link_content.link);
                if (o) res = o.name;
                return res;
            }
        }
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';
</style>
