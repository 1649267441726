var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "h-100" }, [
      _c("div", { staticClass: "container-fluid m1" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "text-center" }, [
              _c(
                "h1",
                {
                  staticClass: "font-weight-bold",
                  staticStyle: { "font-size": "3rem" },
                },
                [
                  _c("span", { staticStyle: { color: "green" } }, [
                    _vm._v("S"),
                  ]),
                  _vm._v("peka"),
                ]
              ),
              _vm._v(" "),
              _c("p", [
                _c("a", { attrs: { href: "/calc/kz" } }, [
                  _vm._v("расчёт токов короткого замыкания"),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("a", { attrs: { href: "/calc/term_cab" } }, [
                  _vm._v("проверка кабеля на термическую стойкость"),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("a", { attrs: { href: "/calc/zaz" } }, [
                  _vm._v("Расчёт сопротивления заземляющего устройства"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }