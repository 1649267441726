<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal">
        <div class="modal-dialog" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span v-if="state.isEdit">редактировать</span>
                    <span v-else>вставить документ</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">

                    <multiselect
                        v-model="state.obj"
                        placeholder="выберите сокращение"
                        :options="local.fTable"
                        :option-height="104"
                        :show-labels="false"
                        :searchable="true"
                        :close-on-select="true"
                        track-by="id"
                        :internal-search="false"
                        @search-change="customSearch"
                        tag-placeholder="создать новый"
                        @tag="addNewPps"
                        :taggable="true"
                        @input="onChange"
                    >
                        <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title">
                                                <font-awesome-layers class="handle">
                                                    <font-awesome-icon icon="fa-regular fa-file"/>
                                                    <font-awesome-icon icon="fa-solid fa-link" transform="shrink-6 down-1 right-1" style="color: #ee9b00"/>
                                                    <!--                            <font-awesome-layers-text class="gray8" transform="down-2 shrink-8" value="New!" style="color:#e2f506"/>-->
                                                </font-awesome-layers>
                                                {{ props.option.name }}
                                            </span>
                                        </span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                            <span v-if="props.option.name" class="option__title">
                                                <font-awesome-layers class="handle">
                                                    <font-awesome-icon icon="fa-regular fa-file"/>
                                                    <font-awesome-icon icon="fa-solid fa-link" transform="shrink-6 down-1 right-1" style="color: #ee9b00"/>
                                                    <!--                            <font-awesome-layers-text class="gray8" transform="down-2 shrink-8" value="New!" style="color:#e2f506"/>-->
                                                </font-awesome-layers>
                                                {{ props.option.name }}
                                            </span>
                                <span v-else class="option__title">
                                                {{props.search}}
                                            </span>
                                <br>
                                <span class="option__small small">
                                                {{ props.option.desc }}
                                            </span>
                            </div>
                        </template>
                    </multiselect>


                    <div v-if="!!state.obj && state.isEdit">
                        <label class="w-100">
                            <span class="small">сокращение</span>
                            <input class="form-control" v-model="state.obj.name" placeholder="сокращенное название" ref="v_focus"/>
                        </label>
                        <br>
                        <label class="w-100">
                            <span class="small">полное название</span>
                            <input class="form-control" v-model="state.obj.desc" placeholder="полное название"/>
                        </label>
                    </div>

                </div>
                <div class="modal-footer d-flex flex-row justify-content-between">
                    <div>
                        <label v-if="!!state.obj" class="apple-switch_2"><input type="checkbox" v-model="state.isEdit"><i></i>редактировать</label>
                    </div>
                    <div>
                        <button v-if="state.isEdit" type="button" class="btn btn-default" @click="clickDelete">удалить</button>
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickSave(null)">сохранить</button>
                        <button v-else-if="!!state.obj" type="button" class="btn btn-primary" @click="clickInsert">вставить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    //todo js modal modalDraggable
    import {modalDraggable, page} from "./tools";


    export default {
        name: 'ModalPps',
        components: {Multiselect},
        props: {
            modalId: {type: String, default: 'modal',},
            listPps: {
                type: Array, default: () => [],
                validator: (value) => {
                    return true;
                }
            },//todo js props
            // obj: {type: Object, default: () => ({})}, //todo
            tableCPage: {type: Array, default: [],},
        },
        data: function () {
            return {
                //todo js modal add page module
                pageT: new page(this),
                rand: 1,
                local: {
                    table: this.listPps,
                    fTable: this.listPps,
                },
                state: {
                    obj: null,
                    isEdit: false,
                },
                status: {
                    loading: false,
                },
                templateObj: {
                    name: '',
                    desc: '',
                }
            }
        },
        created: function () {
            Bus.$on('show_' + this.modalId, (obj) => this.showModal(obj));
        },
        mounted() {
            new modalDraggable(this);
        },
        methods: {
            onChange() {
                if (!this.state.obj) this.state.isEdit = false;
            },
            showModal(obj) {
                if (obj) this.state.obj = JSON.parse(JSON.stringify(obj));
                this.customSearch('');
                $('#' + this.modalId).modal('show');
                if (obj) setTimeout(() => this.$refs.v_focus.focus(), 500);
            },
            closeModal() {
                $('#' + this.modalId).modal('hide');
            },
            clickInsert() {
                Bus.$emit('addPps', this.state.obj);
                this.customSearch('');
                this.state.obj = null;
                $('#' + this.modalId).modal('hide');
            },
            clickSave(obj) {
                if (!obj) obj = this.state.obj;
                this.pageT.clickSave('/f/table_pps/save', {state: obj}, '_save_' + this.modalId);
                Bus.$once('zResponse_save_' + this.modalId, response => {
                    Bus.$emit('updPps', response);
                    Vue.nextTick(() => {
                        this.local.table = JSON.parse(JSON.stringify(this.listPps));
                        this.state.obj = this.local.table.find(item => item.id === response.id);
                        this.state.isEdit = true;
                        this.customSearch('');
                    })
                });
                // $('#' + this.modalId).modal('hide');
            },
            clickDelete() {
                this.pageT.clickSave('/f/table_pps/delete', {state: this.state.obj}, '_delete_' + this.modalId);
                Bus.$once('zResponse_delete_' + this.modalId, response => {
                    Bus.$emit('updPps', response);
                    Vue.nextTick(() => {
                        this.local.table = JSON.parse(JSON.stringify(this.listPps));
                        this.state.obj = null;
                        this.state.isEdit = false;
                        this.customSearch('');
                    })
                });
            },
            addNewPps(name, index) {
                let obj = this.templateObj;
                obj.name = name;
                this.clickSave(obj);
            },
            //todo js multiselect custom search
            customSearch(query) {
                if (query === '') {
                    this.local.fTable = this.local.table.filter(item => {
                        return !!!this.tableCPage.find(i2 => i2.id === item.id);
                    })
                }
                let i = 0;
                this.local.fTable = this.local.table.filter(item => {
                    // if (i > 10) return false;
                    let res = true;
                    query.split(' ').forEach(value => {
                        let desc = item.desc;
                        if (!desc) desc = '';
                        let s = item.name + desc;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    res = res && !!!this.tableCPage.find(i2 => i2.id === item.id);
                    if (res) i++;
                    return res;
                })
            },
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }
</style>
