var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "multiselect__select" }),
      _vm._v(" "),
      _c("div", { staticClass: "multiselect__tags" }, [
        _c("div", {
          staticClass: "multiselect__tags-wrap",
          staticStyle: { display: "none" },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "multiselect__spinner",
          staticStyle: { display: "none" },
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "multiselect__input",
          staticStyle: { width: "0px", position: "absolute", padding: "0px" },
          attrs: {
            name: "",
            type: "text",
            autocomplete: "nope",
            placeholder: "name2",
            tabindex: "2",
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "multiselect__single" }, [_vm._v("Котёл")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "multiselect__content-wrapper",
          staticStyle: { "max-height": "300px", "/*! display": "none" },
          attrs: { tabindex: "-1" },
        },
        [
          _c(
            "ul",
            {
              staticClass: "multiselect__content",
              staticStyle: { display: "inline-block" },
            },
            [
              _c("li", { staticClass: "multiselect__element" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "multiselect__option multiselect__option--highlight",
                    attrs: {
                      "data-select": "Press enter to select",
                      "data-selected": "Selected",
                      "data-deselect": "Press enter to remove",
                    },
                  },
                  [_c("span", [_vm._v("Вытяжка")])]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "multiselect__element" }, [
                _c(
                  "span",
                  {
                    staticClass: "multiselect__option",
                    attrs: {
                      "data-select": "Press enter to select",
                      "data-selected": "Selected",
                      "data-deselect": "Press enter to remove",
                    },
                  },
                  [_c("span", [_vm._v("Тёплый пол")])]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "multiselect__element" }, [
                _c(
                  "span",
                  {
                    staticClass: "multiselect__option",
                    attrs: {
                      "data-select": "Press enter to select",
                      "data-selected": "Selected",
                      "data-deselect": "Press enter to remove",
                    },
                  },
                  [_c("span", [_vm._v("Розетки")])]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { display: "none" } }, [
                _c("span", { staticClass: "multiselect__option" }, [
                  _vm._v(
                    "No elements found. Consider changing the search query."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { display: "none" } }, [
                _c("span", { staticClass: "multiselect__option" }, [
                  _vm._v("List is empty."),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }