<template>
    <div>
        <div class="container-fluid m1">

            <title-for-page :c-page="local.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}">настройки</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swImportW=!state.swImportW" :class="{active:state.swImportW}">переменые</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="clickShowModal(null)">добавить файл</button>
                                </div>
                                <div class="nav-item">
                                    <a v-if="!!cField.opt.attach.name" class="btn nav-link" :href="nbi.url.current+'/dl'">скачать оригинал</a>
                                </div>
                                <div v-if="!!cField.opt.attach.name" class="navbar-text font-weight-bold">шаблон: {{cField.opt.attach.name}}</div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="local.cPage" :state="state"/>

                </div>
            </div>

            <div v-if="isMounted" class="row justify-content-center">
                <variables-div :local="local.cPage.data" :class="{'col-12':!state.swImportW,'col-xl-8':!state.swImportW,'col-6':state.swImportW}"/>
                <import-fields v-show="state.swImportW" class="col-6" modal-id="modalImportFields" :is-show="state.swImportW"/>
            </div>

        </div>
        <modal-template-docx modal-id="modalAttach" url="/template_docx/upload" :acceptedFiles="'.docx'" :c-page="local.cPage"/>
    </div>
</template>

<script>
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import TitleForPage from "./sub/TitleForPage";
    import {page} from "./sub/tools";
    import CardOptions from "./sub/CardOptions";
    import uniqid from 'uniqid';
    import variablesDiv from "./sub/variablesDiv";
    import importFields from "./sub/importFields";
    import ModalTemplateDocx from "./sub/ModalTemplateDocx";

    export default {
        name: "page-template-docx",
        components: {TitleForPage, CardOptions, variablesDiv, importFields, ModalTemplateDocx},
        props: ['cPage'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                local: {
                    cPage: this.cPage,
                },
                status: {
                    loading: false,
                },
                state: {
                    version: 3,
                    swOnOpt: false,
                    swImportW: false,
                },
                nameCField: 't_docx_0',
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.pageT.clickSaveCPage();
                if (e.key === 'download') this.pageT.clickDownload();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
            Bus.$on('updCPage', obj => this.local.cPage = obj);
            Bus.$on('removeRow', (obj) => this.onRemoveRow(obj));
            Bus.$on('addRow', () => this.clickAddRow());
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, download: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            if (this.cField === undefined) this.flash('ошибка содержимого страницы', 'error');
            else this.isMounted = true;
        },
        methods: {
            clickShowModal(obj) {
                Bus.$emit('showModalAttachEdit', obj);
            },
            clickAddRow() {
                this.pageT.clickSave('/template_docx/add_row', {cPage: this.local.cPage}, '_add_row');
                Bus.$once('zResponse_add_row', response => {
                    this.local.cPage.data = response.cPage.data;
                });
            },
            onRemoveRow(obj) {
                this.pageT.clickSave('/template_docx/remove_row', {cPage: this.local.cPage, obj: obj}, '_remove_row');
                Bus.$once('zResponse_remove_row', response => {
                    this.local.cPage.data = response.cPage.data;
                });
            },
        },
        computed: {
            cField: function () {
                return this.local.cPage.data.fields.find(item => item.idd === this.nameCField);
            },
        },
        watch: {
            'state.swOnOpt'(newVal, oldVal) {
                this.pageT.updCookie();
            },
            'state.swImportW'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        }
    }
</script>

