var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._v(
              "\n                редактирование организаций\n                "
            ),
            _c("i", {
              staticClass: "far fa-question-circle mt-1",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  _vm.help = !_vm.help
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm.help
            ? _c("div", { staticClass: "modal-body" }, [
                _c("p", [_vm._v("Выберите организацию для редактирования.")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Каждая организация имеет название для внутреннего использования. Полное название для титульных листов и упоминаний в текстовой части. Короткое название которое прописывается\n                    в на месте логотипа в штампе, в случае если логотип отсутствует."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Для организации можно загрузить два логотипа, один для титульного листа, для верхней части. Второй для штампов."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    'Для создания новой организации просто наберите её название в поле и выберите "создать новую"'
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "В качестве логотипов лучше использовать PNG изображения с прозрачным фоном. Размер не более 1Мб"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Для логотипа титульного листа, оптимальная ширина составляет порядка 700px."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Фактический размер логотипа в штампе 15х50мм, оптимальная ширина составляет порядка 50х150px."
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.help
            ? _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.local.listOrganization,
                      searchable: true,
                      "close-on-select": true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: "организация",
                      "tag-placeholder": "создать новую",
                      taggable: true,
                      id: "mindex",
                      label: "name",
                      "track-by": "id",
                    },
                    on: { tag: _vm.addNew },
                    model: {
                      value: _vm.state.modal,
                      callback: function ($$v) {
                        _vm.$set(_vm.state, "modal", $$v)
                      },
                      expression: "state.modal",
                    },
                  }),
                  _vm._v(" "),
                  _vm.state.modal.id
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._m(0),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.state.modal.name,
                                  expression: "state.modal.name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { placeholder: "фио" },
                              domProps: { value: _vm.state.modal.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.state.modal,
                                    "name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.state.modal.prop.logo_text,
                                  expression: "state.modal.prop.logo_text",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { placeholder: "полное название" },
                              domProps: {
                                value: _vm.state.modal.prop.logo_text,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.state.modal.prop,
                                    "logo_text",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.state.modal.prop.full_name,
                              expression: "state.modal.prop.full_name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { placeholder: "полное название" },
                          domProps: { value: _vm.state.modal.prop.full_name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.state.modal.prop,
                                "full_name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-9" },
                            [
                              _c(
                                "vue-dropzone",
                                {
                                  staticStyle: { position: "relative" },
                                  attrs: {
                                    title: "логотип для титульного листа",
                                    id: "logo1",
                                    options: _vm.optionsLogo1,
                                    useCustomSlot: true,
                                  },
                                  on: {
                                    "vdropzone-success": _vm.successUpload1,
                                    "vdropzone-sending": _vm.addParam,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dropzone-custom-content" },
                                    [
                                      _c("img", {
                                        ref: "logo1",
                                        staticClass: "w-100",
                                        attrs: {
                                          src:
                                            "/project/org/logo/" +
                                            _vm.state.modal.id +
                                            "/1",
                                        },
                                        on: { error: _vm.replaceImgByClear },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-danger",
                                    staticStyle: {
                                      position: "absolute",
                                      right: "0",
                                      bottom: "0",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      icon: "fa-solid fa-xmark",
                                      size: "lg",
                                      title: "удалить логотип",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.clickDelLogo(1)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c(
                                "vue-dropzone",
                                {
                                  attrs: {
                                    title: "логотип в штампе",
                                    id: "logo2",
                                    options: _vm.optionsLogo2,
                                    useCustomSlot: true,
                                  },
                                  on: {
                                    "vdropzone-success": _vm.successUpload2,
                                    "vdropzone-sending": _vm.addParam,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dropzone-custom-content" },
                                    [
                                      _c("img", {
                                        ref: "logo2",
                                        staticClass: "w-100",
                                        attrs: {
                                          src:
                                            "/project/org/logo/" +
                                            _vm.state.modal.id +
                                            "/2",
                                        },
                                        on: { error: _vm.replaceImgByClear },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("font-awesome-icon", {
                                    staticClass: "text-danger",
                                    staticStyle: {
                                      position: "absolute",
                                      right: "0",
                                      bottom: "0",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      icon: "fa-solid fa-xmark",
                                      size: "lg",
                                      title: "удалить логотип",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.clickDelLogo(2)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer justify-content-between" }, [
            _c("div", [
              _vm.state.modal.id
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-default text-danger",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.clickDelOrg },
                    },
                    [_vm._v("удалить")]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.state.modal.id
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("сохранить")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("отмена")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0 mt-3" }, [
      _c("span", { staticClass: "small" }, [_vm._v("название")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0 mt-3" }, [
      _c(
        "span",
        {
          staticClass: "small",
          attrs: {
            title:
              "короткое название организации используется в штампах, в случае если нет логотипа",
          },
        },
        [_vm._v("короткое название организации")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c(
        "span",
        {
          staticClass: "small",
          attrs: {
            title:
              "полное название организации используется в тексте или на титульных листах",
          },
        },
        [_vm._v("полное название организации")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }