var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c(
          "div",
          { staticClass: "modal-content", staticStyle: { width: "16rem" } },
          [
            _c("div", { staticClass: "modal-header" }, [
              _vm._v("\n                выбор цвета\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal-body",
                staticStyle: { "max-height": "30rem", overflow: "auto" },
              },
              [
                _c("picker", {
                  model: {
                    value: _vm.local.obj.color,
                    callback: function ($$v) {
                      _vm.$set(_vm.local.obj, "color", $$v)
                    },
                    expression: "local.obj.color",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer justify-content-between" }, [
              _c("div"),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.clickSave },
                  },
                  [_vm._v("сохранить")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v("отмена")]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }