var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "w-100 mt-2" }, [
        _c("span", { staticClass: "small" }, [_vm._v("id")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputVal.val.id_f,
              expression: "inputVal.val.id_f",
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.inputVal.val.id_f },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.inputVal.val, "id_f", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "w-100 mt-2" }, [
        _c("span", { staticClass: "small" }, [_vm._v("количество")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.inputVal.val.v_in,
              expression: "inputVal.val.v_in",
              modifiers: { number: true },
            },
          ],
          ref: "v_focus",
          staticClass: "form-control",
          domProps: { value: _vm.inputVal.val.v_in },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.inputVal.val, "v_in", _vm._n($event.target.value))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6" }, [
      _c("label", { staticClass: "w-100 mt-2" }, [
        _c("span", { staticClass: "small" }, [
          _vm._v("формула " + _vm._s(_vm.inputVal.val.resp.fText)),
        ]),
        _vm._v(" "),
        _vm.inputVal.val.resp.eMess
          ? _c("span", { staticClass: "small text-danger" }, [
              _vm._v(_vm._s(_vm.inputVal.val.resp.eMess)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.inputVal.val.formula,
              expression: "inputVal.val.formula",
              modifiers: { trim: true },
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.inputVal.val.formula },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.inputVal.val, "formula", $event.target.value.trim())
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-1" }, [
      _c("label", { staticClass: "w-100 mt-2" }, [
        _c("span", { staticClass: "small" }, [_vm._v("результат")]),
        _vm._v(" "),
        _c("div", { staticClass: "w-100 position-relative" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.inputVal.val.v_out,
                expression: "inputVal.val.v_out",
                modifiers: { trim: true },
              },
            ],
            staticClass: "form-control",
            attrs: { disabled: "", title: "результат" },
            domProps: { value: _vm.inputVal.val.v_out },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.inputVal.val, "v_out", $event.target.value.trim())
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "position-absolute",
              staticStyle: {
                top: "0.45rem",
                right: "0.8rem",
                cursor: "pointer",
              },
              attrs: { title: "округление" },
              on: {
                click: function ($event) {
                  return _vm.clickRound(_vm.inputVal.val)
                },
              },
            },
            [_vm._v(_vm._s(_vm.inputVal.val.round))]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-1" }, [
      _c("label", { staticClass: "w-100 mt-2" }, [
        _c("span", { staticClass: "small" }, [_vm._v("ед.изм.")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.inputVal.element.unit,
              expression: "inputVal.element.unit",
              modifiers: { trim: true },
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.inputVal.element.unit },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.inputVal.element, "unit", $event.target.value.trim())
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }