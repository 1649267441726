<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8 py-5">

                    <table>

                        <tr v-for="item in local.table" :key="item.id">
                            <td>{{item.id}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.prop}}</td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        name: "page-sync-all-projects",
        components: {Multiselect},
        props: ['table'],
        data: function () {
            return {
                nbi: {
                    projectInfo: {},
                },
                local: {
                    table: this.table,
                },
            }
        },
        created: function () {
            Bus.$on('navbar_info', obj => this.nbi = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showBulocal.tableons', args: {reload: true, insert: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
        },
        methods: {

        },
        computed: {

        },
        watch: {

        },
        filters: {},
        directives: {

        }
    }
</script>

<style scoped>
    i.fas {
        color: green;
        cursor: pointer;
        opacity: 0.2;
        font-weight: bold;
        font-size: large;
    }

    i.fas:hover {
        opacity: 1;
    }

    i.fas.active {
        color: green;
        opacity: 1;
    }

</style>
