<template>
    <div :id="modalId" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    редактирование участников
                </div>
                <div class="modal-body" style="max-height: 30rem; overflow: auto">
                    <div class="d-flex justify-content-between mb-3">
                        <div class="w-100 mr-2" style="position: relative">
                            <input class="form-control w-100" v-model="search" ref="v_focus">
                            <font-awesome-icon icon="fa-solid fa-eraser" size="lg" @click="search=''" title="очистить"/>
                        </div>
                        <div>
                            <font-awesome-icon icon="fa-solid fa-plus" size="lg" border class="mt-1" style="cursor: pointer" @click="clickAdd" title="добавить"/>
                        </div>
                    </div>
                    <table class="table table-sm">
                        <tr v-for="(val,index) in fTable" :key="index">
                            <td class="text-left">{{val.name}}</td>
                            <td class="text-right">
                                <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click="clickRemove(val)" title="удалить"/>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="modal-footer justify-content-between">
                    <div></div>
                    <div>
                        <button type="button" class="btn btn-primary" @click="clickSave">сохранить</button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">отмена</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';

    export default {
        name: 'modal-list-fio-a',
        components: {Multiselect, vueDropzone: vue2Dropzone},
        props: {
            modalId: {type: String, default: 'modalListFioA',},
            listFio: {type: Array, default: [],}
        },
        data: function () {
            return {
                rand: 1,
                local: {
                    listFio: [],
                },
                state: {},
                search: '',
            }
        },
        created: function () {
            Bus.$on('show_' + this.modalId, () => this.showModal());
        },
        mounted() {
            // Vue.nextTick(() => this.local.listFio = JSON.parse(JSON.stringify(this.listFio)))
        },
        methods: {
            showModal() {
                this.local.listFio = JSON.parse(JSON.stringify(this.listFio));
                $('#' + this.modalId).modal('show');
                setTimeout(() => this.$refs.v_focus.focus(), 500);
            },
            clickAdd() {
                if (this.search === '') {
                    setTimeout(() => this.$refs.v_focus.focus(), 500)
                    return
                }
                this.local.listFio.push({name: this.search})
            },
            clickSave() {
                Bus.$emit('zPost', {
                    url: '/project/fio_a/save',
                    obj: {obj: this.local.listFio},
                    flash: true,
                    suffix: '_save_fio_a_modal',
                });
                Bus.$once('zResponse_save_fio_a_modal', response => {
                    this.local.listFio = response.list;
                    Bus.$emit('updListFioA', this.local.listFio);
                    $('#' + this.modalId).modal('hide');
                });
            },
            clickRemove(obj) {
                let index = this.local.listFio.findIndex(item => item.name === obj.name);
                if (index !== undefined && index !== null) this.local.listFio.splice(index, 1);
            },
        },
        computed: {
            fTable: function () {
                return this.local.listFio.filter(item => {
                    let res = true;
                    this.search.split(' ').forEach(value => {
                        let s = item.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;
        &:hover{
            opacity: 1;
        }
    }
</style>
