var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-sm mb-3",
          attrs: { type: "button" },
          on: { click: _vm.addVars },
        },
        [_vm._v("добавить переменную")]
      ),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: {
            list: _vm.inputVal,
            group: "section",
            "scroll-sensitivity": 100,
            "fallback-tolerance": 1,
            "force-fallback": true,
            handle: ".handle",
          },
        },
        [
          _vm._l(_vm.inputVal, function (el) {
            return [
              _c("div", { key: el.id, staticClass: "row" }, [
                _c("div", { staticClass: "col-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: el.id_f,
                        expression: "el.id_f",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control w-100 handle",
                    attrs: {
                      title: "имя переменной",
                      placeholder: "имя переменной",
                    },
                    domProps: { value: el.id_f },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(el, "id_f", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: el.name,
                        expression: "el.name",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control w-100",
                    attrs: { title: "примечание", placeholder: "примечание" },
                    domProps: { value: el.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(el, "name", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: el.v_in,
                        expression: "el.v_in",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control w-100",
                    attrs: { title: "значение", placeholder: "значение" },
                    domProps: { value: el.v_in },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(el, "v_in", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: el.formula,
                        expression: "el.formula",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control w-100",
                    attrs: { title: el.resp.fText, placeholder: "формула" },
                    domProps: { value: el.formula },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(el, "formula", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  el.resp.eMess
                    ? _c("span", { staticClass: "small text-danger" }, [
                        _vm._v(_vm._s(el.resp.eMess)),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2 d-flex" },
                  [
                    _c("div", { staticClass: "w-75 position-relative" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: el.v_out,
                            expression: "el.v_out",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: "", title: "результат" },
                        domProps: { value: el.v_out },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(el, "v_out", $event.target.value.trim())
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "position-absolute",
                          staticStyle: {
                            top: "0.45rem",
                            right: "0.8rem",
                            cursor: "pointer",
                          },
                          attrs: { title: "округление" },
                          on: {
                            click: function ($event) {
                              return _vm.clickRound(el)
                            },
                          },
                        },
                        [_vm._v(_vm._s(el.round))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "text-danger mt-2 ml-3 w-auto",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                      on: {
                        click: function ($event) {
                          return _vm.removeVars(el)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }