var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-fluid m1" }, [
      _c("div", { staticClass: "row justify-content-center m-0" }, [
        _c("div", { staticClass: "col-12 col-xl-10" }, [
          _c("div", { staticClass: "mb-3" }, [
            _c(
              "h1",
              { staticClass: "mb-0" },
              [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        _vm.state.catEdit = false
                      },
                    },
                  },
                  [_vm._v("Категории оборудования")]
                ),
                _vm._v(" "),
                !_vm.state.catEdit && !_vm.state.propEdit
                  ? _c("font-awesome-icon", {
                      staticClass: "ml-2",
                      attrs: {
                        icon: "fa-solid fa-pen",
                        size: "sm",
                        title: "редактировать категорию",
                      },
                      on: {
                        click: function ($event) {
                          _vm.state.catEdit = true
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.state.catEdit
                  ? _c("font-awesome-icon", {
                      staticClass: "ml-2",
                      attrs: {
                        icon: "fa-solid fa-plus",
                        title: "добавить категорию",
                      },
                      on: { click: _vm.clickAddCat },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.state.catEdit
                  ? _c("font-awesome-icon", {
                      staticClass: "ml-2",
                      attrs: {
                        icon: "fa-solid fa-floppy-disk",
                        title: "сохранить изменения",
                      },
                      on: { click: _vm.clickSaveCat },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row vh-100" }, [
            _c(
              "div",
              {
                staticClass: "col-4 vh-100",
                staticStyle: { "overflow-y": "scroll" },
              },
              [
                _vm.isMounted
                  ? _c("nested-category", {
                      attrs: { "p-state": _vm.state },
                      model: {
                        value: _vm.local.table,
                        callback: function ($$v) {
                          _vm.$set(_vm.local, "table", $$v)
                        },
                        expression: "local.table",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _c("label", { staticClass: "w-100" }, [
                  _vm._v("название категории"),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.state.activeRow.name,
                        expression: "state.activeRow.name",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { disabled: !_vm.state.catEdit },
                    domProps: { value: _vm.state.activeRow.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.state.activeRow,
                          "name",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "w-100" }, [
                  _vm._v("описание категории"),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.state.activeRow.desc,
                        expression: "state.activeRow.desc",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { disabled: !_vm.state.catEdit },
                    domProps: { value: _vm.state.activeRow.desc },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.state.activeRow,
                          "desc",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "font-weight-bold" },
                  [
                    _vm._v("ПОЛЯ\n                            "),
                    !_vm.state.propEdit && !_vm.state.catEdit
                      ? _c("font-awesome-icon", {
                          staticClass: "ml-2",
                          attrs: {
                            icon: "fa-solid fa-pen",
                            size: "sm",
                            title: "редактировать поле",
                          },
                          on: {
                            click: function ($event) {
                              _vm.state.propEdit = true
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.state.propEdit
                      ? _c("font-awesome-icon", {
                          staticClass: "ml-2",
                          attrs: {
                            icon: "fa-solid fa-plus",
                            title: "добавить поле",
                          },
                          on: { click: _vm.clickAddProp },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.state.propEdit
                      ? _c("font-awesome-icon", {
                          staticClass: "ml-2",
                          attrs: {
                            icon: "fa-solid fa-floppy-disk",
                            title: "сохранить изменеия",
                          },
                          on: { click: _vm.clickSaveFields },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.state.propEdit
                      ? _c("font-awesome-icon", {
                          staticClass: "ml-2 text-secondary",
                          class: {
                            "text-dark": _vm.state.importCategoryFields.isEdit,
                          },
                          attrs: {
                            icon: "fa-solid fa-file-import",
                            title: "импорт полей из другой выбранной категории",
                          },
                          on: {
                            click: function ($event) {
                              _vm.state.importCategoryFields.isEdit =
                                !_vm.state.importCategoryFields.isEdit
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isMounted
                  ? _c("nested-category-fields", {
                      attrs: {
                        "p-state": _vm.state,
                        "prop-visible": _vm.local.potrVisible,
                      },
                      model: {
                        value: _vm.getProp,
                        callback: function ($$v) {
                          _vm.getProp = $$v
                        },
                        expression: "getProp",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isMounted && _vm.state.importCategoryFields.isEdit
                  ? _c(
                      "div",
                      [
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v("импорт "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.state.importCategoryFields.row.name)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("nested-category-fields", {
                          attrs: {
                            "p-state": _vm.state,
                            "prop-visible": _vm.local.potrVisible,
                            "is-import": true,
                          },
                          model: {
                            value: _vm.getImportProp,
                            callback: function ($$v) {
                              _vm.getImportProp = $$v
                            },
                            expression: "getImportProp",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }