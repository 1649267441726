<template>
    <div>
        <div class="card-body">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" class="w-1">номер</th>
                    <th scope="col" class="w-5">название</th>
                    <th scope="col" class="w-1">отметка</th>
                    <th scope="col" class="w-1">кабель</th>
                </tr>
                </thead>
                <draggable v-model="local.table" tag="tbody" ghost-class="ghost" :disabled="pState.isEdit">
                    <tr v-for="el in fTable" :key="el.id">
                        <td scope="row">{{el.marker_tag}}/{{el.explication_tag}}</td>
                        <td>
                            <span v-if="!pState.isEdit">{{el.name}}</span>
                            <input v-else class="form-control1" v-model="el.name">
                        </td>
                        <td>
                            <span v-if="!pState.isEdit">{{el.otm}}</span>
                            <input v-else class="form-control1" v-model="el.otm">
                        </td>
                        <td>
                            <span v-if="!pState.isEdit">{{el.kabl}}</span>
                            <input v-else class="form-control1" v-model="el.kabl">
                        </td>
                    </tr>
                </draggable>
            </table>
        </div>
    </div>
</template>
<script>

    import uniqid from 'uniqid';
    import ModalPotr from "./sub/ModalPotr";
    import {eTable} from "./sub/tools";

    export default {
        name: 'e-room2',
        components: {ModalPotr},
        props: {
            pLocal: {},
            pState: {}
        },
        data: function () {
            return {
                eTable: new eTable(this),
                local: {
                    table: [],
                    selectArr: [],
                },
                state: {
                    search: this.pState.search,
                },
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'insert') this.showModalInsert();
                if (e.key === 113) this.showModal();
            });
            Bus.$on('localSave', () => this.localSave());
            Bus.$on('dotSelect', () => this.eTable.onDotSelect());
            Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
            Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        },
        mounted() {
            this.init();
            this.local.selectArr = [];
            if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
            setTimeout(() => this.$refs.search.focus(), 200);
        },
        methods: {
            localSave() {
                let eMarker = [];
                this.local.table.forEach(item => {
                    eMarker.push(item);
                })
                this.pLocal.eMarker.filter(item => !~this.expl.indexOf(item.id)).forEach(item => {
                    eMarker.push(item);
                })
                this.pLocal.eMarker = eMarker;
            },
            showModal() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('show_modalPotr0');
            },
            showModalInsert() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('showI_modalPotr0');
            },
            init() {
                let res = [];

                this.pLocal.eMarker.filter(item => !!~this.expl.indexOf(item.id)).forEach(item => {
                    res.push(item);
                })

                this.local.table = res;
            },
        },
        computed: {
            fTable: function () {
                if (this.state.search.val === '') return this.local.table;
                return this.local.table.filter(item => {
                    let res = true;
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
            expl: function () {
                return this.pLocal.eRoom.map(item => item.marker.id).filter((value, index, self) => self.indexOf(value) === index);
            },
        },
        watch: {
            'fTable': {
                handler: function (newVal, oldVal) {
                    this.eTable.clearSelectArr();
                },
                deep: false
            },
        }
    }
</script>


<style scoped lang="scss">
    @import 'resources/sass/variables';

    .nav-link {
        cursor: pointer;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .form-control1 {
        border: 0;
        width: 100%;
    }
</style>
