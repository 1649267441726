<template>
    <div class="card mb-3">
        <a class="card-link" data-toggle="collapse" :href="'#'+name">
            <div class="card-header p-1 pl-3 text-dark">{{ name }}</div>
        </a>
        <div :id="name" class="collapse" v-bind:class="{show:expand}">
            <div class="card-body">
                <c-tiptap v-model="local_value" :is-edit="isEdit"/>
            </div>
        </div>
    </div>
</template>
<script>
import CTiptap from "../tipTap/CTiptap";

export default {
    name: 'c-collapse-block',
    components: {CTiptap},
    props: {
        name: {
            type: String,
            default: 'заголовок',
            required: true,
        },
        value: {
            // type: String,
            default: 'описание',
            required: true,
        },
        expand: {
            type: Boolean,
            default: false,
            required: false,
        },
        isEdit: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            local_value: this.value,
        }
    },
    methods: {
        change(value) {
            console.log(value);
            this.$emit('input', value);
        }
    },
}
</script>

