<template>
    <div>
        <div class="container-fluid m1">

            <title-for-page :c-page="local.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="clickShowModal(null)">добавить файл</button>
                                </div>
                                <div class="nav-item">
                                    <a v-if="!!cField.opt.attach.name" class="btn nav-link" :href="nbi.url.current+'/dl'">скачать оригинал</a>
                                </div>
                                <div v-if="!!cField.opt.attach.name" class="navbar-text font-weight-bold">файл: {{cField.opt.attach.name}}</div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="local.cPage" :state="state"/>

                </div>
            </div>
        </div>
        <modal-attach-edit v-if="isMounted" modal-id="modalAttach" url="/attach/upload" :c-page="local.cPage" :max-file-size="1"/>
    </div>
</template>

<script>
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import TitleForPage from "./sub/TitleForPage";
    import moment from "moment";
    import Multiselect from "vue-multiselect";
    import {page} from "./sub/tools";
    import CardOptions from "./sub/CardOptions";
    import ModalAttachEdit from "./sub/ModalAttachEdit";

    export default {
        name: "page-attach",
        components: {TitleForPage, Multiselect, CardOptions, ModalAttachEdit},
        props: ['cPage'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                local: {
                    cPage: this.cPage,
                },
                status: {
                    loading: false,
                },
                state: {
                    version: 1,
                    swOnOpt: true,
                },
                nameCField: 'attach_0',
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.pageT.clickSaveCPage();
                if (e.key === 'download') this.pageT.clickDownload();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
            Bus.$on('updCPage', obj => {
                this.local.cPage = obj;
            });
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, download: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            if (this.cField === undefined) this.flash('ошибка содержимого страницы', 'error');
            else this.isMounted = true;
        },
        methods: {
            clickShowModal(obj) {
                Bus.$emit('showModalAttachEdit', obj);
            },
        },
        computed: {
            cField: function () {
                return this.local.cPage.data.fields.find(item => item.idd === this.nameCField);
            },
        },
        watch: {
            'state.swOnOpt'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        }

    }
</script>
