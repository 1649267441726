var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { id: _vm.modalId, role: "dialog" },
      on: { click: _vm.closeModal },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              { staticClass: "modal-header" },
              [
                _c("span", [_vm._v("настройки поля")]),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  staticClass: "text-danger mt-1",
                  staticStyle: { cursor: "pointer" },
                  attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                  on: { click: _vm.closeModal },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("label", { staticClass: "mb-0 mt-2" }, [
                  _c("span", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.optNameRu.desc)),
                  ]),
                ]),
                _vm._v(" "),
                _c("multiselect", {
                  attrs: {
                    placeholder: _vm.optNameRu.desc,
                    options: _vm.local.listRu,
                    "option-height": 104,
                    "show-labels": false,
                    searchable: true,
                    "close-on-select": true,
                    "allow-empty": false,
                  },
                  model: {
                    value: _vm.optNameRu.val,
                    callback: function ($$v) {
                      _vm.$set(_vm.optNameRu, "val", $$v)
                    },
                    expression: "optNameRu.val",
                  },
                }),
                _vm._v(" "),
                _c("label", { staticClass: "mb-0 mt-2 w-100" }, [
                  _c("span", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.optF.desc)),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.optF.val,
                        expression: "optF.val",
                      },
                    ],
                    staticClass: "form-control",
                    domProps: { value: _vm.optF.val },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.optF, "val", $event.target.value)
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v("закрыть")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSave(null)
                      },
                    },
                  },
                  [_vm._v("сохранить")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }