var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "item-container",
      attrs: {
        tag: "div",
        list: _vm.list,
        value: _vm.value,
        handle: ".handle",
        animation: "150",
        group: "nested",
        "fallback-on-body": true,
        "swap-threshold": 0.5,
        "ghost-class": "ghost",
        filter: ".no-drag",
        move: _vm.move,
      },
      on: {
        input: _vm.emitter,
        choose: _vm.choose,
        unchoose: _vm.unchoose,
        end: _vm.end,
        change: _vm.onChange,
      },
    },
    _vm._l(_vm.realValue, function (el) {
      return _c(
        "div",
        { key: el.id, staticClass: "item-group" },
        [
          _c(
            "div",
            { staticClass: "item", class: { "bg-danger": _vm.level > 2 } },
            [
              _c("div", { staticClass: "row mb-0" }, [
                _c("div", { staticClass: "col-1 pt-2 text-nowrap" }, [
                  _c("i", { staticClass: "far fa-file handle" }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold ml-1 name",
                      class: { active: el.id === _vm.activeId },
                      on: {
                        click: function ($event) {
                          return _vm.clickName(el)
                        },
                      },
                    },
                    [_vm._v(_vm._s(el.stamp.list_name))]
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("nested-list-page", {
            staticClass: "item-sub",
            attrs: {
              list: el.child,
              level: _vm.level + 1,
              id: el.id,
              "active-id": _vm.activeId,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }