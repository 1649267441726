<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount" data-keyboard="false">
        <div class="modal-dialog modal-xl" @click.stop="">
            <div class="modal-content">
                <div class="modal-header" style="cursor: move">
                    <span v-if="state.isEdit">редактировать оборудование</span>
                    <span v-else>вставить оборудование</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">

                    <div class="d-flex flex-column" style="max-height: calc(100vh - 236px);">

                        <div class="row mb-0">
                            <span class="ml-3 small"><c-category-string v-if="isMount" :list-category="listCategory" :ttype="'2'" :category="state.currentCategory" :is-ps-global="state.isPS"
                                                                        @setCategory="onSetCategory"/></span>
                            <div class="col-12"><input class="form-control w-100" v-model="state.search.val" ref="v_focus"></div>
                        </div>

                        <div class="row p-0 m-0 mb-3">
                            <div class="col-12 d-flex justify-content-between">
                                <div>
                                    <span v-for="(el) in state.listBase" class="mr-2 small" style="cursor: pointer" :class="{'font-weight-bold':el.isSelect}" @click="clickListBase(el)">
                                                                    <font-awesome-icon style="color: grey" v-if="el.id==='bs'" icon="fa-regular fa-star"/>
                                                                    <font-awesome-icon style="color: goldenrod" v-if="el.id==='ps'" icon="fa-solid fa-award"/>
                                                                    <font-awesome-icon style="color: royalblue" v-if="el.id==='pr'" icon="fa-regular fa-circle-user"/>
                                                                    {{ el.name }}
                                    </span>
                                </div>
                                <span class="small" @click="state.brand=''" style="cursor: pointer">{{ state.brand }}</span>
                            </div>
                        </div>

                        <div style="overflow-x: hidden;">
                            <div v-for="(el,index) in fTable" class="row mx-0" :class="{'bg-primary-l':index===state.activeRowIndex}" :key="el.uid" @click="state.activeRowIndex=index">
                                <div class="col-1" style="cursor: pointer" @click="showModalSoInfo(index)">
                                    <img :src="getImg(el)" alt="" class="w-100">
                                </div>
                                <div class="col-2">{{ el.marka }}</div>
                                <div class="col-9">
                                    <div class="small d-flex">
                                            <span style="cursor: pointer" @click="clickCategoryItem(el)">
                                                <c-category-string v-if="isMount" :list-category="listCategory" :c-obj="el" :ttype="'1'"/>
                                            </span>
                                    </div>
                                    <div class="text-left">{{ el.name }}</div>
                                    <div class="small d-flex justify-content-between">
                                        <span>{{ el.article }}</span>
                                        <span @click="state.brand=el.brand" style="cursor: pointer">
                                                <font-awesome-icon style="color: grey" v-if="getTwoChars(el.uid)==='bs'" icon="fa-regular fa-star"/>
                                                <font-awesome-icon style="color: goldenrod" v-if="getTwoChars(el.uid)==='ps'" icon="fa-solid fa-award"/>
                                                <font-awesome-icon style="color: royalblue" v-if="getTwoChars(el.uid)==='pr'" icon="fa-regular fa-circle-user"/>
                                                {{ el.brand }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {eTable} from "./tools";
import EInput from "./EInput";
import BInputField from "./BInputField";
import modalSoInfo from "./ModalSoInfo.vue";
import cCategoryString from "./cCategoryString.vue"


export default {
    name: 'modal-so',
    components: {EInput, Multiselect, BInputField, modalSoInfo, cCategoryString},
    props: {
        modalId: {type: String, default: 'modal',},
        AW: {type: Array, default: () => [],},
        listCategory: {type: Array, default: () => [],},
        marka: {type: String, default: 'marka_new',},
    },
    data: function () {
        return {
            //todo js modal add page module
            eTable: new eTable(this),
            local: {
                bsBase: [],
                psBase: [],
                prBase: [],
                selectArr: [],
            },
            state: {
                obj: null,
                isEdit: true,
                search: {
                    val: ''
                },
                activeRowIndex: 0,
                listBase: [
                    {id: 'bs', name: 'база', isSelect: true},
                    {id: 'ps', name: 'производители', isSelect: false},
                    {id: 'pr', name: 'личная', isSelect: false},
                ],
                currentCategory: '',
                isPS: false,
                brand: '',
            },
            status: {
                loading: false,
            },
            templateObj: {
                name: '',
                marka: '',
                fields: {},
            },
            lock: {
                name: true,
                marka: true,
            },
            lockF: {},
            templateCMarka: {
                id: '',
                marka: '',
                name: '',
            },
            isMount: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (this.isAW) {
                if (e.key === 'f2') this.showModalSoInfo();
                if (e.key === 'enter') this.clickLocalSave();
                if (e.key === 'esc') this.closeModal();
                if (e.key === 'down') {
                    if (this.state.activeRowIndex + 1 < this.fTable.length) this.clickRow(this.state.activeRowIndex + 1);
                    e.evt.preventDefault();
                }
                if (e.key === 'up') {
                    if (this.state.activeRowIndex > 0) this.clickRow(this.state.activeRowIndex - 1);
                    e.evt.preventDefault();
                }
            }
        });
        Bus.$on('show_' + this.modalId, (e) => {
            this.state.isEdit = true;
            this.local.selectArr = e.selectArr;
            this.local.saveArr = e.saveArr;
            this.showModal()
        });
        Bus.$on('showI_' + this.modalId, (e) => {
            this.state.isEdit = false;
            this.local.selectArr = e.selectArr;
            this.local.saveArr = e.saveArr;
            this.showModal();
        });
        Bus.$on('updListBase', () => {
            // console.trace('updList');
            this.local.bsBase = [];
            this.local.psBase = [];
            this.local.prBase = [];
            this.updListBase();
        });
        Bus.$on('closeModal', () => {
            this.closeModal();
        });
    },
    mounted() {
        // new modalDraggable(this);
        this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
        this.isMount = true;
        // this.state.search.val = 'авт';
    },
    methods: {
        onSetCategory(id) {
            this.state.currentCategory = String(id);
        },
        clickCategoryItem(obj) {
            let firstTwoChars = this.getTwoChars(obj.uid);
            this.state.isPS = firstTwoChars === 'ps';
            this.state.currentCategory = obj.category;
        },
        getTwoChars(uid) {
            if (!uid) return '';
            return String(uid).slice(0, 2);
        },
        showModalSoInfo() {
            setTimeout(() => {
                Bus.$emit('show_modalSoInfo', {activeUid: this.activeRow.uid, selectArr: this.local.selectArr, saveArr: this.local.saveArr});
            });
        },
        getImg(obj) {
            if (obj.img !== null && obj.img[0] !== undefined) return obj.img[0];
            return '';
        },
        clickListBase(obj) {
            obj.isSelect = !obj.isSelect;
            if (obj.isSelect) {
                if (this.local[obj.id + 'Base'].length === 0) this.dlBase(obj);
            }
        },
        clickEnter() {
            let o = this.fTable[this.state.activeRowIndex];
            if (o) this.$emit('selectObj', o);
            this.closeModal();
        },
        clickRow(i) {
            this.state.activeRowIndex = i;
        },
        updListBase() {
            this.state.listBase.forEach(item => {
                if (item.isSelect && this.local[item.id + 'Base'].length === 0) this.dlBase(item);
            })
        },
        showModal() {
            if (!this.lastSelectObj) return;
            this.activeRowIndex = 0;

            $('#' + this.modalId).modal('show');

            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    if (this.$refs.v_focus) this.$refs.v_focus.focus();
                }, 200);
            }

            setTimeout(function () {
                $(".modal").draggable({handle: ".modal-header"});
            });

            (function (id) {
                setTimeout(function () {
                    Bus.$emit('setAW', id);
                });
            })(this.modalId)

            setTimeout(() => {
                setTimeout(() => {
                    // this.showModalSoInfo()
                }, 500);
                // this.state.search.val = 'd';
            }, 1000);

            let marka = this.cMarka?.marka ?? '';
            if (marka === '') marka = this.cMarka?.name ?? '';
            this.state.search.val = marka;

            let t = this.getTwoChars(this.cMarka?.id);
            this.state.listBase.forEach(item => {
                if (item.id === t) item.isSelect = true;
            })
            this.updListBase();

            let i = this.fTable.findIndex(item => item.uid === this.cMarka?.id);
            if (i >= 0) this.clickRow(i);

            // this.state.brand=this.state.obj.brand;
        },
        dlBase(obj) {
            // console.trace('asd');
            Bus.$emit('zPost', {
                url: '/marka_element/dl_base',
                obj: obj,
                suffix: '_dl_base_' + obj.id,
                flash: true,
            });
            Bus.$once('zResponse_dl_base_' + obj.id, response => {
                this.local[obj.id + 'Base'] = response.table;
            });
            Bus.$once('zError_dl_base_' + obj.id, response => {
            });
        },
        clickLocalSave() {
            if (this.state.isEdit) {
                this.local.selectArr.forEach(item => {
                    item[this.marka].id = this.activeRow.uid;
                    item[this.marka].marka = this.activeRow.marka;
                    item[this.marka].name = this.activeRow.name;
                    item[this.marka].article = this.activeRow.article;
                    item[this.marka].brand = this.activeRow.brand;
                    if (item.child) {
                        item.child.forEach(item2 => {
                            item2[this.marka].id = this.activeRow.uid;
                            item2[this.marka].marka = this.activeRow.marka;
                            item2[this.marka].name = this.activeRow.name;
                        })
                    }
                    this.local.saveArr.push(item);
                })
            } else {
                this.cMarka.id = this.activeRow.uid;
                this.cMarka.marka = this.activeRow.marka;
                this.cMarka.name = this.activeRow.name;
                Bus.$emit('onInsertEl',this.cMarka);
            }
            this.closeModal();
        },
        closeModal() {
            $('#' + this.modalId).modal('hide');
            Bus.$emit('removeAW', this.modalId);
        },
        getParentInThreeById(arr, id_parent) {
            let o;
            arr.forEach(item => {
                if (item.id === id_parent) {
                    o = item;
                }
                if (!o) o = this.getParentInThreeById(item.child, id_parent);
            })

            return o;
        },
        recursGetChild(id, arr) {
            arr.push(id);
            this.listCategory.forEach(item => {
                if (item.id_parent === id) {
                    this.recursGetChild(item.id, arr);
                }
            })
        },
    },
    computed: {
        listCatChild: function () {
            if (this.state.currentCategory === '') return [];
            if (this.state.isPS) return [this.state.currentCategory];
            let arr = [];
            this.recursGetChild(parseInt(this.state.currentCategory), arr);
            return arr;
        },
        isAW: function () {
            return this.AW !== undefined && this.AW[0] === this.modalId;
        },
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
        cMarka: function () {
            return this.lastSelectObj[this.marka] ?? this.templateCMarka;
        },
        activeRow: function () {
            let o = this.fTable[this.state.activeRowIndex];
            if (!o) o = {};
            return o;
        },

        fTable: function () {
            if (this.state.search.val === '') return [];
            let i = 0;
            let table = [];
            this.state.listBase.forEach(item => {
                if (item.isSelect && this.local[item.id + 'Base'] !== undefined) table = table.concat(this.local[item.id + 'Base']);
            })
            if (table.length === 0) return [];

            if (this.listCatChild.length > 0) {
                table = table.filter(item => {
                    return this.listCatChild.findIndex(item2 => String(item2) === String(item.category)) > -1;
                })
            }

            return table.filter(item => this.state.brand === '' || item.brand === this.state.brand).filter(item => {
                if (i > 9) return false;
                let res = true;
                if (this.state.search.val !== '') {
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name + item.marka + item.brand + item.article;
                        if (value[0] === '-') {
                            if (value.slice(1) !== '') res = res && s.toLowerCase().indexOf(value.slice(1).toLowerCase()) === -1;
                        } else {
                            res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                        }
                    });
                }
                if (res) i++;
                return res;
            })
        },
    },
    watch: {},
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}

ul.multiselect__content {
    width: 100% !important;
}

input.lock {
    background-color: lighten($primary, 50%);
}

.bg-primary-l {
    background-color: lighten($primary, 40);
}


</style>
