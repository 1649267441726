<template>
    <node-view-wrapper class="draggable-item">
        <div class="drag-handle text-left" draggable="true" data-drag-handle title="перетащить">
            <font-awesome-icon icon="fa-solid fa-bars" transform="grow-5"/>
        </div>
        <div class="b-del text-right" @click="clickDel" title="удалить">
            <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" transform="grow-5"/>
        </div>
        <node-view-content class="content" contenteditable="false"/>
    </node-view-wrapper>
</template>

<script>
    import {NodeViewWrapper, NodeViewContent, nodeViewProps} from '@tiptap/vue-2'

    export default {
        props: nodeViewProps,
        components: {
            NodeViewWrapper,
            NodeViewContent,
        },
        methods: {
            clickDel() {
                Bus.$emit('delNode', this.node);
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../sass/variables';

    .ProseMirror-focused {
        .draggable-item {
            background: lighten($primary, 40%);
        }
        .drag-handle {
            cursor: grab;

            &:hover {
                opacity: 1;
            }
        }

        .b-del {
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    .draggable-item {
        display: inline-block;
        position: relative;
        padding: 0;
        margin: 0;
        /*border-bottom: 2px dashed black;*/
        /*background: white;*/

        &:hover {
            /*box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.4);*/
        }

        .drag-handle {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 2rem;
        }

        .b-del {
            position: absolute;
            top: 0;
            right: 5px;
            opacity: 0;
            width: 2rem;
        }

        .content {
            flex: 1 1 auto;
        }
    }
</style>
