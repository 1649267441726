import { render, staticRenderFns } from "./pageCategory.vue?vue&type=template&id=761c95f8&scoped=true&"
import script from "./pageCategory.vue?vue&type=script&lang=js&"
export * from "./pageCategory.vue?vue&type=script&lang=js&"
import style0 from "./pageCategory.vue?vue&type=style&index=0&id=761c95f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "761c95f8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/aleksej/app/dev.speka/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('761c95f8')) {
      api.createRecord('761c95f8', component.options)
    } else {
      api.reload('761c95f8', component.options)
    }
    module.hot.accept("./pageCategory.vue?vue&type=template&id=761c95f8&scoped=true&", function () {
      api.rerender('761c95f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/pageCategory.vue"
export default component.exports