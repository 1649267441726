<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-2">
                    <div class="card mb-3">
                        <div class="card-body pl-0" style="overflow: hidden">
                            <a class="nav-link" href="#" :disabled="currentPage.id==='0'" @click="clickProjectButton"><b>Проект</b></a>
                            <nested-list-page class="item-sub" v-model="local.listPages" :active-id="currentPage.id"/>
                        </div>
                    </div>
                </div>

                <div class="col-10">
                    <div class="row">
                        <div class="col-12">
                            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                                <div class="container-fluid">
                                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li v-if="currentPage.id!=='0'" class="nav-item">
                                            <a class="nav-link text-primary" :href="'/tp/to_word/export/'+currentPage.id">word</a>
                                        </li>
                                        <li v-if="currentPage.id!=='0'" class="nav-item">
                                            <a class="nav-link text-primary" href="#" @click="isEdit=!isEdit">редактировать</a>
                                        </li>
                                        <li v-if="currentPage.id==='0'" class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">вставить лист</a>
                                            <div class="dropdown-menu">
                                                <a v-for="val in listPagesTemplate.filter(item => item.parent === '_')" :key="val.name"
                                                   class="dropdown-item" :class="{disabled:isDisableLink(val)}" href="#" @click="clickAddPage(val.name)">{{val.list_name}}</a>
                                            </div>
                                        </li>
                                        <li v-if="currentPage.type_list==='text'" class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">вставить раздел</a>
                                            <div class="dropdown-menu">
                                                <a v-for="val in listPagesTemplate.filter(item => item.parent === 'text')" :key="val.name"
                                                   class="dropdown-item" :class="{disabled:isDisableLink(val)}" href="#" @click="clickAddPage(val.name)">{{val.list_name}}</a>
                                            </div>
                                        </li>
                                        <li v-if="currentPage.id==='0'" class="nav-item"><a class="nav-link text-danger" href="#" @click="state.show_1=!state.show_1">суффиксы</a></li>
                                    </ul>
                                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li v-if="currentPage.id!=='0'" class="nav-item"><a class="nav-link text-danger" href="#" @click="clickDelPage">удалить</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div class="col-12" v-if="state.show_1 && currentPage.id==='0'">
                            <edit-suffix :list-pages-template="listPagesTemplate" :setting="setting" :list-pages="local.listPages" @save="clickSaveSuffix"/>
                        </div>

                        <div class="col-12" v-if="currentPage.id==='0'">
                            <div class="card">
                                <div class="card-body">
                                    <nested-list class="item-sub" v-model="local.listPages" :setting="local.setting" :list-pages-template="listPagesTemplate"/>
                                </div>
                            </div>
                        </div>

                        <div class="col-6" v-if="currentPage.id!=='0'">
                            <div class="card">
                                <div class="card-body p-0">
                                    <c-tiptap v-model="currentPage.html" :is-edit="isEdit"/>
                                </div>
                            </div>
                            <br>
                            <br>
                            <!--                    <div id="html_code" v-html="currentPage.html"></div>-->
                            <div v-if="isEdit">{{currentPage.html}}</div>
                        </div>
                        <div class="col-6" v-if="currentPage.id!=='0'">
                            <div class="card">
                                <div class="card-body">

                                    <ul class="nav nav-tabs">
                                        <li class="nav-item">
                                            <button class="nav-link" data-toggle="tab" data-target="#fields" type="button">поля</button>
                                        </li>
                                        <li class="nav-item">
                                            <button class="nav-link active" data-toggle="tab" data-target="#profile1" type="button">страницы</button>
                                        </li>
                                    </ul>


                                    <div class="tab-content">
                                        <div class="tab-pane fade" id="fields">s
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-check mt-3"><label class="form-check-label" style="cursor: pointer">
                                                        <input class="form-check-input" type="checkbox" v-model="state.show_2"> все
                                                    </label></div>
                                                </div>
                                            </div>
                                            <template v-if="!state.show_2" v-for="val in local.listFieldTemplates.filter(item=>item.razdel===currentPage.type_list)">
                                                <div class="template">
                                                    <i class="fas fa-chevron-left text-success" @click="clickInsertBlock(val)"></i>
                                                    <div v-html="val.html"/>
                                                    <i class="fas fa-chevron-down text-success" @click="clickCollapseBlock(val)"></i>
                                                    <div class="collapse m-3" :id="'collapse_'+val.id">
                                                        <template v-for="valt in val.child">
                                                            <div class="template">
                                                                <i class="fas fa-chevron-left text-success" @click="clickInsertBlock(valt)"></i>
                                                                <div v-html="valt.html"/>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-if="state.show_2" v-for="val in local.listFieldTemplates">
                                                <p>{{val.razdel}}</p>
                                                <div class="template">
                                                    <i class="fas fa-chevron-left text-success" @click="clickInsertBlock(val)"></i>
                                                    <div v-html="val.html"/>
                                                    <i class="fas fa-chevron-down text-success" @click="clickCollapseBlock(val)"></i>
                                                    <div class="collapse m-3" :id="'collapse_'+val.id">
                                                        <template v-for="valt in val.child">
                                                            <div class="template">
                                                                <i class="fas fa-chevron-left text-success" @click="clickInsertBlock(valt)"></i>
                                                                <div v-html="valt.html"/>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                            <div id="templateBlock"></div>
                                        </div>
                                        <div class="tab-pane fade show active" id="profile1">
                                            <div class="row">
                                                <template v-for="val in getCurrentPageTemplate">
                                                    <div class="">
                                                        <div class="doc-view-small">
                                                            <div v-html="val.html"/>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="contact1">...3</div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="html_code" style="display: none"></div>

        <zapros></zapros>
    </div>
</template>

<script>
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import nestedListPage from "./sub/nestedListPage";
    import CTiptap from "./tipTap/CTiptap";
    import EditSuffix from "./sub/EditSuffix";

    export default {
        name: "page-text-part",
        components: {EditSuffix, nestedListPage, CTiptap},
        props: ['listPages', 'psInfo', 'listFieldTemplates', 'listPagesTemplate', 'setting'],
        data: function () {
            return {
                switchId: '',
                nbi: {
                    projectInfo: {},
                    authUser: {prop: {setting: {listFioA: []}}},
                },
                listStage: [
                    {name: 'П'},
                    {name: 'Р'},
                    {name: 'ИД'},
                ],
                isEdit: false,
                errors: [],
                markerRow: 0,
                markRow: [0],
                search: '',
                markerObj: {},
                state: {
                    show_1: false,
                    show_2: false,
                    export: false,
                },
                status: {
                    reset: false,
                    save: false,
                    loading: false,
                },
                local: {
                    listPages: this.listPages,
                    table: this.table,
                    setting: this.setting,
                    ps_info: this.psInfo,
                    listFio: this.listFio,
                    listFieldTemplates: this.listFieldTemplates,
                    listPageTemplatesFull: [],
                },
                lockCalc: false,
                currentPage: {id: '0', type_list: '_', stamp: {list_name: ''}},
                currentTTBlock: {},
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'ctrlS') this.clickSave();
                if (e.key === 'exportAutocad') this.clickExport();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
            Bus.$on('clickPage', obj => this.clickPage(obj));
            Bus.$on('changeTiptapContent', obj => {
                this.currentPage.html = obj;
                $('#html_code').html(obj);
            });
            Bus.$on('delNode', () => this.delNode());

            Bus.$on('blockByPosition', obj => this.currentTTBlock = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload: true, save: true, export: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            // this.calc();
        },
        methods: {
            clickExportWord() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/tp/to_word/' + this.currentPage.id,
                    obj: {},
                    suffix: '_to_word'
                })
                Bus.$once('zResponse_to_word', response => {
                    location.href = '/tp/to_word/export';
                    this.setLoading(false);
                });
            },
            loadPageTemplate() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/tp/load_page_templates/' + this.currentPage.type_list,
                    obj: {},
                    suffix: '_load_page_templates',
                });
                Bus.$once('zResponse_load_page_templates', response => {
                    this.setLoading(false);
                    // let obj = this.local.listPageTemplatesFull.find(item => item.type_list === response.id);
                    // if (obj) obj.child = response.list;
                    this.local.listPageTemplatesFull[response.id] = response.list;
                });
            },
            isDisableLink(obj) {
                if (obj.name === 'dwg' || obj.name === 'annex') return false;
                let o = this.local.listPages.map(item => item.type_list);
                return !!~o.indexOf(obj.name);
            },
            clickSaveSuffix() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/tp/save_setting',
                    obj: {setting: this.local.setting},
                    suffix: '_save_setting',
                });
                Bus.$once('zResponse_save_setting', response => {
                    this.setLoading(false);
                    this.clickSave();
                });
            },
            clickProjectButton() {
                this.currentPage = {id: '0', stamp: {list_name: ''}};
            },
            delNode() {
                let id = this.currentTTBlock.attrs.msId;
                $('#html_code span[data-ms-id="' + id + '"]').detach();
                this.currentPage.html = $('#html_code').html();
                Bus.$emit('updTiptapContent', $('#html_code').html());
            },
            clickCollapseBlock(obj) {
                $('#collapse_' + obj.id).collapse('toggle');
                if (obj.child.length === 0) this.loadChild(obj);

            },
            loadChild(obj) {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/tp/load_child_for_templates/' + obj.id,
                    obj: {},
                    suffix: '_load_child_for_templates',
                });
                Bus.$once('zResponse_load_child_for_templates', response => {
                    this.setLoading(false);
                    let obj = this.local.listFieldTemplates.find(item => item.id === response.id);
                    if (obj) obj.child = response.list;
                });
            },
            clickInsertBlock(obj) {
                let tt = Date.now();
                $('#templateBlock').html(obj.html);
                $('#templateBlock span').attr('data-ms-id', tt);
                Bus.$emit('insertTiptapBlock', $('#templateBlock').html());
                this.currentPage.options.fields.push({id: tt, options: obj.options});
            },
            clickPage(obj) {
                this.currentPage = obj;
                $('#html_code').html(this.currentPage.html);
                this.currentPage.html = $('#html_code').html();
                Bus.$emit('updTiptapContent', $('#html_code').html());
            },
            clickDelPage() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/tp/del_page/' + this.currentPage.id,
                    obj: {setting: this.local.setting},
                    suffix: '_del_page',
                });
                Bus.$once('zResponse_del_page', response => {
                    this.setLoading(false);
                    this.local.listPages = response.list;
                    this.clickProjectButton();
                });
            },
            clickAddPage(pageName) {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/tp/add_page/' + pageName,
                    obj: {setting: this.local.setting},
                    suffix: '_add_page',
                });
                Bus.$once('zResponse_add_page', response => {
                    this.setLoading(false);
                    this.local.listPages = response.list;
                });
            },
            clickSave() {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/tp/save_page',
                    obj: {listPages: this.local.listPages},
                    suffix: '_save_page',
                });
                Bus.$once('zResponse_save_page', response => {
                    this.setLoading(false);
                    this.local.listPages = response.list;
                });
            },
            setLoading(state) {
                this.status.loading = state;
                // Bus.$emit('navbar', {command: 'setLoading', args: state});
            },
        },
        computed: {
            getCurrentPageTemplate() {
                if (this.currentPage.type_list === '_') return [];
                if (this.local.listPageTemplatesFull[this.currentPage.type_list] !== undefined) return this.local.listPageTemplatesFull[this.currentPage.type_list];
                else {
                    this.loadPageTemplate();
                    return [];
                }
            },
        },
        directives: {
            focus: {
                inserted: function (el) {
                    el.focus()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

    /*i.fas {*/
    /*    color: green;*/
    /*    cursor: pointer;*/
    /*    opacity: 0.2;*/
    /*    font-weight: bold;*/
    /*    font-size: large;*/
    /*}*/

    i.fas:hover {
        opacity: 1;
    }

    i.fas.active {
        color: green;
        opacity: 1;
    }

    .template {
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.4);
        padding: 0;
        margin: 1rem 0;
        position: relative;

        & > div {
            margin-left: 2rem;
        }

        i {
            position: absolute;
            cursor: pointer;

            &.fa-chevron-left {
                left: 5px;
                top: 0;
                font-size: 25px;
            }

            &.fa-chevron-down {
                right: 5px;
                top: 0;
                font-size: 25px;
            }

            &:hover {
            }
        }
    }


</style>
