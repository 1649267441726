<template>
    <div class="h-100">
        <div class="container-fluid m1" @click="clickBody">

            <title-for-page :c-page="local.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt2=!state.swOnOpt2" :class="{active:state.swOnOpt2}" title="настройки страницы">настройки таблицы</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="local.cPage" :state="state" class="mb-3"/>

                    <div v-if="state.swOnOpt2" class="card mb-3">
                        <div class="card-header p-1 pl-2" @click="state.swOnOpt2 = !state.swOnOpt2" style="cursor: pointer">настройки таблицы</div>
                        <div class="card-body">
                            <div class="row">
<!--                                <div class="col-2">-->
<!--                                    <label class="apple-switch_2 ml-1"><input type="checkbox" v-model="cField.opt.manual.val"><i></i>{{cField.opt.manual.desc}}</label>-->
<!--                                </div>-->
                                <div class="col-2">
                                    <label class="apple-switch_2 ml-1"><input type="checkbox" v-model="cField.opt.plus_name3.val"><i></i>{{cField.opt.plus_name3.desc}}</label>
                                </div>
                            </div>
                            <div v-if="cField.opt.manual.val">
                                <div class="row" v-for="(el,index) in cField.opt.manual_arr">
                                    <div class="col-3">
                                        <label class="mb-0 mt-2">
                                            <span class="small">{{el[0].desc}}</span>
                                        </label>
                                        <multiselect
                                            v-model="el[0].val"
                                            :options="cField.opt.list_type_blocks.map(item => item.val)"
                                            :custom-label="opt_ => cField.opt.list_type_blocks.find(i=>i.val===opt_).desc"
                                            :searchable="false"
                                            :close-on-select="true"
                                            :show-labels="false"
                                            :allow-empty="false"
                                        >
                                        </multiselect>
                                    </div>
                                    <div class="col-3">
                                        <label class="mb-0 mt-2">
                                            <span class="small">{{el[1].desc}}</span>
                                        </label>
                                        <multiselect
                                            v-model="el[1].val"
                                            :options="listPanel"
                                            :custom-label="opt_ => customLabel(opt_)"
                                            :searchable="false"
                                            :close-on-select="true"
                                            :show-labels="false"
                                            :allow-empty="false"
                                        >
                                        </multiselect>
                                    </div>
                                    <div class="col-5">
                                        <label class="w-100 mt-2">
                                            <span class="small">{{el[2].desc}}</span>
                                            <input class="form-control" v-model="el[2].val" :placeholder="el[2].desc"/>
                                        </label>
                                    </div>
                                    <div class="col-1">
                                        <font-awesome-icon icon="fa-solid fa-plus" class="mt-5 ml-3" style="cursor: pointer" @click="clickAdd(el)"/>
                                        <font-awesome-icon icon="fa-solid fa-minus" class="mt-5 ml-3 text-danger" style="cursor: pointer" @click="clickRemove(index)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="!cField.opt.manual.val">
                                <div class="col-3">
                                    <label class="mb-0 mt-2">
                                        <span class="small">{{cField.opt.name_panel.desc}}</span>
                                    </label>
                                    <multiselect
                                        v-model="cField.opt.name_panel.val"
                                        :options="listPanel"
                                        :custom-label="opt_ => customLabel(opt_)"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :allow-empty="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-2">
                                    <label class="mb-0 mt-2">
                                        <span class="small">{{cField.opt.type_trn.desc}}</span>
                                    </label>
                                    <multiselect
                                        v-model="cField.opt.type_trn.val"
                                        :options="cField.opt.list_type_trn.map(item => item.val)"
                                        :custom-label="opt_ => cField.opt.list_type_trn.find(i=>i.val===opt_).desc"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :allow-empty="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-3">
                                    <label class="mb-0 mt-2">
                                        <span class="small">{{cField.opt.type_arr.desc}}</span>
                                    </label>
                                    <multiselect
                                        v-model="cField.opt.type_arr.val"
                                        :options="cField.opt.list_type_arr.map(item => item.val)"
                                        :custom-label="opt_ => cField.opt.list_type_arr.find(i=>i.val===opt_).desc"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :allow-empty="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-3">
                                    <label class="mb-0 mt-2">
                                        <span class="small">{{cField.opt.position_itog.desc}}</span>
                                    </label>
                                    <multiselect
                                        v-model="cField.opt.position_itog.val"
                                        :options="cField.opt.list_position_itog.map(item => item.val)"
                                        :custom-label="opt_ => cField.opt.list_position_itog.find(i=>i.val===opt_).desc"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :allow-empty="false"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right">
                        <button class="btn btn-danger btn-sm" @click="clickReset">сброс</button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row header align-items-center">
                                <div class="col-4">Наименование нагрузки</div>
                                <div class="col-1">кол-во</div>
                                <div class="col-1">Pуст,<br>кВт</div>
                                <div class="col-1">cosϕ</div>
                                <div class="col-1">Кс</div>
                                <div class="col-1">Pр,<br>кВт</div>
                                <div class="col-1">Qр,<br>квар</div>
                                <div class="col-1">Sр,<br>кВА</div>
                                <div class="col-1">Iр,<br>А</div>
                            </div>
                            <nested-trn class="item-sub" v-model="cField.opt.table" :nbi="nbi"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <context-menu-my ref="menu" :items="items"/>
    </div>
</template>

<script>
    import TitleForPage from "./sub/TitleForPage";
    import {page} from "./sub/tools";
    import CardOptions from "./sub/CardOptions";
    import Multiselect from 'vue-multiselect';
    import "vue-multiselect/dist/vue-multiselect.min.css";
    import nestedTrn from "./sub/nestedTrn";
    import ContextMenuMy from "./sub/ContextMenuMy";


    export default {
        name: "page-text-trn",
        components: {CardOptions, TitleForPage, Multiselect, nestedTrn, ContextMenuMy},
        props: ['cPage', 'listPanel'],
        data: function () {
            return {
                pageT: new page(this),
                nbi: {},
                state: {
                    version: 5,
                    swOnOpt: false,
                    swOnOpt2: false,
                },
                status: {
                    save: false,
                    loading: false,
                },
                local: {
                    cPage: this.cPage,
                },
                items: [
                    {name: 'переключить видимость', link: 'hide'},
                    {name: '---'},
                    {name: 'переключить видимость щита', link: 'hideRu'},
                ],
                nameCField: 'table_trn_0',
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.pageT.clickSaveCPage();
                if (e.key === 'download') this.pageT.clickDownload();
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);

            Bus.$on('onShowContextMenu', obj => this.$refs[obj.refs].open(obj.e, obj.el));
            Bus.$on('contextMenu_menu', (o) => {
                if (o.link === 'hide') this.onHide(o.obj);
                if (o.link === 'hideRu') this.onHideRu(o.obj);
            });
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {reload_calc: true, save: true, download: true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            if (this.cField === undefined) this.flash('ошибка содержимого страницы', 'error');
            else this.isMounted = true;
        },
        methods: {
            clickAdd(obj) {
                this.cField.opt.manual_arr.push(JSON.parse(JSON.stringify(obj)));
            },
            clickRemove(key) {
                if (key >= 0) this.cField.opt.manual_arr.splice(key, 1);
            },
            customLabel(opt) {
                let o = this.listPanel.find(item => item.id === opt.id);
                if (o) {
                    let d = '';
                    if (o.desc !== '') d = ' (' + o.desc + ')';
                    return o.name + d;
                } else return '';
            },
            onHide(obj) {
                obj.isVisible = !obj.isVisible;
            },
            onHideRu(obj) {
                let parent = obj;
                if (!obj.isRu) parent = this.cField.opt.table.find(item => item.id === obj.parent_id);

                parent.isVisible = !parent.isVisible;
                parent.child.forEach(item => item.isVisible = parent.isVisible);
            },
            clickReset() {
                this.cField.opt.table = [];
                this.clickSave();
            },
            clickInsert() {
                Bus.$emit('show_modalPps', null);
            },
            clickBody() {
                Bus.$emit('clickBody');
            },
            onDelete(obj) {
                let index = this.cField.opt.table.findIndex(item => item.id === obj.id);
                if (index !== undefined && index !== null) this.cField.opt.table.splice(index, 1);
            },
            onAdd(obj) {
                this.cField.opt.table.push(obj);
            },
        },
        computed: {
            cField: function () {
                return this.local.cPage.data.fields.find(item => item.idd === this.nameCField);
            },
        },
        watch: {
            'state.swOnOpt'(newVal, oldVal) {
                this.pageT.updCookie();
            },
            'state.swOnOpt2'(newVal, oldVal) {
                this.pageT.updCookie();
            },
        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

    .row.header {
        font-weight: bold;
        text-align: center;
        border: 1px solid $primary;
        background-color: lighten($primary, 20%);
    }

</style>
