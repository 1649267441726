<template>
    <div>
        <div class="container-fluid m1">

            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">
                    <h1 class="mb-0">Помещения и зоны</h1>
                </div>
            </div>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">


                    <nav class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="clickReCalc">пересчитать</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.isEdit=!state.isEdit" :class="{active:state.isEdit}">редактирование</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                </div>
            </div>

            <div class="row justify-content-center">

                <div class="col-12 col-xl-8">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(0)" :class="{active:state.activeTab===0}">Помещения</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(1)" :class="{active:state.activeTab===1}">Маркеры</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(2)" :class="{active:state.activeTab===2}">Экспликации</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(3)" :class="{active:state.activeTab===3}">Импорт</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(4)" :class="{active:state.activeTab===4}">Зоны</a>
                        </li>
                    </ul>

                    <div v-if="state.activeTab===0" class="card" style="border-top: none;">
                        <e-room0 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>
                    <div v-if="state.activeTab===1" class="card" style="border-top: none;">
                        <e-room1 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>

                    <div v-if="state.activeTab===2" class="card" style="border-top: none;">
                        <e-room2 v-if="isMounted" :p-local="local" :p-state="state"/>
                    </div>

                    <div v-if="state.activeTab===3" class="card" style="border-top: none;">
                        <div class="card-body">
                            <table class="table table-sm">
                                <thead>
                                <tr>
                                    <th scope="col" class="w-0">номер</th>
                                    <th scope="col" class="w-5">название</th>
                                    <th scope="col" class="w-5">импорт</th>
                                </tr>
                                </thead>
                                <template v-for="val in local.eRoomF">
                                    <tr :key="val.id" class="mark">
                                        <td></td>
                                        <td>{{val.name}}</td>
                                        <td></td>
                                    </tr>
                                    <draggable :list="val.child" tag="tbody" ghost-class="ghost" :disabled="state.isEdit">
                                        <tr v-for="el in val.child" :key="el.id">
                                            <td scope="row">{{el.number}}</td>
                                            <td class="text-left">{{el.name}}</td>
                                            <td>
                                                <!--//todo-code-vue multiselect obj-->
                                                <multiselect
                                                    v-model="el.name_"
                                                    placeholder="выберите помещение для импорта настроек"
                                                    :options="listRoomName"
                                                    :option-height="104"
                                                    :show-labels="false"
                                                    :searchable="true"
                                                    :close-on-select="true"
                                                    :allow-empty="false"
                                                    @open="onOpen(el)"
                                                    @input="onChange"
                                                >
                                                    <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ props.option.name }}
                                                            </span>
                                                        </span>
                                                    </template>
                                                    <template slot="option" slot-scope="props">
                                                        <div class="option__desc">
                                                            <span class="option__title">
                                                                {{ props.option.name }}
                                                            </span>
                                                            <br>
                                                            <span class="option__small small">
                                                                {{ props.option.zone.name }}
                                                            </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </td>
                                        </tr>
                                    </draggable>
                                </template>
                            </table>
                        </div>
                    </div>

                    <div v-if="state.activeTab===4" class="card" style="border-top: none;">
                        <div class="card-body">
                            <table class="table table-sm">
                                <thead>
                                <tr>
                                    <th scope="col" class="w-0">номер</th>
                                    <th scope="col" class="w-5">название</th>
                                    <th scope="col" class="w-5">функциональная зона</th>
                                </tr>
                                </thead>
                                <template v-for="val in local.eRoomF">
                                    <tr :key="val.id" class="mark">
                                        <td></td>
                                        <td>{{val.name}}</td>
                                        <td></td>
                                    </tr>
                                    <draggable :list="val.child" tag="tbody" ghost-class="ghost" :disabled="state.isEdit">
                                        <tr v-for="el in val.child" :key="el.id">
                                            <td scope="row">{{el.number}}</td>
                                            <td class="text-left">{{el.name}}</td>
                                            <td>
                                                <!--//todo-code-vue multiselect obj-->
                                                <multiselect
                                                    v-model="el.zone"
                                                    :options="listZones.map(item => {return {id:item.id,name:item.name}})"
                                                    :searchable="true"
                                                    :close-on-select="true"
                                                    :show-labels="false"
                                                    :custom-label="opt => listZones.find(item => item.id === opt.id).name"
                                                    :allow-empty="false"
                                                    placeholder="название помещения"
                                                >
                                                </multiselect>
                                            </td>
                                        </tr>
                                    </draggable>
                                </template>
                            </table>
                        </div>
                    </div>


                </div>

            </div>


        </div>

    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import VueContext from 'vue-context';
    import {page} from "./sub/tools";
    import Multiselect from 'vue-multiselect'
    import {toolM} from "./sub/tools";
    import ERoom0 from "./ERoom0";
    import ERoom1 from "./ERoom1";
    import ERoom2 from "./ERoom2";

    export default {
        name: "e-room",
        components: {draggable, Multiselect, ERoom0, ERoom1, ERoom2},
        props: ['eRoom', 'eMarker', 'listZones', 'listRoomName'],
        data: function () {
            return {
                pageT: new page(this),
                toolM: new toolM(this),
                nbi: {},
                local: {
                    eRoom: this.eRoom,
                    eMarker: this.eMarker,
                    eRoomF: {},
                    eMarkerF: {},
                    eMarkerFNotExpl: {},
                    eMarkerExF: {},
                    currentRoom: {},
                    listZones:this.listZones,
                },
                status: {
                    loading: false,
                },
                state: {
                    version: 4,
                    swImportW: false,
                    isEdit: false,
                    activeTab: 0,
                    search: {
                        val: ''
                    },
                },
                isMounted: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
                if (e.key === 'ctrlS') this.clickSave();
                if (e.key === 'exportAutocad') this.onExportAutocad();
                if (e.key === 'exportAutocadWs') this.pageT.clickAutocadWS('/export_autocadWs');
            });
            Bus.$on('navbar_info', obj => this.nbi = obj);
            Bus.$on('removeRow', obj => this.onRemoveRow(obj));
            Bus.$on('addRow', () => this.onAddRow());
        },
        mounted() {
            Bus.$emit('navbar', {command: 'showButtons', args: {save: true, export: true, reload:true}});
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.pageT.loadCookie();
            this.isMounted = true;
        },
        methods: {
            onOpen(obj) {
                this.local.currentRoom = obj;
            },
            onChange(e) {
                this.local.currentRoom.name = e.name;
                this.local.currentRoom.zone = e.zone;
            },
            clickTab(i) {
                Bus.$emit('localSave');
                this.state.activeTab = i;
            },
            clickSave() {
                Bus.$emit('localSave');
                this.pageT.clickSave('/room/save', {eMarker: this.local.eMarker, eRoom: this.local.eRoom}, '_save');
                Bus.$once('zResponse_save', response => {
                    this.local.eMarker.length = 0;
                    response.eMarker.forEach(item => this.local.eMarker.push(item));
                    this.local.eRoom.length = 0;
                    response.eRoom.forEach(item => this.local.eRoom.push(item));
                    Bus.$emit('updTable');
                    Bus.$emit('updSelectArr');
                });
            },
            clickReCalc() {
                this.pageT.clickSave('/room/re_calc', {}, '_re_calc');
                Bus.$once('zResponse_re_calc', response => {
                    this.local.eRoom = response.eRoom;
                });
            },
            onExportAutocad() {
                document.location.href = '/pages/' + this.nbi.projectInfo.id + '/export_autocad';
            },
            fRoomList() {
                let res = [];
                this.local.eMarker.forEach(item => {
                    let m = this.fExplList.find(item2 => item2 === item.id);
                    if (m) {
                        item.child = this.local.eRoom.filter(item3 => item3.marker.id === item.id);
                        res.push(item);
                    }
                })
                this.local.eRoomF = JSON.parse(JSON.stringify(res));
            },
            fMarkerList() {
                let res = [];
                this.local.eMarker.forEach(item => {
                    let m = this.fExplList.find(item2 => item2 === item.id);
                    if (m) {
                        item.child = this.local.eMarker.filter(item3 => item3.explication_tag === item.explication_tag && item.id !== item3.id);
                        res.push(item);
                    }
                })
                this.local.eMarkerF = JSON.parse(JSON.stringify(res));
            },
            fMarkerListNotExpl() {
                let res = [];
                this.local.eMarker.forEach(item => {
                    let m = this.fExplList.find(item2 => item2 === item.id);
                    if (!m) {
                        res.push(item);
                    }
                })
                this.local.eMarkerFNotExpl = JSON.parse(JSON.stringify(res));
            },
            fMarkerExList() {
                let res = [];
                this.local.eMarker.forEach(item => {
                    let m = this.fExplList.find(item2 => item2 === item.id);
                    if (m) {
                        res.push(item);
                    }
                })
                this.local.eMarkerExF = JSON.parse(JSON.stringify(res));
            },
        },
        computed: {
            fExplList: function () {
                //todo js поиск уникальных значений
                return this.local.eRoom.map(item => item.marker.id).filter((value, index, self) => self.indexOf(value) === index);
            },
        },
        watch: {
            'state': {
                handler: function (newVal, oldVal) {
                    this.pageT.updCookie();
                },
                deep: true
            },
        }

    }
</script>


<style scoped lang="scss">
    @import 'resources/sass/variables';

    .nav-link {
        cursor: pointer;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .form-control1{
        border: 0;
        width: 100%;
    }
</style>
