<template>
    <draggable
        tag="div"
        :list="list"
        :value="value"
        @input="emitter"
        handle=".handle"
        animation="150"
        :fallback-on-body="true"
        :swap-threshold="0.5"
        :scroll-sensitivity="100"
        :fallback-tolerance="1"
        :force-fallback="true"
        ghost-class="ghost"
        group="as"
        class="item-container"
        :disabled="!pState.propEdit"
        @end="dStop"
    >

        <div v-for="el in realValue" class="item-group">
            <div class="item" :key="el.id">
                <div class="row hover">
                    <div class="col-2 handle">
                        <span v-if="!state.propEdit">{{ el.id }}</span>
                        <span v-else-if="!getVisibleProp(el)" class="font-weight-bold" style="cursor: pointer" @click="clickSwDown(el)">{{ el.id }}</span>
                        <span v-else class="font-weight-bold" style="cursor: pointer" @click="clickSwUp(el)">{{ el.id }}</span>
                    </div>
                    <div class="col-4 text-nowrap">
                        <span>{{ el.name }}</span>
                        <font-awesome-icon v-if="el.req" icon="fa-solid fa-circle-exclamation" class="text-success"/>
                        <span class="small d-block" style="margin-top: -0.4rem">{{ el.desc }}</span>
                    </div>
                    <div class="col-3">{{ el.unit }}</div>
                    <div class="col-2">{{ el.type }}</div>
                    <div class="col-1">
                        <font-awesome-icon v-if="state.propEdit && !isImport" icon="fa-solid fa-xmark" @click="clickDelProp(el)"/>
                        <font-awesome-icon v-if="state.propEdit && isImport" icon="fa-solid fa-file-export" @click="clickImportField(el)"/>
                    </div>
                </div>
                <div v-if="getVisibleProp(el)" class="row">
                    <div class="col-2">
                        <label class="w-100">id
                            <div class="d-flex justify-content-between w-100">
                                <input class="form-control w-100" v-model.trim="el.id" disabled v-if="!editId.isEdit || editId.id!==el.id"/>
                                <input class="form-control w-100" v-model.trim="editId.newId" v-if="editId.isEdit && editId.id===el.id"/>
                                <font-awesome-icon v-if="!editId.isEdit" icon="fa-solid fa-pen" size="sm" @click="clickEditId(el,true)"
                                                   style="position: absolute; top: 2.3rem; right: 1.5rem; cursor: pointer"/>
                                <font-awesome-icon v-if="editId.isEdit && editId.id===el.id" icon="fa-solid fa-floppy-disk" size="sm" @click="clickEditId(el,false)"
                                                   style="position: absolute; top: 2.3rem; right: 1.5rem; cursor: pointer"/>
                            </div>
                        </label>

                    </div>
                    <div class="col-4">
                        <label class="w-100">название<input class="form-control" v-model.trim="el.name"/></label>
                    </div>
                    <div class="col-6">
                        <label class="w-100">описание<input class="form-control" v-model.trim="el.desc"/></label>
                    </div>
                    <div class="col-2">
                        <label class="mb-0">
                            <span>ед.изм.</span>
                        </label>
                        <multiselect
                            v-model="el.unit"
                            :options="listUnit"
                            :searchable="false"
                            :close-on-select="true"
                            :show-labels="false"
                            :allow-empty="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-2">
                        <label class="mb-0">
                            <span>тип поля</span>
                        </label>
                        <multiselect
                            v-model="el.type"
                            :options="listType"
                            :searchable="false"
                            :close-on-select="true"
                            :show-labels="false"
                            :allow-empty="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-3">
                        <label v-if="el.type!=='bool'">по умолчанию
                            <input v-if="el.type==='int'||el.type==='float'" class="form-control" v-model.number="el.val"/>
                            <input v-if="el.type!=='int'&&el.type!=='float'" class="form-control" v-model.trim="el.val"/>
                        </label>
                        <label v-if="el.type==='bool'" class="apple-switch_2 mt-3 ml-1"><input type="checkbox" v-model="el.val"><i></i>по умолчанию</label>
                    </div>
                    <div class="col-5">
                        <label title="размер поля от 1 до 12">размер поля<input class="form-control" v-model.number="el.size"/></label>
                        <label class="apple-switch_2 mt-3 ml-3"><input type="checkbox" v-model="el.req"><i></i>обязательное</label>
                    </div>

                </div>
            </div>
        </div>

    </draggable>
</template>

<script>

import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'

export default {
    name: "nested-category-fields",
    components: {draggable, Multiselect},
    props: {
        value: {
            required: false,
            type: Array,
            default: null
        },
        list: {
            required: false,
            type: Array,
            default: null
        },
        id: {
            required: false,
            type: Number,
            default: 0
        },
        pState: {
            required: true,
            type: Object,
        },
        propVisible: {
            required: true,
            type: Array,
            default() {
                return []
            }
        },
        isImport: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            editId: {
                id: '',
                newId: '',
                isEdit: false,
            },
            isMove: true,
            oldValue: this.value,
            lValue: this.value,
            state: this.pState,
            listUnit: [' ', 'шт.', 'м', 'м.п.', 'компл.', 'мм2', 'мм', 'кг', 'кг/км', 'кг/м', 'В', 'кВ', 'А', 'кА', 'мА', 'кг', 'т','Вт','кВт','ВА','кВА'],
            listType: ['string', 'int', 'text', 'float', 'bool'],
        }
    },
    mounted: function () {

    },
    methods: {

        dStop(obj) {
            console.log(obj);
        },
        clickEditId(obj, state) {
            if (state) {
                this.editId.id = this.editId.newId = obj.id;
            } else {
                obj.id = this.editId.newId;
                this.editId.id = this.editId.newId = '';
            }
            this.editId.isEdit = state;
        },
        clickRow(obj) {
            this.pState.activeRow = obj;
            this.pState.propEdit = false;
        },
        emitter(value) {
            this.$emit("input", value);
        },
        getVisibleProp(obj) {
            if (obj === undefined) return false;
            let key = this.propVisible.findIndex(item => item.id === obj.id);
            return key >= 0;
        },
        clickSwDown(obj) {
            this.propVisible.push(obj);
        },
        clickSwUp(obj) {
            let key = this.propVisible.findIndex(item => item.id === obj.id);
            if (key >= 0) this.propVisible.splice(key, 1);
        },
        clickDelProp(obj) {
            let key = this.state.activeRow.prop.fields.findIndex(item => item.id === obj.id);
            if (key >= 0) this.state.activeRow.prop.fields.splice(key, 1);
        },
        clickImportField(obj) {
            let key = this.state.activeRow.prop.fields.findIndex(item => item.id === obj.id);
            if (key >= 0) this.state.activeRow.prop.fields.splice(key, 1, obj);
            else this.state.activeRow.prop.fields.push(obj)
        },
    },
    computed: {
        realValue() {
            return this.value ? this.value : this.list;
        },
    },
}
</script>

<style scoped lang="scss">
@import 'resources/sass/variables';

.ghost {
    opacity: 0.3;
    background: #c8ebfb;
}

.item-container {
    margin: 0;
    cursor: pointer;
}

.item-sub {
    margin: 0 0 0 1rem;
    cursor: pointer;
}

.item {
    &.active {
        background-color: lighten($primary, 40%);
    }

    &:hover {
        background-color: lighten($primary, 20%);
    }
}


</style>
