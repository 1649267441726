var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-fluid m1" },
      [
        _c("title-for-page", {
          attrs: { "c-page": _vm.state.cPage, nbi: _vm.nbi },
        }),
        _vm._v(" "),
        _vm.isMounted
          ? _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-12 col-xl-8" },
                [
                  _c(
                    "nav",
                    {
                      staticClass: "navbar navbar-expand pl-0 pt-0",
                      staticStyle: { "margin-left": "-0.5rem" },
                    },
                    [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c("div", { staticClass: "navbar-nav" }, [
                          _c("div", { staticClass: "nav-item" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-link btn",
                                class: { active: _vm.state.swOnOpt },
                                attrs: { title: "настройки страницы" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.swOnOpt = !_vm.state.swOnOpt
                                  },
                                },
                              },
                              [_vm._v("настройки")]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.ePotr.length > 0
                            ? _c("div", { staticClass: "nav-item" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "nav-link btn",
                                    class: { active: _vm.state.swOnOpt2 },
                                    attrs: { title: "настройки страницы" },
                                    on: {
                                      click: function ($event) {
                                        _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                      },
                                    },
                                  },
                                  [_vm._v("настройки страницы")]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("card-options", {
                    staticClass: "mb-3",
                    attrs: { "c-page": _vm.state.cPage, state: _vm.state },
                  }),
                  _vm._v(" "),
                  _vm.state.swOnOpt2
                    ? _c("div", { staticClass: "card mb-3" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _vm.ePotr.length > 0
                            ? _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-5" }, [
                                    _c("p", [_vm._v("Расчётная точка")]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-check d-inline-block",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "form-check-label" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.cOpt.data.calcPoint
                                                      .type,
                                                  expression:
                                                    "cOpt.data.calcPoint.type",
                                                },
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                value: "eBbs",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.cOpt.data.calcPoint.type,
                                                  "eBbs"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.cOpt.data.calcPoint,
                                                    "type",
                                                    "eBbs"
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(
                                              " секция шин\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-check d-inline-block ml-3",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "form-check-label" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.cOpt.data.calcPoint
                                                      .type,
                                                  expression:
                                                    "cOpt.data.calcPoint.type",
                                                },
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                value: "ePotr",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.cOpt.data.calcPoint.type,
                                                  "ePotr"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    _vm.cOpt.data.calcPoint,
                                                    "type",
                                                    "ePotr"
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(
                                              " потребитель\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-3 text-danger small",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            _vm.cOpt.data.calcPoint.id = 0
                                          },
                                        },
                                      },
                                      [_vm._v("сброс")]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _vm.cOpt.data.calcPoint.type === "ePotr"
                                    ? _c(
                                        "div",
                                        { staticClass: "col-5" },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              options: _vm.local.ePotr.map(
                                                (item) => item.id
                                              ),
                                              "show-labels": false,
                                              searchable: true,
                                              "close-on-select": true,
                                              "allow-empty": false,
                                              "internal-search": false,
                                            },
                                            on: {
                                              "search-change": _vm.customSearch,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "singleLabel",
                                                  fn: function (props) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "option__desc",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getMSStr(
                                                                    props.option
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "option",
                                                  fn: function (props) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "option__desc",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getMSStr(
                                                                    props.option
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3522616897
                                            ),
                                            model: {
                                              value: _vm.cOpt.data.calcPoint.id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.cOpt.data.calcPoint,
                                                  "id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cOpt.data.calcPoint.id",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.cOpt.data.calcPoint.type === "eBbs"
                                    ? _c(
                                        "div",
                                        { staticClass: "col-5" },
                                        [
                                          _c("multiselect", {
                                            attrs: {
                                              options: _vm.local.eBbs.map(
                                                (item) => item.id
                                              ),
                                              "show-labels": false,
                                              searchable: true,
                                              "close-on-select": true,
                                              "allow-empty": false,
                                              "internal-search": false,
                                            },
                                            on: {
                                              "search-change":
                                                _vm.customSearchBbs,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "singleLabel",
                                                  fn: function (props) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "option__desc",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getMSStrBbs(
                                                                    props.option
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "option",
                                                  fn: function (props) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "option__desc",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "option__title",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getMSStrBbs(
                                                                    props.option
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1113787233
                                            ),
                                            model: {
                                              value: _vm.cOpt.data.calcPoint.id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.cOpt.data.calcPoint,
                                                  "id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cOpt.data.calcPoint.id",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 d-flex align-items-end pb-2",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: { click: _vm.clickSavePoint },
                                        },
                                        [_vm._v("сохранить")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", [_vm._v("Установка исходных данных")]),
                          _vm._v(" "),
                          _vm.eBbs.length > 0
                            ? _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-3" },
                                  [
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _c("multiselect", {
                                      attrs: {
                                        options: _vm.eBbs,
                                        searchable: true,
                                        "close-on-select": true,
                                        "show-labels": false,
                                        "custom-label": (opt) =>
                                          opt.panel.name +
                                          " (" +
                                          opt.name +
                                          ")",
                                        "allow-empty": false,
                                        placeholder: "название помещения",
                                      },
                                      model: {
                                        value: _vm.currentBbs,
                                        callback: function ($$v) {
                                          _vm.currentBbs = $$v
                                        },
                                        expression: "currentBbs",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("span", { staticClass: "small" }, [
                                      _vm._v("падение напряжения, %"),
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.currentBbs.es.dU,
                                          expression: "currentBbs.es.dU",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      domProps: { value: _vm.currentBbs.es.dU },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.currentBbs.es,
                                            "dU",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-3 d-flex align-items-end pb-2",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: { click: _vm.clickLoadBbs },
                                      },
                                      [_vm._v("загрузить")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary ml-3",
                                        on: { click: _vm.clickSaveBbs },
                                      },
                                      [_vm._v("сохранить")]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.typeReport,
                            expression: "cOpt.data.setting.typeReport",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "radio", value: "long" },
                        domProps: {
                          checked: _vm._q(
                            _vm.cOpt.data.setting.typeReport,
                            "long"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.cOpt.data.setting,
                              "typeReport",
                              "long"
                            )
                          },
                        },
                      }),
                      _vm._v(" подробный отчёт\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-3" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.typeReport,
                            expression: "cOpt.data.setting.typeReport",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "radio", value: "short" },
                        domProps: {
                          checked: _vm._q(
                            _vm.cOpt.data.setting.typeReport,
                            "short"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.cOpt.data.setting,
                              "typeReport",
                              "short"
                            )
                          },
                        },
                      }),
                      _vm._v(" краткий отчёт\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-5" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.ikz3,
                            expression: "cOpt.data.setting.ikz3",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", value: "short" },
                        domProps: {
                          checked: Array.isArray(_vm.cOpt.data.setting.ikz3)
                            ? _vm._i(_vm.cOpt.data.setting.ikz3, "short") > -1
                            : _vm.cOpt.data.setting.ikz3,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cOpt.data.setting.ikz3,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "short",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz3",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz3",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.cOpt.data.setting, "ikz3", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" 3хф КЗ\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-3" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.ikz3r,
                            expression: "cOpt.data.setting.ikz3r",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", value: "short" },
                        domProps: {
                          checked: Array.isArray(_vm.cOpt.data.setting.ikz3r)
                            ? _vm._i(_vm.cOpt.data.setting.ikz3r, "short") > -1
                            : _vm.cOpt.data.setting.ikz3r,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cOpt.data.setting.ikz3r,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "short",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz3r",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz3r",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.cOpt.data.setting, "ikz3r", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" 3хфД КЗ\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-3" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.ikz3sr,
                            expression: "cOpt.data.setting.ikz3sr",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", value: "short" },
                        domProps: {
                          checked: Array.isArray(_vm.cOpt.data.setting.ikz3sr)
                            ? _vm._i(_vm.cOpt.data.setting.ikz3sr, "short") > -1
                            : _vm.cOpt.data.setting.ikz3sr,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cOpt.data.setting.ikz3sr,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "short",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz3sr",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz3sr",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.cOpt.data.setting, "ikz3sr", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" 3хф.ср КЗ\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-3" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.ikz2,
                            expression: "cOpt.data.setting.ikz2",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", value: "short" },
                        domProps: {
                          checked: Array.isArray(_vm.cOpt.data.setting.ikz2)
                            ? _vm._i(_vm.cOpt.data.setting.ikz2, "short") > -1
                            : _vm.cOpt.data.setting.ikz2,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cOpt.data.setting.ikz2,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "short",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz2",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz2",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.cOpt.data.setting, "ikz2", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" 2хф КЗ\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-3" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.ikz2r,
                            expression: "cOpt.data.setting.ikz2r",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", value: "short" },
                        domProps: {
                          checked: Array.isArray(_vm.cOpt.data.setting.ikz2r)
                            ? _vm._i(_vm.cOpt.data.setting.ikz2r, "short") > -1
                            : _vm.cOpt.data.setting.ikz2r,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cOpt.data.setting.ikz2r,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "short",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz2r",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz2r",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.cOpt.data.setting, "ikz2r", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" 2хфД КЗ\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-3" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.ikz1,
                            expression: "cOpt.data.setting.ikz1",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", value: "short" },
                        domProps: {
                          checked: Array.isArray(_vm.cOpt.data.setting.ikz1)
                            ? _vm._i(_vm.cOpt.data.setting.ikz1, "short") > -1
                            : _vm.cOpt.data.setting.ikz1,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cOpt.data.setting.ikz1,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "short",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz1",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz1",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.cOpt.data.setting, "ikz1", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" 1ф КЗ\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check d-inline-block ml-3" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cOpt.data.setting.ikz1r,
                            expression: "cOpt.data.setting.ikz1r",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox", value: "short" },
                        domProps: {
                          checked: Array.isArray(_vm.cOpt.data.setting.ikz1r)
                            ? _vm._i(_vm.cOpt.data.setting.ikz1r, "short") > -1
                            : _vm.cOpt.data.setting.ikz1r,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cOpt.data.setting.ikz1r,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "short",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz1r",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.cOpt.data.setting,
                                    "ikz1r",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.cOpt.data.setting, "ikz1r", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" 1фД КЗ\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-danger ml-2",
                      staticStyle: { float: "right" },
                      attrs: { type: "button" },
                      on: { click: _vm.clickReset },
                    },
                    [
                      _vm.status.reset
                        ? _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw",
                          })
                        : _vm._e(),
                      _vm._v(" reset\n                "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("table", { staticClass: "table border mb-5" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.cOpt.data.rSchema, function (value, key) {
                          return [
                            value.type === "system"
                              ? _c("tr", { key: key }, [
                                  _c("td", { staticClass: "text-left" }, [
                                    value.ikz === value.ikzmin
                                      ? _c(
                                          "p",
                                          {
                                            staticClass: "mb-0 text-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickLock(value)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Система " +
                                                _vm._s(value.ikz) +
                                                "кА"
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "p",
                                          {
                                            staticClass: "mb-0 text-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickLock(value)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Система " +
                                                _vm._s(value.ikzmin) +
                                                "-" +
                                                _vm._s(value.ikz) +
                                                "кА"
                                            ),
                                          ]
                                        ),
                                    _vm._v(" "),
                                    value.dop
                                      ? _c("div", [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c(
                                                  "label",
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "small" },
                                                      [
                                                        _vm._v(
                                                          "Ток короткого замыкания на зажимах ВН трансформатора, кА"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("vue-autonumeric", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        options:
                                                          _vm.optionsForFloat(
                                                            1000,
                                                            1
                                                          ),
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeIkzSystem(
                                                            "ikz"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: value.ikz,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            value,
                                                            "ikz",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression: "value.ikz",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c(
                                                  "label",
                                                  [
                                                    _vm._m(2, true),
                                                    _vm._v(" "),
                                                    _c("vue-autonumeric", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        options:
                                                          _vm.optionsForFloat(
                                                            1000,
                                                            1
                                                          ),
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeIkzSystem(
                                                            "ikzmin"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: value.ikzmin,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            value,
                                                            "ikzmin",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "value.ikzmin",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "small text-secondary m-0 p-0",
                                            },
                                            [
                                              _vm._v(
                                                "r=" +
                                                  _vm._s(value.r) +
                                                  " x=" +
                                                  _vm._s(value.x)
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3sr))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1r))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "text-danger",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          icon: "fa-solid fa-xmark",
                                          size: "lg",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickDelRow(value)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            value.type === "transformer"
                              ? _c("tr", { key: key }, [
                                  _c("td", { staticClass: "text-left" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "mb-0 text-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickLock(value)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Трансформатор\n                                    "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(value.obj.name) +
                                              " " +
                                              _vm._s(value.obj.uv) +
                                              "/" +
                                              _vm._s(value.obj.un) +
                                              "кВ " +
                                              _vm._s(value.obj.sn) +
                                              "кВА " +
                                              _vm._s(value.obj.shem)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        value.nameTp
                                          ? _c("span", [
                                              _vm._v(
                                                "в " + _vm._s(value.nameTp)
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              "~" + _vm._s(value.obj.in) + "А"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    value.dop
                                      ? _c(
                                          "div",
                                          [
                                            _c("multiselect", {
                                              attrs: {
                                                options: _vm.listTransformer,
                                                searchable: true,
                                                "close-on-select": true,
                                                "show-labels": false,
                                                "allow-empty": false,
                                                "custom-label": (opt) =>
                                                  opt.name +
                                                  " " +
                                                  opt.uv +
                                                  "/" +
                                                  opt.un +
                                                  "кВ " +
                                                  opt.sn +
                                                  "кВА " +
                                                  opt.shem,
                                              },
                                              model: {
                                                value: value.obj,
                                                callback: function ($$v) {
                                                  _vm.$set(value, "obj", $$v)
                                                },
                                                expression: "value.obj",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              { staticClass: "w-100 mt-2" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "small" },
                                                  [_vm._v("Название ТП")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: value.nameTp,
                                                      expression:
                                                        "value.nameTp",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    placeholder:
                                                      "введите название ТП если необходимо",
                                                  },
                                                  domProps: {
                                                    value: value.nameTp,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        value,
                                                        "nameTp",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "small text-secondary m-0 p-0",
                                              },
                                              [
                                                _vm._v("u"),
                                                _c("sub", [_vm._v("кз")]),
                                                _vm._v(
                                                  "=" +
                                                    _vm._s(value.obj.uk) +
                                                    " P"
                                                ),
                                                _c("sub", [_vm._v("кз")]),
                                                _vm._v(
                                                  "=" +
                                                    _vm._s(value.obj.pkz) +
                                                    "  r=" +
                                                    _vm._s(value.r) +
                                                    " x=" +
                                                    _vm._s(value.x) +
                                                    " z/3=" +
                                                    _vm._s(value.z3)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3sr))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1r))]),
                                  _vm._v(" "),
                                  _c("td"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            value.type === "shina"
                              ? _c("tr", { key: key }, [
                                  _c("td", { staticClass: "text-left" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 text-primary d-inline-block",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickLock(value)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Шины " +
                                            _vm._s(value.obj.w) +
                                            "х" +
                                            _vm._s(value.obj.h) +
                                            "мм, l=" +
                                            _vm._s(value.l) +
                                            "м\n                                    "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v("~"),
                                            value.isCu
                                              ? _c("span", [
                                                  _vm._v(_vm._s(value.obj.icu)),
                                                ])
                                              : _vm._e(),
                                            !value.isCu
                                              ? _c("span", [
                                                  _vm._v(_vm._s(value.obj.ial)),
                                                ])
                                              : _vm._e(),
                                            _vm._v("А"),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-3 small",
                                        on: {
                                          click: function ($event) {
                                            value.lock = !value.lock
                                          },
                                        },
                                      },
                                      [
                                        value.lock
                                          ? _c("i", {
                                              staticClass: "fa fa-lock",
                                            })
                                          : _vm._e(),
                                        !value.lock
                                          ? _c("i", {
                                              staticClass: "fa fa-lock-open",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    value.dop
                                      ? _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-secondary",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                              },
                                              [
                                                _vm._v("алюминий "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: value.isCu,
                                                      expression: "value.isCu",
                                                    },
                                                  ],
                                                  staticClass: "apple-switch",
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      value.isCu
                                                    )
                                                      ? _vm._i(
                                                          value.isCu,
                                                          null
                                                        ) > -1
                                                      : value.isCu,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a = value.isCu,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              value,
                                                              "isCu",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              value,
                                                              "isCu",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          value,
                                                          "isCu",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" медь"),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          !value.isCu
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "col-6",
                                                  staticStyle: {
                                                    "margin-top": "1.2rem",
                                                  },
                                                },
                                                [
                                                  _c("multiselect", {
                                                    attrs: {
                                                      options: _vm.listShina,
                                                      searchable: true,
                                                      "close-on-select": true,
                                                      "show-labels": false,
                                                      "allow-empty": false,
                                                      "custom-label": (opt) =>
                                                        opt.w +
                                                        "х" +
                                                        opt.h +
                                                        " ~" +
                                                        opt.ial +
                                                        "А",
                                                    },
                                                    model: {
                                                      value: value.obj,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          value,
                                                          "obj",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "value.obj",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          value.isCu
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "col-6",
                                                  staticStyle: {
                                                    "margin-top": "1.2rem",
                                                  },
                                                },
                                                [
                                                  _c("multiselect", {
                                                    attrs: {
                                                      options: _vm.listShina,
                                                      searchable: true,
                                                      "close-on-select": true,
                                                      "show-labels": false,
                                                      "allow-empty": false,
                                                      "custom-label": (opt) =>
                                                        opt.w +
                                                        "х" +
                                                        opt.h +
                                                        " ~" +
                                                        opt.icu +
                                                        "А",
                                                    },
                                                    model: {
                                                      value: value.obj,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          value,
                                                          "obj",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "value.obj",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-3" }, [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "small",
                                                attrs: {
                                                  title:
                                                    "расстояние между шинами",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            расстояние, мм\n                                            "
                                                ),
                                                _c("vue-autonumeric", {
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    options:
                                                      _vm.optionsForFloat(
                                                        1000,
                                                        0
                                                      ),
                                                  },
                                                  model: {
                                                    value: value.a,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        value,
                                                        "a",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression: "value.a",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-3" }, [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "small",
                                                attrs: { title: "длина шин" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            длина, м\n                                            "
                                                ),
                                                _c("vue-autonumeric", {
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    options:
                                                      _vm.optionsForFloat(
                                                        1000,
                                                        0
                                                      ),
                                                  },
                                                  model: {
                                                    value: value.l,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        value,
                                                        "l",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression: "value.l",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "small text-secondary m-0 p-0",
                                              },
                                              [
                                                _vm._v(
                                                  "r=" +
                                                    _vm._s(value.r) +
                                                    " x=" +
                                                    _vm._s(value.x)
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3sr))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1r))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "text-danger",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          icon: "fa-solid fa-xmark",
                                          size: "lg",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickDelRow(value)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            value.type === "cable"
                              ? _c("tr", { key: key }, [
                                  _c("td", { staticClass: "text-left" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 text-primary d-inline-block",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickLock(value)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Кабель "
                                        ),
                                        value.amount > 1
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(value.amount) + "х"
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          _vm._s(value.marka) +
                                            " " +
                                            _vm._s(value.obj.name) +
                                            "мм"
                                        ),
                                        _c("sup", [_vm._v("2")]),
                                        _vm._v(
                                          ", l=" +
                                            _vm._s(value.l) +
                                            "м\n                                    "
                                        ),
                                        value.ru
                                          ? _c("span", [
                                              _vm._v(
                                                " от щита " + _vm._s(value.ru)
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v("~"),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.getIForCable(value))
                                              ),
                                            ]),
                                            _vm._v("А"),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-3 small",
                                        on: {
                                          click: function ($event) {
                                            value.lock = !value.lock
                                          },
                                        },
                                      },
                                      [
                                        value.lock
                                          ? _c("i", {
                                              staticClass: "fa fa-lock",
                                            })
                                          : _vm._e(),
                                        !value.lock
                                          ? _c("i", {
                                              staticClass: "fa fa-lock-open",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    value.dop
                                      ? _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-secondary",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                              },
                                              [
                                                _vm._v("алюминий "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: value.isCu,
                                                      expression: "value.isCu",
                                                    },
                                                  ],
                                                  staticClass: "apple-switch",
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      value.isCu
                                                    )
                                                      ? _vm._i(
                                                          value.isCu,
                                                          null
                                                        ) > -1
                                                      : value.isCu,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a = value.isCu,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              value,
                                                              "isCu",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              value,
                                                              "isCu",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          value,
                                                          "isCu",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" медь"),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-2" }, [
                                            _c(
                                              "label",
                                              { staticClass: "small w-100" },
                                              [
                                                _vm._v(
                                                  "\n                                            кол-во\n                                            "
                                                ),
                                                _c("vue-autonumeric", {
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    options:
                                                      _vm.optionsForFloat(
                                                        10,
                                                        0
                                                      ),
                                                  },
                                                  model: {
                                                    value: value.amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        value,
                                                        "amount",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression: "value.amount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-3" }, [
                                            _c(
                                              "label",
                                              { staticClass: "small w-100" },
                                              [
                                                _vm._v(
                                                  "\n                                            марка кабеля\n                                            "
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: value.marka,
                                                      expression: "value.marka",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  domProps: {
                                                    value: value.marka,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        value,
                                                        "marka",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-5" }, [
                                            _vm._m(3, true),
                                            _vm._v(" "),
                                            !value.isCu
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("multiselect", {
                                                      attrs: {
                                                        options: _vm.listCable,
                                                        searchable: true,
                                                        "close-on-select": true,
                                                        "show-labels": false,
                                                        "allow-empty": false,
                                                        "custom-label": (opt) =>
                                                          opt.name +
                                                          " ~" +
                                                          opt.ial +
                                                          "А",
                                                      },
                                                      model: {
                                                        value: value.obj,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            value,
                                                            "obj",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "value.obj",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            value.isCu
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("multiselect", {
                                                      attrs: {
                                                        options: _vm.listCable,
                                                        searchable: true,
                                                        "close-on-select": true,
                                                        "show-labels": false,
                                                        "allow-empty": false,
                                                        "custom-label": (opt) =>
                                                          opt.name +
                                                          " ~" +
                                                          opt.icu +
                                                          "А",
                                                      },
                                                      model: {
                                                        value: value.obj,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            value,
                                                            "obj",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "value.obj",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-2 pt-1" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "small",
                                                  attrs: {
                                                    title: "длина кабеля",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            длина, м\n                                            "
                                                  ),
                                                  _c("vue-autonumeric", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      options:
                                                        _vm.optionsForFloat(
                                                          10000,
                                                          0
                                                        ),
                                                    },
                                                    model: {
                                                      value: value.l,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          value,
                                                          "l",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression: "value.l",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "small text-secondary m-0 p-0",
                                              },
                                              [
                                                _vm._v(
                                                  "r=" +
                                                    _vm._s(value.r) +
                                                    " x=" +
                                                    _vm._s(value.x) +
                                                    " zп=" +
                                                    _vm._s(value.zp)
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3sr))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1r))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "text-danger",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          icon: "fa-solid fa-xmark",
                                          size: "lg",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickDelRow(value)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            value.type === "tt"
                              ? _c("tr", { key: key }, [
                                  _c("td", { staticClass: "text-left" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 text-primary d-inline-block",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickLock(value)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Трансформаторы тока " +
                                            _vm._s(value.marka) +
                                            " " +
                                            _vm._s(value.obj.name) +
                                            " кл.точн." +
                                            _vm._s(value.obj.tochn)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-3 small",
                                        on: {
                                          click: function ($event) {
                                            value.lock = !value.lock
                                          },
                                        },
                                      },
                                      [
                                        value.lock
                                          ? _c("i", {
                                              staticClass: "fa fa-lock",
                                            })
                                          : _vm._e(),
                                        !value.lock
                                          ? _c("i", {
                                              staticClass: "fa fa-lock-open",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    value.dop
                                      ? _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-6 pt-1" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "small w-100",
                                                  attrs: {
                                                    title:
                                                      "марка трансформатора тока",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            марка ТТ\n                                            "
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: value.marka,
                                                        expression:
                                                          "value.marka",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    domProps: {
                                                      value: value.marka,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          value,
                                                          "marka",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-6" },
                                            [
                                              _vm._m(4, true),
                                              _vm._v(" "),
                                              _c("multiselect", {
                                                attrs: {
                                                  options: _vm.listTT,
                                                  searchable: true,
                                                  "close-on-select": true,
                                                  "show-labels": false,
                                                  "allow-empty": false,
                                                  "custom-label": (opt) =>
                                                    opt.name +
                                                    " кл.точн." +
                                                    opt.tochn,
                                                },
                                                model: {
                                                  value: value.obj,
                                                  callback: function ($$v) {
                                                    _vm.$set(value, "obj", $$v)
                                                  },
                                                  expression: "value.obj",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "small text-secondary m-0 p-0",
                                              },
                                              [
                                                _vm._v(
                                                  "r=" +
                                                    _vm._s(value.r) +
                                                    " x=" +
                                                    _vm._s(value.x)
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3sr))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1r))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "text-danger",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          icon: "fa-solid fa-xmark",
                                          size: "lg",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickDelRow(value)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            value.type === "ab"
                              ? _c("tr", { key: key }, [
                                  _c("td", { staticClass: "text-left" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 text-primary d-inline-block",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickLock(value)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Аппарат " +
                                            _vm._s(value.marka) +
                                            " " +
                                            _vm._s(value.obj.name) +
                                            " " +
                                            _vm._s(value.obj.in) +
                                            "А"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-3 small",
                                        on: {
                                          click: function ($event) {
                                            value.lock = !value.lock
                                          },
                                        },
                                      },
                                      [
                                        value.lock
                                          ? _c("i", {
                                              staticClass: "fa fa-lock",
                                            })
                                          : _vm._e(),
                                        !value.lock
                                          ? _c("i", {
                                              staticClass: "fa fa-lock-open",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    value.dop
                                      ? _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-6 pt-1" },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "small w-100" },
                                                [
                                                  _vm._v(
                                                    "\n                                            марка\n                                            "
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: value.marka,
                                                        expression:
                                                          "value.marka",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    domProps: {
                                                      value: value.marka,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          value,
                                                          "marka",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-6" },
                                            [
                                              _vm._m(5, true),
                                              _vm._v(" "),
                                              _c("multiselect", {
                                                attrs: {
                                                  options: _vm.listAB,
                                                  searchable: true,
                                                  "close-on-select": true,
                                                  "show-labels": false,
                                                  "allow-empty": false,
                                                  "custom-label": (opt) =>
                                                    opt.name +
                                                    " " +
                                                    opt.in +
                                                    "А",
                                                },
                                                model: {
                                                  value: value.obj,
                                                  callback: function ($$v) {
                                                    _vm.$set(value, "obj", $$v)
                                                  },
                                                  expression: "value.obj",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "small text-secondary m-0 p-0",
                                              },
                                              [
                                                _vm._v(
                                                  "r=" +
                                                    _vm._s(value.r) +
                                                    " x=" +
                                                    _vm._s(value.x)
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3sr))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz3r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz2r))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(value.ikz1r))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "text-danger",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          icon: "fa-solid fa-xmark",
                                          size: "lg",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickDelRow(value)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.getButtons, function (value) {
                    return [
                      value === "system"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.addE("system")
                                },
                              },
                            },
                            [_vm._v("система")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      value === "tr"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.addE("transformer")
                                },
                              },
                            },
                            [_vm._v("трансформатор")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      value === "shina"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.addE("shina")
                                },
                              },
                            },
                            [_vm._v("шины")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      value === "cable"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.addE("cable")
                                },
                              },
                            },
                            [_vm._v("кабель")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      value === "tt"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.addE("tt")
                                },
                              },
                            },
                            [_vm._v("ТТ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      value === "ab"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.addE("ab")
                                },
                              },
                            },
                            [_vm._v("коммутационные аппараты")]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                ],
                2
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("секция шин")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "w-50" }, [_vm._v("элемент расчётной схемы")]),
        _vm._v(" "),
        _c(
          "th",
          {
            attrs: {
              title: "ток 3х фазного, наиболее вероятного, короткого замыкания",
            },
          },
          [_vm._v("3хф.ср, кА")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            attrs: {
              title:
                "ток металлического 3х фазного короткого замыкания в конце участка",
            },
          },
          [_vm._v("3хф, кА")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            attrs: {
              title:
                "ток 3х фазного короткого замыкания в конце участка с учётом сопротитвления дуги",
            },
          },
          [_vm._v("3хфД, кА")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            attrs: {
              title:
                "ток металлического 2х фазного короткого замыкания в конце участка",
            },
          },
          [_vm._v("2хф, кА")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            attrs: {
              title:
                "ток 2х фазного короткого замыкания в конце участка с учётом сопротитвления дуги",
            },
          },
          [_vm._v("2хфД, кА")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            attrs: {
              title:
                "ток металлического 1 фазного короткого замыкания в конце участка",
            },
          },
          [_vm._v("1ф, кА")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            attrs: {
              title:
                "ток 1 фазного короткого замыкания в конце участка с учётом сопротитвления дуги",
            },
          },
          [_vm._v("1фД, кА")]
        ),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "3rem" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "small" }, [
      _vm._v("Ток короткого замыкания на зажимах ВН трансформатора, "),
      _c("b", [_vm._v("минимальный")]),
      _vm._v(", кА"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("сечение")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("трансформатор тока")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [
        _vm._v("номинал коммутационного аппарата"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }