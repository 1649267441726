var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._v("\n                редактирование тегов\n            "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal-body",
              staticStyle: { "max-height": "30rem", overflow: "auto" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between mb-3" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "w-100 mr-2",
                      staticStyle: { position: "relative" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search,
                            expression: "search",
                          },
                        ],
                        ref: "v_focus",
                        staticClass: "form-control w-100",
                        domProps: { value: _vm.search },
                        on: {
                          click: _vm.setClear,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.search = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "fa-solid fa-eraser",
                          size: "lg",
                          title: "очистить",
                        },
                        on: {
                          click: function ($event) {
                            _vm.search = ""
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "mt-1",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          icon: "fa-solid fa-plus",
                          size: "lg",
                          border: "",
                          title: "добавить",
                        },
                        on: { click: _vm.clickAdd },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table table-sm" },
                _vm._l(_vm.fTable, function (val, index) {
                  return _c("tr", { key: val.id }, [
                    val.id !== _vm.currentId
                      ? _c(
                          "td",
                          {
                            staticClass: "text-left",
                            staticStyle: {
                              cursor: "pointer",
                              "text-decoration": "underline",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setActive(val)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(val.name) + "\n                        "
                            ),
                          ]
                        )
                      : _c("td", { staticClass: "text-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: val.name,
                                expression: "val.name",
                              },
                            ],
                            staticClass: "form-control",
                            domProps: { value: val.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(val, "name", $event.target.value)
                              },
                            },
                          }),
                        ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "p-2", staticStyle: { width: "5rem" } },
                      [
                        _c(
                          "div",
                          {
                            style: { backgroundColor: val.color },
                            on: {
                              click: function ($event) {
                                return _vm.showModalColorPicker(val)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              style: { color: _vm.invertHex(val.color) },
                              attrs: {
                                icon: "fa-solid fa-eye-dropper",
                                size: "xs",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-danger",
                          attrs: {
                            icon: "fa-solid fa-xmark",
                            title: "удалить",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.clickRemove(val)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal-footer justify-content-between",
              on: { click: _vm.setClear },
            },
            [
              _c("div"),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.clickSave },
                  },
                  [_vm._v("сохранить")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [_vm._v("отмена")]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-color-picker"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }