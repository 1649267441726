var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-fluid m1" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _vm.nbi.authUser
          ? _c(
              "div",
              { staticClass: "col-12 col-xl-6 py-5" },
              [
                _c("p", { staticClass: "mb-0 mt-5 font-weight-bold" }, [
                  _vm._v("сопоставить файл с проектом"),
                ]),
                _vm._v(" "),
                _c("multiselect", {
                  attrs: {
                    options: _vm.listProject.map((item) => item.id),
                    searchable: true,
                    "close-on-select": true,
                    "show-labels": false,
                    "custom-label": (opt) =>
                      _vm.labelSearch(_vm.listProject, opt),
                  },
                  model: {
                    value: _vm.nbi.authUser.prop.loadProjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.nbi.authUser.prop, "loadProjectId", $$v)
                    },
                    expression: "nbi.authUser.prop.loadProjectId",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.clickSave } },
          [
            _vm.status.loading
              ? _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
              : _vm._e(),
            _vm._v("сопоставить"),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }