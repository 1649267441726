<template>
    <label class="w-100" :title="title">
        <span class="small">{{label}}</span>
        <span class="w-100">
            <input v-if="isNumber" class="form-control" v-model.number="inputVal" :placeholder="label" :class="{lock:lock[k]}" @keyup="lock[k]=false" @click="lock[k]=false"/>
            <input v-else class="form-control" v-model.trim="inputVal" :placeholder="label" :class="{lock:lock[k]}" @keyup="lock[k]=false" @click="lock[k]=false"/>
            <font-awesome-icon v-if="lock[k]" icon="fa-solid fa-lock"/>
        </span>
        <span v-for="e in listTag" class="small tag" @click="clickTag(e)">{{e}} </span>
    </label>
</template>
<script>
    export default {
        name: 'e-input',
        props: {
            listTag: '',
            value: '',
            label: '',
            title: '',
            lock: '',
            k: '',
            isNumber: {type: Boolean, default: true,},
        },
        methods: {
            clickTag(e) {
                this.inputVal = e;
                this.lock[this.k] = false;
            },
        },
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    if (this.isNumber) this.$emit('input', val * 1);
                    else this.$emit('input', val);
                }
            }
        }
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    span.small.tag {
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }


    svg.fa-lock {
        position: absolute;
        right: 34px;
        top: 36px;
        opacity: 0.2;
    }

    input.lock {
        background-color: lighten($primary, 50%);
    }

</style>
