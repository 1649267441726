var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMount
    ? _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: { id: _vm.modalId, role: "dialog", "data-keyboard": "false" },
          on: { click: _vm.closeModal },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-xl",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-header",
                    staticStyle: { cursor: "move" },
                  },
                  [
                    _c("c-title-so", {
                      model: {
                        value: _vm.state.obj,
                        callback: function ($$v) {
                          _vm.$set(_vm.state, "obj", $$v)
                        },
                        expression: "state.obj",
                      },
                    }),
                    _vm._v(" "),
                    _vm.state.isEdit
                      ? _c("span", [_vm._v("редактировать")])
                      : _c("span", [_vm._v("вставить потребитель")]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "text-danger mt-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                      on: { click: _vm.closeModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isShow
                  ? _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          staticStyle: { "max-height": "calc(100vh - 236px)" },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "overflow-x": "hidden" } },
                            [
                              _c("b-input-val-so", {
                                model: {
                                  value: _vm.state.obj,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.state, "obj", $$v)
                                  },
                                  expression: "state.obj",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                { staticClass: "apple-switch_2 mr-3" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.state.obj.isCalculate,
                                        expression: "state.obj.isCalculate",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.state.obj.isCalculate
                                      )
                                        ? _vm._i(
                                            _vm.state.obj.isCalculate,
                                            null
                                          ) > -1
                                        : _vm.state.obj.isCalculate,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.state.obj.isCalculate,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.state.obj,
                                                "isCalculate",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.state.obj,
                                                "isCalculate",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.state.obj,
                                            "isCalculate",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c("i"),
                                  _vm._v("учитывать в расчётах блока"),
                                ]
                              ),
                              _vm._v(" "),
                              _c("c-input-razdel-name-so", {
                                model: {
                                  value: _vm.state.obj,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.state, "obj", $$v)
                                  },
                                  expression: "state.obj",
                                },
                              }),
                              _vm._v(" "),
                              _c("c-input-desc-so", {
                                model: {
                                  value: _vm.state.obj.element,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.state.obj, "element", $$v)
                                  },
                                  expression: "state.obj.element",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer d-flex flex-row" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.clickLocalSave },
                    },
                    [_vm._v("сохранить")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("закрыть")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }