var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMount
    ? _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: { id: _vm.modalId, role: "dialog", "data-keyboard": "false" },
          on: { click: _vm.closeModal },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-xl",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-header",
                    staticStyle: { cursor: "move" },
                  },
                  [
                    _c("c-title-so", {
                      attrs: { "is-razdel": true },
                      model: {
                        value: _vm.state.obj,
                        callback: function ($$v) {
                          _vm.$set(_vm.state, "obj", $$v)
                        },
                        expression: "state.obj",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.state.obj?.name))]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "text-danger mt-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                      on: { click: _vm.closeModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isShow
                  ? _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          staticStyle: { "max-height": "calc(100vh - 236px)" },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "overflow-x": "hidden" } },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.state.obj.name,
                                        expression: "state.obj.name",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control w-100",
                                    attrs: {
                                      title: "название блока",
                                      placeholder: "название блока",
                                    },
                                    domProps: { value: _vm.state.obj.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.state.obj,
                                          "name",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Количество: " +
                                        _vm._s(_vm.state.obj.dParam.count) +
                                        "шт."
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("c-list-v-so", {
                                model: {
                                  value: _vm.state.obj.v,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.state.obj, "v", $$v)
                                  },
                                  expression: "state.obj.v",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer d-flex flex-row" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.clickLocalSave },
                    },
                    [_vm._v("сохранить")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("закрыть")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }