<template>
    <div class="row">
        <div class="col-2">
            <label class="w-100 mt-2">
                <span class="small">id</span>
                <input class="form-control" v-model="inputVal.val.id_f"/>
            </label>
        </div>
        <div class="col-2">
            <label class="w-100 mt-2">
                <span class="small">количество</span>
                <input class="form-control" v-model.number="inputVal.val.v_in" ref="v_focus"/>
            </label>
        </div>
        <div class="col-6">
            <label class="w-100 mt-2">
                <span class="small">формула {{ inputVal.val.resp.fText }}</span>
                <span v-if="inputVal.val.resp.eMess" class="small text-danger">{{ inputVal.val.resp.eMess }}</span>
                <input class="form-control" v-model.trim="inputVal.val.formula"/>
            </label>
        </div>
        <div class="col-1">
            <label class="w-100 mt-2">
                <span class="small">результат</span>
                <div class="w-100 position-relative">
                    <input class="form-control" v-model.trim="inputVal.val.v_out" disabled title="результат"/>
                    <span class="position-absolute" style="top: 0.45rem; right: 0.8rem; cursor: pointer;" title="округление" @click="clickRound(inputVal.val)">{{ inputVal.val.round }}</span>
                </div>
            </label>
        </div>
        <div class="col-1">
            <label class="w-100 mt-2">
                <span class="small">ед.изм.</span>
                <input class="form-control" v-model.trim="inputVal.element.unit"/>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    name: 'b-input-val-so',
    props: {
        value: {type: Object, default: {}, required: true,},
    },
    mounted() {
        for (let i = 0; i < 3; i++) {
            setTimeout(() => {
                if (this.$refs.v_focus) this.$refs.v_focus.focus();
            }, 200);
        }
    },
    methods: {
        clickRound(obj) {
            if (obj.round === 3) {
                obj.round = 0;
            } else {
                obj.round += 1;
            }
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', this.value);
            }
        }
    }
}
</script>
