<template>
    <div>
        <div class="card-body">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" class="w-5">name2</th>
                    <th scope="col" class="w-1">количество</th>
                    <th scope="col" class="w-1">Ру</th>
                    <th scope="col" class="w-1">cos</th>
                </tr>
                </thead>
                <template v-for="val in fTable">
                    <tr :key="val.id" @click.ctrl.prevent="eTable.clickCtrlRow(val)" :class="{'bg-primary-l':eTable.isSelect(val)}" @click.exact="eTable.clickRow(val)">
                        <td class="text-left">{{val.name2}}</td>
                        <td>{{val.child.length}}</td>
                        <td>{{val.es.pu}}</td>
                        <td>{{val.es.cos}}</td>
                    </tr>
                </template>
            </table>
        </div>
        <modal-potr modal-id="modalPotr1" :p-local="local" @updInit="init"/>
    </div>
</template>
<script>

    import uniqid from 'uniqid';
    import ModalPotr from "./sub/ModalPotr";
    import {eTable} from "./sub/tools";


    export default {
        name: 'e-potr1',
        components: {ModalPotr},
        props: {
            pLocal: {},
            pState: {}
        },
        data: function () {
            return {
                eTable: new eTable(this),
                local: {
                    table: [],
                    selectArr: [],
                    fListImportPotr: this.pLocal.listImportPotr,
                    ePotr: this.pLocal.ePotr,
                    listName3: this.pLocal.listName3,
                    listAvt: this.pLocal.listAvt,
                    listKab: this.pLocal.listKab,
                },
                state: {
                    search: this.pState.search,
                },
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                // if (e.key === 'insert') this.showModalInsert();
                if (e.key === 113) this.showModal();
            });
            Bus.$on('updTable', () => this.init());
            Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
            Bus.$on('dotSelect', () => this.eTable.onDotSelect());
            Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
            Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        },
        mounted() {
            this.init();
            this.local.selectArr = [];
            if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
            setTimeout(() => this.$refs.search.focus(), 200);
        },
        methods: {
            showModal() {
                if(this.local.selectArr.length===0) return;
                Bus.$emit('show_modalPotr1');
            },
            showModalInsert() {
                if(this.local.selectArr.length===0) return;
                Bus.$emit('showI_modalPotr1');
            },
            init() {
                let res = [];
                this.pLocal.ePotr.forEach(item => {
                    let p = res.find(item2 => item2.name2 === item.name2 && item2.es.pu === item.es.pu && item2.es.cos === item.es.cos && item2.es.i_st === item.es.i_st);
                    if (!p) {
                        let o = {
                            id: uniqid(),
                            name2: item.name2,
                            es: {
                                pu: item.es.pu,
                                cos: item.es.cos,
                                i_st: item.es.i_st,
                            },
                            child: this.pLocal.ePotr.filter(item2 => item2.name2 === item.name2 && item2.es.pu === item.es.pu && item2.es.cos === item.es.cos && item2.es.i_st === item.es.i_st),
                        };
                        res.push(o);
                    }
                })

                res.sort((a, b) => {
                    if (a.es.pu === b.es.pu) {
                        if (a.name1 === b.name1) return 0;
                        return a.name1 > b.name1 ? -1 : 1;
                    }
                    return a.es.pu > b.es.pu ? -1 : 1;
                });

                this.local.table = res;
            },
        },
        computed: {
            fTable: function () {
                if (this.state.search.val === '') return this.local.table;
                return this.local.table.filter(item => {
                    let res = true;
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name2;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
        watch: {
            'fTable': {
                handler: function (newVal, oldVal) {
                    this.eTable.clearSelectArr();
                },
                deep: false
            },
        }
    }
</script>
