var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-fluid m1" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-2" }, [
            _c("div", { staticClass: "card mb-3" }, [
              _c(
                "div",
                {
                  staticClass: "card-body pl-0",
                  staticStyle: { overflow: "hidden" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        href: "#",
                        disabled: _vm.currentPage.id === "0",
                      },
                      on: { click: _vm.clickProjectButton },
                    },
                    [_c("b", [_vm._v("Проект")])]
                  ),
                  _vm._v(" "),
                  _c("nested-list-page", {
                    staticClass: "item-sub",
                    attrs: { "active-id": _vm.currentPage.id },
                    model: {
                      value: _vm.local.listPages,
                      callback: function ($$v) {
                        _vm.$set(_vm.local, "listPages", $$v)
                      },
                      expression: "local.listPages",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "nav",
                  {
                    staticClass:
                      "navbar navbar-expand-lg navbar-light bg-light",
                  },
                  [
                    _c("div", { staticClass: "container-fluid" }, [
                      _c(
                        "ul",
                        { staticClass: "navbar-nav me-auto mb-2 mb-lg-0" },
                        [
                          _vm.currentPage.id !== "0"
                            ? _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link text-primary",
                                    attrs: {
                                      href:
                                        "/tp/to_word/export/" +
                                        _vm.currentPage.id,
                                    },
                                  },
                                  [_vm._v("word")]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentPage.id !== "0"
                            ? _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link text-primary",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isEdit = !_vm.isEdit
                                      },
                                    },
                                  },
                                  [_vm._v("редактировать")]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentPage.id === "0"
                            ? _c("li", { staticClass: "nav-item dropdown" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    attrs: {
                                      "data-toggle": "dropdown",
                                      href: "#",
                                    },
                                  },
                                  [_vm._v("вставить лист")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "dropdown-menu" },
                                  _vm._l(
                                    _vm.listPagesTemplate.filter(
                                      (item) => item.parent === "_"
                                    ),
                                    function (val) {
                                      return _c(
                                        "a",
                                        {
                                          key: val.name,
                                          staticClass: "dropdown-item",
                                          class: {
                                            disabled: _vm.isDisableLink(val),
                                          },
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickAddPage(val.name)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(val.list_name))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentPage.type_list === "text"
                            ? _c("li", { staticClass: "nav-item dropdown" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link dropdown-toggle",
                                    attrs: {
                                      "data-toggle": "dropdown",
                                      href: "#",
                                    },
                                  },
                                  [_vm._v("вставить раздел")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "dropdown-menu" },
                                  _vm._l(
                                    _vm.listPagesTemplate.filter(
                                      (item) => item.parent === "text"
                                    ),
                                    function (val) {
                                      return _c(
                                        "a",
                                        {
                                          key: val.name,
                                          staticClass: "dropdown-item",
                                          class: {
                                            disabled: _vm.isDisableLink(val),
                                          },
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickAddPage(val.name)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(val.list_name))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentPage.id === "0"
                            ? _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        _vm.state.show_1 = !_vm.state.show_1
                                      },
                                    },
                                  },
                                  [_vm._v("суффиксы")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "navbar-nav me-auto mb-2 mb-lg-0" },
                        [
                          _vm.currentPage.id !== "0"
                            ? _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link text-danger",
                                    attrs: { href: "#" },
                                    on: { click: _vm.clickDelPage },
                                  },
                                  [_vm._v("удалить")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.state.show_1 && _vm.currentPage.id === "0"
                ? _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("edit-suffix", {
                        attrs: {
                          "list-pages-template": _vm.listPagesTemplate,
                          setting: _vm.setting,
                          "list-pages": _vm.local.listPages,
                        },
                        on: { save: _vm.clickSaveSuffix },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentPage.id === "0"
                ? _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("nested-list", {
                            staticClass: "item-sub",
                            attrs: {
                              setting: _vm.local.setting,
                              "list-pages-template": _vm.listPagesTemplate,
                            },
                            model: {
                              value: _vm.local.listPages,
                              callback: function ($$v) {
                                _vm.$set(_vm.local, "listPages", $$v)
                              },
                              expression: "local.listPages",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentPage.id !== "0"
                ? _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body p-0" },
                        [
                          _c("c-tiptap", {
                            attrs: { "is-edit": _vm.isEdit },
                            model: {
                              value: _vm.currentPage.html,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentPage, "html", $$v)
                              },
                              expression: "currentPage.html",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.isEdit
                      ? _c("div", [_vm._v(_vm._s(_vm.currentPage.html))])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentPage.id !== "0"
                ? _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "tab-content" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade",
                              attrs: { id: "fields" },
                            },
                            [
                              _vm._v(
                                "s\n                                        "
                              ),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-check mt-3" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.state.show_2,
                                                expression: "state.show_2",
                                              },
                                            ],
                                            staticClass: "form-check-input",
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.state.show_2
                                              )
                                                ? _vm._i(
                                                    _vm.state.show_2,
                                                    null
                                                  ) > -1
                                                : _vm.state.show_2,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.state.show_2,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.state,
                                                        "show_2",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.state,
                                                        "show_2",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.state,
                                                    "show_2",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(
                                            " все\n                                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.local.listFieldTemplates.filter(
                                  (item) =>
                                    item.razdel === _vm.currentPage.type_list
                                ),
                                function (val) {
                                  return !_vm.state.show_2
                                    ? [
                                        _c("div", { staticClass: "template" }, [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-chevron-left text-success",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickInsertBlock(val)
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(val.html),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "fas fa-chevron-down text-success",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickCollapseBlock(
                                                  val
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "collapse m-3",
                                              attrs: {
                                                id: "collapse_" + val.id,
                                              },
                                            },
                                            [
                                              _vm._l(
                                                val.child,
                                                function (valt) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "template",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-chevron-left text-success",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.clickInsertBlock(
                                                                valt
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("div", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              valt.html
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]),
                                      ]
                                    : _vm._e()
                                }
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.local.listFieldTemplates,
                                function (val) {
                                  return _vm.state.show_2
                                    ? [
                                        _c("p", [_vm._v(_vm._s(val.razdel))]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "template" }, [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-chevron-left text-success",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickInsertBlock(val)
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(val.html),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "fas fa-chevron-down text-success",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickCollapseBlock(
                                                  val
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "collapse m-3",
                                              attrs: {
                                                id: "collapse_" + val.id,
                                              },
                                            },
                                            [
                                              _vm._l(
                                                val.child,
                                                function (valt) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "template",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-chevron-left text-success",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.clickInsertBlock(
                                                                valt
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("div", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              valt.html
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]),
                                      ]
                                    : _vm._e()
                                }
                              ),
                              _vm._v(" "),
                              _c("div", { attrs: { id: "templateBlock" } }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade show active",
                              attrs: { id: "profile1" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _vm._l(
                                    _vm.getCurrentPageTemplate,
                                    function (val) {
                                      return [
                                        _c("div", {}, [
                                          _c(
                                            "div",
                                            { staticClass: "doc-view-small" },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(val.html),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade",
                              attrs: { id: "contact1" },
                            },
                            [_vm._v("...3")]
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        staticStyle: { display: "none" },
        attrs: { id: "html_code" },
      }),
      _vm._v(" "),
      _c("zapros"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "nav nav-tabs" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "button",
          {
            staticClass: "nav-link",
            attrs: {
              "data-toggle": "tab",
              "data-target": "#fields",
              type: "button",
            },
          },
          [_vm._v("поля")]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "button",
          {
            staticClass: "nav-link active",
            attrs: {
              "data-toggle": "tab",
              "data-target": "#profile1",
              type: "button",
            },
          },
          [_vm._v("страницы")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }