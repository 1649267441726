<template>
    <draggable
        tag="div"
        class="item-container"
        :list="list"
        :value="value"
        @input="emitter"
        handle=".handle"
        animation="150"
        :fallback-on-body="true"
        :swap-threshold="0.5"
        ghost-class="ghost"
        filter=".no-drag"
        @change="onChange"
    >
        <div v-for="el in realValue" class="item-group" :key="el.id">
            <div class="item" @contextmenu.prevent="$refs.menu.open($event, el)" style="cursor: pointer">
                <div class="row mb-0">
                    <div class="col-3">
                        <font-awesome-icon v-if="el.type==='norm'" icon="fa-solid fa-file" class="handle"/>
                        <font-awesome-layers v-else class="handle">
                            <font-awesome-icon icon="fa-regular fa-file"/>
                            <font-awesome-icon icon="fa-solid fa-link" transform="shrink-6 down-1 right-1" style="color: #ee9b00"/>
                            <!--                            <font-awesome-layers-text class="gray8" transform="down-2 shrink-8" value="New!" style="color:#e2f506"/>-->
                        </font-awesome-layers>

                        <span class="font-weight-bold ml-1"> {{ el.name }}</span>
                    </div>
                    <div class="col-8">
                        <span class="ml-1"> {{ el.desc }}</span>
                    </div>
                </div>
            </div>
        </div>

        <ContextMenuMy ref="menu" :items="items"/>

    </draggable>
</template>

<script>

    import draggable from 'vuedraggable'
    import VueContext from 'vue-context';
    import 'vue-context/dist/css/vue-context.css';
    import ContextMenuMy from "./ContextMenuMy";

    export default {
        name: "nested-pps",
        components: {ContextMenuMy, draggable, VueContext},
        props: {
            value: {
                required: false,
                type: Array,
                default: null
            },
            list: {
                required: false,
                type: Array,
                default: null
            },
            level: {
                type: Number,
                default: 1,
            },
            id: {
                required: false,
                type: String,
                default: '0'
            },
            nbi: {
                required: true,
                type: Object,
                default: {}
            },
        },
        data: function () {
            return {
                isMove: true,
                oldValue: this.value,
                lValue: this.value,
                stamp_size: [
                    {name: 'Большой штамп', s_name: 'Б'},
                    {name: 'Средний штамп', s_name: 'С'},
                    {name: 'Маленький штамп', s_name: 'М'},
                    {name: 'Без штампа', s_name: 'БШ'},
                    {name: 'Без всего', s_name: 'БВ'},
                ],
                items: [
                    {name: 'вставить', link: 'insert'},
                    {
                        name: 'редактировать', link: 'edit', disable: (obj) => {
                            return obj.type === 'norm'
                        }
                    },
                    {name: '---'},
                    {name: 'удалить', link: 'delete'},
                ],
            }
        },
        created: function () {
            Bus.$on('contextMenu_menu', (obj) => this.onContext(obj));
        },
        methods: {
            onContext(obj) {
                if (obj.link === 'insert') Bus.$emit('show_modalPps', null);
                if (obj.link === 'edit') Bus.$emit('show_modalPps', obj.obj);
                if (obj.link === 'delete') this.clickRemove(obj.obj);
            },
            clickMinus(obj) {
                if (obj.options.count_page <= 0) return;
                obj.options.count_page -= 1;
            },
            clickPlus(obj) {
                obj.options.count_page += 1;
            },
            getTitle(obj) {
                let res = obj.stamp.type + " \nлист.скв. " + obj.stamp.list_num_global + " \nлистов " + obj.stamp.list_num_text;
                if (obj.stamp.list_count > 1) res += ' из ' + obj.stamp.list_count;
                if (obj.options.count_page > 1) res += "\nсостоит из " + obj.options.count_page + ' листов';

                return res;
            },
            clickRemove(obj) {
                Bus.$emit('deletePps', obj);
            },
            emitter(value) {
                this.$emit("input", value);
            },
            onChange() {
                this.realValue.forEach(item => item.parent_id = this.id);
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            realValue() {
                return this.value ? this.value : this.list;
            },

        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

    .handle {
        cursor: move;
        color: $primary;
    }

    .ghost {
        opacity: 0.3;
        background: #c8ebfb;
    }

    .item-container {
        margin: 0;
    }

    .item {
        padding: 0.3rem;
        border-bottom: solid $primary 1px;
        cursor: pointer;

        &:hover {
            background-color: lighten($primary, 20%);
        }
    }

</style>
