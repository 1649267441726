<template></template>
<script>

export default {
    props: ['f1'],
    data() {
        return {
            zObj:
                {
                    url: '',
                    obj: {},
                    messOk: 'Задача выполнена',
                    messError: 'Ошибка',
                    f1: function () {

                    },
                    flash: true,
                    suffix: '',
                },
        }
    },
    created() {
        Bus.$on('zPost', e => {
            this.zObj = Object.assign(this.zObj, e);
            this.post(this.zObj.url, this.zObj.obj, this.zObj.messOk, this.zObj.messError, this.zObj.suffix, this.zObj.f1);
        });
        Bus.$on('zSetF1', e => this.zObj.f1 = e.f1);
        this.zObj.f1 = this.f1;
    },
    methods: {
        myFlash(a, b, c) {
            if (!this.zObj.flash) return null;
            return this.flash(a, b, c);
        },
        myFlashDestroy(a) {
            if (a === null) return;
            a.destroy();
        },
        post(url = '', ob = {}, messOk = 'Задача выполнена', messError = 'Ошибка', suffix, f1 = function (r) {
        }) {
            let fl = this.myFlash('запрос к серверу', 'info');
            this.errors = [];
            axios
                .post(url, ob)
                .then(response => {
                    // console.log(response);
                    this.myFlashDestroy(fl);
                    f1();
                    if (response.exception === undefined && (response.data.error === undefined || response.data.error === false || response.data.error.length === 0)) {
                        this.myFlash(messOk, 'success', {timeout: 2000});
                        Bus.$emit('zResponse' + suffix, response.data);
                    } else {
                        Bus.$emit('zError' + suffix, response.data);
                        if (Array.isArray(response.data.error)) {
                            if (response.data.error.length > 0) {
                                let str = '';
                                response.data.error.forEach(item => str += item.message + '<br>');
                                this.myFlash(str, 'error')
                            }
                        } else if (typeof response.data.error === 'object')
                            this.myFlash(response.data.error.message, 'error');
                        else if (typeof response.data.error === 'string')
                            this.myFlash(response.data.error, 'error');
                        else
                            this.myFlash(messError, 'error');
                    }
                })
                .catch(error => {
                    Bus.$emit('zCatch' + suffix, error);
                    this.myFlashDestroy(fl);
                    this.myFlash(error, 'error');
                });

        }

    },
}
</script>

