<template>
    <div class="col-12">


    </div>
</template>
<script>
    import Draggable from 'vuedraggable'
    import Multiselect from 'vue-multiselect'

    export default {
        name: 'tp-cover',
        components: {Draggable, Multiselect},
        props: {
            projectInfo: {type: Object, default: {}},
            listFio: {type: Array, default: []},
        },
        data: function () {
            return {
                listStage: [
                    {name: 'П'},
                    {name: 'Р'},
                    {name: 'ИД'},
                ],
                local: {
                    projectInfo: this.projectInfo,
                    listFio: this.listFio,
                    listFioA: [],
                },
                nbi: {
                    projectInfo: {},
                    authUser: {prop: {setting: {listFioA: []}}},
                }
            }
        },
        created: function () {
            // Bus.$on('navbar_info', obj => {
            //     this.nbi = obj;
            //     this.init();
            // });
            // Bus.$on('updListFio', (obj) => {
            //     this.local.listFio = obj;
            // });
        },
        mounted() {
            // Bus.$emit('navbar', {command: 'get_navbar_info'});
        },
        methods: {
            // init() {
            //     console.log('init');
            //     this.local.listFioA = this.nbi.authUser.prop.setting.listFioA;
            //     this.local.projectInfo.fio.forEach(item=>{
            //         let obj = this.local.listFioA.find(v => v.name === item.a);
            //         if(!obj && item.a) this.local.listFioA.push({name: item.a});
            //     });
            // },
            // getImgSrc(obj) {
            //     if (obj.b === null) return '/img/clear.png';
            //     return '/project/fio/sign/' + obj.b.id + '/' + obj.b.prop.default;
            // },
            // replaceImgByClear(e) {
            //     e.target.src = '/img/clear.png';
            // },
            // labelSearch(list, opt) {
            //     let obj = list.find(item => item.name === opt);
            //     if (!obj) return '';
            //     return obj.name;
            // },
            // //todo multiselect
            // addNewFioA(newTag, index) {
            //     let tag = {name: newTag};
            //     this.nbi.authUser.prop.setting.listFioA.push(tag);
            //     this.local.projectInfo.fio[index].a = newTag;
            // },
            // addNewFio(name, index) {
            //     this.setLoading(true);
            //     Bus.$emit('zPost', {
            //         url: '/project/fio/add',
            //         obj: {name: name},
            //         flash: true,
            //         suffix: '_add_new_fio',
            //     });
            //     Bus.$once('zResponse_add_new_fio', response => {
            //         this.local.listFio = response.listFio;
            //         this.local.projectInfo.fio[index].b = response.listFio.find(item => item.id === response.id);
            //         Bus.$emit('updListFio', this.local.listFio);
            //         this.setLoading(false);
            //     });
            // },
            // setLoading(state) {
            //     // this.status.loading = state;
            //     Bus.$emit('navbar', {command: 'setLoading', args: state});
            // },
        },
    }
</script>
