<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal" role="dialog" @click="closeModal" v-if="isMount">
        <div class="modal-dialog modal-xl" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span v-if="state.isEdit">редактировать</span>
                    <span v-else>вставить потребитель</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label class="w-100">
                                <span class="small">Название </span>
                                <span class="w-100" title="Название оборудования">
                                <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="text-success" :class="{'text-danger':state.obj.name===''}" title="обязательное поле"/>
                                <input class="form-control" v-model.trim="state.obj.name" placeholder="Название" :class="{lock:lock.name}" @keyup="lock.name=false" ref="v_focus"/>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <e-input :lock="lock" :k="'marka'" v-model="state.obj.marka" :label="'марка'" :title="'Марка оборудования'" :is-number="false"/>
                        </div>
                        <div class="col-3">
                            <e-input :lock="lockB" :k="'name'" v-model="state.obj.brand.name" :label="'Производитель'" :title="'Производитель'" :is-number="false"/>
                        </div>
                        <div class="col-3">
                            <e-input :lock="lockB" :k="'article'" v-model="state.obj.brand.article" :label="'Артикул'" :title="'Артикул'" :is-number="false"/>
                        </div>
                        <div class="col-2">
                            <e-input :lock="lockI" :k="'unit'" v-model="state.obj.info.unit" :label="'ед.изм.'" :title="'Единица измерения'" :is-number="false"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <e-input :lock="lockI" :k="'desc'" v-model="state.obj.info.desc" :label="'Описание'" :title="'Описание'" :is-number="false"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <!--                            <e-input :lock="lock" :k="'i_unit'" v-model="state.obj.prop.info.unit" :label="'ед.изм.'" :title="'Единица измерения'" :is-number="false"/>-->
                        </div>
                    </div>
                    <div class="row">
                        <template v-for="val in local.activeCategoryL.prop.fields.filter(item=>item.req)" class="col-4 pr-4">
                            <b-input-field :lock="lockF" v-model="state.obj.fields[val.id]" :field="val"/>
                        </template>
                        <template v-for="val in local.activeCategoryL.prop.fields.filter(item=>!item.req)" class="col-4 pr-4">
                            <b-input-field :lock="lockF" v-model="state.obj.fields[val.id]" :field="val"/>
                        </template>
                    </div>
                </div>
                <div class="modal-footer d-flex flex-row">
                    <div class="mr-auto">
                        <font-awesome-icon icon="fa-solid fa-lock" @click="setLock(true)" title="заблокировать всё"/>
                        <font-awesome-icon icon="fa-solid fa-lock-open" class="ml-3" @click="setLock(false)" title="разблокировать всё"/>
                    </div>
                    <div>
                        <a v-if="state.obj.fields.url!==''" :href="state.obj.fields.url" target="_blank">
                            <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" class="mt-1 mr-3"/>
                        </a>
                        <button v-if="!!lastSelectObj" type="button" class="btn btn-primary" @click="clickInsert" :disabled="!isReqFull">вставить новую запись</button>
                        <button v-if="state.isEdit" type="button" class="btn btn-default" @click="clickLocalSave" :disabled="!isReqFull">сохранить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {page} from "./tools";
import EInput from "./EInput";
import BInputField from "./BInputField";
import uniqid from 'uniqid';


export default {
    name: 'modal-base',
    components: {EInput, Multiselect, BInputField},
    props: {
        modalId: {type: String, default: 'modal',},
        pLocal: {type: Object, default: {},},
        pState: {type: Object, default: {},},
        isGroupedItem: {type: Boolean, default: false,},
    },
    data: function () {
        return {
            //todo js modal add page module
            pageT: new page(this),
            rand: 1,
            local: {
                table: this.pLocal.table,
                listName: [],
                listTmpl: [],
                listDd: [],
                listOtm: [],
                activeCategoryL: {prop: {fields: []}},
            },
            state: {
                obj: null,
                isEdit: true,
            },
            status: {
                loading: false,
            },
            templateObj: {
                id: '',
                id_old: '',
                name: '',
                marka: '',
                status: 0,
                fields: {},
                info: {},
                brand: {},
            },
            lock: {
                name: true,
                marka: true,
            },
            lockI: {},
            lockB: {},
            lockF: {},
            isMount: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlEnter') this.clickLocalSave();
        });
        Bus.$on('show_' + this.modalId, () => {
            this.state.isEdit = true;
            this.showModal()
        });
        Bus.$on('showI_' + this.modalId, () => {
            this.state.isEdit = false;
            this.showModal();
        });
    },
    mounted() {
        this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
        this.isMount = true;
    },
    methods: {
        clickInsert() {
            let obj = JSON.parse(JSON.stringify(this.state.obj));
            obj.id = uniqid();
            obj.prop = {
                fields: obj.fields,
                info: obj.info,
            }
            obj.prop.info.brand = obj.brand;
            obj.prop.ver = this.lastSelectObj.prop.ver;
            delete obj.fields;
            delete obj.info;
            delete obj.brand;
            // this.pLocal.selectArr = [];
            this.pLocal.selectArr.push(obj);

            this.pLocal.table.unshift(obj);
            this.closeModal();
        },
        setLock(val) {
            Object.keys(this.lock).forEach(item => this.lock[item] = val);
            Object.keys(this.lockF).forEach(item => this.lockF[item] = val);
            Object.keys(this.lockI).forEach(item => this.lockI[item] = val);
            Object.keys(this.lockB).forEach(item => this.lockB[item] = val);
        },
        showModal() {
            if (!this.lastSelectObj) return;

            this.local.activeCategoryL = this.pLocal.category.find(item => item.id === parseInt(this.lastSelectObj.prop.info.category));
            if (!this.local.activeCategoryL) return;

            this.templateObj.fields = {};
            this.lockF = {};

            this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
            Object.keys(this.lastSelectObj.prop.fields).forEach(item => this.$set(this.lockF, item, false));
            Object.keys(this.lastSelectObj.prop.info.brand).forEach(item => this.$set(this.lockB, item, false));
            Object.keys(this.lastSelectObj.prop.info).forEach(item => {
                if (item !== 'brand') this.$set(this.lockI, item, false);
            });
            this.setLock(false);

            this.setObj(this.state.obj, this.lastSelectObj);
            // this.setObjF(this.state.obj, this.lastSelectObj);


            // this.state.obj.name = this.lastSelectObj.name;
            // this.state.obj.marka = this.lastSelectObj.marka;
            // this.state.obj.prop.info.unit = this.lastSelectObj.prop.info.unit;
            // this.state.obj.prop.info.desc = this.lastSelectObj.prop.info.desc;
            // this.state.obj.prop.info.category = this.lastSelectObj.prop.info.category;
            // this.state.obj.prop.info.brand.name = this.lastSelectObj.prop.info.brand.name;
            // this.state.obj.prop.info.brand.article = this.lastSelectObj.prop.info.brand.article;


            //todo js поиск уникальных значений
            // this.local.listTmpl = this.local.table.map(item => item.tmpl_suffix).filter((value, index, self) => self.indexOf(value) === index);

            this.local.activeCategoryL.prop.fields.forEach(item => {
                let k = item.id;
                this.$set(this.state.obj.fields, k, this.lastSelectObj.prop.fields[k] ?? item.val);
            })
            Object.keys(this.lastSelectObj.prop.info).forEach(item => {
                if (item !== 'brand') this.$set(this.state.obj.info, item, this.lastSelectObj.prop.info[item] ?? '');
            });
            Object.keys(this.lastSelectObj.prop.info.brand).forEach(item => {
                this.$set(this.state.obj.brand, item, this.lastSelectObj.prop.info.brand[item] ?? '');
            });

            this.setLock(this.pLocal.selectArr.length > 1);
            $('#' + this.modalId).modal('show');
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    if (this.$refs.v_focus) this.$refs.v_focus.focus()
                }, 200);
            }
            $(".modal").draggable({
                handle: ".modal-header"
            });
        },
        clickLocalSave() {
            if (!this.isReqFull) return;
            if (!this.state.isEdit) {
                this.clickInsert();
                return;
            }
            if (!confirm('действительно изменить данную информацию?')) {
                return;
            }
            this.pLocal.selectArr.forEach(item => {
                this.setObj(item, this.state.obj);
                this.setObjF(item.prop.fields, this.state.obj.fields, this.lockF);
                this.setObjI(item.prop.info, this.state.obj.info, this.lockI);
                this.setObjB(item.prop.info.brand, this.state.obj.brand, this.lockB);
            })
            this.closeModal();
        },
        setObj(obj, iObj) {
            if (!this.lock.name) obj.name = iObj.name;
            if (!this.lock.marka) {
                console.log('setMarka');
                obj.marka = iObj.marka;
            }
            // if (!this.lock.id) obj.id = iObj.id;
            // if (!this.lock.id_old) obj.id_old = iObj.id_old;
            // if (!this.lock.i_unit) obj.prop.info.unit = iObj.prop.info.unit;
        },
        setObjF(obj, iObj, lock) {
            Object.keys(obj).forEach(item => {
                if (!lock[item]) obj[item] = iObj[item];
            });
        },
        setObjI(obj, iObj, lock) {
            Object.keys(obj).forEach(item => {
                if (item !== 'brand') {
                    if (!lock[item]) obj[item] = iObj[item];
                }
            });
        },
        setObjB(obj, iObj, lock) {
            Object.keys(obj).forEach(item => {
                if (!lock[item]) obj[item] = iObj[item];
            });
        },
        addNewItem2(newTag) {
            this.state.obj.name = newTag;
            this.local.listName.push(newTag);
        },
        onOpen(r, f) {
            setTimeout(() => {
                this.$refs[r].$refs.search.value = this.state.obj[f];
            }, 10);
        },
        onChange() {
            if (!this.state.obj) this.state.isEdit = false;
        },
        closeModal() {
            this.$emit('updInit');
            $('#' + this.modalId).modal('hide');
        },
        //todo js multiselect custom search
        customSearch(query) {
            if (query === '') {
                this.local.fListImportPotr = this.pLocal.fListImportPotr;
            }
            let i = 0;
            this.local.fListImportPotr = this.pLocal.fListImportPotr.filter(item => {
                // if (i > 10) return false;
                let res = true;
                query.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                if (res) i++;
                return res;
            })
        },
    },
    computed: {
        isReqFull: function () {
            let res = true;
            res = res && this.state.obj.name !== '';
            this.local.activeCategoryL.prop.fields.forEach(item => {
                if (item.req) {
                    res = res && this.state.obj.fields[item.id] !== '' && this.state.obj.fields[item.id] !== 0;
                }
            })
            return res;
        },
        lastSelectObj: function () {
            if (this.pLocal.selectArr.length === 0) return null;
            return this.pLocal.selectArr[this.pLocal.selectArr.length - 1];
        }
    },
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.modal-footer {
    svg.fa-lock, svg.fa-lock-open {
        opacity: 1;
        cursor: pointer;
    }
}

svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}

ul.multiselect__content {
    width: 100% !important;
}

input.lock {
    background-color: lighten($primary, 50%);
}

</style>
