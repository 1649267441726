<template>
    <div>
        <div class="card-body">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" class="w-1">номер</th>
                    <th scope="col" class="w-1">копия</th>
                    <th scope="col" class="w-5">название</th>
                    <th scope="col" class="w-5">зона</th>
                    <th scope="col" class="w-1">отметка</th>
                    <th scope="col" class="w-1">кабель</th>
                    <th scope="col" class="w0"></th>
                </tr>
                </thead>
                <template v-for="val in fTable">
                    <tr :key="val.id" class="mark">
                        <td>{{val.marker_tag}}/{{val.explication_tag}}</td>
                        <td @click="clickCopyNumberForChild(val)">
                            <font-awesome-icon icon="fa-solid fa-angle-right" @click="clickCopyNumberForChild(val)"/>
                        </td>
                        <td>
                            <span>{{val.name}}</span>
                        </td>
                        <td></td>
                        <td>
                            <span>{{val.otm}}</span>
                        </td>
                        <td>
                            <span>{{val.kabl}}</span>
                        </td>
                        <td></td>
                    </tr>
                    <draggable :list="val.child" tag="tbody" ghost-class="ghost" :disabled="pState.isEdit" :scroll-sensitivity="100" :fallback-tolerance="1" :force-fallback="true">
                        <tr v-for="el in fTableChild(val.child)" :key="el.id"
                            @click.ctrl.prevent="eTable.clickCtrlRow(el)"
                            @click.exact="eTable.clickRow(el)"
                            :class="{'bg-primary-l':eTable.isSelect(el),'bg-danger-l':el.area.id===''}"
                        >
                            <td scope="row">
                                <span v-if="!pState.isEdit">{{el.number}}</span>
                                <input v-else class="form-control1" v-model="el.number">
                            </td>
                            <td @click="el.name=el.number">
                                <font-awesome-icon icon="fa-solid fa-angle-right" @click="el.name=el.number"/>
                            </td>
                            <td class="text-left">
                                <span v-if="!pState.isEdit">{{el.name}}<span v-if="el.area.id===''" class="font-weight-bold text-danger"> Область не найдена</span></span>
                                <input v-else class="form-control1" v-model="el.name">
                            </td>
                            <td><span @click="clickAll(el)" style="opacity: 0.3">all</span> {{el.zone.name}}</td>
                            <td></td>
                            <td></td>
                            <td @click="eTable.copyClipBoard(el)" title="копирование координат в буфер обмена">
                                <font-awesome-icon icon="fa-solid fa-location-crosshairs"/>
                            </td>
                        </tr>
                    </draggable>
                </template>
            </table>
        </div>
        <modal-room modal-id="modalRoom0" :p-local="local" @updInit="init"/>
    </div>
</template>
<script>

    import uniqid from 'uniqid';
    import {eTableMulti} from "./sub/tools";
    import ModalRoom from "./sub/ModalRoom";

    export default {
        name: 'e-room0',
        components: {ModalRoom},
        props: {
            pLocal: {},
            pState: {}
        },
        data: function () {
            return {
                eTable: new eTableMulti(this),
                local: {
                    table: [],
                    selectArr: [],
                    eRoom: this.pLocal.eRoom,
                    listZones: this.pLocal.listZones,
                },
                state: {
                    search: this.pState.search,
                },
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'insert') this.showModalInsert();
                if (e.key === 113) this.showModal();
            });
            Bus.$on('updTable', () => this.init());
            Bus.$on('localSave', () => this.localSave());
            Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
            Bus.$on('dotSelect', () => this.eTable.onDotSelect());
            Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
            Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        },
        mounted() {
            this.init();
            this.local.selectArr = [];
            if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
            setTimeout(() => this.$refs.search.focus(), 200);
        },
        methods: {
            clickAll(obj) {
                let parent = this.local.table.find(item => item.explication_tag === obj.marker.explication_tag);
                if (!parent) return;
                parent.child.forEach(item => {
                    item.zone.id = obj.zone.id;
                    item.zone.name = obj.zone.name;
                })
            },
            showModal() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('show_modalRoom0');
            },
            showModalInsert() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('showI_modalRoom0');
            },
            clickCopyNumberForChild(obj) {
                obj.child.forEach(item => item.name = item.number)
            },
            localSave() {
                let eRoom = [];
                this.local.table.forEach(item => {
                    item.child.forEach(item2 => {
                        eRoom.push(item2);
                    })
                })
                this.pLocal.eRoom = eRoom;
            },
            init() {
                let res = [];

                let expl = this.pLocal.eRoom.map(item => item.marker.id).filter((value, index, self) => self.indexOf(value) === index);

                let o = {
                    id: uniqid(),
                    name: 'без маркера',
                    otm: '',
                    kabl: '',
                    explication_tag: '',
                    marker_tag: '',
                    child: this.pLocal.eRoom.filter(item2 => item2.marker.id === ''),
                };
                res.push(o);

                this.pLocal.eMarker.filter(item => !!~expl.indexOf(item.id)).forEach(item => {
                    let o = {
                        id: uniqid(),
                        name: item.name,
                        otm: item.otm,
                        kabl: item.kabl,
                        explication_tag: item.explication_tag,
                        marker_tag: item.marker_tag,
                        child: this.pLocal.eRoom.filter(item2 => item2.marker.id === item.id),
                    };
                    res.push(o);
                })

                this.local.table = res;
            },
            fTableChild(child) {
                if (this.state.search.val === '') return child;
                return child.filter(item => {
                    let res = true;
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name + item.number;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
        computed: {
            fTable: function () {
                // return this.local.table;
                // if (this.state.search.val === '') return this.local.table;
                return this.local.table.filter(item => {
                    return this.fTableChild(item.child).length > 0;
                })
            },
        },
        watch: {
            'fTable': {
                handler: function (newVal, oldVal) {
                    this.eTable.clearSelectArr();
                },
                deep: false
            },
        }
    }
</script>


<style scoped lang="scss">
    @import 'resources/sass/variables';

    .nav-link {
        cursor: pointer;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .form-control1 {
        border: 0;
        width: 100%;
    }
</style>
