<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12">

                    <ul class="nav nav-tabs mt-4">
<!--                        <li class="nav-item">-->
<!--                            <a class="nav-link" @click="clickTab(0)" :class="{active:state.activeTab===0}" style="cursor: pointer">общая таблица</a>-->
<!--                        </li>-->
<!--                        <li class="nav-item">-->
<!--                            <a class="nav-link" @click="clickTab(1)" :class="{active:state.activeTab===1}" style="cursor: pointer">элементы системы</a>-->
<!--                        </li>-->
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(2)" :class="{active:state.activeTab===2}" style="cursor: pointer">Щиты</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(3)" :class="{active:state.activeTab===3}" style="cursor: pointer">Автоматы</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(4)" :class="{active:state.activeTab===4}" style="cursor: pointer">Потребители</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(5)" :class="{active:state.activeTab===5}" style="cursor: pointer">Выключатели</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(6)" :class="{active:state.activeTab===6}" style="cursor: pointer">Дополнительно</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(7)" :class="{active:state.activeTab===7}" style="cursor: pointer">Кабели</a>
                        </li>
                    </ul>

                    <div class="tab-content">
<!--                        <div class="tab-pane container-fluid" :class="{active:state.activeTab===0}">-->
<!--                            <div class="row mt-3">-->
<!--                                <div class="col-12">-->
<!--                                    <specification0 v-if="isMounted" :p-local="local" :p-state="state"/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="tab-pane container-fluid" :class="{active:state.activeTab===1}">-->
<!--                            <div class="row mt-3">-->
<!--                                <div class="col-12">-->
<!--                                    <specification1 v-if="isMounted" :p-local="local" :p-state="state"/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="tab-pane container-fluid" :class="{active:state.activeTab===2}">
                            <div class="row mt-3">
                                <div class="col-12">
                                    <marka_e-panel v-if="isMounted" :p-local="local" :p-state="state"/>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane container-fluid" :class="{active:state.activeTab===3}">
                            <div class="row mt-3">
                                <div class="col-12">
                                    <marka_e-avt v-if="isMounted" :p-local="local" :p-state="state"/>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane container-fluid" :class="{active:state.activeTab===4}">
                            <div class="row mt-3">
                                <div class="col-12">
                                    <marka_e-potr v-if="isMounted" :p-local="local" :p-state="state"/>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane container-fluid" :class="{active:state.activeTab===5}">
                            <div class="row mt-3">
                                <div class="col-12">
                                    <marka_e-vykl v-if="isMounted" :p-local="local" :p-state="state"/>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane container-fluid" :class="{active:state.activeTab===6}">
                            <div class="row mt-3">
                                <div class="col-12">
                                    <marka_e-dop v-if="isMounted" :p-local="local" :p-state="state"/>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane container-fluid" :class="{active:state.activeTab===7}">
                            <div class="row mt-3">
                                <div class="col-12">
                                    <marka_e-section v-if="isMounted" :p-local="local" :p-state="state"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <modal-so modal-id="modalSo1" :a-w="local.AW" :list-category="listCategory" :marka="'marka_new'"/>
        <modal-so-info modal-id="modalSoInfo" :p-local="local" :prod-obj="{}" :marka="'marka_new'"/>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import nestedList from "./sub/nestedList";
import moment from "moment";
import {eTable, page, toolM} from "./sub/tools";
import ModalBase from "./sub/ModalBase";
import nestedSo from "./sub/nestedSo";
import nestedSpecification from "./sub/nestedSpecification";
import ContextMenuMy from "./sub/ContextMenuMy";
import ModalSo from "./sub/ModalSo";
import Specification1 from "./specification1.vue";
import Specification0 from "./specification0.vue";
import EPotr0 from "./EPotr0.vue";
import modalSoInfo from "./sub/ModalSoInfo.vue";
import Marka_eAvt from "./marka_eAvt.vue";
import Marka_eDop from "./marka_eDop.vue";
import Marka_ePotr from "./marka_ePotr.vue";
import Marka_eSection from "./marka_eSection.vue";
import Marka_ePanel from "./marka_ePanel.vue";
import Marka_eVykl from "./marka_eVykl.vue";

export default {
    name: "page-marka",
    components: {
        Marka_eVykl,
        Marka_ePanel,
        Marka_eSection,
        Marka_ePotr,
        Marka_eDop,
        Marka_eAvt,
        modalSoInfo, EPotr0, Specification1, Multiselect, nestedList, ModalBase, nestedSpecification, ContextMenuMy, ModalSo, Specification0
    },
    props: ['table', 'base', 'ePotr', 'ePanel', 'eAvt', 'eVykl', 'eDop', 'eSection', 'psSetting', 'listCategory'],
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            eTable: new eTable(this),
            nbi: {},
            local: {
                selectArr: [],
                saveArr: [],
                table: this.table,
                base: this.base,
                ePotr: this.ePotr,
                ePanel: this.ePanel,
                eAvt: this.eAvt,
                eVykl: this.eVykl,
                eDop: this.eDop,
                eSection: this.eSection,
                psSetting: this.psSetting,
                AW: ['root1', 'root0'],
                listCategory: this.listCategory,
            },
            status: {
                loading: false,
            },
            state: {
                version: 3,
                activeTab: 2,
                activeRowIndex: 0,
                activeRow: {},
                activeCategory: {},
                search: {
                    val: ''
                },
            },
            items: [
                {name: 'переключить видимость', link: 'hide'},
                {name: '---'},
                {name: 'переключить видимость щита', link: 'hideRu'},
            ],
            isMounted: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            // if (e.key === 'insert') this.showModalInsert();
            // if (e.key === 'ctrlS') this.clickSave();
        })
        // Bus.$on('keyDown', e => {
        //     if (e.key === 40) this.keyDown()//Down
        //     if (e.key === 38) this.keyUp()//Up
        // });
        Bus.$on('navbar_info', obj => this.nbi = obj);
        Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
        Bus.$on('dotSelect', () => this.eTable.onDotSelect());
        Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
        Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        // Bus.$on('onShowContextMenu', obj => this.$refs[obj.refs].open(obj.e, obj.el));
        // Bus.$on('contextMenu_menu', (o) => {
        //     if (o.link === 'hide') this.onHide(o.obj);
        //     if (o.link === 'hideRu') this.onHideAll(o.obj);
        // });
        Bus.$on('clickRow', (obj) => this.eTable.clickRow(obj));
        Bus.$on('setAW', (obj) => this.setAW(obj));
        Bus.$on('removeAW', (obj) => this.removeAW(obj));
    },

    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {reload: true, save: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.pageT.loadLS();
        this.state.activeRow = this.local.table[0];
        this.local.selectArr = [];
        if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
        this.isMounted = true;
        $(document).on('show.bs.modal', '.modal', function () {
            const zIndex = 1040 + 10 * $('.modal:visible').length;
            $(this).css('z-index', zIndex);
            setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
        });
        setTimeout(() => this.clickTab(this.state.activeTab));
    },
    methods: {
        clickTab(ind) {
            this.state.activeTab = ind;
            Bus.$emit('setAW', 'root' + ind);
            Bus.$emit('clickTab', ind);
            this.pageT.updLS();
        },
        setAW(obj) {
            this.removeAW(obj);
            this.local.AW.unshift(obj);
        },
        removeAW(obj) {
            let index = this.local.AW.findIndex(item => item === obj);
            if (index > -1) this.local.AW.splice(index, 1);
        },
        showModalInsert(obj) {
            Bus.$emit('showI_modalSo')
        },
        onHide(obj) {
            obj.is_visible = !obj.is_visible;
        },
        onHideAll(obj) {
            let parent = obj;
            if (!obj.isRu) parent = this.local.table.find(item => item.id === obj.parent_id);

            parent.is_visible = !parent.is_visible;
            parent.child.forEach(item => item.is_visible = parent.is_visible);
        },
        clickDot(type) {
            if (type === 'select') Bus.$emit('dotSelect')
            else if (type === 'un_select') Bus.$emit('dotUnSelect')
            else if (type === 'inverse') Bus.$emit('dotInverse')
        },
        init() {

        },
        // clickSave() {
        //     // Bus.$emit('localSave');
        //     this.pageT.clickSave('/marka_element/save', {table: this.local.table, save_arr: this.local.saveArr, ps_setting: this.local.psSetting}, '_save');
        //     Bus.$once('zResponse_save', response => {
        //         this.local.saveArr.length = 0;
        //         this.local.table.length = 0;
        //         response.table.forEach(item => this.local.table.push(item));
        //         // Bus.$emit('updTable');
        //         // Bus.$emit('updSelectArr');
        //     });
        // },
    },
    computed: {},
    watch: {
        'fTable': {
            handler: function (newVal, oldVal) {
                this.eTable.clearSelectArr();
            },
            deep: false
        },
    },
    filters: {},
    directives: {}
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/variables';

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.row {
    padding: 0;
    margin: 0.1rem 0;

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        text-align: center;
        padding: 0;
    }

    .zag {
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        border: 1px solid $primary;
        background-color: lighten($primary, 20%);
    }
}

.row-w {
    border-bottom: 1px solid #959595;
    cursor: pointer;
}

span.small {
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    font-style: italic;
}

table.table.in_td {
    /*td{*/
    /*    text-align: center;*/
    /*    vertical-align: middle;*/
    /*}*/
    th {
        border: none;
        background: none;
        padding: 0;
    }

    tr {
        border: none;
        background: none;
        height: 0px;

        &.hover:hover {
            background-color: lighten($primary, 20%);
        }
    }

    tbody tr:first-child {
        td {
            border: none;
        }
    }

    .bg-primary-l {
        background-color: lighten($primary, 40);
    }

    .bg-success-l {
        background-color: lighten($success, 40);
    }
}

</style>
