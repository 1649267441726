import {Node, mergeAttributes} from '@tiptap/core'
import {VueNodeViewRenderer} from '@tiptap/vue-2'
import Component from './CTipTabBlockTable2.vue'

export default Node.create({
    name: 'dItemTable',

    group: 'block',
    // inline: true,
    content: 'block+',
    selectable: true,
    defining: true,
    draggable: true,
    // tableRole: 'table',

    // defaultOptions: {
    //     HTMLAttributes: {
    //         class: 'page pagebreak',
    //     },
    // },

    parseHTML() {
        return [
            {
                tag: 'span[data-type="d-item-table"]',
                // preserveWhitespace: 'true',
            },
        ]
    },

    renderHTML({HTMLAttributes}) {
        return ['span', mergeAttributes(HTMLAttributes, {'data-type': 'd-item-table'}), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(Component)
    },

    // commands({type}) {
    //     return {
    //         insertHTML: value =>
    //             (state, dispatch) => {
    //                 const {selection} = state
    //                 const element = document.createElement('div')
    //                 element.innerHTML = value.trim()
    //                 const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
    //                 const transaction = state.tr.insert(selection.anchor, slice.content)
    //                 dispatch(transaction)
    //             }
    //     }
    // },

    addAttributes() {
        return {
            msType: {
                default: null,

                parseHTML: element => {
                    return {
                        msType: element.getAttribute('data-ms-type'),
                    }
                },

                renderHTML: attributes => {
                    return {
                        'data-ms-type': `${attributes.msType}`,
                    }
                },
            },

            msId: {
                default: null,

                parseHTML: element => {
                    return {
                        msId: element.getAttribute('data-ms-id'),
                    }
                },

                renderHTML: attributes => {
                    return {
                        'data-ms-id': `${attributes.msId}`,
                    }
                },
            },
        };
    },
})
