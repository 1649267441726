var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMount
    ? _c(
        "div",
        {
          staticClass: "modal",
          attrs: { id: _vm.modalId, role: "dialog" },
          on: { click: _vm.closeModal },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-xl",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  { staticClass: "modal-header" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.state.obj.name))]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "text-danger mt-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                      on: { click: _vm.closeModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          staticStyle: { "max-height": "calc(100vh - 236px)" },
                        },
                        [
                          !!_vm.state.obj.img && _vm.state.obj.img.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-flex mb-2",
                                  staticStyle: {
                                    "min-height": "200px",
                                    height: "1px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-item h-100",
                                      staticStyle: { "overflow-y": "hidden" },
                                    },
                                    _vm._l(_vm.state.obj.img, function (el) {
                                      return _c("img", {
                                        staticClass: "h-100",
                                        attrs: { src: el, alt: "" },
                                      })
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("multiselect", {
                            staticClass: "mb-2",
                            attrs: {
                              options: _vm.local.category,
                              "show-labels": false,
                              searchable: true,
                              "close-on-select": true,
                              "allow-empty": false,
                              "internal-search": false,
                            },
                            on: {
                              "search-change": _vm.customSearch,
                              input: _vm.onChange,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "singleLabel",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "option__desc" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "option__title" },
                                            [_vm._v(_vm._s(props.option.name))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "option",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "option__desc" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "option__title",
                                              style: {
                                                "padding-left":
                                                  props.option.level + "rem",
                                              },
                                            },
                                            [_vm._v(_vm._s(props.option.name))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1236036848
                            ),
                            model: {
                              value: _vm.state.activeCategory,
                              callback: function ($$v) {
                                _vm.$set(_vm.state, "activeCategory", $$v)
                              },
                              expression: "state.activeCategory",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "px-1",
                              staticStyle: { "overflow-x": "hidden" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _vm._l(
                                    _vm.state.aCompare.filter(
                                      (item) =>
                                        item.id !== "fix_name" &&
                                        item.id !== "fix_category"
                                    ),
                                    function (el) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: el.id,
                                            staticClass: "col-12 draggable",
                                            attrs: { draggable: true },
                                            on: {
                                              dragstart: function ($event) {
                                                return _vm.dragStart(el, $event)
                                              },
                                              dragend: function ($event) {
                                                _vm.draggedItem = null
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "bg-danger-l":
                                                    el.orig === null ||
                                                    el.val === null,
                                                },
                                                attrs: {
                                                  title: _vm.getField(
                                                    "desc",
                                                    el
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getField("name", el)
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.getField("unit", el)
                                              ? _c("span", [
                                                  _vm._v(
                                                    ", " +
                                                      _vm._s(
                                                        _vm.getField("unit", el)
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { opacity: "0.5" },
                                              },
                                              [
                                                _vm._v(
                                                  " (" + _vm._s(el.id) + ")"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.getField("type", el))
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            el.val
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v(_vm._s(el.val))]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 d-flex" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: el.expl,
                                                  expression: "el.expl",
                                                },
                                              ],
                                              staticClass:
                                                "form-control d-inline-block py-0 h-auto",
                                              staticStyle: {
                                                "max-width": "4rem",
                                              },
                                              attrs: {
                                                title:
                                                  "разделитель разбиения строки",
                                              },
                                              domProps: { value: el.expl },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    el,
                                                    "expl",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: el.factor,
                                                  expression: "el.factor",
                                                },
                                              ],
                                              staticClass:
                                                "form-control d-inline-block py-0 h-auto",
                                              staticStyle: {
                                                "max-width": "4rem",
                                              },
                                              attrs: {
                                                disabled:
                                                  _vm.getField("type", el) ===
                                                  "string",
                                                title: "множитель",
                                              },
                                              domProps: { value: el.factor },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    el,
                                                    "factor",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: el.fix,
                                                  expression: "el.fix",
                                                },
                                              ],
                                              staticClass:
                                                "form-control d-inline-block py-0 h-auto",
                                              attrs: {
                                                title: "фиксированное значение",
                                              },
                                              domProps: { value: el.fix },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    el,
                                                    "fix",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm",
                                      attrs: { type: "button" },
                                      on: { click: _vm.calc },
                                    },
                                    [_vm._v("посчитать")]
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          staticStyle: { "max-height": "calc(100vh - 236px)" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "px-1",
                              staticStyle: { "overflow-x": "hidden" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.state.obj.prop,
                                    function (val, key) {
                                      return [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0 droppable",
                                            attrs: { "data-drop-key": key },
                                            on: {
                                              dragover: function ($event) {
                                                $event.preventDefault()
                                              },
                                              drop: _vm.drop,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(key) +
                                                ": "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold",
                                                attrs: { "data-drop-key": key },
                                              },
                                              [_vm._v(_vm._s(val))]
                                            ),
                                            _vm._v(" "),
                                            _vm.getTag(key)
                                              ? _c(
                                                  "span",
                                                  { staticClass: "badge" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.getTag(key))
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer d-flex flex-row" }, [
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.clickLocalSave },
                      },
                      [_vm._v("сохранить")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v("закрыть")]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }