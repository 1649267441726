<template>
    <draggable
        tag="div"
        class="item-container"
        :list="list"
        :value="value"
        @input="emitter"
        handle=".handle"
        animation="150"
        group="nested"
        :fallback-on-body="true"
        :swap-threshold="0.5"
        ghost-class="ghost"
        filter=".no-drag"
        @change="onChange"
    >
        <div v-for="el in realValue" class="item-group" :key="el.id">
            <div class="item row mb-0" style="cursor: pointer" :class="{ru:el.isRu, 'not-visible':!el.isVisible}" @contextmenu.prevent="onShowContext($event,el)">
                <div class="col-1 text-center">
                    <font-awesome-icon icon="fa-solid fa-bars" class="handle"/>
                    <span class="ml-2 small"> {{ el.npp }}</span>
                </div>
                <div class="col-5">
                    <span class="small" :class="{'font-weight-bold':el.isTitle}"> {{ el.name }}</span>
                    <font-awesome-icon v-if="!el.isVisible" icon="fa-solid fa-eye-slash"/>
                </div>
                <div class="col-1 small text-center">{{ el.marka }}</div>
                <div class="col-1 small text-center">{{ el.code }}</div>
                <div class="col-1 small text-center">{{ el.firma }}</div>
                <div class="col-1 small text-center">{{ el.edizm }}</div>
                <div class="col-1 small text-center">{{ el.kolvo }}</div>
                <div class="col-1 small text-center">{{ el.desc }}</div>
            </div>
            <nested-so class="item-sub ml-3" :list="el.child" :level="level + 1" :id="el.id" :nbi="nbi"/>
        </div>

    </draggable>
</template>

<script>

    import draggable from 'vuedraggable'

    export default {
        name: "nested-so",
        components: {draggable},
        props: {
            value: {
                required: false,
                type: Array,
                default: null
            },
            list: {
                required: false,
                type: Array,
                default: null
            },
            level: {
                type: Number,
                default: 1,
            },
            id: {
                required: false,
                type: String,
                default: '0'
            },
            nbi: {
                required: true,
                type: Object,
                default: {}
            },
        },
        data: function () {
            return {
                isMove: true,
                oldValue: this.value,
                lValue: this.value,
            }
        },
        created: function () {

        },
        methods: {
            onShowContext(e, el) {
                Bus.$emit('onShowContextMenu', {e: e, el: el, refs: 'menu'});
            },
            clickMinus(obj) {
                if (obj.options.count_page <= 0) return;
                obj.options.count_page -= 1;
            },
            clickPlus(obj) {
                obj.options.count_page += 1;
            },
            getTitle(obj) {
                let res = obj.stamp.type + " \nлист.скв. " + obj.stamp.list_num_global + " \nлистов " + obj.stamp.list_num_text;
                if (obj.stamp.list_count > 1) res += ' из ' + obj.stamp.list_count;
                if (obj.options.count_page > 1) res += "\nсостоит из " + obj.options.count_page + ' листов';

                return res;
            },
            clickRemove(obj) {
                Bus.$emit('deletePps', obj);
            },
            emitter(value) {
                this.$emit("input", value);
            },
            onChange() {
                this.realValue.forEach(item => item.parent_id = this.id);
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            realValue() {
                return this.value ? this.value : this.list;
            },

        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

    .handle {
        cursor: move;
        color: $primary;
    }

    .ghost {
        opacity: 0.3;
        background: #c8ebfb;
    }

    .item-container {
        margin: 0;
    }

    .item {
        padding: 0.3rem;
        border-bottom: solid $primary 1px;
        cursor: pointer;

        &.not-visible {
            opacity: 0.5;
        }

        &:hover {
            background-color: lighten($primary, 20%);
        }

        &.ru {
            font-weight: bold;
            //border: 1px solid $primary;
            background-color: lighten($primary, 50%);
        }
    }

</style>
