var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMount
    ? _c(
        "div",
        {
          staticClass: "modal",
          attrs: { id: _vm.modalId, role: "dialog" },
          on: { click: _vm.closeModal },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-xl",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  { staticClass: "modal-header" },
                  [
                    _vm.state.isEdit
                      ? _c("span", [_vm._v("редактировать")])
                      : _c("span", [_vm._v("вставить потребитель")]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "text-danger mt-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                      on: { click: _vm.closeModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", { staticClass: "w-100" }, [
                        _c("span", { staticClass: "small" }, [
                          _vm._v("Название "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "w-100",
                            attrs: { title: "Название оборудования" },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "text-success",
                              class: {
                                "text-danger": _vm.state.obj.name === "",
                              },
                              attrs: {
                                icon: "fa-solid fa-circle-exclamation",
                                title: "обязательное поле",
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.state.obj.name,
                                  expression: "state.obj.name",
                                  modifiers: { trim: true },
                                },
                              ],
                              ref: "v_focus",
                              staticClass: "form-control",
                              class: { lock: _vm.lock.name },
                              attrs: { placeholder: "Название" },
                              domProps: { value: _vm.state.obj.name },
                              on: {
                                keyup: function ($event) {
                                  _vm.lock.name = false
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.state.obj,
                                    "name",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("e-input", {
                          attrs: {
                            lock: _vm.lock,
                            k: "marka",
                            label: "марка",
                            title: "Марка оборудования",
                            "is-number": false,
                          },
                          model: {
                            value: _vm.state.obj.marka,
                            callback: function ($$v) {
                              _vm.$set(_vm.state.obj, "marka", $$v)
                            },
                            expression: "state.obj.marka",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("e-input", {
                          attrs: {
                            lock: _vm.lockB,
                            k: "name",
                            label: "Производитель",
                            title: "Производитель",
                            "is-number": false,
                          },
                          model: {
                            value: _vm.state.obj.brand.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.state.obj.brand, "name", $$v)
                            },
                            expression: "state.obj.brand.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("e-input", {
                          attrs: {
                            lock: _vm.lockB,
                            k: "article",
                            label: "Артикул",
                            title: "Артикул",
                            "is-number": false,
                          },
                          model: {
                            value: _vm.state.obj.brand.article,
                            callback: function ($$v) {
                              _vm.$set(_vm.state.obj.brand, "article", $$v)
                            },
                            expression: "state.obj.brand.article",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("e-input", {
                          attrs: {
                            lock: _vm.lockI,
                            k: "unit",
                            label: "ед.изм.",
                            title: "Единица измерения",
                            "is-number": false,
                          },
                          model: {
                            value: _vm.state.obj.info.unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.state.obj.info, "unit", $$v)
                            },
                            expression: "state.obj.info.unit",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("e-input", {
                          attrs: {
                            lock: _vm.lockI,
                            k: "desc",
                            label: "Описание",
                            title: "Описание",
                            "is-number": false,
                          },
                          model: {
                            value: _vm.state.obj.info.desc,
                            callback: function ($$v) {
                              _vm.$set(_vm.state.obj.info, "desc", $$v)
                            },
                            expression: "state.obj.info.desc",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._l(
                        _vm.local.activeCategoryL.prop.fields.filter(
                          (item) => item.req
                        ),
                        function (val) {
                          return [
                            _c("b-input-field", {
                              attrs: { lock: _vm.lockF, field: val },
                              model: {
                                value: _vm.state.obj.fields[val.id],
                                callback: function ($$v) {
                                  _vm.$set(_vm.state.obj.fields, val.id, $$v)
                                },
                                expression: "state.obj.fields[val.id]",
                              },
                            }),
                          ]
                        }
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.local.activeCategoryL.prop.fields.filter(
                          (item) => !item.req
                        ),
                        function (val) {
                          return [
                            _c("b-input-field", {
                              attrs: { lock: _vm.lockF, field: val },
                              model: {
                                value: _vm.state.obj.fields[val.id],
                                callback: function ($$v) {
                                  _vm.$set(_vm.state.obj.fields, val.id, $$v)
                                },
                                expression: "state.obj.fields[val.id]",
                              },
                            }),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer d-flex flex-row" }, [
                  _c(
                    "div",
                    { staticClass: "mr-auto" },
                    [
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "fa-solid fa-lock",
                          title: "заблокировать всё",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setLock(true)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("font-awesome-icon", {
                        staticClass: "ml-3",
                        attrs: {
                          icon: "fa-solid fa-lock-open",
                          title: "разблокировать всё",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setLock(false)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _vm.state.obj.fields.url !== ""
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.state.obj.fields.url,
                              target: "_blank",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "mt-1 mr-3",
                              attrs: {
                                icon: "fa-solid fa-arrow-up-right-from-square",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.lastSelectObj
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button", disabled: !_vm.isReqFull },
                            on: { click: _vm.clickInsert },
                          },
                          [_vm._v("вставить новую запись")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.state.isEdit
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: { type: "button", disabled: !_vm.isReqFull },
                            on: { click: _vm.clickLocalSave },
                          },
                          [_vm._v("сохранить")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v("закрыть")]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }