<template>
    <div>
        <div class="card-body">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <button class="btn btn-primary" @click="clickReset">reset</button>

            <div class="row zag">
                <div class="col-5">
                    <div class="row">
                        <div class="col-2">позиция</div>
                        <div class="col-10">название</div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="row">
                        <div class="col-3">марка</div>
                        <div class="col-3">артикул</div>
                        <div class="col-2">поставщик</div>
                        <div class="col-1">ед-изм</div>
                        <div class="col-1">кол-во</div>
                        <div class="col-2">примечение</div>
                    </div>
                </div>
            </div>

            <nested-specification class="item-sub" v-model="local.table" :local="local"/>
        </div>
        <modal-so modal-id="modalSo" :p-local="local" :p-state="state" @updInit="init" @selectObj="onSelectObjFromBase"/>
        <!--        <context-menu-my ref="menuSo1" :items="items"/>-->
    </div>
</template>
<script>

import uniqid from 'uniqid';
import ModalPotr from "./sub/ModalPotr";
import {eTable} from "./sub/tools";
import ContextMenuMy from "./sub/ContextMenuMy.vue";
import ModalSo from "./sub/ModalSo.vue";
import nestedSpecification from "./sub/nestedSpecification.vue";

export default {
    name: 'specification0',
    components: {nestedSpecification, ModalSo, ContextMenuMy},
    props: {
        pLocal: {},
        pState: {}
    },
    data: function () {
        return {
            eTable: new eTable(this),
            local: {
                table: this.pLocal.table,
                selectArr: [],
                saveArr: [],
                fListImportPotr: this.pLocal.listImportPotr,
                ePotr: this.pLocal.ePotr,
                listName3: this.pLocal.listName3,
                listAvt: this.pLocal.listAvt,
                listKab: this.pLocal.listKab,
                base: this.pLocal.base,
            },
            state: {
                search: this.pState.search,
            },
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (this.pState.activeTab === 0) {
                if (e.key === 'insert') this.showModalInsert();
                if (e.key === 113) this.showModal();
            }
        });
        Bus.$on('updTable', () => this.init());
        Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
        Bus.$on('dotSelect', () => this.eTable.onDotSelect());
        Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
        Bus.$on('dotInverse', () => this.eTable.onDotInverse());
    },
    mounted() {
        this.init();
        this.local.selectArr = [];
        if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
        setTimeout(() => this.$refs.search.focus(), 200);
    },
    methods: {

        onSelectObjFromBase(obj) {
            this.local.selectArr.forEach(item => {
                item.base_id = obj.id;
                item.code = obj.code;
                item.edizm = obj.edizm;
                item.firma = obj.firma;
                item.marka = obj.marka;
                item.name = obj.namer1;
                if (obj.dop.x) item.dop.x = obj.dop.x;
                if (obj.dop.y) item.dop.y = obj.dop.y;
                if (obj.dop.z) item.dop.z = obj.dop.z;
                this.local.saveArr.push(item);
            })
        },
        showModal() {
            if (this.local.selectArr.length === 0) return;
            Bus.$emit('show_modalSo');
        },
        showModalInsert() {
            if (this.local.selectArr.length === 0) return;
            Bus.$emit('showI_modalSo');
        },
        init() {
            let res = [];
            this.local.table.forEach(item => {
                let l = this.local.selectArr.find(item2 => item2.id === item.id);
                if (l) res.push(l); else res.push(item);
            })
            this.local.table = res;
        },
        clickReset() {
            location.href = '/marka_element/reset';
        },
    },
    computed: {
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
        fTable: function () {
            if (this.state.search.val === '') return this.local.table;
            return this.local.table.filter(item => {
                let res = true;
                this.state.search.val.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
    },
    watch: {
        'fTable': {
            handler: function (newVal, oldVal) {
                this.eTable.clearSelectArr();
            },
            deep: false
        },
    }
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/variables';

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.row {
    padding: 0;
    margin: 0.1rem 0;

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        text-align: center;
        padding: 0;
    }

    .zag {
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        border: 1px solid $primary;
        background-color: lighten($primary, 20%);
    }
}

.row-w {
    border-bottom: 1px solid #959595;
    cursor: pointer;
}

span.small {
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    font-style: italic;
}

table.table.in_td {
    /*td{*/
    /*    text-align: center;*/
    /*    vertical-align: middle;*/
    /*}*/
    th {
        border: none;
        background: none;
        padding: 0;
    }

    tr {
        border: none;
        background: none;
        height: 0px;

        &.hover:hover {
            background-color: lighten($primary, 20%);
        }
    }

    tbody tr:first-child {
        td {
            border: none;
        }
    }

    .bg-primary-l {
        background-color: lighten($primary, 40);
    }

    .bg-success-l {
        background-color: lighten($success, 40);
    }
}

</style>
