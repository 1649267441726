<template>
    <div>
        <div class="row">
            <div class="col-12">
                <button type="button" class="btn btn-primary" @click="addVars">добавить</button>
            </div>
        </div>
        <div class="row" v-for="el in inputVal" :key="el.id">
            <div class="col-2">
                <input class="form-control w-100" v-model.trim="el.id_f" title="id"/>
            </div>
            <div class="col-2">
                <input class="form-control w-100" v-model.trim="el.name" title="имя перменной"/>
            </div>
            <div class="col-2">
                <input class="form-control w-100" v-model.trim="el.v_in" title="значение"/>
            </div>
            <div class="col-4">
                <input class="form-control w-100" v-model.trim="el.formula" :title="el.resp.fText"/>
                <span v-if="el.resp.eMess" class="small text-danger">{{ el.resp.eMess }}</span>
            </div>
            <div class="col-2 d-flex">
                <div class="w-75 position-relative">
                    <input class="form-control" v-model.trim="el.v_out" disabled title="результат"/>
                    <span class="position-absolute" style="top: 0.45rem; right: 0.8rem; cursor: pointer;" title="округление" @click="clickRound(el)">{{ el.round }}</span>
                </div>
                <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-2 ml-3 w-auto" style="cursor: pointer" size="lg" @click="removeVars(el)"/>
            </div>
        </div>
    </div>
</template>
<script>
import uniqid from "uniqid";

export default {
    name: 'c-list-v-f-param-so',
    props: {
        value: {type: Array, default: () => [],},
    },
    methods: {
        clickTag(e) {
            this.inputVal = e;
        },
        removeVars(el) {
            let index = this.state.obj.v.findIndex(item => item.id === el.id);
            if (index >= 0) this.state.obj.v.splice(index, 1);
        },
        clickRound(obj) {
            if (obj.round === 3) {
                obj.round = 0;
            } else {
                obj.round += 1;
            }
        },
        addVars() {
            let uid = uniqid();
            let o = {
                id: 'v' + uid,
                id_f: uid,
                name: '',
                v_in: 0,
                formula: '',
                v_out: 0,
                isCalc: false,
                isShow: true,
                round: 0,
                resp: {
                    eMess: '',
                    fText: '',
                }
            };
            this.inputVal.push(o);
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', this.value);
            }
        }
    }
}
</script>
