<template>
    <div>
        <div class="container-fluid m1">

            <title-for-page :c-page="state.cPage" :nbi="nbi"/>

            <div v-if="isMounted" class="row justify-content-center">
                <div class="col-12 col-xl-8">

                    <nav v-if="!!state.cPage.id" class="navbar navbar-expand pl-0 pt-0" style="margin-left: -0.5rem;">
                        <div class="container-fluid">
                            <div class="navbar-nav">
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt=!state.swOnOpt" :class="{active:state.swOnOpt}" title="настройки страницы">настройки</button>
                                </div>
                                <div class="nav-item">
                                    <button class="nav-link btn" @click="state.swOnOpt2=!state.swOnOpt2" :class="{active:state.swOnOpt2}" title="настройки страницы">настройки таблицы</button>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <card-options :c-page="state.cPage" :state="state" class="mb-3"/>

                    <div v-if="state.swOnOpt2" class="card mb-3">
                        <div class="card-body">

                        </div>
                    </div>

                    <h1 v-if="!state.cPage.id" class="text-center mb-5">Проверка кабеля на термическую стойкость</h1>

                    <div class="form-check d-inline-block">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" v-model="cOpt.data.setting.typeReport" value='long'> подробный отчёт
                        </label>
                    </div>
                    <div class="form-check d-inline-block ml-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" v-model="cOpt.data.setting.typeReport" value='short'> краткий отчёт
                        </label>
                    </div>

                    <button type="button" class="btn btn-sm btn-danger ml-2" style="float: right" @click="clickReset">
                        <i v-if="status.reset" class="fa fa-spinner fa-spin fa-fw"></i> reset
                    </button>


                    <div class="row mt-5 border-top pt-3">
                        <div class="col-6">
                            температура окружающей среды в регионе, &deg;C
                            <small class="text-muted">определяется по СП 131.13330.2020 табл.5, принимается для наиболее тяжёлого режима а именно, берётся максимальное среднемесячное
                                значение</small>
                        </div>
                        <div class="col-6">
                            <label class="small w-100 text-left mb-0">
                                регион
                                <span class="ml-3 text-danger small" style="cursor: pointer" @click="cOpt.data.cityObj={id:null}">сброс</span>
                            </label>
                            <multiselect
                                ref="ms4"
                                v-model="cOpt.data.cityObj"
                                :options="local.listCity"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                :internal-search="false"
                                @search-change="customSearch4"
                                @open="onOpen4('ms4')"
                                :blockKeys="['Delete']"
                                placeholder="регион"
                                class="mb-2"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc"><span class="option__title">{{ props.option.city }} ({{ props.option.region }})</span></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.city }} ({{ props.option.region }})</span>
                                    </div>
                                </template>
                            </multiselect>
                            <label class="small w-100 text-left">
                                температура окружающей среды в регионе, &deg;C
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.t_os_fact.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.t_os_fact.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.t_os_fact.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.t_os_fact.lock=true"/>
                                </span>
                                <input class="form-control" v-model.number="cOpt.data.t_os_fact.val" :disabled="cOpt.data.t_os_fact.lock"/>
                            </label>
                        </div>
                    </div>

                    <div class="row border-top pt-3">
                        <div class="col-6">
                            температура окружающей среды, &deg;C
                            <small class="text-muted">температура окружающей среды, при прокладке в земле - 15&deg;C, при прокладке на воздухе - 25&deg;C</small>
                        </div>
                        <div class="col-6">
                            <input class="form-control" v-model.number="cOpt.data.t_os"/>
                            <div class="text-left mt-1">
                                <div class="form-check d-inline-block">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.isInAir" v-bind:value=false @click="cOpt.data.t_os=15"> земля
                                    </label>
                                </div>
                                <div class="form-check d-inline-block ml-3">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.isInAir" v-bind:value=true @click="cOpt.data.t_os=25"> воздух
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row border-top pt-3">
                        <div class="col-6">
                            расчётная точка
                        </div>
                        <div class="col-6">
                            <div v-if="ePotr.length>0">
                                <div class="form-check d-inline-block">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.calcPoint.type" value='eBbs'> секция шин
                                    </label>
                                </div>
                                <div class="form-check d-inline-block ml-3">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.calcPoint.type" value='ePotr'> потребитель
                                    </label>
                                </div>

                                <span class="ml-3 text-danger small" style="cursor: pointer" @click="cOpt.data.calcPoint.id=0">сброс</span>

                                <div class="row">
                                    <div class="col-10">
                                        <div v-if="cOpt.data.calcPoint.type==='ePotr'" class="mt-3">
                                            <multiselect
                                                v-model="cOpt.data.calcPoint.id"
                                                :options="local.ePotr.map(item=>item.id)"
                                                :show-labels="false"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :allow-empty="false"
                                                :internal-search="false"
                                                @input="clickSave"
                                                @search-change="customSearch"
                                            >
                                                <template slot="singleLabel" slot-scope="props">
                                                    <span class="option__desc"><span class="option__title">{{ getMSStr(props.option) }}</span></span>
                                                </template>
                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ getMSStr(props.option) }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div v-if="cOpt.data.calcPoint.type==='eBbs'" class="mt-3">
                                            <multiselect
                                                v-model="cOpt.data.calcPoint.id"
                                                :options="local.eBbs.map(item=>item.id)"
                                                :show-labels="false"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :allow-empty="false"
                                                :internal-search="false"
                                                @input="clickSave"
                                                @search-change="customSearchBbs"
                                            >
                                                <template slot="singleLabel" slot-scope="props">
                                                    <span class="option__desc"><span class="option__title">{{ getMSStrBbs(props.option) }}</span></span>
                                                </template>
                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ getMSStrBbs(props.option) }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row border-top pt-3">
                        <div class="col-6">
                            устройство защиты
                        </div>
                        <div class="col-3">
                            <multiselect
                                v-if="!cOpt.data.calcPoint.id"
                                ref="ms1"
                                v-model="cOpt.data.avt.obj"
                                placeholder="аппарат защиты"
                                :options="local.listBaseAvt"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                :internal-search="false"
                                @search-change="customSearch3"
                                @open="onOpen('ms1')"
                                @input="upd"
                                :blockKeys="['Delete']"
                                class="mb-3 mt-4"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ getMSStrAvt(props.option.uid, 'marka') }}
                                                            </span>
                                                        </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ getMSStrAvt(props.option.uid, 'marka') }}</span>
                                        <br>
                                        <span class="option__small small">{{ getMSStrAvt(props.option.uid, 'brand') }}</span>
                                    </div>
                                </template>
                            </multiselect>

                            <div v-if="cOpt.data.avt.obj.uid">
                                <p v-if="cOpt.data.calcPoint.id && cOpt.data.isEnd" class="small mb-0" style="background-color: orange;">защита в конце линии</p>
                                <p v-if="cOpt.data.calcPoint.id" class="small">информация из однолинейной схемы</p>
                                <p class="m-0">{{ cOpt.data.avt.obj.marka }}</p>
                                <p class="m-0">Iном = {{ cOpt.data.avt.obj.fields.iots }}А</p>
                                <p class="m-0">Icn = {{ cOpt.data.avt.obj.fields.icn }}кА</p>
                                <p class="m-0">характеристика: {{ cOpt.data.avt.obj.fields.har }}</p>
                            </div>

                        </div>
                        <div class="col-3">
                            <label class="w-100 small" title="Номинальный ток аппарата защиты">
                                Iном
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.avt.iots.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.avt.iots.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.avt.iots.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.avt.iots.lock=true"/>
                                </span>
                                <input class="form-control" v-model.number="cOpt.data.avt.iots.val" :disabled="cOpt.data.avt.iots.lock"/>
                            </label>
                            <label class="mb-0 w-100" title="Характеристика срабатывания аппарата защиты">
                                <span class="small">характеристика срабатывания</span>
                                <multiselect
                                    v-model="cOpt.data.avt.har.val"
                                    :options="listHar.map(item=>item.id)"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :searchable="false"
                                    :disabled="cOpt.data.avt.iots.lock"
                                    placeholder="характеристика срабатывания"
                                    :custom-label="opt => listHar.find(item => item.id === opt).name"
                                />
                            </label>

                        </div>
                    </div>

                    <div class="row border-top pt-3">
                        <div class="col-6">
                            характеристики кабеля
                            <small class="text-muted">длительно допустимый ток кабеля берётся из технических характеристик кабеля или из ПУЭ</small>
                        </div>
                        <div class="col-3">
                            <multiselect
                                v-if="!cOpt.data.calcPoint.id"
                                ref="ms2"
                                v-model="cOpt.data.cable.obj"
                                placeholder="питающий кабель"
                                :options="local.listBaseCable"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                :internal-search="false"
                                @input="upd"
                                @search-change="customSearch2"
                                @open="onOpen2('ms2')"
                                :blockKeys="['Delete']"
                                class="mb-3 mt-4"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ getMSStrCable(props.option.uid, 'marka') }}
                                                            </span>
                                                        </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ getMSStrCable(props.option.uid, 'marka') }}</span>
                                        <br>
                                        <span class="option__small small">{{ getMSStrCable(props.option.uid, 'brand') }}</span>
                                    </div>
                                </template>
                            </multiselect>
                            <div v-if="cOpt.data.cable.obj.uid">
                                <p v-if="cOpt.data.calcPoint.id" class="small">информация из однолинейной схемы</p>
                                <p class="m-0"><span v-if="cOpt.data.cable.number.val>1">{{ cOpt.data.cable.number.val }}х</span>{{ cOpt.data.cable.obj.marka }}</p>
                                <p class="m-0" v-if="cOpt.data.cable.number.val>1">количество ниток кабеля: х{{ cOpt.data.cable.number.val }}</p>
                                <p class="m-0" :class="{'bg-danger-l':getNormIByType('iacv')===0}">в воздухе Iдоп = {{ getNormIByType('iacv') }}А</p>
                                <p class="m-0" :class="{'bg-danger-l':getNormIByType('iacz')===0}">в земле Iдоп = {{ getNormIByType('iacz') }}А</p>
                                <p class="m-0" :class="{'bg-danger-l':!cOpt.data.cable.obj.fields.sech}">сечение: {{ cOpt.data.cable.obj.fields.sech }}мм<sup>2</sup></p>
                                <p class="m-0" :class="{'bg-danger-l':!cOpt.data.cable.obj.fields.u}">U = {{ cOpt.data.cable.obj.fields.u }}кВ</p>
                                <p class="m-0" :class="{'bg-danger-l':!cOpt.data.cable.obj.fields.isCu}">материал жилы:
                                    <span v-if="!!cOpt.data.cable.obj.fields.isCu">медь</span>
                                    <span v-else>алюминий</span>
                                </p>
                                <p class="m-0 text-danger" v-if="cOpt.data.cable.number.val>1">расчёт производится при повреждении на одной нитке кабеля</p>

                            </div>
                        </div>
                        <div class="col-3">
                            <label class="w-100 small">
                                количество ниток кабеля
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.cable.number.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.cable.number.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.cable.number.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.cable.number.lock=true"/>
                                </span>
                                <input class="form-control" v-model.number="cOpt.data.cable.number.val" :disabled="cOpt.data.cable.number.lock"/>
                            </label>
                            <label class="w-100 small">
                                рабочий ток кабеля, А
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.in.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.in.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.in.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.in.lock=true"/>
                                </span>
                                <input class="form-control" v-model.number="cOpt.data.in.val" :disabled="cOpt.data.in.lock"/>
                            </label>
                            <label class="small w-100 text-left">
                                сечение жил кабеля, мм<sup>2</sup>
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.cable.sechenie.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.cable.sechenie.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.cable.sechenie.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.cable.sechenie.lock=true"/>
                                </span>
                                <input class="form-control" v-model.number="cOpt.data.cable.sechenie.val" :disabled="cOpt.data.cable.sechenie.lock"/>
                            </label>
                            <label class="small w-100 text-left">
                                длительно допустимый ток кабеля, А
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.cable.i_dop.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.cable.i_dop.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.cable.i_dop.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.cable.i_dop.lock=true"/>
                                </span>
                                <input class="form-control" v-model.number="cOpt.data.cable.i_dop.val" :disabled="cOpt.data.cable.i_dop.lock"/>
                            </label>
                            <div class="text-left">
                                <div class="form-check d-inline-block">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.u.val" v-bind:value='1' :disabled="cOpt.data.cable.u.lock"> до 1кВ
                                    </label>
                                </div>
                                <div class="form-check d-inline-block ml-3">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.u.val" v-bind:value='6' :disabled="cOpt.data.cable.u.lock"> 6кВ
                                    </label>
                                </div>
                                <div class="form-check d-inline-block ml-3">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.u.val" v-bind:value='10' :disabled="cOpt.data.cable.u.lock"> 10кВ
                                    </label>
                                </div>
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.cable.u.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.cable.u.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.cable.u.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.cable.u.lock=true"/>
                                </span>
                            </div>
                            <div class="text-left mt-2">
                                <label class="text-primary">алюминий <input class="apple-switch" type="checkbox" v-model="cOpt.data.cable.isCu.val" :disabled="cOpt.data.cable.isCu.lock"> медь</label>
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.cable.isCu.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.cable.isCu.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.cable.isCu.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.cable.isCu.lock=true"/>
                                </span>
                            </div>
                            <div class="text-left">
                                <div class="form-check d-inline-block" title="Кабель с ПВХ или резиновой изоляцией">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.obolochka.val" value='plastic'> ПВХ
                                    </label>
                                </div>
                                <div class="form-check d-inline-block ml-3" title="Кабель с изоляцией из вулканизированного полиэтилена">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.obolochka.val" value='v_plastic'>
                                        ПЭТ
                                    </label>
                                </div>
                                <div class="form-check d-inline-block" title="Бронированный кабель с пропитанной бумажной изоляцией">
                                    <label class="form-check-label ml-3">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.obolochka.val" value='bron_bum'
                                               disabled>
                                        ББ
                                    </label>
                                </div>
                                <div class="form-check d-inline-block ml-3" title="Не бронированный кабель с пропитанной бумажной изоляцией">
                                    <label class="form-check-label">
                                        <input class="form-check-input" type="radio" v-model="cOpt.data.cable.obolochka.val" value='bum'
                                               disabled> Б
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="cOpt.data.calcPoint.id" class="row border-top pt-3">
                        <div class="col-6">
                            виды токов КЗ
                            <br>
                            <div class="form-check d-inline-block">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz3"> 3хф КЗ
                                </label>
                            </div>
                            <div class="form-check d-inline-block ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz3r"> 3хфД КЗ
                                </label>
                            </div>
                            <div class="form-check d-inline-block ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz3sr"> 3хф.ср КЗ
                                </label>
                            </div>
                            <br>
                            <div class="form-check d-inline-block">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz2"> 2хф КЗ
                                </label>
                            </div>
                            <div class="form-check d-inline-block ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz2r"> 2хфД КЗ
                                </label>
                            </div>
                            <br>
                            <div class="form-check d-inline-block">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz1"> 1ф КЗ
                                </label>
                            </div>
                            <div class="form-check d-inline-block ml-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" v-model="cOpt.data.setting.ikz1r"> 1фД КЗ
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-2 text-center">l, м</div>
                                <div class="col-2 text-center">Iкз, кА</div>
                                <div class="col-2 text-center">tотс, сек</div>
                                <div class="col-2 text-center">K</div>
                                <div class="col-2 text-center">T, &deg;C</div>
                            </div>
                            <template v-for="kk in ['kz3','kz3r','kz3sr','kz2','kz2r','kz1','kz1r']">
                                <div v-if="cOpt.data.setting['i'+kk]" class="col-12 font-weight-bold text-center">{{ kk }}</div>
                                <div v-if="cOpt.data.setting['i'+kk]" class="row" v-for="el in cOpt.data.IkzArr">
                                    <div class="col-2 text-center">{{ el.l }}</div>
                                    <div class="col-2 text-center">{{ el['i' + kk] }}</div>
                                    <div class="col-2 text-center">{{ el['t' + kk] }}</div>
                                    <div class="col-2 text-center">{{ el['k' + kk] }}</div>
                                    <div class="col-2 text-center">
                                        <span v-if="el['r' + kk]<=cOpt.data.tdop.t2"
                                              :title="'('+el['r' + kk]+'<='+cOpt.data.tdop.t2+') возгорание отсутствует, кабели пригодны для дальнейшей эксплуатации'"
                                              style="background-color: limegreen;">{{ el['r' + kk] }}</span>
                                        <span v-if="el['r' + kk]>cOpt.data.tdop.t2 && el['r' + kk]<=cOpt.data.tdop.t3"
                                              :title="'('+cOpt.data.tdop.t2+'<'+el['r' + kk]+'<='+cOpt.data.tdop.t3+') возгорание отсутствует, допускается эксплуатация кабеля в течении одного года'"
                                              style="background-color: orange;">{{ el['r' + kk] }}</span>
                                        <span v-if="el['r' + kk]>cOpt.data.tdop.t3 && el['r' + kk]<=cOpt.data.tdop.t1"
                                              :title="'('+cOpt.data.tdop.t3+'<'+el['r' + kk]+'<='+cOpt.data.tdop.t1+') возгорание отсутствует, кабель не пригоден для дальнейшей эксплуатации'"
                                              style="background-color: palevioletred;">{{ el['r' + kk] }}</span>
                                        <span v-if="el['r' + kk]>cOpt.data.tdop.t1"
                                              :title="'('+el['r' + kk]+'>'+cOpt.data.tdop.t1+') кабель не прошёл проверку на возгорание'"
                                              style="background-color: palevioletred;">{{ el['r' + kk] }}</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>


                    <div v-if="!cOpt.data.calcPoint.id" class="row border-top pt-3">
                        <div class="col-6">
                            ток КЗ в проверяемой точке линии, кА
                            <small class="text-muted">ток КЗ в проверяемой точке линии</small>
                            <small class="text-muted">основная проверка выполняется для металлического 3х фазного КЗ на расстойнии 1/3 от длины кабеля</small>
                            <small class="text-muted">также рекомендуется проверять кабель в конце линии, для разных токов КЗ</small>
                        </div>
                        <div class="col-6">
                            <input class="form-control" v-model.number="cOpt.data.ikz"/>
                        </div>
                    </div>

                    <div v-if="!cOpt.data.calcPoint.id" class="row border-top pt-3">
                        <div class="col-6">
                            время действия резервной защиты
                            <small class="text-muted">время действия резервной защиты в соответствии с выбранным аппаратом защиты, или введённое вручную</small>
                        </div>
                        <div class="col-6">
                            <label class="small w-100 text-left">
                                время действия резервной защиты,
                                <span v-if="cOpt.data.time_ots.lock">{{ getHarName() }}, </span>
                                сек
                                <span class="ml-2" style="opacity: 0.8; cursor:pointer; color: darkgoldenrod">
                                    <font-awesome-icon v-if="cOpt.data.time_ots.lock" size="sm" icon="fa-solid fa-lock" @click="cOpt.data.time_ots.lock=false"/>
                                    <font-awesome-icon v-if="!cOpt.data.time_ots.lock" size="sm" icon="fa-solid fa-lock-open" @click="cOpt.data.time_ots.lock=true"/>
                                </span>
                                <input class="form-control" v-model.number="cOpt.data.time_ots.val" :disabled="cOpt.data.time_ots.lock"/>
                            </label>
                            <div v-if="cOpt.data.t_before_kz<=3600" style="background-color: palevioletred;" class="d-inline-block">
                                Аппарат защиты сработает от рабочего тока кабеля за время {{ cOpt.data.t_before_kz }}сек.
                            </div>
                        </div>
                    </div>


                    <div v-if="!cOpt.data.calcPoint.id" class="row border-top pt-3">
                        <div class="col-6">
                            температура жилы до короткого замыкания, в нормальном режиме работы
                        </div>
                        <div class="col-6 font-weight-bold">
                            {{ cOpt.data.t_ish }} &deg;C
                        </div>
                    </div>

                    <div v-if="!cOpt.data.calcPoint.id" class="row border-top pt-3">
                        <div class="col-6">
                            расчётный коэффициент K
                        </div>
                        <div class="col-6 font-weight-bold">
                            {{ cOpt.data.k }}
                        </div>
                    </div>

                    <div v-if="!cOpt.data.calcPoint.id" class="row border-top pt-3">
                        <div class="col-6">
                            температура жилы в конце короткого замыкания
                        </div>
                        <div class="col-6 font-weight-bold">
                            <div v-if="cOpt.data.t_res<=cOpt.data.tdop.t2" style="background-color: limegreen;" class="d-inline-block">
                                {{ cOpt.data.t_res }} &deg;C <= {{ cOpt.data.tdop.t2 }} &deg;C
                            </div>
                            <div v-if="cOpt.data.t_res>cOpt.data.tdop.t2 && cOpt.data.t_res<=cOpt.data.tdop.t3" style="background-color: orange;" class="d-inline-block">
                                {{ cOpt.data.tdop.t2 }} &deg;C < {{ cOpt.data.t_res }} &deg;C <= {{ cOpt.data.tdop.t3 }} &deg;C
                            </div>
                            <div v-if="cOpt.data.t_res>cOpt.data.tdop.t3 && cOpt.data.t_res<=cOpt.data.tdop.t1" style="background-color: palevioletred;" class="d-inline-block">
                                {{ cOpt.data.tdop.t3 }} &deg;C < {{ cOpt.data.t_res }} &deg;C <= {{ cOpt.data.tdop.t1 }} &deg;C
                            </div>
                            <div v-if="cOpt.data.t_res>cOpt.data.tdop.t1" style="background-color: palevioletred;" class="d-inline-block">
                                {{ cOpt.data.t_res }} &deg;C > {{ cOpt.data.tdop.t1 }} &deg;C
                            </div>
                            <i v-if="timerLoading" class="fas fa-spin fa-circle-notch fa-fw" style="color: #008000; opacity: 0.5;"></i>
                        </div>
                    </div>


                    <div v-if="!cOpt.data.calcPoint.id">
                        <p v-if="cOpt.data.t_res<=cOpt.data.tdop.t2" style="background-color: limegreen;"><b>Вывод:</b> возгорание отсутствует, кабели пригодны для дальнейшей эксплуатации</p>
                        <p v-if="cOpt.data.t_res>cOpt.data.tdop.t2 && cOpt.data.t_res<=cOpt.data.tdop.t3" style="background-color: orange;">
                            <b>Вывод:</b> возгорание отсутствует, допускается эксплуатация кабеля в течении одного года</p>
                        <p v-if="cOpt.data.t_res>cOpt.data.tdop.t3 && cOpt.data.t_res<=cOpt.data.tdop.t1" style="background-color: palevioletred;">
                            <b>Вывод:</b> возгорание отсутствует, кабель не пригоден для дальнейшей эксплуатации</p>
                        <p v-if="cOpt.data.t_res>cOpt.data.tdop.t1" style="background-color: palevioletred;">
                            <b>Вывод:</b> кабель не прошёл проверку на возгорание</p>
                    </div>


                    <br>
                    <br>


                    <br>
                    <br>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import {page} from "./sub/tools";
import TitleForPage from "./sub/TitleForPage.vue";
import CardOptions from "./sub/CardOptions.vue";
import {noConflict} from "lodash";
// import {default as tools} from "./sub/tools";


export default {
    name: "page-term-cab",
    components: {CardOptions, TitleForPage, Multiselect},
    props: ['cPage', 'ePotr', 'eBbs', 'eSection', 'listHar', 'listBaseCable', 'listBaseAvt', 'listCity', 'normI', 'dataDefault'],
    data: function () {
        return {
            pageT: new page(this),
            state: {
                version: 23,
                cPage: this.cPage,
                swOnOpt: false,
                swOnOpt2: false,
            },
            nbi: {},
            tools: '',
            chartData: {},
            jh: {},
            countries: [],
            isLoadingSearch: false,
            pausedL: false,
            search: '',
            status: {
                reset: false,
                save: false,
                loading: false,
                loadLocalStorage: false,
            },
            local: {
                ePotr: this.ePotr,
                eBbs: this.eBbs,
                listBaseAvt: this.listBaseAvt,
                listBaseCable: this.listBaseCable,
                listCity: this.listCity,
            },
            tempTr: {},
            timer: {},
            timerLoading: false,
            isSave: false,
            nameCField: 'term_cab_0',
            isMounted: false,

        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'reloadCalc') this.pageT.clickReloadCalc()
            if (e.key === 'ctrlS') this.clickSave()
            if (e.key === 'exportWord') this.clickExportWord();
        })
        Bus.$on('navbar_info', obj => this.nbi = obj);
    },
    mounted() {
        this.init();
        Bus.$emit('navbar', {command: 'showButtons', args: {reload: true, save: true, word: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.isMounted = true;
    },
    methods: {
        init() {
            this.pageT.loadLS();
            if (this.pageT.isU(this.state.cPage.id)) this.state.cPage = this.cPage;

            this.local.listBaseAvt = this.listBaseAvt.filter((item, index) => index < 10);
            if (this.cOpt.data.avt.obj) {
                let obj = this.listBaseAvt.find(item => item.uid === this.cOpt.data.avt.obj.uid);
                if (obj) this.local.listBaseAvt.push(obj);
            }
            this.local.listBaseCable = this.listBaseCable.filter((item, index) => index < 10);
            if (this.cOpt.data.cable.obj) {
                let obj = this.listBaseCable.find(item => item.uid === this.cOpt.data.cable.obj.uid);
                if (obj) this.local.listBaseCable.push(obj);
            }
            this.local.listCity = this.listCity.filter((item, index) => index < 10);
            if (this.cOpt.data.cityObj.id !== undefined) {
                let obj = this.listCity.find(item => item.id === this.cOpt.data.cityObj.id);
                if (obj) this.local.listCity.push(obj);
            }
        },
        getHarName() {
            let obj = this.listHar.find(item => item.id === this.cOpt.data.avt.har.val);
            if (!obj) return '';
            return obj.name;
        },
        onOpen(r) {
            setTimeout(() => {
                if (!this.cOpt.data.avt.uid) return;
                let obj = this.local.listBaseAvt.find(item => item.uid === this.cOpt.data.avt.uid);
                if (!obj) return;
                this.$refs[r].$refs.search.value = obj.marka;
            }, 10);
        },
        onOpen4(r) {
            setTimeout(() => {
                if (!this.cOpt.data.cityObj) return;
                let obj = this.local.listCity.find(item => item.id === this.cOpt.data.cityObj.id);
                if (!obj) return;
                this.$refs[r].$refs.search.value = obj.city + ' (' + obj.region + ')';
            }, 10);
        },
        onOpen2(r) {
            setTimeout(() => {
                if (this.cOpt.data.cable.obj.marka === undefined) return;
                this.$refs[r].$refs.search.value = this.cOpt.data.cable.obj.marka;
            }, 10);
        },
        upd() {
            this.setAvtFixVal();
            this.setCableFixVal();
        },
        setAvtFixVal() {
            if (!this.cOpt.data.avt.obj.uid) return;
            if (this.cOpt.data.avt.iots.lock) {
                this.cOpt.data.avt.iots.val = this.cOpt.data.avt.obj.fields.iots;
                this.cOpt.data.avt.har.val = this.cOpt.data.avt.obj.fields.har;
            }
        },
        setCableFixVal() {
            if (!this.cOpt.data.cable.obj.uid) return;
            if (this.cOpt.data.cable.isCu.lock) this.cOpt.data.cable.isCu.val = this.cOpt.data.cable.obj.fields.isCu;
            if (this.cOpt.data.cable.sechenie.lock) this.cOpt.data.cable.sechenie.val = this.cOpt.data.cable.obj.fields.sech;
            if (this.cOpt.data.cable.u.lock) {
                let u = this.cOpt.data.cable.obj.fields.u;
                if (u <= 1) this.cOpt.data.cable.u.val = 1;
                else if (u > 1 && u <= 6) this.cOpt.data.cable.u.val = 6;
                else if (u > 6 && u <= 10) this.cOpt.data.cable.u.val = 10;
            }

            if (this.cOpt.data.cable.obolochka.lock) {
                let o = 'plastic';
                if (this.cOpt.data.cable.obj.fields.isXLPE) o = 'v_plastic';
                this.cOpt.data.cable.obolochka.val = o;
            }

            this.setNormI();
        },
        getNormIByType(type) {
            let i = 0;

            let normI = this.getNormI(this.cOpt.data.cable.obj);
            if (!normI) return i;

            let k = type + this.cOpt.data.cable.obj.fields.num;

            if (normI[k] !== undefined) i = normI[k];

            i = i * this.cOpt.data.cable.number.val;

            return i;
        },
        setNormI() {
            let normI = this.getNormI(this.cOpt.data.cable.obj);
            if (!normI) return;

            let k = 'iacv' + this.cOpt.data.cable.number.val;
            if (!this.cOpt.data.cable.isInAir) k = 'iacz' + this.cOpt.data.cable.number.val;

            let i = 0;
            if (normI[k] !== undefined) i = normI[k];

            if (this.cOpt.data.cable.i_dop.lock) this.cOpt.data.cable.i_dop.val = i;
        },
        //todo js multiselect custom search
        getNormI(obj) {
            let res = this.normI.find(item => {
                return item.sech === obj.fields.sech && item.is_cu === obj.fields.isCu && item.is_xlpe === obj.fields.isXLPE;
            })

            return res;
        },
        //todo js multiselect custom search
        customSearch3(query) {
            if (query === '') return;
            let i = 0;
            this.local.listBaseAvt = this.listBaseAvt.filter(item => {
                if (i > 9) return false;
                let res = true;
                if (query !== '') {
                    query.split(' ').forEach(value => {
                        let s = item.marka + item.brand;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                }
                if (res) i++;
                return res;
            })
        },
        //todo js multiselect custom search
        customSearch2(query) {
            if (query === '') return;
            let i = 0;
            this.local.listBaseCable = this.listBaseCable.filter(item => {
                if (i > 9) return false;
                let res = true;
                if (query !== '') {
                    query.split(' ').forEach(value => {
                        let s = item.marka + item.brand;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                }
                if (res) i++;
                return res;
            })
        },
        customSearch4(query) {
            if (query === '') return;
            let i = 0;
            this.local.listCity = this.listCity.filter(item => {
                if (i > 9) return false;
                let res = true;
                if (query !== '') {
                    query.split(' ').forEach(value => {
                        let s = item.city + item.region.replace('(', '').replace(')', '');
                        res = res && s.toLowerCase().indexOf(value.replace('(', '').replace(')', '').toLowerCase()) !== -1;
                    });
                }
                if (res) i++;
                return res;
            })
        },
        getMSStr(id) {
            let obj = this.local.ePotr.find(item => item.id === id);
            if (obj === undefined) return '';

            let name2 = obj.name2 ?? "_";
            let panel = obj.panel !== undefined ? obj.panel.name : "_";
            let section = obj.section !== undefined ? obj.section.name : "_";
            let room = obj.room !== undefined ? obj.room.name : "_";

            return name2 + ' (' + room + ') ' + panel + ' ' + section;
        },
        getMSStrAvt(uid, field) {
            let obj = this.local.listBaseAvt.find(item => item.uid === uid);
            if (obj === undefined) return '';

            return obj[field] ?? '_';
        },
        getMSStrCable(uid, field) {
            let obj = this.local.listBaseCable.find(item => item.uid === uid);
            if (obj === undefined) return '';

            return obj[field] ?? '_';
        },
        getMSStrBbs(id) {
            let obj = this.local.eBbs.find(item => item.id === id);
            if (obj === undefined) return '';

            let name = obj.name ?? "_";
            let panel = obj.panel !== undefined ? obj.panel.name : "_";

            return panel + ' (' + name + ') ';
        },
        customSearch(query) {
            if (query === '') {
                this.local.ePotr = this.ePotr;
            }
            this.local.ePotr = this.ePotr.filter(item => {
                let res = true;
                query.split(' ').forEach(value => {
                    let s = this.getMSStr(item);
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        customSearchBbs(query) {
            if (query === '') {
                this.local.eBbs = this.eBbs;
            }
            this.local.eBbs = this.eBbs.filter(item => {
                let res = true;
                query.split(' ').forEach(value => {
                    let s = this.getMSStrBbs(item);
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        clickReset() {
            if (window.confirm('Вы уверены что хотите сбросить настройки?')) {
                this.pageT.clickSave('/calc/term_cab/reset', {}, '_reset')
                Bus.$once('zResponse_reset', response => {
                    this.cOpt.data = response.data;
                });
            }
        },
        clickSave() {
            this.pageT.clickSave('/calc/term_cab/save', {cPage: this.state.cPage}, '_save_term_cab')
            Bus.$once('zResponse_save_term_cab', response => {
                this.cOpt.data = response.data;
                if (this.state.cPage.no_project) this.pageT.updLS();
                else this.pageT.clickSaveCPage();
            });
        },
        clickExportWord() {
            if (!this.state.cPage.no_project)
                this.pageT.clickDownload();
            else {
                this.pageT.updLS();
                location.href = this.nbi.url.current + '/export';
            }
        },
    },
    computed: {
        cOpt: function () {
            return this.state.cPage.data.fields.find(item => item.idd === this.nameCField).opt;
        },
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()

            }
        }
    },
    watch: {
        'cOpt.data.avt.iots.lock': {
            handler: function (newVal, oldVal) {
                this.upd();
            },
            deep: false
        },
        'cOpt.data.cable.number.lock': {
            handler: function (newVal, oldVal) {
                this.upd();
            },
            deep: false
        },
        'cOpt.data.cable.i_dop.lock': {
            handler: function (newVal, oldVal) {
                this.upd();
            },
            deep: false
        },
        'cOpt.data.cable.isCu.lock': {
            handler: function (newVal, oldVal) {
                this.upd();
            },
            deep: false
        },
        'cOpt.data.cable.sechenie.lock': {
            handler: function (newVal, oldVal) {
                this.upd();
            },
            deep: false
        },
        'cOpt.data.cable.u.lock': {
            handler: function (newVal, oldVal) {
                this.upd();
            },
            deep: false
        },
        'cOpt.data.cable.isInAir': {
            handler: function (newVal, oldVal) {
                this.upd();
            },
            deep: false
        },
    }
}
</script>

<style scoped lang="scss">
.btn-link {
    cursor: pointer;
}

.form-check-label {
    cursor: pointer;
}

small {
    display: block;
    line-height: 1.5;

    &.active {
        font-weight: bold;
        color: red;
    }
}

p {
    & b {
        font-weight: bold;
    }
}
</style>
