var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100" }, [
    _c(
      "div",
      { staticClass: "container-fluid m1" },
      [
        _c("p", [_vm._v("HOT")]),
        _vm._v(" "),
        _vm._l(_vm.graph.h, function (el) {
          return _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [_vm._v(_vm._s(el.key))]),
            _vm._v(" "),
            _c("div", { staticClass: "col-1" }, [_vm._v(_vm._s(el.val))]),
          ])
        }),
        _vm._v(" "),
        _c("textarea", {
          staticClass: "form-control mb-3",
          domProps: { textContent: _vm._s(_vm.getArrText(_vm.graph.h)) },
        }),
        _vm._v(" "),
        _c("p", [_vm._v("COLD")]),
        _vm._v(" "),
        _vm._l(_vm.graph.c, function (el) {
          return _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }, [_vm._v(_vm._s(el.key))]),
            _vm._v(" "),
            _c("div", { staticClass: "col-1" }, [_vm._v(_vm._s(el.val))]),
          ])
        }),
        _vm._v(" "),
        _c("textarea", {
          staticClass: "form-control mb-3",
          domProps: { textContent: _vm._s(_vm.getArrText(_vm.graph.c)) },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }