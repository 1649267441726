var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._v("\n                редактирование участников\n            "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.local.listFio,
                  searchable: true,
                  "close-on-select": true,
                  "show-labels": false,
                  "allow-empty": true,
                  placeholder: "фио",
                  "tag-placeholder": "создать новую",
                  taggable: true,
                  id: "mindex",
                  label: "name",
                  "track-by": "id",
                },
                on: { tag: _vm.addNew },
                model: {
                  value: _vm.state.modal.fio,
                  callback: function ($$v) {
                    _vm.$set(_vm.state.modal, "fio", $$v)
                  },
                  expression: "state.modal.fio",
                },
              }),
              _vm._v(" "),
              _vm.state.modal.fio.id
                ? _c("div", [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.state.modal.fio.name,
                          expression: "state.modal.fio.name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { placeholder: "фио" },
                      domProps: { value: _vm.state.modal.fio.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.state.modal.fio,
                            "name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row mt-3 justify-content-around" },
                      [
                        _vm._l([1, 2, 3, 4, 5], function (val) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "col-2 border m-0 p-0",
                                class: {
                                  active:
                                    _vm.state.modal.fio.prop.default === val,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.state.modal.fio.prop.default = val
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  ref: "sign_" + val,
                                  refInFor: true,
                                  staticClass: "w-100",
                                  attrs: {
                                    src:
                                      "/project/fio/sign/" +
                                      _vm.state.modal.fio.id +
                                      "/" +
                                      val +
                                      "?rand=" +
                                      _vm.rand,
                                  },
                                  on: { error: _vm.replaceImgByClear },
                                }),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "vue-dropzone",
                            {
                              ref: "signDZ",
                              attrs: {
                                id: "signDZ1",
                                options: _vm.optionsDZ,
                                useCustomSlot: true,
                              },
                              on: {
                                "vdropzone-success": _vm.successDZ,
                                "vdropzone-sending": _vm.addParamDZ,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "dropzone-custom-content" },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "dropzone-custom-title" },
                                    [_vm._v("Перетащите сюда файл .PNG")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "subtitle" }, [
                                    _vm._v(
                                      "подпись в формате .PNG с прозрачным фоном"
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer justify-content-between" }, [
            _c("div", [
              _vm.state.modal.fio.id
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-default text-danger",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.clickDel },
                    },
                    [_vm._v("удалить")]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.state.modal.fio.id
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("сохранить")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("отмена")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0 mt-3" }, [
      _c("span", { staticClass: "small" }, [_vm._v("ФИО")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 small" }, [
        _vm._v(
          "\n                            Для подписи использются изображения PNG с прозрачным фоном. Поле в штампе имеет размеры 5х15мм, т.е. соотношение сторон 1:3. Оптимальная ширина изображения для\n                            подписи в штампе 60px, соотношение сторон не важно. Можно загружать больших размеров, просто все они будут уменьшаться до 60px при вставке в штамп.\n                            Можно загрузить до 5ти разных подписей, и выбрать ту, которая будет использоваться по умолчанию.\n                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }