var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100" }, [
    _c(
      "div",
      { staticClass: "container-fluid m1" },
      [
        _c("title-for-page", {
          attrs: {
            "c-page": _vm.local.cPage,
            nbi: _vm.nbi,
            "is-setting-page": true,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          _c("div", { staticClass: "col-12 col-xl-8" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.local.cPage.stamp.list_name,
                  expression: "local.cPage.stamp.list_name",
                },
                { name: "focus", rawName: "v-focus" },
              ],
              staticClass: "form-control w-100 mb-3",
              domProps: { value: _vm.local.cPage.stamp.list_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.local.cPage.stamp,
                    "list_name",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-7" },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.fListTypePage.map(
                        (item) => item.link_content
                      ),
                      searchable: false,
                      "close-on-select": true,
                      "show-labels": false,
                      "custom-label": (opt) => opt.name,
                      "allow-empty": false,
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.local.cPage.link_content,
                      callback: function ($$v) {
                        _vm.$set(_vm.local.cPage, "link_content", $$v)
                      },
                      expression: "local.cPage.link_content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isGo
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      on: { click: _vm.clickSave },
                    },
                    [_vm._v("сохранить")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isGo
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: { click: _vm.clickGo },
                    },
                    [
                      _vm._v("перейти "),
                      _c("i", { staticClass: "fas fa-angle-right" }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger ml-2",
                  on: { click: _vm.clickReset },
                },
                [_vm._v("сброс")]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }