<template>

    <div :class="getSize">
        <label v-if="field.type==='bool'" class="apple-switch_2" style="margin-top: 2rem">
            <input type="checkbox" v-model="inputVal" @click="lock[field.id]=false"><i></i>{{ field.name }}
            <font-awesome-icon v-if="lock[field.id]" icon="fa-solid fa-lock"/>
            <font-awesome-icon v-if="field.req" icon="fa-solid fa-circle-exclamation" class="text-success"/>
        </label>

        <label v-else class="w-100" :title="field.desc">
            <span class="small">
                {{ field.name }}
                <font-awesome-icon v-if="field.req" icon="fa-solid fa-circle-exclamation" class="text-success" :class="{'text-danger':isReq}"/>
            </span>
            <span class="w-100">
                <input v-if="isNumber" class="form-control" v-model.number="inputVal" :placeholder="field.name" :class="{lock:lock[field.id]}" @keyup="lock[field.id]=false"
                       @click="lock[field.id]=false"/>
                <input v-else class="form-control" v-model.trim="inputVal" :placeholder="field.name" :class="{lock:lock[field.id]}" @keyup="lock[field.id]=false" @click="lock[field.id]=false"/>
                <font-awesome-icon v-if="lock[field.id]" icon="fa-solid fa-lock" class="inp"/>
            </span>
            <span v-for="e in listTag" class="small tag" @click="clickTag(e)">{{ e }} </span>
        </label>


    </div>

</template>
<script>
export default {
    name: 'b-input-field',
    props: {
        listTag: '',
        value: '',
        label: '',
        title: '',
        lock: '',
        k: '',
        field: '',
    },
    methods: {
        clickInput(e) {
            if (this.field.id === 'url') {

            }
        },
        clickTag(e) {
            this.inputVal = e;
            this.lock[this.field.id] = false;
        },
    },
    computed: {
        getSize: function () {
            return 'col-' + this.field.size;
        },
        isNumber: function () {
            return this.field.type === 'int' || this.field.type === 'float';
        },
        isReq() {
            return !!((this.inputVal === '' || this.inputVal === 0) && this.field.req);
        },
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                if (this.isNumber) this.$emit('input', val * 1);
                else this.$emit('input', val);
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}


svg.fa-lock {
    &.inp {
        position: absolute;
        right: 34px;
        top: 36px;
    }

    opacity: 0.2;
}

input.lock {
    background-color: lighten($primary, 50%);
}

</style>
