var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "item-container",
      attrs: {
        tag: "div",
        list: _vm.list,
        value: _vm.value,
        handle: ".handle",
        animation: "150",
        "fallback-on-body": true,
        "swap-threshold": 0.5,
        "ghost-class": "ghost",
        filter: ".no-drag",
      },
      on: { input: _vm.emitter, change: _vm.onChange },
    },
    [
      _vm._l(_vm.realValue, function (el) {
        return _c("div", { key: el.id, staticClass: "item-group" }, [
          _c(
            "div",
            {
              staticClass: "item",
              staticStyle: { cursor: "pointer" },
              on: {
                contextmenu: function ($event) {
                  $event.preventDefault()
                  return _vm.$refs.menu.open($event, el)
                },
              },
            },
            [
              _c("div", { staticClass: "row mb-0" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    el.type === "norm"
                      ? _c("font-awesome-icon", {
                          staticClass: "handle",
                          attrs: { icon: "fa-solid fa-file" },
                        })
                      : _c(
                          "font-awesome-layers",
                          { staticClass: "handle" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "fa-regular fa-file" },
                            }),
                            _vm._v(" "),
                            _c("font-awesome-icon", {
                              staticStyle: { color: "#ee9b00" },
                              attrs: {
                                icon: "fa-solid fa-link",
                                transform: "shrink-6 down-1 right-1",
                              },
                            }),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c("span", { staticClass: "font-weight-bold ml-1" }, [
                      _vm._v(" " + _vm._s(el.name)),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-8" }, [
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v(" " + _vm._s(el.desc)),
                  ]),
                ]),
              ]),
            ]
          ),
        ])
      }),
      _vm._v(" "),
      _c("ContextMenuMy", { ref: "menu", attrs: { items: _vm.items } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }