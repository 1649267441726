var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-fluid m1" },
      [
        _c("title-for-page", {
          attrs: { "c-page": _vm.local.cPage, nbi: _vm.nbi },
        }),
        _vm._v(" "),
        _vm.isMounted
          ? _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-12 col-xl-8" },
                [
                  !!_vm.local.cPage.id
                    ? _c(
                        "nav",
                        {
                          staticClass: "navbar navbar-expand pl-0 pt-0",
                          staticStyle: { "margin-left": "-0.5rem" },
                        },
                        [
                          _c("div", { staticClass: "container-fluid" }, [
                            _c("div", { staticClass: "navbar-nav" }, [
                              _c("div", { staticClass: "nav-item" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "nav-link btn",
                                    class: { active: _vm.state.swOnOpt },
                                    attrs: { title: "настройки страницы" },
                                    on: {
                                      click: function ($event) {
                                        _vm.state.swOnOpt = !_vm.state.swOnOpt
                                      },
                                    },
                                  },
                                  [_vm._v("настройки")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "nav-item" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "nav-link btn",
                                    class: { active: _vm.state.swOnOpt2 },
                                    attrs: { title: "настройки страницы" },
                                    on: {
                                      click: function ($event) {
                                        _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                      },
                                    },
                                  },
                                  [_vm._v("настройки таблицы")]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.local.cPage.id
                    ? _c("card-options", {
                        staticClass: "mb-3",
                        attrs: { "c-page": _vm.local.cPage, state: _vm.state },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.state.swOnOpt2
                    ? _c("div", { staticClass: "card mb-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-header p-1 pl-2",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                              },
                            },
                          },
                          [_vm._v("настройки таблицы")]
                        ),
                        _vm._v(" "),
                        _vm._m(0),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.local.cPage.id
                    ? _c("h1", { staticClass: "text-center mb-5" }, [
                        _vm._v("Расчёт сопротивления заземляющего устройства"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c(
                          "label",
                          { staticClass: "small w-100 text-left mb-0" },
                          [
                            _vm._v(
                              "\n                            регион\n                            "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ml-3 text-danger small",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.cOpt.data.cityObj = {}
                                  },
                                },
                              },
                              [_vm._v("сброс")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("multiselect", {
                          ref: "ms4",
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.local.listCity,
                            "show-labels": false,
                            searchable: true,
                            "close-on-select": true,
                            "allow-empty": false,
                            "internal-search": false,
                            blockKeys: ["Delete"],
                            placeholder: "регион",
                          },
                          on: {
                            "search-change": _vm.customSearch4,
                            open: function ($event) {
                              return _vm.onOpen4("ms4")
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "singleLabel",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "option__desc" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "option__title" },
                                          [
                                            _vm._v(
                                              _vm._s(props.option.city) +
                                                " (" +
                                                _vm._s(props.option.region) +
                                                ")"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "option",
                                fn: function (props) {
                                  return [
                                    _c("div", { staticClass: "option__desc" }, [
                                      _c(
                                        "span",
                                        { staticClass: "option__title" },
                                        [
                                          _vm._v(
                                            _vm._s(props.option.city) +
                                              " (" +
                                              _vm._s(props.option.region) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2711289441
                          ),
                          model: {
                            value: _vm.cOpt.data.cityObj,
                            callback: function ($$v) {
                              _vm.$set(_vm.cOpt.data, "cityObj", $$v)
                            },
                            expression: "cOpt.data.cityObj",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "form-check d-inline-block ml-3 my-2",
                          },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                staticClass: "form-check-input",
                                attrs: { type: "radio" },
                                domProps: {
                                  checked: _vm.cOpt.data.region.id === 1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickSetRegion(1)
                                  },
                                },
                              }),
                              _vm._v(" I\n                            "),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-check d-inline-block ml-3" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                staticClass: "form-check-input",
                                attrs: { type: "radio" },
                                domProps: {
                                  checked: _vm.cOpt.data.region.id === 2,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickSetRegion(2)
                                  },
                                },
                              }),
                              _vm._v(" II\n                            "),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-check d-inline-block ml-3" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                staticClass: "form-check-input",
                                attrs: { type: "radio" },
                                domProps: {
                                  checked: _vm.cOpt.data.region.id === 3,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickSetRegion(3)
                                  },
                                },
                              }),
                              _vm._v(" III\n                            "),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-check d-inline-block ml-3" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                staticClass: "form-check-input",
                                attrs: { type: "radio" },
                                domProps: {
                                  checked: _vm.cOpt.data.region.id === 4,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickSetRegion(4)
                                  },
                                },
                              }),
                              _vm._v(" IV\n                            "),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "my-0 mt-1" }, [
                          _vm._v(
                            "Средняя многолетняя низшая температура (январь) "
                          ),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.cOpt.data.region.t1)),
                          ]),
                          _vm._v(" °C"),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "my-0" }, [
                          _vm._v(
                            "Средняя многолетняя высшая температура (июль) "
                          ),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.cOpt.data.region.t2)),
                          ]),
                          _vm._v(" °C"),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "my-0" }, [
                          _vm._v("Продолжительность замерзания вод "),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.cOpt.data.region.d)),
                          ]),
                          _vm._v(" дней"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("span", { staticClass: "small" }, [
                          _vm._v("сопротивление слоя грунта"),
                        ]),
                        _vm._v(" "),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.listGrunt,
                            searchable: false,
                            "close-on-select": true,
                            "show-labels": false,
                            "allow-empty": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "singleLabel",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "option__desc" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "option__title" },
                                          [
                                            _vm._v(
                                              _vm._s(props.option.name) +
                                                " (" +
                                                _vm._s(props.option.min) +
                                                "..." +
                                                _vm._s(props.option.max) +
                                                " Ом*м)"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "option",
                                fn: function (props) {
                                  return [
                                    _c("div", { staticClass: "option__desc" }, [
                                      _c(
                                        "span",
                                        { staticClass: "option__title" },
                                        [_vm._v(_vm._s(props.option.name))]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "option__small small" },
                                        [
                                          _vm._v(
                                            _vm._s(props.option.min) +
                                              "..." +
                                              _vm._s(props.option.max) +
                                              " Ом*м"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2719603579
                          ),
                          model: {
                            value: _vm.currentGrunt,
                            callback: function ($$v) {
                              _vm.currentGrunt = $$v
                            },
                            expression: "currentGrunt",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-1 d-flex align-items-end" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          staticStyle: { float: "right" },
                          attrs: { type: "button" },
                          on: { click: _vm.clickAddGrunt },
                        },
                        [_vm._v("добавить")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row mb-5" },
                    [
                      _vm._l(_vm.cOpt.data.grunt, function (el, index) {
                        return [
                          _c("div", { staticClass: "col-5 text-left" }, [
                            _c("span", { staticClass: "small" }, [
                              _vm._v("слой грунта "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "form-control btn btn-sm d-inline text-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickRemoveGrunt(index)
                                    },
                                  },
                                },
                                [_vm._v("удалить")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: el.name,
                                  expression: "el.name",
                                },
                              ],
                              staticClass: "form-control",
                              domProps: { value: el.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(el, "name", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-7" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-4",
                                  attrs: {
                                    title: "Глубина залегания слоя от, мм",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v("глубина от, м"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: el.h1,
                                        expression: "el.h1",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    domProps: { value: el.h1 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          el,
                                          "h1",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-4",
                                  attrs: {
                                    title: "Глубина залегания слоя до, мм",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v("глубина до, м"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: el.h2,
                                        expression: "el.h2",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    domProps: { value: el.h2 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          el,
                                          "h2",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-4" }, [
                                _c("span", { staticClass: "small" }, [
                                  _vm._v("сопротивление грунта, Ом*м"),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: el.r,
                                      expression: "el.r",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  domProps: { value: el.r },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        el,
                                        "r",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn form-control btn-secondary",
                          attrs: { disabled: _vm.isDisabled(1) },
                          on: {
                            click: function ($event) {
                              return _vm.clickAddElement(1)
                            },
                          },
                        },
                        [_vm._v("вертикальный электрод")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn form-control btn-secondary",
                          attrs: { disabled: _vm.isDisabled(2) },
                          on: {
                            click: function ($event) {
                              return _vm.clickAddElement(2)
                            },
                          },
                        },
                        [_vm._v("горизонтальный электрод")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cOpt.data.elements, function (el, index) {
                    return _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-5" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.listElements.find((item) => item.id === el.id)
                                .name
                            ) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "form-control btn btn-sm d-inline text-danger",
                            on: {
                              click: function ($event) {
                                return _vm.clickRemoveElement(index)
                              },
                            },
                          },
                          [_vm._v("удалить")]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("h5", [
                          _c(
                            "span",
                            {
                              staticClass: "font-weight-bold",
                              attrs: {
                                title: "сопротивление одиночного электрода",
                              },
                            },
                            [_vm._v(_vm._s(el.r1))]
                          ),
                          _vm._v(" /\n                            "),
                          _c(
                            "span",
                            {
                              staticClass: "font-weight-bold",
                              attrs: {
                                title: "сопротивление с учётом экранирования",
                              },
                            },
                            [_vm._v(_vm._s(el.r2))]
                          ),
                          _vm._v(" Ом"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-7" }, [
                        _c("div", { staticClass: "row" }, [
                          el.id !== 2
                            ? _c("div", { staticClass: "col-2" }, [
                                _c("span", { staticClass: "small" }, [
                                  _vm._v("количество"),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: el.amount,
                                      expression: "el.amount",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  domProps: { value: el.amount },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        el,
                                        "amount",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-3" }, [
                            _c("span", { staticClass: "small" }, [
                              _vm._v("длина электрода, м"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: el.l,
                                  expression: "el.l",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              domProps: { value: el.l },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(el, "l", _vm._n($event.target.value))
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-3" }, [
                            el.isPipe
                              ? _c("span", { staticClass: "small" }, [
                                  _vm._v("диаметр электрода, м"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !el.isPipe && el.id === 1
                              ? _c("span", { staticClass: "small" }, [
                                  _vm._v("ширина уголка, м"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !el.isPipe && el.id === 2
                              ? _c("span", { staticClass: "small" }, [
                                  _vm._v("ширина полосы, м"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: el.d,
                                  expression: "el.d",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              domProps: { value: el.d },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(el, "d", _vm._n($event.target.value))
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            el.id === 1
                              ? _c("span", { staticClass: "small" }, [
                                  _vm._v("глубина верхнего конца, м"),
                                ])
                              : _c("span", { staticClass: "small" }, [
                                  _vm._v("глубина укладки, м"),
                                ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: el.h,
                                  expression: "el.h",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              domProps: { value: el.h },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(el, "h", _vm._n($event.target.value))
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-4 d-flex align-items-end pb-2",
                              attrs: { title: "форма электрода" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-check d-inline-block" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      staticStyle: { cursor: "pointer" },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: el.isPipe,
                                            expression: "el.isPipe",
                                          },
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(el.isPipe, false),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(el, "isPipe", false)
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      el.id === 1
                                        ? _c("span", [_vm._v("уголок")])
                                        : _c("span", [_vm._v("полоса")]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "form-check d-inline-block ml-3",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      staticStyle: { cursor: "pointer" },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: el.isPipe,
                                            expression: "el.isPipe",
                                          },
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(el.isPipe, true),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(el, "isPipe", true)
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        " труба/арматура\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-4 d-flex align-items-end pb-2",
                              attrs: {
                                title:
                                  "электроды расположены в ряд или расположены по замкнутому контуру?",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-check d-inline-block" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      staticStyle: { cursor: "pointer" },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: el.isClosed,
                                            expression: "el.isClosed",
                                          },
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(el.isClosed, false),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              el,
                                              "isClosed",
                                              false
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        " в ряд\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "form-check d-inline-block ml-3",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      staticStyle: { cursor: "pointer" },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: el.isClosed,
                                            expression: "el.isClosed",
                                          },
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(el.isClosed, true),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              el,
                                              "isClosed",
                                              true
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        " по контуру\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          el.id === 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "col-4",
                                  attrs: {
                                    title:
                                      "среднее расстояние между электродами",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "small" }, [
                                    _vm._v("интервал, м"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: el.interval,
                                        expression: "el.interval",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    domProps: { value: el.interval },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          el,
                                          "interval",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h5", [
                        _vm._v("Итого " + _vm._s(_vm.cOpt.data.r) + " Ом"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                ],
                2
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 bg-primary-l font-weight-bold p-2" }, [
        _vm._v("Климатический регион"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [_c("br")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 bg-primary-l font-weight-bold p-2" }, [
        _vm._v("Грунт"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 bg-primary-l font-weight-bold p-2" }, [
        _vm._v("Элементы заземления"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }