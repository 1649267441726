<template>
    <div>
        <div class="card-body">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" class="w-1">markerTeg</th>
                    <th scope="col" class="w-1">экспл</th>
                    <th scope="col" class="w-7">название</th>
                    <th scope="col" class="w-1">отметка</th>
                    <th scope="col" class="w-1">кабель</th>
                    <th scope="col" class="w0"></th>
                </tr>
                </thead>
                <template v-for="val in fTable">
                    <tr :key="val.id" class="mark">
                        <td></td>
                        <td></td>
                        <td>
                            <span>{{val.name}}</span>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td></td>
                    </tr>
                    <draggable v-model="val.child" tag="tbody" ghost-class="ghost" :disabled="pState.isEdit">
                        <tr v-for="el in fTableChild(val.child)" :key="el.id"
                            @click.ctrl.prevent="eTable.clickCtrlRow(el)"
                            @click.exact="eTable.clickRow(el)"
                            :class="{'bg-primary-l':eTable.isSelect(el),'bg-danger-l':el.area.id===''}"
                        >
                            <td scope="row">
                                <span v-if="!pState.isEdit">{{el.marker_tag}}</span>
                                <input v-else class="form-control1" v-model="el.marker_tag">
                            </td>
                            <td scope="row">{{el.explication_tag}}</td>
                            <td class="text-left">
                                <span v-if="!pState.isEdit">{{el.name}}
                                    <span v-if="el.area.id===''" class="font-weight-bold text-danger"> Область не найдена</span>
                                    <font-awesome-icon v-if="isExpl(el)" icon="fa-solid fa-bookmark" title="марекер экспликации"/>
                                </span>
                                <input v-else class="form-control1" v-model="el.name">
                            </td>
                            <td>
                                <span v-if="!pState.isEdit">{{el.otm}}</span>
                                <input v-else class="form-control1" v-model="el.otm">
                            </td>
                            <td>
                                <span v-if="!pState.isEdit">{{el.kabl}}</span>
                                <input v-else class="form-control1" v-model="el.kabl">
                            </td>
                            <td @click="eTable.copyClipBoard(el)" title="копирование координат в буфер обмена">
                                <font-awesome-icon icon="fa-solid fa-location-crosshairs"/>
                            </td>
                        </tr>
                    </draggable>
                </template>
            </table>

        </div>
    </div>
</template>
<script>

    import uniqid from 'uniqid';
    import ModalPotr from "./sub/ModalPotr";
    import {eTableMulti} from "./sub/tools";

    export default {
        name: 'e-room1',
        components: {ModalPotr},
        props: {
            pLocal: {},
            pState: {}
        },
        data: function () {
            return {
                eTable: new eTableMulti(this),
                local: {
                    table: [],
                    selectArr: [],
                },
                state: {
                    search: this.pState.search,
                },
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'insert') this.showModalInsert();
                if (e.key === 113) this.showModal();
            });
            Bus.$on('localSave', () => this.localSave());
            Bus.$on('dotSelect', () => this.eTable.onDotSelect());
            Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
            Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        },
        mounted() {
            this.init();
            this.local.selectArr = [];
            if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
            setTimeout(() => this.$refs.search.focus(), 200);
        },
        methods: {
            localSave() {
                let eMarker = [];
                this.local.table.forEach(item => {
                    item.child.forEach(item2 => {
                        eMarker.push(item2);
                    })
                })
                this.pLocal.eMarker = eMarker;
            },
            showModal() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('show_modalPotr0');
            },
            showModalInsert() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('showI_modalPotr0');
            },
            isExpl(obj) {
                return !!~this.expl.indexOf(obj.id);
            },
            init() {
                let res = [];

                let explM = this.pLocal.eRoom.map(item => item.marker.explication_tag).filter((value, index, self) => self.indexOf(value) === index);


                let o = {
                    id: uniqid(),
                    name: 'без экспликации помещений',
                    otm: '',
                    kabl: '',
                    explication_tag: '',
                    marker_tag: '',
                    child: this.pLocal.eMarker.filter(item2 => item2.explication_tag === '' || !~explM.indexOf(item2.explication_tag)),
                };
                res.push(o);

                this.pLocal.eMarker.filter(item => !!~this.expl.indexOf(item.id)).forEach(item => {
                    let o = {
                        id: uniqid(),
                        name: item.name,
                        otm: item.otm,
                        kabl: item.kabl,
                        explication_tag: item.explication_tag,
                        marker_tag: item.marker_tag,
                        child: this.pLocal.eMarker.filter(item2 => item2.explication_tag === item.explication_tag),
                    };
                    res.push(o);
                })

                this.local.table = res;
            },
            fTableChild(child) {
                if (this.state.search.val === '') return child;
                return child.filter(item => {
                    let res = true;
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name + item.number;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
        computed: {
            fTable: function () {
                // return this.local.table;
                // if (this.state.search.val === '') return this.local.table;
                return this.local.table.filter(item => {
                    return this.fTableChild(item.child).length > 0;
                })
            },
            expl: function () {
                return this.pLocal.eRoom.map(item => item.marker.id).filter((value, index, self) => self.indexOf(value) === index);
            },

        },
        watch: {
            'fTable': {
                handler: function (newVal, oldVal) {
                    this.eTable.clearSelectArr();
                },
                deep: false
            },
        }
    }
</script>


<style scoped lang="scss">
    @import 'resources/sass/variables';

    .nav-link {
        cursor: pointer;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .form-control1 {
        border: 0;
        width: 100%;
    }
</style>
