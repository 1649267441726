import { render, staticRenderFns } from "./BInputField.vue?vue&type=template&id=517a2230&scoped=true&"
import script from "./BInputField.vue?vue&type=script&lang=js&"
export * from "./BInputField.vue?vue&type=script&lang=js&"
import style0 from "./BInputField.vue?vue&type=style&index=0&id=517a2230&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517a2230",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/aleksej/app/dev.speka/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('517a2230')) {
      api.createRecord('517a2230', component.options)
    } else {
      api.reload('517a2230', component.options)
    }
    module.hot.accept("./BInputField.vue?vue&type=template&id=517a2230&scoped=true&", function () {
      api.rerender('517a2230', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/sub/BInputField.vue"
export default component.exports