var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c(
      "span",
      {
        staticClass: "mr-2",
        staticStyle: { cursor: "pointer" },
        attrs: { title: "Видимость" },
        on: {
          click: function ($event) {
            _vm.inputVal.isVisible = !_vm.inputVal.isVisible
          },
        },
      },
      [
        _vm.inputVal.isVisible
          ? _c("font-awesome-icon", { attrs: { icon: "fa-regular fa-eye" } })
          : _c("font-awesome-icon", {
              staticStyle: { opacity: "0.5" },
              attrs: { icon: "fa-regular fa-eye-slash" },
            }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isRazdel
      ? _c(
          "span",
          {
            staticClass: "mr-2",
            staticStyle: { cursor: "pointer" },
            attrs: { title: "Блок является разделом" },
            on: {
              click: function ($event) {
                _vm.inputVal.isRazdel = !_vm.inputVal.isRazdel
              },
            },
          },
          [
            _vm.inputVal.isRazdel
              ? _c("span", [_vm._v("Раздел > ")])
              : _c(
                  "span",
                  {
                    staticStyle: {
                      "text-decoration": "line-through",
                      opacity: "0.5",
                    },
                  },
                  [_vm._v("Раздел > ")]
                ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }