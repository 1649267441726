var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-fluid m1" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-xl-8" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-3" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.profile.name,
                    expression: "profile.name",
                  },
                ],
                staticClass: "form-control",
                attrs: { disabled: "" },
                domProps: { value: _vm.profile.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.profile, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.profile.email,
                    expression: "profile.email",
                  },
                ],
                staticClass: "form-control",
                attrs: { disabled: "" },
                domProps: { value: _vm.profile.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.profile, "email", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-3" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.pass,
                    expression: "pass",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "password", placeholder: "6-20 символов" },
                domProps: { value: _vm.pass },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.pass = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { staticClass: "mb-0" }, [
                _vm.errors.pass[0]
                  ? _c("span", { staticClass: "small text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.pass[0])),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { disabled: !_vm.checkPassLen() },
                    on: { click: _vm.clickSavePass },
                  },
                  [_vm._v("сохранить пароль")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary w-100",
                  on: { click: _vm.clickResetPass },
                },
                [_vm._v("сброс пароля")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-3" }, [
              _vm._m(4),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.profile.app_token,
                    expression: "profile.app_token",
                  },
                ],
                staticClass: "form-control mb-3",
                attrs: { disabled: "" },
                domProps: { value: _vm.profile.app_token },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.profile, "app_token", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.clickGenToken },
                  },
                  [_vm._v("генерировать")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.clickSaveToken },
                  },
                  [_vm._v("сохранить")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: { id: "myModal", role: "dialog" },
            },
            [
              _c("div", { staticClass: "modal-dialog" }, [
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _vm._v(
                      "\n                                myspeka.ru\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("p", [
                      _vm._v("Подключение существующего аккаунта myspeka.ru"),
                    ]),
                    _vm._v(" "),
                    _vm._m(5),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.old.username,
                          expression: "old.username",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.old.username },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.old,
                            "username",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._m(6),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.old.pass,
                          expression: "old.pass",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "password" },
                      domProps: { value: _vm.old.pass },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.old, "pass", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: { click: _vm.clickOldConnect },
                      },
                      [_vm._v("привязать")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button", "data-dismiss": "modal" },
                      },
                      [_vm._v("отмена")]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("h1", { staticClass: "mb-0" }, [_vm._v("Профиль")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("name")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0 mt-3" }, [
      _c("span", { staticClass: "small" }, [_vm._v("email")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("новый пароль")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("токен для приложения")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("username")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("пароль")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }