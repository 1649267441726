<template>
    <div class="h-100">
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="text-center">
                        <h1 style="font-size: 3rem;" class="font-weight-bold"><span style="color: green;">S</span>peka</h1>
                        <p><a href="/calc/kz">расчёт токов короткого замыкания</a></p>
                        <p><a href="/calc/term_cab">проверка кабеля на термическую стойкость</a></p>
                        <p><a href="/calc/zaz">Расчёт сопротивления заземляющего устройства</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: [],
        data: function () {
            return {
                nbi: {},
            }
        },
        created: function () {
            Bus.$on('navbar_info', obj => this.nbi = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'get_navbar_info'});
        },
        methods: {},
        directives: {}
    }
</script>

<style scoped>

</style>
