<template>
    <div>
        <div class="card-body" @click="context.close()">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <div class="row">
                <div class="col-12 text-right">
                    <div class="btn-group btn-group-sm">
                        <button v-for="el in state.listGroup" type="button" class="btn btn-primary" :class="{'active':el.isSelect}" @click="clickListGroup(el)">{{ el.name }}</button>
                    </div>
                </div>
            </div>
            <table class="table table-sm">
                <thead>
                <tr>
                    <th v-if="isSetGroup('room')" scope="col" class="w-2">пом.</th>
                    <th v-if="!isSetGroup('vid')" scope="col" class="w-2">тип</th>
                    <th scope="col" class="w-2">видимость</th>
                    <th v-if="!isSetGroup('vid')" scope="col" class="w-5">name1</th>
                    <th v-if="!isSetGroup('vid')" scope="col" class="w-5">name2</th>
                    <th scope="col" class="w-5">марка</th>
                    <th scope="col" class="w-5">марка новая</th>
                    <th scope="col" class="w-1">количество</th>
                </tr>
                </thead>
                <template v-for="(val,index) in fTable">
                    <tr :key="index" @click.ctrl.prevent="eTable.clickCtrlRow(val)"
                        :class="{'bg-primary-l':eTable.isSelect(val), 'bg-success-l':eTable.isActiveSave(val)}"
                        @click.exact="eTable.clickRow(val)"
                        @contextmenu.prevent="context.show($event,val,'menu')"
                    >
                        <td v-if="isSetGroup('room')">{{ val.room.name }}</td>
                        <td v-if="!isSetGroup('vid')">{{ val.type }}</td>
                        <td>{{ val.vid }}</td>

                        <td v-if="!isSetGroup('vid')" class="text-left">{{ val.name1 }}</td>
                        <td v-if="!isSetGroup('vid')" class="">
                            <span class="d-flex justify-content-between">
                                <span>{{ val.name2 }}</span>
                            </span>
                        </td>
                        <td class="text-left">{{ val.marka_new.name }}</td>
                        <td class="text-left" :title="val.marka_new.name">
                            <span>
                                <font-awesome-icon style="color: grey" v-if="eTable.getTwoChars(val.marka_new.id)==='bs'" icon="fa-regular fa-star"/>
                                <font-awesome-icon style="color: goldenrod" v-if="eTable.getTwoChars(val.marka_new.id)==='ps'" icon="fa-solid fa-award"/>
                                <font-awesome-icon style="color: royalblue" v-if="eTable.getTwoChars(val.marka_new.id)==='pr'" icon="fa-regular fa-circle-user"/>
                                {{ val.marka_new.marka }}
                            </span>
                        </td>
                        <td>{{ val.child.length }}</td>
                    </tr>
                </template>
            </table>
        </div>
        <context-menu-my ref="menu" :items="items"/>
    </div>
</template>
<script>

import {contextMenuTools, eTable, page} from "./sub/tools";
import ContextMenuMy from "./sub/ContextMenuMy.vue";


export default {
    name: 'marka_e-potr',
    components: {ContextMenuMy},
    props: {
        pLocal: {},
        pState: {}
    },
    data: function () {
        return {
            eTable: new eTable(this),
            pageT: new page(this),
            context: new contextMenuTools(this),
            local: {
                table: [],
                selectArr: [],
                AW: this.pLocal.AW,
                saveArr: this.pLocal.saveArr,
            },
            state: {
                version: 3,
                search: this.pState.search,
                listGroup: [
                    {id: 'none', name: 'без группировки', isSelect: false},
                    {id: 'vid', name: 'по виду', isSelect: false},
                    {id: 'name', name: 'по имени', isSelect: true},
                    {id: 'room', name: 'по комнатам', isSelect: false},
                ],
            },
            status: {
                loading: false,
            },
            items: [
                {name: 'удалить марку', link: 'delete_marka'},
                {name: '---'},
                {name: 'переключить видимость щита', link: 'hideRu'},
            ],
            currentAW: 4,
            classEl: 'ePotr',

        }
    },
    //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    created: function () {
        Bus.$on('keyUp', e => {
            if (this.isAW) {
                if (e.key === 'f2') this.showModal();
                if (e.key === 'down') this.eTable.keyDownArrow();
                if (e.key === 'up') this.eTable.keyUpArrow();
                if (e.key === 'esc') Bus.$emit('clickBody');
                if (e.key === 'ctrlS') this.clickSave();
            }
        });
        Bus.$on('updTable', () => this.init());
        Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
        Bus.$on('dotSelect', () => this.eTable.onDotSelect());
        Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
        Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        Bus.$on('onShowContextMenu', (obj) => {
            if (this.$refs[obj.refs] === undefined) return;
            this.$refs[obj.refs].open(obj.e, obj.el)
        });
        Bus.$on('contextMenu_menu', (o) => {
            if (o.link === 'delete_marka') this.onDeleteMarka(o.obj);
        });
        Bus.$on('clickTab', (i) => {
            if (i !== this.currentAW) return;
            if (this.local.table.length === 0) this.onLoadTable();
        });
    },
    mounted() {
        this.init();
        this.pageT.loadLS();
        this.local.selectArr = [];
        if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
        setTimeout(() => this.$refs.search.focus(), 200);
        // setTimeout(() => this.showModal(), 200);
    },
    //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    methods: {
        isSetGroup(group) {
            let o = this.state.listGroup.find(item => item.id === group);
            if (!o) return false;
            return o.isSelect;
        },
        clickListGroup(el) {
            this.state.listGroup.forEach(item => {
                item.isSelect = item.id === el.id;
            })
            this.pageT.updLS();
        },
        onDeleteMarka(obj) {
            if (!!!obj) return;
            obj.marka_new.id = null;
            obj.marka_new.marka = '';
            obj.marka_new.name = null;
            this.local.saveArr.push(obj);
        },
        onLoadTable() {
            this.pageT.clickSave('/marka_element/load_table_el', {classEl: this.classEl}, '_load_table', false);
            Bus.$once('zResponse_load_table', response => {
                this.local.saveArr.length = 0;
                this.local.table = response.table;
                this.init();
            });
        },
        clickSave() {
            this.pageT.clickSave('/marka_element/save_2', {save_arr: this.local.saveArr, classEl: this.classEl}, '_save_2');
            Bus.$once('zResponse_save_2', response => {
                this.local.saveArr.length = 0;
                this.local.table = response.table;
                this.init();
            });
        },
        showModal() {
            if (this.local.selectArr.length === 0) return;
            Bus.$emit('show_modalSo1', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
        },
        init() {
        },
        groupNone() {
            let res = this.eTable.groupU(this.local.table, ['id', 'name1', 'name2', 'name3', 'type', 'vid', 'marka.id', 'marka_new.id']);
            res.sort((a, b) => a.type.localeCompare(b.type) || a.vid.localeCompare(b.vid));
            return res;
        },
        groupName() {
            let res = this.eTable.groupU(this.local.table, ['name1', 'name2', 'name3', 'type', 'vid', 'marka.id', 'marka_new.id']);
            res.sort((a, b) => a.type.localeCompare(b.type) || a.vid.localeCompare(b.vid));
            return res;
        },
        groupRoom() {
            let res = this.eTable.groupU(this.local.table, ['name1', 'name2', 'name3', 'type', 'vid', 'marka.id', 'marka_new.id', 'room.id', 'marker.id']);
            res.sort((a, b) => (String(a.marker.explication_tag)+String(a.room.name)).localeCompare(String(b.marker.explication_tag)+String(b.room.name)) || a.vid.localeCompare(b.vid));
            return res;
        },
        groupVid() {
            let res = this.eTable.groupU(this.local.table, ['vid', 'marka.id', 'marka_new.id']);
            res.sort((a, b) => a.vid.localeCompare(b.vid));
            return res;
        },
    },
    computed: {
        isAW: function () {
            return this.local.AW !== undefined && this.local.AW[0] === 'root' + this.currentAW;
        },
        fTable: function () {
            if (this.state.search.val === '') return this.ffTable;
            return this.ffTable.filter(item => {
                let res = true;
                this.state.search.val.split(' ').forEach(value => {
                    let s = item.name2 + item.name1 + item.type + item.vid;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        ffTable: function () {
            let obj = this.state.listGroup.find(item => item.isSelect);
            if (!obj) return [];
            if (obj.id === 'none') return this.groupNone()
            else if (obj.id === 'name') return this.groupName()
            else if (obj.id === 'room') return this.groupRoom()
            else if (obj.id === 'vid') return this.groupVid()

            return [];
        },
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
    },
    watch: {
        'fTable': {
            handler: function (newVal, oldVal) {
                this.eTable.clearSelectArr();
            },
            deep: false
        },
    }
}
</script>
