var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-fluid m1" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm.isMounted
          ? _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-12 col-xl-8" }, [
                _c(
                  "nav",
                  {
                    staticClass: "navbar navbar-expand pl-0 pt-0",
                    staticStyle: { "margin-left": "-0.5rem" },
                  },
                  [
                    _c("div", { staticClass: "container-fluid" }, [
                      _c("div", { staticClass: "navbar-nav" }, [
                        _c("div", { staticClass: "nav-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "nav-link btn",
                              class: { active: _vm.state.swImportW },
                              on: {
                                click: function ($event) {
                                  _vm.state.swImportW = !_vm.state.swImportW
                                },
                              },
                            },
                            [_vm._v("переменые")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _c("variables-div", {
              class: {
                "col-12": !_vm.state.swImportW,
                "col-xl-8": !_vm.state.swImportW,
                "col-6": _vm.state.swImportW,
              },
              attrs: { local: _vm.local, "is-global": true },
            }),
            _vm._v(" "),
            _c("import-fields", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state.swImportW,
                  expression: "state.swImportW",
                },
              ],
              staticClass: "col-6",
              attrs: {
                "modal-id": "modalImportFields",
                "is-show": _vm.state.swImportW,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("modal-es-ru", { attrs: { "modal-id": "modalEsRu" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-12 col-xl-8" }, [
        _c("h1", { staticClass: "mb-0" }, [_vm._v("Глобальные переменные")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }