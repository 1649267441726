var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card mb-3" }, [
    _c(
      "a",
      {
        staticClass: "card-link",
        attrs: { "data-toggle": "collapse", href: "#" + _vm.name },
      },
      [
        _c("div", { staticClass: "card-header p-1 pl-3 text-dark" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "collapse",
        class: { show: _vm.expand },
        attrs: { id: _vm.name },
      },
      [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("c-tiptap", {
              attrs: { "is-edit": _vm.isEdit },
              model: {
                value: _vm.local_value,
                callback: function ($$v) {
                  _vm.local_value = $$v
                },
                expression: "local_value",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }