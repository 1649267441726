<template>
    <div>
        <div class="card-body">
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" class="w-5">name1</th>
                    <th scope="col" class="w-5">name2</th>
                    <th scope="col" class="w-1">Ру</th>
                    <th scope="col" class="w-1">Кс</th>
                    <th scope="col" class="w-1">Pp</th>
                    <th scope="col" class="w-1">cos</th>
                    <th scope="col" class="w-1">Qp</th>
                    <th scope="col" class="w-1">Sp</th>
                    <th scope="col" class="w-1">Ip</th>
                    <th scope="col" class="w-1">faz</th>
                    <th scope="col" class="w-0"></th>
                </tr>
                </thead>
                <draggable v-model="local.table" tag="tbody" ghost-class="ghost">
                    <template v-for="val in fTable">
                        <tr :key="val.id" @click.ctrl.prevent="eTable.clickCtrlRow(val)"
                            :class="{'bg-primary-l':eTable.isSelect(val),'bg-danger-l':val.room.id===''}"
                            @click.exact="eTable.clickRow(val)"
                            class="font-weight-bold"
                        >
                            <td class="text-left"><span>{{val.name1}}</span><br><span class="small">{{val.dop.method.name}}, {{val.dop.app.name}}</span></td>
                            <td class="text-left">{{val.name2}}</td>
                            <td>{{val.es.pu}}</td>
                            <td>{{val.es.ks}}</td>
                            <td>{{val.es.pr}}</td>
                            <td>{{val.es.cos}}</td>
                            <td>{{val.es.qr}}</td>
                            <td>{{val.es.sr}}</td>
                            <td>{{val.es.ir}}</td>
                            <td>{{val.es.faz}}</td>
                            <td @click="eTable.copyClipBoard(val)" title="копирование координат в буфер обмена">
                                <font-awesome-icon icon="fa-solid fa-location-crosshairs"/>
                            </td>
                        </tr>

                    </template>

                </draggable>
            </table>
        </div>
        <modal-panel modal-id="modalPanel2" :p-local="local" @updInit="init"/>
    </div>
</template>
<script>

    import ModalPotr from "./sub/ModalPotr";
    import ModalPanel from "./sub/ModalPanel";
    import {eTable} from "./sub/tools";


    export default {
        name: 'e-panel2',
        components: {ModalPanel},
        props: {
            pLocal: {},
            pState: {}
        },
        data: function () {
            return {
                eTable: new eTable(this),
                local: {
                    table: [],
                    selectArr: [],
                    listMethod: this.pLocal.listMethod,
                    listPanelName2: this.pLocal.listPanelName2,
                    listApp: this.pLocal.listApp,
                },
                state: {
                    search: this.pState.search,
                },
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'insert') this.showModalInsert();
                if (e.key === 113) this.showModal();
            });
            Bus.$on('localSave', () => this.localSave());
            Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
            Bus.$on('dotSelect', () => this.eTable.onDotSelect());
            Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
            Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        },
        mounted() {
            this.init();
            this.local.selectArr = [];
            if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
            setTimeout(() => this.$refs.search.focus(), 200);
            // setTimeout(() => this.showModal(), 200);
        },
        methods: {
            localSave() {
                let eFull = [];
                this.local.table.forEach(item => {
                    eFull.push(item);
                })
                this.pLocal.eFull = eFull;
            },
            showModal() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('show_modalPanel2');
            },
            showModalInsert() {
                if (this.local.selectArr.length === 0) return;
                Bus.$emit('showI_modalPanel2');
            },
            init() {
                let res = [];
                res = this.pLocal.eFull;

                this.local.table = res;
            },
        },
        computed: {
            fTable: function () {
                if (this.state.search.val === '') return this.local.table;
                return this.local.table.filter(item => {
                    let res = true;
                    this.state.search.val.split(' ').forEach(value => {
                        let s = item.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                })
            },
        },
        watch: {
            'fTable': {
                handler: function (newVal, oldVal) {
                    this.eTable.clearSelectArr();
                },
                deep: false
            },
        }
    }
</script>
