var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMount
    ? _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: { id: _vm.modalId, role: "dialog", "data-keyboard": "false" },
          on: { click: _vm.closeModal },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-xl",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-header",
                    staticStyle: { cursor: "move" },
                  },
                  [
                    _vm.state.isEdit
                      ? _c("span", [_vm._v("редактировать оборудование")])
                      : _c("span", [_vm._v("вставить оборудование")]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "text-danger mt-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                      on: { click: _vm.closeModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { "max-height": "calc(100vh - 236px)" },
                    },
                    [
                      _c("div", { staticClass: "row mb-0" }, [
                        _c(
                          "span",
                          { staticClass: "ml-3 small" },
                          [
                            _vm.isMount
                              ? _c("c-category-string", {
                                  attrs: {
                                    "list-category": _vm.listCategory,
                                    ttype: "2",
                                    category: _vm.state.currentCategory,
                                    "is-ps-global": _vm.state.isPS,
                                  },
                                  on: { setCategory: _vm.onSetCategory },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.state.search.val,
                                expression: "state.search.val",
                              },
                            ],
                            ref: "v_focus",
                            staticClass: "form-control w-100",
                            domProps: { value: _vm.state.search.val },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.state.search,
                                  "val",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row p-0 m-0 mb-3" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 d-flex justify-content-between",
                          },
                          [
                            _c(
                              "div",
                              _vm._l(_vm.state.listBase, function (el) {
                                return _c(
                                  "span",
                                  {
                                    staticClass: "mr-2 small",
                                    class: { "font-weight-bold": el.isSelect },
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickListBase(el)
                                      },
                                    },
                                  },
                                  [
                                    el.id === "bs"
                                      ? _c("font-awesome-icon", {
                                          staticStyle: { color: "grey" },
                                          attrs: { icon: "fa-regular fa-star" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    el.id === "ps"
                                      ? _c("font-awesome-icon", {
                                          staticStyle: { color: "goldenrod" },
                                          attrs: { icon: "fa-solid fa-award" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    el.id === "pr"
                                      ? _c("font-awesome-icon", {
                                          staticStyle: { color: "royalblue" },
                                          attrs: {
                                            icon: "fa-regular fa-circle-user",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                                                                " +
                                        _vm._s(el.name) +
                                        "\n                                "
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "small",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.brand = ""
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.state.brand))]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "overflow-x": "hidden" } },
                        _vm._l(_vm.fTable, function (el, index) {
                          return _c(
                            "div",
                            {
                              key: el.uid,
                              staticClass: "row mx-0",
                              class: {
                                "bg-primary-l":
                                  index === _vm.state.activeRowIndex,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.state.activeRowIndex = index
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-1",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showModalSoInfo(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "w-100",
                                    attrs: { src: _vm.getImg(el), alt: "" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-2" }, [
                                _vm._v(_vm._s(el.marka)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("div", { staticClass: "small d-flex" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickCategoryItem(el)
                                        },
                                      },
                                    },
                                    [
                                      _vm.isMount
                                        ? _c("c-category-string", {
                                            attrs: {
                                              "list-category": _vm.listCategory,
                                              "c-obj": el,
                                              ttype: "1",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "text-left" }, [
                                  _vm._v(_vm._s(el.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "small d-flex justify-content-between",
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(el.article))]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            _vm.state.brand = el.brand
                                          },
                                        },
                                      },
                                      [
                                        _vm.getTwoChars(el.uid) === "bs"
                                          ? _c("font-awesome-icon", {
                                              staticStyle: { color: "grey" },
                                              attrs: {
                                                icon: "fa-regular fa-star",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getTwoChars(el.uid) === "ps"
                                          ? _c("font-awesome-icon", {
                                              staticStyle: {
                                                color: "goldenrod",
                                              },
                                              attrs: {
                                                icon: "fa-solid fa-award",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getTwoChars(el.uid) === "pr"
                                          ? _c("font-awesome-icon", {
                                              staticStyle: {
                                                color: "royalblue",
                                              },
                                              attrs: {
                                                icon: "fa-regular fa-circle-user",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(el.brand) +
                                            "\n                                    "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }