var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-fluid m1" },
      [
        _c("title-for-page", {
          attrs: { "c-page": _vm.local.cPage, nbi: _vm.nbi },
        }),
        _vm._v(" "),
        _vm.isMounted
          ? _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-12 col-xl-8" },
                [
                  _c(
                    "nav",
                    {
                      staticClass: "navbar navbar-expand pl-0 pt-0",
                      staticStyle: { "margin-left": "-0.5rem" },
                    },
                    [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c("div", { staticClass: "navbar-nav" }, [
                          _c("div", { staticClass: "nav-item" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-link btn",
                                class: { active: _vm.state.swOnOpt },
                                attrs: { title: "настройки страницы" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.swOnOpt = !_vm.state.swOnOpt
                                  },
                                },
                              },
                              [_vm._v("настройки")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "nav-item" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-link btn",
                                class: { active: _vm.isEdit },
                                attrs: { title: "редактирование текста" },
                                on: {
                                  click: function ($event) {
                                    _vm.isEdit = !_vm.isEdit
                                  },
                                },
                              },
                              [_vm._v("редактировать")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "nav-item" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-link btn",
                                class: { active: _vm.state.isField },
                                on: {
                                  click: function ($event) {
                                    _vm.state.isField = !_vm.state.isField
                                  },
                                },
                              },
                              [_vm._v("поля")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "nav-item" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-link btn",
                                class: { active: _vm.state.swImportW },
                                on: {
                                  click: function ($event) {
                                    _vm.state.swImportW = !_vm.state.swImportW
                                  },
                                },
                              },
                              [_vm._v("импорт")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("card-options", {
                    staticClass: "mb-3",
                    attrs: { "c-page": _vm.local.cPage, state: _vm.state },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _c(
              "div",
              {
                class: {
                  "col-12": !_vm.state.swImportW,
                  "col-xl-8": !_vm.state.swImportW,
                  "col-6": _vm.state.swImportW,
                },
              },
              [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-body p-0",
                      on: {
                        click: function ($event) {
                          _vm.isEdit = true
                        },
                      },
                    },
                    [
                      _c("c-tiptap", {
                        attrs: { "is-edit": _vm.isEdit },
                        model: {
                          value: _vm.local.cPage.html,
                          callback: function ($$v) {
                            _vm.$set(_vm.local.cPage, "html", $$v)
                          },
                          expression: "local.cPage.html",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("import-fields", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state.swImportW && _vm.state.isField,
                  expression: "state.swImportW && state.isField",
                },
              ],
              staticClass: "col-6",
              attrs: {
                "modal-id": "modalImportFields",
                "is-show": _vm.state.swImportW && _vm.state.isField,
                "c-page": _vm.local.cPage,
                "with-html": true,
              },
            }),
            _vm._v(" "),
            _c("import-div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state.swImportW && !_vm.state.isField,
                  expression: "state.swImportW && !state.isField",
                },
              ],
              staticClass: "col-6",
              attrs: {
                "modal-id": "modalImportDiv",
                "is-show": _vm.state.swImportW && !_vm.state.isField,
                "is-for-text": true,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }