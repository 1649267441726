var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { id: _vm.modalId, role: "dialog" },
      on: { click: _vm.closeModal },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-content", staticStyle: { width: "67rem" } },
            [
              _c(
                "div",
                { staticClass: "modal-header" },
                [
                  _vm._v("\n                поля\n                "),
                  _c("font-awesome-icon", {
                    staticClass: "text-danger mt-1",
                    staticStyle: { cursor: "pointer" },
                    attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                    on: { click: _vm.closeModal },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between mb-3" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "w-100 mr-2",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.search,
                              expression: "search",
                            },
                          ],
                          ref: "v_focus",
                          staticClass: "form-control w-100",
                          domProps: { value: _vm.search },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.search = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("font-awesome-icon", {
                          attrs: {
                            icon: "fa-solid fa-eraser",
                            size: "lg",
                            title: "очистить",
                          },
                          on: {
                            click: function ($event) {
                              _vm.search = ""
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "mt-2",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            icon: "fa-solid fa-magnifying-glass",
                            size: "lg",
                          },
                          on: { click: _vm.clickSearch },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                this.local.table.length === 0
                  ? _c(
                      "div",
                      { staticClass: "text-center p-5" },
                      [
                        true
                          ? _c("font-awesome-icon", {
                              staticClass: "mt-1 text-secondary",
                              attrs: {
                                icon: "fa-solid fa-circle-notch",
                                size: "3x",
                                title: "загрузка данных...",
                                spin: "",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "max-height": "40rem",
                      "overflow-y": "auto",
                      "overflow-x": "hidden",
                    },
                  },
                  [
                    _c(
                      "table",
                      { staticClass: "table table-sm" },
                      _vm._l(_vm.local.table, function (val, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "text-left" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(val.value)
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "small" }, [
                              _vm._v(_vm._s(val.param.name)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              staticStyle: { width: "3rem" },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "fa-solid fa-angles-right",
                                  title: "вставить",
                                  size: "lg",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickInsertBlock(val)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }