var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container-fluid m1" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 col-xl-8" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "h1",
                  { staticClass: "mb-0" },
                  [
                    _vm._v(
                      _vm._s(_vm.local.project.name) +
                        "\n                            "
                    ),
                    _c("font-awesome-icon", {
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "fa-solid fa-list", title: "содержание" },
                      on: { click: _vm.clickGoPages },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "nav nav-tabs mt-4" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 0 },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(0)
                      },
                    },
                  },
                  [_vm._v("общая информация")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 1 },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(1)
                      },
                    },
                  },
                  [_vm._v("участники")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 2 },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(2)
                      },
                    },
                  },
                  [_vm._v("bakup")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 3 },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(3)
                      },
                    },
                  },
                  [_vm._v("финансы")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.state.activeTab === 4 },
                    on: {
                      click: function ($event) {
                        return _vm.clickTab(4)
                      },
                    },
                  },
                  [_vm._v("файлы")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tab-content" }, [
              _c(
                "div",
                {
                  staticClass: "tab-pane container",
                  class: { active: _vm.state.activeTab === 0 },
                },
                [
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local.ps_info.number,
                            expression: "local.ps_info.number",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "шифр проекта" },
                        domProps: { value: _vm.local.ps_info.number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info,
                              "number",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.local.project.name,
                            expression: "local.project.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "название" },
                        domProps: { value: _vm.local.project.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.project,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-1" },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.listStage,
                            searchable: false,
                            "close-on-select": true,
                            "show-labels": false,
                            "allow-empty": false,
                          },
                          model: {
                            value: _vm.local.ps_info.stage,
                            callback: function ($$v) {
                              _vm.$set(_vm.local.ps_info, "stage", $$v)
                            },
                            expression: "local.ps_info.stage",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.listStatus.map((item) => item.id),
                            searchable: false,
                            "close-on-select": true,
                            "show-labels": false,
                            "custom-label": (opt) =>
                              _vm.toolM.labelSearch_1(_vm.listStatus, opt),
                            "allow-empty": false,
                          },
                          model: {
                            value: _vm.local.project.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.local.project, "status", $$v)
                            },
                            expression: "local.project.status",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.address,
                            expression: "local.ps_info.address",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "объект проектирования" },
                        domProps: { value: _vm.local.ps_info.address },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info,
                              "address",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-1" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.section.number,
                            expression: "local.ps_info.section.number",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "номер" },
                        domProps: { value: _vm.local.ps_info.section.number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info.section,
                              "number",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.section.name,
                            expression: "local.ps_info.section.name",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "раздел" },
                        domProps: { value: _vm.local.ps_info.section.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info.section,
                              "name",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-1" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.sub_section.number,
                            expression: "local.ps_info.sub_section.number",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "номер" },
                        domProps: {
                          value: _vm.local.ps_info.sub_section.number,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info.sub_section,
                              "number",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.sub_section.name,
                            expression: "local.ps_info.sub_section.name",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "подраздел" },
                        domProps: { value: _vm.local.ps_info.sub_section.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info.sub_section,
                              "name",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-1" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.part.number,
                            expression: "local.ps_info.part.number",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "номер" },
                        domProps: { value: _vm.local.ps_info.part.number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info.part,
                              "number",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.part.name,
                            expression: "local.ps_info.part.name",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "часть" },
                        domProps: { value: _vm.local.ps_info.part.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info.part,
                              "name",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-1" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.book.number,
                            expression: "local.ps_info.book.number",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "номер" },
                        domProps: { value: _vm.local.ps_info.book.number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info.book,
                              "number",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.local.ps_info.book.name,
                            expression: "local.ps_info.book.name",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "книга" },
                        domProps: { value: _vm.local.ps_info.book.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.local.ps_info.book,
                              "name",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.nameProject,
                            expression: "nameProject",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: "" },
                        domProps: { value: _vm.nameProject },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.nameProject = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("datepicker", {
                          attrs: {
                            language: _vm.ru,
                            format: _vm.toolM.customFormatter,
                            inputClass: "form-control",
                          },
                          model: {
                            value: _vm.local.ps_info.start_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.local.ps_info, "start_date", $$v)
                            },
                            expression: "local.ps_info.start_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _vm._m(3),
                        _vm._v(" "),
                        _c("datepicker", {
                          attrs: {
                            language: _vm.ru,
                            format: _vm.toolM.customFormatter,
                            inputClass: "form-control",
                          },
                          model: {
                            value: _vm.local.ps_info.stop_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.local.ps_info, "stop_date", $$v)
                            },
                            expression: "local.ps_info.stop_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 d-flex justify-content-between" },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.local.listOrganization.map((item) => {
                              return { id: item.id, name: item.name }
                            }),
                            searchable: true,
                            "close-on-select": true,
                            "show-labels": false,
                            "custom-label": (opt) =>
                              _vm.toolM.labelSearch_2(
                                _vm.local.listOrganization,
                                opt
                              ),
                            "allow-empty": true,
                            placeholder: "организация",
                            "tag-placeholder": "создать новую",
                            taggable: true,
                          },
                          on: { tag: _vm.addNewOrg },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (beforeList) {
                                return [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "form-control btn btn-sm btn-outline-primary p-0",
                                      on: { click: _vm.clickResetOrg },
                                    },
                                    [
                                      _vm._v(
                                        "очистить\n                                        "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.local.ps_info.organization,
                            callback: function ($$v) {
                              _vm.$set(_vm.local.ps_info, "organization", $$v)
                            },
                            expression: "local.ps_info.organization",
                          },
                        }),
                        _vm._v(" "),
                        _c("font-awesome-icon", {
                          staticClass: "mt-2 ml-2 mr-2",
                          attrs: {
                            icon: "fa-solid fa-list",
                            size: "lg",
                            "data-toggle": "modal",
                            "data-target": "#myModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6 d-flex justify-content-between" },
                      [
                        _c("multiselect", {
                          attrs: {
                            placeholder: "теги",
                            label: "name",
                            "track-by": "name",
                            options: _vm.local.listTag,
                            multiple: true,
                            taggable: true,
                            "tag-placeholder": "создать новый",
                          },
                          on: { tag: _vm.addTag },
                          model: {
                            value: _vm.local.project.tags,
                            callback: function ($$v) {
                              _vm.$set(_vm.local.project, "tags", $$v)
                            },
                            expression: "local.project.tags",
                          },
                        }),
                        _vm._v(" "),
                        _c("font-awesome-icon", {
                          staticClass: "mt-2 ml-2 mr-2",
                          attrs: { icon: "fa-solid fa-list", size: "lg" },
                          on: { click: _vm.showModalListTag },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("c-collapse-block", {
                        staticClass: "w-100",
                        attrs: {
                          name: "комментарий",
                          "is-edit": true,
                          expand: _vm.local.ps_info.comm !== "",
                        },
                        model: {
                          value: _vm.local.ps_info.comm,
                          callback: function ($$v) {
                            _vm.$set(_vm.local.ps_info, "comm", $$v)
                          },
                          expression: "local.ps_info.comm",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.clickClone },
                      },
                      [_vm._v("клонировать проект")]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane container",
                  class: { active: _vm.state.activeTab === 1 },
                },
                [
                  _c("fio-stamp", {
                    attrs: {
                      "project-info": _vm.local.ps_info,
                      "list-fio": _vm.local.listFio,
                      nbi: _vm.nbi,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane container",
                  class: { active: _vm.state.activeTab === 2 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "row mt-3" },
                    _vm._l(_vm.listBackup, function (el) {
                      return _c("div", { staticClass: "col-12" }, [
                        el.name !== "upd"
                          ? _c(
                              "button",
                              {
                                staticClass: "form-control btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickBackup(el)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(el.name) + " " + _vm._s(el.size) + "кБ"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        el.name === "upd"
                          ? _c(
                              "button",
                              {
                                staticClass: "form-control btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickBackup(el)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "последняя загрузка " + _vm._s(el.size) + "кБ"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.state.activeTab === 3
                ? _c(
                    "div",
                    {
                      staticClass: "card",
                      staticStyle: { "border-top": "none" },
                    },
                    [
                      _vm.isMounted
                        ? _c("finance", {
                            attrs: {
                              "p-local": _vm.local,
                              "p-state": _vm.state,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane container mt-3",
                  class: { active: _vm.state.activeTab === 4 },
                },
                [
                  _c("div", { staticClass: "card p-3" }, [
                    _c("div", { staticClass: "row m-0" }, [
                      _vm.local.gofInfo.id === ""
                        ? _c("div", { staticClass: "col-12" }, [
                            _vm.gofState
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.local.gofInfo.name,
                                          expression: "local.gofInfo.name",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      ref: "add_gof",
                                      staticClass: "form-control",
                                      attrs: { placeholder: "название группы" },
                                      domProps: {
                                        value: _vm.local.gofInfo.name,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.local.gofInfo,
                                            "name",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "form-control btn btn-primary w-auto ml-2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickCreateGof_()
                                          },
                                        },
                                      },
                                      [_vm._v("добавить")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "form-control btn btn-primary w-auto ml-2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickCreateGofReset()
                                          },
                                        },
                                      },
                                      [_vm._v("отмена")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.gofState
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickCreateGof()
                                      },
                                    },
                                  },
                                  [_vm._v("создать группу файлов")]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.local.gofInfo.id !== ""
                        ? _c("div", { staticClass: "col-12" }, [
                            !_vm.gofState
                              ? _c("div", { staticClass: "d-inline" }, [
                                  _vm._v(_vm._s(_vm.local.gofInfo.name)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.gofState
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-link d-inline",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickEditGof()
                                      },
                                    },
                                  },
                                  [_vm._v("редактировать")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gofState
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.local.gofInfo.name,
                                          expression: "local.gofInfo.name",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      ref: "gof_edit",
                                      staticClass: "form-control mb-3",
                                      attrs: { placeholder: "название группы" },
                                      domProps: {
                                        value: _vm.local.gofInfo.name,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.local.gofInfo,
                                            "name",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "form-control btn btn-primary w-auto ml-3",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickSaveGof()
                                          },
                                        },
                                      },
                                      [_vm._v("сохранить")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "form-control btn btn-primary w-auto ml-3",
                                        on: {
                                          click: function ($event) {
                                            _vm.gofState = false
                                          },
                                        },
                                      },
                                      [_vm._v("отмена")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "form-control btn btn-primary w-auto ml-3",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickDeleteGof()
                                          },
                                        },
                                      },
                                      [_vm._v("удалить")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.gofState
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        options: _vm.local.list_projects,
                                        searchable: true,
                                        "close-on-select": true,
                                        "show-labels": false,
                                        "allow-empty": false,
                                        "custom-label": (opt) =>
                                          _vm.local.list_projects.find(
                                            (item) => item.uid === opt.uid
                                          ).name,
                                        placeholder: "выберите проект",
                                      },
                                      model: {
                                        value: _vm.local.add_project,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.local,
                                            "add_project",
                                            $$v
                                          )
                                        },
                                        expression: "local.add_project",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.gofState
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "form-control btn btn-primary w-auto ml-3",
                                            attrs: {
                                              title: "добавить проект в группу",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickAddProjectToGof()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "добавить проект\n                                        "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.local.gof, function (el) {
                    return _vm.isMounted
                      ? _c("div", { staticClass: "card p-3" }, [
                          _vm.gofInfo.prop !== undefined
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-3" }, [
                                  _vm._v(_vm._s(el.name) + " "),
                                  el.uid === _vm.gofInfo.prop.main_uid
                                    ? _c("span", [_vm._v(" (главный)")])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-3" }, [
                                  _vm._v(_vm._s(el.number)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-5" }, [
                                  _vm._v(_vm._s(el.name_project)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-1" }, [
                                  el.uid !== _vm.gofInfo.prop.main_uid
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "btn-link text-danger",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickDeleteProjectGof(
                                                el.uid
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("удалить")]
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "card w-100" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "card-link",
                                    attrs: {
                                      "data-toggle": "collapse",
                                      href: "#users" + el.uid,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-header p-1 pl-3 text-dark",
                                      },
                                      [
                                        _vm._l(el.users, function (us) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mr-3",
                                                class: {
                                                  "font-weight-bold":
                                                    us.id ===
                                                    _vm.nbi.authUser.id,
                                                },
                                              },
                                              [_vm._v(_vm._s(us.name))]
                                            ),
                                          ]
                                        }),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "collapse1",
                                    attrs: { id: "users" + el.uid },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card-body" },
                                      [
                                        _vm._l(el.users, function (us) {
                                          return _c(
                                            "div",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-2" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        "font-weight-bold":
                                                          us.id ===
                                                          _vm.nbi.authUser.id,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(us.name))]
                                                  ),
                                                  _vm._v(" "),
                                                  us.id !== _vm.nbi.authUser.id
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "text-danger ml-3",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        attrs: {
                                                          icon: "fa-solid fa-xmark",
                                                          title:
                                                            "удалить пользователя",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickRemoveUserAdd(
                                                              el.uid,
                                                              us.id
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-10" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "mr-4",
                                                      class: {
                                                        "font-weight-bold":
                                                          us.prop.isAdmin,
                                                      },
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickAdminGof(
                                                            el.uid,
                                                            us.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("администратор")]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    us.prop.razdel,
                                                    function (r) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "mr-2",
                                                            class: {
                                                              "font-weight-bold":
                                                                r.check,
                                                            },
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            attrs: {
                                                              title: r.title,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clickRazdelGof(
                                                                  el.uid,
                                                                  us.id,
                                                                  r.name
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(r.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-6" }, [
                                            _vm.addUser[el.uid].state
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-between",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.trim",
                                                          value:
                                                            _vm.addUser[el.uid]
                                                              .name,
                                                          expression:
                                                            "addUser[el.uid].name",
                                                          modifiers: {
                                                            trim: true,
                                                          },
                                                        },
                                                      ],
                                                      ref: "add_user",
                                                      refInFor: true,
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        placeholder:
                                                          "имя пользователя",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.addUser[el.uid]
                                                            .name,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.addUser[el.uid],
                                                            "name",
                                                            $event.target.value.trim()
                                                          )
                                                        },
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.$forceUpdate()
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "form-control btn btn-primary w-auto ml-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickAddUserAdd(
                                                              el.uid
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("добавить")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "form-control btn btn-primary w-auto ml-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickAddUserReset(
                                                              el.uid
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("отмена")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.addUser[el.uid].state &&
                                            _vm.isAdminForGof(el.uid)
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "form-control btn btn-sm btn-primary",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.clickAddUser(
                                                          el.uid
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                            добавить пользователя\n                                                        "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-4" }),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-2" }, [
                                            _vm.isAdminForGof(el.uid)
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "form-control btn btn-success",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.clickSaveUser(
                                                          el.uid,
                                                          el.users
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("сохранить")]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e()
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-org-logo", {
        attrs: {
          "list-organization": _vm.local.listOrganization,
          "modal-id": "myModal",
        },
      }),
      _vm._v(" "),
      _c("modal-fio-sign", {
        attrs: { "list-fio": _vm.local.listFio, "modal-id": "myModalFio" },
      }),
      _vm._v(" "),
      _c("modal-list-tag", { attrs: { "list-tag": _vm.local.listTag } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("объект проектирования")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("название проекта")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("начало")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "small" }, [_vm._v("окончание")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }