<template>
    <div :id="modalId" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content" style="width: 16rem">
                <div class="modal-header">
                    выбор цвета
                </div>
                <div class="modal-body" style="max-height: 30rem; overflow: auto">
                    <picker v-model="local.obj.color"/>
                </div>
                <div class="modal-footer justify-content-between">
                    <div></div>
                    <div>
                        <button type="button" class="btn btn-primary" @click="clickSave">сохранить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">отмена</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import {Sketch} from 'vue-color';
    import {modalDraggable} from "./tools";


    export default {
        name: 'modal-color-picker',
        components: {Multiselect, vueDropzone: vue2Dropzone, picker: Sketch},
        props: {
            modalId: {type: String, default: 'modalColorPicker',},
        },
        data: function () {
            return {
                rand: 1,
                local: {
                    obj: {color: ''},
                },
                state: {},
                search: '',
            }
        },
        created: function () {
            Bus.$on('show_' + this.modalId, (obj) => this.showModal(obj));
        },
        mounted() {
            new modalDraggable(this);
        },
        methods: {
            showModal(obj) {
                this.local.obj = JSON.parse(JSON.stringify(obj));
                $('#' + this.modalId).modal('show');
            },
            closeModal() {
                $('#' + this.modalId).modal('hide');
            },
            clickSave() {
                this.local.obj.color = this.local.obj.color.hex;
                console.log('emit');
                Bus.$emit('updColor', this.local.obj);
                $('#' + this.modalId).modal('hide');
            },
        },
    }
</script>
