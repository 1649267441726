var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ttype === "1"
        ? _c(
            "div",
            _vm._l(_vm.local.listCat, function (el, index) {
              return _c("span", [
                index > 0 ? _c("span", [_vm._v(">")]) : _vm._e(),
                _vm._v(" " + _vm._s(el.name)),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ttype === "2" && _vm.isPsGlobal
        ? _c("div", [
            _c(
              "span",
              {
                staticClass: "badge bg-info",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.clickCategory("")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.category))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.ttype === "2" || _vm.ttype === "3") && !_vm.isPsGlobal
        ? _c(
            "div",
            [
              _vm.category !== ""
                ? _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.clickCategory("")
                        },
                      },
                    },
                    [_vm._v("все")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.local.listCat, function (el, index) {
                return _c(
                  "span",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickCategory(el.id)
                      },
                    },
                  },
                  [
                    index > 0 ? _c("span", [_vm._v(">")]) : _vm._e(),
                    _vm._v(" " + _vm._s(el.name)),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ttype === "4" && !_vm.isPsGlobal
        ? _c(
            "div",
            [
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.clickCategory("")
                    },
                  },
                },
                [
                  _vm._v("все\n            "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.onShowContext($event, { id: 0 })
                        },
                      },
                    },
                    [_vm._v("...")]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.local.listCat, function (el, index) {
                return _c("span", { staticStyle: { cursor: "pointer" } }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.clickCategory(el.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(el.name))]
                  ),
                  _vm._v(" "),
                  _vm.isChild(el.id)
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.onShowContext($event, el)
                            },
                          },
                        },
                        [_vm._v("....")]
                      )
                    : _vm._e(),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ttype === "4"
        ? _c("context-menu-my", {
            ref: "menu",
            attrs: { items: _vm.items, "id-menu": "ccs" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }