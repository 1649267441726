<template>
    <div>
        <div class="w-100 text-right">
            <button class="btn btn-primary btn-sm" @click="clickAddRow">добавить</button>
        </div>
        <div class="card">
            <div class="card-body">
                <div v-for="(val,index) in fFields" :key="index" class="card my-1 myRow" :class="{selected:currentObj!==null && currentObj.id===val.id}">
                    <div class="card-body">

                        <div class="d-flex justify-content-start">
                            <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mr-2 mt-2" @click="clickRemoveRow(val)" title="удалить" style="cursor: pointer"/>
                            <input class="form-control w-25" v-model="val.name">
                            <div class="w-75 ml-2">
                                <textarea rows="1" class="form-control" v-model="val.pattern"/>
                                <div class="w-100 mt-2">{{getValue(val)}}</div>
                            </div>
                            <div class="ml-2">
                                <font-awesome-icon v-if="currentObj!==null && currentObj.id===val.id" icon="fa-solid fa-circle-check" @click="clickCurrentId(null)"/>
                                <font-awesome-icon v-else icon="fa-solid fa-circle" @click="clickCurrentId(val)"/>
                            </div>
                        </div>

                        <div class="mt-2">
                            <div v-for="val2 in val.fields" :key="val2.id" class="d-flex justify-content-start align-items-center">
                                <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mr-2" @click="clickRemoveFieldFromBlock(val,val2)" title="удалить" style="cursor: pointer"/>
                                <input class="form-control w-25" v-model="val2.id">
                                <div class="w-75 ml-2">
                                    <div class="small" @click="clickNameField(val2)" style="cursor: pointer">
                                        {{val2.name}}
                                        <span v-for="val3 in val2.opt"> {{val3.val}}</span>
                                    </div>
                                    <div>{{val2.value}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {page} from "./tools";
    import uniqid from "uniqid";

    export default {
        name: 'variables-div',
        props: {
            local: {},
            // pageT:{},
        },
        data: function () {
            return {
                pageT: new page(this),
                // nbi: {},
                // local: {
                //     table: this.table,
                // },
                status: {
                    loading: false,
                },
                currentObj: {},
                // isMounted: false,
            }
        },
        created: function () {
            Bus.$on('insertBlock', obj => {
                obj.id = uniqid();
                this.currentObj.fields.push(obj);
            });
        },
        mounted() {
        },
        methods: {
            clickAddRow() {
                Bus.$emit('addRow');
            },
            clickNameField(obj) {
                if (obj.idd === 'es_ru_0') Bus.$emit('show_modalEsRu', obj);
            },
            getValue(obj) {
                if (!obj.pattern) return '';

                let pattern = obj.pattern;

                obj.value = pattern.replace(/{{.*?}}/gm, (match) => {
                    let id = match.replace(/{{|}}/gm, '');
                    let o = obj.fields.find(i2 => i2.id === id);
                    if (o) return o.value;
                    o = this.local.fields.find(i2 => i2.name === id);
                    if (o && o.name !== obj.name) return o.value;

                    return '<пусто>';
                });
                return obj.value;
            },
            clickRemoveFieldFromBlock(obj, field) {
                let index = obj.fields.findIndex(item => item.id === field.id);
                if (index !== undefined && index !== null) obj.fields.splice(index, 1);
            },
            clickCurrentId(val = null) {
                if (!val) this.currentObj = null; else this.currentObj = val;
                Bus.$emit('updCurrentId', val);
            },
            clickRemoveRow(obj) {
                Bus.$emit('removeRow', obj);
            },
        },
        computed: {
            fFields: function () {
                if(!this.local.fields) return [];
                return this.local.fields.filter(item => item.idd !== 't_docx_0');
            },
        },
    }
</script>

<style scoped lang="scss">
    @import '../../../sass/variables';

    .item {
        padding: 0.3rem;
        border-bottom: solid $primary 1px;
        cursor: pointer;

        &.not-visible {
            opacity: 0.5;
        }

        &:hover {
            background-color: lighten($primary, 20%);
        }

        &.ru {
            font-weight: bold;
            //border: 1px solid $primary;
            background-color: lighten($primary, 50%);
        }
    }

    svg.fa-circle {
        cursor: pointer;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }

    svg.fa-circle-check {
        cursor: pointer;
        opacity: 1;
        color: $primary;
    }

    .myRow {
        &:hover {
            background-color: lighten($primary, 50%);
        }

        &.selected {
            background-color: lighten($primary, 40%);
            border: 1px solid $primary;
        }
    }

</style>
