<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount" data-keyboard="false">
        <div class="modal-dialog modal-xl" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <font-awesome-icon style="color: grey" v-if="getTwoChars(state.obj.uid)==='bs'" icon="fa-regular fa-star"/>
                        <font-awesome-icon style="color: goldenrod" v-if="getTwoChars(state.obj.uid)==='ps'" icon="fa-solid fa-award"/>
                        <font-awesome-icon style="color: royalblue" v-if="getTwoChars(state.obj.uid)==='pr'" icon="fa-regular fa-circle-user"/>
                        <span class="ml-1">карточка продукта</span>
                    </div>

                    <div>
                        <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                    </div>
                </div>
                <div v-if="isLoad" class="modal-body" @click="clickCloseContextMenu">

                    <div class="d-flex flex-column" style="max-height: calc(100vh - 236px);">

                        <div class="row">
                            <div class="col-12 text-left">
                                <c-category-string v-if="isMount && state.isEdit" :list-category="local.listCategory" :c-obj="state.objNew" :ttype="'4'" class="d-inline-block"/>
                                <c-category-string v-if="isMount && !state.isEdit" :list-category="local.listCategory" :c-obj="state.obj" :ttype="'3'" class="d-inline-block"/>
                            </div>
                        </div>

                        <div v-if="!state.isEdit">
                            <div v-if="!!state.obj.img && state.obj.img.length>0" class="mb-2" style="min-height: 200px; height: 1px;">
                                <div class="d-flex h-100" style="overflow-y: hidden">
                                    <div v-for="(el,index) in state.obj.img" :key="index" class="h-100" style="position:relative;">
                                        <img :src="el" alt="" class="h-100">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.isEdit">
                            <div v-if="!!state.objNew.img && state.objNew.img.length>0" class="mb-2" style="min-height: 200px; height: 1px;">
                                <div class="d-flex h-100" style="overflow-y: hidden">
                                    <div v-for="(el,index) in state.objNew.img" :key="index" class="h-100" style="position:relative;">
                                        <img :src="el" alt="" class="h-100">
                                        <font-awesome-icon v-if="state.isEdit" icon="fa-solid fa-xmark" class="text-danger mt-1" @click="clickDeleteImg(index)"
                                                           style="cursor: pointer; position: absolute; right: 0; top: 0;" size="lg"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.isEdit">
                            <div class="row mb-0">
                                <div class="col-12 text-left">
                                    <label class="w-100">
                                        <span class="small font-weight-bold">Название</span>
                                        <input class="form-control" v-model.trim="state.objNew.name"/>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <label class="w-100">
                                        <span class="small font-weight-bold">Марка</span>
                                        <input class="form-control" v-model.trim="state.objNew.marka"/>
                                    </label>
                                </div>
                                <div class="col-3 text-left">
                                    <label class="w-100">
                                        <span class="small font-weight-bold">Артикул</span>
                                        <input class="form-control" v-model.trim="state.objNew.prop.info.brand.article"/>
                                    </label>
                                </div>
                                <div class="col-3 text-left">
                                    <label class="w-100">
                                        <span class="small font-weight-bold">Производитель</span>
                                        <input class="form-control" v-model.trim="state.objNew.prop.info.brand.name"/>
                                    </label>
                                </div>
                                <div class="col-3 text-left">
                                    <label class="w-100">
                                        <span class="small font-weight-bold">ед.изм.</span>
                                        <input class="form-control" v-model.trim="state.objNew.prop.info.unit"/>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div v-if="!state.isPS && !state.isDeleteLink" class="text-left">
                            <p><span>Название:</span> {{ state.obj.name }}</p>
                            <p><span>Марка:</span> {{ state.obj.marka }}</p>
                            <p><span>Артикул:</span> {{ state.obj.prop.info.brand.article }}</p>
                            <p><span>Производитель:</span> {{ state.obj.prop.info.brand.name }}</p>
                            <p><span>ед.изм.:</span> {{ state.obj.prop.info.unit }}</p>
                            <p v-if="Object.keys(state.obj.prop.fields).length>0" class="font-weight-bold mt-1">Свойства:</p>
                        </div>

                        <div v-if="state.isPS && !state.isDeleteLink" class="text-left">
                            <p><span>Название:</span> {{ state.obj.name }}</p>
                            <p><span>Серия:</span> {{ state.obj.series }}</p>
                            <p><span>Серия продукта:</span> {{ state.obj.series_p }}</p>
                            <p><span>Артикул:</span> {{ state.obj.article }}</p>
                            <p><span>Производитель:</span> {{ state.obj.brand }}</p>
                            <p v-if="Object.keys(state.obj.prop).length>0" class="font-weight-bold mt-1">Свойства:</p>
                        </div>

                        <div v-if="!state.isDeleteLink && !state.isPS && Object.keys(state.obj.prop.fields).length>0" class="text-left" style="overflow-x: hidden; border: 1px solid grey">
                            <p v-for="(el,name) in state.obj.prop.fields"><span>{{ name }}:</span> {{ el }}</p>
                        </div>
                        <div v-if="!state.isDeleteLink && state.isPS && Object.keys(state.obj.prop).length>0" class="text-left" style="overflow-x: hidden; border: 1px solid grey">
                            <p v-for="(el,name) in state.obj.prop"><span>{{ name }}:</span> {{ el }}</p>
                        </div>
                    </div>

                </div>
                <div v-if="isLoad" class="modal-footer d-flex flex-row">
                    <div class="mr-auto">
                        <div v-if="!state.isEdit">
                            <font-awesome-icon style="color: grey; cursor: pointer;" v-if="getTwoChars(state.obj.base_uid)==='bs'" icon="fa-regular fa-star" @click="clickIcon()"/>
                            <font-awesome-icon style="color: goldenrod; cursor: pointer;" v-if="getTwoChars(state.obj.base_uid)==='ps'" icon="fa-solid fa-award" @click="clickIcon()"/>
                        </div>
                        <div v-if="state.isEdit">
                            <button v-if="state.isEdit && !state.isDeleteLink && !!state.obj.base_uid" type="button" class="btn btn-danger" @click="state.isDeleteLink=true">удалить зависимость
                            </button>
                        </div>
                    </div>
                    <div>
                        <button v-if="getTwoChars(state.obj.uid)==='ps' && state.isConvert && state.obj.base_uid===null && !state.isEdit" class="btn btn-default"
                                title="клонировать запись в общую базу" type="button"
                                @click="clickCreateBs">
                            <font-awesome-icon style="color: grey" icon="fa-regular fa-star"/>
                            создать
                        </button>

                        <button v-if="!state.isEdit && getTwoChars(state.obj.uid)==='pr'" type="button" class="btn btn-default text-danger" @click="clickDeletePr">удалить</button>
                        <button v-if="!state.isEdit && getTwoChars(state.obj.uid)==='pr'" type="button" class="btn btn-default" @click="clickSetEditPr">редактировать</button>

                        <button v-if="!state.isEdit" type="button" class="btn btn-default" @click="clickCreatePr" title="клонировать запись в личную базу">
                            <font-awesome-icon style="color: royalblue" icon="fa-regular fa-circle-user"/>
                            создать
                        </button>
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickReset">отмена</button>
                        <button v-if="!state.isEdit" type="button" class="btn btn-primary" @click="clickEnter">вставить</button>
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickPrSave">сохранить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {modalDraggable, page} from "./tools";
import EInput from "./EInput";
import BInputField from "./BInputField";
import uniqid from "uniqid";
import tab from "bootstrap/js/src/tab";
import cCategoryString from "./cCategoryString.vue";


export default {
    name: 'modal-so-info',
    components: {cCategoryString, EInput, Multiselect, BInputField},
    props: {
        modalId: {type: String, default: 'modal',},
        prodObj: {type: Object, default: {},},
        pLocal: {type: Object, default: {},},
        marka: {type: String, default: 'marka_new',},
    },
    data: function () {
        return {
            //todo js modal add page module
            pageT: new page(this),
            rand: 1,
            local: {
                // table: this.pLocal.table,
                // psSetting: this.pLocal.psSetting,
                AW: this.pLocal.AW,
                listCategory: this.pLocal.listCategory,
                selectArr: [],
                saveArr: [],
            },
            state: {
                obj: {uid: ''},
                activeUid: {},
                isConvert: false,
                isPS: false,
                currentCategory: '',
                isEdit: false,
                isDeleteLink: false,
                isCreate: false,
                objNew: {
                    name: '',
                    marka: '',
                    prop: {
                        info: {
                            brand: {
                                article: '',
                                name: '',
                            },
                            category: '',
                            desc: '',
                            unit: '',
                        }
                    },
                    base_uid: '',
                }
            },
            status: {
                loading: false,
            },
            templateObj: {
                name: '',
                marka: '',
                prop: {
                    info: {
                        brand: {
                            article: '',
                            name: '',
                        },
                        category: '',
                        desc: '',
                        unit: '',
                    }
                },
                base_uid: '',
            },
            lock: {
                name: true,
                marka: true,
            },
            templateCMarka: {
                id: '',
                marka: '',
                name: '',
            },
            lockF: {},
            isMount: false,
            isLoad: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (this.isAW) {
                if (e.key === 'esc') this.closeModal();
                if (e.key === 'enter') this.clickEnter();
            }
        });
        Bus.$on('keyDown', e => {
            if (e.key === 40) if (this.state.activeRowIndex + 1 < this.fTable.length) this.clickRow(this.state.activeRowIndex + 1);//Down
            if (e.key === 38) if (this.state.activeRowIndex > 0) this.clickRow(this.state.activeRowIndex - 1);//Up
        });
        Bus.$on('show_' + this.modalId, (e) => {
            this.state.activeUid = e.activeUid;
            this.local.selectArr = e.selectArr;
            this.local.saveArr = e.saveArr;
            this.showModal()
        });
    },
    mounted() {
        this.isMount = true;
    },
    methods: {
        clickEnter() {
            if (this.state.isEdit) {
                this.local.selectArr.forEach(item => {
                    item[this.marka].id = this.state.obj.uid;
                    item[this.marka].marka = this.state.obj.marka;
                    item[this.marka].name = this.state.obj.name;
                    item[this.marka].article = this.state.obj.article;
                    item[this.marka].brand = this.state.obj.brand;
                    if (item.child) {
                        item.child.forEach(item2 => {
                            item2[this.marka].id = this.state.obj.uid;
                            item2[this.marka].marka = this.state.obj.marka;
                            item2[this.marka].name = this.state.obj.name;
                        })
                    }
                    this.local.saveArr.push(item);
                })
            } else {
                this.cMarka.id = this.state.obj.uid;
                this.cMarka.marka = this.state.obj.marka;
                this.cMarka.name = this.state.obj.name;
                Bus.$emit('onInsertEl',this.cMarka);
            }
            this.closeModal();
            Bus.$emit('closeModal');
        },
        clickDeleteImg(index) {
            this.state.objNew.img.splice(index, 1);
        },
        clickDeletePr() {
            if (!window.confirm("Вы уверены что хотите удалить " + this.state.obj.name + "?")) return false;
            this.pageT.clickSave('/marka_element/delete_pr', {obj: this.state.obj}, '_delete_' + this.modalId);
            Bus.$once('zResponse_delete_' + this.modalId, response => {
                Bus.$emit('updListBase');
                this.closeModal();
            });
        },
        clickReset() {
            this.state.isCreate = false;
            this.state.isEdit = false;
            this.state.isDeleteLink = false;
        },
        clickCloseContextMenu() {
            Bus.$emit('clickBody');
        },
        clickCreatePr() {
            this.state.objNew = JSON.parse(JSON.stringify(this.templateObj));

            this.state.objNew.name = this.state.obj.name;
            this.state.objNew.uid = this.state.obj.uid;
            this.state.objNew.base_uid = this.state.obj.uid;
            this.state.objNew.img = this.state.obj.img;

            if (this.getTwoChars(this.state.obj.uid) === 'bs') {
                this.state.objNew.marka = this.state.obj.marka;
                this.state.objNew.prop.info.brand.article = this.state.obj.prop.info.brand.article;
                this.state.objNew.prop.info.brand.name = this.state.obj.prop.info.brand.name;
                this.state.objNew.prop.info.unit = this.state.obj.prop.info.unit;
                this.state.objNew.prop.info.category = this.state.obj.prop.info.category;
            }
            if (this.getTwoChars(this.state.obj.uid) === 'ps') {
                this.state.objNew.marka = this.state.obj.series;
                this.state.objNew.prop.info.brand.article = this.state.obj.article;
                this.state.objNew.prop.info.brand.name = this.state.obj.brand;
                this.state.objNew.prop.info.category = 55;
            }
            if (this.getTwoChars(this.state.obj.uid) === 'pr') {
                this.state.objNew = JSON.parse(JSON.stringify(this.state.obj));
            }

            this.state.isEdit = true;
            this.state.isCreate = true;
        },
        clickSetEditPr() {
            this.state.objNew = JSON.parse(JSON.stringify(this.state.obj));
            this.state.isEdit = true;
            this.state.isCreate = false;
        },
        clickPrSave() {
            Bus.$emit('zPost', {
                url: '/marka_element/create_pr',
                obj: {obj: this.state.objNew, isCreate: this.state.isCreate, isDeleteLink: this.state.isDeleteLink},
                suffix: '_create_pr',
                flash: true,
            });
            Bus.$once('zResponse_create_pr', response => {
                this.state.obj = response.obj;
                this.state.isConvert = false;
                this.state.isPS = this.getTwoChars(this.state.obj.uid) === 'ps';
                this.isLoad = true;
                this.clickReset();
                Bus.$emit('updListBase');
            });
            Bus.$once('zError_create_pr', response => {
            });
        },
        clickCreateBs() {
            Bus.$emit('zPost', {
                url: '/marka_element/create_bs',
                obj: {uid: this.state.obj.uid},
                suffix: '_create_bs',
                flash: true,
            });
            Bus.$once('zResponse_create_bs', response => {
                this.state.obj = response.obj;
                this.state.isConvert = response.isConvert;

                this.state.isPS = this.getTwoChars(this.state.obj.uid) === 'ps';

                this.isLoad = true;
                this.clickReset();
                Bus.$emit('updListBase');
            });
            Bus.$once('zError_create_bs', response => {
            });
        },
        clickIcon() {
            this.postGetInfo(this.state.obj.base_uid);
        },
        showModal() {
            this.isLoad = false;
            this.clickReset();
            if (this.state.activeUid === '') return;

            this.postGetInfo(this.state.activeUid);

            $('#' + this.modalId).modal('show');

            setTimeout(function () {
                $(".modal").draggable({handle: ".modal-header"});
            });

            (function (id) {
                setTimeout(function () {
                    Bus.$emit('setAW', id);
                });
            })(this.modalId)
        },
        getTwoChars(uid) {
            if (!uid) return '';
            return String(uid).slice(0, 2);
        },
        postGetInfo(uid) {
            this.pageT.clickSave('/marka_element/get_info', {uid: uid}, '_get_info');
            Bus.$once('zResponse_get_info', response => {
                this.state.obj = response.obj;
                this.state.isConvert = response.isConvert;
                this.state.isPS = this.getTwoChars(this.state.obj.uid) === 'ps';
                this.isLoad = true;
            });
            Bus.$once('zError_get_info', response => {
                this.closeModal();
            });
        },
        closeModal() {
            $('#' + this.modalId).modal('hide');
            Bus.$emit('removeAW', this.modalId);
        },
    },
    computed: {
        isAW: function () {
            return this.local.AW !== undefined && this.local.AW[0] === this.modalId;
        },
        cMarka: function () {
            return this.lastSelectObj[this.marka] ?? this.templateCMarka;
        },
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
    },
    watch: {},
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';


p {
    margin: 0;

    & > span {
        font-weight: bold;
    }
}

h6 > span {
    font-weight: bold;
}

//.modal {
//    height: 90vh;
//}

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}

ul.multiselect__content {
    width: 100% !important;
}

input.lock {
    background-color: lighten($primary, 50%);
}

.bg-primary-l {
    background-color: lighten($primary, 40);
}

</style>
