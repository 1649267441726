var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      !_vm.inputVal.desc
        ? _c(
            "span",
            {
              staticClass: "small",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.clickTag },
            },
            [_vm._v("примечание >")]
          )
        : _c("label", { staticClass: "w-100 mt-12" }, [
            _c(
              "span",
              {
                staticClass: "small",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    _vm.inputVal.desc = ""
                  },
                },
              },
              [_vm._v("примечание")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.inputVal.desc,
                  expression: "inputVal.desc",
                  modifiers: { number: true },
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.inputVal.desc },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.inputVal, "desc", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }