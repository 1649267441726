var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card-body" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.state.search.val,
            expression: "state.search.val",
            modifiers: { trim: true },
          },
        ],
        ref: "search",
        staticClass: "form-control mb-2",
        domProps: { value: _vm.state.search.val },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.state.search, "val", $event.target.value.trim())
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "table table-sm" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "draggable",
            {
              attrs: {
                tag: "tbody",
                "ghost-class": "ghost",
                disabled: _vm.pState.isEdit,
              },
              model: {
                value: _vm.local.table,
                callback: function ($$v) {
                  _vm.$set(_vm.local, "table", $$v)
                },
                expression: "local.table",
              },
            },
            _vm._l(_vm.fTable, function (el) {
              return _c("tr", { key: el.id }, [
                _c("td", { attrs: { scope: "row" } }, [
                  _vm._v(
                    _vm._s(el.marker_tag) + "/" + _vm._s(el.explication_tag)
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  !_vm.pState.isEdit
                    ? _c("span", [_vm._v(_vm._s(el.name))])
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: el.name,
                            expression: "el.name",
                          },
                        ],
                        staticClass: "form-control1",
                        domProps: { value: el.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(el, "name", $event.target.value)
                          },
                        },
                      }),
                ]),
                _vm._v(" "),
                _c("td", [
                  !_vm.pState.isEdit
                    ? _c("span", [_vm._v(_vm._s(el.otm))])
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: el.otm,
                            expression: "el.otm",
                          },
                        ],
                        staticClass: "form-control1",
                        domProps: { value: el.otm },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(el, "otm", $event.target.value)
                          },
                        },
                      }),
                ]),
                _vm._v(" "),
                _c("td", [
                  !_vm.pState.isEdit
                    ? _c("span", [_vm._v(_vm._s(el.kabl))])
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: el.kabl,
                            expression: "el.kabl",
                          },
                        ],
                        staticClass: "form-control1",
                        domProps: { value: el.kabl },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(el, "kabl", $event.target.value)
                          },
                        },
                      }),
                ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("номер"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-5", attrs: { scope: "col" } }, [
          _vm._v("название"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("отметка"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("кабель"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }