var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _c(
        "div",
        { staticClass: "container-fluid m1", on: { click: _vm.clickBody } },
        [
          _c("title-for-page", {
            attrs: { "c-page": _vm.local.cPage, nbi: _vm.nbi },
          }),
          _vm._v(" "),
          _vm.isMounted
            ? _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-xl-8" },
                  [
                    _c(
                      "nav",
                      {
                        staticClass: "navbar navbar-expand pl-0 pt-0",
                        staticStyle: { "margin-left": "-0.5rem" },
                      },
                      [
                        _c("div", { staticClass: "container-fluid" }, [
                          _c("div", { staticClass: "navbar-nav" }, [
                            _c("div", { staticClass: "nav-item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "nav-link btn",
                                  class: { active: _vm.state.swOnOpt },
                                  attrs: { title: "настройки страницы" },
                                  on: {
                                    click: function ($event) {
                                      _vm.state.swOnOpt = !_vm.state.swOnOpt
                                    },
                                  },
                                },
                                [_vm._v("настройки")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "nav-item" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "nav-link btn",
                                  class: { active: _vm.state.swOnOpt2 },
                                  attrs: { title: "настройки страницы" },
                                  on: {
                                    click: function ($event) {
                                      _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                    },
                                  },
                                },
                                [_vm._v("настройки таблицы")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("card-options", {
                      staticClass: "mb-3",
                      attrs: { "c-page": _vm.local.cPage, state: _vm.state },
                    }),
                    _vm._v(" "),
                    _vm.state.swOnOpt2
                      ? _c("div", { staticClass: "card mb-3" }, [
                          _c(
                            "div",
                            {
                              staticClass: "card-header p-1 pl-2",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  _vm.state.swOnOpt2 = !_vm.state.swOnOpt2
                                },
                              },
                            },
                            [_vm._v("настройки таблицы")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-2" }, [
                                _c(
                                  "label",
                                  { staticClass: "apple-switch_2 ml-1" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.cField.opt.plus_name3.val,
                                          expression:
                                            "cField.opt.plus_name3.val",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.cField.opt.plus_name3.val
                                        )
                                          ? _vm._i(
                                              _vm.cField.opt.plus_name3.val,
                                              null
                                            ) > -1
                                          : _vm.cField.opt.plus_name3.val,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.cField.opt.plus_name3.val,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.cField.opt.plus_name3,
                                                  "val",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.cField.opt.plus_name3,
                                                  "val",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.cField.opt.plus_name3,
                                              "val",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _c("i"),
                                    _vm._v(
                                      _vm._s(_vm.cField.opt.plus_name3.desc)
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.cField.opt.manual.val
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.cField.opt.manual_arr,
                                    function (el, index) {
                                      return _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-3" },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "mb-0 mt-2" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "small" },
                                                  [_vm._v(_vm._s(el[0].desc))]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("multiselect", {
                                              attrs: {
                                                options:
                                                  _vm.cField.opt.list_type_blocks.map(
                                                    (item) => item.val
                                                  ),
                                                "custom-label": (opt_) =>
                                                  _vm.cField.opt.list_type_blocks.find(
                                                    (i) => i.val === opt_
                                                  ).desc,
                                                searchable: false,
                                                "close-on-select": true,
                                                "show-labels": false,
                                                "allow-empty": false,
                                              },
                                              model: {
                                                value: el[0].val,
                                                callback: function ($$v) {
                                                  _vm.$set(el[0], "val", $$v)
                                                },
                                                expression: "el[0].val",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-3" },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "mb-0 mt-2" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "small" },
                                                  [_vm._v(_vm._s(el[1].desc))]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("multiselect", {
                                              attrs: {
                                                options: _vm.listPanel,
                                                "custom-label": (opt_) =>
                                                  _vm.customLabel(opt_),
                                                searchable: false,
                                                "close-on-select": true,
                                                "show-labels": false,
                                                "allow-empty": false,
                                              },
                                              model: {
                                                value: el[1].val,
                                                callback: function ($$v) {
                                                  _vm.$set(el[1], "val", $$v)
                                                },
                                                expression: "el[1].val",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-5" }, [
                                          _c(
                                            "label",
                                            { staticClass: "w-100 mt-2" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "small" },
                                                [_vm._v(_vm._s(el[2].desc))]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: el[2].val,
                                                    expression: "el[2].val",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  placeholder: el[2].desc,
                                                },
                                                domProps: { value: el[2].val },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      el[2],
                                                      "val",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-1" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "mt-5 ml-3",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                icon: "fa-solid fa-plus",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickAdd(el)
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              staticClass:
                                                "mt-5 ml-3 text-danger",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                icon: "fa-solid fa-minus",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickRemove(index)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.cField.opt.manual.val
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "mb-0 mt-2" },
                                        [
                                          _c("span", { staticClass: "small" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.cField.opt.name_panel.desc
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("multiselect", {
                                        attrs: {
                                          options: _vm.listPanel,
                                          "custom-label": (opt_) =>
                                            _vm.customLabel(opt_),
                                          searchable: false,
                                          "close-on-select": true,
                                          "show-labels": false,
                                          "allow-empty": false,
                                        },
                                        model: {
                                          value: _vm.cField.opt.name_panel.val,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cField.opt.name_panel,
                                              "val",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "cField.opt.name_panel.val",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-2" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "mb-0 mt-2" },
                                        [
                                          _c("span", { staticClass: "small" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.cField.opt.type_trn.desc
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("multiselect", {
                                        attrs: {
                                          options:
                                            _vm.cField.opt.list_type_trn.map(
                                              (item) => item.val
                                            ),
                                          "custom-label": (opt_) =>
                                            _vm.cField.opt.list_type_trn.find(
                                              (i) => i.val === opt_
                                            ).desc,
                                          searchable: false,
                                          "close-on-select": true,
                                          "show-labels": false,
                                          "allow-empty": false,
                                        },
                                        model: {
                                          value: _vm.cField.opt.type_trn.val,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cField.opt.type_trn,
                                              "val",
                                              $$v
                                            )
                                          },
                                          expression: "cField.opt.type_trn.val",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "mb-0 mt-2" },
                                        [
                                          _c("span", { staticClass: "small" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.cField.opt.type_arr.desc
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("multiselect", {
                                        attrs: {
                                          options:
                                            _vm.cField.opt.list_type_arr.map(
                                              (item) => item.val
                                            ),
                                          "custom-label": (opt_) =>
                                            _vm.cField.opt.list_type_arr.find(
                                              (i) => i.val === opt_
                                            ).desc,
                                          searchable: false,
                                          "close-on-select": true,
                                          "show-labels": false,
                                          "allow-empty": false,
                                        },
                                        model: {
                                          value: _vm.cField.opt.type_arr.val,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cField.opt.type_arr,
                                              "val",
                                              $$v
                                            )
                                          },
                                          expression: "cField.opt.type_arr.val",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "mb-0 mt-2" },
                                        [
                                          _c("span", { staticClass: "small" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.cField.opt.position_itog
                                                  .desc
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("multiselect", {
                                        attrs: {
                                          options:
                                            _vm.cField.opt.list_position_itog.map(
                                              (item) => item.val
                                            ),
                                          "custom-label": (opt_) =>
                                            _vm.cField.opt.list_position_itog.find(
                                              (i) => i.val === opt_
                                            ).desc,
                                          searchable: false,
                                          "close-on-select": true,
                                          "show-labels": false,
                                          "allow-empty": false,
                                        },
                                        model: {
                                          value:
                                            _vm.cField.opt.position_itog.val,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cField.opt.position_itog,
                                              "val",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "cField.opt.position_itog.val",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-sm",
                          on: { click: _vm.clickReset },
                        },
                        [_vm._v("сброс")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("nested-trn", {
                            staticClass: "item-sub",
                            attrs: { nbi: _vm.nbi },
                            model: {
                              value: _vm.cField.opt.table,
                              callback: function ($$v) {
                                _vm.$set(_vm.cField.opt, "table", $$v)
                              },
                              expression: "cField.opt.table",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("context-menu-my", { ref: "menu", attrs: { items: _vm.items } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row header align-items-center" }, [
      _c("div", { staticClass: "col-4" }, [_vm._v("Наименование нагрузки")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("кол-во")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [
        _vm._v("Pуст,"),
        _c("br"),
        _vm._v("кВт"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("cosϕ")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [_vm._v("Кс")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [
        _vm._v("Pр,"),
        _c("br"),
        _vm._v("кВт"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [
        _vm._v("Qр,"),
        _c("br"),
        _vm._v("квар"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [
        _vm._v("Sр,"),
        _c("br"),
        _vm._v("кВА"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }, [
        _vm._v("Iр,"),
        _c("br"),
        _vm._v("А"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }