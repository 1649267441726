<template>
    <draggable
        tag="div"
        class="item-container"
        :list="list"
        :value="value"
        @input="emitter"
        handle=".handle"
        animation="150"
        group="nested"
        :fallback-on-body="true"
        :swap-threshold="0.5"
        ghost-class="ghost"
        filter=".no-drag"
        @choose="choose"
        @unchoose="unchoose"
        @end="end"
        :move="move"
        @change="onChange"
        :scroll-sensitivity="100" :fallback-tolerance="1" :force-fallback="true"
    >
        <div v-for="el in realValue" class="item-group" :key="el.id">
            <div class="item" :class="{'bg-danger':level>2}">
                <div class="row mb-0">
                    <div class="col-1 pt-2 text-nowrap">
                        <font-awesome-icon icon="fa-regular fa-file" class="handle"/>
                        <span class="font-weight-bold ml-1"><a tabindex="-1" class="text-dark" :href="el.link_content.link+'/'+nbi.authUser.prop.currentProjectId+'/'+el.id">{{getPageName(el)}}</a>
                        </span>
                        <span class="font-weight-bold ml-1 small" title="название листа в autocad"> {{ el.autocad.list_name_autocad }}</span>
                    </div>
                    <div class="col-4">
                        <input class="form-control ml-3" v-model.trim="el.stamp.list_name"/>
                    </div>
                    <div class="col-3 pt-2">
                        {{el.stamp.number}}
                    </div>
                    <div v-if="el.options.count_page>0" class="col-2 pt-2" :title="getTitle(el)">
                        <span v-if="el.type_list!=='text' && el.type_list!=='cover' && el.type_list!=='title_page'">
                        {{el.stamp.list_num_global}}
                        <span>{{getStampSize(el)}}</span>
                        <span>{{getListNum2(el)}}</span>
                        <span v-if="el.stamp.list_count>1">из {{el.stamp.list_count}}</span>
                        </span>
                    </div>
                    <div v-else class="col-2 pt-2" title="в составе другого листа">в составе</div>
                    <div class="col-1 pt-2 text-right no-select">
                        <font-awesome-icon icon="fa-solid fa-minus" @click="clickMinus(el)"/>
                        <span title="количество страниц в одном листе">{{el.options.count_page}}</span>
                        <font-awesome-icon icon="fa-solid fa-plus" @click="clickPlus(el)"/>
                    </div>
                    <div class="col-1 pt-2 text-right">
                        <font-awesome-icon icon="fa-solid fa-xmark" @click="clickRemove(el)" title="удалить" size="xl"/>
                    </div>
                </div>
            </div>
            <nested-list class="item-sub" :list="el.child" :level="level + 1" :setting="setting" :id="el.id" :list-pages-template="listPagesTemplate" :nbi="nbi"/>
        </div>
    </draggable>
</template>

<script>

    import draggable from 'vuedraggable'
    import Multiselect from 'vue-multiselect'

    export default {
        name: "nested-list",
        components: {draggable, Multiselect},
        props: {
            value: {
                required: false,
                type: Array,
                default: null
            },
            list: {
                required: false,
                type: Array,
                default: null
            },
            level: {
                type: Number,
                default: 1,
            },
            setting: {
                required: true,
                type: Object,
                default: {
                    stage: 'П',
                },
            },
            id: {
                required: false,
                type: String,
                default: '0'
            },
            listPagesTemplate: {
                required: true,
                type: Array,
                default: []
            },
            nbi: {
                required: true,
                type: Object,
                default: {}
            },
        },
        data: function () {
            return {
                isMove: true,
                oldValue: this.value,
                lValue: this.value,
                stamp_size: [
                    {name: 'Большой штамп', s_name: 'Б'},
                    {name: 'Средний штамп', s_name: 'С'},
                    {name: 'Маленький штамп', s_name: 'М'},
                    {name: 'Без штампа', s_name: 'БШ'},
                    {name: 'Без всего', s_name: 'БВ'},
                ],
            }
        },
        methods: {
            clickMinus(obj) {
                if (obj.options.count_page <= 0) return;
                obj.options.count_page -= 1;
            },
            clickPlus(obj) {
                obj.options.count_page += 1;
            },
            getTitle(obj) {
                let res = obj.stamp.type + " \nлист.скв. " + obj.stamp.list_num_global + " \nлистов " + obj.stamp.list_num_text;
                if (obj.stamp.list_count > 1) res += ' из ' + obj.stamp.list_count;
                if (obj.options.count_page > 1) res += "\nсостоит из " + obj.options.count_page + ' листов';

                return res;
            },
            clickRemove(obj) {
                Bus.$emit('deletePage', obj);
            },
            getPageName(obj) {
                let o = this.listPagesTemplate.find(item => item.id === obj.type_list);
                if (o) return o.name;
                return 'X';
            },
            getListNum2(obj) {
                let res = obj.stamp.list_num;
                if (obj.stamp.list_count > 1) res = obj.stamp.list_num + '.' + obj.stamp.list_num_2;
                if (obj.type_list === 'razdel' || obj.type_list === 'vspd' || obj.type_list === 'st' || obj.type_list === 'od' || obj.type_list === 'vrch') res = obj.stamp.list_num_2;

                return res;
            },
            getStampSize(obj) {
                let f = this.stamp_size.find(item => obj.stamp.type === item.name);
                if (f === null || obj.type_list === 'text') return '';
                return '(' + f.s_name + ')';
            },
            labelSearch(list, opt) {
                let obj = list.find(item => item.id === opt);
                if (obj === undefined) return '';
                return obj.name;
            },
            emitter(value) {
                this.$emit("input", value);
            },
            as(a, b) {
                console.log(a);
                console.log(b);
            },
            choose() {
                console.log('choose');
            },
            unchoose() {
                console.log('unchoose');
            },
            end() {
                console.log('end');
                // console.log(this.level);
                // if (this.level > 1) return false;
                // console.log(this.oldValue);
                this.$emit("end");
            },
            move() {
                return this.isMove;
            },
            onChange() {
                this.realValue.forEach(item => item.parent_id = this.id);
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            realValue() {
                return this.value ? this.value : this.list;
            },

        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

    .handle {
        /*float: left;*/
        /*padding-top: 8px;*/
        /*padding-bottom: 8px;*/
        color: $primary;
        cursor: move !important;
    }

    .ghost {
        opacity: 0.3;
        background: #c8ebfb;
    }

    .item-container {
        margin: 0;
    }

    .item {
        padding: 0.3rem;
        border-bottom: solid #bbbaba 1px;
    }

    .item-sub {
        margin: 0 0 0 1rem;
    }

    svg.svg-inline--fa {
        cursor: pointer;

        &.fa-xmark {
            color: $danger;
        }
    }

    .type-list {
        opacity: 0.5;
        cursor: pointer;

        &.active {
            font-weight: bold;
            opacity: 1;
        }
    }

    //todo css .no-select
    .no-select {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }

</style>
