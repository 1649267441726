<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
                    <div class="row">
                        <div class="col-8"><input class="form-control w-100 mb-3" v-model="state.search.val"></div>
                        <div class="col-4" v-if="isMounted">
                            <multiselect
                                v-model="state.activeCategory"
                                :options="local.category"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                :internal-search="false"
                                @search-change="customSearch"
                                @input="onChange"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title" :style="{'padding-left':props.option.level+'rem'}">{{ props.option.name }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>

                    <div v-if="isMounted" class="row">
                        <div class="col-12">
                            <span class="mr-2" style="cursor: pointer"
                                  v-for="el in state.filterFields"
                                  :key="el.id"
                                  @click="clickSwFilterField(el)"
                            >{{ el.name }}:{{ el.val }}</span>
                        </div>
                    </div>

                    <div v-if="isMounted" class="row">
                        <div class="col-12">
                            <span class="mr-2" style="cursor: pointer"
                                  v-for="el in fix_field"
                                  :key="el.id"
                                  :class="{'font-weight-bold':isActiveField(el)}"
                                  @click="clickSwField(el)"
                            >{{ el.name }}</span>
                            <span class="mr-2" style="cursor: pointer"
                                  v-for="el in state.activeCategory.prop.fields"
                                  :key="el.id"
                                  :class="{'font-weight-bold':isActiveField(el)}"
                                  @click="clickSwField(el)"
                            >{{ el.name }}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-8"></div>
                        <div class="col-4">
                            <font-awesome-icon icon="fa-solid fa-circle" class="mr-2 mt-2" size="lg" @click="clickDot('select')" style="cursor: pointer;"/>
                            <font-awesome-icon icon="fa-regular fa-circle" class="mr-2 mt-2" size="lg" @click="clickDot('un_select')" style="cursor: pointer;"/>
                            <font-awesome-icon icon="fa-solid fa-circle-dot" class="mr-2 mt-2" size="lg" @click="clickDot('inverse')" style="cursor: pointer;"/>
                            <button class="btn btn-primary btn-sm" @click="state.sortColumn=[]">сбросить сортировку</button>
                            <button class="btn btn-primary btn-sm" @click="showModalInsert">вставить</button>
                        </div>
                    </div>


                    <div id="open" @click="$refs.menu.close()">
                        <table class="w-100">
                            <thead>
                            <draggable tag="tr" :list="activeFields.fields" :scroll-sensitivity="100" :fallback-tolerance="1" :force-fallback="true" ghost-class="ghost">
                                <th v-for="el in activeFields.fields" class="text-nowrap" :title="el.desc">
                                    {{ el.name }}
                                    <font-awesome-icon v-if="getSortStatus(el)===''" :icon="['fas', 'sort']" @click="clickSort(el,'')"/>
                                    <font-awesome-icon v-if="getSortStatus(el)==='up'" :icon="['fas', 'sort-up']" @click="clickSort(el,'up')"/>
                                    <font-awesome-icon v-if="getSortStatus(el)==='down'" :icon="['fas', 'sort-down']" @click="clickSort(el,'down')"/>
                                    <br>{{ el.unit }}
                                </th>
                            </draggable>
                            </thead>
                            <tbody>
                            <tr v-for="val in fTable" :key="val.id"
                                :class="{'bg-success-l':eTable.isEditForSave(val),'bg-primary-l':eTable.isSelect(val),'bg-danger-l':isDeleteFields(val)}"
                                @click.exact="eTable.clickRow(val)"
                                @click.ctrl.prevent="eTable.clickCtrlRow(val)"
                            >
                                <template v-for="el in activeFields.fields">
                                    <td v-if="el.id==='url'" class="text-left small" @contextmenu.prevent="$refs.menu.open($event, {tr:val,td:el})">
                                        <a v-if="getValById(el.id, val)" :href="getValById(el.id, val)" target="_blank">
                                            <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" class="mt-1 mr-3"/>
                                        </a>
                                    </td>
                                    <td v-else class="text-left small" @contextmenu.prevent="$refs.menu.open($event, {tr:val,td:el})">{{ getValById(el.id, val) }}</td>
                                </template>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
        <modal-base modal-id="modalBase" :p-local="local" :p-state="state" @updInit="init"/>
        <ContextMenuMy ref="menu" :items="items"/>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import nestedList from "./sub/nestedList";
import {eTable, page, toolM} from "./sub/tools";
import ModalBase from "./sub/ModalBase";
import ContextMenuMy from "./sub/ContextMenuMy.vue";
import uniqid from "uniqid";

export default {
    name: "page-base",
    components: {ContextMenuMy, Multiselect, nestedList, ModalBase},
    props: ['table', 'category'],
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            eTable: new eTable(this),
            nbi: {},
            local: {
                selectArr: [],
                saveArr: [],
                deleteArr: [],
                table: this.table,
                category: this.category,
            },
            fix_field: [
                {id: 'fix_name', name: 'Название'},
                {id: 'fix_marka', name: 'марка'},
                {id: 'fix_category', name: 'категория'},
                {id: 'fix_unit', name: 'ед.изм.'},
                {id: 'fix_brand', name: 'производитель'},
                {id: 'fix_article', name: 'артикул'},
                {id: 'fix_date', name: 'дата'},
            ],
            defaultObj: {
                name: '',
                marka: '',
                status: 0,

            },
            status: {
                loading: false,
            },
            state: {
                version: 1,
                activeRow: {},
                activeCategory: {},
                sortColumn: [],
                filterFields: [],
                activeFields: [],
                search: {
                    val: ''
                },
            },
            cookie: {
                main: {
                    version: 7,
                    catId: 1,
                    rowId: 1,
                    search: '',
                },
                activeF: [],
            },
            isMounted: false,
            items: [
                {name: 'фильтр по полю', link: 'filter_field'},
                {name: 'вставить', link: 'insert'},
                {name: '---'},
                {name: 'удалить', link: 'delete'},
                {name: 'отмена', link: 'cancel'},
            ]
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            // if (e === 'insert') this.showModalInsert();
            if (e.key === 'ctrlS') this.clickSave();
            if (e.key === 113) this.showModal();
            if (e.key === 'down') this.eTable.keyDownArrow();
            if (e.key === 'up') this.eTable.keyUpArrow();
            if (e.key === 'shiftDown') this.keyShiftDown();
            if (e.key === 'shiftUp') this.keyShiftUp();
            if (e.key === 'insert') this.showModalInsert();
            if (e.key === 27) Bus.$emit('clickBody');
        })

        Bus.$on('navbar_info', obj => this.nbi = obj);
        Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
        Bus.$on('dotSelect', () => this.eTable.onDotSelect());
        Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
        Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        Bus.$on('contextMenu_menu', (obj) => this.onContext(obj));
    },
    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {reload: true, save: true}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.state.activeRow = this.local.table[0];
        this.state.activeCategory = this.category[0];
        this.pageT.loadCookieMulti();
        // if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
        this.setLastSelectObject();
        Vue.nextTick(() => this.isMounted = true);
        // setTimeout(() => this.showModal(), 200);
    },
    methods: {
        setLastSelectObject() {
            this.local.selectArr = [];
            if (this.ffffTable.length > 0) {
                this.local.selectArr.push(this.ffffTable[0]);
                return;
            }
            let info = {
                unit: '',
                desc: '',
                category: this.state.activeCategory.id,
                brand: {
                    name: '',
                    article: '',
                },
            };
            let fields = {};
            this.state.activeCategory.prop.fields.forEach(item => {
                fields[item.id] = item.val;
            })

            let o = {
                name: this.state.activeCategory.name + ' !!изменить!!',
                id: uniqid(),
                marka: '',
                status: 0,
                prop: {
                    ver: 0,
                    info: info,
                    fields: fields,
                }
            };

            this.local.table.push(o);
        },
        onChange(e) {
            this.setLastSelectObject();
        },
        clickSort(obj, t) {
            if (t === '') {
                this.state.sortColumn.push({sortt: 'up', obj: obj});
            } else if (t === 'up') {
                let o = this.state.sortColumn.find(item => item.obj.id === obj.id);
                if (o) o.sortt = 'down';
            } else if (t === 'down') {
                let index = this.state.sortColumn.findIndex(item => item.obj.id === obj.id);
                if (index >= 0) this.state.sortColumn.splice(index, 1);
            }
        },
        getSortStatus(obj) {
            let o = this.state.sortColumn.find(item => item.obj.id === obj.id);
            if (o) return o.sortt;
            else return '';
        },
        addFieldToFilter(obj) {
            let val = this.getValById(obj.td.id, obj.tr);
            let index = this.state.filterFields.findIndex(item => item.id === obj.td.id);
            if (index < 0) {
                this.state.filterFields.push({id: obj.td.id, val: val, name: obj.td.name});
            } else {
                this.state.filterFields.splice(index, 1);
                this.state.filterFields.push({id: obj.td.id, val: val, name: obj.td.name});
            }
        },
        onContext(obj) {
            if (obj.link === 'filter_field') this.addFieldToFilter(obj.obj);
            if (obj.link === 'delete') this.clickRemoveSection();
            // if (obj.link === 'insert') this.clickAddSection(obj.obj);
            // // if (obj.link === 'edit') Bus.$emit('show_modalVspd', obj.obj);
        },
        clickRemoveSection() {
            this.local.selectArr.forEach(item => {
                if (!this.local.deleteArr.find(item2 => item2.id === item.id)) {
                    this.local.deleteArr.push(item);
                }
                let index = this.local.saveArr.findIndex(item3 => item3.id === item.id);
                if (index >= 0) this.local.saveArr.splice(index, 1);
            })
        },
        isDeleteFields(obj) {
            return !!this.local.deleteArr.find(item => item.id === obj.id)
        },
        keyShiftUp() {
            let index = this.fTable.findIndex(item => item.id === this.lastSelectObj.id);
            if (index <= 0) return;

            //todo js delete Array element
            let key = this.local.selectArr.findIndex(item => item.id === this.lastSelectObj.id);
            this.local.selectArr.push(this.fTable[index - 1]);
            // if (key < 0) return;

            // this.local.selectArr.splice(key, 1);
        },
        keyShiftDown() {
            let index = this.fTable.findIndex(item => item.id === this.lastSelectObj.id);
            if (index + 1 >= this.fTable.length) return;

            //todo js delete Array element
            let key = this.local.selectArr.findIndex(item => item.id === this.fTable[index].id);
            this.local.selectArr.push(this.fTable[index + 1]);
            // if (key < 0) return;
            // this.local.selectArr.splice(key, 1);
        },
        getValById(id, obj) {
            if (id === 'fix_name') return obj.name ?? '';
            if (id === 'fix_marka') return obj.marka ?? '';
            if (id === 'fix_date') return this.getDateInterval(obj);
            if (id === 'fix_category') return this.getCategoryPathById(obj.prop.info['category']) ?? '';
            if (id === 'fix_unit') return obj.prop.info['unit'] ?? '';
            if (id === 'fix_brand') return obj.prop.info.brand.name ?? '';
            if (id === 'fix_article') return obj.prop.info.brand.article ?? '';
            let val = obj.prop.fields[id] ?? '';
            if (val === true) val = 'да';
            if (val === false) val = '';
            return val;
        },
        getDateInterval(obj) {
            let dMs = new Date().getTime() - new Date(obj.updated_at).getTime(); // Разница в миллисекундах
            let dS = Math.round(dMs / 1000);

            let diff = Math.round(dS / 60);
            if (diff < 60) return diff + 'м';
            diff = Math.round(diff / 60);
            if (diff < 24) return diff + 'ч';
            diff = Math.round(diff / 24);
            if (diff < 30) return diff + 'д';
            diff = Math.round(diff / 30);
            if (diff < 12) return diff + 'М';
            diff = Math.round(diff / 12);
            return diff + 'г';
        },
        getCategoryById(id) {
            if (!id) return;
            let cat = this.local.category.find(item => parseInt(item.id) === parseInt(id));
            if (!cat) return;
            return cat;
        },
        getCategoryPathById(id) {
            let cat = this.getCategoryById(id);
            let str = '';
            if (cat) {
                str = '/' + cat.name + str;
                while (cat.id_parent !== 0) {
                    cat = this.getCategoryById(cat.id_parent);
                    if (cat) str = '/' + cat.name + str;
                }
            }
            return str;
        },
        postLoadCookie() {
            this.state.activeCategory = this.category.find(item => item.id === this.cookie.main.catId);
            if (!this.state.activeCategory) this.state.activeCategory = this.category[0];
            // this.state.activeRow = this.local.table.find(item => item.id === this.cookie.main.rowId);
            // if (!this.state.activeRow) this.state.activeRow = this.local.table[0];
            // this.state.search.val = this.cookie.main.search;

            this.cookie.activeF.forEach(item => {
                let t = {};
                let cat = this.category.find(i => i.id === item.id);
                if (cat) {
                    t = {id: cat.id, fields: []};
                    item.fields.forEach(idd => {
                        let f = cat.prop.fields.find(i => i.id === idd);
                        if (!f) f = this.fix_field.find(i => i.id === idd);
                        if (f) t.fields.push(f);
                    })
                }
                if (t) this.state.activeFields.push(t);
            })
        },
        preSaveCookie() {
            this.cookie.main.catId = this.state.activeCategory.id;
            // this.cookie.main.rowId = this.state.activeRow.id;
            // this.cookie.main.search = this.state.search.val;

            this.cookie.activeF = [];
            this.state.activeFields.forEach(item => {
                if (item.id && item.fields.length > 0) this.cookie.activeF.push({id: item.id, fields: item.fields.map(i => i.id)})
            })
        },
        clickSwFilterField(obj) {
            let i = this.state.filterFields.findIndex(item => item.id === obj.id);
            if (i >= 0) this.state.filterFields.splice(i, 1);
            this.pageT.updCookieMulti();
        },
        clickSwField(obj) {
            let i = this.activeFields.fields.findIndex(item => item.id === obj.id);
            if (i < 0) this.activeFields.fields.push(obj); else this.activeFields.fields.splice(i, 1);
            this.pageT.updCookieMulti();
        },
        isActiveField(obj) {
            let i = this.activeFields.fields.findIndex(item => item.id === obj.id);
            return i >= 0;
        },
        clickDot(type) {
            if (type === 'select') Bus.$emit('dotSelect')
            else if (type === 'un_select') Bus.$emit('dotUnSelect')
            else if (type === 'inverse') Bus.$emit('dotInverse')
        },
        init() {
            let res = [];
            this.local.selectArr.forEach(item => {
                if (this.local.saveArr.findIndex(item2 => item2.id === item.id) < 0) this.local.saveArr.push(item);
            })
            this.local.table.forEach(item => {
                let l = this.local.selectArr.find(item2 => item2.id === item.id);
                if (l) res.push(l); else res.push(item);
            })
            this.local.table = res;
        },
        //todo js multiselect custom search
        customSearch(query) {
            if (query === '') {
                this.local.category = this.category;
            }
            let i = 0;
            this.local.category = this.category.filter(item => {
                let res = true;
                query.split(' ').forEach(value => {
                    let s = item.name;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        showModal() {
            if (this.local.selectArr.length === 0) return;
            if (this.local.selectArr.length > 1) {
                let k = JSON.parse(JSON.stringify(this.local.selectArr.filter(item => item.prop.info.category === this.eTable.lastSelectObj().prop.info.category)));
                this.local.selectArr = [];
                k.forEach(item => this.eTable.clickCtrlRow(item));
            }
            Bus.$emit('show_modalBase')
        },
        showModalInsert() {
            // if (this.local.selectArr.length === 0) return;
            // if (this.local.selectArr.length > 1) {
            //     let k = JSON.parse(JSON.stringify(this.local.selectArr.filter(item => item.prop.info.category === this.eTable.lastSelectObj().prop.info.category)));
            //     this.local.selectArr = [];
            //     k.forEach(item => this.eTable.clickCtrlRow(item));
            // }
            Bus.$emit('showI_modalBase')
        },
        clickSave() {
            Bus.$emit('localSave');
            this.pageT.updCookieMulti();
            this.pageT.clickSave('/base/save', {table: this.local.saveArr, delete_arr: this.local.deleteArr}, '_save');
            Bus.$once('zResponse_save', response => {
                this.local.table.length = 0;
                response.table.forEach(item => this.local.table.push(item));
                this.local.saveArr.length = 0;
                Bus.$emit('updTable');
                Bus.$emit('updSelectArr');
            });
        },
    },
    computed: {
        fTable: function () {
            if (this.state.sortColumn.length === 0) return this.ffTable.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            return this.ffTable.sort((a, b) => {
                let res = 0;
                let aa = a;
                let bb = b;
                let path = '';
                let tVal = '';
                this.state.sortColumn.forEach(item => {
                    path = '';
                    if (item.obj.id === 'fix_name') path = 'name';
                    if (item.obj.id === 'fix_marka') path = 'marka';
                    if (item.obj.id === 'fix_unit') path = 'prop.info.unit';
                    if (item.obj.id === 'fix_desc') path = 'prop.info.desc';
                    if (item.obj.id === 'fix_category') path = 'prop.info.category';
                    if (item.obj.id === 'fix_brand') path = 'prop.info.brand.name';
                    if (item.obj.id === 'fix_article') path = 'prop.info.brand.article';
                    if (item.obj.id === 'fix_date') {
                        path = 'updated_at';
                        tVal = 'date';
                    }
                    if (path === '') {
                        path = 'prop.fields.' + item.obj.id;
                        tVal = item.obj.type;
                    }

                    if (item.sortt === 'down') {
                        aa = b;
                        bb = a;
                    } else {
                        aa = a;
                        bb = b;
                    }

                    for (let p of path.split('.')) {
                        aa = aa[p] ?? '';
                        bb = bb[p] ?? '';
                    }

                    if (tVal === 'bool') {
                        let r = aa > bb ? 1 : -1;
                        res += r;
                    } else if (tVal === 'float' || tVal === 'int') {
                        let r = aa > bb ? 1 : -1;
                        res += r;
                    } else if (tVal === 'date') {
                        let r = new Date(aa) > new Date(bb) ? -1 : 1;
                        res += r;
                    } else res += aa.localeCompare(bb);
                })
                return res;
            });
        },
        ffTable: function () {
            if (this.state.filterFields.length === 0) return this.fffTable;
            return this.fffTable.filter(item => {
                let res = true;
                let path = '';
                this.state.filterFields.forEach(value => {
                    let s = item;
                    path = '';
                    if (value.id === 'fix_name') path = 'name';
                    if (value.id === 'fix_marka') path = 'marka';
                    if (value.id === 'fix_unit') path = 'prop.info.unit';
                    if (value.id === 'fix_desc') path = 'prop.info.desc';
                    if (value.id === 'fix_category') path = 'prop.info.category';
                    if (value.id === 'fix_brand') path = 'prop.info.brand.name';
                    if (value.id === 'fix_article') path = 'prop.info.brand.article';
                    if (value.id === 'fix_date') {
                        path = 'updated_at';
                    }
                    if (path === '') {
                        path = 'prop.fields.' + value.id;
                    }

                    for (let p of path.split('.')) {
                        s = s[p] ?? '';
                    }
                    res = res && s.toString().toLowerCase().indexOf(value.val.toString().toLowerCase()) !== -1;
                });
                return res;
            });
        },
        fffTable: function () {
            if (this.state.search.val === '') return this.ffffTable;
            return this.ffffTable.filter(item => {
                let res = true;
                this.state.search.val.split(' ').forEach(value => {
                    let s = item.name + item.marka + item.prop.info.brand.name + item.prop.info.brand.article;
                    if (value[0] === '-') {
                        if (value.slice(1) !== '') res = res && s.toLowerCase().indexOf(value.slice(1).toLowerCase()) === -1;
                    } else {
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    }
                });
                return res;
            });
        },
        ffffTable: function () {
            if (this.state.activeCategory.id === undefined || this.state.activeCategory.id === 1) return this.local.table;
            else return this.local.table.filter(item => item.prop.info.category.toString() === this.state.activeCategory.id.toString());
        },
        propFields: {
            get() {

            },
            set(val) {

            }
        },
        activeFields: function () {
            let catId = this.state.activeCategory.id;
            let f = this.state.activeFields.find(item => item.id === catId);
            if (!f) {
                f = {id: catId, fields: []};
                this.state.activeFields.push(f);
            }
            return this.state.activeFields.find(item => item.id === catId);
        },
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
    },
    watch: {
        'fTable': {
            handler: function (newVal, oldVal) {
                this.eTable.clearSelectArr();
            },
            deep: false
        },
        'state.activeCategory': function (newVal, oldVal) {
            if (!this.isMounted) return
            // this.state.cookie.catId = this.state.activeCategory.id;
            this.pageT.updCookieMulti();
        },
        'state.activeFields': function (newVal, oldVal) {
            if (!this.isMounted) return
            this.pageT.updCookieMulti();
        },
        'state.search.val': function (newVal, oldVal) {
            if (!this.isMounted) return
            this.pageT.updCookieMulti();
        },
    },
    filters: {},
    directives: {}
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/variables';

svg.fa-sort {
    opacity: 0.5;
    cursor: pointer;
}

svg.fa-sort-up, svg.fa-sort-down {
    cursor: pointer;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

table {
    th {
        padding: 0.5rem;
    }
}

table.table.in_td {
    td {
        text-align: center;
        vertical-align: middle;
    }

    th {
        border: none;
        background: none;
        padding: 0;

    }

    tr {
        border: none;
        background: none;
        height: 0px;

        &.hover:hover {
            background-color: lighten($primary, 20%);
        }
    }

    tbody tr:first-child {
        td {
            border: none;
        }
    }
}
</style>
