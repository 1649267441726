var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "card-body" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.state.search.val,
              expression: "state.search.val",
              modifiers: { trim: true },
            },
          ],
          ref: "search",
          staticClass: "form-control mb-2",
          domProps: { value: _vm.state.search.val },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.state.search, "val", $event.target.value.trim())
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "table table-sm" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.fTable, function (val) {
              return [
                _c(
                  "tr",
                  {
                    key: val.id,
                    class: { "bg-primary-l": _vm.eTable.isSelect(val) },
                    on: {
                      click: [
                        function ($event) {
                          if (!$event.ctrlKey) return null
                          $event.preventDefault()
                          return _vm.eTable.clickCtrlRow(val)
                        },
                        function ($event) {
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          )
                            return null
                          return _vm.eTable.clickRow(val)
                        },
                      ],
                    },
                  },
                  [
                    _c("td", { staticClass: "text-left" }, [
                      _vm._v(_vm._s(val.name2)),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(val.child.length))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(val.es.pu))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(val.es.cos))]),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("modal-potr", {
        attrs: { "modal-id": "modalPotr1", "p-local": _vm.local },
        on: { updInit: _vm.init },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "w-5", attrs: { scope: "col" } }, [
          _vm._v("name2"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("количество"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("Ру"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("cos"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }