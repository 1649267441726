<template>
    <div class="h-100">
        <div class="container-fluid m1">
            <div class="row justify-content-center py-5">
                <div class="col-12 col-sm-8 col-md-6 col-lg-3">

                    <div v-if="isLoginForm" class="card pb-3">
                        <div class="card-header bg-white">Вход в личный кабинет</div>
                        <div class="card-body">
                            <p class="mb-0 font-weight-bold">email</p>
                            <input type="email" class="form-control" v-model="email" @keyup="checkEmail">
                            <p class="mt-3 mb-0 font-weight-bold">пароль</p>
                            <input type="password" class="form-control" v-model="pass" @keyup="checkPass" @keyup.enter="clickLogin">
                        </div>
                        <p class="mt-2 text-center">
                            <label style="cursor: pointer"><input type="checkbox" class="mr-2" v-model="isRemember">запомнить меня</label>
                        </p>
                        <p v-if="isError" class="v-string text-center">пароль или email не верен, повторите попытку входа</p>
                        <button class="btn btn-primary w-50 align-self-center" @click="clickLogin">
                            <i v-if="isLoading" class="fa fa-spinner fa-spin fa-fw"></i>
                            ВХОД
                        </button>
<!--                        <p class="text-center mt-4">или можете <a class="font-weight-bold" @click="clickShowRegistrationForm">зарегистрироваться</a></p>-->
<!--                        <p v-if="isError" class="text-center mt-0">или <a class="font-weight-bold" @click="clickShowRepairForm">сбросить</a> пароль</p>-->
                    </div>

                    <div v-if="isRegistrationForm" class="card">
                        <div class="card-header">Регистрация</div>
                        <div class="card-body">
                            <p class="mb-0 font-weight-bold">имя</p>
                            <p v-if="!isValidName && name!==''" class="v-string">имя должно содержать не менее 3х символов</p>
                            <input type="text" class="form-control" v-model="name" @keyup="checkName">
                            <p class="mb-0 mt-3 font-weight-bold">email</p>
                            <p v-if="!isValidEmail && email!==''" class="v-string">введите корректный email</p>
                            <input type="email" class="form-control" v-model="email" @keyup="checkEmail">

                            <p class="mt-3">
                                <input type="checkbox" class="mr-2" v-model="isPolitic">
                                Вы согласны с
                                <a target="_blank" href="/">Политикой конфиденциальности</a>
                                на обработку персональных данных
                            </p>
                        </div>
                        <p v-if="isError" class="v-string text-center">Указаный email уже зарегистрирован в нашей системе</p>
                        <button class="btn btn-primary mb-4 w-50 align-self-center" v-bind:class="{disabled:!isValidRegistration}" @click="clickRegistration">
                            <i v-if="isLoading" class="fa fa-spinner fa-spin fa-fw"></i>
                            регистрация
                        </button>
                        <p v-if="isError" class="text-center my-0">вы можете попробовать <a class="font-weight-bold" @click="clickShowLoginForm">войти с паролем</a></p>
                        <p v-if="isError" class="text-center mt-0">или <a class="font-weight-bold" @click="clickShowRepairForm">сбросить</a> пароль</p>
                    </div>

                    <div v-if="isEndRegistration" class="card">
                        <div class="card-header">Регистрация прошла успешно</div>
                        <div class="card-body text-center">
                            <p class="">Для подтверждения email, на Ваш почтовый адрес <span class="font-weight-bold">{{email}}</span> было выслано письмо.
                                На всякий случай, не забудьте поверить папку СПАМ, иногда письма попадают туда.</p>
                            <a class="font-weight-bold mb-3" :href="nbi.url.prev">Вернуться на прошлую страницу сайта</a>
                        </div>
                    </div>

                    <div v-if="isEmailVerified" class="card">
                        <div class="card-header">Email подтверждён</div>
                        <div class="card-body text-center">
                            <p>Пароль от личного кабинета выслан Вам на почту</p>
                        </div>
                    </div>

                    <div v-if="isResetPasswordForm" class="card">
                        <div class="card-header">Сброс пароля</div>
                        <div class="card-body">
                            <p>На указаный email, будет выслано письмо с новым паролем</p>
                            <p class="mb-0 mt-3 font-weight-bold">email</p>
                            <p v-if="!isValidEmail && email!==''" class="v-string">введите корректный email</p>
                            <input type="email" class="form-control" v-model="email" @keyup="checkEmail">
                            <div class="text-center mt-4">
                                <p v-if="isError" class="v-string text-center mb-1">{{isError}}</p>
                                <button v-if="lockReset<4" class="btn btn-primary w-50 align-self-center" v-bind:class="{disabled:!isValidEmail}" @click="clickResetPassword">
                                    <i v-if="isLoading" class="fa fa-spinner fa-spin fa-fw"></i>
                                    отправить
                                </button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: ['isEmailVerified'],
        data: function () {
            return {
                isError: false,
                isLoading: false,
                isLoginForm: true,
                isRegistrationForm: false,
                isResetPasswordForm: false,
                isEndRegistration: false,
                isEmailExist: false,
                lockReset: 0,

                isRemember: false,
                isPolitic: false,
                isValidEmail: false,
                isValidPass: false,
                isValidName: false,
                isValidRePass: false,
                name: '',
                email: '',
                pass: '',
                repass: '',
                nbi: {},
            }
        },
        created: function () {
            Bus.$on('navbar_info', obj => this.nbi = obj);
        },
        mounted() {
            Bus.$emit('navbar', {command: 'get_navbar_info'});
            this.resetAllForm();
            if (!this.isEmailVerified) this.isLoginForm = true;
        },
        methods: {
            clickResetPassword() {
                this.isLoading = true;
                Bus.$emit('zPost', {
                    url: '/reset_password_send',
                    obj: {
                        email: this.email,
                    },
                    flash: false,
                    suffix:'_reset_password_send',
                });
                Bus.$once('zResponse_reset_password_send', response => {
                    this.isLoading = false;
                    this.lockReset = 5;
                    location.href = this.nbi.url.prev;
                });
                Bus.$once('zError_reset_password_send', response => {
                    this.isLoading = false;
                    this.isError = response.error;
                    this.lockReset++;
                });
            },
            clickLogin() {
                // if (!this.isValidLogin) return false;
                this.isLoading = true;
                this.isError = false;
                Bus.$emit('zPost', {
                    url: '/login_',
                    obj: {
                        email: this.email,
                        pass: this.pass,
                        remember: this.isRemember,
                    },
                    flash: false,
                    suffix:'_login_'
                });
                Bus.$once('zResponse_login_', response => {
                    this.isLoading = false;
                    location.href = this.nbi.url.prev;
                });
                Bus.$once('zError_login_', () => {
                    this.isLoading = false;
                    this.isError = true;
                });
            },
            clickRegistration() {
                this.isLoading = true;
                this.isError = false;
                Bus.$emit('zPost', {
                    url: this.nbi.url.main + '/registration',
                    obj: {
                        name: this.name,
                        email: this.email,
                        pass: this.pass,
                    },
                    flash: false,
                    suffix:'_registration'
                });
                Bus.$once('zResponse_registration', response => {
                    this.isLoading = false;
                    if (response.checkEmail) {
                        this.isError = true;
                    } else {
                        this.resetAllForm();
                        this.isEndRegistration = true;
                    }
                });
            },
            clickShowRepairForm() {
                this.resetAllForm();
                this.isResetPasswordForm = true;
            },
            clickShowLoginForm() {
                this.resetAllForm();
                this.isLoginForm = true;
            },
            clickShowRegistrationForm() {
                this.resetAllForm();
                this.isRegistrationForm = true;
            },
            checkEmail() {
                let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.isValidEmail = re.test(this.email);

            },
            checkName() {
                let re = /^.{3,}$/;
                this.isValidName = re.test(this.name);
            },
            checkPass() {
                this.isValidPass = true;
            },
            checkRePass() {
                this.isValidRePass = this.pass === this.repass;
            },
            resetAllForm() {
                this.isLoginForm = this.isRegistrationForm = this.isResetPasswordForm = this.isEndRegistration = this.isEmailExist = this.isError = false;
                this.pass = '';
            }
        },
        computed: {
            isValidRegistration: function () {
                return this.name !== '' && this.isValidEmail && this.isPolitic;
            },
            isValidLogin: function () {
                return this.isValidEmail && this.isValidPass;
            }
        },
    }
</script>


<style scoped>
    .v-string {
        color: red;
        font-size: small;
        line-height: 1.2;
        margin-bottom: 0;
    }

    a {
        color: dodgerblue;
        cursor: pointer;
    }
</style>
