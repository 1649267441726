<template>
    <div :id="modalId" class="h-100 ddiv" role="dialog">
        <div class="card h-100">
            <div class="card-body">

                <div class="d-flex justify-content-between">
                    <div class="w-100 mr-2" style="position: relative">
                        <input class="form-control w-100" v-model="search" ref="v_focus_2">
                        <font-awesome-icon icon="fa-solid fa-eraser" size="lg" @click="search=''" title="очистить"/>
                    </div>
                </div>
                <div class="mb-3">
                    <template v-for="val in search2">
                        <span @click="clickRemoveTag(val)" style="cursor: pointer" class="badge ml-2" :style="{'background-color': val.color}">{{val.name}}</span>
                    </template>
                </div>

                <div v-if="this.local.table.length === 0" class="text-center p-5">
                    <font-awesome-icon v-if="true" icon="fa-solid fa-circle-notch" size="3x" class="mt-1 text-secondary" title="загрузка данных..." spin/>
                </div>

                <div v-for="val in filteredTable" :key="'b_'+val.id" class="card my-1 myRow">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="nav-item dropdown p-0">
                                <font-awesome-icon icon="fa-regular fa-square-caret-down" class="nav-link dropdown-toggle pl-0" data-toggle="dropdown" style="cursor: pointer;"/>
                                <div class="dropdown-menu">
                                    <button class="dropdown-item" @click="clickInsertBlock(val,'replaceText')">заменить текстовую часть</button>
                                    <button class="dropdown-item" @click="clickInsertBlock(val,'replaceGVariables')">заменить глобальные переменные</button>
                                    <button class="dropdown-item" @click="clickInsertBlock(val,'replaceSetting')">заменить настройки проекта</button>
                                    <button class="dropdown-item" @click="clickInsertBlock(val,'replaceInfo')">заменить информацию о проекте</button>
                                    <button class="dropdown-item" @click="clickInsertBlock(val,'replaceTextDwg')">заменить листы dwg</button>
                                    <button class="dropdown-item" @click="clickInsertBlock(val,'replaceData')">заменить расчётную структуру</button>
                                </div>
                            </div>
                            <span class="font-weight-bold">{{val.name}}</span>
                            <span @click="clickAddTag(val,'org')" style="cursor: pointer" class="badge ml-2 bg-info">{{val.org.name}}</span>
                            <template v-for="tt in val.tags">
                                <span @click="clickAddTag(tt,'tag')" style="cursor: pointer" class="badge ml-2" :style="{'background-color': tt.color}">{{tt.name}}</span>
                            </template>
                            <span class="ml-auto">{{getDate(val.created_at)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import {page} from "./tools";
    import moment from "moment";


    export default {
        name: 'importDivProject',
        components: {Multiselect},
        props: {
            modalId: {type: String, default: 'importDivProject',},
            isShow: {type: Boolean, default: false,},
        },
        data: function () {
            return {
                pageT: new page(this),
                rand: 1,
                local: {
                    table: [],
                },
                state: {},
                status: {
                    loading: false,
                },
                search: '',
                search2: [],
                listFoldPage: ['/f/table_trn', '/f/table_od', '/f/table_vrch', '/f/table_pps', '/f/table_vspd', '/f/table_so'],

            }
        },
        created: function () {
            // Bus.$on('show_' + this.modalId, () => this.showModal());
            Bus.$on('syncSearchA', (obj) => this.search = obj.search);
        },
        mounted() {
            if (this.isShow) this.showModal();
        },
        methods: {
            clickRemoveTag(obj) {
                //todo js delete Array element
                let key = this.search2.findIndex(item => item.id === obj.id && item.ttype === obj.ttype);
                if (key < 0) return;
                this.search2.splice(key, 1);
            },
            clickAddTag(obj, ttype) {
                let o = {};
                if (ttype === 'project') {
                    o = {id: obj.project_id, name: obj.name, ttype: ttype, color: '#e9d8a6'};
                }
                if (ttype === 'org') {
                    o = obj.org;
                    o.ttype = ttype;
                    o.color = '#e9d8a6';
                }
                if (ttype === 'tag') {
                    o = obj;
                    o.ttype = ttype;
                }
                this.search2.push(o);

            },
            clickInsertBlock(obj, ttype) {
                if (ttype === 'replaceInfo') this.postReplaceInfo(obj);
                else if (ttype === 'replaceSetting') this.postReplaceSetting(obj);
                else if (ttype === 'replaceText') this.postReplaceText(obj);
                else if (ttype === 'replaceGVariables') this.postReplaceGVariables(obj);
                else if (ttype === 'replaceData') this.postReplaceData(obj);
                else if (ttype === 'replaceTextDwg') this.postReplaceTextDwg(obj);
            },
            postReplaceData(obj) {
                this.pageT.clickSave('/import/replace_data', obj, '_replace_data', false)
                Bus.$on('zResponse_replace_data', response => {
                    Bus.$emit('updTable', response);
                });
            },
            postReplaceGVariables(obj) {
                this.pageT.clickSave('/import/replace_g_variables', obj, '_replace_g_variables', false)
                Bus.$on('zResponse_replace_g_variables', response => {
                    Bus.$emit('updTable', response);
                });
            },
            postReplaceInfo(obj) {
                this.pageT.clickSave('/import/replace_info', obj, '_replace_info', false)
                Bus.$on('zResponse_replace_info', response => {
                    Bus.$emit('updTable', response);
                });
            },
            postReplaceSetting(obj) {
                this.pageT.clickSave('/import/replace_setting', obj, '_replace_setting', false)
                Bus.$on('zResponse_replace_setting', response => {
                    Bus.$emit('updTable', response);
                    Bus.$emit('updSetting', response);
                });
            },
            postReplaceText(obj) {
                this.pageT.clickSave('/import/replace_text', obj, '_replace_text', false)
                Bus.$on('zResponse_replace_text', response => {
                    Bus.$emit('updTable', response);
                });
            },
            postReplaceTextDwg(obj) {
                this.pageT.clickSave('/import/replace_text_dwg', obj, '_replace_text_dwg', false)
                Bus.$on('zResponse_replace_text_dwg', response => {
                    Bus.$emit('updTable', response);
                });
            },
            showModal() {
                // $('#' + this.modalId).modal('show');
                setTimeout(() => this.$refs.v_focus_2.focus(), 500);
                if (this.local.table.length === 0) this.load();
            },
            load() {
                this.pageT.clickSave('/import/load_list_project', {}, '_load_table', false)
                Bus.$on('zResponse_load_table', response => {
                    this.local.table = response.table;
                    import("../../../sass/_rtl.css")
                });
            },
            getDate(date) {
                return moment(date).format('DD.MM.YYYY');
            },
        },
        computed: {
            filteredTable: function () {
                return this.filteredTable2.filter(item => {
                    let res = true;
                    this.search.split(' ').forEach(value => {
                        let s = item.name + item.org.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    return res;
                });
            },
            filteredTable2: function () {
                if (this.search2.length === 0) return this.local.table;
                return this.local.table.filter(item => {
                    let res = true;
                    this.search2.forEach(value => {
                        if (value.ttype === 'project') res = res && item.id === value.id;
                        if (value.ttype === 'org') res = res && item.org.id === value.id;
                        if (value.ttype === 'tag') res = res && !!item.tags.find(ii2 => ii2.id === value.id);
                    });
                    return res;
                });
            },
        },
        watch: {
            isShow(newVal, oldVal) {
                this.showModal();
            }
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }

    .myRow {
        &:hover {
            background-color: lighten($primary, 50%);
        }
    }
</style>
