<template>
    <draggable
        tag="div"
        class="item-container"
        :list="list"
        :value="value"
        @input="emitter"
        handle=".handle"
        animation="150"
        group="nested"
        :fallback-on-body="true"
        :swap-threshold="0.5"
        ghost-class="ghost"
        filter=".no-drag"
        @choose="choose"
        @unchoose="unchoose"
        @end="end"
        :move="move"
        @change="onChange"
    >
        <div v-for="el in realValue" class="item-group" :key="el.id">
            <div class="item" :class="{'bg-danger':level>2}">
                <div class="row mb-0">
                    <div class="col-1 pt-2 text-nowrap">
                        <i class="far fa-file handle"></i>
                        <span class="font-weight-bold ml-1 name" @click="clickName(el)" :class="{active:el.id===activeId}">{{ el.stamp.list_name}}</span>
                    </div>
                </div>
            </div>
            <nested-list-page class="item-sub" :list="el.child" :level="level + 1" :id="el.id" :active-id="activeId"/>
        </div>
    </draggable>
</template>

<script>

    import draggable from 'vuedraggable'
    import Multiselect from 'vue-multiselect'

    export default {
        name: "nested-list-page",
        components: {draggable, Multiselect},
        props: {
            value: {
                required: false,
                type: Array,
                default: null
            },
            list: {
                required: false,
                type: Array,
                default: null
            },
            level: {
                type: Number,
                default: 1,
            },
            id: {
                required: false,
                type: String,
                default: '0'
            },
            activeId: {
                required: false,
                type: String,
                default: '0'
            },
        },
        data: function () {
            return {
                isMove: true,
                oldValue: this.value,
                lValue: this.value,
                stamp_size: [
                    {name: 'Большой штамп', s_name: 'Б'},
                    {name: 'Средний штамп', s_name: 'С'},
                    {name: 'Маленький штамп', s_name: 'М'},
                    {name: 'Без штампа', s_name: 'БШ'},
                    {name: 'Без всего', s_name: 'БВ'},
                ],

            }
        },
        methods: {
            clickName(obj) {
                Bus.$emit('clickPage', obj);
            },
            getStampSize(obj) {
                let f = this.stamp_size.find(item => obj.stamp.type === item.name);
                if (f === null) return '';
                return f.s_name;
            },
            labelSearch(list, opt) {
                let obj = list.find(item => item.id === opt);
                if (obj === undefined) return '';
                return obj.name;
            },
            emitter(value) {
                this.$emit("input", value);
            },
            as(a, b) {
                console.log(a);
                console.log(b);
            },
            choose() {
                console.log('choose');
            },
            unchoose() {
                console.log('unchoose');
            },
            end() {
                console.log('end');
                // console.log(this.level);
                // if (this.level > 1) return false;
                // console.log(this.oldValue);
                this.$emit("end");
            },
            move() {
                return this.isMove;
            },
            onChange() {
                this.realValue.forEach(item => item.parent_id = this.id);
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            realValue() {
                return this.value ? this.value : this.list;
            },

        },
    }
</script>

<style scoped lang="scss">
    .handle {
        /*float: left;*/
        /*padding-top: 8px;*/
        /*padding-bottom: 8px;*/
        opacity: 0.3;
        cursor: move;
    }

    .ghost {
        opacity: 0.3;
        background: #c8ebfb;
    }

    .item-container {
        margin: 0;
    }

    .item {
        padding: 0.3rem;
        border-bottom: solid #bbbaba 1px;
    }

    .item-sub {
        margin: 0 0 0 1rem;
    }

    .type-list {
        opacity: 0.5;
        cursor: pointer;

        &.active {
            font-weight: bold;
            opacity: 1;
        }
    }

    span.name {
        cursor: pointer;

        &.active {
            background-color: #a8c9a8;
        }

    }
</style>
