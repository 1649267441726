var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.state.swOnOpt
    ? _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("label", { staticClass: "w-100 mt-2" }, [
                _c("span", { staticClass: "small" }, [
                  _vm._v("количество листов в файле"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.cPage.options.count_page,
                      expression: "cPage.options.count_page",
                      modifiers: { number: true },
                    },
                    { name: "focus", rawName: "v-focus" },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "количество листов в файле" },
                  domProps: { value: _vm.cPage.options.count_page },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.cPage.options,
                        "count_page",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "w-100 mt-2" }, [
                _c("span", { staticClass: "small" }, [
                  _vm._v("название листа"),
                ]),
                _vm._v(" "),
                _c(
                  "small",
                  {
                    staticClass: "btn-link d-inline-block ml-2",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        _vm.cPage.stamp.list_name = _vm.cPage.link_content.name
                      },
                    },
                  },
                  [_vm._v("auto")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cPage.stamp.list_name,
                      expression: "cPage.stamp.list_name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "название листа" },
                  domProps: { value: _vm.cPage.stamp.list_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.cPage.stamp,
                        "list_name",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "w-100 mt-2" }, [
                _c("span", { staticClass: "small" }, [
                  _vm._v("имя файла при экспорте"),
                ]),
                _vm._v(" "),
                _c(
                  "small",
                  {
                    staticClass: "btn-link d-inline-block ml-2",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        _vm.cPage.options.file_name =
                          "_" + _vm.cPage.link_content.name
                      },
                    },
                  },
                  [_vm._v("auto")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cPage.options.file_name,
                      expression: "cPage.options.file_name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "имя файла при экспорте" },
                  domProps: { value: _vm.cPage.options.file_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.cPage.options,
                        "file_name",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "apple-switch_2 mt-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cPage.options.isOd,
                      expression: "cPage.options.isOd",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.cPage.options.isOd)
                      ? _vm._i(_vm.cPage.options.isOd, null) > -1
                      : _vm.cPage.options.isOd,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.cPage.options.isOd,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cPage.options,
                              "isOd",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cPage.options,
                              "isOd",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.cPage.options, "isOd", $$c)
                      }
                    },
                  },
                }),
                _c("i"),
                _vm._v("учитывать в содержании"),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "w-100 mt-2" }, [
                _c("label", { staticClass: "apple-switch_2 mt-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cPage.stamp.suffix.auto,
                        expression: "cPage.stamp.suffix.auto",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.cPage.stamp.suffix.auto)
                        ? _vm._i(_vm.cPage.stamp.suffix.auto, null) > -1
                        : _vm.cPage.stamp.suffix.auto,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.cPage.stamp.suffix.auto,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cPage.stamp.suffix,
                                "auto",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cPage.stamp.suffix,
                                "auto",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.cPage.stamp.suffix, "auto", $$c)
                        }
                      },
                    },
                  }),
                  _c("i"),
                  _vm._v("суффикс автоматически"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.cPage.stamp.suffix.value,
                      expression: "cPage.stamp.suffix.value",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { disabled: _vm.cPage.stamp.suffix.auto },
                  domProps: { value: _vm.cPage.stamp.suffix.value },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.cPage.stamp.suffix,
                        "value",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }