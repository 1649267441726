<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal">
        <div class="modal-dialog" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span>настройки поля</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <label class="mb-0 mt-2">
                        <span class="small">{{optNameRu.desc}}</span>
                    </label>
                    <multiselect
                        v-model="optNameRu.val"
                        :placeholder="optNameRu.desc"
                        :options="local.listRu"
                        :option-height="104"
                        :show-labels="false"
                        :searchable="true"
                        :close-on-select="true"
                        :allow-empty="false"
                    >
                    </multiselect>

                    <label class="mb-0 mt-2 w-100">
                        <span class="small">{{optF.desc}}</span>
                        <input class="form-control" v-model="optF.val"/>
                    </label>
                </div>
                <div class="modal-footer">
                    <div class="text-right">
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                        <button type="button" class="btn btn-primary" @click="clickSave(null)">сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    //todo js modal modalDraggable
    import {modalDraggable, page} from "./tools";


    export default {
        name: 'modal-es-ru',
        components: {Multiselect},
        props: {
            modalId: {type: String, default: 'modal',},
        },
        data: function () {
            return {
                //todo js modal add page module
                pageT: new page(this),
                rand: 1,
                local: {
                    listRu: [],
                    listField: [],
                },
                state: {
                    obj: null,
                },
                status: {
                    loading: false,
                },
                templateObj: {
                    name: '',
                    desc: '',
                }
            }
        },
        created: function () {
            Bus.$on('show_' + this.modalId, (obj) => this.showModal(obj));
        },
        mounted() {
            new modalDraggable(this);
        },
        methods: {
            showModal(obj) {
                if (obj) this.state.obj = obj;
                // if (obj) this.state.obj = JSON.parse(JSON.stringify(obj));
                $('#' + this.modalId).modal('show');
                // if (obj) setTimeout(() => this.$refs.v_focus.focus(), 500);
                if (this.local.listRu.length === 0) this.load();
            },
            load() {
                this.pageT.clickSave('/global_variables/load_modal_es_ru', {}, '_load_modal_es_ru', false)
                Bus.$on('zResponse_load_modal_es_ru', response => {
                    this.local.listRu = response.listRu;
                });
            },
            closeModal() {
                $('#' + this.modalId).modal('hide');
            },
            clickSave(obj) {
                // Bus.$emit('updOpt', this.state.obj);
                this.closeModal();
            },
        },
        computed: {
            optNameRu: function () {
                if (!this.state.obj) return {};
                return this.state.obj.opt.find(item => item.name === 'nameRu');
            },
            optF: function () {
                if (!this.state.obj) return {};
                return this.state.obj.opt.find(item => item.name === 'f');
            }
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    svg.fa-eraser {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }
</style>
