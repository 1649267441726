var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 ddiv", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "card h-100" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "div",
                {
                  staticClass: "w-100 mr-2",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search",
                      },
                    ],
                    ref: "v_focus",
                    staticClass: "form-control w-100",
                    domProps: { value: _vm.search },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.search = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: "fa-solid fa-eraser",
                      size: "lg",
                      title: "очистить",
                    },
                    on: {
                      click: function ($event) {
                        _vm.search = ""
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            this.local.table.length === 0
              ? _c(
                  "div",
                  { staticClass: "text-center p-5" },
                  [
                    true
                      ? _c("font-awesome-icon", {
                          staticClass: "mt-1 text-secondary",
                          attrs: {
                            icon: "fa-solid fa-circle-notch",
                            size: "3x",
                            title: "загрузка данных...",
                            spin: "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.filteredTable, function (val, index) {
              return _c(
                "div",
                { key: "a_" + index, staticClass: "card my-1 myRow" },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-start align-items-center",
                      },
                      [
                        !!_vm.currentObj
                          ? _c(
                              "span",
                              [
                                _vm.withHtml
                                  ? _c("font-awesome-icon", {
                                      staticClass: "mr-2",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: { icon: "fa-solid fa-arrow-left" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickInsertBlockHTML(val)
                                        },
                                      },
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass: "mr-2",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: { icon: "fa-solid fa-arrow-left" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickInsertBlock(val)
                                        },
                                      },
                                    }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(val.name)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "small" }, [
                      _vm._v(_vm._s(val.id)),
                    ]),
                    _vm._v(" "),
                    _c("div", { domProps: { innerHTML: _vm._s(val.value) } }),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }