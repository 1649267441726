var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "item-container",
      attrs: {
        tag: "div",
        list: _vm.list,
        value: _vm.value,
        handle: ".handle",
        animation: "150",
        "fallback-on-body": true,
        "swap-threshold": 0.5,
        "scroll-sensitivity": 100,
        "fallback-tolerance": 1,
        "force-fallback": true,
        "ghost-class": "ghost",
        group: "as",
        disabled: !_vm.pState.propEdit,
      },
      on: { input: _vm.emitter, end: _vm.dStop },
    },
    _vm._l(_vm.realValue, function (el) {
      return _c("div", { staticClass: "item-group" }, [
        _c("div", { key: el.id, staticClass: "item" }, [
          _c("div", { staticClass: "row hover" }, [
            _c("div", { staticClass: "col-2 handle" }, [
              !_vm.state.propEdit
                ? _c("span", [_vm._v(_vm._s(el.id))])
                : !_vm.getVisibleProp(el)
                ? _c(
                    "span",
                    {
                      staticClass: "font-weight-bold",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSwDown(el)
                        },
                      },
                    },
                    [_vm._v(_vm._s(el.id))]
                  )
                : _c(
                    "span",
                    {
                      staticClass: "font-weight-bold",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSwUp(el)
                        },
                      },
                    },
                    [_vm._v(_vm._s(el.id))]
                  ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-4 text-nowrap" },
              [
                _c("span", [_vm._v(_vm._s(el.name))]),
                _vm._v(" "),
                el.req
                  ? _c("font-awesome-icon", {
                      staticClass: "text-success",
                      attrs: { icon: "fa-solid fa-circle-exclamation" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "small d-block",
                    staticStyle: { "margin-top": "-0.4rem" },
                  },
                  [_vm._v(_vm._s(el.desc))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-3" }, [_vm._v(_vm._s(el.unit))]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2" }, [_vm._v(_vm._s(el.type))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-1" },
              [
                _vm.state.propEdit && !_vm.isImport
                  ? _c("font-awesome-icon", {
                      attrs: { icon: "fa-solid fa-xmark" },
                      on: {
                        click: function ($event) {
                          return _vm.clickDelProp(el)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.state.propEdit && _vm.isImport
                  ? _c("font-awesome-icon", {
                      attrs: { icon: "fa-solid fa-file-export" },
                      on: {
                        click: function ($event) {
                          return _vm.clickImportField(el)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.getVisibleProp(el)
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-2" }, [
                  _c("label", { staticClass: "w-100" }, [
                    _vm._v("id\n                        "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between w-100" },
                      [
                        !_vm.editId.isEdit || _vm.editId.id !== el.id
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: el.id,
                                  expression: "el.id",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass: "form-control w-100",
                              attrs: { disabled: "" },
                              domProps: { value: el.id },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(el, "id", $event.target.value.trim())
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.editId.isEdit && _vm.editId.id === el.id
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.editId.newId,
                                  expression: "editId.newId",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass: "form-control w-100",
                              domProps: { value: _vm.editId.newId },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.editId,
                                    "newId",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.editId.isEdit
                          ? _c("font-awesome-icon", {
                              staticStyle: {
                                position: "absolute",
                                top: "2.3rem",
                                right: "1.5rem",
                                cursor: "pointer",
                              },
                              attrs: { icon: "fa-solid fa-pen", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickEditId(el, true)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.editId.isEdit && _vm.editId.id === el.id
                          ? _c("font-awesome-icon", {
                              staticStyle: {
                                position: "absolute",
                                top: "2.3rem",
                                right: "1.5rem",
                                cursor: "pointer",
                              },
                              attrs: {
                                icon: "fa-solid fa-floppy-disk",
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickEditId(el, false)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4" }, [
                  _c("label", { staticClass: "w-100" }, [
                    _vm._v("название"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: el.name,
                          expression: "el.name",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: el.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(el, "name", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("label", { staticClass: "w-100" }, [
                    _vm._v("описание"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: el.desc,
                          expression: "el.desc",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: el.desc },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(el, "desc", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("label", { staticClass: "mb-0" }, [
                      _c("span", [_vm._v("ед.изм.")]),
                    ]),
                    _vm._v(" "),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.listUnit,
                        searchable: false,
                        "close-on-select": true,
                        "show-labels": false,
                        "allow-empty": false,
                      },
                      model: {
                        value: el.unit,
                        callback: function ($$v) {
                          _vm.$set(el, "unit", $$v)
                        },
                        expression: "el.unit",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("label", { staticClass: "mb-0" }, [
                      _c("span", [_vm._v("тип поля")]),
                    ]),
                    _vm._v(" "),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.listType,
                        searchable: false,
                        "close-on-select": true,
                        "show-labels": false,
                        "allow-empty": false,
                      },
                      model: {
                        value: el.type,
                        callback: function ($$v) {
                          _vm.$set(el, "type", $$v)
                        },
                        expression: "el.type",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  el.type !== "bool"
                    ? _c("label", [
                        _vm._v("по умолчанию\n                        "),
                        el.type === "int" || el.type === "float"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: el.val,
                                  expression: "el.val",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              domProps: { value: el.val },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    el,
                                    "val",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        el.type !== "int" && el.type !== "float"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: el.val,
                                  expression: "el.val",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass: "form-control",
                              domProps: { value: el.val },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    el,
                                    "val",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  el.type === "bool"
                    ? _c("label", { staticClass: "apple-switch_2 mt-3 ml-1" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: el.val,
                              expression: "el.val",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(el.val)
                              ? _vm._i(el.val, null) > -1
                              : el.val,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = el.val,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(el, "val", $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      el,
                                      "val",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(el, "val", $$c)
                              }
                            },
                          },
                        }),
                        _c("i"),
                        _vm._v("по умолчанию"),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-5" }, [
                  _c("label", { attrs: { title: "размер поля от 1 до 12" } }, [
                    _vm._v("размер поля"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: el.size,
                          expression: "el.size",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: el.size },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(el, "size", _vm._n($event.target.value))
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "apple-switch_2 mt-3 ml-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: el.req,
                          expression: "el.req",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(el.req)
                          ? _vm._i(el.req, null) > -1
                          : el.req,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = el.req,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && _vm.$set(el, "req", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  el,
                                  "req",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(el, "req", $$c)
                          }
                        },
                      },
                    }),
                    _c("i"),
                    _vm._v("обязательное"),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }