<template>
    <div>
        <button type="button" class="btn btn-primary btn-sm mb-3" @click="addVars">добавить переменную</button>
        <draggable :list="inputVal" group="section" :scroll-sensitivity="100" :fallback-tolerance="1"
                   :force-fallback="true" handle=".handle">

            <template v-for="el in inputVal">
                <div class="row" :key="el.id">
                    <div class="col-2">
                        <input class="form-control w-100 handle" v-model.trim="el.id_f" title="имя переменной" placeholder="имя переменной"/>
                    </div>
                    <div class="col-3">
                        <input class="form-control w-100" v-model.trim="el.name" title="примечание" placeholder="примечание"/>
                    </div>
                    <div class="col-1">
                        <input class="form-control w-100" v-model.trim="el.v_in" title="значение" placeholder="значение"/>
                    </div>
                    <div class="col-4">
                        <input class="form-control w-100" v-model.trim="el.formula" :title="el.resp.fText" placeholder="формула"/>
                        <span v-if="el.resp.eMess" class="small text-danger">{{ el.resp.eMess }}</span>
                    </div>
                    <div class="col-2 d-flex">
                        <div class="w-75 position-relative">
                            <input class="form-control" v-model.trim="el.v_out" disabled title="результат"/>
                            <span class="position-absolute" style="top: 0.45rem; right: 0.8rem; cursor: pointer;" title="округление" @click="clickRound(el)">{{ el.round }}</span>
                        </div>
                        <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-2 ml-3 w-auto" style="cursor: pointer" size="lg" @click="removeVars(el)"/>
                    </div>
                </div>
            </template>
        </draggable>
    </div>
</template>
<script>
import uniqid from "uniqid";
import draggable from 'vuedraggable'

export default {
    name: 'c-list-v-so',
    components: {draggable},
    props: {
        value: {type: Array, default: () => [],},
    },
    methods: {
        clickTag(e) {
            this.inputVal = e;
        },
        removeVars(el) {
            let index = this.inputVal.findIndex(item => item.id === el.id);
            if (index >= 0) this.inputVal.splice(index, 1);
        },
        clickRound(obj) {
            if (obj.round === 3) {
                obj.round = 0;
            } else {
                obj.round += 1;
            }
        },
        addVars() {
            let uid = uniqid();
            let o = {
                id: 'v' + uid,
                id_f: uid,
                name: '',
                v_in: 0,
                formula: '',
                v_out: 0,
                isCalc: false,
                isShow: true,
                round: 0,
                resp: {
                    eMess: '',
                    fText: '',
                }
            };
            this.inputVal.push(o);
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', this.value);
            }
        }
    }
}
</script>
