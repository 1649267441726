var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: _vm.modalId, role: "dialog" } },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._v("импортировать файл с базой оборудования"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c(
                "div",
                { staticClass: "col-12" },
                _vm._l(_vm.partners, function (el) {
                  return _c(
                    "span",
                    {
                      key: el.id,
                      staticClass: "mr-2",
                      class: {
                        "font-weight-bold":
                          el.id === _vm.state.selectPartner.id,
                      },
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSwPartner(el)
                        },
                      },
                    },
                    [_vm._v(_vm._s(el.name))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "vue-dropzone",
                {
                  ref: "dropzone",
                  attrs: {
                    id: "dropzone",
                    options: _vm.dropzoneOptionsAttaches,
                    useCustomSlot: true,
                  },
                  on: {
                    "vdropzone-success": _vm.dropzoneSuccessAttach,
                    "vdropzone-sending": _vm.addParamAttach,
                  },
                },
                [
                  _c("div", { staticClass: "dropzone-custom-content" }, [
                    _c("h3", { staticClass: "dropzone-custom-title" }, [
                      _vm._v("Перетащите сюда файл"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "subtitle" }, [
                      _vm._v("...или кликните для выбора файла на компьютере"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _vm.state.id
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-default text-danger",
                    attrs: { type: "button" },
                    on: { click: _vm.clickDelete },
                  },
                  [_vm._v("удалить")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.state.id
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.clickSave },
                  },
                  [_vm._v("сохранить")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button", "data-dismiss": "modal" },
              },
              [_vm._v("закрыть")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }