var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMounted
    ? _c("div", { staticClass: "h-100" }, [
        _c(
          "div",
          { staticClass: "container-fluid m1" },
          [
            _c("title-for-page", {
              attrs: {
                "c-page": _vm.local.cPage,
                nbi: _vm.nbi,
                "lock-link": true,
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-12 col-xl-8" },
                [
                  _c(
                    "nav",
                    {
                      staticClass: "navbar navbar-expand pl-0 pt-0",
                      staticStyle: { "margin-left": "-0.5rem" },
                    },
                    [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c("div", { staticClass: "navbar-nav" }, [
                          _c("div", { staticClass: "nav-item" }, [
                            _c(
                              "button",
                              {
                                staticClass: "nav-link btn",
                                class: { active: _vm.state.swOnOpt },
                                attrs: { title: "настройки страницы" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.swOnOpt = !_vm.state.swOnOpt
                                  },
                                },
                              },
                              [_vm._v("настройки")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("card-options", {
                    staticClass: "mb-3",
                    attrs: { "c-page": _vm.local.cPage, state: _vm.state },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _vm._v(
                        "\n                        Страница привязана к листу "
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          _vm._s(_vm.local.cPage.autocad.list_name_autocad)
                        ),
                      ]),
                      _vm._v(" из DWG\n                    "),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }