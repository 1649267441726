import moment from "moment";
import VueCookies from 'vue-cookies'

export class contextMenuTools {
    constructor(th) {
        this.th = th;
    }

    close() {
        Bus.$emit('clickBody');
    }

    show(e, el, refs) {
        Bus.$emit('onShowContextMenu', {e: e, el: el, refs: refs});
    }
}

export class page {

    constructor(th) {
        this.th = th;
    }

    setLoading(state) {
        this.th.status.loading = state;
        Bus.$emit('navbar', {command: 'setLoading', args: state});
    }

    clickSave(url, obj, suffix = '_save_page', isFlash = true) {
        this.setLoading(true, this.th.status.loading);
        Bus.$emit('zPost', {
            url: url,
            obj: obj,
            suffix: suffix,
            flash: isFlash,
        });
        Bus.$once('zResponse' + suffix, response => {
            this.setLoading(false, this.th.status.loading);
        });
        Bus.$once('zError' + suffix, response => {
            this.setLoading(false, this.th.status.loading);
        });
    }

    clickAutocadWS(url, suffix = '_uploadWS', isFlash = true) {
        this.setLoading(true, this.th.status.loading);
        Bus.$emit('zPost', {
            url: url,
            obj: {},
            suffix: suffix,
            flash: isFlash,
        });
        Bus.$once('zResponse' + suffix, response => {
            this.setLoading(false, this.th.status.loading);
        });
        Bus.$once('zError' + suffix, response => {
            this.setLoading(false, this.th.status.loading);
        });
    }

    clickDownload() {
        document.location.href = '/page/' + this.th.local.cPage.id + '/dl';
    }

    clickDownloadAll() {
        console.log('dl');
        document.location.href = '/pages/' + this.th.nbi.authUser.prop.currentProjectId + '/get_docx';
    }

    clickReloadCalc(suffix = '_save_page') {
        if (!this.th.local.cPage.id) return;
        this.clickSave('/page/reCalc', {cPage: this.th.local.cPage}, '_re_calc')
        Bus.$on('zResponse_re_calc', response => {
            this.th.local.cPage = response.cPage;
            Bus.$emit('updListMenu', response.menu);
        });
    }

    clickSaveCPage(suffix = '_save_page') {
        if (!this.th.local.cPage.id) return;
        this.setLoading(true, this.th.status.loading);
        Bus.$emit('zPost', {
            url: '/page/save',
            obj: {cPage: this.th.local.cPage},
            suffix: suffix,
        });
        Bus.$once('zResponse' + suffix, response => {
            this.th.local.cPage = response.cPage;
            Bus.$emit('updListMenu', response.menu);
            this.setLoading(false, this.th.status.loading);
        });
        Bus.$once('zError' + suffix, response => {
            this.setLoading(false, this.th.status.loading);
        });
    }

    customFormatter(date, format = 'DD.MM.YYYY') {
        return moment(date).format(format);
    }

    labelSearch(obj, opt, type = 'list_name') {
        let o = obj.find(item => item.link_content === opt);
        if (!o) return '';
        if (type === 'list_name') return o.list_name;
        return o.list_name;
    }

    labelSearchId(obj, opt, type = 'name') {
        let o = obj.find(item => item.id === opt);
        if (!o) return '';
        if (type === 'name') return o.name;
        return o.name;
    }

    isU(obj, len = false) {
        if (!len) return !obj
        else return !obj || Object.keys(obj).length === 0
    }

    loadLS() {
        let state = localStorage.getItem(this.th.$options.name);
        if (state === null) return false;
        state = JSON.parse(state);
        if (this.th.state.version !== undefined && state.version !== undefined && this.th.state.version === state.version) {
            if (this.th.state.cookie !== undefined) {
                this.th.state.cookie = state;
            } else {
                this.th.state = state;
            }
            if (typeof (this.th.postLoadLS) === 'function') this.th.postLoadLS();
        }
        return true;
    }

    updLS() {
        if (typeof (this.th.preSaveLS) === 'function') this.th.preSaveLS();
        let state = this.th.state;
        if (state.cookie !== undefined) {
            if (state.version) state.cookie.version = state.version;
            localStorage.setItem(this.th.$options.name, JSON.stringify(state.cookie));
        } else {
            localStorage.setItem(this.th.$options.name, JSON.stringify(state));
        }
    }

    clearLS() {
        localStorage.removeItem(this.th.$options.name);
        document.location.reload();
    }

    loadLSMulti() {
        let state = localStorage.getItem(this.th.$options.name);
        if (state === null) return;
        state = JSON.parse(state);

        if (this.th.cookie === undefined) {
            if (this.th.state.version !== undefined && state.version !== undefined && this.th.state.version === state.version) {
                this.th.state = state;
            }
            if (typeof (this.th.postLoadLS) === 'function') this.th.postLoadLS();
            return;
        }

        if (this.th.cookie.main === undefined) {
            if (this.th.cookie.version !== undefined && state.version !== undefined && this.th.cookie.version === state.version) {
                this.th.cookie = state;
            }
            if (typeof (this.th.postLoadLS) === 'function') this.th.postLoadLS();
            return;
        }

        if (this.th.cookie.main.version !== undefined && state.version !== undefined && this.th.cookie.main.version === state.version) {
            this.th.cookie.main = state;
            Object.keys(this.th.cookie).forEach(item => {
                if (item !== 'main') {
                    state = localStorage.getItem(this.th.$options.name + '_' + item);
                    if (state === null) return;
                    state = JSON.parse(state);
                    this.th.cookie[item] = state.val;
                }
            })
        }
        if (typeof (this.th.postLoadLS) === 'function') this.th.postLoadLS();
        return;

    }

    updLSMulti() {
        if (typeof (this.th.preSaveLS) === 'function') this.th.preSaveLS();
        if (this.th.cookie === undefined) {
            localStorage.setItem(this.th.$options.name, JSON.stringify(this.th.state));
            return;
        }
        if (this.th.cookie.main === undefined) {
            localStorage.setItem(this.th.$options.name, JSON.stringify(this.th.cookie));
            return;
        }

        localStorage.setItem(this.th.$options.name, JSON.stringify(this.th.cookie.main));
        Object.keys(this.th.cookie).forEach(item => {
            if (item !== 'main') {
                localStorage.setItem(this.th.$options.name + '_' + item, JSON.stringify({val: this.th.cookie[item]}));
            }
        })

    }

    loadCookie() {
        let state = VueCookies.get(this.th.$options.name);
        if (!state) return;
        if (this.th.state.version !== undefined && state.version !== undefined && this.th.state.version === state.version) {
            if (this.th.state.cookie !== undefined) {
                this.th.state.cookie = state;
            } else {
                this.th.state = state;
            }
            if (typeof (this.th.postLoadCookie) === 'function') this.th.postLoadCookie();
        }
    }

    updCookie() {
        if (typeof (this.th.preSaveCookie) === 'function') this.th.preSaveCookie();
        let state = this.th.state;
        if (state.cookie !== undefined) {
            if (state.version) state.cookie.version = state.version;
            VueCookies.set(this.th.$options.name, state.cookie, 60 * 60 * 24 * 7);
        } else {
            VueCookies.set(this.th.$options.name, state, 60 * 60 * 24 * 7);
        }
    }

    loadCookieMulti() {
        let state = VueCookies.get(this.th.$options.name);
        if (!state) return;

        if (this.th.cookie === undefined) {
            if (this.th.state.version !== undefined && state.version !== undefined && this.th.state.version === state.version) {
                this.th.state = state;
            }
            if (typeof (this.th.postLoadCookie) === 'function') this.th.postLoadCookie();
            return;
        }

        if (this.th.cookie.main === undefined) {
            if (this.th.cookie.version !== undefined && state.version !== undefined && this.th.cookie.version === state.version) {
                this.th.cookie = state;
            }
            if (typeof (this.th.postLoadCookie) === 'function') this.th.postLoadCookie();
            return;
        }

        if (this.th.cookie.main.version !== undefined && state.version !== undefined && this.th.cookie.main.version === state.version) {
            this.th.cookie.main = state;
            Object.keys(this.th.cookie).forEach(item => {
                if (item !== 'main') {
                    state = VueCookies.get(this.th.$options.name + '_' + item);
                    if (state) this.th.cookie[item] = state.val;
                }
            })
        }
        if (typeof (this.th.postLoadCookie) === 'function') this.th.postLoadCookie();
        return;

    }

    updCookieMulti() {
        // console.log('upd');
        // console.log(this.th.cookie.main);
        if (typeof (this.th.preSaveCookie) === 'function') this.th.preSaveCookie();
        if (this.th.cookie === undefined) {
            VueCookies.set(this.th.$options.name, this.th.state, 60 * 60 * 24 * 7);
            return;
        }
        if (this.th.cookie.main === undefined) {
            VueCookies.set(this.th.$options.name, this.th.cookie, 60 * 60 * 24 * 7);
            return;
        }

        VueCookies.set(this.th.$options.name, this.th.cookie.main, 60 * 60 * 24 * 7);
        Object.keys(this.th.cookie).forEach(item => {
            if (item !== 'main') {
                VueCookies.set(this.th.$options.name + '_' + item, {val: this.th.cookie[item]}, 60 * 60 * 24 * 7);
            }
        })

    }

    clearCookie() {
        VueCookies.remove(this.th.$options.name);
        document.location.reload();
    }
}

export class toolM {

    constructor(th) {
        this.th = th;
    }

    setLoading(state, hideSave = false) {
        this.th.status.loading = state;
        Bus.$emit('navbar', {command: 'setLoading', args: state});
        if (hideSave) Bus.$emit('navbar', {command: 'showButtons', args: {save: false}});
    }

    labelSearch_1(obj, opt) {
        let o = obj.find(item => item.id === opt);
        if (!o) return '';
        return o.name;
    }

    labelSearch_2(obj, opt) {
        let o = obj.find(item => item.id === opt.id);
        if (!o) return '';
        return o.name;
    }

    labelSearch_3(obj, opt) {
        let o = obj.find(item => item.name === opt);
        if (!o) return '';
        return o.name;
    }

    customFormatter(date, format = 'DD.MM.YYYY') {
        return moment(date).format(format);
    }

    setMounted(arrButton = ['reload', 'save']) {
        let args = {};
        arrButton.forEach(item => args[item] = true);
        Bus.$emit('navbar', {command: 'showButtons', args: args});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
    }

    setCreated() {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlS') this.th.clickSave();
        });
        Bus.$on('navbar_info', obj => this.th.nbi = obj);
    }

    r0(val) {
        if (val === 0 || val === undefined || val === null) return '';
        return Math.round(parseFloat(val));
    }

    r1(val) {
        if (val === 0 || val === undefined || val === null) return '';
        return Math.round(parseFloat(val) * 10) / 10;
    }

    r2(val) {
        if (val === 0 || val === undefined || val === null) return '';
        return Math.round(parseFloat(val) * 100) / 100;
    }

}

export class eTable {

    constructor(th) {
        this.th = th;
    }

    onDotSelect() {
        let r = [];
        this.th.fTable.forEach(item => r.push(item))
        this.th.local.selectArr = r;
    }

    onDotUnSelect() {
        let l = this.lastSelectObj();
        if (!l) return;
        this.th.local.selectArr = [];
        this.th.local.selectArr.push(l);
    }

    onDotInverse() {
        let l = this.lastSelectObj();
        if (!l) return;
        let r = [];
        this.th.fTable.forEach(item => {
            if (!this.isSelect(item)) r.push(item);
        })
        this.th.local.selectArr = r;
        if (r.length < 1) this.th.local.selectArr.push(l);
    }

    lastSelectObj() {
        if (this.th.local.selectArr.length === 0) return null;
        return this.th.local.selectArr[this.th.local.selectArr.length - 1];
    }

    keyUpArrow() {
        let index = this.th.fTable.findIndex(item => item.id === this.th.lastSelectObj.id);
        if (index <= 0) return;

        //todo js delete Array element
        let key = this.th.local.selectArr.findIndex(item => item.id === this.th.lastSelectObj.id);
        if (key < 0) return;
        this.th.local.selectArr.splice(key, 1);

        this.th.local.selectArr.push(this.th.fTable[index - 1]);
    }

    keyDownArrow() {
        let index = this.th.fTable.findIndex(item => item.id === this.th.lastSelectObj.id);
        if (index + 1 >= this.th.fTable.length) return;

        //todo js delete Array element
        let key = this.th.local.selectArr.findIndex(item => item.id === this.th.lastSelectObj.id);
        if (key < 0) return;
        this.th.local.selectArr.splice(key, 1);

        this.th.local.selectArr.push(this.th.fTable[index + 1]);
    }

    keyUpArrowWithChild(table = this.th.fTable) {
        let t = [];
        this.convertThreeToLine(table, t);

        let index = t.findIndex(item => item.id === this.th.lastSelectObj.id);
        if (index <= 0) return;

        //todo js delete Array element
        let key = this.th.local.selectArr.findIndex(item => item.id === this.th.lastSelectObj.id);
        if (key < 0) return;
        this.th.local.selectArr.splice(key, 1);

        this.th.local.selectArr.push(t[index - 1]);
    }

    keyDownArrowWithChild(table = this.th.fTable) {
        let t = [];
        this.convertThreeToLine(table, t);

        let index = t.findIndex(item => item.id === this.th.lastSelectObj.id);
        if (index + 1 >= t.length) return;

        //todo js delete Array element
        let key = this.th.local.selectArr.findIndex(item => item.id === this.th.lastSelectObj.id);
        if (key < 0) return;
        this.th.local.selectArr.splice(key, 1);

        this.th.local.selectArr.push(t[index + 1]);
    }

    convertThreeToLine(arr, res, parent = '0', npp = 0) {
        arr.forEach(item => {
            res.push(item);
            this.convertThreeToLine(item.child, res);
        })
    }


    clearSelectArr() {
        let r = [];
        this.th.local.selectArr.forEach(item => {
            let i = this.th.fTable.findIndex(item2 => item2.id === item.id);
            if (i > -1) r.push(item);
        })
        this.th.local.selectArr = r;
        if (r.length === 0 && this.th.fTable.length > 0) this.th.local.selectArr.push(this.th.fTable[0]);
    }

    isSelect(obj) {
        let key = this.th.local.selectArr.findIndex(item => item.id === obj.id);
        return key >= 0;
    }

    isActiveSave(obj) {
        let key = this.th.local.saveArr.findIndex(item => item.id === obj.id);
        return key >= 0;
    }

    isEditForSave(obj) {
        let key = this.th.local.saveArr.findIndex(item => item.id === obj.id);
        return key >= 0;
    }

    clickRow(obj) {
        console.log('click Row');
        // if (this.th.local.selectArr.length < 2) {
        this.th.local.selectArr = [];
        this.th.local.selectArr.push(obj);
        return;
        // }
        // this.clickCtrlRow(obj);
    }

    clickCtrlRow(obj) {
        let key = this.th.local.selectArr.findIndex(item => item.id === obj.id);
        if (key >= 0) {
            this.th.local.selectArr.splice(key, 1);
        } else {
            this.th.local.selectArr.push(obj);
        }
    }

    copyClipBoard(obj) {
        let point = obj.r_point;
        point = point[0] + ',' + point[1] + ' ';
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            this.th.flash('координаты скопированы', 'success', {timeout: 1000});
            return navigator.clipboard.writeText(point);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = point;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                this.th.flash('координаты скопированы 2', 'success', {timeout: 1000});
            });
        }
    }

    updSelectArr() {
        let arr = [];
        this.th.local.selectArr.forEach(item => {
            let o = this.th.local.table.find(i2 => i2.id === item.id);
            if (o) arr.push(o);
        })
        this.th.local.selectArr = arr;
    }

    getTwoChars(uid) {
        uid = String(uid);
        if (!uid) return '';
        return uid.slice(0, 2);
    }

    groupU(table, keys) {
        let res = [];
        let i = 0;
        let r2 = false;
        if (table.length === 0) return [];
        table.forEach(item => {

            let p = res.find(item2 => {
                    r2 = true;
                    keys.forEach(item3 => {
                        let pKey = item3.split('.');
                        if (pKey.length === 1) {
                            r2 = r2 && item2[pKey[0]] === item[pKey[0]];
                        } else if (pKey.length === 2) {
                            r2 = r2 && item2[pKey[0]][pKey[1]] === item[pKey[0]][pKey[1]];
                        }
                    })
                    return r2;
                }
            );

            let child = table.filter(item2 => {
                r2 = true;
                keys.forEach(item3 => {
                    let pKey = item3.split('.');
                    if (pKey.length === 1) {
                        r2 = r2 && item2[pKey[0]] === item[pKey[0]];
                    } else if (pKey.length === 2) {
                        r2 = r2 && item2[pKey[0]][pKey[1]] === item[pKey[0]][pKey[1]];
                    }
                })
                return r2;
            })

            if (!p) {
                let o = {
                    id: i,
                    child: child,
                };
                keys.forEach(item3 => {
                    let pKey = item3.split('.');
                    o[pKey[0]] = item[pKey[0]];
                })

                res.push(o);
                i++;
            }
        })

        return res;
    }
}

export class eTableMulti {

    constructor(th) {
        this.th = th;
    }

    onDotSelect() {
        let r = [];
        this.th.fTable.forEach(item => r.push(item))
        this.th.local.selectArr = r;
    }

    onDotUnSelect() {
        let l = this.lastSelectObj();
        if (!l) return;
        this.th.local.selectArr = [];
        this.th.local.selectArr.push(l);
    }

    onDotInverse() {
        let l = this.lastSelectObj();
        if (!l) return;
        let r = [];
        this.th.fTable.forEach(item => {
            if (!this.isSelect(item)) r.push(item);
        })
        this.th.local.selectArr = r;
        if (r.length < 1) this.th.local.selectArr.push(l);
    }

    lastSelectObj() {
        if (this.th.local.selectArr.length === 0) return null;
        return this.th.local.selectArr[this.th.local.selectArr.length - 1];
    }

    clearSelectArr() {
        let r = [];
        this.th.local.selectArr.forEach(item => {
            let i = this.th.fTable.findIndex(item2 => item2.id === item.id);
            if (i > -1) r.push(item);
        })
        this.th.local.selectArr = r;
        if (r.length === 0 && this.th.fTable.length > 0) this.th.local.selectArr.push(this.th.fTable[0]);
    }

    isSelect(obj) {
        let key = this.th.local.selectArr.findIndex(item => item.id === obj.id);
        return key >= 0;
    }

    clickRow(obj) {
        if (this.th.local.selectArr.length < 2) {
            this.th.local.selectArr = [];
            this.th.local.selectArr.push(obj);
            return;
        }
        this.clickCtrlRow(obj);
    }

    clickCtrlRow(obj) {
        let key = this.th.local.selectArr.findIndex(item => item.id === obj.id);
        if (key >= 0) {
            this.th.local.selectArr.splice(key, 1);
        } else {
            this.th.local.selectArr.push(obj);
        }
    }

    copyClipBoard(obj) {
        let point = obj.r_point;
        point = point.replace(/\(|\)/g, '').replace(/\s/g, ',');
        window.navigator.clipboard.writeText(point);
        this.th.flash('координаты скопированы', 'success', {timeout: 1000});
    }

    updSelectArr() {
        let arr = [];
        this.th.local.selectArr.forEach(item => {
            let o = this.th.local.table.find(i2 => i2.id === item.id);
            if (o) arr.push(o);
        })
        this.th.local.selectArr = arr;
    }
}

export class modalDraggable {
    constructor(th) {
        this.th = th;

        (function ($) {
            $.fn.drags = function (opt) {

                opt = $.extend({handle: "", cursor: "move"}, opt);

                if (opt.handle === "") {
                    var $el = this;
                } else {
                    var $el = this.find(opt.handle);
                }

                return $el.css('cursor', opt.cursor).on("mousedown", function (e) {
                    if (opt.handle === "") {
                        var $drag = $(this).addClass('draggable');
                    } else {
                        var $drag = $(this).addClass('active-handle').parent().addClass('draggable');
                    }
                    var z_idx = $drag.css('z-index'),
                        drg_h = $drag.outerHeight(),
                        drg_w = $drag.outerWidth(),
                        pos_y = $drag.offset().top + drg_h - e.pageY,
                        pos_x = $drag.offset().left + drg_w - e.pageX;
                    $drag.css('z-index', 1000).parents().on("mousemove", function (e) {
                        $('.draggable').offset({
                            top: e.pageY + pos_y - drg_h,
                            left: e.pageX + pos_x - drg_w
                        }).on("mouseup", function () {
                            $(this).removeClass('draggable').css('z-index', z_idx);
                        });
                    });
                    e.preventDefault(); // disable selection
                }).on("mouseup", function () {
                    if (opt.handle === "") {
                        $(this).removeClass('draggable');
                    } else {
                        $(this).removeClass('active-handle').parent().removeClass('draggable');
                    }
                });

            }
        })(jQuery);

        $('#' + this.th.modalId).modal({
            backdrop: false,
            show: false
        });

        $('#' + this.th.modalId + ' .modal-dialog').drags({
            handle: ".modal-header"
        });
    }


}


