var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ml-2" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "table",
          { staticClass: "table table-sm" },
          _vm._l(this.getLocalTable, function (val, index) {
            return _c(
              "tr",
              {
                key: index,
                class: {
                  active: index === _vm.state.activeRow,
                  selected: _vm.isSelectedRow(val),
                },
                on: {
                  click: function ($event) {
                    return _vm.clickRow(index)
                  },
                },
              },
              [
                _c("td", { staticClass: "text-left" }, [
                  _c(
                    "a",
                    {
                      staticStyle: { "text-decoration": "underline" },
                      on: {
                        click: function ($event) {
                          return _vm.clickShowModal(val)
                        },
                      },
                    },
                    [_vm._v(_vm._s(val.name))]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticStyle: { width: "150px" } }, [
                  _vm._v(_vm._s(_vm.getDate(val.created_at))),
                ]),
                _vm._v(" "),
                _c("td", { staticStyle: { width: "50px" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-link p-0",
                      attrs: { href: "/attach/" + val.id + "/dl" },
                    },
                    [_vm._v("link")]
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function ($event) {
                return _vm.clickShowModal(null)
              },
            },
          },
          [_vm._v("добавить файл")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }