var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100" }, [
    _c("div", { staticClass: "container-fluid m1" }, [
      _c("div", { staticClass: "row justify-content-center py-5" }, [
        _c("div", { staticClass: "col-12 col-sm-8 col-md-6 col-lg-3" }, [
          _vm.isLoginForm
            ? _c("div", { staticClass: "card pb-3" }, [
                _c("div", { staticClass: "card-header bg-white" }, [
                  _vm._v("Вход в личный кабинет"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                    _vm._v("email"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "email" },
                    domProps: { value: _vm.email },
                    on: {
                      keyup: _vm.checkEmail,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mt-3 mb-0 font-weight-bold" }, [
                    _vm._v("пароль"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pass,
                        expression: "pass",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "password" },
                    domProps: { value: _vm.pass },
                    on: {
                      keyup: [
                        _vm.checkPass,
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.clickLogin.apply(null, arguments)
                        },
                      ],
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.pass = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mt-2 text-center" }, [
                  _c("label", { staticStyle: { cursor: "pointer" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isRemember,
                          expression: "isRemember",
                        },
                      ],
                      staticClass: "mr-2",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.isRemember)
                          ? _vm._i(_vm.isRemember, null) > -1
                          : _vm.isRemember,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.isRemember,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.isRemember = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.isRemember = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.isRemember = $$c
                          }
                        },
                      },
                    }),
                    _vm._v("запомнить меня"),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isError
                  ? _c("p", { staticClass: "v-string text-center" }, [
                      _vm._v(
                        "пароль или email не верен, повторите попытку входа"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary w-50 align-self-center",
                    on: { click: _vm.clickLogin },
                  },
                  [
                    _vm.isLoading
                      ? _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
                      : _vm._e(),
                    _vm._v(
                      "\n                            ВХОД\n                        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isRegistrationForm
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v("Регистрация"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                    _vm._v("имя"),
                  ]),
                  _vm._v(" "),
                  !_vm.isValidName && _vm.name !== ""
                    ? _c("p", { staticClass: "v-string" }, [
                        _vm._v("имя должно содержать не менее 3х символов"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.name,
                        expression: "name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.name },
                    on: {
                      keyup: _vm.checkName,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.name = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0 mt-3 font-weight-bold" }, [
                    _vm._v("email"),
                  ]),
                  _vm._v(" "),
                  !_vm.isValidEmail && _vm.email !== ""
                    ? _c("p", { staticClass: "v-string" }, [
                        _vm._v("введите корректный email"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "email" },
                    domProps: { value: _vm.email },
                    on: {
                      keyup: _vm.checkEmail,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mt-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isPolitic,
                          expression: "isPolitic",
                        },
                      ],
                      staticClass: "mr-2",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.isPolitic)
                          ? _vm._i(_vm.isPolitic, null) > -1
                          : _vm.isPolitic,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.isPolitic,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.isPolitic = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.isPolitic = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.isPolitic = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(
                      "\n                                Вы согласны с\n                                "
                    ),
                    _c("a", { attrs: { target: "_blank", href: "/" } }, [
                      _vm._v("Политикой конфиденциальности"),
                    ]),
                    _vm._v(
                      "\n                                на обработку персональных данных\n                            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isError
                  ? _c("p", { staticClass: "v-string text-center" }, [
                      _vm._v(
                        "Указаный email уже зарегистрирован в нашей системе"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mb-4 w-50 align-self-center",
                    class: { disabled: !_vm.isValidRegistration },
                    on: { click: _vm.clickRegistration },
                  },
                  [
                    _vm.isLoading
                      ? _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
                      : _vm._e(),
                    _vm._v(
                      "\n                            регистрация\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.isError
                  ? _c("p", { staticClass: "text-center my-0" }, [
                      _vm._v("вы можете попробовать "),
                      _c(
                        "a",
                        {
                          staticClass: "font-weight-bold",
                          on: { click: _vm.clickShowLoginForm },
                        },
                        [_vm._v("войти с паролем")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isError
                  ? _c("p", { staticClass: "text-center mt-0" }, [
                      _vm._v("или "),
                      _c(
                        "a",
                        {
                          staticClass: "font-weight-bold",
                          on: { click: _vm.clickShowRepairForm },
                        },
                        [_vm._v("сбросить")]
                      ),
                      _vm._v(" пароль"),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isEndRegistration
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v("Регистрация прошла успешно"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("p", {}, [
                    _vm._v("Для подтверждения email, на Ваш почтовый адрес "),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.email)),
                    ]),
                    _vm._v(
                      " было выслано письмо.\n                                На всякий случай, не забудьте поверить папку СПАМ, иногда письма попадают туда."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold mb-3",
                      attrs: { href: _vm.nbi.url.prev },
                    },
                    [_vm._v("Вернуться на прошлую страницу сайта")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isEmailVerified
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v("Email подтверждён"),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isResetPasswordForm
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v("Сброс пароля"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("p", [
                    _vm._v(
                      "На указаный email, будет выслано письмо с новым паролем"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0 mt-3 font-weight-bold" }, [
                    _vm._v("email"),
                  ]),
                  _vm._v(" "),
                  !_vm.isValidEmail && _vm.email !== ""
                    ? _c("p", { staticClass: "v-string" }, [
                        _vm._v("введите корректный email"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "email" },
                    domProps: { value: _vm.email },
                    on: {
                      keyup: _vm.checkEmail,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center mt-4" }, [
                    _vm.isError
                      ? _c("p", { staticClass: "v-string text-center mb-1" }, [
                          _vm._v(_vm._s(_vm.isError)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lockReset < 4
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary w-50 align-self-center",
                            class: { disabled: !_vm.isValidEmail },
                            on: { click: _vm.clickResetPassword },
                          },
                          [
                            _vm.isLoading
                              ? _c("i", {
                                  staticClass: "fa fa-spinner fa-spin fa-fw",
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n                                    отправить\n                                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body text-center" }, [
      _c("p", [_vm._v("Пароль от личного кабинета выслан Вам на почту")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }