var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMount
    ? _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: { id: _vm.modalId, role: "dialog", "data-keyboard": "false" },
          on: { click: _vm.closeModal },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-xl",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "div",
                    [
                      _vm.getTwoChars(_vm.state.obj.uid) === "bs"
                        ? _c("font-awesome-icon", {
                            staticStyle: { color: "grey" },
                            attrs: { icon: "fa-regular fa-star" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getTwoChars(_vm.state.obj.uid) === "ps"
                        ? _c("font-awesome-icon", {
                            staticStyle: { color: "goldenrod" },
                            attrs: { icon: "fa-solid fa-award" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getTwoChars(_vm.state.obj.uid) === "pr"
                        ? _c("font-awesome-icon", {
                            staticStyle: { color: "royalblue" },
                            attrs: { icon: "fa-regular fa-circle-user" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v("карточка продукта"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-danger mt-1",
                        staticStyle: { cursor: "pointer" },
                        attrs: { icon: "fa-solid fa-xmark", size: "lg" },
                        on: { click: _vm.closeModal },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.isLoad
                  ? _c(
                      "div",
                      {
                        staticClass: "modal-body",
                        on: { click: _vm.clickCloseContextMenu },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-column",
                            staticStyle: {
                              "max-height": "calc(100vh - 236px)",
                            },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-12 text-left" },
                                [
                                  _vm.isMount && _vm.state.isEdit
                                    ? _c("c-category-string", {
                                        staticClass: "d-inline-block",
                                        attrs: {
                                          "list-category":
                                            _vm.local.listCategory,
                                          "c-obj": _vm.state.objNew,
                                          ttype: "4",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isMount && !_vm.state.isEdit
                                    ? _c("c-category-string", {
                                        staticClass: "d-inline-block",
                                        attrs: {
                                          "list-category":
                                            _vm.local.listCategory,
                                          "c-obj": _vm.state.obj,
                                          ttype: "3",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            !_vm.state.isEdit
                              ? _c("div", [
                                  !!_vm.state.obj.img &&
                                  _vm.state.obj.img.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "mb-2",
                                          staticStyle: {
                                            "min-height": "200px",
                                            height: "1px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex h-100",
                                              staticStyle: {
                                                "overflow-y": "hidden",
                                              },
                                            },
                                            _vm._l(
                                              _vm.state.obj.img,
                                              function (el, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "h-100",
                                                    staticStyle: {
                                                      position: "relative",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "h-100",
                                                      attrs: {
                                                        src: el,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.state.isEdit
                              ? _c("div", [
                                  !!_vm.state.objNew.img &&
                                  _vm.state.objNew.img.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "mb-2",
                                          staticStyle: {
                                            "min-height": "200px",
                                            height: "1px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex h-100",
                                              staticStyle: {
                                                "overflow-y": "hidden",
                                              },
                                            },
                                            _vm._l(
                                              _vm.state.objNew.img,
                                              function (el, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "h-100",
                                                    staticStyle: {
                                                      position: "relative",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "h-100",
                                                      attrs: {
                                                        src: el,
                                                        alt: "",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.state.isEdit
                                                      ? _c(
                                                          "font-awesome-icon",
                                                          {
                                                            staticClass:
                                                              "text-danger mt-1",
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                              position:
                                                                "absolute",
                                                              right: "0",
                                                              top: "0",
                                                            },
                                                            attrs: {
                                                              icon: "fa-solid fa-xmark",
                                                              size: "lg",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clickDeleteImg(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.state.isEdit
                              ? _c("div", [
                                  _c("div", { staticClass: "row mb-0" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 text-left" },
                                      [
                                        _c("label", { staticClass: "w-100" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "small font-weight-bold",
                                            },
                                            [_vm._v("Название")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.state.objNew.name,
                                                expression: "state.objNew.name",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            domProps: {
                                              value: _vm.state.objNew.name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.state.objNew,
                                                  "name",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-3 text-left" },
                                      [
                                        _c("label", { staticClass: "w-100" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "small font-weight-bold",
                                            },
                                            [_vm._v("Марка")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.state.objNew.marka,
                                                expression:
                                                  "state.objNew.marka",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            domProps: {
                                              value: _vm.state.objNew.marka,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.state.objNew,
                                                  "marka",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-3 text-left" },
                                      [
                                        _c("label", { staticClass: "w-100" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "small font-weight-bold",
                                            },
                                            [_vm._v("Артикул")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.state.objNew.prop.info
                                                    .brand.article,
                                                expression:
                                                  "state.objNew.prop.info.brand.article",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            domProps: {
                                              value:
                                                _vm.state.objNew.prop.info.brand
                                                  .article,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.state.objNew.prop.info
                                                    .brand,
                                                  "article",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-3 text-left" },
                                      [
                                        _c("label", { staticClass: "w-100" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "small font-weight-bold",
                                            },
                                            [_vm._v("Производитель")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.state.objNew.prop.info
                                                    .brand.name,
                                                expression:
                                                  "state.objNew.prop.info.brand.name",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            domProps: {
                                              value:
                                                _vm.state.objNew.prop.info.brand
                                                  .name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.state.objNew.prop.info
                                                    .brand,
                                                  "name",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-3 text-left" },
                                      [
                                        _c("label", { staticClass: "w-100" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "small font-weight-bold",
                                            },
                                            [_vm._v("ед.изм.")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.state.objNew.prop.info
                                                    .unit,
                                                expression:
                                                  "state.objNew.prop.info.unit",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            domProps: {
                                              value:
                                                _vm.state.objNew.prop.info.unit,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.state.objNew.prop.info,
                                                  "unit",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.state.isPS && !_vm.state.isDeleteLink
                              ? _c("div", { staticClass: "text-left" }, [
                                  _c("p", [
                                    _c("span", [_vm._v("Название:")]),
                                    _vm._v(" " + _vm._s(_vm.state.obj.name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("span", [_vm._v("Марка:")]),
                                    _vm._v(" " + _vm._s(_vm.state.obj.marka)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("span", [_vm._v("Артикул:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.state.obj.prop.info.brand.article
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("span", [_vm._v("Производитель:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.state.obj.prop.info.brand.name
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("span", [_vm._v("ед.изм.:")]),
                                    _vm._v(
                                      " " + _vm._s(_vm.state.obj.prop.info.unit)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  Object.keys(_vm.state.obj.prop.fields)
                                    .length > 0
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "font-weight-bold mt-1",
                                        },
                                        [_vm._v("Свойства:")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.state.isPS && !_vm.state.isDeleteLink
                              ? _c("div", { staticClass: "text-left" }, [
                                  _c("p", [
                                    _c("span", [_vm._v("Название:")]),
                                    _vm._v(" " + _vm._s(_vm.state.obj.name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("span", [_vm._v("Серия:")]),
                                    _vm._v(" " + _vm._s(_vm.state.obj.series)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("span", [_vm._v("Серия продукта:")]),
                                    _vm._v(
                                      " " + _vm._s(_vm.state.obj.series_p)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("span", [_vm._v("Артикул:")]),
                                    _vm._v(" " + _vm._s(_vm.state.obj.article)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("span", [_vm._v("Производитель:")]),
                                    _vm._v(" " + _vm._s(_vm.state.obj.brand)),
                                  ]),
                                  _vm._v(" "),
                                  Object.keys(_vm.state.obj.prop).length > 0
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "font-weight-bold mt-1",
                                        },
                                        [_vm._v("Свойства:")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.state.isDeleteLink &&
                            !_vm.state.isPS &&
                            Object.keys(_vm.state.obj.prop.fields).length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-left",
                                    staticStyle: {
                                      "overflow-x": "hidden",
                                      border: "1px solid grey",
                                    },
                                  },
                                  _vm._l(
                                    _vm.state.obj.prop.fields,
                                    function (el, name) {
                                      return _c("p", [
                                        _c("span", [
                                          _vm._v(_vm._s(name) + ":"),
                                        ]),
                                        _vm._v(" " + _vm._s(el)),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.state.isDeleteLink &&
                            _vm.state.isPS &&
                            Object.keys(_vm.state.obj.prop).length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-left",
                                    staticStyle: {
                                      "overflow-x": "hidden",
                                      border: "1px solid grey",
                                    },
                                  },
                                  _vm._l(
                                    _vm.state.obj.prop,
                                    function (el, name) {
                                      return _c("p", [
                                        _c("span", [
                                          _vm._v(_vm._s(name) + ":"),
                                        ]),
                                        _vm._v(" " + _vm._s(el)),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLoad
                  ? _c("div", { staticClass: "modal-footer d-flex flex-row" }, [
                      _c("div", { staticClass: "mr-auto" }, [
                        !_vm.state.isEdit
                          ? _c(
                              "div",
                              [
                                _vm.getTwoChars(_vm.state.obj.base_uid) === "bs"
                                  ? _c("font-awesome-icon", {
                                      staticStyle: {
                                        color: "grey",
                                        cursor: "pointer",
                                      },
                                      attrs: { icon: "fa-regular fa-star" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickIcon()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.getTwoChars(_vm.state.obj.base_uid) === "ps"
                                  ? _c("font-awesome-icon", {
                                      staticStyle: {
                                        color: "goldenrod",
                                        cursor: "pointer",
                                      },
                                      attrs: { icon: "fa-solid fa-award" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickIcon()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.state.isEdit
                          ? _c("div", [
                              _vm.state.isEdit &&
                              !_vm.state.isDeleteLink &&
                              !!_vm.state.obj.base_uid
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          _vm.state.isDeleteLink = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "удалить зависимость\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm.getTwoChars(_vm.state.obj.uid) === "ps" &&
                        _vm.state.isConvert &&
                        _vm.state.obj.base_uid === null &&
                        !_vm.state.isEdit
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  title: "клонировать запись в общую базу",
                                  type: "button",
                                },
                                on: { click: _vm.clickCreateBs },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticStyle: { color: "grey" },
                                  attrs: { icon: "fa-regular fa-star" },
                                }),
                                _vm._v(
                                  "\n                        создать\n                    "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.state.isEdit &&
                        _vm.getTwoChars(_vm.state.obj.uid) === "pr"
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-default text-danger",
                                attrs: { type: "button" },
                                on: { click: _vm.clickDeletePr },
                              },
                              [_vm._v("удалить")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.state.isEdit &&
                        _vm.getTwoChars(_vm.state.obj.uid) === "pr"
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: { type: "button" },
                                on: { click: _vm.clickSetEditPr },
                              },
                              [_vm._v("редактировать")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.state.isEdit
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  type: "button",
                                  title: "клонировать запись в личную базу",
                                },
                                on: { click: _vm.clickCreatePr },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticStyle: { color: "royalblue" },
                                  attrs: { icon: "fa-regular fa-circle-user" },
                                }),
                                _vm._v(
                                  "\n                        создать\n                    "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.state.isEdit
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                                on: { click: _vm.clickReset },
                              },
                              [_vm._v("отмена")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.state.isEdit
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                                on: { click: _vm.clickEnter },
                              },
                              [_vm._v("вставить")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.state.isEdit
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                                on: { click: _vm.clickPrSave },
                              },
                              [_vm._v("сохранить")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: { type: "button" },
                            on: { click: _vm.closeModal },
                          },
                          [_vm._v("закрыть")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }