var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMounted
    ? _c("div", { staticClass: "h-100" }, [
        _c("div", { staticClass: "container-fluid m1" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-12 col-xl-8" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "h1",
                    { staticClass: "mb-0" },
                    [
                      _vm._v(
                        _vm._s(_vm.nbi.projectInfo.name) +
                          "\n                            "
                      ),
                      _c("font-awesome-icon", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { icon: "fa-solid fa-gear", title: "настройки" },
                        on: { click: _vm.goGetSetting },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "nav",
                {
                  staticClass: "navbar navbar-expand pl-0 pt-0",
                  staticStyle: { "margin-left": "-0.5rem" },
                },
                [
                  _c("div", { staticClass: "container-fluid" }, [
                    _c("div", { staticClass: "navbar-nav" }, [
                      _c("div", { staticClass: "nav-item" }, [
                        _c(
                          "button",
                          {
                            staticClass: "nav-link btn",
                            class: { active: _vm.state.swOnOpt },
                            on: {
                              click: function ($event) {
                                _vm.state.swOnOpt = !_vm.state.swOnOpt
                              },
                            },
                          },
                          [_vm._v("настройки")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "button",
                          {
                            staticClass: "dropdown-toggle nav-link btn",
                            staticStyle: { cursor: "pointer" },
                            attrs: { "data-toggle": "dropdown" },
                          },
                          [_vm._v("вставить лист")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dropdown-menu" },
                          _vm._l(
                            _vm.fListListTypes.filter(
                              (item) => item.parent === "_"
                            ),
                            function (val) {
                              return _c("span", { key: val.name }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickAddPage(val.name)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(val.link_content.name))]
                                ),
                                _vm._v(" "),
                                val.name === "text"
                                  ? _c(
                                      "span",
                                      _vm._l(
                                        _vm.fListListTypes.filter(
                                          (item) => item.parent !== "_"
                                        ),
                                        function (val2) {
                                          return _c(
                                            "span",
                                            { key: val2.name },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "dropdown-item ml-3",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clickAddPage(
                                                        val2.name
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      val2.link_content.name
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "nav-item" }, [
                        !_vm.state.isThree
                          ? _c(
                              "button",
                              {
                                staticClass: "btn nav-link",
                                on: {
                                  click: function ($event) {
                                    _vm.state.isThree = true
                                  },
                                },
                              },
                              [_vm._v("режим структуры")]
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "btn nav-link active",
                                on: {
                                  click: function ($event) {
                                    _vm.state.isThree = false
                                  },
                                },
                              },
                              [_vm._v("режим списка")]
                            ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "nav-item" }, [
                        _c(
                          "button",
                          {
                            staticClass: "nav-link btn",
                            class: { active: _vm.state.swImportW },
                            on: {
                              click: function ($event) {
                                _vm.state.swImportW = !_vm.state.swImportW
                              },
                            },
                          },
                          [_vm._v("импорт")]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm.state.swOnOpt
                    ? _c("div", { staticClass: "card" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-header p-1 pl-2",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                _vm.state.swOnOpt = false
                              },
                            },
                          },
                          [_vm._v("настройки")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              _vm._l(this.fListTypes, function (el) {
                                return _c(
                                  "div",
                                  { key: el.id, staticClass: "row mt-3" },
                                  [
                                    _c("div", { staticClass: "col-4" }, [
                                      _vm._v(
                                        _vm._s(_vm.getNameSuffixForTable(el))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-2" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: el.suffix,
                                            expression: "el.suffix",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass: "form-control",
                                        domProps: { value: el.suffix },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              el,
                                              "suffix",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.local.setting.is_num_title,
                                      expression: "local.setting.is_num_title",
                                    },
                                  ],
                                  staticClass: "apple-switch",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.local.setting.is_num_title
                                    )
                                      ? _vm._i(
                                          _vm.local.setting.is_num_title,
                                          null
                                        ) > -1
                                      : _vm.local.setting.is_num_title,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.local.setting.is_num_title,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.local.setting,
                                              "is_num_title",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.local.setting,
                                              "is_num_title",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.local.setting,
                                          "is_num_title",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" нумерация титула"),
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(
                                  "\n                                            шрифт\n                                            "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.local.setting.font_name,
                                      expression: "local.setting.font_name",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  domProps: {
                                    value: _vm.local.setting.font_name,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.local.setting,
                                        "font_name",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        _vm.local.setting.font_name = "isocpeur"
                                      },
                                    },
                                  },
                                  [_vm._v("isocpeur")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        _vm.local.setting.font_name =
                                          "Arial Narrow"
                                      },
                                    },
                                  },
                                  [_vm._v("Arial Narrow")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        _vm.local.setting.font_name = "Arial"
                                      },
                                    },
                                  },
                                  [_vm._v("Arial")]
                                ),
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("размер шрифта"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.local.setting.font_size,
                                      expression: "local.setting.font_size",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  domProps: {
                                    value: _vm.local.setting.font_size,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.local.setting,
                                        "font_size",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.local.setting.font_italic,
                                      expression: "local.setting.font_italic",
                                    },
                                  ],
                                  staticClass: "apple-switch",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.local.setting.font_italic
                                    )
                                      ? _vm._i(
                                          _vm.local.setting.font_italic,
                                          null
                                        ) > -1
                                      : _vm.local.setting.font_italic,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.local.setting.font_italic,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.local.setting,
                                              "font_italic",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.local.setting,
                                              "font_italic",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.local.setting,
                                          "font_italic",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" italic"),
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _c("label", { staticClass: "w-100 mt-2" }, [
                                _c("span", { staticClass: "small" }, [
                                  _vm._v("имя файла при экспорте"),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.local.setting.file_name,
                                      expression: "local.setting.file_name",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    placeholder: "имя файла при экспорте",
                                  },
                                  domProps: {
                                    value: _vm.local.setting.file_name,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.local.setting,
                                        "file_name",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("запас кабеля"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.local.setting.zapas,
                                      expression: "local.setting.zapas",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  domProps: { value: _vm.local.setting.zapas },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.local.setting,
                                        "zapas",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("отметка розеток"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.local.setting.potr_otm,
                                      expression: "local.setting.potr_otm",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  domProps: {
                                    value: _vm.local.setting.potr_otm,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.local.setting,
                                        "potr_otm",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v("отметка выключателей"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.local.setting.vykl_otm,
                                      expression: "local.setting.vykl_otm",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  domProps: {
                                    value: _vm.local.setting.vykl_otm,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.local.setting,
                                        "vykl_otm",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.local.setting.is_auto_label,
                                      expression: "local.setting.is_auto_label",
                                    },
                                  ],
                                  staticClass: "apple-switch",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.local.setting.is_auto_label
                                    )
                                      ? _vm._i(
                                          _vm.local.setting.is_auto_label,
                                          null
                                        ) > -1
                                      : _vm.local.setting.is_auto_label,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.local.setting.is_auto_label,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.local.setting,
                                              "is_auto_label",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.local.setting,
                                              "is_auto_label",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.local.setting,
                                          "is_auto_label",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" автоматические label"),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row justify-content-center" },
            [
              _c(
                "div",
                {
                  class: {
                    "col-12": !_vm.state.swImportW,
                    "col-xl-8": !_vm.state.swImportW,
                    "col-6": _vm.state.swImportW,
                  },
                },
                [
                  _c("nested-list", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.state.isThree,
                        expression: "!state.isThree",
                      },
                    ],
                    staticClass: "item-sub",
                    attrs: {
                      setting: _vm.local.setting,
                      "list-types": _vm.fListListTypes,
                      "list-pages-template": _vm.listListTypes,
                      nbi: _vm.nbi,
                    },
                    model: {
                      value: _vm.local.table,
                      callback: function ($$v) {
                        _vm.$set(_vm.local, "table", $$v)
                      },
                      expression: "local.table",
                    },
                  }),
                  _vm._v(" "),
                  _c("list-all-pages", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.state.isThree,
                        expression: "state.isThree",
                      },
                    ],
                    attrs: {
                      "modal-id": "modalListAllPages",
                      pages: _vm.local.table,
                      nbi: _vm.nbi,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("import-div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.state.swImportW && _vm.state.isThree,
                    expression: "state.swImportW && state.isThree",
                  },
                ],
                staticClass: "col-6",
                attrs: {
                  "modal-id": "modalImportDiv",
                  "is-show": _vm.state.swImportW && _vm.state.isThree,
                },
              }),
              _vm._v(" "),
              _c("import-div-project", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.state.swImportW && !_vm.state.isThree,
                    expression: "state.swImportW && !state.isThree",
                  },
                ],
                staticClass: "col-6",
                attrs: {
                  "modal-id": "modalImportDivProject",
                  "is-show": _vm.state.swImportW && !_vm.state.isThree,
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }