import { render, staticRenderFns } from "./nestedCategoryFields.vue?vue&type=template&id=5b0bc368&scoped=true&"
import script from "./nestedCategoryFields.vue?vue&type=script&lang=js&"
export * from "./nestedCategoryFields.vue?vue&type=script&lang=js&"
import style0 from "./nestedCategoryFields.vue?vue&type=style&index=0&id=5b0bc368&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0bc368",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/aleksej/app/dev.speka/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b0bc368')) {
      api.createRecord('5b0bc368', component.options)
    } else {
      api.reload('5b0bc368', component.options)
    }
    module.hot.accept("./nestedCategoryFields.vue?vue&type=template&id=5b0bc368&scoped=true&", function () {
      api.rerender('5b0bc368', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/sub/nestedCategoryFields.vue"
export default component.exports