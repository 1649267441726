<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount">
        <div class="modal-dialog modal-lg" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span v-if="state.isEdit">редактировать</span>
                    <span v-else>вставить потребитель</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal"
                                       style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="text-right">
                        <button v-if="!isImport" class="btn btn-default" @click="isImport=true"
                                style="text-decoration: underline">импорт настроек
                        </button>
                    </div>
                    <div v-if="isImport" class="row">
                        <div class="col-8 pr-1">
                            <multiselect
                                ref="ms4"
                                v-model="state.obj.import"
                                placeholder="выберите потребитель для импорта настроек"
                                :options="local.fListImportPotr"
                                :option-height="104"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms4','name2')"
                                @input="onChange"
                                :internal-search="false"
                                @search-change="customSearch"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ props.option.name2 }}
                                                            </span>
                                                        </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                    <span class="option__title">
                                        {{ props.option.name2 }} - {{ props.option.es.pu }}кВт
                                    </span>
                                        <br>
                                        <span class="option__small small">
                                        {{ props.option.es.cos }}
                                    </span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-2 px-1">
                            <button class="btn btn-primary w-100" @click="clickImport(false)">импорт</button>
                        </div>
                        <div class="col-2 pl-1">
                            <button class="btn btn-primary w-100" @click="clickImport(true)">импорт всё</button>
                        </div>
                    </div>
                    <div class="row my-0">
                        <div class="col-2 pr-1">
                            <label class="w-100">
                                <span class="small">Ру, кВт </span>
                                <span class="w-100" title="Установленная мощность, кВт">
                                    <input class="form-control" v-model.number="state.obj.es.pu" placeholder="Ру, кВт"
                                           ref="v_focus" :class="{lock:lock.pu}" @keyup="lock.pu=false"/>
                                    <font-awesome-icon v-if="lock.pu" icon="fa-solid fa-lock" class="right"/>
                                </span>
                                <span v-for="e in local.listPU" class="small tag"
                                      @click="state.obj.es.pu=e">{{ e }} </span>
                            </label>
                        </div>
                        <div class="col-2 px-1">
                            <label class="w-100">
                                <span class="small d-flex justify-content-between mt-1">
                                    <span>cos</span>
                                    <span style="cursor: pointer;" @click="clickInd"
                                          title="расчёт COS при ИНДУКТИВНОЙ нагрузке">инд</span>
                                </span>
                                <span class="w-100">
                                    <input class="form-control" v-model.number="state.obj.es.cos"
                                           placeholder="установленная мощность" :class="{lock:lock.cos}"
                                           @keyup="lock.cos=false"/>
                                    <font-awesome-icon v-if="lock.cos" icon="fa-solid fa-lock" class="right"/>
                                </span>
                                <span v-for="e in local.listCos" class="small tag"
                                      @click="state.obj.es.cos=e">{{ e }} </span>
                            </label>
                        </div>
                        <div class="col-2 px-1">
                            <e-input :lock="lock" :k="'otm'" :list-tag="local.listOtm" v-model="state.obj.dop.otm"
                                     :label="'отм., мм'" :title="'Отметка установки относительно уровня маркера, мм'"/>
                        </div>
                        <div class="col-2 px-1">
                            <e-input :lock="lock" :k="'dd'" :list-tag="local.listDd" v-model="state.obj.dop.dd"
                                     :label="'запас, мм'" :title="'Запас кабеля, мм'"/>
                        </div>
                        <div class="col-2 px-1">
                            <e-input :lock="lock" :k="'dlina'" :list-tag="local.listDlina" v-model="state.obj.dop.dlina"
                                     :label="'длина, мм'" :title="'Фиксированная длин кабеля, мм'"/>
                        </div>
                        <div class="col-2 pl-4">
                            <span v-if="state.obj.es.faz===3" @click="clickFaz(1)" style="cursor: pointer;">
                                <font-awesome-icon icon="fa-solid fa-3" size="lg" style="margin-top: 2rem;"/><span
                                class="ml-1">фазы</span>
                            </span>
                            <span v-else @click="clickFaz(3)" style="cursor: pointer;">
                                <font-awesome-icon icon="fa-solid fa-1" size="lg" style="margin-top: 2rem;"/><span
                                class="ml-1">фаза</span>
                            </span>
                            <font-awesome-icon v-if="lock.faz" icon="fa-solid fa-lock"/>
                        </div>
                    </div>
                    <div class="row mt-0">
                        <div class="col-6 pr-1" @click="lock.name2=false">
                            <span class="small">название <span @click="setUpCase('name2')" style="cursor: pointer" title="сделать первую букву большой">А</span><font-awesome-icon v-if="lock.name2"
                                                                                                                                                                                   icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.name2"
                                ref="ms2"
                                v-model="state.obj.name2"
                                placeholder="name2"
                                :options="local.listName2"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms2','name2')"
                                :blockKeys="['Delete']"
                                @tag="addNewItem2"
                                :taggable="true"
                            >
                            </multiselect>
                        </div>
                        <div class="col-6 pl-1" @click="lock.name1=false">
                            <span class="small">функциональная группа
                                <span @click="setUpCase('name1')" style="cursor: pointer" title="сделать первую букву большой">А</span>
                                <span @click="copyName3" style="cursor: pointer" title="скопировать категорию">B</span>
                                <font-awesome-icon v-if="lock.name1" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.name1"
                                ref="ms1"
                                v-model="state.obj.name1"
                                placeholder="name1"
                                :options="local.listName1"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms1','name1')"
                                :blockKeys="['Delete']"
                                @tag="addNewItem1"
                                :taggable="true"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 pr-1" @click="lock.name3=false">
                            <span class="small">категория потребителя <font-awesome-icon v-if="lock.name3"
                                                                                         icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.name3"
                                ref="ms3"
                                v-model="state.obj.name3"
                                placeholder="name3"
                                :options="local.listName3"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms3','name3')"
                                :blockKeys="['Delete']"
                                @input="onChangeName3"
                            >
                            </multiselect>
                        </div>
                        <div class="col-2 pr-1" @click="lock.arr=false">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox"
                                                                                          v-model="state.obj.panel.arr"><i></i>пожар</label>
                            <font-awesome-icon v-if="lock.arr" icon="fa-solid fa-lock"/>
                        </div>
                        <div class="col-2 px-1" @click="lock.nrr=false">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox"
                                                                                          v-model="state.obj.panel.nrr"><i></i>норм.</label>
                            <font-awesome-icon v-if="lock.nrr" icon="fa-solid fa-lock"/>
                        </div>
                        <div class="col-2 pl-1" @click="lock.fr=false">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox"
                                                                                          v-model="state.obj.panel.fr"><i></i>FR</label>
                            <font-awesome-icon v-if="lock.fr" icon="fa-solid fa-lock"/>
                        </div>
                    </div>
                    <div class="row mb-0">
                        <div class="col-2 pr-1">
                            <e-input :lock="lock" :k="'i_st'" :list-tag="local.listGroupedUp"
                                     v-model="state.obj.es.i_st" :label="'Kпуск'" :title="'Кратность пускового тока'"/>
                        </div>
                        <div class="col-2 px-1">
                            <e-input :lock="lock" :k="'grouped_up'" :list-tag="local.listGroupedUp"
                                     v-model="state.obj.panel.grouped_up" :label="'кол-во'"
                                     :title="'Максимальнок количество потребителей в группе'"/>
                        </div>
                        <div class="col-2 px-1" @click="lock.avt1=false">
                            <span class="small">авт.мин. <font-awesome-icon v-if="lock.avt1"
                                                                            icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.avt1"
                                v-model="state.obj.panel.avt1"
                                :options="pLocal.listAvt"
                                :show-labels="false"
                                :close-on-select="true"
                                :allow-empty="false"
                                :blockKeys="['Delete']"
                            >
                            </multiselect>
                        </div>
                        <div class="col-2 px-1" @click="lock.avt2=false">
                            <span class="small">авт.макс. <font-awesome-icon v-if="lock.avt2" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.avt2"
                                v-model="state.obj.panel.avt2"
                                :options="pLocal.listAvt"
                                :show-labels="false"
                                :close-on-select="true"
                                :allow-empty="false"
                                :blockKeys="['Delete']"
                            >
                            </multiselect>
                        </div>
                        <div class="col-2 px-1" @click="lock.kab1=false">
                            <span class="small">сеч.мин. <font-awesome-icon v-if="lock.kab1"
                                                                            icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.kab1"
                                v-model="state.obj.panel.kab1"
                                :options="pLocal.listKab"
                                :show-labels="false"
                                :close-on-select="true"
                                :allow-empty="false"
                                :blockKeys="['Delete']"
                            >
                            </multiselect>
                        </div>
                        <div class="col-2 pl-1" @click="lock.kab2=false">
                            <span class="small">сеч.макс. <font-awesome-icon v-if="lock.kab2" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.kab2"
                                v-model="state.obj.panel.kab2"
                                :options="pLocal.listKab"
                                :show-labels="false"
                                :close-on-select="true"
                                :allow-empty="false"
                                :blockKeys="['Delete']"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div class="row mb-0">
                        <div class="col-3 pr-1" @click="lock.room_is_hide=false" title="скрывать названия помещений">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox" v-model="state.obj.room.isHide"><i></i>помещения</label>
                            <font-awesome-icon v-if="lock.room_is_hide" icon="fa-solid fa-lock"/>
                        </div>
                        <div class="col-3 pr-1" @click="clickIsOtm" title="заполнять типовые отметки, запас и длину?">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox" v-model="state.isOtm"><i></i>отметки</label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex flex-row">
                    <div class="mr-auto">
                        <font-awesome-icon icon="fa-solid fa-lock" @click="setLock(true)" title="заблокировать всё"/>
                        <font-awesome-icon icon="fa-solid fa-lock-open" class="ml-3" @click="setLock(false)" title="разблокировать всё"/>
                    </div>
                    <div>
                        <!--                        <button v-if="state.isEdit" type="button" class="btn btn-default" @click="clickDelete">удалить</button>-->
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickLocalSave">
                            сохранить
                        </button>
                        <button v-else-if="!!lastSelectObj" type="button" class="btn btn-primary" @click="clickInsert">
                            вставить
                        </button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
//todo js modal modalDraggable
import {modalDraggable, page} from "./tools";
import EInput from "./EInput";


export default {
    name: 'modal-potr',
    components: {EInput, Multiselect},
    props: {
        modalId: {type: String, default: 'modal',},
        pLocal: {type: Object, default: {},},
    },
    data: function () {
        return {
            //todo js modal add page module
            pageT: new page(this),
            rand: 1,
            local: {
                ePotr: this.pLocal.ePotr,
                listName1: [],
                listName2: [],
                listName3: this.pLocal.listName3,
                listPU: [],
                listCos: [],
                listIst: [],
                listDd: [],
                listOtm: [],
                fListImportPotr: this.pLocal.fListImportPotr,
                listGroupedUp: [],
            },
            state: {
                obj: null,
                isEdit: true,
                isOtm: false,
            },
            status: {
                loading: false,
            },
            isImport: false,
            templateObj: {
                child: [],
                name1: '',
                name2: '',
                name3: '',
                dop: {dd: 0, dlina: 0, otm: 0},
                es: {pu: '', cos: 1, i_st: '', faz: 1,},
                panel: {arr: false, nrr: true, fr: false, avt1: '', avt2: '', kab1: '', kab2: '', grouped_up: ''},
                room: {isHide: false},
                import: {},
            },
            lock: {
                pu: true,
                cos: true,
                otm: true,
                dd: true,
                dlina: true,
                faz: true,
                name1: true,
                name2: true,
                name3: true,
                arr: true,
                nrr: true,
                fr: true,
                avt1: true,
                avt2: true,
                kab1: true,
                kab2: true,
                grouped_up: true,
                i_st: true,
                room_is_hide: true,
            },
            isMount: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlEnter') this.clickLocalSave();
        });
        Bus.$on('show_' + this.modalId, () => {
            this.state.isEdit = true;
            this.showModal()
        });
        Bus.$on('showI_' + this.modalId, () => {
            this.state.isEdit = false;
            this.showModal();
        });
    },
    mounted() {
        new modalDraggable(this);
        this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
        this.isMount = true;
    },
    methods: {
        clickIsOtm() {

        },
        copyName3() {
            this.state.obj.name1 = this.state.obj.name3;
        },
        setUpCase(type) {
            let str = this.state.obj[type];
            this.state.obj[type] = str.charAt(0).toUpperCase() + str.slice(1);
        },
        onChangeName3() {
            let name3 = this.state.obj.name3;
            let pu = this.state.obj.es.pu;
            let cos = this.state.obj.es.cos;
            let set = true;
            if (name3 === 'Квартиры с плитами на природном газе') {
                pu = 4.5;
                cos = 0.96;
            } else if (name3 === 'Квартиры с плитами на сжиженном газе') {
                pu = 6;
                cos = 0.96;
            } else if (name3 === 'Квартиры с электрическими плитами') {
                pu = 10;
                cos = 0.98;
            } else if (name3 === 'Летние домики') {
                pu = 4;
            } else {
                set = false;
            }

            if (set) {
                this.state.obj.es.pu = pu;
                this.state.obj.es.cos = cos;
                this.lock.pu = false;
                this.lock.cos = false;
            }
        },
        clickInsert() {

        },
        setLock(val) {
            Object.keys(this.lock).forEach(item => this.lock[item] = val);
            if (!this.state.isOtm) {
                this.lock.otm = true;
                this.lock.dd = true;
                this.lock.dlina = true;
            }
        },
        clickFaz(f) {
            this.state.obj.es.faz = f;
            this.lock.faz = false;
        },
        showModal() {
            this.isImport = false;
            this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
            if (this.lastSelectObj) {
                this.setLock(false);
                let child = [];
                if (this.lastSelectObj.child) {
                    child = JSON.parse(JSON.stringify(this.lastSelectObj.child));
                    this.setObj(this.state.obj, child[0]);
                } else {
                    this.setObj(this.state.obj, this.lastSelectObj);
                }

                this.state.obj.child = child;
                this.state.obj.import = this.local.fListImportPotr.find(item => item.name2.toLowerCase() === this.lastSelectObj.name2.toLowerCase());

                //todo js поиск уникальных значений
                this.local.listName2 = this.local.ePotr.map(item => item.name2).filter((value, index, self) => self.indexOf(value) === index);

                let obj = [];
                if (this.lastSelectObj.child) {
                    obj = this.state.obj.child;
                } else {
                    obj = this.local.ePotr;
                }
                this.local.listName1 = obj.map(item => item.name1).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listPU = obj.map(item => item.es.pu).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listCos = obj.map(item => item.es.cos).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listIst = obj.map(item => item.es.i_st).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listDd = obj.map(item => item.dop.dd).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listDlina = obj.map(item => item.dop.dlina).filter((value, index, self) => self.indexOf(value) === index);
                this.local.listGroupedUp = obj.map(item => item.panel.grouped_up).filter((value, index, self) => self.indexOf(value) === index);
            }
            this.setLock(this.pLocal.selectArr.length > 1);


            $('#' + this.modalId).modal('show');
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    if (this.$refs.v_focus) this.$refs.v_focus.focus()
                }, 200);
            }
        },
        clickLocalSave() {
            this.pLocal.selectArr.forEach(item => {
                if (item.child) {
                    item.child.forEach(item2 => {
                        this.setObj(item2, this.state.obj);
                    })
                } else {
                    this.setObj(item, this.state.obj);
                }
            })
            this.closeModal();
        },
        setObj(obj, iObj, isExport = false) {
            if (!this.lock.name1) obj.name1 = iObj.name1;
            if (!this.lock.name2) obj.name2 = iObj.name2;
            if (!this.lock.name3) obj.name3 = iObj.name3;
            if (!this.lock.pu) obj.es.pu = iObj.es.pu;
            if (!this.lock.cos) obj.es.cos = iObj.es.cos;
            if (!this.lock.i_st) obj.es.i_st = iObj.es.i_st;
            if (!this.lock.faz) obj.es.faz = iObj.es.faz;
            if (!this.lock.dd) obj.dop.dd = iObj.dop.dd;
            if (!this.lock.dlina) obj.dop.dlina = iObj.dop.dlina;
            if (!this.lock.otm && !isExport) obj.dop.otm = iObj.dop.otm;
            if (!this.lock.arr) obj.panel.arr = iObj.panel.arr;
            if (!this.lock.nrr) obj.panel.nrr = iObj.panel.nrr;
            if (!this.lock.fr) obj.panel.fr = iObj.panel.fr;
            if (!this.lock.avt1) obj.panel.avt1 = iObj.panel.avt1;
            if (!this.lock.avt2) obj.panel.avt2 = iObj.panel.avt2;
            if (!this.lock.kab1) obj.panel.kab1 = iObj.panel.kab1;
            if (!this.lock.kab2) obj.panel.kab2 = iObj.panel.kab2;
            if (!this.lock.grouped_up) obj.panel.grouped_up = iObj.panel.grouped_up;
            if (!this.lock.room_is_hide) obj.room.isHide = iObj.room.isHide;
        },
        addNewItem1(newTag) {
            this.state.obj.name1 = newTag;
            this.local.listName1.push(newTag);
        },
        addNewItem2(newTag) {
            this.state.obj.name2 = newTag;
            this.local.listName2.push(newTag);
        },
        onOpen(r, f) {
            setTimeout(() => {
                this.$refs[r].$refs.search.value = this.state.obj[f];
            }, 10);
        },
        onChange() {
            if (!this.state.obj) this.state.isEdit = false;
        },
        clickImport(unLock) {
            let isLock=true;
            Object.keys(this.lock).forEach(item => {
                isLock = isLock && this.lock[item];
            });

            if(isLock) this.setLock(false); //если не одно поле не разблокированно, то разблокируется всё

            this.setObj(this.state.obj, this.state.obj.import, true);
            this.setLock(!unLock);
            this.isImport = false;
        },
        closeModal() {
            this.$emit('updInit');
            $('#' + this.modalId).modal('hide');
        },
        //todo js multiselect custom search
        customSearch(query) {
            if (query === '') {
                this.local.fListImportPotr = this.pLocal.fListImportPotr;
            }
            let i = 0;
            this.local.fListImportPotr = this.pLocal.fListImportPotr.filter(item => {
                // if (i > 10) return false;
                let res = true;
                query.split(' ').forEach(value => {
                    let s = item.name2;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                if (res) i++;
                return res;
            })
        },
        clickInd() {
            if (["Компьютерное рабочее место"].indexOf(this.state.obj.name3) >= 0) this.state.obj.es.cos = 0.65;
            else if (["Тепловое оборудование", "Тепловое оборудование пищеблока"].indexOf(this.state.obj.name3) >= 0) this.state.obj.es.cos = 0.98;
            else if ([
                "Рабочее освещение",
                "Аварийное освещение",
                "Наружное освещение",
                "Освещение витрин",
                "Реклама",
                "Общедомовое освещение",
            ].indexOf(this.state.obj.name3) >= 0) this.state.obj.es.cos = this.getIndLed();
            else this.state.obj.es.cos = this.getInd_();

            this.lock.cos = false;
        },
        getInd_() {
            if (this.state.obj.es.pu <= 1) return 0.65;
            else if (this.state.obj.es.pu <= 4) return 0.75;
            else if (this.state.obj.es.pu > 4) return 0.85;
        },
        getIndLed() {
            if (this.state.obj.es.pu <= 0.008) return 0.7;
            else if (this.state.obj.es.pu <= 0.02) return 0.85;
            else if (this.state.obj.es.pu > 0.02) return 0.9;
        },
    },
    computed: {
        lastSelectObj: function () {
            if (this.pLocal.selectArr.length === 0) return null;
            return this.pLocal.selectArr[this.pLocal.selectArr.length - 1];
        }
    },
}
</script>
<style scoped lang="scss">
@import '../../../sass/variables';

span.small.tag {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

svg.fa-lock {
    opacity: 0.2;

    &.right {
        position: absolute;
        right: 14px;
        top: 36px;
    }
}


input.lock {
    background-color: lighten($primary, 50%);
}

</style>
