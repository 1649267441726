<template>
    <div class="ml-2">
        <div class="row">
            <div class="col-12">
                <table class="table table-sm">
                    <tr v-for="(val,index) in this.getLocalTable" :key="index" :class="{active:index===state.activeRow,selected:isSelectedRow(val)}" @click="clickRow(index)">
                        <td class="text-left"><a @click="clickShowModal(val)" style="text-decoration: underline">{{val.name}}</a></td>
                        <td style="width: 150px">{{getDate(val.created_at)}}</td>
                        <td style="width: 50px"><a class="btn btn-link p-0" :href="'/attach/'+val.id+'/dl'">link</a></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-primary" @click="clickShowModal(null)">добавить файл</button>
            </div>
        </div>

    </div>
</template>
<script>
    import moment from "moment";
    import {ru} from "vuejs-datepicker/dist/locale";
    import Datepicker from "vuejs-datepicker";

    export default {
        name: 'edit-attach',
        components: {Datepicker},
        props: {
            table: {type: Array, default: [],},
            isActive: {type: Boolean, default: false,},
            cPage: {},
        },
        data() {
            return {
                ru: ru,
                state: {
                    activeRow: 0,
                    isFilter: false,
                },
                local: {
                    table: [],
                },
                selectedRow: '',
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (!this.isActive) return;
                if (e.key === 'insert') this.clickShowModal(null);
            });
            Bus.$on('keyDown', e => {
                if (!this.isActive) return;
                if (e.key === 40) if (this.state.activeRow + 1 < this.local.table.length) this.clickRow(this.state.activeRow + 1);//Down
                if (e.key === 38) if (this.state.activeRow > 0) this.clickRow(this.state.activeRow - 1);//Up
                if (e.key === 113) this.clickShowModal(this.activeAttach);
            });
            Bus.$on('modalAttachEditSave', obj => this.onSave(obj));
            Bus.$on('modalAttachEditDelete', obj => this.onDelete(obj));
            Bus.$on('saveAttach', obj => {
                this.activeAttach.elements = [];
                obj.forEach(item => {
                    this.activeAttach.elements.push(item.userData);
                })
                this.onSave(this.activeAttach)
            });
            Bus.$on('selectElement', id => this.onSelectElement(id));
            Bus.$on('deSelectElement', obj => {
                if (!this.state.isFilter) this.selectedRow = '';
            });
        },
        mounted() {
            this.loadCookie();
            this.updTable();
        },
        methods: {
            onSelectElement(id) {
                this.selectedRow = id;
            },
            clickRow(i) {
                this.state.activeRow = i;
            },
            loadCookie() {
                let state = this.$cookies.get('page_data_attach');
                if (!state) return;
                this.state = state;
            },
            updCookie() {
                this.$cookies.set('page_data_attach', this.state, 60 * 60 * 24);
            },
            onSave(obj) {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/project/save_attach',
                    obj: {attach: obj},
                    flash: true,
                    suffix: '_modal_show_save_attach',
                });
                Bus.$once('zResponse_modal_show_save_attach', response => {
                    this.$emit('updAttach', response.attach);
                    Vue.nextTick(() => this.updTable());
                    this.setLoading(false);
                });
                Bus.$once('zError_modal_show_save_attach', () => {
                    this.setLoading(false);
                });
            },
            onDelete(obj) {
                this.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/project/delete_attach',
                    obj: {attach: obj},
                    flash: true,
                    suffix: '_modal_show_delete_attach',
                });
                Bus.$once('zResponse_modal_show_delete_attach', response => {
                    this.$emit('updAttach', response.attach);
                    Vue.nextTick(() => this.updTable());
                    this.setLoading(false);
                });
                Bus.$once('zError_modal_show_delete_attach', () => {
                    this.setLoading(false);
                });
            },
            clickShowModal(obj) {
                if (obj) obj = this.local.table.find(item => item.id === obj.id);
                Bus.$emit('showModalAttachEdit', obj);
            },
            setLoading(state) {
                Bus.$emit('navbar', {command: 'setLoading', args: state});
            },
            updTable() {
                console.log('upd');
                this.state.activeRow = 0;
                let res = this.table;

                res.sort((a, b) => {
                    if (a.created_at > b.created_at) return -1;
                    if (a.created_at === b.created_at) return 0;
                    if (a.created_at < b.created_at) return 1;
                });

                this.local.table = res;
            },
            getDate(date) {
                return moment(date).format('DD.MM.YYYY');
                // return moment(date).format('DD.MM.YYYY HH:mm');
            },
            isSelectedRow(obj) {
                if (!!!this.cPage) return false;
                if (!!!this.cPage.options.attach) return false;
                return obj.id === this.cPage.options.attach.id;
            },
        },
        computed: {
            activeAttach: function () {
                let obj = this.getLocalTable[this.state.activeRow];
                if (obj) return obj; else return null;
            },
            getLocalTable: function () {
                return this.local.table;
            },
        },
        watch: {
            'state.activeRow'(newVal, oldVal) {
                this.updCookie();
            },
            'state.isFilter'(newVal, oldVal) {
                this.updTable();
                this.updCookie();
            },
        }
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/variables';

    tr {
        &.active {
            background-color: $secondary;
        }

        &.selected {
            background-color: $primary;
        }
    }
</style>
