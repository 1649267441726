<template>
    <div class="editor">
        <div v-if="editor && isEdit" class="mb-3">
            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                    :class="{ 'active': editor.isActive('heading', { level: 1 }) }" class="btn btn-outline-primary btn-sm">H1
            </button>
            <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                    :class="{ 'active': editor.isActive('heading', { level: 2 }) }" class="btn btn-outline-primary btn-sm">H2
            </button>
            <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                    :class="{ 'active': editor.isActive('heading', { level: 3 }) }" class="btn btn-outline-primary btn-sm">H3
            </button>
            <button @click="editor.chain().focus().setParagraph().run()"
                    :class="{ 'active': editor.isActive('paragraph') }" class="btn btn-outline-primary btn-sm">P
            </button>
            <button @click="editor.chain().focus().toggleBold().run()"
                    :class="{ 'active': editor.isActive('bold') }" class="btn btn-outline-primary btn-sm">
                <font-awesome-icon icon="fa-solid fa-bold"/>
            </button>
            <button @click="editor.chain().focus().toggleItalic().run()"
                    :class="{ 'active': editor.isActive('italic') }" class="btn btn-outline-primary btn-sm">
                <font-awesome-icon icon="fa-solid fa-italic"/>
            </button>
            <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'active': editor.isActive('strike') }" class="btn btn-outline-primary btn-sm">strike</button>
            <button @click="editor.chain().focus().toggleHighlight().run()" :class="{ 'active': editor.isActive('highlight') }" class="btn btn-outline-primary btn-sm">highlight</button>
            <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'active': editor.isActive({ textAlign: 'left' }) }" class="btn btn-outline-primary btn-sm">left</button>
            <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'active': editor.isActive({ textAlign: 'center' }) }" class="btn btn-outline-primary btn-sm">center</button>
            <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'active': editor.isActive({ textAlign: 'right' }) }" class="btn btn-outline-primary btn-sm">right</button>
            <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'active': editor.isActive({ textAlign: 'justify' }) }" class="btn btn-outline-primary btn-sm">justify
            </button>
            <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'active': editor.isActive('bulletList') }" class="btn btn-outline-primary btn-sm">bullet list</button>
            <button @click="clickHome" class="btn btn-outline-primary btn-sm">home</button>
            <button @click="clickEnd" class="btn btn-outline-primary btn-sm">end</button>
        </div>

        <editor-content :editor="editor"/>
    </div>
</template>

<script>
    import {Editor, EditorContent} from '@tiptap/vue-2'
    import StarterKit from '@tiptap/starter-kit'
    import TextAlign from '@tiptap/extension-text-align'
    import Highlight from '@tiptap/extension-highlight'
    import BulletList from '@tiptap/extension-bullet-list'
    import Table from '@tiptap/extension-table'
    import TableRow from '@tiptap/extension-table-row'
    import TableCell from '@tiptap/extension-table-cell'
    import TableHeader from '@tiptap/extension-table-header'
    import DraggableItem from './CTipTabBlock1.js'
    import dItemTable from './CTipTabBlockTable1.js'

    export default {
        name: 'c-tiptap',
        components: {
            EditorContent,
        },
        props: {
            value: {
                // type: String,
                default: 'описание',
                required: true,
            },
            isEdit: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        data() {
            return {
                editor: new Editor({
                    editable: this.isEdit,
                    extensions: [
                        StarterKit,
                        TextAlign.configure({
                            types: ['heading', 'paragraph'],
                        }),
                        Highlight,
                        DraggableItem,
                        dItemTable,
                        Table.configure({
                            resizable: true,
                            HTMLAttributes: {
                                class: 'tableBorder',
                            },
                        }),
                        TableRow,
                        TableHeader,
                        TableCell,
                    ],
                    content: this.value,
                    onUpdate: ({editor}) => {
                        let content = editor.getHTML(), json = editor.getJSON().content;

                        if (Array.isArray(json) && json.length === 1 && !json[0].hasOwnProperty("content")) {
                            content = null; // or any other default value
                        }

                        Bus.$emit('changeTiptapContent', content);
                    },
                    onTransaction: ({editor}) => {
                        let sel = editor.state.selection
                        // TODO: Check if sel range > 0
                        let resolvedPos = editor.state.doc.resolve(sel.from)
                        let node = resolvedPos.node(resolvedPos.depth)
                        Bus.$emit('blockByPosition', node);
                        // console.log(editor.state.selection.anchor);
                    },
                    // parseOptions: {
                    //     preserveWhitespace: 'full',
                    // },
                }),
                lContent: '',
                CustomBulletList: {},
                select: {},
                res: {},
            }
        },
        created: function () {
            Bus.$on('updTiptapContent', obj => {
                if (obj) this.editor.commands.setContent(obj)
            });
            Bus.$on('changeTiptapContentInit', () => {
                Vue.nextTick(() => Bus.$emit('changeTiptapContent', this.editor.getHTML()));
            });
            Bus.$on('insertTiptapBlock', obj => {
                this.editor.commands.insertContent(obj);
            });
        },
        mounted() {
            this.editor.commands.onUpdate;
        },
        methods: {
            clickEnd() {
                let r = this.editor.state.selection.anchor;
                this.editor.chain().focus().setTextSelection(r + 1).insertContent(' ').run();
            },
            clickHome() {
                let r = this.editor.state.selection.anchor;
                this.editor.chain().focus().setTextSelection(r - 1).insertContent(' ').setTextSelection(r - 1).run();
            },
        },
        beforeDestroy() {
            this.editor.destroy()
        },
        watch: {
            isEdit() {
                this.editor.setOptions({editable: this.isEdit,});
            }
        },
    }
</script>


<style lang="scss">

    .ProseMirror {
        padding: 1rem;
        /*border: 1px solid grey;*/
        h1 {
            /*color: red;*/
            margin-bottom: 0.5rem;
        }

        p {
            line-height: 1.7rem;
            text-indent: 1rem;
        }
        ul{
            margin-top: 0.6rem;
            margin-bottom: 0.6rem;
        }
    }

    .ProseMirror {
        position: relative;
    }

    .ProseMirror {
        word-wrap: break-word;
        white-space: pre-wrap;
        white-space: break-spaces;
        -webkit-font-variant-ligatures: none;
        font-variant-ligatures: none;
        font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
    }

    .ProseMirror [contenteditable="false"] {
        white-space: normal;
    }

    .ProseMirror [contenteditable="false"] [contenteditable="true"] {
        white-space: pre-wrap;
    }

    .ProseMirror pre {
        white-space: pre-wrap;
    }

    img.ProseMirror-separator {
        display: inline !important;
        border: none !important;
        margin: 0 !important;
        width: 1px !important;
        height: 1px !important;
    }

    .ProseMirror-gapcursor {
        display: none;
        pointer-events: none;
        position: absolute;
        margin: 0;
    }

    .ProseMirror-gapcursor:after {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        width: 20px;
        border-top: 1px solid black;
        animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
    }

    @keyframes ProseMirror-cursor-blink {
        to {
            visibility: hidden;
        }
    }

    .ProseMirror-hideselection *::selection {
        background: transparent;
    }

    .ProseMirror-hideselection *::-moz-selection {
        background: transparent;
    }

    .ProseMirror-hideselection * {
        caret-color: transparent;
    }

    .ProseMirror-focused .ProseMirror-gapcursor {
        display: block;
    }

    .tippy-box[data-animation=fade][data-state=hidden] {
        opacity: 0
    }


</style>

