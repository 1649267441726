<template>
    <div>
        <div class="card-body" @click="clickCloseContextMenu">
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-10">
                    <div class="btn-group btn-group-sm">
                        <button v-for="el in state.listTable" type="button" class="btn btn-primary" :class="{'active':el.isSelect}" @click="clickListTable(el)">{{ el.name }}</button>
                    </div>
                </div>

            </div>
            <input class="form-control mb-2" v-model.trim="state.search.val" ref="search">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th scope="col" class="w-2">тип</th>
                    <th scope="col" class="w-2">видимость</th>
                    <th scope="col" class="w-5">name1</th>
                    <th scope="col" class="w-5">name2</th>
                    <th scope="col" class="w-5">марка</th>
                    <th scope="col" class="w-5">марка новая</th>
                    <th scope="col" class="w-1">количество</th>
                </tr>
                </thead>
                <template v-for="val in fTable">
                    <tr :key="val.id" @click.ctrl.prevent="eTable.clickCtrlRow(val)"
                        :class="{'bg-primary-l':eTable.isSelect(val), 'bg-success-l':eTable.isActiveSave(val)}"
                        @click.exact="eTable.clickRow(val)"
                        @contextmenu.prevent="onShowContext($event,val)"
                    >
                        <td>{{ val.type }}</td>
                        <td>{{ val.vid }}</td>

                        <td class="text-left">{{ val.name1 }}</td>
                        <td class="">
                            <span class="d-flex justify-content-between">
                                <span>{{ val.name2 }}</span>
                            </span>
                        </td>
                        <td class="text-left">{{ val.marka.name }}</td>
                        <td class="text-left" :title="val.marka_new.name">
                            <span>
                                <font-awesome-icon style="color: grey" v-if="getTwoChars(val.marka_new.id)==='bs'" icon="fa-regular fa-star"/>
                                <font-awesome-icon style="color: goldenrod" v-if="getTwoChars(val.marka_new.id)==='ps'" icon="fa-solid fa-award"/>
                                <font-awesome-icon style="color: royalblue" v-if="getTwoChars(val.marka_new.id)==='pr'" icon="fa-regular fa-circle-user"/>
                                {{ val.marka_new.marka }}
                            </span>
                        </td>
                        <td>{{ val.child.length }}</td>
                    </tr>
                </template>
            </table>
        </div>
        <!--        <modal-so modal-id="modalSo1" :p-local="local" :p-state="state" @updInit="init" @selectObj="onSelectObjFromBase"/>-->
        <!--        <modal-so-info modal-id="modalSoInfo" :p-local="local" :prod-obj="{}"/>-->
        <context-menu-my ref="menu" :items="items"/>
    </div>
</template>
<script>

import uniqid from 'uniqid';
import ModalPotr from "./sub/ModalPotr";
import {eTable, page} from "./sub/tools";
import ContextMenuMy from "./sub/ContextMenuMy.vue";
import ModalSo from "./sub/ModalSo.vue";
import modalSoInfo from "./sub/ModalSoInfo.vue";


export default {
    name: 'specification1',
    components: {modalSoInfo, ModalSo, ContextMenuMy},
    props: {
        pLocal: {},
        pState: {}
    },
    data: function () {
        return {
            eTable: new eTable(this),
            pageT: new page(this),
            local: {
                table: [],
                selectArr: [],
                saveArr: [],
                fListImportPotr: this.pLocal.listImportPotr,
                ePotr: this.pLocal.ePotr,
                ePanel: this.pLocal.ePanel,
                eAvt: this.pLocal.eAvt,
                eVykl: this.pLocal.eVykl,
                eDop: this.pLocal.eDop,
                eSection: this.pLocal.eSection,
                listName3: this.pLocal.listName3,
                listAvt: this.pLocal.listAvt,
                listKab: this.pLocal.listKab,
                base: this.pLocal.base,
                psSetting: this.pLocal.psSetting,
                AW: this.pLocal.AW,
                listCategory: this.pLocal.listCategory,
            },
            currentAW: 1,
            state: {
                version: 1,
                search: this.pState.search,
                listTable: [
                    {name: 'щиты', id: 'ePanel', isSelect: false},
                    {name: 'автоматы', id: 'eAvt', isSelect: false},
                    {name: 'потребители', id: 'ePotr', isSelect: true},
                    {name: 'выключатели', id: 'eVykl', isSelect: false},
                    {name: 'дополнительно', id: 'eDop', isSelect: false},
                    {name: 'кабели', id: 'eSection', isSelect: false},
                ],
            },
            status: {
                loading: false,
            },
            items: [
                {name: 'удалить марку', link: 'delete_marka'},
                {name: '---'},
                {name: 'переключить видимость щита', link: 'hideRu'},
            ],
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (this.isAW) {
                if (e.key === 'f2') this.showModal();
                if (e.key === 'down') this.eTable.keyDownArrow();
                if (e.key === 'up') this.eTable.keyUpArrow();
                if (e.key === 'esc') Bus.$emit('clickBody');
                if (e.key === 'ctrlS') this.clickSave();
            }
        });
        Bus.$on('updTable', () => this.init());
        Bus.$on('updSelectArr', () => this.eTable.updSelectArr());
        Bus.$on('dotSelect', () => this.eTable.onDotSelect());
        Bus.$on('dotUnSelect', () => this.eTable.onDotUnSelect());
        Bus.$on('dotInverse', () => this.eTable.onDotInverse());
        Bus.$on('onShowContextMenu', obj => {
            if (this.$refs[obj.refs] === undefined) return;
            this.$refs[obj.refs].open(obj.e, obj.el)
        });
        Bus.$on('contextMenu_menu', (o) => {
            if (o.link === 'delete_marka') this.onDeleteMarka(o.obj);
        });

    },
    mounted() {
        this.init();
        this.pageT.loadLS();
        this.local.selectArr = [];
        if (!!this.local.table[0]) this.local.selectArr.push(this.local.table[0]);
        setTimeout(() => this.$refs.search.focus(), 200);
        // setTimeout(() => this.showModal(), 200);
    },
    methods: {
        clickListTable(obj) {
            this.state.listTable.forEach(item => {
                item.isSelect = item.id === obj.id;
            })
            this.pageT.updLS();
        },
        onDeleteMarka(obj) {
            if (!!!obj) return;
            obj.marka_new.id = null;
            obj.marka_new.marka = '';
            obj.marka_new.name = null;
            this.local.saveArr.push(obj);
        },
        clickCloseContextMenu() {
            Bus.$emit('clickBody');
        },
        onShowContext(e, el) {
            Bus.$emit('onShowContextMenu', {e: e, el: el, refs: 'menu'});
        },
        clickSave() {
            this.pageT.clickSave('/marka_element/save_2', {save_arr: this.local.saveArr}, '_save_2');
            Bus.$once('zResponse_save_2', response => {
                this.local.saveArr.length = 0;
                Bus.$emit('updTable');
                Bus.$emit('updSelectArr');
            });
        },
        onSelectObjFromBase(obj) {

        },
        showModal() {
            if (this.local.selectArr.length === 0) return;
            Bus.$emit('show_modalSo1', {selectArr: this.local.selectArr, saveArr: this.local.saveArr});
        },
        init() {
            this.local.table = [];

            this.initEPotr();
            this.initEPanel();
            this.initEAvt();
            this.initEVykl();
            this.initEDop();
            // this.initESection();
        },
        initEPotr() {
            let res = [];
            this.pLocal.ePotr.forEach(item => {
                let p = res.find(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                    && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id && item2.class === 'ePotr');
                if (!p) {
                    let o = {
                        id: uniqid(),
                        class: 'ePotr',
                        type: item.type,
                        vid: item.vid,
                        name1: item.name1,
                        name2: item.name2,
                        name3: item.name3,
                        marka: item.marka,
                        marka_new: item.marka_new,
                        child: this.pLocal.ePotr.filter(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                            && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id),
                    };
                    res.push(o);
                }
            })

            res.sort((a, b) => {
                if (a.type === b.type) {
                    if (a.vid === b.vid) return 0;
                    return a.vid > b.vid ? 1 : -1;
                }
                return a.type > b.type ? 1 : -1;
            });
            res.forEach(item => this.local.table.push(item));

        },
        initEPanel() {
            let res = [];
            this.pLocal.ePanel.forEach(item => {
                let p = res.find(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                    && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id && item2.class === 'ePanel');
                if (!p) {
                    let o = {
                        id: uniqid(),
                        class: 'ePanel',
                        type: item.type,
                        vid: item.vid,
                        name1: item.name1,
                        name2: item.name2,
                        name3: item.name3,
                        marka: item.marka,
                        marka_new: item.marka_new,
                        child: this.pLocal.ePanel.filter(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                            && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id),
                    };
                    res.push(o);
                }
            })
            res.sort((a, b) => {
                if (a.type === b.type) {
                    if (a.vid === b.vid) return 0;
                    return a.vid > b.vid ? 1 : -1;
                }
                return a.type > b.type ? 1 : -1;
            });
            res.forEach(item => this.local.table.push(item));

        },
        initEAvt() {
            let res = [];
            this.pLocal.eAvt.forEach(item => {
                let p = res.find(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                    && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id && item2.class === 'eAvt');
                if (!p) {
                    let o = {
                        id: uniqid(),
                        class: 'eAvt',
                        type: item.type,
                        vid: item.vid,
                        name1: item.name1,
                        name2: item.name2,
                        name3: item.name3,
                        marka: item.marka,
                        marka_new: item.marka_new,
                        child: this.pLocal.eAvt.filter(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                            && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id),
                    };
                    res.push(o);
                }
            })
            res.sort((a, b) => {
                if (a.type === b.type) {
                    if (a.vid === b.vid) return 0;
                    return a.vid > b.vid ? 1 : -1;
                }
                return a.type > b.type ? 1 : -1;
            });
            res.forEach(item => this.local.table.push(item));

        },
        initEVykl() {
            let res = [];
            this.pLocal.eVykl.forEach(item => {
                let p = res.find(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                    && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id && item2.class === 'eVykl');
                if (!p) {
                    let o = {
                        id: uniqid(),
                        class: 'eVykl',
                        type: item.type,
                        vid: item.vid,
                        name1: item.name1,
                        name2: item.name2,
                        name3: item.name3,
                        marka: item.marka,
                        marka_new: item.marka_new,
                        child: this.pLocal.eVykl.filter(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                            && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id),
                    };
                    res.push(o);
                }
            })
            res.sort((a, b) => {
                if (a.type === b.type) {
                    if (a.vid === b.vid) return 0;
                    return a.vid > b.vid ? 1 : -1;
                }
                return a.type > b.type ? 1 : -1;
            });
            res.forEach(item => this.local.table.push(item));

        },
        initEDop() {
            let res = [];
            this.pLocal.eDop.forEach(item => {
                let p = res.find(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                    && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id && item2.class === 'eDop');
                if (!p) {
                    let o = {
                        id: uniqid(),
                        class: 'eDop',
                        type: item.type,
                        vid: item.vid,
                        name1: item.name1,
                        name2: item.name2,
                        name3: item.name3,
                        marka: item.marka,
                        marka_new: item.marka_new,
                        child: this.pLocal.eDop.filter(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                            && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id),
                    };
                    res.push(o);
                }
            })
            res.sort((a, b) => {
                if (a.type === b.type) {
                    if (a.vid === b.vid) return 0;
                    return a.vid > b.vid ? 1 : -1;
                }
                return a.type > b.type ? 1 : -1;
            });
            res.forEach(item => this.local.table.push(item));

        },
        initESection() {
            let res = [];
            this.pLocal.eSection.forEach(item => {
                let p = res.find(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                    && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id && item2.class === 'eSection');
                if (!p) {
                    let o = {
                        id: uniqid(),
                        class: 'eSection',
                        type: item.type,
                        vid: item.vid,
                        name1: item.name1,
                        name2: item.name2,
                        name3: item.name3,
                        marka: item.marka,
                        marka_new: item.marka_new,
                        child: this.pLocal.eSection.filter(item2 => item2.name1 === item.name1 && item2.name2 === item.name2 && item2.name3 === item.name3 && item2.type === item.type && item2.vid === item.vid
                            && item2.marka.id === item.marka.id && item2.marka_new.id === item.marka_new.id),
                    };
                    res.push(o);
                }
            })
            res.sort((a, b) => {
                if (a.type === b.type) {
                    if (a.vid === b.vid) return 0;
                    return a.vid > b.vid ? 1 : -1;
                }
                return a.type > b.type ? 1 : -1;
            });
            res.forEach(item => this.local.table.push(item));

        },
        getTwoChars(uid) {
            if (!uid) return '';
            return String(uid).slice(0, 2);
        },
    },
    computed: {
        isAW: function () {
            return this.local.AW !== undefined && this.local.AW[0] === 'root' + this.currentAW;
        },
        fTable: function () {
            if (this.state.search.val === '') return this.ffTable;
            return this.ffTable.filter(item => {
                let res = true;
                this.state.search.val.split(' ').forEach(value => {
                    let s = item.name2 + item.name1 + item.type + item.vid;
                    res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                });
                return res;
            })
        },
        ffTable: function () {
            return this.local.table.filter(item => {
                let k = false;
                this.state.listTable.forEach(item2 => {
                    if (item2.isSelect) {
                        k = k || item.class === item2.id;
                    }
                })
                return k;
            })
        },
        lastSelectObj: function () {
            if (this.local.selectArr.length === 0) return null;
            return this.local.selectArr[this.local.selectArr.length - 1];
        },
    },
    watch: {
        'fTable': {
            handler: function (newVal, oldVal) {
                this.eTable.clearSelectArr();
            },
            deep: false
        },
    }
}
</script>
