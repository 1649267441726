<template>
    <div>
        <div class="container-fluid m1">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="mb-0">{{ local.project.name }}
                                <font-awesome-icon icon="fa-solid fa-list" title="содержание" style="cursor: pointer"
                                                   @click="clickGoPages"/>
                            </h1>
                        </div>
                    </div>

                    <ul class="nav nav-tabs mt-4">
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(0)" :class="{active:state.activeTab===0}">общая информация</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(1)" :class="{active:state.activeTab===1}">участники</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(2)" :class="{active:state.activeTab===2}">bakup</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(3)" :class="{active:state.activeTab===3}">финансы</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="clickTab(4)" :class="{active:state.activeTab===4}">файлы</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane container" :class="{active:state.activeTab===0}">
                            <div class="row mt-3">
                                <div class="col-3">
                                    <input class="form-control" v-model="local.ps_info.number"
                                           placeholder="шифр проекта">
                                </div>
                                <div class="col-6">
                                    <input class="form-control" v-model="local.project.name" placeholder="название">
                                </div>
                                <div class="col-1">
                                    <multiselect
                                        v-model="local.ps_info.stage"
                                        :options="listStage"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :allow-empty="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-2">
                                    <multiselect
                                        v-model="local.project.status"
                                        :options="listStatus.map(item => item.id)"
                                        :searchable="false"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :custom-label="opt => toolM.labelSearch_1(listStatus,opt)"
                                        :allow-empty="false"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="mb-0">
                                        <span class="small">объект проектирования</span>
                                    </label>
                                    <input class="form-control" v-model.trim="local.ps_info.address"
                                           placeholder="объект проектирования"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1">
                                    <input class="form-control" v-model.trim="local.ps_info.section.number"
                                           placeholder="номер"/>
                                </div>
                                <div class="col-5">
                                    <input class="form-control" v-model.trim="local.ps_info.section.name"
                                           placeholder="раздел"/>
                                </div>
                                <div class="col-1">
                                    <input class="form-control" v-model.trim="local.ps_info.sub_section.number"
                                           placeholder="номер"/>
                                </div>
                                <div class="col-5">
                                    <input class="form-control" v-model.trim="local.ps_info.sub_section.name"
                                           placeholder="подраздел"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1">
                                    <input class="form-control" v-model.trim="local.ps_info.part.number"
                                           placeholder="номер"/>
                                </div>
                                <div class="col-5">
                                    <input class="form-control" v-model.trim="local.ps_info.part.name"
                                           placeholder="часть"/>
                                </div>
                                <div class="col-1">
                                    <input class="form-control" v-model.trim="local.ps_info.book.number"
                                           placeholder="номер"/>
                                </div>
                                <div class="col-5">
                                    <input class="form-control" v-model.trim="local.ps_info.book.name"
                                           placeholder="книга"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label class="mb-0">
                                        <span class="small">название проекта</span>
                                    </label>
                                    <input class="form-control" v-model.trim="nameProject" disabled/>
                                </div>
                                <div class="col-3">
                                    <label class="mb-0">
                                        <span class="small">начало</span>
                                    </label>
                                    <datepicker v-model="local.ps_info.start_date" :language="ru"
                                                :format="toolM.customFormatter" inputClass="form-control"></datepicker>
                                </div>
                                <div class="col-3">
                                    <label class="mb-0">
                                        <span class="small">окончание</span>
                                    </label>
                                    <datepicker v-model="local.ps_info.stop_date" :language="ru"
                                                :format="toolM.customFormatter" inputClass="form-control"></datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex justify-content-between">
                                    <!--//todo-code-vue multiselect obj-->
                                    <multiselect
                                        v-model="local.ps_info.organization"
                                        :options="local.listOrganization.map(item => {return {id:item.id, name: item.name}})"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :custom-label="opt => toolM.labelSearch_2(local.listOrganization,opt)"
                                        :allow-empty="true"
                                        placeholder="организация"
                                        tag-placeholder="создать новую"
                                        @tag="addNewOrg"
                                        :taggable="true"
                                    >
                                        <template v-slot="beforeList">
                                            <button class="form-control btn btn-sm btn-outline-primary p-0"
                                                    @click="clickResetOrg">очистить
                                            </button>
                                        </template>
                                    </multiselect>
                                    <font-awesome-icon icon="fa-solid fa-list" size="lg" class="mt-2 ml-2 mr-2"
                                                       data-toggle="modal" data-target="#myModal"/>
                                </div>
                                <div class="col-6 d-flex justify-content-between">
                                    <multiselect
                                        v-model="local.project.tags"
                                        placeholder="теги"
                                        label="name"
                                        track-by="name"
                                        :options="local.listTag"
                                        :multiple="true"
                                        :taggable="true"
                                        tag-placeholder="создать новый"
                                        @tag="addTag"
                                    ></multiselect>
                                    <font-awesome-icon icon="fa-solid fa-list" size="lg" class="mt-2 ml-2 mr-2" @click="showModalListTag"/>
                                </div>
                                <!--                                <div class="col-2">-->
                                <!--                                    <button class="btn form-control btn-primary" data-toggle="modal" data-target="#myModal">организации</button>-->
                                <!--                                </div>-->
                            </div>
                            <div class="row">
                                <c-collapse-block name="комментарий" v-model="local.ps_info.comm" :is-edit="true"
                                                  class="w-100" :expand="local.ps_info.comm!==''"/>
                            </div>
                            <div class="row">
                                <button type="button" class="btn btn-primary" @click="clickClone">клонировать проект</button>
                            </div>
                        </div>
                        <div class="tab-pane container" :class="{active:state.activeTab===1}">
                            <fio-stamp :project-info="local.ps_info" :list-fio="local.listFio" :nbi="nbi"/>
                        </div>
                        <div class="tab-pane container" :class="{active:state.activeTab===2}">
                            <div class="row mt-3">
                                <div v-for="el in listBackup" class="col-12">
                                    <button v-if="el.name!=='upd'" class="form-control btn btn-primary" @click="clickBackup(el)">{{ el.name }} {{ el.size }}кБ</button>
                                    <button v-if="el.name==='upd'" class="form-control btn btn-primary" @click="clickBackup(el)">последняя загрузка {{ el.size }}кБ</button>
                                </div>
                            </div>
                        </div>

                        <!--                        <div v-if="state.activeTab===0" class="card" style="border-top: none;">-->
                        <!--                            <e-potr0 v-if="isMounted" :p-local="local" :p-state="state"/>-->
                        <!--                        </div>-->

                        <!--                        <div class="tab-pane container" :class="{active:state.activeTab===3}">-->
                        <div v-if="state.activeTab===3" class="card" style="border-top: none;">
                            <finance v-if="isMounted" :p-local="local" :p-state="state"/>
                        </div>

                        <div class="tab-pane container mt-3" :class="{active:state.activeTab===4}">
                            <div class="card p-3">
                                <div class="row m-0">
                                    <div v-if="local.gofInfo.id===''" class="col-12">
                                        <div v-if="gofState" class="d-flex justify-content-between">
                                            <input class="form-control" v-model.trim="local.gofInfo.name" ref="add_gof" placeholder="название группы">
                                            <button class="form-control btn btn-primary w-auto ml-2" @click="clickCreateGof_()">добавить</button>
                                            <button class="form-control btn btn-primary w-auto ml-2" @click="clickCreateGofReset()">отмена</button>
                                        </div>
                                        <button v-if="!gofState" class="btn btn-link" @click="clickCreateGof()">создать группу файлов</button>
                                    </div>

                                    <div v-if="local.gofInfo.id!==''" class="col-12">
                                        <div v-if="!gofState" class="d-inline">{{ local.gofInfo.name }}</div>
                                        <a v-if="!gofState" class="btn btn-link d-inline" @click="clickEditGof()">редактировать</a>
                                        <div v-if="gofState" class="d-flex justify-content-between">
                                            <input class="form-control mb-3" v-model.trim="local.gofInfo.name" ref="gof_edit" placeholder="название группы">
                                            <button class="form-control btn btn-primary w-auto ml-3" @click="clickSaveGof()">сохранить</button>
                                            <button class="form-control btn btn-primary w-auto ml-3" @click="gofState=false">отмена</button>
                                            <button class="form-control btn btn-primary w-auto ml-3" @click="clickDeleteGof()">удалить</button>
                                        </div>
                                        <div v-if="gofState" class="d-flex justify-content-between">
                                            <multiselect
                                                v-model="local.add_project"
                                                :options="local.list_projects"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :show-labels="false"
                                                :allow-empty="false"
                                                :custom-label="opt => local.list_projects.find(item => item.uid === opt.uid).name"
                                                placeholder="выберите проект"
                                            >
                                            </multiselect>
                                            <button v-if="gofState" class="form-control btn btn-primary w-auto ml-3" @click="clickAddProjectToGof()" title="добавить проект в группу">добавить проект
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="isMounted" v-for="el in local.gof" class="card p-3">
                                <div class="row" v-if="gofInfo.prop!==undefined">
                                    <div class="col-3">{{ el.name }} <span v-if="el.uid===gofInfo.prop.main_uid"> (главный)</span> </div>
                                    <div class="col-3">{{ el.number }}</div>
                                    <div class="col-5">{{ el.name_project }}</div>
                                    <div class="col-1"><a v-if="el.uid!==gofInfo.prop.main_uid" class="btn-link text-danger" @click="clickDeleteProjectGof(el.uid)" style="cursor: pointer">удалить</a></div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card w-100">
                                            <a class="card-link" data-toggle="collapse" :href="'#users'+el.uid">
                                                <div class="card-header p-1 pl-3 text-dark">
                                                    <template v-for="us in el.users">
                                                        <span class="mr-3" :class="{'font-weight-bold':us.id===nbi.authUser.id}">{{ us.name }}</span>
                                                    </template>
                                                </div>
                                            </a>
                                            <div :id="'users'+el.uid" class="collapse1">
                                                <div class="card-body">
                                                    <div class="row" v-for="us in el.users">
                                                        <div class="col-2">
                                                            <span :class="{'font-weight-bold':us.id===nbi.authUser.id}">{{ us.name }}</span>
                                                            <font-awesome-icon v-if="us.id!==nbi.authUser.id" icon="fa-solid fa-xmark" class="text-danger ml-3" style="cursor: pointer"
                                                                               title="удалить пользователя" @click="clickRemoveUserAdd(el.uid, us.id)"/>
                                                        </div>
                                                        <div class="col-10">
                                                            <span class="mr-4" :class="{'font-weight-bold':us.prop.isAdmin}" style="cursor: pointer"
                                                                  @click="clickAdminGof(el.uid, us.id)">администратор</span>
                                                            <template v-for="r in us.prop.razdel">
                                                                <span class="mr-2" :title="r.title" style="cursor: pointer" :class="{'font-weight-bold':r.check}"
                                                                      @click="clickRazdelGof(el.uid, us.id, r.name)">{{ r.name }}</span>
                                                            </template>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div v-if="addUser[el.uid].state" class="d-flex justify-content-between">
                                                                <input class="form-control" v-model.trim="addUser[el.uid].name" ref="add_user" placeholder="имя пользователя">
                                                                <button class="form-control btn btn-primary w-auto ml-2" @click="clickAddUserAdd(el.uid)">добавить</button>
                                                                <button class="form-control btn btn-primary w-auto ml-2" @click="clickAddUserReset(el.uid)">отмена</button>
                                                            </div>
                                                            <button v-if="!addUser[el.uid].state && isAdminForGof(el.uid)" class="form-control btn btn-sm btn-primary" @click="clickAddUser(el.uid)">
                                                                добавить пользователя
                                                            </button>
                                                        </div>
                                                        <div class="col-4"></div>
                                                        <div class="col-2">
                                                            <button v-if="isAdminForGof(el.uid)" class="form-control btn btn-success" @click="clickSaveUser(el.uid,el.users)">сохранить</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-org-logo :list-organization="local.listOrganization" modal-id="myModal"/>
        <modal-fio-sign :list-fio="local.listFio" modal-id="myModalFio"/>
        <!--        <modal-attach-edit modal-id="modalAttach"/>-->
        <modal-list-tag :list-tag="local.listTag"/>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import nestedList from "./sub/nestedList";
import CCollapseBlock from "./sub/CCollapseBlock";
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import {ru} from 'vuejs-datepicker/dist/locale'
import ModalFioSign from "./sub/ModalFioSign";
import ModalOrgLogo from "./sub/ModalOrgLogo";
import FioStamp from "./sub/FioStamp";
import ModalAttachEdit from "./sub/ModalAttachEdit";
import {page, toolM} from "./sub/tools";
import ModalListFioA from "./sub/ModalListFioA";
import ModalListTag from "./sub/ModalListTag";
import ModalFinance from "./sub/ModalFinance.vue";
import Finance from "./sub/Finance.vue";
import CTiptap from "./tipTap/CTiptap.vue";

export default {
    name: "page-project-show",
    components: {
        CTiptap,
        Finance,
        ModalFinance,
        ModalOrgLogo,
        Multiselect,
        nestedList,
        CCollapseBlock,
        Datepicker,
        ModalFioSign,
        ModalAttachEdit,
        ModalListFioA,
        ModalListTag
    },
    props: ['project', 'listOrganization', 'listFio', 'psInfo', 'attach', 'listTag', 'finance', 'listBackup', 'gof', 'gofInfo'],
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            ru: ru,
            search: '',
            listStage: ['П', 'Р', 'ИД'],
            listStatus: [
                {id: 0, name: 'закрытый'},
                {id: 1, name: 'открытый'},
            ],
            old: {
                username: 'qwe',
                pass: 'qwe',
            },
            pass: '',
            nbi: {
                projectInfo: {},
                authUser: {
                    prop: {
                        setting: {
                            listTag: [],
                            listFioA: [],
                        }
                    }
                }
            },
            errors: {
                pass: [''],
            },
            status: {
                reset: false,
                save: false,
                loading: false,
                id_edit_finance: null,
            },
            local: {
                project: this.project,
                listOrganization: this.listOrganization,
                listFio: this.listFio,
                listTag: this.listTag,
                ps_info: this.psInfo,
                attach: this.attach,
                finance: this.finance,
                gof: this.gof,
                gofInfo: {id: '', name: ''},
                list_projects: [],
                add_project: null,
            },
            state: {
                version: 5,
                activeTab: 0,
            },
            lockCalc: false,
            isMounted: false,
            addUser: {},
            gofState: false,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'ctrlS') this.clickSave();
            if (e.key === 'exportAutocadWs') this.pageT.clickAutocadWS('/export_autocadWs');
        });
        Bus.$on('navbar_info', obj => this.nbi = obj);

        Bus.$on('updListFio', obj => this.local.listFio = obj);
        Bus.$on('updListOrganization', (obj) => this.local.listOrganization = obj);
        Bus.$on('updListFioA', obj => this.nbi.authUser.prop.setting.listFioA = obj);
        Bus.$on('updListTag', obj => this.local.listTag = obj);
        Bus.$on('updTagsProject', obj => this.local.project.tags = obj);
        Bus.$on('updTableFinance', obj => this.local.finance = obj);
        Bus.$on('changeTiptapContent', obj => {
            this.local.ps_info.comm = obj;
        });
    },
    mounted() {
        Bus.$emit('navbar', {command: 'showButtons', args: {save: true, export: true, reload: true,}});
        Bus.$emit('navbar', {command: 'get_navbar_info'});
        this.pageT.loadCookie();
        if (this.gofInfo.id !== undefined) {
            this.local.gofInfo = this.gofInfo;
        }
        this.updateGof();
        this.isMounted = true;
    },
    methods: {
        updateGof() {
            this.local.gof.forEach(item => {
                this.$set(this.addUser, item.uid, {state: false, name: ''});
            })
        },
        clickEditGof() {
            this.loadListProjects();
            this.gofState = true;
            setTimeout(() => this.$refs.gof_edit.focus, 500);
        },
        clickCreateGof() {
            if (this.local.gofInfo.name === '') this.local.gofInfo.name = this.project.name;
            this.gofState = true;
            setTimeout(() => this.$refs.add_gof.focus, 200);
        },
        clickCreateGofReset() {
            this.gofState = false;
        },
        clickCreateGof_() {
            Bus.$emit('zPost', {url: '/project/create_gof', obj: {uid: this.project.uid, name: this.local.gofInfo.name}, flash: true, suffix: '_create_gof',});
            Bus.$once('zResponse_create_gof', response => {
                this.local.gof.length = 0;
                response.gof.forEach(item => this.local.gof.push(item));
                this.local.gofInfo = response.gofInfo;
                this.gofState = false;
            });
        },
        loadListProjects() {
            Bus.$emit('zPost', {url: '/project/load_list_projects', obj: {}, flash: true, suffix: '_load_list_projects',});
            Bus.$once('zResponse_load_list_projects', response => {
                this.local.list_projects.length = 0;
                response.list_projects.forEach(item => this.local.list_projects.push(item));
            });
        },
        clickSaveGof() {
            Bus.$emit('zPost', {url: '/project/save_gof', obj: {gof: this.local.gofInfo}, flash: true, suffix: '_save_gof',});
            Bus.$once('zResponse_save_gof', response => {
                this.local.gofInfo = response.gofInfo;
                this.gofState = false;
            });
        },
        clickDeleteProjectGof(uid) {
            Bus.$emit('zPost', {url: '/project/delete_project_gof', obj: {uid: uid}, flash: true, suffix: '_delete_project_gof',});
            Bus.$once('zResponse_delete_project_gof', response => {
                this.local.gof.length = 0;
                response.gof.forEach(item => this.local.gof.push(item));
                this.gofState = false;
            });
        },
        clickAddProjectToGof() {
            if (this.local.add_project === null) return;
            Bus.$emit('zPost', {url: '/project/add_project_to_gof', obj: {gof: this.local.gofInfo, project: this.local.add_project}, flash: true, suffix: '_add_project_to_gof',});
            Bus.$once('zResponse_add_project_to_gof', response => {
                this.local.gof.length = 0;
                response.gof.forEach(item => {
                    this.local.gof.push(item);
                    if (!this.addUser[item.uid]) {
                        this.$set(this.addUser, item.uid, {state: false, name: ''})
                    }
                });

                this.gofState = false;
            });
        },
        clickDeleteGof() {
            Bus.$emit('zPost', {url: '/project/delete_gof', obj: {gof: this.local.gofInfo}, flash: true, suffix: '_delete_gof',});
            Bus.$once('zResponse_delete_gof', response => {
                this.local.gofInfo = response.gofInfo;
                this.gofState = false;
            });
        },

        clickAdminGof(uid, id) {
            if (!this.isAdminForGof(uid)) return;
            let project = this.gof.find(item => item.uid === uid);
            if (!project) return;

            let user = project.users.find(item => item.id === id);
            if (!user) return;

            user.prop.isAdmin = !user.prop.isAdmin;
        },
        clickRazdelGof(uid, id, name) {
            if (!this.isAdminForGof(uid)) return;

            let project = this.gof.find(item => item.uid === uid);
            if (!project) return;

            let user = project.users.find(item => item.id === id);
            if (!user) return;

            let razdel = user.prop.razdel.find(item => item.name === name);
            if (!razdel) return;

            razdel.check = !razdel.check;
        },
        isAdminForGof(uid) {
            let project = this.gof.find(item => item.uid === uid);
            if (!project) return;

            let user = project.users.find(item => item.name === this.nbi.authUser.name);
            if (!user) return;

            return user.prop.isAdmin;
        },
        clickAddUser(uid) {
            this.addUser[uid].state = true;
        },
        clickAddUserReset(uid) {
            this.addUser[uid].state = false;
        },
        clickSaveUser(uid, users) {
            Bus.$emit('zPost', {url: '/project/save_user', obj: {uid: uid, users: users}, flash: true, suffix: '_save_user',});
            Bus.$once('zResponse_save_user', response => {
                this.local.gof.length = 0;
                response.gof.forEach(item => this.local.gof.push(item));
                this.addUser[uid].state = false;
            });
        },
        clickAddUserAdd(uid) {
            Bus.$emit('zPost', {url: '/project/add_user', obj: {addUser: this.addUser[uid], uid: uid}, flash: true, suffix: '_add_user',});
            Bus.$once('zResponse_add_user', response => {
                this.local.gof.length = 0;
                response.gof.forEach(item => this.local.gof.push(item));
                this.addUser[uid].state = false;
            });
        },
        clickRemoveUserAdd(uid, userId) {
            Bus.$emit('zPost', {url: '/project/remove_user', obj: {userId: userId, uid: uid}, flash: true, suffix: '_remove_user',});
            Bus.$once('zResponse_remove_user', response => {
                this.local.gof.length = 0;
                response.gof.forEach(item => this.local.gof.push(item));
            });
        },
        clickClone() {
            Bus.$emit('zPost', {
                url: '/project/clone',
                obj: {id: this.nbi.projectInfo.id},
                flash: true,
                suffix: '_clone',
            });
            Bus.$once('zResponse_clone', response => {
                document.location.href = '/project/' + response.id + '/show';
            });
        },
        clickTab(i) {
            this.state.activeTab = i;
        },
        clickResetOrg() {
            this.local.ps_info.organization.id = null;
            this.local.ps_info.organization.name = null;
        },
        clickGoPages() {
            document.location.href = '/pages/' + this.nbi.projectInfo.id;
        },
        showModalListTag() {
            Bus.$emit('show_modalListTag');
        },
        updAttach(obj) {
            if (!obj) return false;
            if (!this.local.attach) return false;
            this.local.attach = obj;
        },
        addNewOrg(name, index) {
            Bus.$emit('zPost', {
                url: '/project/org/add',
                obj: {name: name},
                flash: true,
                suffix: '_add_org',
            });
            Bus.$once('zResponse_add_org', response => {
                this.local.listOrganization = response.list;
                this.local.ps_info.organization.id = response.id;
                Bus.$emit('updListOrganization', this.local.listOrganization);
            });
        },
        clickSave() {
            this.toolM.setLoading(true);
            Bus.$emit('zPost', {
                url: this.nbi.url.current + '/save',
                obj: {project: this.local.project, psInfo: this.local.ps_info},
                flash: true,
                suffix: '_show_save',
                // messOk: 'сохранено',
                // messError: 'ошибка привязки аккаунта',
            });
            Bus.$once('zResponse_show_save', () => {
                this.toolM.setLoading(false);
            });
            Bus.$once('zError_show_save', () => {
                this.toolM.setLoading(false);
            });
        },
        addTag(newTag) {
            Bus.$emit('zPost', {
                url: '/project/tag/add',
                obj: {name: newTag, color: '#ffae07'},
                flash: true,
                suffix: '_add_tag_modal',
            });
            Bus.$once('zResponse_add_tag_modal', response => {
                this.local.listTag.push(response.tag);
                this.local.project.tags.push(response.tag);
            });
        },
        clickBackup(el) {
            this.pageT.clickSave('/project/backup_restore', {name: el.name}, '_backup_restore')
        },
    },
    computed: {
        nameProject: function () {
            let t = '';
            let info = this.local.ps_info;
            if (info.book.name) t = 'book';
            else if (info.part.name) t = 'part';
            else if (info.sub_section.name) t = 'sub_section';
            else if (info.section.name) t = 'section';

            if (t === '') {
                info.name_project = '';
            } else {
                let num = info[t].number;
                let str = '';
                if (num) {
                    if (t === 'book') str = 'Книга ';
                    else if (t === 'part') str = 'Часть ';
                    else if (t === 'section') str = 'Раздел ';
                    else if (t === 'sub_section') str = 'Подраздел ';
                    str += num + '. ';
                }
                info.name_project = '';
                if (info[t] !== undefined) info.name_project = str + info[t].name;
            }
            info.name_project_type = t;

            return info.name_project;
        },
    },
    watch: {
        'local.project': {
            handler: function (newVal, oldVal) {
                Bus.$emit('navbar', {command: 'showButtons', args: {save: true}});
            },
            deep: true
        },
        'local.ps_info': {
            handler: function (newVal, oldVal) {
                Bus.$emit('navbar', {command: 'showButtons', args: {save: true}});
            },
            deep: true
        },
        'state': {
            handler: function (newVal, oldVal) {
                this.pageT.updCookie();
            },
            deep: true
        },
    },
    filters: {},
    directives: {}
}
</script>

<style lang="scss" scoped>

svg.fa-list {
    cursor: pointer;
}

.nav-link {
    cursor: pointer;
}

.fas.fa-check {
    opacity: 0.3;


    &:hover {
        cursor: pointer;
    }

    &.active {
        opacity: 1;
        color: green;
    }
}
</style>
