<template>
    <div :id="modalId" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    редактирование организаций
                    <i class="far fa-question-circle mt-1" @click="help=!help" style="cursor: pointer"></i>
                </div>
                <div class="modal-body" v-if="help">
                    <p>Выберите организацию для редактирования.</p>
                    <p>Каждая организация имеет название для внутреннего использования. Полное название для титульных листов и упоминаний в текстовой части. Короткое название которое прописывается
                        в на месте логотипа в штампе, в случае если логотип отсутствует.</p>
                    <p>Для организации можно загрузить два логотипа, один для титульного листа, для верхней части. Второй для штампов.</p>
                    <p>Для создания новой организации просто наберите её название в поле и выберите "создать новую"</p>
                    <p>В качестве логотипов лучше использовать PNG изображения с прозрачным фоном. Размер не более 1Мб</p>
                    <p>Для логотипа титульного листа, оптимальная ширина составляет порядка 700px.</p>
                    <p>Фактический размер логотипа в штампе 15х50мм, оптимальная ширина составляет порядка 50х150px.</p>
                </div>
                <div class="modal-body" v-if="!help">
                    <multiselect
                        v-model="state.modal"
                        :options="local.listOrganization"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        :allow-empty="false"
                        placeholder="организация"
                        tag-placeholder="создать новую"
                        @tag="addNew"
                        :taggable="true"
                        id="mindex"
                        label="name"
                        track-by="id"
                    >
                    </multiselect>
                    <div v-if="state.modal.id">
                        <div class="row">
                            <div class="col-6">
                                <label class="mb-0 mt-3">
                                    <span class="small">название</span>
                                </label>
                                <input class="form-control" v-model="state.modal.name" placeholder="фио">
                            </div>
                            <div class="col-6">
                                <label class="mb-0 mt-3">
                                    <span class="small" title="короткое название организации используется в штампах, в случае если нет логотипа">короткое название организации</span>
                                </label>
                                <input class="form-control" v-model="state.modal.prop.logo_text" placeholder="полное название">
                            </div>
                        </div>

                        <label class="mb-0">
                            <span class="small" title="полное название организации используется в тексте или на титульных листах">полное название организации</span>
                        </label>
                        <input class="form-control" v-model="state.modal.prop.full_name" placeholder="полное название">

                        <div class="row mt-3">
                            <div class="col-9">
                                <vue-dropzone
                                    title="логотип для титульного листа"
                                    id="logo1"
                                    :options="optionsLogo1"
                                    :useCustomSlot=true
                                    @vdropzone-success="successUpload1"
                                    @vdropzone-sending="addParam"
                                    style="position: relative"
                                >
                                    <div class="dropzone-custom-content">
                                        <img :src="'/project/org/logo/'+state.modal.id+'/1'" @error="replaceImgByClear" class="w-100" ref="logo1">
                                    </div>
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click.stop="clickDelLogo(1)" style="position: absolute; right: 0; bottom: 0; cursor: pointer;" size="lg"  title="удалить логотип"/>
                                </vue-dropzone>
                            </div>
                            <div class="col-3">
                                <vue-dropzone
                                    title="логотип в штампе"
                                    id="logo2"
                                    :options="optionsLogo2"
                                    :useCustomSlot=true
                                    @vdropzone-success="successUpload2"
                                    @vdropzone-sending="addParam"
                                >
                                    <div class="dropzone-custom-content">
                                        <img :src="'/project/org/logo/'+state.modal.id+'/2'" @error="replaceImgByClear" class="w-100" ref="logo2">
                                    </div>
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" @click.stop="clickDelLogo(2)" style="position: absolute; right: 0; bottom: 0; cursor: pointer;" size="lg"  title="удалить логотип"/>
                                </vue-dropzone>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <div>
                        <button v-if="state.modal.id" type="button" class="btn btn-default text-danger" data-dismiss="modal" @click="clickDelOrg">удалить</button>
                    </div>
                    <div>
                        <button v-if="state.modal.id" type="button" class="btn btn-primary" data-dismiss="modal" @click="save">сохранить</button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">отмена</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';

    export default {
        name: 'modalOrgLogo',
        components: {Multiselect, vueDropzone: vue2Dropzone},
        props: {
            listOrganization: {
                type: Array,
                default: [],
            },
            modalId: {
                type: String,
                default: 'myModal',
            }
        },
        data: function () {
            return {
                help: false,
                local: {
                    listOrganization: this.listOrganization,
                },
                state: {
                    modal: {id: ''}
                },
                optionsLogo1: {
                    url: '/project/org/upload_img/1',
                    maxFilesize: 1000,
                    headers: {'X-CSRF-TOKEN': document.head.querySelector("[name=csrf-token]").content,},
                    acceptedFiles: '.png',
                    maxFiles: 1,
                    includeStyling: false,
                    previewsContainer: false,
                    init: function () {
                        this.on("maxfilesexceeded", function (file) {
                            this.removeAllFiles();
                            this.addFile(file);
                        });
                    }
                },
                optionsLogo2: {
                    url: '/project/org/upload_img/2',
                    maxFilesize: 1000,
                    headers: {'X-CSRF-TOKEN': document.head.querySelector("[name=csrf-token]").content,},
                    acceptedFiles: '.png',
                    maxFiles: 1,
                    includeStyling: false,
                    previewsContainer: false,
                    init: function () {
                        this.on("maxfilesexceeded", function (file) {
                            this.removeAllFiles();
                            this.addFile(file);
                        });
                    }
                },
            }
        },
        created: function () {
            Bus.$on('updListOrganization', (obj) => this.local.listOrganization = obj);
        },
        methods: {

            successUpload1(file, response) {
                if (!response.error) {
                    this.flash('файл успешно загружен', 'success', {timeout: 2000});
                    let rand = Date.now();
                    this.$refs.logo1.src = '/project/org/logo/' + this.state.modal.id + '/1?rand=' + rand;
                } else {
                    this.flash(response.error, 'error');
                }
            },
            successUpload2(file, response) {
                if (!response.error) {
                    this.flash('файл успешно загружен', 'success', {timeout: 2000});
                    let rand = Date.now();
                    this.$refs.logo2.src = '/project/org/logo/' + this.state.modal.id + '/2?rand=' + rand;
                } else {
                    this.flash(response.error, 'error');
                }
            },
            addParam(file, xhr, formData) {
                formData.append('obj', JSON.stringify(this.state.modal));
            },
            replaceImgByClear(e) {
                e.target.src = '/img/clear.png';
            },
            addNew(name, index) {
                Bus.$emit('zPost', {
                    url: '/project/org/add',
                    obj: {name: name},
                    flash: true,
                    suffix: '_add_org_modal',
                });
                Bus.$once('zResponse_add_org_modal', response => {
                    this.local.listOrganization = response.list;
                    Bus.$emit('updListOrganization', this.local.listOrganization);
                    this.state.modal = this.local.listOrganization.find(item => item.id === response.id);
                });
            },
            save() {
                Bus.$emit('zPost', {
                    url: '/project/org/save',
                    obj: {obj: this.state.modal},
                    flash: true,
                    suffix: '_save_org_modal',
                });
                Bus.$once('zResponse_save_org_modal', response => {
                    this.local.listOrganization = response.list;
                    Bus.$emit('updListOrganization', this.local.listOrganization);
                });
            },
            clickDelLogo(num) {
                Bus.$emit('zPost', {
                    url: '/project/org/logo/del/' + this.state.modal.id + '/' + num,
                    obj: {},
                    flash: true,
                    suffix: '_del_org_logo',
                });
                Bus.$once('zResponse_del_org_logo', response => {
                    let n = 'logo' + num;
                    let rand = Date.now();
                    this.$refs[n].src = '/project/org/logo/' + this.state.modal.id + '/' + num + '?rand=' + rand;
                });
            },
            clickDelOrg() {
                if (!window.confirm("Вы уверены что хотите удалить " + this.state.modal.name + "?")) return false;
                Bus.$emit('zPost', {
                    url: '/project/org/del/' + this.state.modal.id,
                    obj: {},
                    flash: true,
                    suffix: '_del_org',
                });
                Bus.$once('zResponse_del_org', response => {
                    this.local.listOrganization = response.list;
                    Bus.$emit('updListOrganization', this.local.listOrganization);
                    this.state.modal = {id: ''};
                });
            },
        }
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    .border {
        cursor: pointer;

        &.active {
            border: 2px solid black !important;
        }
    }

    .dropzone {
        padding: 0;

    }

    div.dz-message {
        margin: 0 !important;
    }
</style>
