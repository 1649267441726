<template>
    <div>
        <div class="card-body">
            <div class="row">
                <div class="col-6">

                    <h3 v-if="getOstatok()!==0" class="mb-3">остаток: <span class="text-danger"> {{ getOstatok() }}</span></h3>

                    <div v-for="val in local.finance" class="row" style="cursor: pointer" @click="clickEdit(val.id)">
                        <div class="col-3">{{ toolM.customFormatter(val.d_event) }}</div>
                        <div class="col-3">
                            <font-awesome-icon v-if="val.t_event==='price'" :icon="['fas', 'comments-dollar']" class="text-primary"/>
                            <font-awesome-icon v-if="val.t_event==='receipt'" :icon="['fas', 'coins']" style="color: goldenrod"/>
                            {{ val.amount }}
                        </div>
                        <div class="col-1">
                            <img v-if="val.wallet==='avangard'" :src="'/img/avangard_logo.png'" style="width: 1rem" class="mb-1"/>
                            <img v-if="val.wallet==='sber'" :src="'/img/sber_logo.png'" style="width: 1rem" class="mb-1"/>
                            <font-awesome-icon v-if="val.wallet==='cache'" :icon="['fas', 'hand-holding-dollar']" class="text-secondary"/>
                        </div>
                        <div class="col-5">{{ val.comm }}</div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <button class="form-control btn btn-primary w-100" @click="showModalInsert">добавить</button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <modal-finance modal-id="modalFinance" :p-local="local" :p-state="state" @updInit="init"/>
    </div>
</template>
<script>

import uniqid from 'uniqid';
import ModalFinance from "./ModalFinance.vue";
import {page, toolM} from "./tools";
import Datepicker from "vuejs-datepicker";
import {ru} from "vuejs-datepicker/dist/locale";
// import ModalPotr from "./sub/ModalPotr";
// import {eTable} from "./sub/tools";

export default {
    name: 'finance',
    components: {
        ModalFinance,
        Datepicker,
    },
    props: {
        pLocal: {},
        pState: {}
    },
    data: function () {
        return {
            pageT: new page(this),
            toolM: new toolM(this),
            local: {
                finance: this.pLocal.finance,
                ps_info: this.pLocal.ps_info,
            },
            state: {
                obj: {},
            },
            ru: ru,
        }
    },
    created: function () {
        Bus.$on('keyUp', e => {
            if (e.key === 'insert') this.showModalInsert();
            if (e.key === 113) this.showModal();
        });
        Bus.$on('updTable', () => this.init());
    },
    mounted() {
        this.init();
        // setTimeout(() => this.$refs.search.focus(), 200);
    },
    methods: {
        getOstatok() {
            let price = 0;
            let receipt = 0;
            this.pLocal.finance.forEach(item => {
                if (item.t_event === 'price') price += parseFloat(item.amount);
                if (item.t_event === 'receipt') receipt += parseFloat(item.amount);
            })

            return price - receipt;
        },
        // getFireStatus(obj) {
        //     if (obj.panel.nrr && !obj.panel.arr) return 'OFF';
        //     else if (!obj.panel.nrr && obj.panel.arr) return 'ON';
        //     else return '';
        // },
        clickEdit(id) {
            this.state.obj = this.local.finance.find(item => item.id === id);
            this.showModal();
        },
        showModal() {
            Bus.$emit('show_modalFinance');
        },
        showModalInsert() {
            Bus.$emit('showI_modalFinance');
        },
        addFinanceEvent() {

        },
        init() {
            this.local.finance = this.pLocal.finance;
        },
        // computed: {
        // fTable: function () {
        //     if (this.state.search.val === '') return this.local.table;
        //     return this.local.table.filter(item => {
        //         let res = true;
        //         this.state.search.val.split(' ').forEach(value => {
        //             let s = item.name2 + item.name1 + item.type + item.vid;
        //             res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        //         });
        //         return res;
        //     })
        // },
        // },
        // watch: {
        // 'fTable': {
        //     handler: function (newVal, oldVal) {
        //         this.eTable.clearSelectArr();
        //     },
        //     deep: false
        // },
        // }
    }
}
</script>
