var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-fluid m1" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-xl-8" }, [
          _c("div", { staticClass: "mb-3" }, [
            _c("h1", { staticClass: "mb-0" }, [
              _vm._v(
                "\n                        Проекты\n                        "
              ),
              _c(
                "span",
                { staticClass: "ml-5", attrs: { title: "задолженность" } },
                [_vm._v(_vm._s(_vm.fin_analysis.debt))]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml-5", attrs: { title: "за год" } }, [
                _vm._v(_vm._s(_vm.fin_analysis.sum_Y)),
              ]),
              _vm._v(" /\n                        "),
              _c("span", { attrs: { title: "за квартал" } }, [
                _vm._v(_vm._s(_vm.fin_analysis.sum_Q)),
              ]),
              _vm._v(" /\n                        "),
              _c("span", { attrs: { title: "за месяц" } }, [
                _vm._v(_vm._s(_vm.fin_analysis.sum_M)),
              ]),
              _vm._v(" /\n                        "),
              _c("span", { attrs: { title: "за календарный месяц" } }, [
                _vm._v(_vm._s(_vm.fin_analysis.sum_CM)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "form-control w-100 mb-3",
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              {
                staticClass: "tab-pane container active",
                attrs: { id: "open" },
              },
              [
                _c(
                  "table",
                  { staticClass: "table table-sm" },
                  _vm._l(_vm.filteredOpenProject, function (val) {
                    return _c(
                      "tr",
                      {
                        key: val.id,
                        class: {
                          active:
                            _vm.nbi.authUser.prop.currentProjectId === val.id,
                        },
                      },
                      [
                        _c("td", { staticClass: "w-2" }, [
                          _c("i", {
                            staticClass: "fas fa-check",
                            class: {
                              active:
                                _vm.nbi.authUser.prop.currentProjectId ===
                                val.id,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickSetCurrentProject(val)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left text-nowrap" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-dark",
                              attrs: { href: "/project/" + val.id + "/show" },
                            },
                            [_vm._v(_vm._s(val.name))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm._l(val.tags, function (tt) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge",
                                    style: {
                                      backgroundColor: tt.color,
                                      color: _vm.invertHex(tt.color),
                                    },
                                  },
                                  [_vm._v(_vm._s(tt.name))]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.stage))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.getRest(val.id)))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.customFormatter(val.start_date)) +
                              " - " +
                              _vm._s(_vm.customFormatter(val.stop_date))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(_vm._s(val.organization.name)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tab-pane container", attrs: { id: "close" } },
              [
                _c(
                  "table",
                  { staticClass: "table table-sm" },
                  _vm._l(_vm.filteredCloseProject, function (val) {
                    return _c(
                      "tr",
                      {
                        key: val.id,
                        class: {
                          active:
                            _vm.nbi.authUser.prop.currentProjectId === val.id,
                        },
                      },
                      [
                        _c("td", { staticClass: "w-2" }, [
                          _c("i", {
                            staticClass: "fas fa-check",
                            class: {
                              active:
                                _vm.nbi.authUser.prop.currentProjectId ===
                                val.id,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickSetCurrentProject(val)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left text-nowrap" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-dark",
                              attrs: { href: "/project/" + val.id + "/show" },
                            },
                            [_vm._v(_vm._s(val.name))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm._l(val.tag, function (tt) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge",
                                    style: { "background-color": tt.color },
                                  },
                                  [_vm._v(_vm._s(tt.name))]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.stage))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.getRest(val.id)))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.customFormatter(val.start_date)) +
                              " - " +
                              _vm._s(_vm.customFormatter(val.stop_date))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(_vm._s(val.organization.name)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "nav nav-tabs" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link active",
            attrs: { "data-toggle": "tab", href: "#open" },
          },
          [_vm._v("открытые")]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { "data-toggle": "tab", href: "#close" },
          },
          [_vm._v("закрытые")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }