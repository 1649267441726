var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "card-body" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.state.search.val,
              expression: "state.search.val",
              modifiers: { trim: true },
            },
          ],
          ref: "search",
          staticClass: "form-control mb-2",
          domProps: { value: _vm.state.search.val },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.state.search, "val", $event.target.value.trim())
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "table table-sm" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "draggable",
              {
                attrs: { tag: "tbody", "ghost-class": "ghost" },
                model: {
                  value: _vm.local.table,
                  callback: function ($$v) {
                    _vm.$set(_vm.local, "table", $$v)
                  },
                  expression: "local.table",
                },
              },
              [
                _vm._l(_vm.fTable, function (val) {
                  return [
                    _c(
                      "tr",
                      {
                        key: val.id,
                        staticClass: "font-weight-bold",
                        class: {
                          "bg-primary-l": _vm.eTable.isSelect(val),
                          "bg-danger-l": val.room.id === "",
                        },
                        on: {
                          click: [
                            function ($event) {
                              if (!$event.ctrlKey) return null
                              $event.preventDefault()
                              return _vm.eTable.clickCtrlRow(val)
                            },
                            function ($event) {
                              if (
                                $event.ctrlKey ||
                                $event.shiftKey ||
                                $event.altKey ||
                                $event.metaKey
                              )
                                return null
                              return _vm.eTable.clickRow(val)
                            },
                          ],
                        },
                      },
                      [
                        _c("td", { staticClass: "text-left" }, [
                          _c("span", [_vm._v(_vm._s(val.name2))]),
                          _c("br"),
                          _c("span", { staticClass: "small" }, [
                            _vm._v(
                              _vm._s(val.dop.method.name) +
                                ", " +
                                _vm._s(val.dop.app.name)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.es.pu))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.es.ks))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.es.pr))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.es.cos))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.es.qr))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.es.sr))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(val.es.ir))]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(
                      val.trn.nrr.filter((item) => item.name3 !== "*itogo*"),
                      function (val2, index) {
                        return [
                          _c("tr", { key: val.id + index }, [
                            _c("td", { staticClass: "text-left" }, [
                              _c("span", [_vm._v(_vm._s(val2.name3))]),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(val2.val.kolvo))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(val2.val.pu))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(val2.val.ks))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(val2.val.pr))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(val2.val.cos))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(val2.val.qr))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(val2.val.sr))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(val2.val.ir))]),
                          ]),
                        ]
                      }
                    ),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("modal-panel", {
        attrs: { "modal-id": "modalPanel2", "p-local": _vm.local },
        on: { updInit: _vm.init },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "w-5", attrs: { scope: "col" } }, [
          _vm._v("name"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("кол-во"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("Ру"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("Кс"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("Pp"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("cos"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("Qp"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("Sp"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "w-1", attrs: { scope: "col" } }, [
          _vm._v("Ip"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }