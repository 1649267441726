var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "item-container",
      attrs: {
        tag: "div",
        list: _vm.list,
        value: _vm.value,
        handle: ".handle",
        animation: "150",
        group: "nested",
        "fallback-on-body": true,
        "swap-threshold": 0.5,
        "ghost-class": "ghost",
        filter: ".no-drag",
        move: _vm.move,
        "scroll-sensitivity": 100,
        "fallback-tolerance": 1,
        "force-fallback": true,
      },
      on: {
        input: _vm.emitter,
        choose: _vm.choose,
        unchoose: _vm.unchoose,
        end: _vm.end,
        change: _vm.onChange,
      },
    },
    _vm._l(_vm.realValue, function (el) {
      return _c(
        "div",
        { key: el.id, staticClass: "item-group" },
        [
          _c(
            "div",
            { staticClass: "item", class: { "bg-danger": _vm.level > 2 } },
            [
              _c("div", { staticClass: "row mb-0" }, [
                _c(
                  "div",
                  { staticClass: "col-1 pt-2 text-nowrap" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "handle",
                      attrs: { icon: "fa-regular fa-file" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "font-weight-bold ml-1" }, [
                      _c(
                        "a",
                        {
                          staticClass: "text-dark",
                          attrs: {
                            tabindex: "-1",
                            href:
                              el.link_content.link +
                              "/" +
                              _vm.nbi.authUser.prop.currentProjectId +
                              "/" +
                              el.id,
                          },
                        },
                        [_vm._v(_vm._s(_vm.getPageName(el)))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-bold ml-1 small",
                        attrs: { title: "название листа в autocad" },
                      },
                      [_vm._v(" " + _vm._s(el.autocad.list_name_autocad))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: el.stamp.list_name,
                        expression: "el.stamp.list_name",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control ml-3",
                    domProps: { value: el.stamp.list_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          el.stamp,
                          "list_name",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 pt-2" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(el.stamp.number) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                el.options.count_page > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "col-2 pt-2",
                        attrs: { title: _vm.getTitle(el) },
                      },
                      [
                        el.type_list !== "text" &&
                        el.type_list !== "cover" &&
                        el.type_list !== "title_page"
                          ? _c("span", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(el.stamp.list_num_global) +
                                  "\n                    "
                              ),
                              _c("span", [
                                _vm._v(_vm._s(_vm.getStampSize(el))),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.getListNum2(el)))]),
                              _vm._v(" "),
                              el.stamp.list_count > 1
                                ? _c("span", [
                                    _vm._v("из " + _vm._s(el.stamp.list_count)),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "col-2 pt-2",
                        attrs: { title: "в составе другого листа" },
                      },
                      [_vm._v("в составе")]
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-1 pt-2 text-right no-select" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "fa-solid fa-minus" },
                      on: {
                        click: function ($event) {
                          return _vm.clickMinus(el)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { attrs: { title: "количество страниц в одном листе" } },
                      [_vm._v(_vm._s(el.options.count_page))]
                    ),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      attrs: { icon: "fa-solid fa-plus" },
                      on: {
                        click: function ($event) {
                          return _vm.clickPlus(el)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-1 pt-2 text-right" },
                  [
                    _c("font-awesome-icon", {
                      attrs: {
                        icon: "fa-solid fa-xmark",
                        title: "удалить",
                        size: "xl",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clickRemove(el)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("nested-list", {
            staticClass: "item-sub",
            attrs: {
              list: el.child,
              level: _vm.level + 1,
              setting: _vm.setting,
              id: el.id,
              "list-pages-template": _vm.listPagesTemplate,
              nbi: _vm.nbi,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }