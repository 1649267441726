var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%" },
      on: {
        click: function ($event) {
          return _vm.context.close()
        },
      },
    },
    [
      _c("div", { staticClass: "container-fluid m1" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary",
                class: { active: _vm.isViewTable },
                attrs: { type: "button" },
                on: { click: _vm.clickSwView },
              },
              [_vm._v("просмотр")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-danger",
                attrs: { type: "button" },
                on: { click: _vm.clickResetTable },
              },
              [_vm._v("сброс")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "card-body",
                on: {
                  click: function ($event) {
                    return _vm.context.close()
                  },
                },
              },
              [
                !_vm.isViewTable
                  ? _c(
                      "div",
                      [
                        _c("nested-specification", {
                          staticClass: "item-sub",
                          attrs: { local: _vm.local },
                          model: {
                            value: _vm.local.table,
                            callback: function ($$v) {
                              _vm.$set(_vm.local, "table", $$v)
                            },
                            expression: "local.table",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-primary mb-3",
                            attrs: { type: "button" },
                            on: { click: _vm.clickRefreshView },
                          },
                          [_vm._v("обновить")]
                        ),
                        _vm._v(" "),
                        _c("nested-specification-view", {
                          staticClass: "item-sub",
                          attrs: { local: _vm.local },
                          model: {
                            value: _vm.local.tableV,
                            callback: function ($$v) {
                              _vm.$set(_vm.local, "tableV", $$v)
                            },
                            expression: "local.tableV",
                          },
                        }),
                      ],
                      1
                    ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-so-f-element-base", {
        attrs: {
          "modal-id": "modalSoFElementBase",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-element", {
        attrs: {
          "modal-id": "modalSoFElement",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-panel", {
        attrs: {
          "modal-id": "modalSoFPanel",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-cable", {
        attrs: {
          "modal-id": "modalSoFCable",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-cable-fr", {
        attrs: {
          "modal-id": "modalSoFCableFR",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-light", {
        attrs: {
          "modal-id": "modalSoFLight",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-potr", {
        attrs: {
          "modal-id": "modalSoFPotr",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-other", {
        attrs: {
          "modal-id": "modalSoFOther",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-import", {
        attrs: {
          "modal-id": "modalSoFImport",
          "a-w": _vm.local.AW,
          "list-project": _vm.listProject,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-f-block", {
        attrs: {
          "modal-id": "modalSoFBlock",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so", {
        attrs: {
          "modal-id": "modalSo",
          "a-w": _vm.local.AW,
          "list-category": _vm.listCategory,
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("modal-so-info", {
        attrs: {
          "modal-id": "modalSoInfo",
          "p-local": _vm.local,
          "prod-obj": {},
          marka: "element",
        },
      }),
      _vm._v(" "),
      _c("context-menu-my", {
        ref: "menu",
        attrs: { items: _vm.items, "id-menu": "sp" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }