<template>
    <div class="col-12">

        <div class="row mt-3">
            <div class="col-6">
                <draggable :list="local.projectInfo.fio" class="" ghost-class="ghost">
                    <div class="row p-0 m-0" v-for="(el,index) in local.projectInfo.fio" :key="index">
                        <div class="col-4 m-0 p-0">
                            <multiselect
                                v-model="el.a"
                                :options="local.listFioA.map(item => item.name)"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                :allow-empty="false"
                                :custom-label="opt => toolM.labelSearch_3(local.listFioA,opt)"
                                placeholder="должность"
                                tag-placeholder="добавить"
                                @tag="addNewFioA"
                                :taggable="true"
                                :id="'_'+index"
                            >
                                <template slot="beforeList">
                                    <button class="form-control btn btn-sm btn-outline-primary p-0" @click="el.a=null">очистить</button>
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-5 m-0 p-0">
                            <multiselect
                                v-model="el.b"
                                :options="local.listFio"
                                :searchable="true"
                                :close-on-select="true"
                                :show-labels="false"
                                :allow-empty="false"
                                placeholder="фио"
                                tag-placeholder="добавить"
                                @tag="addNewFio"
                                :taggable="true"
                                :id="'_'+index"
                                label="name"
                                track-by="id"
                            >
                                <template slot="beforeList">
                                    <button class="form-control btn btn-sm btn-outline-primary p-0" @click="el.b=null">очистить</button>
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-1 m-0 p-0">
                            <img :src="getImgSrc(el)" style="width: 100%;" @error="replaceImgByClear" alt=""/>
                        </div>
                        <div class="col-2 m-0 p-0">
                            <input class="form-control" v-model="el.d" placeholder="">
                        </div>
                    </div>
                </draggable>
                <div class="row mt-3">
                    <div class="col-4 text-center">
                        <button class="form-control btn btn-secondary" @click="showModalFioA">участники</button>
                    </div>
                    <div class="col-5 text-center">
                        <button class="form-control btn btn-secondary" data-toggle="modal" data-target="#myModalFio">подписи</button>
                    </div>
                    <div class="col-1 p-0 m-0"></div>
                    <div class="col-2 pl-0">
                        <input class="form-control w-100" v-model="newDate">
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="row">
                    <div class="col-9">
                        <input class="form-control" v-model="local.projectInfo.number" placeholder="шифр проекта">
                    </div>
                    <div class="col-3">
                        <multiselect
                            v-model="local.projectInfo.stage"
                            :options="listStage.map(item => item.name)"
                            :custom-label="opt => toolM.labelSearch_3(listStage,opt)"
                            :searchable="false"
                            :close-on-select="true"
                            :show-labels="false"
                            :allow-empty="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input class="form-control" v-model="local.projectInfo.address" placeholder="объект проектирования">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input class="form-control" v-model="local.projectInfo.name_project" placeholder="название проекта ">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                    </div>
                </div>
            </div>
        </div>

        <modal-list-fio-a :list-fio="nbi.authUser.prop.setting.listFioA"/>
    </div>
</template>
<script>
    import Draggable from 'vuedraggable'
    import Multiselect from 'vue-multiselect'
    import {toolM} from "./tools";
    import ModalListFioA from "./ModalListFioA";

    export default {
        name: 'fio-stamp',
        components: {Draggable, Multiselect, ModalListFioA},
        props: {
            projectInfo: {},
            nbi: {},
            listFio: {type: Array, default: []},
        },
        data: function () {
            return {
                toolM: new toolM(this),
                listStage: [
                    {name: 'П'},
                    {name: 'Р'},
                    {name: 'ИД'},
                ],
                local: {
                    projectInfo: this.projectInfo,
                    listFio: this.listFio,
                    listFioA: [],
                },
                status: {
                    loading: false,
                },
                newDate: '',
            }
        },
        created: function () {
            Bus.$on('updListFio', obj => this.local.listFio = obj);
            Bus.$on('updListFioA', obj => this.updLocalData());
        },
        mounted() {
            Vue.nextTick(() => this.updLocalData())
        },
        methods: {
            updLocalData() {
                this.local.listFioA = this.nbi.authUser.prop.setting.listFioA;
                this.local.projectInfo.fio.forEach(item => {
                    let obj = this.local.listFioA.find(v => v.name === item.a);
                    if (!obj && item.a) this.local.listFioA.push({name: item.a});
                });
            },
            showModalFioA() {
                Bus.$emit('show_modalListFioA')
            },
            getImgSrc(obj) {
                if (obj.b === null) return '/img/clear.png';
                return '/project/fio/sign/' + obj.b.id + '/' + obj.b.prop.default;
            },
            replaceImgByClear(e) {
                e.target.src = '/img/clear.png';
            },
            //todo multiselect add new item
            addNewFioA(newTag, index) {
                index = index.substr(1);
                let tag = {name: newTag};
                this.nbi.authUser.prop.setting.listFioA.push(tag);
                this.local.projectInfo.fio[index].a = newTag;
            },
            addNewFio(name, index) {
                index = index.substr(1);
                this.toolM.setLoading(true);
                Bus.$emit('zPost', {
                    url: '/project/fio/add',
                    obj: {name: name},
                    flash: true,
                    suffix: '_add_new_fio',
                });
                Bus.$once('zResponse_add_new_fio', response => {
                    Bus.$emit('updListFio', response.list);
                    this.local.projectInfo.fio[index].b = response.list.find(item => item.id === response.id);
                    this.toolM.setLoading(false);
                });
            },
        },
        watch: {
            'newDate'(newVal, oldVal) {
                this.local.projectInfo.fio.forEach(item => {
                    if (item.d !== '' && item.d !== null) item.d = newVal;
                })
            },
        }

    }
</script>
