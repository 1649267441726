<template>
    <!--//todo-template modal add @click and @click.stop and modal-content add width-->
    <div :id="modalId" class="modal fade" role="dialog" @click="closeModal" v-if="isMount">
        <div class="modal-dialog modal-lg" @click.stop="">
            <div class="modal-content">
                <div class="modal-header">
                    <span v-if="state.isEdit">редактировать</span>
                    <span v-else>вставить потребитель</span>
                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger mt-1" @click="closeModal" style="cursor: pointer" size="lg"/>
                </div>
                <div class="modal-body">
                    <div class="row my-0">
                        <div class="col-6 pr-1" @click="lock.name=false">
                            <span class="small">название <font-awesome-icon v-if="lock.name" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                :disabled="lock.name"
                                ref="ms2"
                                v-model="state.obj.name"
                                placeholder="name"
                                :options="local.listName.map(item=>item.name)"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @open="onOpen('ms2','name')"
                                :blockKeys="['Delete']"
                                @tag="addNewItem2"
                                :taggable="true"
                            >
                            </multiselect>
                        </div>
                        <div class="col-6 pr-1" @click="lock.method=false">
                            <span class="small">методика расчёта <font-awesome-icon v-if="lock.method" icon="fa-solid fa-lock"/></span>
                            <multiselect
                                ref="ms3"
                                v-model="state.obj.dop.method"
                                placeholder="методика расчёта"
                                :options="pLocal.listMethod"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="false"
                                :close-on-select="true"
                                :allow-empty="false"
                                @input="onChange"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                    <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.name }}</span>
                                        <br>
                                        <div class="option__small small">{{ props.option.desc }}</div>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2 pl-4">
                            <span v-if="state.obj.es.faz===3" @click="clickFaz(1)" style="cursor: pointer;">
                                <font-awesome-icon icon="fa-solid fa-3" size="lg" style="margin-top: 2rem;"/><span class="ml-1">фазы</span>
                            </span>
                            <span v-else @click="clickFaz(3)" style="cursor: pointer;">
                                <font-awesome-icon icon="fa-solid fa-1" size="lg" style="margin-top: 2rem;"/><span class="ml-1">фаза</span>
                            </span>
                            <font-awesome-icon v-if="lock.faz" icon="fa-solid fa-lock"/>
                        </div>
                        <div class="col-1 text-right" @click="lock.fix=false" title="фиксированные значения">
                            <label class="apple-switch_2" style="margin-top: 2rem"><input type="checkbox" v-model="state.obj.fix.isFix"><i></i></label>
                            <font-awesome-icon v-if="lock.fix" icon="fa-solid fa-lock"/>
                        </div>
                        <div class="col-2 px-1">
                            <label class="w-100">
                                <span class="small">Рр, кВт </span>
                                <span class="w-100" title="Фиксированная расчётная мощность, кВт">
                                    <input class="form-control" v-model.number="state.obj.fix.pr" placeholder="Рр, кВт" :class="{lock:lock.fix}" @keyup="lock.fix=false"
                                           :disabled="!state.obj.fix.isFix"/>
                                </span>
                            </label>
                        </div>
                        <div class="col-2 pl-1">
                            <label class="w-100">
                                <span class="small">Кс </span>
                                <span class="w-100" title="Фиксированный коэффициент спроса">
                                    <input class="form-control" v-model.number="state.obj.fix.ks" placeholder="Кс" :class="{lock:lock.fix}" @keyup="lock.fix=false"
                                           :disabled="!state.obj.fix.isFix"/>
                                </span>
                            </label>
                        </div>
                        <div class="col-2 px-1">
                            <e-input :lock="lock" :k="'tmpl_suffix'" :list-tag="local.listName" v-model="state.obj.tmpl_suffix" :label="'шаблон'" :title="'Шаблон названия группы'" :is-number="false"/>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6 px-1" @click="lock.avt1=false">
                            <span class="small">автомат 1 <font-awesome-icon v-if="lock.avt1" icon="fa-solid fa-lock"/></span>
                            <!--//todo-code-vue multiselect obj-->
                            <multiselect
                                ref="ms4"
                                :disabled="lock.avt1"
                                v-model="state.obj.avt1_base"
                                placeholder="марка автомата"
                                :options="local.listBaseAvt"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @input="onChange3"
                                @open="onOpen3('ms4',0)"
                                :internal-search="false"
                                @search-change="customSearch3"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ props.option.marka }}
                                                            </span>
                                                        </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.marka }}</span>
                                        <br>
                                        <span class="option__small small">{{ props.option.brand }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-6 px-1" @click="lock.avt2=false">
                            <span class="small">автомат 2 <font-awesome-icon v-if="lock.avt2" icon="fa-solid fa-lock"/></span>
                            <!--//todo-code-vue multiselect obj-->
                            <multiselect
                                ref="ms5"
                                :disabled="lock.avt2"
                                v-model="state.obj.avt2_base"
                                placeholder="марка аппарата"
                                :options="local.listBaseAvt"
                                :option-height="124"
                                :show-labels="false"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                @input="onChange4"
                                @open="onOpen3('ms5',1)"
                                :internal-search="false"
                                @search-change="customSearch3"
                                :blockKeys="['Delete']"
                            >
                                <template slot="singleLabel" slot-scope="props">
                                                        <span class="option__desc">
                                                            <span class="option__title">
                                                                {{ props.option.marka }}
                                                            </span>
                                                        </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="option__desc">
                                        <span class="option__title">{{ props.option.marka }}</span>
                                        <br>
                                        <span class="option__small small">{{ props.option.brand }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex flex-row justify-content-end">
                    <div>
                        <!--                        <button v-if="state.isEdit" type="button" class="btn btn-default" @click="clickDelete">удалить</button>-->
                        <button v-if="state.isEdit" type="button" class="btn btn-primary" @click="clickLocalSave">сохранить</button>
                        <button v-else-if="!!lastSelectObj" type="button" class="btn btn-primary" @click="clickInsert">вставить</button>
                        <button type="button" class="btn btn-default" @click="closeModal">закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    //todo js modal modalDraggable
    import {page} from "./tools";
    import EInput from "./EInput";


    export default {
        name: 'modal-bbs-n',
        components: {EInput, Multiselect},
        props: {
            modalId: {type: String, default: 'modal',},
            pLocal: {type: Object, default: {},},
            isGroupedItem: {type: Boolean, default: false,},
        },
        data: function () {
            return {
                //todo js modal add page module
                pageT: new page(this),
                rand: 1,
                local: {
                    table: this.pLocal.table,
                    listName: [],
                    listTmpl: [],
                    listDd: [],
                    listOtm: [],
                    listBaseAvt: this.pLocal.listBaseAvt,
                },
                state: {
                    obj: null,
                    isEdit: true,
                },
                status: {
                    loading: false,
                },
                templateObj: {
                    name: '',
                    fix: {isFix: false, pu: 0, ks: 0},
                    dop: {method: {}},
                    es: {faz: 1,},
                    tmpl_suffix: '',
                    avt: [
                        {id: ''},
                        {id: ''},
                    ],
                    avt1_base: {},
                    avt2_base: {},
                },
                lock: {
                    faz: true,
                    name: true,
                    fix: true,
                    method: true,
                    tmpl_suffix: true,
                    avt1: true,
                    avt2: true,
                },
                isMount: false,
            }
        },
        created: function () {
            Bus.$on('keyUp', e => {
                if (e.key === 'ctrlEnter') this.clickLocalSave();
            });
            Bus.$on('show_' + this.modalId, () => {
                this.state.isEdit = true;
                this.showModal()
            });
            Bus.$on('showI_' + this.modalId, () => {
                this.state.isEdit = false;
                this.showModal();
            });
        },
        mounted() {
            this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
            this.isMount = true;
        },
        methods: {
            //todo js multiselect custom search
            customSearch3(query) {
                let i = 0;
                this.local.listBaseAvt = this.pLocal.listBaseAvt.filter(item => {
                    if (i > 9) return false;
                    let res = true;
                    if (query !== '') {
                        query.split(' ').forEach(value => {
                            let s = item.marka + item.brand;
                            res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                        });
                    }
                    if (res) i++;
                    return res;
                })
            },
            onOpen3(r, f) {
                setTimeout(() => {
                    this.$refs[r].$refs.search.value = this.state.obj.avt[f].marka;
                }, 10);
            },
            onChange3(e) {
                this.state.obj.avt[0].id = e.uid;
                this.state.obj.avt[0].marka = e.marka;
                this.state.obj.avt[0].name = e.name;
            },
            onChange4(e) {
                this.state.obj.avt[1].id = e.uid;
                this.state.obj.avt[1].marka = e.marka;
                this.state.obj.avt[1].name = e.name;
            },
            clickInsert() {

            },
            setLock(val) {
                Object.keys(this.lock).forEach(item => this.lock[item] = val);
            },
            clickFaz(f) {
                this.state.obj.es.faz = f;
                this.lock.faz = false;
            },
            showModal() {
                this.state.obj = JSON.parse(JSON.stringify(this.templateObj));
                if (this.lastSelectObj) {
                    this.setLock(false);
                    if (this.isGroupedItem) {
                        let child = JSON.parse(JSON.stringify(this.lastSelectObj.child));
                        this.setObj(this.state.obj, child[0]);
                        this.state.obj.child = child;
                    } else {
                        this.setObj(this.state.obj, this.lastSelectObj);
                    }

                    this.state.obj.name = this.lastSelectObj.name;
                    this.state.obj.tmpl_suffix = this.lastSelectObj.tmpl_suffix;
                    this.state.obj.fix = JSON.parse(JSON.stringify(this.lastSelectObj.fix));
                    this.state.obj.dop.method = JSON.parse(JSON.stringify(this.lastSelectObj.dop.method));
                    this.state.obj.avt = this.lastSelectObj.avt;
                    this.state.obj.avt1_base = this.local.listBaseAvt.find(item => item.uid === this.lastSelectObj.avt[0].id);
                    this.state.obj.avt2_base = this.local.listBaseAvt.find(item => item.uid === this.lastSelectObj.avt[1].id);

                    //todo js поиск уникальных значений
                    this.local.listTmpl = this.local.table.map(item => item.tmpl_suffix).filter((value, index, self) => self.indexOf(value) === index);
                }
                this.setLock(this.pLocal.selectArr.length > 1);
                $('#' + this.modalId).modal('show');
                for (let i = 0; i < 3; i++) {
                    setTimeout(() => {
                        if (this.$refs.v_focus) this.$refs.v_focus.focus()
                    }, 200);
                }
                $(".modal").draggable({
                    handle: ".modal-header"
                });
            },
            clickLocalSave() {
                this.pLocal.selectArr.forEach(item => {
                    if (this.isGroupedItem) {
                        item.child.forEach(item2 => {
                            this.setObj(item2, this.state.obj);
                        })
                    } else {
                        this.setObj(item, this.state.obj);
                    }
                })
                this.closeModal();
            },
            setObj(obj, iObj) {
                if (!this.lock.name) obj.name = iObj.name;
                if (!this.lock.tmpl_suffix) obj.tmpl_suffix = iObj.tmpl_suffix;
                if (!this.lock.method) obj.dop.method = JSON.parse(JSON.stringify(iObj.dop.method));
                if (!this.lock.fix) obj.fix = JSON.parse(JSON.stringify(iObj.fix));
                if (!this.lock.faz) obj.es.faz = iObj.es.faz;
                if (!this.lock.avt1) {
                    obj.avt[0].id = iObj.avt[0].id;
                    obj.avt[0].marka = iObj.avt[0].marka;
                    obj.avt[0].name = iObj.avt[0].name;
                }
                if (!this.lock.avt2) {
                    obj.avt[1].id = iObj.avt[1].id;
                    obj.avt[1].marka = iObj.avt[1].marka;
                    obj.avt[1].name = iObj.avt[1].name;
                }
            },
            addNewItem2(newTag) {
                this.state.obj.name = newTag;
                this.local.listName.push(newTag);
            },
            onOpen(r, f) {
                setTimeout(() => {
                    this.$refs[r].$refs.search.value = this.state.obj[f];
                }, 10);
            },
            onChange() {
                if (!this.state.obj) this.state.isEdit = false;
            },
            closeModal() {
                this.$emit('updInit');
                $('#' + this.modalId).modal('hide');
            },
            //todo js multiselect custom search
            customSearch(query) {
                if (query === '') {
                    this.local.fListImportPotr = this.pLocal.fListImportPotr;
                }
                let i = 0;
                this.local.fListImportPotr = this.pLocal.fListImportPotr.filter(item => {
                    // if (i > 10) return false;
                    let res = true;
                    query.split(' ').forEach(value => {
                        let s = item.name;
                        res = res && s.toLowerCase().indexOf(value.toLowerCase()) !== -1;
                    });
                    if (res) i++;
                    return res;
                })
            },
        },
        computed: {
            lastSelectObj: function () {
                if (this.pLocal.selectArr.length === 0) return null;
                return this.pLocal.selectArr[this.pLocal.selectArr.length - 1];
            }
        },
    }
</script>
<style scoped lang="scss">
    @import '../../../sass/variables';

    span.small.tag {
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    svg.fa-lock {
        opacity: 0.2;

        &.right {
            position: absolute;
            right: 14px;
            top: 36px;
        }
    }

    ul.multiselect__content {
        width: 100% !important;
    }

    input.lock {
        background-color: lighten($primary, 50%);
    }

</style>
