<template>
    <div :id="modalId" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div v-if="!state.id" class="modal-header">прикрепить файл к проекту</div>
                <div v-else class="modal-header">редактирование файла</div>

                <div class="modal-body">
                    <input class="form-control mb-3" placeholder="название" v-model="state.name" ref="v_focus" :disabled="!state.id">
                    <vue-dropzone
                        id="dropzone"
                        ref="dropzone"
                        :options="dropzoneOptionsAttaches"
                        :useCustomSlot=true
                        @vdropzone-success="dropzoneSuccessAttach"
                        v-on:vdropzone-sending="addParamAttach"
                    >
                        <div class="dropzone-custom-content">
                            <h3 class="dropzone-custom-title">Перетащите сюда файл</h3>
                            <div class="subtitle">...или кликните для выбора файла на компьютере</div>
                        </div>
                    </vue-dropzone>
                </div>
                <div class="modal-footer">
                    <button v-if="state.id" type="button" class="btn btn-default text-danger" @click="clickDelete">удалить</button>
                    <button v-if="state.id" type="button" class="btn btn-primary" @click="clickSave">сохранить</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">закрыть</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Datepicker from 'vuejs-datepicker'
    import Multiselect from 'vue-multiselect'
    import moment from "moment";
    import {ru} from "vuejs-datepicker/dist/locale";
    import CCollapseBlock from "./CCollapseBlock";
    import vue2Dropzone from "vue2-dropzone";

    export default {
        name: 'modal-attach-edit',
        components: {Datepicker, Multiselect, vueDropzone: vue2Dropzone},
        props: {
            modalId: {type: String, default: 'myModal',},
            url: {type: String, require: true,},
            acceptedFiles: {type: String, default: '',},
            maxFileSize: {type: Number, default: null,},
            cPage: {type: Object, default: {}, require: true},
        },
        data() {
            return {
                dropzoneOptionsAttaches: {
                    url: this.url,
                    thumbnailWidth: 150,
                    maxFileSize: this.maxFileSize,
                    headers: {'X-CSRF-TOKEN': document.head.querySelector("[name=csrf-token]").content,},
                    acceptedFiles: this.acceptedFiles,
                    maxFiles: 1,
                    init: function () {
                        this.on("maxfilesexceeded", function (file) {
                            this.removeAllFiles();
                            this.addFile(file);
                        });
                    }
                },
                ru: ru,
                attachTemplate: {
                    id: null,
                    name: '',
                },
                state: {},
            }
        },
        created: function () {
            Bus.$on('showModalAttachEdit', obj => this.showModal(obj));
        },
        methods: {
            dropzoneSuccessAttach(file, response) {
                $('#' + this.modalId).modal('hide');
                if (response.error === undefined || response.error.length === 0) {
                    if (response.cPage === undefined) return;
                    this.flash('файл успешно загружен', 'success', {timeout: 2000});
                    Bus.$emit('updCPage', response.cPage);
                    this.clickSave();
                } else {
                    let str = '';
                    response.error.forEach(item => str += item.message + '<br>');
                    this.flash(str, 'error')
                }
            },
            addParamAttach(file, xhr, formData) {
                formData.append('cPage', JSON.stringify(this.cPage));
            },
            showModal(obj) {
                if (obj) {
                    this.state = Object.assign({}, obj);
                } else {
                    this.state = this.attachTemplate;
                }

                $('#' + this.modalId).modal('show');
                setTimeout(() => this.$refs.v_focus.focus(), 500);
            },
            clickSave() {
                Bus.$emit('modalAttachEditSave', this.state);
                $('#' + this.modalId).modal('hide');
            },
            clickDelete() {
                Bus.$emit('modalAttachEditDelete', this.state);
                $('#' + this.modalId).modal('hide');
            },
        },
    }
</script>
